import {
  Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Button,
  Chip, Divider, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Modal,
  Select, TextField, Tooltip, Typography
} from '@mui/material'
import React, { Component } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import { ImagePaths } from '../../../Utility/ImagePaths';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Colors } from '../../../Styles/Colors';
import { Trans, withTranslation } from 'react-i18next';
import { Demo_Layout_Default_JSON } from '../../../Utility/Constants';
import { DatePicker, DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';
import { CommonPatientDetails } from '../../../Components/Common Components/CommonComponents';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { getCachevalue, setCachevalue, localGetItem, clearCachevalue } from '../../../Utility/Services/CacheProviderService';
import Loader from '../../../Components/Loader';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import moment from "moment";
import RadBilling from './RadPatients/RadBilling/RadBilling';
import { City, Country, State } from 'country-state-city';

let isUser = null;

class RadQuickDemographics extends Component {

  constructor(props) {
    super(props);
    this.state = {
      clinicId: '',
      layoutData: [],
      mandotory_count: '03',
      RightTabIndex: "1",
      OPServicesList: [],
      OPServiceTypeList: [],
      OPserviceTemplates: [],
      SelectedTemplateName: "",
      SelectedServiceName: "",
      SelectedServiceType: "",
      SelectedServices: [],
      Services: [],
      SpecializationList: [],
      DoctorList: [],
      TagsList: [],
      AppointmentTypeList: [],
      filteredDocList: [],

      selectedDocId: null,
      selectedSpecId: null,
      selectedTagId: null,
      selectedAppointTypeId: null,
      appointmentDateTime: new Date(),
      purposeOfVisit: "",

      referredBy: "",
      referralSource: "",
      mobile_no: "",
      first_name: "",
      age: "",
      dob: null,
      gender: "",
      title: "",
      middle_name: "",
      surname: "",
      address_1: "",
      address_2: "",
      city: "",
      cityName: City.getCitiesOfState("IN", "TN"),
      country_code: 'IN',
      state_code: 'TN',
      AllCountry: Country.getAllCountries(),
      AllStates: State.getStatesOfCountry("IN"),
      pin_code: "",
      employer_id: "",
      employee_id: "",
      email_id: "",
      marital_status: "",
      father_husband_name: "",
      father_husband_age: "",
      occupation: "",
      emergency_contact_person: "",
      emergency_contact_mobile: "",
      relationship: "",

      docDocument: null,
      aadharProof: null,
      panProof: null,
      passportProof: null,
      rationProof: null,
      dlProof: null,
      MandatoryLabels: [],
      CorporateList: [],
      patient_img: null,
      ProfilePicClicked: false,
      OpenAccordion: false,
      receive_email: false,
      receive_whatsapp: false,
      receive_sms: true,
      corporateName: "",
      SameMobileNumPatients: [],
      insurance_company_name: null,
      policy_number: "",
      guardian: "",
      DateOpen: false,
      PatientID: null,
      NurseList: [],
      selectedNurseId: null,
      mother_name: "",
      mother_age: "",
      POVList: [],
      InsuranceList: [],
      RequestedPatientData: {},
      slotPopup: false,
      selectedSlot: "",
      old_referral_no: "",
      patient_account_number: '',
      ip_number: '',
      op_number: '',
      billSummaryId: null,

      RadiologyList: [],
      SelectedRadiology: null,
      isFirstUpdate: true,
      disableNameGenderEdit: false,
    }
  }

  componentDidMount() {
    let details = JSON.parse(localGetItem('loggedInUserInfo'))
    let permissionAccess = details?.permission_access
    this.setState({
      clinicId: details?.clinic_id,
      disableNameGenderEdit: (permissionAccess?.common?.is_demographics_edit && details?.is_user) ? true : false
    }, () => {
      this.GetDatas()
      this.getInsuranceList()
    })
    let POVList = localGetItem("PurposeOfVisit") === null ? '[]' : localGetItem("PurposeOfVisit")
    let array = []
    JSON.parse(POVList).forEach(element => array.push(element.reason));
    if (array.length > 0) {
      this.setState({
        POVList: array
      })
    }
    let datas = this.props.history?.location?.state
    if (datas?.forRadCreate || datas?.forTransmit) {
      this.assignDemographicsData(datas?.patientData)
    } else if (datas?.fromSavedBill) {
      this.assignDemographicsData(datas?.patientData)
    } else if (datas?.BillFromIP || datas?.BillFromOP || datas?.ModifyFromFO || datas?.CancelFromFO || datas?.SavedFromFO ) {
      this.setState({
        PatientID: datas?.patientData?.patient_id ? datas?.patientData?.patient_id : datas?.patient_id ? datas?.patient_id : null,
      }, () => {
        if (this.state.PatientID) { this.getPatientDemographics() }
      })
    }
    if (details?.module_name === "front_office") {
      this.GetRadiologyList()
    }
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps !== this.props) {
      const prevPatientData = prevProps.history?.location?.state?.patientData;
      const currentData = this.props.history?.location?.state;

      if (currentData?.forTransmit && currentData?.patientData) {
        const currentPatientData = currentData?.patientData;

        if (this.state.isFirstUpdate) {
          this.assignDemographicsData(currentPatientData);
          this.setState({
            isFirstUpdate: false
          });
        } else {
          // Check if the patientData has changed
          if (currentPatientData?.patient_id !== prevPatientData?.patient_id) {
            this.assignDemographicsData(currentPatientData);
          }
        }

        // Clear history state
        this.props.history.replace({ state: {} });
      }

      let datas = this.props.history?.location?.state?.selectedPatientDetails
      if (datas?.patient_id && datas?.patient_id !== this.state.PatientID) {
        this.setState({
          PatientID: datas?.patient_id ? datas?.patient_id : null
        }, () => { this.getPatientDemographics() })
        this.props.history.push({ state: { selectedPatientDetails: {} } })
      }
    }
  }

  GetRadiologyList = () => {
    try {
      RestAPIService.getAll(Serviceurls.GET_RADIOLOGY_LIST)
        .then(response => {
          if (response.data.status === "success") {
            this.setState({
              RadiologyList: response?.data?.data ? response?.data?.data : [],
            }, () => {
              if (this.props.history?.location?.state?.ModifyFromFO || this.props.history?.location?.state?.CancelFromFO) {
                this.setState({
                  SelectedRadiology: this.state.RadiologyList.find((list) => (this.props.history?.location?.state?.patientData?.radiology_id === list?.radiology_id))

                })
              }
            })
          }
        })
        .catch(error => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        });
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  getPatientDemographics = () => {
    try {
      RestAPIService.getAll(Serviceurls.FO_PATIENT_UPDATE + "?patient_id=" + this.state.PatientID)
        .then(response => {
          if (response.data.status === "success") {
            this.assignDemographicsData(response.data.data)
          }
        })
        .catch(error => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        });
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  assignDemographicsData(Datas) {
    const { TransmitData } = this.props.history?.location?.state || {};
    const patientData = this.props.history?.location?.state?.patientData;
    const doctorId = TransmitData?.doctor_id  || patientData?.doctor_id || Datas?.doctor_id ;
    let DocID = this.state.DoctorList?.find(item => item.doctor_id === doctorId);
    let SpecID = this.state.SpecializationList?.find(item => item.id === DocID?.specialization_id);
    try {
      this.setState({
        referredBy: Datas.reffered_by ? Datas.reffered_by : '',
        referralSource: Datas.referral_source ? Datas.referral_source : '',
        mobile_no: Datas.mobile_number ? Datas.mobile_number : '',
        first_name: Datas.first_name ? Datas.first_name : '',
        age: this.handleAgeCalculation(Datas.dob !== null ? new Date(Datas?.dob) : new Date(Datas?.approx_dob)),
        dob: Datas.dob !== null ? new Date(Datas?.dob) : new Date(Datas?.approx_dob),
        gender: Datas.gender ? Datas.gender : '',
        title: Datas.title ? Datas.title : '',
        middle_name: Datas.middle_name ? Datas.middle_name : '',
        surname: Datas.last_name ? Datas.last_name : '',
        address_1: Datas.address_line_1 ? Datas.address_line_1 : '',
        address_2: Datas.address_line_2 ? Datas.address_line_2 : '',
        country_code: this.state.AllCountry.find((item) => (item.name === Datas?.country))?.isoCode,
        AllStates: State.getStatesOfCountry(this.state.AllCountry.find((item) => (item.name === Datas?.country))?.isoCode),
        state_code: State.getStatesOfCountry(this.state.AllCountry.find((item) => (item.name === Datas?.country))?.isoCode).find((item) => (item.name === Datas?.state_name))?.isoCode,
        pin_code: Datas.pincode ? Datas.pincode : '',
        employer_id: Datas.employer_id ? Datas.employer_id : '',
        employee_id: Datas.id_no ? Datas.id_no : '',
        email_id: Datas.email ? Datas.email : '',
        marital_status: Datas.marital_status ? Datas.marital_status : '',
        father_husband_name: Datas.father_husband_name ? Datas.father_husband_name : '',
        father_husband_age: Datas.father_husband_age ? Datas.father_husband_age : "",
        occupation: Datas.occupation ? Datas.occupation : '',
        emergency_contact_person: Datas.caretaker_name ? Datas.caretaker_name : '',
        emergency_contact_mobile: Datas.caretaker_mobile ? Datas.caretaker_mobile : '',
        relationship: Datas.caretaker_relationship ? Datas.caretaker_relationship : '',
        docDocument: null,
        aadharProof: Datas.aadhar === "" ? null : Datas.aadhar,
        panProof: Datas.pan === "" ? null : Datas.pan,
        passportProof: Datas.passport === "" ? null : Datas.passport,
        rationProof: Datas.ration_card === "" ? null : Datas.ration_card,
        dlProof: Datas.dl === "" ? null : Datas.dl,
        pancard_number: Datas.pancard_number ? Datas.pancard_number : '',
        ration_card_number: Datas.ration_card_number ? Datas.ration_card_number : '',
        dl_number: Datas.dl_number ? Datas.dl_number : '',
        passport_number: Datas.passport_number ? Datas.passport_number : '',
        aadhar_card_number: Datas.aadhar_card_number ? Datas.aadhar_card_number : '',
        patient_img: Datas?.photo_url === "" ? null : Datas.photo_url,
        receive_email: Datas?.receive_email ? Datas?.receive_email : '',
        receive_whatsapp: Datas?.receive_whatsapp ? Datas?.receive_whatsapp : '',
        receive_sms: Datas?.receive_sms ? Datas?.receive_sms : '',
        insurance_company_name: Datas?.insurance_company_name ? Datas?.insurance_company_name : '',
        policy_number: Datas?.policy_number ? Datas?.policy_number : '',
        guardian: Datas?.guardian ? Datas?.guardian : '',
        mother_name: Datas?.mother_name ? Datas?.mother_name : '',
        old_referral_no: Datas.old_referral_no ? Datas.old_referral_no : "",
        mother_age: Datas?.mother_age ? Datas?.mother_age : '',
        PatientID: Datas?.patient_id ? Datas?.patient_id : '',
        patient_account_number: Datas?.patient_account_number ? Datas?.patient_account_number : '',
        ip_number: Datas?.ip_number ? Datas?.ip_number : '',
        op_number: Datas?.op_number ? Datas?.op_number : '',
        ip_admission_id: Datas?.ip_admission_id ? Datas?.ip_admission_id : '',
        appointment_id: Datas?.appointment_id ? Datas?.appointment_id : '',
        city: Datas?.city_name ? Datas?.city_name : "",
        selectedDocId: DocID ? DocID : this.state.selectedDocId,
        selectedSpecId: SpecID ? SpecID : this.state.selectedSpecId,
        selectedFatHusName:Datas?.relationship_label ? Datas?.relationship_label : ''
      }, () => {
        this.setState({ cityName: City.getCitiesOfState(this.state.country_code, this.state.state_code) })
      })
    }
    catch (e) {
      this.errorMessage(e?.message)
    }
  }

  getInsuranceList = () => {
    try {
      RestAPIService.getAll(Serviceurls.FO_INSURANCE_LIST + '?clinic_id=' + this.state.clinicId).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              InsuranceList: response.data.data,
            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    } catch (e) {
      this.errorMessage(e.message)
    }
  }

  getPincodeToCity = (pincode) => {
    fetch("https://api.postalpincode.in/pincode/" + pincode)
      .then((res) => res.json())
      .then(response => {
        if (response && response[0].Status == "Success") {
          var data = response[0].PostOffice
          if (data && data.length > 0) {
            this.setState({
              city: this.state.city ? this.state.city : data[0].District,
              AllStates: this.state.state_code ? this.state.AllStates : State.getStatesOfCountry(this.state.AllCountry.find((item) => (item.name === data[0].Country))?.isoCode),
              country_code: this.state.country_code ? this.state.country_code : this.state.AllCountry.find((item) => (item.name === data[0].Country))?.isoCode,
            }, () => {
              this.setState({
                state_code: this.state.state_code ? this.state.state_code : this.state.AllStates.find((item) => (item.name === data[0].State))?.isoCode,
                cityName: this.state.city ? this.state.cityName : City.getCitiesOfState(this.state.country_code, this.state.AllStates.find((item) => (item.name === data[0].State))?.isoCode)
              })
            })
          }
        } else if (response.data.status == "fail") {
          this.errorMessage(response.data.message)
        }
      })
      .catch(e => {
        this.errorMessage(e.message)
      });
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  GetDatas = () => {
    try {
      this.lodaerFunction(true)
      RestAPIService.getAll(Serviceurls.FODemographicsLayout)
        .then((response) => {
          if (response.data.status === "success") {
            var Data = response.data.data
            var len = Data.length
            this.setState({
              layoutData: Data[len - 1] ? Data[len - 1].field_order : Demo_Layout_Default_JSON,
              mandotory_count: Data[len - 1] ? ("0" + Data[len - 1].mandatory_field_count) : '04',
            }, () => {
              let MandLabels = []
              for (let i = 0; i < +this.state.mandotory_count; i++) {
                MandLabels.push(this.state.layoutData[i].label)
              }
              this.setState({ MandatoryLabels: MandLabels })
              this.lodaerFunction(false)
            })
          }
        }).catch((error) => {
          this.lodaerFunction(false)
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })

      RestAPIService.getAll(Serviceurls.FO_CONFIG_OPSPECIALIZATION + '?clinic_id=' + this.state.clinicId).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              SpecializationList: response.data.data.filter((item) => (item.is_active === true))
            }, () => {
              const { TransmitData } = this.props.history?.location?.state || {};
              const patientData = this.props.history?.location?.state?.patientData;
              if (Object.keys(this.state.RequestedPatientData).length > 0 || Object.keys(TransmitData || {}).length > 0) {
                const doctorId = this.state.RequestedPatientData?.doctor_id || TransmitData?.doctor_id || patientData.doctor_id;
                let DocID = this.state.DoctorList.find(item => item.doctor_id === doctorId)
                let SpecID = this.state.SpecializationList.find(item => item.id === DocID?.specialization_id)
                this.setState({
                  selectedDocId: DocID ? DocID : this.state.selectedDocId,
                  selectedSpecId: SpecID ? SpecID : this.state.selectedSpecId,
                }, () => {
                  if (DocID) {
                    let states = this.state
                    let docDetail = DocID
                    let filteredLst = states["DoctorList"].length > 0 && states["DoctorList"].filter(item => item["specialization_id"] === docDetail["specialization_id"])
                    states["filteredDocList"] = filteredLst.length > 0 && filteredLst;
                    this.setState({ states })
                  }
                })
              }

            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })

      var date = new Date(this.state.appointmentDateTime)
      var appointment_date = DateTime.fromJSDate(date).toFormat('yyyy-MM-dd')
      RestAPIService.getAll(Serviceurls.FO_CONFIG_OPDOCTORNAME + "?date=" + appointment_date + "&clinic_id=" + this.state.clinicId).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              DoctorList: response.data.data.filter((item) => (item.specialization_active === true))
            }, () => {
              const { TransmitData } = this.props.history?.location?.state || {};
              const patientData = this.props.history?.location?.state?.patientData;
              let doctorData = JSON.parse(getCachevalue('RadPatientData'))
              const doctorId = TransmitData?.doctor_id || doctorData?.doctor_id || patientData?.doctor_id;
              let DocID = this.state.DoctorList?.find((item) => item?.doctor_id === doctorId)
              const specId = doctorData?.speciality_id || DocID?.specialization_id;
              let SpecID = this.state.SpecializationList?.find((item) => item?.id === specId)
              this.setState({
                selectedDocId: DocID ? DocID : this.state.selectedDocId,
                selectedSpecId: SpecID ? SpecID : this.state.selectedSpecId
              })
            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })

      RestAPIService.getAll(Serviceurls.FO_CONFIG_OPPATIENTTAG + '?clinic_id=' + this.state.clinicId).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              TagsList: response.data.data
            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })

      RestAPIService.getAll(Serviceurls.FO_CONFIG_CORPORATE + '?clinic_id=' + this.state.clinicId).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              CorporateList: response.data.data
            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })

      RestAPIService.getAll(Serviceurls.FO_CONFIG_OPAPPOINTMENT + '?clinic_id=' + this.state.clinicId).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              AppointmentTypeList: response.data.data.filter((item) => (item.is_active === true)),
            }, () => {
              if (Object.keys(this.state.RequestedPatientData).length > 0) {
                this.setState({
                  selectedAppointTypeId: this.state.RequestedPatientData?.booked_through === "Mobile" ? response.data.data.find((item) => (item.appoinment_type === "Mobile Appointment")) : response.data.data.find((item) => (item.appoinment_type === "Telephonic Appointment"))
                })
              } else {
                this.setState({
                  selectedAppointTypeId: response.data.data.find((item) => (item.appoinment_type === "Walk in Appointment"))
                })
              }
            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })

      RestAPIService.getAll(Serviceurls.FO_STAFF_LIST + '?clinic_id=' + this.state.clinicId).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              NurseList: response.data.data,
            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  renderDropDown = (label, stateKey, Data) => {
    let states = this.state;
    return (
      <Autocomplete
        size='small'
        clearIcon={false}
        disabled={(label === "Radiology" && (this.props.history?.location?.state?.ModifyFromFO || this.props.history?.location?.state?.CancelFromFO)) ? true : false}
        sx={{ width: '14vw', marginLeft: "1.5vw" }}
        componentsProps={{ popper: { style: { width: label === 'Doctor' ? "23vw" : "14vw" } } }}
        options={Data}
        getOptionLabel={(item) => (label === "Doctor" ? item.doctor_name : label === "Specialization" ? item.name : label === "Patient Tag" ? item.patient_tag_name : label === "Appointment Type" ? item.appoinment_type : label === "Nurse" ? item.nurse : label === "Radiology" ? item.radiology_name : "")}
        value={states[stateKey]}
        renderOption={label === 'Doctor' ? (props, item) => (
          <Box>
            {props["data-option-index"] === 0 ?
              <Box className="eMed_App_Doctor_table_head">
                <Typography width={"50%"} fontWeight={'600'} fontSize={"0.8vw"}>Doctor Name</Typography>
                <Typography width={"25%"} fontWeight={'600'} fontSize={"0.8vw"}>Total App</Typography>
                <Typography width={"25%"} fontWeight={'600'} fontSize={"0.8vw"}>New App</Typography>
              </Box> : null}
            <Box component="li" className='eMed_App_Doctor_table_data' {...props}>
              <div style={{ width: "50%" }}>{item?.doctor_name.length > 15 ? <Tooltip placement="top" title={item?.doctor_name} arrow><div>{item?.doctor_name.slice(0, 16) + "..."}</div></Tooltip> : item?.doctor_name ? item?.doctor_name : "-"}</div>
              <Typography width={"25%"} textAlign={"center"} fontWeight={'600'} fontSize={"0.8vw"}>{item.total_appointment}</Typography>
              <Typography width={"25%"} textAlign={"center"} fontWeight={'600'} fontSize={"0.8vw"}>{item.new_appointment}</Typography>
            </Box>
          </Box>) : null}
        onChange={(event, newValue) => {
          states[stateKey] = newValue
          if (newValue !== null && "doctor_id" in newValue) {
            let docDetail = { ...newValue }
            docDetail["name"] = docDetail["specialization_name"];
            delete docDetail.specialization_name;
            docDetail["id"] = docDetail["specialization_id"];
            delete docDetail.specialization_id;
            states["selectedSpecId"] = docDetail;
          }
          if (newValue !== null && "name" in newValue) {
            let filteredLst = states["DoctorList"].length > 0 && states["DoctorList"].filter(item => item["specialization_id"] === newValue["id"])
            states["filteredDocList"] = filteredLst.length > 0 && filteredLst;
            states["selectedDocId"] = null;
          }
          if (newValue === null && label === "Specialization") {
            states["filteredDocList"] = states["DoctorList"];
          }
          this.setState({ states })
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label + `${(label === "Doctor" || label === "Specialization" || label === "Nurse" || label === "Radiology") ? " *" : ""}`}
            placeholder={label}
          />
        )}
      />
    )
  }

  renderDateTimeFilter = () => {
    try {
      return (
        <Box sx={{ width: '15vw' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              open={this.state.DateOpen}
              onOpen={() => { this.setState({ DateOpen: true }) }}
              onClose={() => { this.setState({ DateOpen: false }) }}
              inputFormat='DD-MM-YYYY & hh:mm A'
              value={this.state.appointmentDateTime}
              minDate={new Date()}
              maxDate={new Date()}
              onChange={(newDate) => {
                if (newDate === null) {
                  this.setState({ appointmentDateTime: new Date() })
                }
                else {
                  this.setState({ appointmentDateTime: newDate?.$d })
                }
              }}
              renderInput={(params) => <TextField size='small' autoComplete="off"
                onKeyDown={(e) => e.preventDefault()}
                onClick={() => { this.setState({ DateOpen: true }) }}
                {...params}
                fullWidth
                variant='outlined'
                label="Appointment Date & Time *"
              />}
            />
          </LocalizationProvider>
        </Box>
      )
    } catch (e) {

    }
  }

  handleChange = (event, newValue) => {
    this.setState({
      RightTabIndex: newValue,
      SelectedServices: [],
      OPServiceTypeList: [],
      SelectedTemplateName: "",
      SelectedServiceName: "",
      SelectedServiceType: "",
    })

  };

  alphaValidation = (num) => {
    return !/[^A-Z a-z]+/.test(num)
  }
  numberValidation(num) {
    return /^\d+$/.test(num);
  }

  handleAgeCalculation = date => {
    // let age = "";
    // let dob = DateTime.fromJSDate(date);
    // var year_age = DateTime.now().diff(dob, 'years');
    // age = Math.floor(year_age.years) || 0;
    // return (date < new Date() && date > new Date("1872-01-01"))?age:"";
    let age = "";
    let dob = new Date(date);
    let m = moment(dob, "DD-MM-YYYY");
    var end_date = moment();

    var year_age = end_date.diff(m, 'year') || 0;
    // reset(year) year with difference year
    m = moment(m).add(year_age, 'years');

    var month_age = end_date.diff(m, 'month') || 0;
    // reset(month) month with difference month
    m = moment(m).add(month_age, 'months');

    var day_age = end_date.diff(m, 'days') || 0;

    if (year_age > 0) {
      age = year_age || 0;
    } else {
      age = year_age + "Y/" + month_age + "M/" + day_age + "D";
    }
    return (date < new Date() && date > new Date("1872-01-01")) ? age : "";
  }

  handleFindApproxDob = ageEntered => {

    var year = 0;
    var month = 0;
    var days = 0;
    var tempDate;
    year = +(ageEntered)
    if (!year) {
      tempDate = "";
    } else {
      tempDate = DateTime.now().minus({ years: year }).toBSON();
    }

    return tempDate;
  }

  CheckExistingPatients() {
    try {
      RestAPIService.getAll(Serviceurls.FO_PATIENT_SEARCH + `?search_key=${this.state.mobile_no}&address_key=&father_husband_key=&clinic_id=${this.state.clinicId}`).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              SameMobileNumPatients: response.data.data
            })
          }
          else {
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
    }
  }

  onClickChipHandler = (item) => {
    this.setState({ selectedSlot: item })
  }

  OpenAppointmentSlotPopUP = () => {
    return (
      <Box>
        <Modal open={true}>
          <Box className="eMed_WebCamPop_Main" sx={{ height: "40vh" }}>
            <Box component={'div'} className="eMed_WebCam_Header">
              <Typography fontWeight={600} fontSize={'1.1vw'} color={Colors.SecondaryText}>
                Select Slot
              </Typography>
              <Button onClick={() => { this.setState({ slotPopup: false }) }} >
                <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
              </Button>
            </Box>
            <Box component={'div'} style={{ display: "flex", flexWrap: "wrap", width: "90%", alignItems: "center", margin: "1vw", marginLeft: "2.5vw", height: "25vh", overflow: "scroll" }}>
              {this.state.slotList?.length > 0 ? this.state.slotList.map((item, index) => (
                <Chip className='eMed_chip'
                  size="medium" label={item} variant={item === this.state.selectedSlot ? 'contained' : 'outlined'}
                  key={index}
                  onClick={() => { this.onClickChipHandler(item) }}
                  color={'primary'}
                  clickable />)) : <Typography sx={{ height: "5vw", width: "100%", display: "flex", alignItems: "center", justifyContent: "center", color: "red" }}>{"Doctor Schedule not available"}</Typography>}
            </Box>
            <Box component={'div'} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end", paddingLeft: "1vw", paddingRight: "3vw" }}>
              <Button variant='outlined' size='small' id="eMed_Config_btn" style={{ marginRight: "0.5vw" }} onClick={() => { this.setState({ selectedSlot: "" }) }}>Clear</Button>
              <Button variant='contained' size='small' id="eMed_Config_btn" onClick={() => { this.setState({ slotPopup: false }) }}>Select</Button>
            </Box>
          </Box>
        </Modal>
      </Box>
    )
  }

  SameNumberPatientPop() {
    let { t } = this.props;
    return (
      <Modal open={true}>
        <Box className="eMed_MobileNum_Main">
          <Box component={'div'} className="eMed_MobileNum_Header">
            <Typography variant='h6'>{"Patient List"}</Typography>
            <IconButton onClick={() => { this.setState({ SameMobileNumPatients: [] }) }} >
              <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
            </IconButton>
          </Box>
          <Box component={'div'} className="eMed_MobileNum_wrapper">
            {
              this.state.SameMobileNumPatients.length > 0 ?
                this.state.SameMobileNumPatients.map((list) => {
                  let PatientFullName = `${list.first_name} ${list.last_name}`
                  list["patient_name"] = PatientFullName
                  list["patient_mobile_number"] = list.mobile_number
                  list["patient_photo_url"] = list.photo_url
                  return (
                    <Box>
                      <Box className="eMed_Patient_Num_Search_pop">
                        <CommonPatientDetails data={list} />
                        {((list.appointment_date === "" || list.appointment_date !== "") || list.appointment_status === "Cancelled") ? <Tooltip title={t("CreateAppointment")} arrow>
                          <IconButton onClick={() => {
                            setCachevalue(list.patient_id, "OP_CreateAppointment_PatientId")
                            this.setState({ PatientID: list?.patient_id, SameMobileNumPatients: [] }, () => { this.getPatientDemographics() })
                          }} position="end">
                            <PersonAddAlt1Icon color='primary' />
                          </IconButton>
                        </Tooltip> : <Typography fontSize={'0.8vw'}>{list.appointment_date}</Typography>}
                      </Box>
                      <Divider />
                    </Box>
                  )
                }) : null
            }
          </Box>
        </Box>
      </Modal>
    )
  }

  getPincodeToCity = (pincode) => {
    fetch("https://api.postalpincode.in/pincode/" + pincode)
      .then((res) => res.json())
      .then(response => {
        if (response && response[0].Status == "Success") {
          var data = response[0].PostOffice
          if (data && data.length > 0) {
            this.setState({
              city: this.state.city ? this.state.city : data[0].District,
              state: this.state.state ? this.state.state : data[0].State,
              country: this.state.country ? this.state.country : data[0].Country
            })
          }
        } else if (response.data.status == "fail") {
        }
      })
      .catch(e => {
      });
  }

  renderInput = (data, type) => {
    var states = this.state
    const { t } = this.props
    let DisableAllFields = (this.props.history?.location?.state?.BillFromOP || this.props.history?.location?.state?.BillFromIP || this.props.history?.location?.state?.ModifyFromFO || this.props.history?.location?.state?.CancelFromFO || this.props.history?.location?.state?.SavedFromFO)
    return (
      <form>
        <Box sx={{ marginTop: '2.5vh', marginLeft: '0.5vw', width: (data.label == "Age" || data.label == "Title") ? '7vw' : data.label == "Gender" ? '10vw' : '12vw' }}>
          {data.label == "Date of Birth" ?
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label={(type === 'mandatory' || data.is_default) ? (t(data.label) + "*") : t(data.label)}
                inputFormat={"dd-MM-yyyy"}
                autoComplete="off"
                disabled={DisableAllFields}
                value={states[data.field_name]}
                minDate={new Date("1872-01-01")}
                maxDate={DateTime.now()}
                onChange={(newDate) => {
                  var age = this.handleAgeCalculation(newDate)
                  this.setState({
                    dob: newDate,
                    age: age
                  })
                }}
                renderInput={(params) => <TextField {...params} size='small' autoComplete="off"
                  variant="outlined" fullWidth />}
              />
            </LocalizationProvider>
            :
            data.label == "Title" ?
              <FormControl size='small' fullWidth sx={{ textAlign: 'center', height: '1.3vw' }}>
                <InputLabel id="title-select-label">{(type === 'mandatory' || data.is_default) ? (t(data.label) + "*") : t(data.label)}</InputLabel>
                <Select
                  labelId="title-select-label"
                  MenuProps={{ style: { maxHeight: 250 } }}
                  sx={{ fontSize: '0.8vw', }}
                  size='small'
                  value={states[data.field_name]}
                  label={t(data.label)}
                  disabled={DisableAllFields || (this.state.disableNameGenderEdit && this.state.PatientID)}
                  onChange={(e) => {
                    states[data.field_name] = e.target.value

                    this.setState({
                      states
                    }, () => {
                      var states = this.state
                      if (states.title == "Mr") {
                        states["gender"] = "m";
                      } else if (states.title == "Ms") {
                        states["gender"] = "f";
                      } else if (states.title == "Mrs") {
                        states["gender"] = "f";
                        states["marital_status"] = "Married"
                        states["manuallyChanged"] = true
                      } 
                      if(states.manuallyChanged && states.title !== "Mrs") {
                        states["marital_status"] = ""
                      }
                      this.setState({ states })
                    })
                  }}
                >
                  <MenuItem value={""}>{"Title"}</MenuItem>
                  <MenuItem value={"Dr"}>{"Dr"}</MenuItem>
                  <MenuItem value={"Mr"}>{"Mr"}</MenuItem>
                  <MenuItem value={"Ms"}>{"Ms"}</MenuItem>
                  <MenuItem value={"Mrs"}>{"Mrs"}</MenuItem>
                  <MenuItem value={"Master"}>{"Master"}</MenuItem>
                  <MenuItem value={"Baby"}>{"Baby"}</MenuItem>
                  <MenuItem value={"Baby of"}>{"Baby of"}</MenuItem>
                </Select>
              </FormControl>
              : data.label == "Gender" ?
                <FormControl size='small' sx={{ width: '10vw' }}>
                  <InputLabel id="gender-select-label">{"Gender *"}</InputLabel>
                  <Select
                    sx={{ width: '10vw' }}
                    labelId='gender-select-label'
                    size='small'
                    label={t(data.label)}
                    value={states[data.field_name]}
                    disabled={DisableAllFields || (states.disableNameGenderEdit && states.PatientID)}
                    onChange={(e) => {
                      states[data.field_name] = e.target.value
                      this.setState({
                        states
                      })
                    }}
                  >
                    <MenuItem key={1} value={"m"}>{t("Male")}</MenuItem>
                    <MenuItem key={2} value={"f"}>{t("Female")}</MenuItem>
                    <MenuItem key={2} value={"t"}>{t("TransGender")}</MenuItem>
                  </Select>
                </FormControl>
                : data.label === "Insurance Company Name" ?
                  <Autocomplete
                    sx={{ width: "12vw" }}
                    size="small"
                    options={states.InsuranceList}
                    getOptionLabel={(option) => (typeof (option) === "string" ? option : option.insurance_name)}
                    onChange={(event, value) => {
                      states["insurance_company_name"] = value?.insurance_name ? value?.insurance_name : ""
                      states["NewInsurance"] = false
                      this.setState({
                        states
                      })
                    }}
                    clearIcon={false}
                    value={states.insurance_company_name}
                    disabled={DisableAllFields}
                    renderInput={(params) => (
                      <TextField
                        label={t("Insurance Company Name") + " *"} InputProps={{ ...params.InputProps }}
                        onChange={(event) => {
                          states["insurance_company_name"] = event.target.value
                          states["NewInsurance"] = true
                          this.setState({
                            states
                          })
                        }}
                        {...params}
                      />
                    )}
                  /> :
                  data.label === "City" ?
                    <Autocomplete
                      sx={{ width: "12vw" }}
                      size="small"
                      options={states.cityName ? states.cityName : []}
                      getOptionLabel={(option) => typeof (option) === "string" ? option : option.name}
                      onChange={(e, value) => {
                        this.setState({
                          city: value?.name ? value?.name : '',
                          // pin_code: '',
                        })
                      }}
                      clearIcon={false}
                      disabled={DisableAllFields}
                      value={states.city}
                      renderInput={(params) => (
                        <TextField
                          label={(type === 'mandatory' || data.is_default) ? ((data.label) + "*") : (data.label)}
                          InputProps={{ ...params.InputProps }}
                          onChange={(e) => this.setState({
                            city: e.target.value,
                            // pin_code: ''
                          })}
                          {...params}
                        />)}
                      onKeyDown={(e) => e.key === "Enter" ? e.preventDefault() : null}
                    />
                    : data.label == "Mobile no" ?
                      <TextField
                        disabled={Object.keys(this.state.RequestedPatientData).length > 0 || DisableAllFields}
                        label={(type === 'mandatory' || data.is_default) ? (t(data.label) + "*") : t(data.label)}
                        autoComplete="off"
                        size="small"
                        onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">{"+91"}</InputAdornment>
                        }}
                        value={states[data.field_name]}
                        onChange={(e) => {
                          var states = this.state
                          var isMobile = this.numberValidation(e.target.value)
                          if (data.label == "Mobile no") {
                            let mobileNum = e.target.value
                            if ((isMobile && mobileNum.length < 11) || e.target.value == "") {
                              states[data.field_name] = e.target.value
                              if (e.target.value.length === 10) { this.CheckExistingPatients() }
                            }
                          }
                          this.setState({ states })
                        }}
                        error={states.mobile_no !== "" && states.mobile_no.length !== 10}
                      />
                      : data.label == "Corporate Name" ?
                        <FormControl size='small' sx={{ width: '12vw' }}>
                          <InputLabel id="gender-select-label">{(type === 'mandatory' || data.is_default) ? (t(data.label) + "*") : t(data.label)}</InputLabel>
                          <Select
                            sx={{ width: '12vw' }}
                            MenuProps={{ style: { maxHeight: 250 } }}
                            labelId='gender-select-label'
                            size='small'
                            label={(type === 'mandatory' || data.is_default) ? (t(data.label) + "*") : t(data.label)}
                            value={states[data.field_name]}
                            disabled={DisableAllFields}
                            onChange={(e) => {
                              states[data.field_name] = e.target.value
                              if (e.target.value === "") { states.employee_id = "" }
                              this.setState({
                                states
                              })
                            }}
                          >
                            <MenuItem value={""}>{"None"}</MenuItem>
                            {
                              states.CorporateList.length > 0 ?
                                states.CorporateList.map((list, index) => (
                                  <MenuItem key={index} value={list.id}>{list.company_name}</MenuItem>
                                )) : null
                            }
                          </Select>
                        </FormControl>
                        : data.label == "Marital Status" ?
                          <FormControl size='small' sx={{ width: '12vw' }}>
                            <InputLabel id="gender-select-label">{(type === 'mandatory' || data.is_default) ? (t(data.label) + "*") : t(data.label)}</InputLabel>
                            <Select
                              sx={{ width: '12vw' }}
                              labelId='gender-select-label'
                              size='small'
                              label={(type === 'mandatory' || data.is_default) ? (t(data.label) + "*") : t(data.label)}
                              value={states[data.field_name]}
                              disabled={DisableAllFields}
                              onChange={(e) => {
                                states[data.field_name] = e.target.value
                                states["manuallyChanged"] = false
                                this.setState({
                                  states
                                })
                              }}
                            >
                              <MenuItem key={1} value={"Single"}>{t("Single")}</MenuItem>
                              <MenuItem key={2} value={"Married"}>{t("Married")}</MenuItem>
                              <MenuItem key={2} value={"Divorced"}>{t("Divorced")}</MenuItem>
                            </Select>
                          </FormControl>
                          : data.label == "Employee ID No" ?
                            <TextField
                              autoComplete="off"
                              inputProps={{ maxLength: 30 }}
                              label={(type === 'mandatory' || data.is_default || states.employer_id) ? (t(data.label) + "*") : t(data.label)}
                              size="small" value={states[data.field_name]}
                              onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                              onChange={(e) => {
                                states[data.field_name] = e.target.value.toUpperCase()
                                this.setState({ states })
                              }}
                              disabled={states.employer_id === null || states.employer_id === "" || DisableAllFields}
                            />
                            : data.label == "State" ?
                              // <RegionDropdown className='eMed_demo_dropdown_inputs'
                              //   country={this.state.country}
                              //   value={this.state.state}
                              //   onChange={(val) => {
                              //     states[data.field_name] = val
                              //     this.setState({ states })
                              //   }} />
                              <FormControl size='small' sx={{ width: '12vw' }}>
                                <InputLabel id="title-select-label">{(type === 'mandatory' || data.is_default) ? ((data.label) + "*") : (data.label)}</InputLabel>
                                <Select
                                  sx={{ width: '12vw' }}
                                  MenuProps={{ style: { maxHeight: 250, maxWidth: '12vw' } }}
                                  labelId='gender-select-label'
                                  size='small'
                                  label={data.label}
                                  disabled={DisableAllFields}
                                  value={states.state_code}
                                  onChange={(e) => {
                                    this.setState({ state_code: e.target.value },
                                      () => {
                                        this.setState({ cityName: City.getCitiesOfState(this.state.country_code, this.state.state_code), city: '', })
                                      })
                                  }}
                                >
                                  {
                                    states.AllStates?.map((list) => (
                                      <MenuItem key={list.isoCode} value={list.isoCode}>{list.name}</MenuItem>
                                    ))
                                  }
                                </Select>
                              </FormControl>
                              : data.label == "Country" ?
                                <FormControl size='small' sx={{ width: '12vw', maxWidth: '12vw' }}>
                                  <InputLabel id="title-select-label">{(type === 'mandatory' || data.is_default) ? ((data.label) + "*") : (data.label)}</InputLabel>
                                  <Select
                                    sx={{ width: '12vw' }}
                                    MenuProps={{ style: { maxHeight: 250, maxWidth: '12vw' } }}
                                    labelId='gender-select-label'
                                    label={data.label}
                                    size='small'
                                    value={states.country_code}
                                    disabled={DisableAllFields}
                                    onChange={(e) => {
                                      this.setState({ country_code: e.target.value, state_code: "", city: '' }, () => {
                                        this.setState({ AllStates: State.getStatesOfCountry(this.state.country_code), cityName: '', })
                                      })
                                    }}
                                  >
                                    {
                                      states.AllCountry.map((list) => (
                                        <MenuItem key={list.isoCode} value={list.isoCode}>{list.name}</MenuItem>
                                      ))
                                    }
                                  </Select>
                                </FormControl>
                                // <CountryDropdown className='eMed_demo_dropdown_inputs'
                                //   value={this.state.country}
                                //   onChange={(val) => this.setState({ country: val })}
                                //   priorityOptions={["IN"]}
                                //   whitelist={["IN", "AF", "AU", "BT", "CA", "CU", "FR", "DE", "ID", "IR", "JP", "KZ", "MY", "MV", "MN", "PK", "RU", "SA", "SG", "LK", "TW", "TH", "TR", "AE", "GB", "US"]}
                                // />
                                : data.label === "Father/Husband Name" ?
                                <Box component={'div'} display={'flex'}>
                                  <Select
                                    size='small'
                                    value={this.state.selectedFatHusName}
                                    onChange={(e) => this.setState({ selectedFatHusName: e.target.value })}
                                    sx={{width:'3.5vw'}}
                                  >
                                    <MenuItem value={1}>Father</MenuItem>
                                    <MenuItem value={2}>Husband</MenuItem>
                                  </Select>
                                  <TextField
                                    disabled={DisableAllFields}
                                    label={(type === 'mandatory' || data.is_default) ? (t(data.label) + "*") : t(data.label)}
                                    autoComplete="off"
                                    size="small"
                                    inputProps={{maxLength:32}}
                                    onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                                    value={states[data.field_name]}
                                    onChange={(e) => {
                                      let states = this.state
                                      let isSpecial = /^[ A-Za-z.,/]*$/.test(e.target.value)
                                      if (isSpecial || e.target.value == "") {
                                        states[data.field_name] = e.target.value
                                        this.setState({states })
                                      }
                                    }}
                                    sx={{width:'10vw'}}
                                  />
                                </Box> :
                                <TextField
                                  disabled={((data.label === "First Name") ? (Object.keys(this.state.RequestedPatientData).length > 0 || (this.state.disableNameGenderEdit && this.state.PatientID)) : (data.label === "Middle Name" || data.label === "Surname") ? (this.state.disableNameGenderEdit && this.state.PatientID) : false) || DisableAllFields}
                                  autoComplete="off"
                                  inputProps={{ maxLength: (data.label === "First Name" || data.label === "Middle Name" || data.label === "Surname" || data.label === "Referred By" || data.label === "Referral Source" || data.label === "Mother's Name" || data.label === "Father/Husband Name" || data.label === "Emergency Contact Person" || data.label === "Relationship") ? 32 : ( data.label === "Occupation" || data.label === "'Insurance Company Name'" || data.label === "Insurance No") ? 45 : ( data.label === "Email ID") ? 50 : 100 }}
                                  label={(type === 'mandatory' || data.is_default) ? (t(data.label) + "*") : t(data.label)}
                                  variant="outlined"
                                  value={states[data.field_name]}
                                  onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                                  onChange={(e) => {
                                    var isAlpha = this.alphaValidation(e.target.value)
                                    var isNum = this.numberValidation(e.target.value)
                                    var isAlphaNum = CommonValidation.alphanumValidation(e.target.value)
                                    var isSpecial = /^[ A-Za-z.,/]*$/.test(e.target.value)

                                    if (data.label === "Age" || data.label === "Father/Husband Age" || data.label === "Mother's Age") {
                                      var age = e.target.value
                                      if ((isNum && +(age) <= 150) || e.target.value == "") {
                                        states[data.field_name] = e.target.value
                                        states['dob'] = data.label === "Age" ? null : states.dob
                                        this.setState({
                                          states,
                                        }, () => {
                                          if (data.label === "Age") {
                                            var approxDob = this.handleFindApproxDob(this.state.age);
                                            this.setState({
                                              dob: approxDob
                                            })
                                          }
                                        })
                                      }
                                      else if (e.target.value.includes("/")) {
                                        this.setState({ age: "", dob: null })
                                      }
                                    }
                                    else if (data.label === "First Name" || data.label === "Middle Name" || data.label === "Surname" || data.label === "Emergency Contact Person" || data.label === "Occupation" || data.label === "Relationship" || data.label === "City" || data.label === "Guardian" || data.label === "Mother's Name") {
                                      if (isAlpha || e.target.value == "") {
                                        states[data.field_name] = e.target.value
                                        this.setState({
                                          states
                                        })
                                      }
                                    }
                                    else if (data.label === "Father/Husband Name") {
                                      if (isSpecial || e.target.value == "") {
                                        states[data.field_name] = e.target.value
                                        this.setState({
                                          states
                                        })
                                      }
                                    }
                                    else if (data.label === "Pin Code") {
                                      if ((isNum || e.target.value == "") && e.target.value.length <= 6) {
                                        states[data.field_name] = e.target.value
                                        this.setState({
                                          states
                                        }, () => {
                                          if (this.state.pin_code && this.state.pin_code.length === 6) {
                                            this.getPincodeToCity(this.state.pin_code)
                                          }
                                        })
                                      }
                                    }
                                    else if (data.label === "Emergency Mobile No") {
                                      if ((isNum || e.target.value == "") && e.target.value.length <= 10) {
                                        states[data.field_name] = e.target.value
                                        this.setState({
                                          states
                                        })
                                      }
                                    }
                                    else if (data.label === "Insurance No") {
                                      if ((isAlphaNum || e.target.value == "")) {
                                        states[data.field_name] = e.target.value
                                        this.setState({
                                          states
                                        })
                                      }
                                    }
                                    else {
                                      states[data.field_name] = e.target.value
                                      this.setState({
                                        states
                                      })
                                    }
                                  }}
                                  size="small"
                                />
          }
        </Box>
      </form>
    )
  }

  ClearDatas() {
    this.setState({
      selectedDocId: null,
      selectedSpecId: null,
      selectedTagId: null,
      SelectedRadiology: null,
      selectedAppointTypeId: this.state.AppointmentTypeList.find((item) => (item.appoinment_type === "Walk in Appointment")),
      appointmentDateTime: new Date(),
      purposeOfVisit: "",
      referredBy: "",
      referralSource: "",
      mobile_no: "",
      first_name: "",
      age: "",
      dob: null,
      gender: "",
      title: "",
      middle_name: "",
      surname: "",
      address_1: "",
      address_2: "",
      city: "",
      cityName: City.getCitiesOfState("IN", "TN"),
      country_code: 'IN',
      state_code: 'TN',
      AllCountry: Country.getAllCountries(),
      AllStates: State.getStatesOfCountry("IN"),
      pin_code: "",
      employer_id: null,
      employee_id: "",
      email_id: "",
      marital_status: "",
      father_husband_name: "",
      father_husband_age: "",
      occupation: "",
      emergency_contact_person: "",
      emergency_contact_mobile: "",
      relationship: "",
      insurance_company_name: null,
      policy_number: "",
      guardian: "",
      filteredDocList: [],
      PatientID: null,
      selectedNurseId: null,
      mother_name: "",
      mother_age: "",
      NewInsurance: false,
      RequestedPatientData: {},
      old_referral_no: "",
      manuallyChanged: false,
      isFirstUpdate: true,
      selectedFatHusName:''
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  lodaerFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  getRadTransmitBillCount = (flag) => {
    if (this.props.getRadTransmitCount && typeof this.props.getRadTransmitCount === 'function')
      this.props.getRadTransmitCount(flag)
  }

  QuickBillPostSucess(toOverall = false, toFoSaved = false) {
    this.ClearDatas()
    if (JSON.parse(localGetItem('loggedInUserInfo'))?.module_name !== "front_office") {
      setTimeout(() => {
        this.props.history?.push({ pathname: '/RadiologyHome' })
      }, 1000)
    } else if (toOverall) {
      if (getCachevalue("patientData")) {
        setTimeout(() => {
          this.props?.history.push({ pathname: '/OPHome/PatientDetails/Billing' })
        }, 1000)
      } else if (getCachevalue("IppatientData")) {
        setTimeout(() => {
          this.props?.history.push({ pathname: '/IPHome/PatientDetails/ServiceBilling' })
        }, 1000)
      } else if(toFoSaved){
        setTimeout(() => {
          this.props.history?.push({ pathname: '/BillingTransaction/SavedBills' })
        }, 1000)
      }else {
        setTimeout(() => {
          this.props.history?.push({ pathname: '/BillingTransaction/OverAllBills' })
        }, 1000)
      }
    }
  }

  sentDataTodemographics(DocId, RadID) {
    if (DocId) {
      this.setState({
        selectedDocId: this.state.DoctorList.find((list) => (list?.doctor_id === DocId))
      }, () => {
        this.setState({
          selectedSpecId: this.state.SpecializationList.find((list) => (list?.id === this.state.selectedDocId?.specialization_id))
        })
      })
    }
    if(RadID){
      this.setState({
        SelectedRadiology: this.state.RadiologyList.find((list) => (list?.radiology_id === RadID))
      })
    }
  }

  render() {
    var states = this.state
    var Mandatory_Array = []
    var Non_Mandatory_Array = []
    var listMandatory = []
    var remainMandatoryList = []
    const SplitCount = +(states.mandotory_count)
    const Total_Len = (states.layoutData).length
    var Active_Array = (states.layoutData).filter((item) => {
      return item.is_active == true
    })

    Mandatory_Array = (Active_Array).slice(0, SplitCount)
    Non_Mandatory_Array = (Active_Array).slice(SplitCount, Total_Len)

    // mandatory list splited for view purpose
    listMandatory = SplitCount > 7 && Active_Array.slice(0, 7);
    remainMandatoryList = Active_Array.slice(7, SplitCount);

    let DemographicData = {
      PatientID: states.PatientID,
      selectedDocId: this.props.is_Nurse ? null : states.selectedDocId,
      selectedSpecId: this.props.is_Nurse ? null : states.selectedSpecId,
      selectedTagId: states.selectedTagId,
      selectedAppointTypeId: states.selectedAppointTypeId,
      appointmentDateTime: states.appointmentDateTime,
      purposeOfVisit: states.purposeOfVisit,
      referredBy: states.referredBy,
      referralSource: states.referralSource,
      mobile_no: states.mobile_no,
      first_name: states.first_name,
      age: states.age,
      dob: states.dob,
      gender: states.gender,
      title: states.title,
      middle_name: states.middle_name,
      surname: states.surname,
      address_1: states.address_1,
      address_2: states.address_2,
      city: states.city,
      state: states.AllStates?.find((item) => (item.isoCode === states.state_code))?.name,
      country: states.AllCountry?.find((item) => (item.isoCode === states.country_code))?.name,
      pin_code: states.pin_code,
      employer_id: states.employer_id,
      employee_id: states.employee_id,
      email_id: states.email_id,
      marital_status: states.marital_status,
      father_husband_name: states.father_husband_name,
      father_husband_age: states.father_husband_age,
      occupation: states.occupation,
      emergency_contact_person: states.emergency_contact_person,
      emergency_contact_mobile: states.emergency_contact_mobile,
      relationship: states.relationship,
      MandatoryLabels: states.MandatoryLabels,
      layoutData: states.layoutData,
      mandotory_count: states.mandotory_count,
      receive_email: states?.receive_email,
      receive_whatsapp: states?.receive_whatsapp,
      receive_sms: states?.receive_sms,
      insurance_company_name: states?.insurance_company_name,
      policy_number: states?.policy_number,
      guardian: states?.guardian,
      selectedNurseId: states.selectedNurseId,
      isNurse: this.props.is_Nurse,
      mother_name: states.mother_name,
      mother_age: states.mother_age,
      NewInsurance: states.NewInsurance,
      RequestedPatientData: states?.RequestedPatientData,
      selectedSlot: states?.selectedSlot,
      old_referral_no: states?.old_referral_no,
      patient_account_number: states?.patient_account_number,
      ip_number: states?.ip_number ? states?.ip_number : '',
      op_number: states?.op_number ? states?.op_number : '',
      SelectedRadiology: states?.SelectedRadiology ? states?.SelectedRadiology : null,
      ip_admission_id: states?.ip_admission_id ? states?.ip_admission_id : '',
      appointment_id: states?.appointment_id ? states?.appointment_id : '',
      relationship_label: states.selectedFatHusName ? states.selectedFatHusName : ''
    }
    return (

      <Box component={"div"} className={"eMed_bill_container"}>
        <Box className="emed_qckbill_box1">
          <Accordion expanded={this.state.OpenAccordion} sx={{ zIndex: "3", backgroundColor: Colors.ComponentBgColor }} elevation={0}>
            <AccordionSummary
              // sx={{ backgroundColor: Colors.ComponentBgColor }}
              className='emed_qckbill_box1_accordian'
              expandIcon={
                <IconButton color='primary' onClick={() => { this.setState({ OpenAccordion: !this.state.OpenAccordion }) }}><ExpandMoreIcon /></IconButton>}
            >
              {listMandatory.length > 0 ? listMandatory.map((item, index) => (
                this.renderInput(item, 'mandatory')
              )) :
                Mandatory_Array.map((item, index) => (
                  this.renderInput(item, 'mandatory')
                ))}
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: Colors.ComponentBgColor }}>
              <Box className='eMed_demolayout_left_TextInput' mb={1.3}>
                {remainMandatoryList.length > 0 ? remainMandatoryList.map((item, index) => (
                  this.renderInput(item, 'mandatory')
                )) : null}
              </Box>
              <Box className="emed_qckbill_box1_accordian_detail_box">
                <Typography className='eMed_Esstial_Fields_title'>{"Essential Information"}</Typography>
                {/* <Box className="emed_qckbill_box1_accordian_detail_box">
                    <FormControlLabel control={<Checkbox checked={this.state.receive_email} onChange={(e) => { this.setState({ receive_email: e.target.checked }) }} size='small' />} label="Receive Email" />
                    <FormControlLabel control={<Checkbox checked={this.state.receive_sms} onChange={(e) => { this.setState({ receive_sms: e.target.checked }) }} size='small' />} label="Receive SMS" />
                    <FormControlLabel control={<Checkbox disabled checked={this.state.receive_whatsapp} onChange={(e) => { this.setState({ receive_whatsapp: e.target.checked }) }} size='small' />} label="WhatsApp" />
                  </Box> */}
              </Box>
              <Box className='eMed_demolayout_left_TextInput'>
                {Non_Mandatory_Array.map((item, index) => (
                  this.renderInput(item, 'non-mandatory')
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box component={'div'} display={'flex'} mt={'0.5vw'} >
          {(this.props.history?.location?.state?.BillFromOP || this.props.history?.location?.state?.BillFromIP) ?
            <Box className="eMed_quickbill_left_view1" sx={{ width: '100vw', justifyContent: 'flex-start' }}>
              {this.renderDropDown("Specialization", "selectedSpecId", this.state.SpecializationList)}
              {this.renderDropDown("Doctor", "selectedDocId", this.state.filteredDocList?.length > 0 ? this.state.filteredDocList : this.state.DoctorList)}
              {this.renderDropDown("Radiology", "SelectedRadiology", this.state.RadiologyList)}
              <Button
                variant="contained"
                size="small"
                id="eMed_Btn_Text"
                sx={{ width: "13vw", marginRight: "1vw", margin: '0.5vw', ml: "1.5vw" }}
                onClick={() => {
                  if (this.props.history?.location?.state?.BillFromOP) {
                    let AppID = this.state.appointmentID
                    clearCachevalue("isInRadiologyBilling");
                    this.props.history.push({
                      pathname: '/OPHome/PatientDetails/Billing',
                      // state: { AppointmentID : AppID}
                    })
                  } else if (this.props.history?.location?.state?.BillFromIP) {
                    let AppID = this.state.appointmentID
                    clearCachevalue("isInRadiologyBilling");
                    this.props.history.push({
                      pathname: '/IPHome/PatientDetails/ServiceBilling',
                      // state: { AppointmentID : AppID}
                    })
                    this.setState({
                      is_OPlabBill: false,
                      is_IPlabBill: false,
                    })
                  }
                }}>{this.props.history?.location?.state?.BillFromIP ? "Back to IP Billing" : "Back to OP Billing"}</Button>
            </Box>
            :
            this.props.history?.location?.state?.ModifyFromFO || this.props.history?.location?.state?.CancelFromFO ?
              <Box className="eMed_quickbill_left_view1" sx={{ width: '100vw', justifyContent: 'flex-start' }}>
                {this.renderDropDown("Radiology", "SelectedRadiology", this.state.RadiologyList)}
                {/* {this.props.history?.location?.state?.ModifyFromFO || this.props.history?.location?.state?.CancelFromFO ?
                  <Button emed_tid='rad_lft_cacl_btn' variant='contained'
                    sx={{ textTransform: 'capitalize', height: '2vw', mr: '1vw', width: '15vw', ml: "1.5vw" }}
                    onClick={() => {
                      clearCachevalue('RadCancelBillData')
                      clearCachevalue('isForCancelBill')
                      this.props.history.push({ pathname: '/BillingTransaction/OverAllBills' })
                    }}>{'Back To Overall Bills'}</Button> : null} */}
              </Box> :
              <Box className="eMed_quickbill_left_view1" sx={{ width: '100vw', justifyContent: 'flex-start' }}>
                {this.renderDropDown("Specialization", "selectedSpecId", this.state.SpecializationList)}
                {this.props.is_Nurse ? this.renderDropDown("Nurse", "selectedNurseId", this.state.NurseList) :
                  this.renderDropDown("Doctor", "selectedDocId", this.state.filteredDocList?.length > 0 ? this.state.filteredDocList : this.state.DoctorList)}
                {/* {this.renderDateTimeFilter()} */}
                {/* <Button variant='outlined' disabled={this.state.selectedDocId == null || !this.state.selectedDocId?.doctor_id} style={{ width: "8vw", height: "2.5vw" }} size="small" id="eMed_Config_btn" onClick={() => { this.getSlotIntervel() }}>{"Select Slot"}</Button> */}
                {JSON.parse(localGetItem('loggedInUserInfo'))?.module_name === "front_office" ? this.renderDropDown("Radiology", "SelectedRadiology", this.state.RadiologyList) : null}
                {/* {this.props.history?.location?.state?.ModifyFromFO || this.props.history?.location?.state?.CancelFromFO ?
                  <Button emed_tid='rad_lft_cacl_btn' variant='contained'
                    sx={{ textTransform: 'capitalize', height: '2vw', mr: '1vw', width: '15vw', ml: "1.5vw" }}
                    onClick={() => {
                      clearCachevalue('RadCancelBillData')
                      clearCachevalue('isForCancelBill')
                      this.props.history.push({ pathname: '/BillingTransaction/OverAllBills' })
                    }}>{'Back To OverallBills'}</Button> : null} */}
              </Box>
          }
          {/* {(this.props.history?.location?.state?.BillFromOP || this.props.history?.location?.state?.BillFromIP) ? null :
            <Box className="eMed_quickbill_right_view1">
              {this.renderDropDown("Patient Tag", "selectedTagId", this.state.TagsList)}
              <Autocomplete
                inputProps={{ maxLength: 250 }}
                options={states.POVList}
                getOptionLabel={(options) => options}
                onChange={(e, value) => {
                  this.setState({
                    purposeOfVisit: value
                  })
                }}
                clearIcon={false}
                size='small'
                sx={{ width: '15vw', marginLeft: "1vw" }}
                value={states.purposeOfVisit}
                renderInput={(params) => <TextField sx={{ width: "15vw" }} onChange={(event) => {
                  this.setState({
                    purposeOfVisit: event.target.value
                  })
                }}
                  {...params}
                  placeholder="Purpose Of Visit *"
                />}
              />
            </Box>
          } */}
        </Box>
        <Box component={"div"}>
          <RadBilling
            // history={this.props.history}
            fromQuickBill={true}
            DamographData={DemographicData}
            QuickBillPostSucess={this.QuickBillPostSucess.bind(this)}
            ClearClicked={this.ClearDatas.bind(this)}
            billSummaryId={this.props.history?.location?.state?.billSummaryId ? this.props.history?.location?.state?.billSummaryId : null}
            BillFromIP={this.props.history?.location?.state?.BillFromIP ? true : false}
            BillFromOP={this.props.history?.location?.state?.BillFromOP ? true : false}
            ModifyFromFO={this.props.history?.location?.state?.ModifyFromFO ? true : false}
            CancelFromFO={this.props.history?.location?.state?.CancelFromFO ? true : false}
            FromIpPatientBillsScreen={this.props.history?.location?.state?.FromIpPatientBillsScreen ? true : false}
            RadiologyId={this.props.history?.location?.state?.patientData?.radiology_id ? this.props.history?.location?.state?.patientData?.radiology_id : null}
            ModifyFromFOData={this.props.history?.location?.state?.patientData}
            SavedFromFO={this.props.history?.location?.state?.SavedFromFO}
            sentDataTodemographics={this.sentDataTodemographics.bind(this)}
            Patient_id = {this.state.PatientID}
            transmitData = {this.props.history?.location?.state?.TransmitData || null}
            getRadTransmitCount = {this.getRadTransmitBillCount}
          />
        </Box>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.SameMobileNumPatients.length > 0 ? this.SameNumberPatientPop() : null}
        {this.state.slotPopup ? this.OpenAppointmentSlotPopUP() : null}
        <Loader loaderOpen={this.state.isLoader} />
      </Box>
    )
  }
}
export default withTranslation()(RadQuickDemographics)