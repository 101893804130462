import { Box, Button, Drawer, Stack, Tooltip, Typography } from '@mui/material'
import React, { Component } from 'react'
import '../pharmaReports.css';
import { Colors } from '../../../../Styles/Colors';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { DataGrid } from '@mui/x-data-grid';
import { CommonGridToolBarWithFilterText } from '../../../../Components/Common Components/CommonComponents';
import { AmountFormat } from '../../../../Components/CommonFunctions/CommonFunctions';
import { withTranslation } from 'react-i18next';
import PharmaFilter from '../PharmaFilter';
import { DateTime } from 'luxon';
import { CurrencySymbol } from '../../../../Utility/Constants';

class ExpiryStock extends Component {

    constructor(props) {
        super(props)
        this.state = {
            expiryStockData: [],
            page: 0,
            pageSize: 10,
            rowCount: 0,
            filterDatas: {
                'fromMonth': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                'toMonth': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                'brandName': '',
                "dosageType": [],
            },
            pageName: 'expiryStock',
            filterOpen: false,
            totalAmount: 0,
            cgst: 0,
            sgst: 0,
            discountAmount: 0,
            netAmount: 0
        }
    }

    componentDidMount() {
        this.getExpiryStockData()
    }

    LoaderFunction = (key) => {
        this.setState({
          isLoader: key
        })
      }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    renderFilter = (data) => {
        this.setState({
            filterDatas: data,
            filterOpen: false,
        }, () => { this.getExpiryStockData() })
    }

    ClosePopUp() {
        this.setState({
            filterOpen: false,
        })
    }

    clearFilter = (data) => {
        this.setState({
            filterDatas: data
        })
    }

    formatMonthAndYear = (value) => {
        if (value) {
            let month = value.split("-")[1]
            let year = value.split('-')[0]

            return month + "-" + year
        } else {
            return "-"
        }
    }

    gridToolBar = () => {
        var { t } = this.props
        let TextArray = [
            { label: "Expiry Till", value: this.state.filterDatas.toMonth ? this.formatMonthAndYear(this.state.filterDatas.toMonth) : "-" },
        ]
        return (
            <Box>
                <CommonGridToolBarWithFilterText FilterTextArray={TextArray} noExport={true} />
            </Box>

        )
    }

    getExpiryStockData = () => {
        try {
            let brandName = this.state.filterDatas.brandName?.replaceAll('&','%26')
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.PHARMA_INVENTORY_EXPIRY_STOCK_REPORT_GET + `?month=${this.state.filterDatas?.toMonth?.split("-")[1]}&year=${this.state.filterDatas?.toMonth?.split("-")[0]}&brand_name=${brandName}&dosage_type=${this.state.filterDatas.dosageType}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            expiryStockData: response.data.data
                        }, ()=>{ this.LoaderFunction(false) })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.LoaderFunction(false)
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.LoaderFunction(false)
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getExpiryStockPrint = () => {
        try {
            this.setState({disableBtn: true})
            let brandName = this.state.filterDatas.brandName?.replaceAll('&','%26')
            RestAPIService.getAllPrint(Serviceurls.PHARMA_INVENTORY_EXPIRY_STOCK_REPORT_GET + `?month=${this.state.filterDatas?.toMonth?.split("-")[1]}&year=${this.state.filterDatas?.toMonth?.split("-")[0]}&brand_name=${brandName}&dosage_type=${this.state.filterDatas.dosageType}&export_type=pdf`).
                then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    this.setState({disableBtn: false})
                }).catch((error) => {
                    if (error.response?.data?.message) {
                        this.errorMessage(error.response?.data?.message);
                    } else {
                        this.errorMessage(error.message);
                    }
                    this.setState({disableBtn: false})
                })
        } catch (error) {
            this.errorMessage(error.message)
            this.setState({disableBtn: false})
        }
    }

    render() {
        const { t } = this.props
        this.state.expiryStockData.forEach((element, index) => element.sno = index + 1)
        const columns = [
            {
                field: 'brand_name', headerName: "Product Details", flex: 0.143,
                renderCell: (params) => (<Box component={'div'}>
                    {params?.row?.brand_name?.length > 12 ? <Tooltip placement='top' title={params?.row?.brand_name} arrow><div>{params?.row?.brand_name?.slice(0, 12) + '...'}</div></Tooltip> :
                        params?.row?.brand_name ? params?.row?.brand_name : '-'}
                    {params?.row?.dosage_type?.length > 12 || params?.row?.dosage_strength?.length > 12 ?
                        <Box display={"flex"}>
                            <Tooltip placement="top" title={params?.row?.dosage_type}><Typography id='eMed_Com_Patient_UhidText' component={'div'}>{params?.row?.dosage_type.slice(0, 12) + '...'}</Typography></Tooltip> |
                            <Tooltip placement="top" title={params?.row?.dosage_strength}><Typography id='eMed_Com_Patient_UhidText' component={'div'}>{params?.row?.dosage_strength.slice(0, 12)}</Typography></Tooltip>
                        </Box> :
                        <Typography id='eMed_Com_Patient_UhidText'>{`${params?.row?.dosage_type} | ${params?.row?.dosage_strength}`}</Typography>}
                </Box>)
            },
            {
                field: 'batch_no', headerName: "Batch No.", flex: 0.143, headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>
                    {params?.row?.batch_no?.length > 12 ? <Tooltip placement='top' title={params?.row?.batch_no} arrow><div>{params?.row?.batch_no?.slice(0, 12) + '...'}</div></Tooltip> :
                        params?.row?.batch_no ? params?.row?.batch_no : '-'}
                </Box>)
            },
            {
                field: 'expiry_date', headerName:"Expiry Date", flex: 0.143, headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params.row?.expiry_date ? params.row?.expiry_date : '-'}</Box>)
            },
            {
                field: 'available_quantity', headerName: "Stock In Qty", flex: 0.143, type: "number",
                renderCell: (params) => (<Box component={'div'}>{params.row?.available_quantity ? params.row?.available_quantity : '0'}</Box>)
            },
            {
                field: 'mrp_qty', headerName: `MRP/Qty (${CurrencySymbol})`, flex: 0.143, type: "number",
                renderCell: ({ row }) => (<Box>{AmountFormat(row?.mrp_qty || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
            },
            {
                field: 'stock_value', headerName: `Stock Value (${CurrencySymbol})`, flex: 0.143, type: "number",
                renderCell: ({ row }) => (<Box>{AmountFormat(row?.stock_value || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
            },
            {
                field: 'stock_days', headerName: "Stock Days", flex: 0.143, type: "number",
                renderCell: (params) => (<Box component={'div'}>{params.row?.stock_days ? params.row?.stock_days : '0'}</Box>)
            },
        ]
        return (
            <Box component={'div'} height={'80vh'} sx={{ backgroundColor: Colors.Background }}>
                <Box component={'div'} className='eMed_Pharma_rts_header' sx={{ height : "7vh"}}>
                    <Box component={'div'} flex={0.8} display={'flex'}>

                    </Box>
                    <Box flex={0.2} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} marginRight={'0.5vw'}>
                        <Button emed_tid = "rpt_expStk_flt" className='eMed_Pharma_rts_btn' onClick={() => this.setState({ filterOpen: true })}>
                            <Box component={'img'} src={ImagePaths.Filter.default} alt='filter' height={'2.3vw'} width={'2.3vw'} />
                        </Button>
                        <Button emed_tid = "rpt_expStk_print" className='eMed_Pharma_rts_btn' disabled={this.state.disableBtn} onClick={()=>{this.getExpiryStockPrint()}}>
                            <Box component={'img'} src={ImagePaths.LabPrintIcon.default} alt='print' height={'2.3vw'} width={'2.3vw'} />
                        </Button>
                    </Box>
                </Box>
                <Box component={'div'} className='eMed_Pharma_rts_tbl_card' sx={{ height : "72vh"}}>
                    <DataGrid
                        className='eMed_Pharma_table'
                        rows={this.state.expiryStockData}
                        columns={columns}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        getRowId={(row) => row['sno']}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        rowCount={this.state.expiryStockData?.length}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        headerHeight={40}
                        disableSelectionOnClick
                        pagination
                        loading={this.state.isLoader}
                    />
                </Box>

                <Drawer
                    anchor={'right'}
                    open={this.state.filterOpen}
                    ModalProps={{ onBackdropClick: this.ClosePopUp.bind(this) }}
                >
                    <PharmaFilter pageName={this.state.pageName} filterDatas={this.state.filterDatas} closeFilter={this.ClosePopUp.bind(this)} filteredData={this.renderFilter.bind(this)} ClearData={this.clearFilter.bind(this)} calDosType={true}/>
                </Drawer>

                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}

export default withTranslation()(ExpiryStock)