import { Autocomplete, Box, Button, Divider, FormControl, InputLabel, MenuItem, Modal, Select, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography, Paper, IconButton, Stepper, Step, StepLabel, Stack, Grid, Dialog, DialogContent, DialogActions, FormControlLabel, Checkbox, TablePagination, FormHelperText, InputAdornment, RadioGroup, Radio } from '@mui/material'
import React, { Component } from 'react'
import { Colors } from '../../../Styles/Colors'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg'
import { ImagePaths } from '../../../Utility/ImagePaths'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { EditRounded } from '@mui/icons-material';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation'
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import Loader from '../../../Components/Loader';
import { ReasonPopup } from '../../../Components/Common Components/CommonComponents'
import { AmountFormat, CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions'
import { Code_Length} from '../../../Utility/Constants'
import { localGetItem } from '../../../Utility/Services/CacheProviderService'
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';

import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'


const Gender_List = [
  { label: "Male", value: 'M' },
  { label: "Female", value: 'F' },
  { label: "Common", value: 'C' }
]

const Days_List = [
  { label: "Days", value: 'days' },
  { label: "Weeks", value: 'weeks' },
  { label: "Months", value: 'months' },
  { label: "Years", value: 'years' }
]

const Referance_Type_List = [
  { label: ">", value: '>' },
  { label: "<", value: '<' },
  { label: "≥", value: '≥' },
  { label: "≤", value: '≤' },
  { label: "=", value: '=' }
]

const Result_Type_List = [
  { label: 'Range Based', value: 1 },
  { label: 'Positive/Negative', value: 2 },
  { label: 'Free Text Based ', value: 5 },
  { label: 'Culture Test', value: 3 },
  { label: 'Present/Absent', value: 6 },
  { label: 'Greater than/Less than', value: 7 }
]

const Temp = [
  {label: '°F', value: 'F'},
  {label: '°C', value: 'C'},
]
const steps = ['Test Details', 'Parameters'];

export default class RangeBasedConfig extends Component {
  constructor(props) {
    super(props)
    this.state = {
      resultConfigData: [],
      openPopUp: false,
      selectedData: null,
      range_data: {
        gender: '',
        age_from: '',
        age_to: '',
        age_from_unit: 'years',
        age_to_unit: 'years',
        reference_range: '',
        description: '',
        interpretation: '',
      },
      ref_data: [{
        reference_from: '',
        reference_to: '',
        panichigh: '',
        paniclow: '',
        unit: '',
      }],
      ref_type: [{
        reference_type: '',
        reference_range: '',
        panichigh: '',
        paniclow: '',
        unit: ''
      }],
      testNameLength: [],
      selectedIndex: "",
      isEdit: false,
      editRangeData: null,
      originalEditData: null,
      refUnitList: [],
      isPrintOrderEdit: false,
      oldSelectedData: '[]',
      selectedEditIndex: null,
      departmentListData: [],
      duplicateResult: '[]',
      departmentName: null,
      grpListData: [],
      testListData: [],
      groupName: null,
      NewgrpListData: [],
      NewtestListData: [],
      newTestNameLength: [],
      isEditTestDetails: false,
      addTestPopup: false,
      stepNumber: 0,
      ViewScreen: 0,
      DepList: [],
      deletePopup: false,
      DltTitle: "",
      testName: null,
      GroupAmt: 0,
      TestAmt: 0,
      NewdepartmentName: null,
      NewgroupName: null,
      NewSpecimen: "",
      NewtestName: "",
      NewMethod: "",
      NewresultType: "",
      newPrintOrder: null,
      receivedData: {},
      EditTitle: "",
      EditPopup: false,
      EditDepName: "",
      EditGrpName: "",
      EditGrpAmt: "",
      EditDepId: "",
      EditGrpId: "",
      EditPrintOrder: "",
      EditTestName: "",
      EditTestAmt: "",
      EditTestId: "",
      isEditDepart: false,
      isEditGrp: false,
      DepPrintOrder: null,
      NewEditTestDetails: false,
      isBillable: false,
      EditIsBillable: false,
      AntibioticList: [],
      BacteriaList: [],
      CulturalTestData: [
        {
          AntibioticData: null,
          BacteriaData: null,
          zone_size_s: "",
          zone_size_i: "",
          zone_size_r: "",
          mic_s: "",
          mic_i: "",
          mic_r: "",
        }
      ],
      containers: [
        {
          id: 1
        }
      ],
      EditCultureTest: false,
      page: 0,
      rowsPerPage: 10,
      CulturalTestList: [],
      selectedIndex: "",
      isEditCulture: false,
      disableBtn: false,
      grpCode:'',
      testCode:'',
      hasCode:false,
      EditGrpCode:'',
      EditTestCode: '',
      PossibleResult: "",
      PossibleTestComments: "",
      PossibleTestStatus: "Abnormal",
      PossibleTestList: [],
      PossibleEditId: null,
      grpshortName: '',
      testshortName: '',
      loincCode: '',
      cptCode: '',
      sacVal: '',
      cutOffHour: null,
      cutOffMin: null,
      specimenList: [],
      nameSpecimen: null,
      MethodList: [],
      analyserList: [],
      specQty: '',
      Qtyunit: null,
      temperature: '',
      Tempunit: null,
      nameMethod: null,
      nameAnalyzer: null,
      EditgrpShortname: '',
      editGrpData: {},
      editTestData: {},
      testId: null
    }
    this.TimeListArray = [];
    for (let i = 1; i <= 72; i++) {
      this.TimeListArray[i] = i < 10 ? `0${i}` : i;
    }
    this.TimeListMin = [];
    for (let i = 1; i <= 59; i++) {
      this.TimeListMin[i] = i < 10 ? `0${i}` : i;
    }
  }

  componentDidMount() {
    this.getDeptList()
    this.getNewReferrenceUnitData()
    this.getBacteriaList()
    this.getSpecimenList()
    this.getAnalyzerList()
  }

  getDeptList = () => {
    let Deplength = []
    try {
      RestAPIService.getAll(Serviceurls.GET_LAB_DEPARTMENT_MASTER)
        .then((response) => {
          if (response.data.status === 'success' && response?.data?.data?.length > 0) {
            var DeptData = response.data?.data ? response.data?.data.filter((item) => item.is_active) : []
            for (let i = 0; i <= DeptData?.length - 1; i++) {
              Deplength?.push(i + 1)
            }
            this.setState({
              departmentListData: DeptData,
              departmentName: DeptData[0] ? DeptData[0] : null,
              selectedDeptId: DeptData[0]?.id ? DeptData[0]?.id : null,
              DepartLength: Deplength
            }, () => {
              var { departmentName } = this.state
              if (departmentName?.dept_name) {
                this.getNewReferrenceData()
              }
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getSpecimenList = () => {
    try {
      RestAPIService.getAll(Serviceurls.GET_LAB_SPECIMEN_MASTER)
        .then((response) => {
          if (response.data.status === 'success') {
            let data = response?.data?.data.filter((item)=> item.is_active)
            this.setState({
              specimenList: data || []
            })
          }
        })
        .catch((e) => {
          if (e?.response?.data?.message) {
            this.errorMessage(e.response.data.message)
          } else {
            this.errorMessage(e.message)
          }
        })
    } catch (e) {
      this.errorMessage(e.message)
    }
  }
  getMethodList = () => {
    try {
      RestAPIService.getAll(Serviceurls.GET_LAB_METHOD_MASTER)
        .then((response) => {
          if (response.data.status === 'success') {
            let data = response?.data?.data.filter((item)=> ( (this.state.NewdepartmentName?.id === item?.select_dept)))
            this.setState({
              MethodList: data || []
            })
          }
        })
        .catch((e) => {
          if (e?.response?.data?.message) {
            this.errorMessage(e.response.data.message)
          } else {
            this.errorMessage(e.message)
          }
        })
    } catch (e) {
      this.errorMessage(e.message)
    }
  }
  getAnalyzerList = () => {
    try {
      RestAPIService.getAll(Serviceurls.LAB_ANALYSER_MASTER)
        .then((response) => {
          if (response.data.status === 'success') {
            let data = response?.data?.data.filter((item)=> item.is_active)
            this.setState({
              analyserList: data || []
            })
          }
        })
        .catch((e) => {
          if (e?.response?.data?.message) {
            this.errorMessage(e.response.data.message)
          } else {
            this.errorMessage(e.message)
          }
        })
    } catch (e) {
      this.errorMessage(e.message)
    }
  }

  getBacteriaList = () => {
    try {
      RestAPIService.getAll(Serviceurls.LAB_ORGANISM_MASTER).
        then((response) => {
          if (response.data.status === "success") {
            let data = response.data.data.filter((item)=>item.is_active)
            this.setState({ BacteriaList: data || [] })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getAntiBacteriaList = (val) => {
    try {
      RestAPIService.getAll(Serviceurls.LAB_ORGANISM_VS_ANTIBIO_MASTER).
        then((response) => {
          if(response?.data.status === 'success'){
            let data = response.data.data.filter((item)=>(item.is_active && val?.id === item?.organism_id))
            this.setState({ AntibioticList: data || [] })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: +event.target.value, page: 0 });
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  getResultConfigExcel = () => {
    try {
      var { NewdepartmentName } = this.state
      this.setState({disableBtn: true})
      RestAPIService.excelGet(Serviceurls.NEW_LAB_RESULT_CONFIGURATION + "?dept_name=" + NewdepartmentName?.dept_name + "export_type=excel")
        .then((response) => {
          if (response.data) {
            var pom = document.createElement('a');
            var csvContent = response.data; //here we load our csv data 

            let filename = response.headers["content-disposition"].split("filename=")[1]
            // var blob = new Blob([csvContent], {type: 'data:application/vnd.ms-excel;base64'});

            var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
            var url = URL.createObjectURL(blob);
            pom.href = url;
            pom.setAttribute('download', filename ? filename : 'registry.xlsx');
            pom.click();
            this.setState({disableBtn: false})
          }
        }).catch((error) => {
          if (error.response?.data?.message) {
            this.errorMessage(error.response?.data?.message);
          } else {
            this.errorMessage(error.message);
          }
          this.setState({disableBtn: false})
        })
    } catch (error) {
      this.errorMessage(error.message)
      this.setState({disableBtn: false})
    }
  }

  handleOpenPopup = (data, dataTwo, data3) => {
    try {
      let Data1 = JSON.parse(JSON.stringify(data))
      let Data2 = JSON.parse(JSON.stringify(dataTwo))
      let Data3 = JSON.stringify(dataTwo)
      let Data4 = JSON.stringify(data)
      if (data?.result_type == 3 && !this.state.isEditRefData) {
        this.setState({
          EditCultureTest: true,
          CulturalTestList: data?.test_data?.length > 0 ? data?.test_data : [],
          selectedData: Data1,
        })
      } else {
          let duplicateRefData = {
            reference_from: '',
            reference_to: '',
            panichigh: '',
            paniclow: '',
            unit: ''
          }
          let duplicateRefTypeData = {
            reference_type: '',
            reference_range: '',
            panichigh: '',
            paniclow: '',
            unit: '',
          }
          var RefData = []
          var RefType = []
          let lastItem = Data2?.reference_data[Data2?.reference_data.length - 1];
          if (data.result_type == 1) {
            if(lastItem?.reference_from || lastItem?.reference_to){
              RefData = [...Data2?.reference_data, duplicateRefData]
            }else{
              RefData = [...Data2?.reference_data]
            }
          } else if (data.result_type == 7) {
            if(lastItem?.reference_type || lastItem?.reference_range){
            RefData = [...Data2?.reference_data, duplicateRefData]
            RefType = [...Data2?.reference_data, duplicateRefTypeData]
          }else{
            RefData = [...Data2?.reference_data]
            RefType = [...Data2?.reference_data]
          }
          } else {
            RefData = [...Data2?.reference_data]
          }
          this.setState({
            editTestData: Data1,
            oldSelectedData: JSON.stringify(Data1),
            editRangeData: Data2,
            originalEditData: Data3,
            ref_data: RefData,
            ref_type: RefType,
            duplicateSelected: Data4
          })
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  handleClosePopup = () => {
    try {
      this.setState({ openPopUp: false, isEdit: false, isEditTestDetails: false, NewEditTestDetails: false }, () => this.resetNewReferrence())
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onChangeHandler = (e, names, index) => {
    try {
      let { selectedData, range_data, ref_data, isEdit, editRangeData, ref_type, editTestData } = this.state
      let value = e.target.value
      switch (names) {
        case 'specimen':
          selectedData.specimen = value
          this.setState({ selectedData })
          break;
        case 'method':
          selectedData.method = value
          this.setState({ selectedData })
          break;
        case 'resultType':
          selectedData.result_type = value
          if (selectedData && selectedData?.result_type === 'range based') {
            this.setState({ selectedData })
          } else {
            let dup_ref_data = [
              {
                reference_from: '',
                reference_to: '',
                panichigh: '',
                paniclow: '',
                unit: '',
              }
            ]
            this.setState({ selectedData, ref_data: dup_ref_data })
          }
          break;
        case 'printOrder':
          selectedData.print_order = value
          this.setState({ selectedData })
          break;
        case 'NewSpecimen':
          this.setState({ NewSpecimen: value })
          break;
        case 'NewMethod':
          this.setState({ NewMethod: value })
          break;
        case 'NewresultType':
          editTestData.result_type = value
          if(this.state.isAddNewRef){
            if(value === 5){
              steps.push('Possible Test')
            }else{
              steps.length = 0
              steps.push('Test Details', 'Parameters')
            }
            this.setState({
              editTestData,
              NewresultType: value
            })
          }else{
            if (this.state.NewresultType === 'range based') {
              this.setState({ NewresultType: value })
            } else {
              let dup_ref_data = [
                {
                  reference_from: '',
                  reference_to: '',
                  panichigh: '',
                  paniclow: '',
                  unit: ''
                }
              ]
              this.setState({ NewresultType: value, ref_data: dup_ref_data })
            }
          }
          break;
        case 'NewtestName':
          this.setState({ NewtestName: value })
          break;
        case 'printOrder':
          selectedData.print_order = value
          this.setState({ selectedData })
          break;
        case 'gender':
          if (isEdit) {
            editRangeData.gender = value
            this.setState({ editRangeData })
          } else {
            range_data.gender = value
            this.setState({ range_data })
          }
          break;
        case 'referanceType':
          ref_type[index].reference_type = value
          this.setState({ ref_type })
          break;
        case 'refRange':
          ref_type[index].reference_range = value
          this.setState({ ref_type })
          break;
        case 'refDescription':
          ref_type[index].description = value
          this.setState({ ref_type })
          break;
        case 'GroupAmt':
          if (CommonValidation.numberOnly(value) || value === '') {
            this.setState({ GroupAmt: +value })
          }
          break;
        case 'TestAmt':
          if (CommonValidation.numberOnly(value) || value === '') {
            this.setState({ TestAmt: +value })
          }
          break;
        case 'ageFrom':
          if ((CommonValidation.numberOnly(value) && +value <= 200) || value === '') {
            if (isEdit) {
              if (((editRangeData.age_from_unit === 'years' && +value <= 200) || (editRangeData.age_from_unit === 'months' && +value <= 11) || (editRangeData.age_from_unit === 'days' && +value <= 31) || (editRangeData.age_from_unit === 'weeks' && +value <= 7))) {
                editRangeData.age_from = value
                this.setState({ editRangeData })
              }
            } else {
              if (((range_data.age_from_unit === 'years' && +value <= 200) || (range_data.age_from_unit === 'months' && +value <= 11) || (range_data.age_from_unit === 'days' && +value <= 31) || (range_data.age_from_unit === 'weeks' && +value <= 7))) {
                range_data.age_from = value
                this.setState({ range_data })
              }
            }
          }
          break;
        case 'fromDays':
          if (isEdit) {
            editRangeData.age_from_unit = value
            editRangeData.age_from = ''
            this.setState({ editRangeData })
          } else {
            range_data.age_from_unit = value
            range_data.age_from = ''
            this.setState({ range_data })
          }
          break;
        case 'ageTo':
          if ((CommonValidation.numberOnly(value)) || value === '') {
            if (isEdit) {
              if (((editRangeData.age_to_unit === 'years' && +value <= 200) || (editRangeData.age_to_unit === 'months' && +value <= 11) || (editRangeData.age_to_unit === 'days' && +value <= 31) || (editRangeData.age_to_unit === 'weeks' && +value <= 7))) {
                editRangeData.age_to = value
                this.setState({ editRangeData })
              }
            } else {
              if (((range_data.age_to_unit === 'years' && +value <= 200) || (range_data.age_to_unit === 'months' && +value <= 11) || (range_data.age_to_unit === 'days' && +value <= 31) || (range_data.age_to_unit === 'weeks' && +value <= 7))) {
                range_data.age_to = value
                this.setState({ range_data })
              }
            }
          }
          break;
        case 'toDays':
          if (isEdit) {
            editRangeData.age_to_unit = value
            editRangeData.age_to = ''
            this.setState({ editRangeData })
          } else {
            range_data.age_to_unit = value
            range_data.age_to = ''
            this.setState({ range_data })
          }
          break;
        case 'rangeFrom':
          let resultType = (this.state.receivedData?.result_type === 1 || this.state.selectedData?.result_type === 1) ? CommonValidation.RangeDecimalNumberFiveNew(value) : CommonValidation.RangeDecimalNumberFive(value)
          // let resultType = (this.state.receivedData?.result_type === 1 || this.state.selectedData?.result_type === 1) ? CommonValidation.RangeDecimalNumberFiveNew(value) : CommonValidation.RangeDecimalNumberFive(value)
          if ((value?.length <= 100) || value === '') {
            ref_data[index].reference_from = value
            this.setState({ ref_data, selectedIndex: index }, () => {
              if ((this.state.receivedData?.result_type === 1 || this.state.selectedData?.result_type === 1)) {
                ref_data[index].reference_to = ''
                this.setState({ ref_data })
              }
            })
          }
          break;
        case 'rangeTo':
          // if ((CommonValidation.RangeDecimalNumberFive(value)) || value === '') {
          if ((value?.length <= 100) || value === '') {
            ref_data[index].reference_to = value
            this.setState({ ref_data })
          }
          break;
        case 'unit':
          ref_data[index].unit = value
          this.setState({ ref_data })
          break;
        case 'description':
          ref_data[index].description = value
          this.setState({ ref_data })
          break;
        default: break;
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  inputTextBox = (names, values, label, width, marginRight, index, disable) => {
    try {
      let {ref_data} = this.state
      return (
        <TextField
          autoComplete='off'
          disabled={disable ? disable : false}
          // disabled={disable ? disable : (names === 'rangeTo' ? (ref_data[index].reference_from?.includes('>') || ref_data[index].reference_from?.includes('<') ? true : false) : false)}
          sx={{ width: width, marginRight: marginRight, marginBottom: names == "NewtestName" ? 0 : '1vw', mt: (names === "NewtestName" || names === 'ageFrom' || names === 'ageTo' || names === 'rangeFrom' || names === 'rangeTo' || names === 'refRange' || names === 'refDescription')  ? 0 : '0.5vw' }}
          label={label}
          value={values}
          size='small'
          onChange={(e) => this.onChangeHandler(e, names, index)}
        />
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onAddNewParameter = (key) => {
    try {
      let { ref_data, ref_type } = this.state
      let duplicateRefData = {
        reference_from: '',
        reference_to: '',
        panichigh: '',
        paniclow: '',
        unit: ''
      }

      let duplicateRefTypeData = {
        reference_type: '',
        reference_range: '',
        panichigh: '',
        paniclow: '',
        unit: ''
      }

      let Invalid = this.ValidateREfCheck(ref_data, ref_type, key)
      if (!Invalid) {
        ref_data = [...ref_data, duplicateRefData]
        ref_type = [...ref_type, duplicateRefTypeData]
        this.setState({ ref_data, ref_type })
      } else {
        this.errorMessage("Enter Referrence Details")
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onAddNewReferance = () => {
    try {
      let { ref_type } = this.state
      let duplicateRefData = {
        reference_type: '',
        reference_range: '',
        paniclow: '',
        panichigh: '',
        unit: '',
      }

      let Invalid = this.ValidateReferanceCheck(ref_type)
      if (!Invalid) {
        ref_type = [...ref_type, duplicateRefData]
        this.setState({ ref_type })
      } else {
        this.errorMessage("Enter Referrence Details")
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  resetNewReferrence = () => {
    try {
      let resetNewRefData = [{
        reference_from: '',
        reference_to: '',
        panichigh: '',
        paniclow: '',
        unit: ''
      }]
      let resetRangeData = {
        gender: '',
        age_from: '',
        age_to: '',
        age_from_unit: 'years',
        age_to_unit: 'years',
        reference_range: '',
        description: '',
        interpretation: '',
      }
      let resetRefType = [{
        reference_type: '',
        reference_range: '',
        panichigh: '',
        paniclow: '',
        unit: ''
      }]
      let resetCulture = [
        {
          AntibioticData: null,
          BacteriaData: null,
          zone_size_s: "",
          zone_size_i: "",
          zone_size_r: "",
          mic_s: "",
          mic_i: "",
          mic_r: "",
        }
      ]
      steps.length = 0
      steps.push('Test Details', 'Parameters')
      // if (this.state.isEdit) {
      //   let refData = JSON.parse(this.state.originalEditData)
      //   let editRefData = this.state.selectedData?.result_type === 1 ? [...refData.reference_data, resetNewRefData[0]] : [...refData.reference_data]
      //   this.setState({
      //     ref_data: editRefData,
      //     editRangeData: refData,
      //     selectedIndex: ""
      //   })
      // } else {
        this.setState({
          selectedData: this.state.duplicateSelected ? JSON.parse(this.state.duplicateSelected) : null,
          ref_data: resetNewRefData,
          range_data: resetRangeData,
          ref_type: resetRefType,
          selectedIndex: "",
          EditTestName: this.state.duplicate?.test_name,
          EditTestAmt: this.state.duplicate?.test_amt,
          EditIsBillable: this.state.duplicate?.is_billable,
          EditTestCode:this.state.duplicate?.test_code ? this.state.duplicate?.test_code : '',
          CulturalTestData: resetCulture,
        })
      // }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  ValidateREfCheck = (data, ref_data, key) => {
    try {
      let isInValid = false
      let dataList = data
      if (key?.result_type === 7) {
        dataList = ref_data.map((item, index) => {
          return {
            reference_type: item.reference_type,
            reference_range: item.reference_range,
            description: data[index].description,
            unit: data[index].unit
          };
        });
      }
      dataList?.forEach((element, index) => {
        if ((!element.reference_from && element.reference_from !== 0) && (!element.reference_to && element.reference_to !== 0) && (!element.unit && element.unit !== 0) && (!element.description && element.description !== 0) && data.length > 1) {
          data.splice(index, 1)
          this.setState({
            ref_data: data
          })
        } else if (key?.result_type === 7) {
          if ((!element.reference_type && element.reference_type !== 0) && (!element.reference_range && element.reference_range !== 0) && (!element.unit && element.unit !== 0) && (!element.description && element.description !== 0) && data.length > 1) {
            data.splice(index, 1)
            this.setState({
              ref_data: data,
              ref_type: data
            })
          } else if ((!element.reference_type && element.reference_type !== 0) || (!element.reference_range && element.reference_range !== 0) || (!element.unit && element.unit !== 0)) {
            isInValid = true
          }
        } else if (((!element.reference_from && element.reference_from !== 0)) || (!element.reference_to && element.reference_to !== 0) || (!element.unit && element.unit !== 0)) {
          if(element?.reference_from?.includes('>') || element?.reference_from?.includes('<')){
            isInValid = false
          }else{
            isInValid = true
          }
        }
      })
      return isInValid
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  ValidateReferanceCheck = (data) => {
    try {
      let isInValid = false
      data?.forEach((element, index) => {
        if ((!element.reference_type && element.reference_type !== 0) && (!element.reference_range && element.reference_range !== 0) && (!element.unit && element.unit !== 0) && (!element.description && element.description !== 0) && data.length > 1) {
          data.splice(index, 1)
          this.setState({
            ref_type: data
          })
        } else if ((!element.reference_type && element.reference_type !== 0) || (!element.reference_range && element.reference_range !== 0) || (!element.unit && element.unit !== 0)) {
          isInValid = true
        }
      })
      return isInValid
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  checkValidation = () => {
    try {
      let error = ""
      let { editTestData, range_data, ref_data, isEdit, editRangeData, selectedEditIndex, isEditTestDetails, duplicateSelected } = this.state
      var RefEmptyCheck = this.ValidateREfCheck(ref_data)
      if (isEdit) {
        editTestData?.test_data.forEach((element, index) => {
          if ((element.gender === editRangeData.gender) && (index != selectedEditIndex)) {
            let value = [];
            for (let i = +element.age_from; i <= +element.age_to; i++) {
              value.push(i)
            }

            if ((value?.includes(+editRangeData.age_to)) && (value?.includes(+editRangeData.age_from)) && element.age_to_unit === editRangeData.age_from_unit && (element.age_from_unit === editRangeData.age_from_unit)) {
              error = 'Age Value Already Exist For This Gender'
            } else if ((value?.includes(+editRangeData.age_to)) && ((element.age_to_unit === editRangeData.age_to_unit) || (element.age_from_unit === editRangeData.age_to_unit))) {
              error = 'Age To Value Already Exist For This Gender'
            } else if ((value?.includes(+editRangeData.age_from)) && ((element.age_to_unit === editRangeData.age_from_unit) || (element.age_from_unit === editRangeData.age_from_unit))) {
              error = 'Age From Value Already Exist For This Gender'
            }

          } 
          // else if (!editRangeData.gender) {
          //   error = "Kindly Enter the Gender Value"
          // } else if ((!editRangeData.age_from && (editRangeData.age_from !== 0)) || (!editRangeData.age_to && (editRangeData.age_to !== 0))) {
          //   error = "Kindly Enter the Age Value"
          // } 
          else if ((editRangeData.age_from >= editRangeData.age_to) && ((editRangeData.age_from_unit === 'months' && editRangeData.age_to_unit === 'days') || (editRangeData.age_from_unit === 'years' && (editRangeData.age_to_unit === 'days' || editRangeData.age_to_unit === 'months')))) {
            error = "Age From Value Should greater than Age To"
          } 
          // else if (RefEmptyCheck && editTestData?.result_type === 1) {
          //   error = "Reference Values and Unit Should not be empty"
          // }
        });
      } else if (isEditTestDetails) {
        if (!editTestData?.print_order) {
          error = "Kindly Select Print Order"
        }
      }
      else {
        if (editTestData?.test_data != null && editTestData?.test_data != undefined) {
          editTestData?.test_data.forEach(element => {
            if(editTestData?.result_type === 5){
              let value = [];
              for (let i = +element.age_from; i <= +element.age_to; i++) {
                value.push(i)
              }

              if (range_data?.gender && ((!range_data.age_from && (range_data.age_from !== 0)) || (!range_data.age_to && (range_data.age_to !== 0)))) {
                error = "Kindly Enter the Age Value"
              } else if (range_data?.gender && (+range_data.age_from >= +range_data.age_to) && ((range_data.age_from_unit === 'months' && range_data.age_to_unit === 'days') || (range_data.age_from_unit === 'years' && (range_data.age_to_unit === 'days' || range_data.age_to_unit === 'months')))) {
                error = "Age From Value Should greater than Age To"
              }else{
                error = ''
              }

            }else{
              if (element.gender === range_data.gender && editTestData.result_type === JSON.parse(duplicateSelected)?.result_type) {
                let value = [];
                for (let i = +element.age_from; i <= +element.age_to; i++) {
                  value.push(i)
                }
  
                if ((value?.includes(+range_data.age_to)) && (value?.includes(+range_data.age_from)) && element.age_to_unit === range_data.age_from_unit && (element.age_from_unit === range_data.age_from_unit)) {
                  error = 'Age Value Already Exist For This Gender'
                } else if ((value?.includes(+range_data.age_to)) && ((element.age_to_unit === range_data.age_to_unit) || (element.age_from_unit === range_data.age_to_unit))) {
                  error = 'Age To Value Already Exist For This Gender'
                } else if ((value?.includes(+range_data.age_from)) && ((element.age_to_unit === range_data.age_from_unit) || (element.age_from_unit === range_data.age_from_unit))) {
                  error = 'Age From Value Already Exist For This Gender'
                }
              } 
              // else if (!range_data.gender) {
              //   error = "Kindly Enter the Gender Value"
              // } else if ((!range_data.age_from && (range_data.age_from !== 0)) || (!range_data.age_to && (range_data.age_to !== 0))) {
              //   error = "Kindly Enter the Age Value"
              // }
               else if ((range_data.age_from >= range_data.age_to) && ((range_data.age_from_unit === 'months' && range_data.age_to_unit === 'days') || (range_data.age_from_unit === 'years' && (range_data.age_to_unit === 'days' || range_data.age_to_unit === 'months')))) {
                error = "Age From Value Should greater than Age To"
              } 
              // else if (RefEmptyCheck && editTestData?.result_type === 1) {
              //   error = "Reference Values and Unit Should not be empty"
              // }
            }
            
          });
        }
      }

      return error
    } catch (e) {
      this.errorMessage(e.message)
    }
  }

  getNewReferrenceUnitData = () => {
    try {
      RestAPIService.getAll(Serviceurls.LAB_PRODUCT_UNIT)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              refUnitList: response.data.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error?.response?.data?.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  getNewTestData = () => {
    try {
      var { NewdepartmentName } = this.state
      RestAPIService.getAll(Serviceurls.NEW_LAB_RESULT_CONFIGURATION + "?dept_name=" + NewdepartmentName?.dept_name)
        .then((response) => {
          if (response.data.status === 'success') {
            var Data = response.data?.data ? response.data?.data : []
            var grpData = []
            Data[0]?.grp_data.forEach((grp) => {
              grpData.push(grp)
            })
            this.setState({
              NewgrpListData: grpData
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error?.response?.data?.message)
          } else {
            this.errorMessage(error?.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getNewReferrenceData = () => {
    let Grplength = []
    try {
      var { departmentName } = this.state
      this.LoaderFunction(true)
      RestAPIService.getAll(Serviceurls.NEW_LAB_RESULT_CONFIGURATION + "?dept_name=" + departmentName.dept_name)
        .then((response) => {
          if (response.data.status === 'success') {
            var Data = response.data?.data ? response.data?.data : []
            var grpData = []
            Data[0]?.grp_data.forEach((grp) => {
              grpData.push(grp)
            })
            for (let i = 0; i <= grpData?.length - 1; i++) {
              Grplength?.push(i + 1)
            }
            this.setState({
              resultConfigData: Data,
              duplicateResult: JSON.stringify(response.data.data),
              grpListData: grpData,
              groupName: null,
              testName: null,
              GrpLength: Grplength
            })
            this.LoaderFunction(false)
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error?.response?.data?.message)
            this.LoaderFunction(false)
          } else {
            this.errorMessage(error?.message)
            this.LoaderFunction(false)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  ValidationForNewTest = () => {
    try {
      let error = ""
      let { range_data, ref_type, isEdit, editRangeData, receivedData } = this.state
      if (receivedData?.result_type !== 5) {
         if(receivedData?.result_type === 7){
          // if (!range_data.gender) {
          //   error = "Kindly Enter the Gender Value"
          // } else if ((!range_data.age_from && (range_data.age_from !== 0)) || (!range_data.age_to && (range_data.age_to !== 0))) {
          //   error = "Kindly Enter the Age Value"
          // } else
           if ((+range_data.age_from >= +range_data.age_to) && ((range_data.age_from_unit === 'months' && range_data.age_to_unit === 'days') || (range_data.age_from_unit === 'years' && (range_data.age_to_unit === 'days' || range_data.age_to_unit === 'months')))) {
            error = "Age From Value Should greater than Age To"
          }else{
            // ref_type?.forEach(element => {
            //   if (element?.reference_type === '' || element?.reference_range === '' || element?.unit === '') {
            //     error = "Enter Reference Range and Unit"
            //   }else{
              error = ""
              // } 
            // })
          }
        } else {
          // if (!range_data.gender) {
          //   error = "Kindly Enter the Gender Value"
          // } else if ((!range_data.age_from && (range_data.age_from !== 0)) || (!range_data.age_to && (range_data.age_to !== 0))) {
          //   error = "Kindly Enter the Age Value"
          // } else 
          if ((+range_data.age_from >= +range_data.age_to) && ((range_data.age_from_unit === 'months' && range_data.age_to_unit === 'days') || (range_data.age_from_unit === 'years' && (range_data.age_to_unit === 'days' || range_data.age_to_unit === 'months')))) {
            error = "Age From Value Should greater than Age To"
          }else{
            error= ""
          }
        }        
      }else if(receivedData?.result_type === 5){
        if (range_data?.gender && ((!range_data.age_from && (range_data.age_from !== 0)) || (!range_data.age_to && (range_data.age_to !== 0)))) {
          error = "Kindly Enter the Age Value"
        } else if (range_data?.gender && (+range_data.age_from >= +range_data.age_to) && ((range_data.age_from_unit === 'months' && range_data.age_to_unit === 'days') || (range_data.age_from_unit === 'years' && (range_data.age_to_unit === 'days' || range_data.age_to_unit === 'months')))) {
          error = "Age From Value Should greater than Age To"
        }else{
          error = ''
        }
      }
      return error
    } catch (e) {
      this.errorMessage(e.message)
    }
  }

  cultureValidate = (data) => {
    let seen = new Set();
    for (const item of data) {
      let bacteriaData = JSON.stringify(item.BacteriaData);
      let antibioticData = JSON.stringify(item.AntibioticData);
      let uniqueKey = `${bacteriaData}-${antibioticData}`;
      if (seen.has(uniqueKey)) {
        return "Duplicate entries found for BacteriaData and AntibioticData.";
      }
      seen.add(uniqueKey);
    }
    return false;
  };

  NewpostNewReferenceData = () => {
    try {
      let { receivedData, testListData, ref_data, range_data, ref_type } = this.state
      let data = {}
      let Error = receivedData?.result_type === 3 ? this.cultureValidate(this.state.CulturalTestData) : this.ValidationForNewTest()
      if (Error) {
        this.errorMessage(Error)
      } else {
        var Details = []; var New_Details = "";let checkRef = false
        if (receivedData.result_type === 1) {
          ref_data?.forEach(element => {
            if (element?.reference_from === '' || element?.reference_to === '' || element?.unit === '') {
              if((element?.reference_from?.includes('>') || element?.reference_from?.includes('<')) && element.unit !== ''){
                checkRef = true
              }else{
                checkRef = false
              }
            }else{
              checkRef = true
            }
          })
          range_data.reference_data = ref_data
          Details.push(range_data)
        } else if (receivedData?.result_type === 7) {
          ref_data = ref_type
          range_data.reference_data = ref_data
          Details.push(range_data)
        } else if (receivedData.result_type !== 3) {
          range_data.reference_data = ref_data
          New_Details = [range_data]
        }

        // if (receivedData.result_type === 3 && (this.state.CulturalTestData[0].AntibioticData == null || this.state.CulturalTestData[0].BacteriaData == null)) {
        //   this.errorMessage("Mandatory Fields Should not be empty")
        // }
        // else if(receivedData?.result_type === 1 && !checkRef){
        //   this.errorMessage('Enter Reference Range and Unit')
        // }
        // else {
            data = {
              result_type: receivedData?.result_type,
              test_data: (receivedData.result_type === 3 && this.state.CulturalTestData[0].AntibioticData != null && this.state.CulturalTestData[0].BacteriaData != null) ? this.state.CulturalTestData : New_Details == "" ? Details : New_Details,
              method: this.state.nameMethod?.method_name,
              print_order: this.state.newPrintOrder,
              specimen: this.state.nameSpecimen?.specimen_name,
              specimen_master_id: this.state.nameSpecimen?.id,
              method_master_id: this.state.nameMethod?.id,
              test_amt: this.state.TestAmt,
              test_id: receivedData?.test_id,
              test_name: receivedData?.test_name
            }
            if(receivedData.result_type === 5){
              data['possible_result_items'] = JSON.parse(JSON.stringify(this.state.PossibleTestList))
            }
            this.setState({disableBtn: true})
            RestAPIService.create(data, Serviceurls.NEW_LAB_RESULT_CONFIGURATION)
              .then((response) => {
                if (response.data.status === 'success') {
                  this.successMessage(response.data.message)
                  this.getNewReferrenceData()
                  this.setState({ 
                    isEdit: false, 
                    selectedData: null, 
                    openPopUp: false, 
                    isEditTestDetails: false, 
                    NewEditTestDetails: false, 
                    groupName: null, 
                    containers: [{ id: 1 }], 
                    EditCultureTest: false, 
                    isEditCulture: false, 
                    selectedIndex: "", 
                    disableBtn: false })
                  this.resetNewReferrence()
                  this.handleClose()
                  this.ResetCultureReference()
                }
              }).catch((error) => {
                if (error?.response?.data?.message) {
                  this.errorMessage(error.response.data.message)
                } else {
                  this.errorMessage(error.message)
                }
                this.setState({disableBtn: false})
              })
        // }
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  postNewReferenceData = () => {
    try {
      let states = this.state
      let { editTestData, ref_data, ref_type, range_data, isEdit, editRangeData, selectedEditIndex, isEditTestDetails, NewEditTestDetails } = this.state
      let Error = editTestData?.result_type === 3 ? false : this.checkValidation()
      var data = {}
      if (Error) {
        this.errorMessage(Error)
      } else if (NewEditTestDetails) {
        data = {
          "dept_id": states.NewdepartmentName?.id || null,
          "dept_name": states.NewdepartmentName?.dept_name || '',
          "grp_id": states.NewgroupName?.grp_id || null,
          "grp_name": states.NewgroupName?.grp_name?.trim().replace(/\s+/g, ' ') || '',
          "grp_test_code": states.grpCode?.trim().replace(/\s+/g, ' ') || '',
          "grp_short_name": states.grpshortName?.trim().replace(/\s+/g, ' ') || '',
          "test_name": states.NewtestName?.trim().replace(/\s+/g, ' ') || '',
          "test_id": this.state.testId || null,
          "test_code": states.testCode?.trim().replace(/\s+/g, ' ') || '',
          "test_short_name": states.testshortName?.trim().replace(/\s+/g, ' ') || '',
          "result_type": states.NewresultType || '',
          "print_order": states.newPrintOrder || null,
          "is_billable": !states.isBillable,
          "loinc_code": states.loincCode?.trim().replace(/\s+/g, ' ') || '',
          "cpt_code": states.cptCode?.trim().replace(/\s+/g, ' ') || '',
          "sac": states.sacVal?.trim().replace(/\s+/g, ' ') || '',

          "specimen_master_id": states.nameSpecimen?.id || null,
          "specimen_quantity": states.specQty || null,
          "specimen_unit": states.Qtyunit || '',
          "specimen_temp": states.temperature || null,
          "specimen_temp_unit": states.Tempunit || '',
          "method_master_id": states.nameMethod?.id || null,
          "cut_off_time": `${states.cutOffHour || '00'}:${states.cutOffMin || '00'}` ,
          "analyser_master_id": states.nameAnalyzer?.id || null,

          "grp_amt": states.GroupAmt || 0,
          "test_amt": states.TestAmt || 0,
        }
        this.setState({disableBtn: true})
        RestAPIService.updateWithOutId(data, Serviceurls.LAB_ADD_NEW_TEST).then((response) => {
          if (response.data.status === 'success') {
            this.successMessage(response.data.message)
            this.getNewReferrenceData()
            this.setState({ isEdit: false, editTestData: null, openPopUp: false, isEditTestDetails: false, groupName: null, NewEditTestDetails: false, addTestPopup: false })
            this.resetNewReferrence()
            this.closePopup()
            this.handleClose()
            this.setState({disableBtn: false})
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
          this.setState({disableBtn: false})
        })

      } else {
        if (editTestData?.result_type == 3) {
          editTestData["test_data"] = this.state.CulturalTestList
          data = editTestData
        } else {
          if (isEdit) {
            if (editTestData?.result_type === 7) {
              const mergedData = ref_type.map((item, index) => {
                return {
                  reference_type: ref_type[index]?.reference_type,
                  reference_range: ref_type[index]?.reference_range,
                  paniclow: ref_type[index]?.paniclow,
                  panichigh: ref_type[index]?.panichigh,
                  unit: item.unit
                };
              });
              ref_type = mergedData
              editRangeData.reference_data = ref_type
              editTestData["test_data"][selectedEditIndex] = editRangeData
              data = editTestData
            } else if( editTestData?.result_type === 5){
              editRangeData.reference_data = ref_data
              editTestData["test_data"][selectedEditIndex] = editRangeData
              editTestData["possible_result_items"] = this.state.PossibleTestList
              data = editTestData
            }else {
              editRangeData.reference_data = ref_data
              editTestData["test_data"][selectedEditIndex] = editRangeData
              data = editTestData
            }
          } else if (isEditTestDetails) {
            data = editTestData
          } else {
            if (editTestData?.result_type === 7) {
              const mergedData = ref_data.map((item, index) => {
                return {
                  reference_type: ref_type[index].reference_type,
                  reference_range: ref_type[index].reference_range,
                  paniclow: ref_type[index].paniclow,
                  panichigh: ref_type[index].panichigh,
                  unit: item.unit
                };
              });
            
              ref_data = mergedData;
              range_data.reference_data = ref_data;
            
              if (!editTestData.test_data) {
                editTestData.test_data = [];
              }
            
              editTestData.test_data.push({ ...range_data }); // Add a copy of range_data
            
              data = editTestData;
            } else if( editTestData?.result_type === 5){
              range_data.reference_data = ref_data
              editTestData["test_data"][selectedEditIndex] = range_data
              editTestData["possible_result_items"] = this.state.PossibleTestList
              data = editTestData
            } else {
              let duplicateSelected = JSON.parse(this.state.duplicateSelected)
              if (editTestData.result_type === 1 && editTestData.result_type === duplicateSelected.result_type) {
                range_data.reference_data = ref_data
                editTestData.test_data.push(range_data)
                data = editTestData
              } else {
                if (editTestData?.test_data?.length > 0 && editTestData.result_type === duplicateSelected.result_type) {
                  range_data.reference_data = ref_data
                  editTestData.test_data.push(range_data)
                } else {
                  range_data.reference_data = ref_data
                  editTestData.test_data = [range_data]
                }
                data = editTestData
              }
            }
          }
        }
        this.setState({disableBtn: true})
        RestAPIService.create(data, Serviceurls.NEW_LAB_RESULT_CONFIGURATION).then((response) => {
          if (response.data.status === 'success') {
            this.successMessage(response.data.message)
            this.getNewReferrenceData()
            this.setState({ isEdit: false, editTestData: null, openPopUp: false, isEditTestDetails: false, groupName: null, NewEditTestDetails: false, EditCultureTest: false, isAddNewRef: false, addTestPopup: false, isEditRefData: false})
            this.resetNewReferrence()
            this.closePopup()
            this.handleClose()
            this.ResetCultureReference()
            this.setState({disableBtn: false})
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
          this.setState({disableBtn: false})
        })
      }
    } catch (error) {
      this.errorMessage(error.message)
      this.setState({disableBtn: false})
    }
  }

  createNewTest = () => {
    try {
      let data = {}
      let states = this.state
     
      data = {
        "dept_id": states.NewdepartmentName?.id || null,
        "grp_id": states.NewgroupName?.grp_id || null,
        "grp_name": states.NewgroupName.grp_name?.trim().replace(/\s+/g, ' ') || '',
        "grp_test_code": states.grpCode?.trim().replace(/\s+/g, ' ') || '',
        "grp_short_name": states.grpshortName?.trim().replace(/\s+/g, ' ') || '',
        "test_name": states.NewtestName?.trim().replace(/\s+/g, ' ') || '',
        "test_code": states.testCode?.trim().replace(/\s+/g, ' ') || '',
        "test_short_name": states.testshortName?.trim().replace(/\s+/g, ' ') || '',
        "result_type": states.NewresultType || '',
        "print_order": states.newPrintOrder || null,
        "is_billable": !states.isBillable,
        "loinc_code": states.loincCode?.trim().replace(/\s+/g, ' ') || '',
        "cpt_code": states.cptCode?.trim().replace(/\s+/g, ' ') || '',
        "sac": states.sacVal?.trim().replace(/\s+/g, ' ') || '',

        "specimen_master_id": states.nameSpecimen?.id || null,
        "specimen_quantity": states.specQty || null,
        "specimen_unit": states.Qtyunit || '',
        "specimen_temp": states.temperature || null,
        "specimen_temp_unit": states.Tempunit || '',
        "method_master_id": states.nameMethod?.id || null,
        "cut_off_time": `${states.cutOffHour || '00'}:${states.cutOffMin || '00'}`,
        "analyser_master_id": states.nameAnalyzer?.id || null,

        "grp_amt": states.GroupAmt || 0,
        "test_amt": states.TestAmt || 0,
      }
     
      this.setState({disableBtn: true})
      RestAPIService.create(data, Serviceurls.LAB_ADD_NEW_TEST)
        .then((response) => {
          if (response.data.status === 'success') {
            this.successMessage(response.data.message)
            this.getDeptList()
            this.setState({
              receivedData: {
                "result_type": response.data?.test_data?.result_type,
                "grp_name": response.data?.test_data?.grp_name,
                "test_name": response.data?.test_data?.test_name,
                "test_id": response.data?.test_data?.test_id,
              },
              isBillable: false,
              testCode:'',grpCode:'',hasCode:false
            }, () => { this.setState({ ViewScreen: 1, stepNumber: 1, disableBtn: false }) })
            if(response.data?.test_data?.result_type === 5){
               steps.push('Possible Test')
            }
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
          this.setState({disableBtn: false})
        })
    } catch (error) {
      this.errorMessage(error.message)
      this.setState({disableBtn: false})
    }
  }

  putNewReferenceData = () => {
    try {
      var { resultConfigData } = this.state
      let printOrder = []
      let groupData = []

      resultConfigData[0]?.grp_data.forEach((grp, index) => {
        groupData.push({ "grp_id": grp.grp_id, "print_order": grp.print_order })
        printOrder.push(grp.print_order)
      })
      var data = {
        "dept_id": resultConfigData[0]?.dept_id,
        "grp_data": groupData
      }

      //validation for duplicate print order

      const unique = Array.from(new Set(printOrder))

      if (unique?.length != printOrder?.length) {
        this.errorMessage("Multiple Group has same Print Order")
      } else {
        RestAPIService.updateWithOutId(data, Serviceurls.NEW_LAB_RESULT_CONFIGURATION)
          .then((response) => {
            if (response.data.status === 'success') {
              this.successMessage(response.data.message)
              this.getNewReferrenceData()
              this.setState({
                isPrintOrderEdit: false
              })
            }
          }).catch((error) => {
            if (error?.response?.data?.message) {
              this.errorMessage(error.response.data.message)
            } else {
              this.errorMessage(error.message)
            }
          })
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  deleteNewReferenceData = (testData, refIndex) => {
    try {
      testData?.test_data.splice(refIndex, 1)

      RestAPIService.create(testData, Serviceurls.NEW_LAB_RESULT_CONFIGURATION)
        .then((response) => {
          if (response.data.status === 'success') {
            this.successMessage(response.data.message)
            this.getNewReferrenceData()
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  newReferrencePopup = () => {
    try {
      let { selectedData, ref_data, range_data, editRangeData } = this.state
      let disable = (this.state.isEdit || !this.state.isEditTestDetails) ? true : false
      let editTestDetails = this.state.isEditTestDetails ? true : false
      let showCode = localGetItem('lab_test_code') ? JSON.parse(localGetItem('lab_test_code')) : JSON.parse(localGetItem('loggedInUserInfo'))?.lab_test_code
      return (
        <Modal open={this.state.openPopUp}>
          <Box component={'div'} className='eMed_lab_rest_New_Popup'>

            <Box component={'div'} sx={{ backgroundColor: Colors.Background, display: 'flex', alignItems: 'center' }} pl={'0.5vw'} height={'2.5vw'}>
              <Typography flex={'0.5'} component={'div'} fontWeight={600} color={Colors.SecondaryText}>Add New Reference</Typography>
              <Box component={'div'} flex={'0.5'} display={'flex'} marginRight={'1vw'} alignItems={'center'} justifyContent={'flex-end'} >
                <Button className='eMed_Lab_act_btn' onClick={() => {
                  this.setState({ isChange:false })
                  this.handleClosePopup()
                  }}>
                  <Box component={'img'} src={ImagePaths.LabCloseButton.default} alt='close' height={'1vw'} width={'1vw'} />
                </Button>
              </Box>
            </Box>
            <Box>
              {this.state.NewEditTestDetails ?
                <Box component={'div'} style={{ display: "flex", flexDirection: "row", margin: "1vw" }}>
                  <TextField
                    sx={{ width: '12vw', marginBottom: "0.5vw", marginRight: "0.5vw" }}
                    disable={true}
                    size='small'
                    label="Enter Test Name"
                    value={this.state.EditTestName}
                    variant="outlined"
                    onChange={(e) => {
                      this.setState({ EditTestName: e.target.value })
                    }} />
                  <TextField
                    sx={{ width: '12vw', marginBottom: "0.5vw",marginRight: "0.5vw"  }}
                    size='small'
                    label="Test Amount"
                    value={this.state.EditTestAmt}
                    variant="outlined"
                    onChange={(e) => {
                      if (CommonValidation.numberOnly(e.target.value) || e.target.value === '') {
                        this.setState({ EditTestAmt: e.target.value,isChange:true })
                      }
                    }} />
                  {showCode ?
                    <TextField
                      sx={{ width: '9vw', marginBottom: "0.5vw" }}
                      size='small'
                      label="Test Code"
                      value={this.state.EditTestCode}
                      variant="outlined"
                      onChange={(e) => {
                        let value = e.target.value
                        if ((value?.length <= Code_Length) || value === '') {
                          this.setState({ EditTestCode: value })
                        }
                      }} /> : null}
                  <FormControlLabel style={{ marginLeft: "0.5vw", marginTop: "-0.5vw" }} control={<Checkbox checked={!this.state.EditIsBillable} onChange={(e) => { this.setState({ EditIsBillable: !this.state.EditIsBillable }) }} />} label={"IS BILLABLE"} />
                </Box> : null}
              <FormHelperText sx={{ color: 'red', fontWeight: 600,ml:'1vw' }}>
                {this.state.hasShare ? this.state.isChange ? 'Existing Doctor Share configuration for this Test will be deleted.You need to reconfigure it' : '' : null}
              </FormHelperText>
              <Box component={'div'} margin={'1vw'} borderBottom={'1px solid lightgray'} display={'flex'}>

                {this.inputTextBox('specimen', selectedData?.specimen, 'Specimen', '11vw', '0.5vw', null, disable)}
                {this.inputTextBox('method', selectedData?.method, 'Method', '11vw', '0.5vw', null, disable)}

                <FormControl size='small' sx={{ width: '11vw', marginRight: '0.5vw', marginBottom: '0.5vw', mt: '0.5vw' }}>
                  <InputLabel>Result Type</InputLabel>
                  <Select
                    disabled={editTestDetails || this.state.isEdit}
                    label='Result Type'
                    value={selectedData?.result_type}
                    onChange={(e) => { this.onChangeHandler(e, 'resultType') }}
                  >
                    {Result_Type_List?.map((item) => (
                      <MenuItem disabled={item.value == 3} value={item.value}>{item.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <Autocomplete
                  disabled={disable}
                  value={selectedData?.print_order}
                  options={this.state.testNameLength.map((item) => item)}
                  getOptionLabel={(option) => option}
                  onChange={(event, newValue) => {
                    selectedData.print_order = newValue
                    this.setState({ selectedData })
                  }}
                  renderInput={(params) => (<TextField {...params} size='small' sx={{ width: '7vw', mt: '0.5vw' }} label='Print No.' />)}
                />

              </Box>

              {this.state.selectedData?.result_type == 3 ? null :
                <div>
                  <Box component={'div'} margin={'1vw'} borderBottom={'1px solid lightgray'} display={'flex'}>

                    <Box mr={'0.5vw'}>
                      <FormControl size='small' sx={{ width: '13vw', marginBottom: '1vw', mt: '0.5vw' }}>
                        <InputLabel>Gender</InputLabel>
                        <Select
                          disabled={editTestDetails}
                          label='Gender'
                          value={this.state.isEdit ? editRangeData?.gender : range_data?.gender}
                          onChange={(e) => { this.onChangeHandler(e, 'gender') }}
                        >
                          {Gender_List?.map((item) => (
                            <MenuItem value={item.value}>{item.label}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>

                    <Box mr={'0.5vw'}>
                      {this.inputTextBox('ageFrom', this.state.isEdit ? editRangeData?.age_from : range_data.age_from, "Age From", '7.8vw', null, null, editTestDetails)}
                      <FormControl size='small' sx={{ width: '6vw', marginBottom: '1vw', mt: '0.5vw' }}>
                        <Select
                          disabled={editTestDetails}
                          value={this.state.isEdit ? editRangeData?.age_from_unit : range_data?.age_from_unit}
                          onChange={(e) => { this.onChangeHandler(e, 'fromDays') }}
                        >
                          {Days_List?.map((item) => (
                            <MenuItem value={item.value}>{item.label}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>

                    <Box mr={'0.5vw'}>
                      {this.inputTextBox('ageTo', this.state.isEdit ? editRangeData?.age_to : range_data.age_to, "Age To", '7.8vw', null, null, editTestDetails)}
                      <FormControl size='small' sx={{ width: '6vw', marginBottom: '1vw', mt: '0.5vw' }}>
                        <Select
                          disabled={editTestDetails}
                          value={this.state.isEdit ? editRangeData?.age_to_unit : range_data.age_to_unit}
                          onChange={(e) => { this.onChangeHandler(e, 'toDays') }}
                        >
                          {Days_List?.map((item) => (
                            <MenuItem value={item.value}>{item.label}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>

                  </Box>

                  <Box component={'div'} ml={'1vw'} paddingTop={'0.5vw'} overflow={'scroll'} maxHeight={'20vw'}>

                    {this.state.selectedData?.result_type === 7 ?
                      <div>
                        {this.state.ref_data.map((item, index) => {
                          return (
                            <>
                              <Box key={index} display={'flex'}>
                                <FormControl disabled={editTestDetails} size='small' sx={{ width: '5vw', marginBottom: '1vw', mt: '0.5vw', marginRight: "0.5vw" }}>
                                  <InputLabel>Reference Type</InputLabel>
                                  <Select
                                    label='Reference Type'
                                    value={item?.reference_type}
                                    onChange={(e) => { this.onChangeHandler(e, 'referanceType', index) }}
                                  >
                                    {Referance_Type_List?.map((item) => (
                                      <MenuItem style={{ textAlign: "center", fontSize: "1vw" }} value={item.value}>{item.label}</MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                                {this.inputTextBox('refRange', item.reference_range, "Referance Range", '9.4vw', '0.5vw', index, editTestDetails)}
                                <Autocomplete
                                  disableClearable
                                  freeSolo
                                  disabled={editTestDetails}
                                  value={item.unit}
                                  options={this.state.refUnitList?.length != 0 ? this.state.refUnitList : []}
                                  getOptionLabel={option => typeof (option) === "string" ? option : option.unit}
                                  onChange={(event, newValue) => {
                                    ref_data[index].unit = newValue.unit
                                    this.setState({ ref_data })
                                  }}
                                  onInputChange={(e, val) => {
                                    ref_data[index].unit = val
                                    this.setState({ ref_data })
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      sx={{ width: '9.5vw', mt: '0.5vw', mr: '0.5vw' }}
                                      label={'Unit'}
                                      size="small"
                                      InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                      }}
                                    />
                                  )}
                                />

                                {this.inputTextBox('description', item.description, "Description", '18.5vw', '0.5vw', index, editTestDetails)}
                                {this.state.ref_data?.length > 1 ?
                                  <Button className='eMed_Lab_act_btn' onClick={() => {
                                    var { ref_data } = this.state
                                    ref_data.splice(index, 1)
                                    this.setState({
                                      ref_data
                                    })
                                  }}>
                                    <Box component={'img'} src={ImagePaths.Delete.default} alt='close' height={'1vw'} width={'1vw'} />
                                  </Button>
                                  : null}
                              </Box>
                            </>
                          )
                        })}
                      </div> :
                      <div>
                        {this.state.ref_data.map((item, index) => {
                          return (
                            <>
                              <Box key={index} display={'flex'}>
                                {this.inputTextBox('rangeFrom', item.reference_from, "Range From", '6vw', '0.5vw', index, editTestDetails)}
                                {this.inputTextBox('rangeTo', item.reference_to, "Range To", '6vw', '0.5vw', index, editTestDetails)}
                                <Autocomplete
                                  disableClearable
                                  freeSolo
                                  disabled={editTestDetails}
                                  value={item.unit}
                                  options={this.state.refUnitList?.length != 0 ? this.state.refUnitList : []}
                                  getOptionLabel={option => typeof (option) === "string" ? option : option.unit}
                                  onChange={(event, newValue) => {
                                    ref_data[index].unit = newValue.unit
                                    this.setState({ ref_data })
                                  }}
                                  onInputChange={(e, val) => {
                                    ref_data[index].unit = val
                                    this.setState({ ref_data })
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      sx={{ width: '9.5vw', mt: '0.5vw', mr: '0.5vw' }}
                                      label={'Unit'}
                                      size="small"
                                      InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                      }}
                                    />
                                  )}
                                />

                                {this.inputTextBox('description', item.description, "Description", '18.5vw', '0.5vw', index, editTestDetails)}
                                {this.state.ref_data?.length > 1 ?
                                  <Button className='eMed_Lab_act_btn' onClick={() => {
                                    var { ref_data } = this.state
                                    ref_data.splice(index, 1)
                                    this.setState({
                                      ref_data
                                    })
                                  }}>
                                    <Box component={'img'} src={ImagePaths.Delete.default} alt='close' height={'1vw'} width={'1vw'} />
                                  </Button>
                                  : null}

                              </Box>
                              {index === editRangeData?.ref_data?.length - 1 ?
                                <Divider>
                                  <Typography fontSize={'0.8vw'} color={'gray'}>New Parameters</Typography>
                                </Divider> : null}
                            </>
                          )
                        })}
                      </div>}

                    {selectedData?.result_type === 1 || selectedData?.result_type === 7 ?
                      <Box display={'flex'} justifyContent={'flex-end'}>
                        <Button
                          disabled={editTestDetails}
                          startIcon={<AddCircleIcon />}
                          className='eMed_Lab_act_btn'
                          sx={{ textTransform: 'capitalize' }}
                          onClick={() => { this.onAddNewParameter(selectedData) }}
                        >Add New Parameter</Button>
                      </Box> : null
                    }

                  </Box>
                </div>}
            </Box>

            <Box component={'div'} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} sx={{ backgroundColor: Colors.Background }} height={'2.5vw'}>
              <Button
                sx={{ width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
                size='small'
                className='eMed_Lab_act_btn'
                variant='outlined'
                onClick={() => {
                  this.setState({ isChange:false })
                  this.resetNewReferrence()
                }}
              >Reset</Button>
              <Button
                sx={{ width: '5vw', textTransform: 'capitalize', mr: "1vw" }}
                size='small'
                className='eMed_Lab_act_btn'
                variant='contained'
                emed_tid='ResultConfig_TestName_Edit_Save'
                disabled={this.state.disableBtn}
                onClick={() => {
                  this.setState({ isChange:false })
                  this.postNewReferenceData()
                }}
              >Save</Button>
            </Box>

          </Box>
        </Modal>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderDropdown(label, Key, List, LabelKey, ValueKey, disable = false, width) {
    let states = this.state;
    try {
      return (
        <Autocomplete
          disabled={disable}
          noOptionsText={'No Data Found...'}
          options={List}
          getOptionLabel={(options) => typeof (options) === "string" ? options : options[LabelKey]}
          onChange={(e, value) => {
            states[Key] = value[ValueKey]
            this.setState({ states })
          }}
          clearIcon={false}
          size='small'
          sx={{ width: width, marginTop: '0.8vw' }}
          value={List.find((item) => (item[ValueKey] === states[Key])) ? List.find((item) => (item[ValueKey] === states[Key])) : null}
          renderInput={(params) => <TextField sx={{ width: width }} label={label} {...params} />}
        />
      )
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  popupClose() {
    this.setState({
      deletePopup: false
    })
  }

  addCancelComments(comments) {
    var states = this.state;
    var data = {}

    try {
      if (this.state.DltTitle == "Department") {
        data = `?dept_id= ${states.selectedvalue} &reason= ${comments}`
      } else if (this.state.DltTitle == "Group") {
        data = `?grp_id= ${states.selectedvalue} &reason= ${comments}`
      } else { data = `?test_id=${states.selectedvalue}&reason=${comments}` }
      let URL = (this.state.DltTitle == "Department" || this.state.DltTitle == "Group") ? Serviceurls.LAB_GRP_EDIT_DELETE + data : Serviceurls.LAB_ADD_NEW_TEST + data
      this.setState({disableBtn: true})
      RestAPIService.delete(URL)
        .then((response) => {
          if (response.data.status === 'success') {
            this.successMessage(response.data.message)
            this.setState({ deletePopup: false ,grpCode:'',testCode:''}, () => { this.getDeptList() })
            this.handleClose()
            this.setState({disableBtn: false})
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
          this.setState({disableBtn: false})
        })
    } catch (error) {
      this.errorMessage(error.message)
      this.setState({disableBtn: false})
    }
  }

  handleWarning = () => {
    this.setState({
      DltTitle: "Test",
      selectedvalue: this.state.receivedData?.test_id,
    }, () => { this.setState({ deletePopup: true }) })
  }

  handleClose = () => {
    this.setState({
      addTestPopup: false,
      GroupAmt: 0,
      TestAmt: 0,
      NewdepartmentName: null,
      NewgroupName: null,
      NewSpecimen: "",
      NewtestName: "",
      NewMethod: "",
      NewresultType: "",
      newPrintOrder: null,
      NewgrpListData: [],
      stepNumber: 0,
      ViewScreen: 0,
      grpCode:'',
      testCode:'',
      grpshortName: '',
      testshortName: '',
      loincCode: '',
      cptCode: '',
      sacVal: '',
      nameSpecimen: null,
      specQty: '',
      Qtyunit: null,
      temperature: '',
      Tempunit: null,
      nameMethod: null,
      nameAnalyzer: null,
      isEditTestDetails: false,
      editGrpData: {},
      editTestData: {},
      isAddNewRef: false,
      isEditRefData: false,
      PossibleTestList: [],
      editRangeData: null,
      isEdit: false,
      cutOffHour: null,
      cutOffMin: null,
      PossibleResult: "",
      PossibleTestComments: "",
      PossibleTestStatus: "Abnormal",
      PossibleTestList: [],
      PossibleEditId: null,
      resultConfigData: JSON.parse(this.state.duplicateResult),
      groupName: null,
    })
  }

  closePopup = () => {
    this.setState({
      EditTitle: "",
      EditPopup: false,
      EditDepName: "",
      EditGrpName: "",
      EditGrpAmt: "",
      EditDepId: "",
      EditGrpId: "",
      EditPrintOrder: "",
      isEditDepart: false,
      isEditGrp: false,
      EditTestName: "",
      EditTestAmt: "",
      EditTestId: "",
      EditIsBillable: false,
      EditGrpCode:'',
      EditTestCode:''
    })
  }

  UpdateDeatails = () => {
    let data = {};
    try {
      if (this.state.isEditDepart) {
        if (this.state.DepPrintOrder == null || this.state.EditDepName == "") {
          this.errorMessage("Department details should Not be empty");
        } else {
          data = { dept_id: this.state.EditDepId, dept_name: this.state.EditDepName, print_order: this.state.DepPrintOrder }
          this.setState({disableBtn: true})
          RestAPIService.patch(data, Serviceurls.LAB_GRP_EDIT_DELETE)
            .then(response => {
              if (response.data.status === "success") {
                this.successMessage(response.data.message);
                this.closePopup()
                this.getDeptList()
                this.setState({disableBtn: false})
              }
            })
            .catch(error => {
              if (error?.response?.data?.status === "fail") {
                this.errorMessage(error.response.data.message);
                this.setState({disableBtn: false})
              }
            });
        }
      } else if (this.state.isEditGrp) {
        if (this.state.EditGrpName == "" || this.state.EditGrpAmt == "") {
          this.errorMessage("Group details should Not be empty");
        } else {
          data = { dept_id: this.state.EditDepId, grp_id: this.state.EditGrpId, grp_name: this.state.EditGrpName, grp_amt: this.state.EditGrpAmt, print_order: this.state.EditPrintOrder,grp_test_code:this.state.EditGrpCode, grp_short_name: this.state.EditgrpShortname || '',}
          this.setState({disableBtn: true})
          RestAPIService.updateWithOutId(data, Serviceurls.LAB_GRP_EDIT_DELETE)
            .then(response => {
              if (response.data.status === "success") {
                this.successMessage(response.data.message);
                this.closePopup()
                this.getDeptList()
                this.setState({disableBtn: false})
              }
            })
            .catch(error => {
              if (error?.response?.data?.status === "fail") {
                this.errorMessage(error.response.data.message);
                this.setState({disableBtn: false})
              }
            });
        }
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  EditDialogBox = () => {
    let showCode = localGetItem('lab_test_code') ? JSON.parse(localGetItem('lab_test_code')) : JSON.parse(localGetItem('loggedInUserInfo'))?.lab_test_code

    return (
      <Dialog open={this.state.EditPopup}>
        <div className="eMed_DialogBox_Title" style={{ padding: '0.5vw' }}>
          <div>{this.state.isEditGrp ? "EDIT GROUP DETAILS" : "EDIT DEPARTMENT DETAILS"}</div>
          <div className="eMed_DialogBox_Close">
            <img className="eMed_DeletePopup_CrossIcon" src={ImagePaths.LabCloseButton.default} alt='Close' onClick={() => {
              this.setState({isChange:false})
               this.closePopup()
               }} />
          </div>
        </div>
        <DialogContent sx={{ width: "35vw" }}>
          {this.state.isEditDepart ?
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <TextField
                sx={{ width: '18vw', marginRight: "0.5vw" }}
                size='small'
                disabled={true}
                label="Enter Department Name"
                value={this.state.EditDepName}
                variant="outlined"
                onChange={(e) => {
                  this.setState({ EditDepName: e.target.value })
                }} />
              <Autocomplete
                value={this.state.DepPrintOrder}
                options={this.state.DepartLength.map((item) => item)}
                getOptionLabel={(option) => option}
                onChange={(event, newValue) => {
                  this.setState({ DepPrintOrder: newValue })
                }}
                renderInput={(params) => (<TextField {...params} size='small' sx={{ width: '16vw' }} label='Print No.' />)}
              />
            </div>
            : null}
          {this.state.isEditGrp ?
            <div>
              <div style={{ fontSize: "1vw", color: Colors.grayShade }}>Department and Group Details</div>
              <Box component={'div'} style={{ display: "flex", flexDirection: "row", marginTop: "0.75vw" }}>
                <TextField
                  disabled={true}
                  size='small'
                  sx={{ width: '14vw', marginRight: "0.5vw" }}
                  label="Enter Department Name"
                  value={this.state.EditDepName}
                  variant="outlined"
                  onChange={(e) => {
                    this.setState({ EditDepName: e.target.value })
                  }} />
                <TextField
                  sx={{ width: '14vw', marginBottom: "0.5vw" , marginRight: "0.5vw"}}
                  size='small'
                  label="Enter Group Name"
                  value={this.state.EditGrpName}
                  variant="outlined"
                  onChange={(e) => {
                    this.setState({ EditGrpName: e.target.value })
                  }} />
                {showCode ?
                  <TextField
                    sx={{ width: '10vw', marginBottom: "0.5vw" }}
                    size='small'
                    label="Group Code"
                    value={this.state.EditGrpCode}
                    variant="outlined"
                    onChange={(e) => {
                      if(e.target.value?.length <= Code_Length || e.target.value === ''){
                        this.setState({ EditGrpCode: e.target.value })
                      }
                    }} /> : null}
              </Box>
              <Box component={'div'} style={{ display: "flex", flexDirection: "row", marginTop: "0.75vw" }}>
              <TextField
                    sx={{ width: '10vw', marginBottom: "0.5vw" }}
                    size='small'
                    label="Group Short Name"
                    value={this.state.EditgrpShortname}
                    variant="outlined"
                    onChange={(e) => {
                      if (CommonValidation.ALPHA_NUMARIC(e.target.value) || e.target.value === '') {
                        this.setState({ EditgrpShortname: e.target.value })
                      }
                    }} /> 
              </Box>
              <div style={{ fontSize: "1vw", color: Colors.grayShade }}>Group Amount</div>
              <Box component={'div'} style={{ display: "flex", flexDirection: "row", marginTop: "0.75vw" }}>
                <TextField
                  sx={{ width: '15.7vw', marginTop: "0.75vw", marginRight: "0.5vw" }}
                  size='small'
                  label="Group Amount"
                  value={this.state.EditGrpAmt}
                  variant="outlined"
                  onChange={(e) => {
                    if (CommonValidation.numberOnly(e.target.value) || e.target.value === '') {
                      this.setState({ EditGrpAmt: e.target.value ,isChange:true})
                    }
                  }} />
                <Autocomplete
                  value={this.state.EditPrintOrder}
                  options={this.state.GrpLength.map((item) => item)}
                  getOptionLabel={(option) => option}
                  onChange={(event, newValue) => {
                    this.setState({ EditPrintOrder: newValue })
                  }}
                  renderInput={(params) => (<TextField {...params} size='small' sx={{ width: '16vw', marginTop: "0.75vw" }} label='Print No.' />)}
                />
              </Box>
              <FormHelperText sx={{color:'red',fontWeight:600}}>
                {this.state.hasShare ? this.state.isChange ? 'Existing Doctor Share configuration for this group will be deleted.You need to reconfigure it' : '' : null}
              </FormHelperText>
            </div>
            : null}
        </DialogContent>
        <DialogActions id="eMed_DialogBox_Btn">
          <Button size="small" variant={'outlined'} id="eMed_Dia_Btn" color="error" onClick={() => { 
            this.setState({isChange:false})
            this.closePopup() 
            }}>
            {"CANCEL"}
          </Button>
          <Button color={this.props.btncolor} size="small" variant={'contained'} id="eMed_Dia_Btn" emed_tid='ResultConfig_Edit_Save_Btn' disabled={this.state.disableBtn} onClick={() => { 
            this.setState({isChange:false})
            this.UpdateDeatails()
             }}>
            {"SAVE"}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  onSave = () => {
    var inValid = this.validCheck()
    if (inValid) {
      this.errorMessage(inValid)
    } else {
      this.updateCultureReference()
    }
  }

  validCheck = () => {
    let { CulturalTestData, CulturalTestList } = this.state
    var saveErrorMsg = ''
    var states = this.state
    const isDuplicate = CulturalTestList.some(
      (detail) => detail.BacteriaData?.organism_name === CulturalTestData?.BacteriaData?.organism_name && detail.AntibioticData?.antibiotic_name === CulturalTestData?.AntibioticData?.antibiotic_name
    );
    if (!isDuplicate || (states.selectedIndex === 0 ? true : states.selectedIndex == "" ? false : states.selectedIndex != "")) {
      if (CulturalTestData?.BacteriaData == null) {
        saveErrorMsg = ('Select Bacteria Name')
      } else if (CulturalTestData?.AntibioticData == null) {
        saveErrorMsg = ('Select Anti-Biotic Name')
      } 
      // else if ((CulturalTestData?.BacteriaData != null && CulturalTestData?.AntibioticData != null) &&
      //   ((CulturalTestData?.zone_size_s == undefined && CulturalTestData?.zone_size_i == undefined && CulturalTestData?.zone_size_r == undefined &&
      //     CulturalTestData?.mic_s == undefined && CulturalTestData?.mic_i == undefined && CulturalTestData?.mic_r == undefined))) {
      //   saveErrorMsg = ('Enter Zone Size Value or MIC Break Point Value')
      // }
    } else {
      saveErrorMsg = ('This Bacteria and Anti-Biotic Combination Already Exist')
    }
    return saveErrorMsg
  }

  updateCultureReference = () => {
    let { CulturalTestData, CulturalTestList } = this.state
    let data = []
    if (CulturalTestData?.AntibioticData != null && CulturalTestData?.BacteriaData != null) {
      data = {
        AntibioticData: CulturalTestData?.AntibioticData,
        BacteriaData: CulturalTestData?.BacteriaData,
        zone_size_s: CulturalTestData?.zone_size_s,
        zone_size_i: CulturalTestData?.zone_size_i,
        zone_size_r: CulturalTestData?.zone_size_r,
        mic_s: CulturalTestData?.mic_s,
        mic_i: CulturalTestData?.mic_i,
        mic_r: CulturalTestData?.mic_r,
      }
      if (this.state.isEditCulture) {
        CulturalTestList[this.state.selectedIndex] = data
      } else {
        CulturalTestList = [...CulturalTestList, data]
      }
      this.setState({
        CulturalTestList,
        isEditCulture: false,
        selectedIndex: "",
        CulturalTestData: [
          {
            AntibioticData: null,
            BacteriaData: null,
            zone_size_s: "",
            zone_size_i: "",
            zone_size_r: "",
            mic_s: "",
            mic_i: "",
            mic_r: "",
          }
        ]
      })
    } else {
      this.errorMessage("Please Select the Mandatory Fields")
    }
  }

  ResetCultureReference = () => {
    this.setState({
      CulturalTestData: [
        {
          AntibioticData: null,
          BacteriaData: null,
          zone_size_s: "",
          zone_size_i: "",
          zone_size_r: "",
          mic_s: "",
          mic_i: "",
          mic_r: "",
        }
      ]
    })
  }

  handleEdit = (item, index) => {
    this.setState({
      CulturalTestData:
      {
        AntibioticData: item?.AntibioticData,
        BacteriaData: item?.BacteriaData,
        zone_size_s: item?.zone_size_s,
        zone_size_i: item?.zone_size_i,
        zone_size_r: item?.zone_size_r,
        mic_s: item?.mic_s,
        mic_i: item?.mic_i,
        mic_r: item?.mic_r,
      },
      isEditCulture: true,
      selectedIndex: index
    });
  }

  handleDelete = (index) => {
    this.setState(prevState => {
      const updatedItems = [...prevState.CulturalTestList];
      updatedItems.splice(index, 1);
      return { CulturalTestList: updatedItems };
    });
  }

  EditCulturalTestBox = () => {
    let states = this.state
    try {
      return (
        <Modal open={true}>
          <Paper elevation={3} id="emedhub_popup_mainBox" sx={{ width: "60vw", height: "90vh" }}>
            <div id="emedhub_popup_header" style={{ backgroundColor: Colors.DataTblHeaderbg }}>
              <Typography sx={{ color: "#616161", fontWeight: 600 }}>{"Blood Culture Test"}</Typography>
              <IconButton onClick={() => { this.setState({ EditCultureTest: false, isEdit: false }, () => { this.ResetCultureReference(); this.handleClose() }) }} size="small" ><img className="eMed_DeletePopup_CrossIcon" src={ImagePaths.LabCloseButton.default} alt={"close"} /></IconButton>
            </div>
            <Box sx={{display: 'flex', flexDirection: 'row'}}>
            <div>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", padding: "0.75vw" }}>
                <Autocomplete
                  size='small'
                  options={this.state.BacteriaList}
                  getOptionLabel={(option) => (option?.organism_name)}
                  value={this.state?.CulturalTestData?.BacteriaData ? this.state?.CulturalTestData?.BacteriaData : null}
                  onChange={(event, newValue) => {
                    states.CulturalTestData.BacteriaData = newValue
                    states.CulturalTestData.AntibioticData = ''
                    this.setState({ states },()=>{this.getAntiBacteriaList(newValue)})
                  }}
                  renderInput={(params) => (
                    <TextField {...params} style={{ width: "15vw", marginRight: "0.65vw" }}
                      label={'Bacteria Name*'}
                    />
                  )}
                />
                <div style={{ fontSize: ".9vw", marginRight: "1vw" }}>Zone Size (mm)</div>
                {this.renderTextBox("S", '8.5vw', states?.CulturalTestData?.zone_size_s, 'zone_size_s')}
                {this.renderTextBox("I", '8.5vw', states?.CulturalTestData?.zone_size_i, 'zone_size_i')}
                {this.renderTextBox("R", '8.5vw', states?.CulturalTestData?.zone_size_r, 'zone_size_r')}
              </div>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", padding: "0.75vw", marginTop: "-0.5vw" }}>
                <Autocomplete
                  size='small'
                  options={this.state?.AntibioticList}
                  disabled={!this.state?.CulturalTestData?.BacteriaData}
                  getOptionLabel={(option) => (option?.antibiotic_name)}
                  value={this.state?.CulturalTestData?.AntibioticData ? this.state?.CulturalTestData?.AntibioticData : null}
                  onChange={(event, newValue) => {
                    states.CulturalTestData.AntibioticData = newValue
                    this.setState({ states })
                  }}
                  renderInput={(params) => (
                    <TextField {...params} style={{ width: "15vw", marginRight: "0.65vw" }}
                      label={'Antibiotic Name*'}
                    />
                  )}
                />
                <div style={{ fontSize: ".9vw", marginRight: "0.75vw" }}>MIC Break Point</div>
                {this.renderTextBox("S", '8.5vw', states?.CulturalTestData?.mic_s, 'mic_s')}
                {this.renderTextBox("I", '8.5vw', states?.CulturalTestData?.mic_i, 'mic_i')}
                {this.renderTextBox("R", '8.5vw', states?.CulturalTestData?.mic_r, 'mic_r')}
              </div>
            </div>
            <div style={{ display: 'flex',  flexDirection: 'column', padding: "0.75vw", borderLeft: '2px solid lightgray', marginTop: '.5vw', marginBottom: '.5vw' }}>
                <Button variant="contained" size="small" onClick={() => { this.onSave() }}>{"ADD TEST"}</Button>
                <Button size="small" sx={{ marginTop: "1vw" }} variant={"outlined"} onClick={() => { this.ResetCultureReference() }}>{"RESET"}</Button>
            </div>
            </Box>
            <div>
              <Paper elevation={0}>
                <TableContainer style={{ height: "58vh", overflowY: 'scroll' }}>
                  <Table stickyHeader size='small'>
                    <TableHead sx={{ height: '7.3vh' }}>
                      <TableRow>
                        <TableCell id='eMed_config_TableRow1' align='left' sx={{ color: Colors.HeadText }} >{'SNo'}</TableCell>
                        <TableCell id='eMed_config_TableRow' align='left' sx={{ color: Colors.HeadText }}>{"Bacteria"}</TableCell>
                        <TableCell id='eMed_config_TableRow' align='left' sx={{ color: Colors.HeadText }}>{"Antibiotic"}</TableCell>
                        <TableCell id='eMed_config_TableRow' align='center' sx={{ color: Colors.HeadText }}>
                          <div style={{ alignItems: "center", justifyContent: "center" }}>
                            <div>{"Zone size"}</div>
                            <div>{"S | I | R"}</div>
                          </div>
                        </TableCell>
                        <TableCell id='eMed_config_TableRow' align='center' sx={{ color: Colors.HeadText }}>
                          <div style={{ alignItems: "center", justifyContent: "center" }}>
                            <div>{"MIC"}</div>
                            <div>{"S | I | R"}</div>
                          </div>
                        </TableCell>
                        <TableCell id='eMed_config_TableRow' align='center' sx={{ color: Colors.HeadText }}>{'Action'}</TableCell>
                      </TableRow>
                    </TableHead>
                    {this.state.CulturalTestList.map((data, index) => {
                      var bacteriaName = data?.BacteriaData?.organism_name;
                      var antibioticName = data?.AntibioticData?.antibiotic_name;
                      var sno = (index + 1)
                      return (
                        <TableBody>
                          <TableRow sx={{ bgcolor: 'white' }}>
                            <TableCell align='left'><div>{sno}</div></TableCell>
                            <TableCell align='left'>
                              {bacteriaName?.length > 13 ?
                                <Tooltip placement="top" title={bacteriaName} arrow>
                                  <div >{(bacteriaName ? bacteriaName.slice(0, 13) + '..' : '-')}</div>
                                </Tooltip> : <div> {(bacteriaName ? bacteriaName : '-')}</div>}</TableCell>
                            <TableCell align='left'>
                              {antibioticName?.length > 13 ?
                                <Tooltip placement="top" title={antibioticName} arrow>
                                  <div >{(antibioticName ? antibioticName.slice(0, 13) + '..' : '-')}</div>
                                </Tooltip> : <div> {antibioticName ? antibioticName : '-'}</div>}</TableCell>
                            <TableCell align='center'>
                              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                                <div>{data?.zone_size_s?.length > 5 ? <Tooltip placement="top" title={data?.zone_size_s} arrow><div>{data?.zone_size_s ? data?.zone_size_s.slice(0, 4) + '..' : "-"}</div></Tooltip> : <div>{data?.zone_size_s ? data?.zone_size_s : "-"}</div>}</div> |
                                <div>{data?.zone_size_i?.length > 5 ? <Tooltip placement="top" title={data?.zone_size_i} arrow><div>{data?.zone_size_i ? data?.zone_size_i.slice(0, 4) + '..' : "-"}</div></Tooltip> : <div>{data?.zone_size_i ? data?.zone_size_i : "-"}</div>}</div> |
                                <div>{data?.zone_size_r?.length > 5 ? <Tooltip placement="top" title={data?.zone_size_r} arrow><div>{data?.zone_size_r ? data?.zone_size_r.slice(0, 4) + '..' : "-"}</div></Tooltip> : <div>{data?.zone_size_r ? data?.zone_size_r : "-"}</div>}</div>
                              </div>
                            </TableCell>
                            <TableCell align='center'>
                              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                                <div>{data?.mic_s?.length > 5 ? <Tooltip placement="top" title={data?.mic_s} arrow><div>{data.mic_s ? data.mic_s.slice(0, 4) + '..' : "-"}</div></Tooltip> : <div>{data?.mic_s ? data?.mic_s : "-"}</div>}</div> |
                                <div>{data?.mic_i?.length > 5 ? <Tooltip placement="top" title={data?.mic_i} arrow><div>{data.mic_i ? data.mic_i.slice(0, 4) + '..' : "-"}</div></Tooltip> : <div>{data?.mic_i ? data?.mic_i : "-"}</div>}</div> |
                                <div>{data?.mic_r?.length > 5 ? <Tooltip placement="top" title={data?.mic_r} arrow><div>{data.mic_r ? data.mic_r.slice(0, 4) + '..' : "-"}</div></Tooltip> : <div>{data?.mic_r ? data?.mic_r : "-"}</div>}</div>
                              </div>
                            </TableCell>
                            <TableCell align='center'>
                              <div className='eMed_TestConfig_Con'>
                                <IconButton size='small'>
                                  <img src={ImagePaths.Edit.default} className='eMed_TestConfig_DeleteIcon' alt='Edit'
                                    onClick={() => { this.handleEdit(data, index) }}
                                  />
                                </IconButton>
                                <IconButton size='small'>
                                  <img src={ImagePaths.Delete.default} className='eMed_TestConfig_DeleteIcon' alt='Delete'
                                    onClick={() => { this.handleDelete(index) }}
                                  />
                                </IconButton>
                              </div>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )
                    })
                    }
                  </Table>
                  {this.state?.CulturalTestList?.length === 0 ?
                    <div className='eMed_TestConfig_NoRecords'>
                      {'NoRecords Shown'}
                    </div> : null}
                </TableContainer>
              </Paper>
            </div>
            <Stack id="emedhub_popup_footer" direction="row" justifyContent={"flex-end"} alignItems="center" sx={{ height: "3vw" }}>
              <Button size="small" sx={{ marginRight: "1vw" }} variant={"outlined"} onClick={() => {
                this.setState({ EditCultureTest: false, isEdit: false }, () => { this.ResetCultureReference(); this.handleClose() })
              }}>
                {"Back"}
              </Button>
              <Button variant="contained" size="small" emed_tid='ResultConfig_Action_Update' disabled={this.state.disableBtn} onClick={() => { this.postNewReferenceData() }}>{"UPDATE"}</Button>
            </Stack>
          </Paper>
        </Modal>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  ValidateCultureCheck = (data) => {
    try {
      let isInValid = false
      let { CulturalTestData } = this.state
      const isDuplicate = CulturalTestData.some(
        (detail) => detail.BacteriaData?.organism_name === data?.BacteriaData?.organism_name && detail.AntibioticData?.antibiotic_name === data?.AntibioticData?.antibiotic_name
      );
      data?.forEach((element, index) => {
        if ((element.AntibioticData !== null) && (element.BacteriaData !== null) && data.length > 1) {
          this.setState({
            CulturalTestData: data
          })
        } else if ((element.AntibioticData == null) || (!element.BacteriaData && element.BacteriaData == null)) {
          isInValid = true
        } else if (isDuplicate) {
          this.errorMessage('This Bacteria and Anti-Biotic Combination Already Exist')
        }
      })
      return isInValid
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  DeleteCulture = (index) => {
    this.setState(prevState => {
      const updatedContainers = prevState.containers.filter((container, i) => i !== index);
      const updatedCulturalTestData = prevState.CulturalTestData.filter((data, i) => i !== index);

      return {
        containers: updatedContainers,
        CulturalTestData: updatedCulturalTestData
      };
    });
  }

  addContainer = () => {
    let { CulturalTestData } = this.state
    let duplicate = {
      AntibioticData: null,
      BacteriaData: null,
      zone_size_s: "",
      zone_size_i: "",
      zone_size_r: "",
      mic_s: "",
      mic_i: "",
      mic_r: "",
    }
    let Invalid = this.ValidateCultureCheck(CulturalTestData)
    if (!Invalid) {
      CulturalTestData = [...CulturalTestData, duplicate]
      this.setState(prevState => ({
        containers: [
          ...prevState.containers,
          {
            id: prevState.containers.length + 1
          }
        ], CulturalTestData
      }));
    } else {
      this.errorMessage("Enter Culture Details")
    }
  }

  renderTextBox = (label, width, values, name, index) => {
    let states = this.state
    return (
      <div>
        <TextField
          size={'small'}
          sx={{ width: width, marginRight: "0.5vw" }}
          label={label}
          name={name}
          value={values ? values : ""}
          variant="outlined"
          onChange={(e) => {
            let value = e.target.value
            let names = e.target.name
            if ((names == "zone_size_s" || names == "zone_size_i" || names == "zone_size_r" || names == "mic_s" || names == "mic_i" || names == "mic_r") && (value.length <= 15 && !(/\s/.test(value)))) {
              if (index != undefined && index != null) {
                states.CulturalTestData[index][names] = value
                this.setState({ states })
              } else {
                states.CulturalTestData[names] = value
                this.setState({ states })
              }
            }
          }}
        />
      </div>
    )
  }

  // renderAddNewTestPopup = () => {
  //   const steps = ['Test Details', 'Parameters'];
  //   let { GroupAmt, TestAmt } = this.state
  //   var numbers = [];

  //   let { ref_data, range_data, editRangeData, receivedData, ref_type } = this.state
  //   let states = this.state
  //   let showCode = localGetItem('lab_test_code') ? JSON.parse(localGetItem('lab_test_code')) : JSON.parse(localGetItem('loggedInUserInfo'))?.lab_test_code
  //   try {
  //     return (
  //       <Modal open={this.state.addTestPopup}>
  //         <Paper elevation={3} id="emedhub_popup_mainBox" sx={{ width: "60vw", height: "69vh" }}>
  //           <div id="emedhub_popup_header" style={{ backgroundColor: Colors.DataTblHeaderbg }}>
  //             <Typography sx={{ color: "#616161", fontWeight: 600 }}>{"Add New Test Details"}</Typography>
  //             <IconButton onClick={() => {((this.state.ViewScreen == 1) && (receivedData?.result_type != 3)) ? this.handleWarning() : this.handleClose() }} size="small" ><img className="eMed_DeletePopup_CrossIcon" src={ImagePaths.LabCloseButton.default} alt={"close"} /></IconButton>
  //           </div>
  //           <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", padding: "1vw" }}>
  //             <Stepper activeStep={this.state.stepNumber} sx={{ width: "50%" }}>
  //               {steps.map((label, index) => (
  //                 <Step key={index}>
  //                   <StepLabel>{label}</StepLabel>
  //                 </Step>
  //               ))}
  //             </Stepper>
  //           </div>
  //           {this.state.ViewScreen == 0 ?
  //             <Box component={'div'} sx={{ marginX: "1vw" }}>
  //               <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
  //                 <div style={{ fontSize: "1vw", color: Colors.grayShade, marginBottom: "1vw", marginTop: "0.5vw" }}>Department and Group Details</div>
  //                 <FormControlLabel control={<Checkbox checked={!this.state.isBillable} onChange={(e) => { this.setState({ isBillable: !this.state.isBillable }) }} />} label={"IS BILLABLE"} />
  //               </div>
  //               <Box component={'div'} style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
  //                 <Autocomplete
  //                   size='small'
  //                   clearIcon
  //                   options={this.state.departmentListData}
  //                   getOptionLabel={(option) => option?.dept_name}
  //                   value={this.state.NewdepartmentName}
  //                   onChange={(e, newValue) => {
  //                     this.setState({ NewdepartmentName: newValue, NewgroupName: null, NewgrpListData: [], newTestNameLength: [], TestAmt: 0, GroupAmt: 0, newTestNameLength: [], NewtestListData: [], NewtestName: "", newPrintOrder: null, }, () => {
  //                       this.getNewTestData()
  //                     })
  //                   }}
  //                   sx={{ width: "15vw", marginRight: "0.5vw" }}
  //                   renderInput={(params) => (<TextField {...params} size='small' label='Enter Department Name *' onChange={(e) => {
  //                     var newDepart = { dept_name: e.target.value, id: "" }
  //                     this.setState({
  //                       NewdepartmentName: newDepart, NewgrpListData: [], NewgroupName: null, newTestNameLength: [], NewtestListData: [], NewtestName: "", TestAmt: 0, GroupAmt: 0
  //                     })
  //                   }} />)}
  //                 />
  //                 <Autocomplete
  //                   size='small'
  //                   options={this.state.NewdepartmentName !== null ? this.state.NewgrpListData : []}
  //                   getOptionLabel={(option) => option.grp_name}
  //                   value={this.state.NewgroupName}
  //                   onChange={(e, newValue) => {
  //                     this.setState({ NewgroupName: newValue, GroupAmt: newValue?.grp_amt ? newValue?.grp_amt : 0,hasCode: newValue?.grp_test_code ? true : false ,grpCode:newValue?.grp_test_code ? newValue?.grp_test_code : ''}, () => {
  //                       var duplicate = JSON.parse(this.state.duplicateResult)
  //                       if (this.state.NewdepartmentName) {
  //                         duplicate?.filter(element => element.dept_id === this.state.NewdepartmentName?.dept_id ? duplicate = [element] : duplicate)
  //                       }
  //                       let data = []
  //                       if (this.state.NewgroupName && duplicate?.length) {
  //                         duplicate[0].grp_data.forEach(element => {
  //                           if (element.grp_id === this.state.NewgroupName?.grp_id) {
  //                             data.push(element)
  //                           }
  //                         })
  //                         duplicate[0].grp_data = data
  //                       }
  //                       for (let i = 0; i <= this.state?.NewgroupName?.grp_test_data?.length; i++) {
  //                         numbers?.push(i + 1)
  //                       }
  //                       this.setState({ NewtestName: "", newTestNameLength: numbers, newPrintOrder: numbers.length, NewtestListData: [], TestAmt: 0 }, () => { this.setState({ NewtestListData: data?.[0]?.grp_test_data }) })
  //                     })
  //                   }}
  //                   sx={{ width: '15vw', marginRight: "0.5vw" }}
  //                   renderInput={(params) => (<TextField {...params} label='Enter Group Name *' onChange={(e) => {
  //                     var newGrp = { grp_name: e.target.value, grp_id: "" }
  //                     this.setState({
  //                       NewgroupName: newGrp, GroupAmt: 0, NewtestName: "", newPrintOrder: 1, NewtestListData: [], TestAmt: 0
  //                     })
  //                   }} />)}
  //                 />
  //                 {showCode ? 
  //                 <TextField
  //                   size='small'
  //                   sx={{ width: '10vw', mr: '0.5vw' }}
  //                   label='Group Code'
  //                   // disabled={this.state.hasCode}
  //                   value={this.state.grpCode}
  //                   onChange={(e) => {
  //                     let value = e.target.value
  //                     if (value?.length <= Code_Length || value === '') {
  //                       this.setState({ grpCode: value })
  //                     }
  //                   }}
  //                   InputProps={{emed_tid:'grp_code'}}
  //                 /> : null}
  //                 {this.inputTextBox('NewtestName', this.state.NewtestName, 'Enter Test Name *', '15vw', '0', null, false)}
  //                 {showCode ? <TextField
  //                   size='small'
  //                   sx={{ width: '10vw', ml: '0.5vw' }}
  //                   label='Test Code'
  //                   value={this.state.testCode}
  //                   onChange={(e) => {
  //                     let value = e.target.value
  //                     if (value?.length <= Code_Length || value === '') {
  //                       this.setState({ testCode: value })
  //                     }
  //                   }}
  //                   InputProps={{emed_tid:'tst_code'}}
  //                 /> : null}
  //               </Box>
  //               <Box component={'div'} sx={{ marginTop: "1vw", display: "flex", flexDirection: "row" }}>
  //                 {this.inputTextBox('NewSpecimen', this.state.NewSpecimen, 'Specimen', '14vw', '0.5vw', null, false)}
  //                 {this.inputTextBox('NewMethod', this.state.NewMethod, 'Method', '14vw', '0.5vw', null, false)}
  //                 <FormControl size='small' sx={{ width: '14vw', marginRight: '0.5vw', marginBottom: '0.5vw', mt: '0.5vw' }}>
  //                   <InputLabel>Result Type *</InputLabel>
  //                   <Select
  //                     label='Result Type'
  //                     value={this.state.NewresultType}
  //                     onChange={(e) => { this.onChangeHandler(e, 'NewresultType') }}
  //                   >
  //                     {Result_Type_List?.map((item) => (
  //                       <MenuItem value={item.value}>{item.label}</MenuItem>
  //                     ))}
  //                   </Select>
  //                 </FormControl>
  //                 <Autocomplete
  //                   value={this.state.newPrintOrder}
  //                   disabled={true}
  //                   options={this.state.newTestNameLength.map((item) => item)}
  //                   getOptionLabel={(option) => option}
  //                   onChange={(event, newValue) => {
  //                     this.setState({ newPrintOrder: newValue })
  //                   }}
  //                   renderInput={(params) => (<TextField {...params} size='small' sx={{ width: '14vw', mt: '0.5vw' }} label='Print No. *' />)}
  //                 />
  //               </Box>
  //               <div style={{ fontSize: "1vw", color: Colors.grayShade }}>Test Amount</div>
  //               <Box component={'div'} sx={{ display: "flex", flexDirection: "row", marginBottom: "4.5vw", marginTop: "1vw" }}>
  //                 {this.inputTextBox('GroupAmt', GroupAmt, 'Enter Group Amount*', '14vw', '0.5vw', null, false)}
  //                 {this.inputTextBox('TestAmt', TestAmt, 'Enter Test Amount', '14vw', '0.5vw', null, false)}
  //               </Box>
  //             </Box>
  //             : null}
  //           {this.state.ViewScreen == 1 ?
  //             <Box component={'div'} sx={{ marginX: "1vw" }}>
  //               <div style={{ fontSize: "1vw", color: Colors.grayShade }}>Test Name - <span style={{ fontSize: "1vw", color: "black" }}>{receivedData?.test_name}</span></div>
  //               <FormControl size='small' sx={{ width: '15vw', margin: "0.75vw" }}>
  //                 <InputLabel>Result Type</InputLabel>
  //                 <Select
  //                   label='Result Type'
  //                   disabled={true}
  //                   value={receivedData?.result_type}
  //                   onChange={(e) => { this.onChangeHandler(e, 'resultType') }}
  //                 >
  //                   {Result_Type_List?.map((item) => (
  //                     <MenuItem value={item.value}>{item.label}</MenuItem>
  //                   ))}
  //                 </Select>
  //               </FormControl>
  //               <div style={{ fontSize: "1vw", color: Colors.grayShade }}>Add Parameters</div>
  //               {this.state?.receivedData?.result_type == 3 ?
  //                 <div style={{ height: "30vh", overflow: "scroll" }}>
  //                   {this.state.containers.map((container, index) => (
  //                     <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
  //                       <div key={container.id}>
  //                         <div style={{ display: "flex", flexDirection: "row", alignItems: "center", padding: "0.75vw" }}>
  //                           <Autocomplete
  //                             size='small'
  //                             options={this.state.BacteriaList}
  //                             getOptionLabel={(option) => (option?.name)}
  //                             value={this.state?.CulturalTestData[index]?.BacteriaData}
  //                             onChange={(event, newValue) => {
  //                               states.CulturalTestData[index].BacteriaData = newValue
  //                               this.setState({ states })
  //                             }}
  //                             renderInput={(params) => (
  //                               <TextField {...params} style={{ width: "15vw", marginRight: "0.65vw" }}
  //                                 label={'Bacteria Name*'}
  //                               />
  //                             )}
  //                           />
  //                           <div style={{ fontSize: "1vw", marginRight: "1vw" }}>Zone Size (mm)</div>
  //                           {this.renderTextBox("S", '8.5vw', states?.CulturalTestData[index]?.zone_size_s, 'zone_size_s', index)}
  //                           {this.renderTextBox("I", '8.5vw', states?.CulturalTestData[index]?.zone_size_i, 'zone_size_i', index)}
  //                           {this.renderTextBox("R", '8.5vw', states?.CulturalTestData[index]?.zone_size_r, 'zone_size_r', index)}
  //                         </div>
  //                         <div style={{ display: "flex", flexDirection: "row", alignItems: "center", padding: "0.75vw", marginTop: "-0.5vw" }}>
  //                           <Autocomplete
  //                             size='small'
  //                             options={this.state?.AntibioticList}
  //                             getOptionLabel={(option) => (option?.name)}
  //                             value={this.state?.CulturalTestData[index]?.AntibioticData}
  //                             onChange={(event, newValue) => {
  //                               states.CulturalTestData[index].AntibioticData = newValue
  //                               this.setState({ states })
  //                             }}
  //                             renderInput={(params) => (
  //                               <TextField {...params} style={{ width: "15vw", marginRight: "0.65vw" }}
  //                                 label={'Antibiotic Name*'}
  //                               />
  //                             )}
  //                           />
  //                           <div style={{ fontSize: "1vw", marginRight: "0.75vw" }}>MIC Break Point</div>
  //                           {this.renderTextBox("S", '8.5vw', states?.CulturalTestData[index]?.mic_s, 'mic_s', index)}
  //                           {this.renderTextBox("I", '8.5vw', states?.CulturalTestData[index]?.mic_i, 'mic_i', index)}
  //                           {this.renderTextBox("R", '8.5vw', states?.CulturalTestData[index]?.mic_r, 'mic_r', index)}
  //                         </div>
  //                       </div>
  //                       <Button disabled={index == 0 ? true : false} className='eMed_Lab_act_btn' onClick={() => { this.DeleteCulture(index) }}>
  //                         <Box component={'img'} src={index == 0 ? ImagePaths.DisabledDelete.default : ImagePaths.Delete.default} alt='close' height={'1.5vw'} width={'1.5vw'} />
  //                       </Button>
  //                     </div>
  //                   ))}
  //                   <Box>
  //                     <Button
  //                       startIcon={<AddCircleIcon />}
  //                       className='eMed_Lab_act_btn'
  //                       sx={{ textTransform: 'capitalize' }}
  //                       onClick={() => { this.addContainer() }}
  //                     >Add New Parameter</Button>
  //                   </Box>
  //                 </div> :
  //                 <div style={{ paddingLeft: "0.7vw" }}>
  //                   <Box component={'div'} borderBottom={'1px solid lightgray'} display={'flex'}>
  //                     <Box mr={'0.5vw'}>
  //                       <FormControl size='small' sx={{ width: '15vw', marginBottom: '1vw', mt: '0.5vw' }}>
  //                         <InputLabel>Gender</InputLabel>
  //                         <Select
  //                           label='Gender'
  //                           value={this.state.isEdit ? editRangeData?.gender : range_data?.gender}
  //                           onChange={(e) => { this.onChangeHandler(e, 'gender') }}
  //                         >
  //                           {Gender_List?.map((item) => (
  //                             <MenuItem value={item.value}>{item.label}</MenuItem>
  //                           ))}
  //                         </Select>
  //                       </FormControl>
  //                     </Box>

  //                     <Box mr={'0.5vw'}>
  //                       {this.inputTextBox('ageFrom', this.state.isEdit ? editRangeData?.age_from : range_data.age_from, "Age From", '8vw', null, null, false)}
  //                       <FormControl size='small' sx={{ width: '7vw', marginBottom: '1vw', mt: '0.5vw', marginLeft: "0.5vw" }}>
  //                         <Select
  //                           value={this.state.isEdit ? editRangeData?.age_from_unit : range_data?.age_from_unit}
  //                           onChange={(e) => { this.onChangeHandler(e, 'fromDays') }}
  //                         >
  //                           {Days_List?.map((item) => (
  //                             <MenuItem value={item.value}>{item.label}</MenuItem>
  //                           ))}
  //                         </Select>
  //                       </FormControl>
  //                     </Box>

  //                     <Box mr={'0.5vw'}>
  //                       {this.inputTextBox('ageTo', this.state.isEdit ? editRangeData?.age_to : range_data.age_to, "Age To", '8vw', null, null, false)}
  //                       <FormControl size='small' sx={{ width: '7vw', marginBottom: '1vw', mt: '0.5vw', marginLeft: "0.5vw" }}>
  //                         <Select
  //                           value={this.state.isEdit ? editRangeData?.age_to_unit : range_data.age_to_unit}
  //                           onChange={(e) => { this.onChangeHandler(e, 'toDays') }}
  //                         >
  //                           {Days_List?.map((item) => (
  //                             <MenuItem value={item.value}>{item.label}</MenuItem>
  //                           ))}
  //                         </Select>
  //                       </FormControl>
  //                     </Box>
  //                   </Box>
  //                   {this.state?.receivedData?.result_type === 7 ?
  //                     <div>
  //                       <Box component={'div'} paddingTop={'0.5vw'} overflow={'scroll'} maxHeight={'11vw'} height={"11vw"}>
  //                         {this.state.ref_type.map((item, index) => {
  //                           return (
  //                             <>
  //                               <Box key={index} display={'flex'}>
  //                                 <FormControl size='small' sx={{ width: '5vw', marginBottom: '1vw', mt: '0.5vw', marginRight: "0.5vw" }}>
  //                                   <InputLabel>Referance Type</InputLabel>
  //                                   <Select
  //                                     label='Referance Type'
  //                                     value={item.reference_type}
  //                                     onChange={(e) => { this.onChangeHandler(e, 'referanceType', index) }}
  //                                   >
  //                                     {Referance_Type_List?.map((item) => (
  //                                       <MenuItem style={{ textAlign: "center", fontSize: "1vw" }} value={item.value}>{item.label}</MenuItem>
  //                                     ))}
  //                                   </Select>
  //                                 </FormControl>
  //                                 {this.inputTextBox('refRange', item.reference_range, "Referance Range", '9.4vw', '0.5vw', index, false)}
  //                                 <Autocomplete
  //                                   disableClearable
  //                                   freeSolo
  //                                   value={item.unit}
  //                                   options={this.state.refUnitList?.length != 0 ? this.state.refUnitList : []}
  //                                   getOptionLabel={option => typeof (option) === "string" ? option : option.unit}
  //                                   onChange={(event, newValue) => {
  //                                     ref_type[index].unit = newValue.unit
  //                                     this.setState({ ref_type })
  //                                   }}
  //                                   onInputChange={(e, val) => {
  //                                     ref_type[index].unit = val
  //                                     this.setState({ ref_type })
  //                                   }}
  //                                   renderInput={(params) => (
  //                                     <TextField
  //                                       {...params}
  //                                       sx={{ width: '8vw', mt: '0.5vw', mr: '0.5vw' }}
  //                                       label={'Unit'}
  //                                       size="small"
  //                                       InputProps={{
  //                                         ...params.InputProps,
  //                                         type: 'search',
  //                                       }}
  //                                     />
  //                                   )}
  //                                 />

  //                                 {this.inputTextBox('refDescription', item.description, "Description", '23vw', '0.5vw', index, false)}
  //                                 {receivedData?.result_type === 7 ?
  //                                   this.state.ref_type?.length > 1 ?
  //                                     <Button className='eMed_Lab_act_btn' onClick={() => {
  //                                       var { ref_type } = this.state
  //                                       ref_type.splice(index, 1)
  //                                       this.setState({ ref_type })
  //                                     }}>
  //                                       <Box component={'img'} src={ImagePaths.Delete.default} alt='close' height={'1vw'} width={'1vw'} />
  //                                     </Button>
  //                                     : null : null}
  //                               </Box>
  //                             </>
  //                           )
  //                         })}
  //                         {receivedData?.result_type === 7 ?
  //                           <Box>
  //                             <Button
  //                               startIcon={<AddCircleIcon />}
  //                               className='eMed_Lab_act_btn'
  //                               sx={{ textTransform: 'capitalize' }}
  //                               onClick={() => { this.onAddNewReferance() }}
  //                             >Add New Parameter</Button>
  //                           </Box> : null
  //                         }
  //                       </Box>
  //                     </div> :
  //                     <Box component={'div'} paddingTop={'0.5vw'} overflow={'scroll'} maxHeight={'11vw'} height={"11vw"}>
  //                       {this.state.ref_data.map((item, index) => {
  //                         return (
  //                           <>
  //                             <Box key={index} display={'flex'}>
  //                               {this.inputTextBox('rangeFrom', item.reference_from, "Range From", '7.3vw', '0.5vw', index, false)}
  //                               {this.inputTextBox('rangeTo', item.reference_to, "Range To", '7.3vw', '0.5vw', index, false)}
  //                               <Autocomplete
  //                                 disableClearable
  //                                 freeSolo
  //                                 value={item.unit}
  //                                 options={this.state.refUnitList?.length != 0 ? this.state.refUnitList : []}
  //                                 getOptionLabel={option => typeof (option) === "string" ? option : option.unit}
  //                                 onChange={(event, newValue) => {
  //                                   ref_data[index].unit = newValue.unit
  //                                   this.setState({ ref_data })
  //                                 }}
  //                                 onInputChange={(e, val) => {
  //                                   ref_data[index].unit = val
  //                                   this.setState({ ref_data })
  //                                 }}
  //                                 renderInput={(params) => (
  //                                   <TextField
  //                                     {...params}
  //                                     sx={{ width: '8vw', mt: '0.5vw', mr: '0.5vw' }}
  //                                     label={'Unit'}
  //                                     size="small"
  //                                     InputProps={{
  //                                       ...params.InputProps,
  //                                       type: 'search',
  //                                     }}
  //                                   />
  //                                 )}
  //                               />

  //                               {this.inputTextBox('description', item.description, "Descriptions", '23vw', '0.5vw', index, false)}
  //                               {this.state.ref_data?.length > 1 ?
  //                                 <Button className='eMed_Lab_act_btn' onClick={() => {
  //                                   var { ref_data } = this.state
  //                                   ref_data.splice(index, 1)
  //                                   this.setState({
  //                                     ref_data
  //                                   })
  //                                 }}>
  //                                   <Box component={'img'} src={ImagePaths.Delete.default} alt='close' height={'1vw'} width={'1vw'} />
  //                                 </Button>
  //                                 : null}
  //                             </Box>
  //                           </>
  //                         )
  //                       })}

  //                       {receivedData?.result_type === 1 ?
  //                         <Box>
  //                           <Button
  //                             startIcon={<AddCircleIcon />}
  //                             className='eMed_Lab_act_btn'
  //                             sx={{ textTransform: 'capitalize' }}
  //                             onClick={() => { this.onAddNewParameter() }}
  //                           >Add New Parameter</Button>
  //                         </Box> : null
  //                       }
  //                     </Box>}
  //                 </div>}
  //             </Box>
  //             : null}
  //           <Stack id="emedhub_popup_footer" direction="row" justifyContent={"flex-end"} alignItems="center" sx={{ height: "3vw" }}>
  //             {this.state.ViewScreen == 1 ? null :
  //               <Button size="small" sx={{ marginRight: "1vw" }} variant={"outlined"} onClick={() => {
  //                 this.setState({
  //                   ViewScreen: 0,
  //                   stepNumber: 0,
  //                 }, () => { this.handleClose() })
  //               }}>
  //                 {"CANCEL"}
  //               </Button>}
  //             <Button variant="contained" size="small" emed_tid='ResultConfig_AddNewTest_Next_btn' disabled={this.state.disableBtn} onClick={() => { this.state.ViewScreen == 0 ? this.createNewTest() : this.NewpostNewReferenceData() }}>{this.state.ViewScreen == 0 ? "NEXT" : "ADD TEST"}</Button>
  //           </Stack>
  //         </Paper>
  //       </Modal>
  //     )
  //   }
  //   catch (error) {
  //     this.errorMessage(error.message)
  //   }
  // }

  grpDataLength = (item) => {
    try {
      let length = []
      for (let i = 0; i <= item?.grp_data?.length - 1; i++) {
        length.push(i + 1)
      }
      return length
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  assignDatas = (testData, grpData, item) => {
    let numbers = [];
    for (let i = 0; i <= testData.length - 1; i++) {
      numbers.push(i + 1)
    }
    let offtime = testData?.cut_off_time?.split(":")
    this.setState({
      editGrpData: grpData,
      editTestData: testData,
      NewdepartmentName: { id: item?.dept_id, dept_name: item?.dept_name },
      NewgroupName: { grp_id: grpData?.grp_id, grp_name: grpData?.grp_name },
      grpCode: grpData?.grp_test_code,
      grpshortName: grpData?.grp_short_name,
      NewtestName: testData?.test_name,
      testId: testData?.test_id,
      testCode: testData?.test_code,
      testshortName: testData?.test_short_name,
      newPrintOrder: testData?.print_order,
      loincCode: testData?.loinc_code,
      cptCode: testData?.cpt_code,
      sacVal: testData?.sac,
      nameSpecimen: (testData?.specimen_master_id && testData?.specimen) ? { id: testData?.specimen_master_id, specimen_name: testData?.specimen } : null,
      specQty: testData?.specimen_quantity,
      Qtyunit: testData?.specimen_unit,
      temperature: testData?.specimen_temp,
      Tempunit: testData?.specimen_temp_unit,
      nameMethod: (testData?.method_master_id && testData?.method) ? { id: testData?.method_master_id, method_name: testData?.method } : null,
      cutOffHour: (offtime && offtime[0]) || null,
      cutOffMin: (offtime && offtime[1]) || null,
      nameAnalyzer: (testData?.analyser_master_id && testData?.analyser) ? { id: testData?.analyser_master_id, analyser_name: testData?.analyser } : null,
      GroupAmt: grpData?.grp_amt,
      TestAmt: testData?.test_amt,
      testNameLength: numbers,
      isBillable: !testData?.is_billable
    }, () => { this.getMethodList() })
  }

  renderResultConfigTable = () => {
    let commonDisable = CheckAccessFunc("Laboratory", "Settings", "Lab Configuration", "Result Configuration", "SubTab")?.editAccess
    let showCode = localGetItem('lab_test_code') ? JSON.parse(localGetItem('lab_test_code')) : JSON.parse(localGetItem('loggedInUserInfo'))?.lab_test_code
    try {
      return (
        <Box component={'div'} className='eMed_Lab_Rst_Tbl_Container' overflow={'scroll'}>
          <Table stickyHeader size='small' >
            <TableHead>
              <TableRow>
                <TableCell className='eMed_Main_Tbl_head' sx={{ width: '14vw' }}>Department</TableCell>
                <TableCell className='eMed_Main_Tbl_head' sx={{ width: '14vw' }}>Group Name</TableCell>
                {/* <TableCell className='eMed_Main_Tbl_head' sx={{ width: '10vw' }}>
                  <Box display={'flex'}>
                    <Typography color={Colors.SecondaryText} fontWeight={600} fontSize={'0.9vw'}>Print Order No.</Typography>
                    {!this.state.isPrintOrderEdit && (this.state.resultConfigData?.length === 1) && (this.state.resultConfigData[0]?.grp_data?.length > 1)  ? 
                      <Button className='eMed_Lab_act_btn'
                        sx={{ width: '1vw', height: '1vw', paddingTop: '0.6vw', marginLeft: '0.3vw' }}
                        onClick={() => this.setState({ isPrintOrderEdit: true })}
                      >
                        <Box component={'img'} src={ImagePaths.Lab_New_Green_Edit.default} height={'1vw'} width={'1vw'} />
                      </Button> : (this.state.resultConfigData?.length === 1) && (this.state.resultConfigData[0]?.grp_data?.length > 1) ?
                        <Button className='eMed_Lab_act_btn'
                          sx={{ width: '1vw', height: '1vw', paddingTop: '0.6vw', marginLeft: '0.3vw' }}
                          onClick={() => this.putNewReferenceData()}
                        >
                          <Box component={'img'} src={ImagePaths.Lab_New_Save.default} height={'1vw'} width={'1vw'} />
                        </Button>
                        : null}
                  </Box>
                </TableCell> */}
                <TableCell className='eMed_Main_Tbl_head' sx={{ width: '18vw' }}>Test Name</TableCell>
                <TableCell className='eMed_Main_Tbl_head' sx={{ width: '10vw' }}>Gender/Age</TableCell>
                <TableCell className='eMed_Main_Tbl_head' sx={{ width: '12.5vw' }}>Referrence Range</TableCell>
                <TableCell className='eMed_Main_Tbl_head' sx={{ width: '15vw' }}>Description</TableCell>
                <TableCell className='eMed_Main_Tbl_head' align='center'>Action</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {this.state.resultConfigData?.length > 0 ? this.state.resultConfigData.map((item, deptIndex) => (
                <TableRow>
                  <TableCell colSpan={8} key={deptIndex} sx={{ padding: '0vw' }}>
                    <Table key={deptIndex}>
                      <TableCell className='eMed_Tbl_rst_Cell_One' style={{ width: "14vw" }}>
                        <div style={{ fontSize: "1vw", color: Colors.grayShade, marginBottom: "0.5vw" }}>Department Name</div>
                        <Typography>{item?.dept_name}</Typography>
                        <Box flex={0.5} sx={{ marginTop: "0.5vw" }}>
                          <Typography color={'gray'} fontSize={'0.8vw'}>Print Order No.</Typography>
                          {item?.print_order ?
                            <Typography fontWeight={600}>{item?.print_order}</Typography> : '-'}
                        </Box>
                        <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'} mt={'0.5vw'} marginLeft={'0.5vw'}>
                          <Button
                            disabled={!commonDisable}
                            className='eMed_Lab_act_btn'
                            sx={{ height: '1.5vw', width: '1.5vw' }}
                            onClick={() => { this.setState({ EditDepName: item?.dept_name, EditDepId: item?.dept_id, DepPrintOrder: item?.print_order, isEditDepart: true }, () => { this.setState({ EditPopup: true }) }) }}
                          >
                            <Tooltip placement='top' title='Edit' arrow>
                              <Box component={'img'} src={commonDisable ? ImagePaths.LabEditButton.default : ImagePaths.DisabledEdit.default} height={'1.5vw'} width={'1.5vw'} mr={'1vw'} />
                            </Tooltip>
                          </Button>
                          <Button
                            disabled={!commonDisable}
                            className='eMed_Lab_act_btn'
                            sx={{ height: '1.5vw', width: '1.5vw' }}
                            onClick={() => { this.setState({ DltTitle: "Department", selectedvalue: item?.dept_id }, () => { this.setState({ deletePopup: true }) }) }}
                          >
                            <Tooltip placement='top' title='Delete' arrow>
                              <Box component={'img'} src={commonDisable ? ImagePaths.Delete.default : ImagePaths.DisabledDelete.default} height={'1.5vw'} width={'1.5vw'} />
                            </Tooltip>
                          </Button>
                        </Box>
                      </TableCell>

                      {item?.grp_data?.map((grpData, grpIndex) => (
                        <Table key={grpIndex}>
                          <TableCell className='eMed_Tbl_rst_Cell_One' style={{ width: "14vw" }}>
                            <div style={{ fontSize: "1vw", color: Colors.grayShade, marginBottom: "0.5vw" }}>Group Name</div>
                            <Typography>{grpData?.grp_name}</Typography>
                            <div style={{ fontSize: "1vw", color: Colors.grayShade, marginBottom: "0.5vw" }}>Group Amount</div>
                            <Typography>{AmountFormat(grpData?.grp_amt || 0)}</Typography>
                            <Box flex={0.5} sx={{ marginTop: "0.5vw" }}>
                              <Typography color={'gray'} fontSize={'0.8vw'}>Print Order No.</Typography>
                              {grpData?.print_order ?
                                <Typography fontWeight={600}>{grpData?.print_order}</Typography> : '-'}
                            </Box>
                            {showCode ?
                            <Box flex={0.5} sx={{ marginTop: "0.5vw" }}>
                              <Typography color={'gray'} fontSize={'0.8vw'}>Group Code</Typography>
                              {grpData?.grp_test_code ? <Typography fontWeight={600}>{grpData?.grp_test_code}</Typography> : '-'}
                            </Box> : null}
                            <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'} mt={'0.5vw'} marginLeft={'0.5vw'}>
                              <Button
                                disabled={!commonDisable}
                                className='eMed_Lab_act_btn'
                                sx={{ height: '1.5vw', width: '1.5vw' }}
                                onClick={() => {this.setState({hasShare:grpData?.is_share ? grpData?.is_share : false, EditDepId: item?.dept_id, EditGrpId: grpData.grp_id, EditGrpAmt: grpData.grp_amt, EditGrpName: grpData.grp_name, EditPrintOrder: grpData.print_order, EditDepName: item.dept_name, isEditGrp: true, EditGrpCode:grpData?.grp_test_code ? grpData?.grp_test_code : '', EditgrpShortname: grpData?.grp_short_name || "", }, () => { this.setState({ EditPopup: true }) }) }}
                              >
                                <Tooltip placement='top' title='Edit' arrow>
                                  <Box component={'img'} src={commonDisable ? ImagePaths.LabEditButton.default : ImagePaths.DisabledEdit.default} height={'1.5vw'} width={'1.5vw'} mr={'1vw'} />
                                </Tooltip>
                              </Button>
                              <Button
                                disabled={!commonDisable}
                                className='eMed_Lab_act_btn'
                                sx={{ height: '1.5vw', width: '1.5vw' }}
                                onClick={() => { this.setState({ DltTitle: "Group", selectedvalue: grpData?.grp_id }, () => { this.setState({ deletePopup: true }) }) }}
                              >
                                <Tooltip placement='top' title='Delete' arrow>
                                  <Box component={'img'} src={commonDisable ? ImagePaths.Delete.default : ImagePaths.DisabledDelete.default} height={'1.5vw'} width={'1.5vw'} />
                                </Tooltip>
                              </Button>
                            </Box>
                          </TableCell>

                          {/* <TableCell className='eMed_Tbl_rst_Cell_One'>
                            {this.state.isPrintOrderEdit ?
                              <Autocomplete
                                value={grpData?.print_order}
                                options={this.grpDataLength(item)}
                                getOptionLabel={(option) => option}
                                onChange={(event, newValue) => {
                                  var { resultConfigData } = this.state
                                  resultConfigData[deptIndex].grp_data[grpIndex].print_order = newValue
                                  this.setState({
                                    resultConfigData
                                  })
                                }}
                                renderInput={(params) => (<TextField {...params} size='small' sx={{ width: '5vw' }} label='Print No.' />)}
                              />
                              :
                              <Typography>{grpData?.print_order}</Typography>}

                          </TableCell> */}

                          <TableCell sx={{ padding: '0vw' }}>
                            {grpData?.grp_test_data?.map((testData, testIndex) => (
                              <Table key={testIndex}>

                                <TableCell className='eMed_Tbl_rst_Cell_Two'>
                                  <Box component={'div'}>
                                    <Box component={'div'} display={'flex'} flexDirection={"row"} >
                                      <Box component={'div'} mb={'0.5vw'}>
                                        {testData?.test_name ? testData?.test_name?.length > 20 ?
                                          <Tooltip arrow placement='top' title={testData?.test_name}><Typography fontWeight={'bold'}>{testData?.test_name.slice(0, 20) + '...'}</Typography></Tooltip> :
                                          <Typography fontWeight={'bold'}>{testData?.test_name}</Typography> : '-'}
                                      </Box>
                                      <img style={{ marginLeft: "0.5vw", marginTop: "0.1vw", width: "1.2vw", height: "1.2vw" }} className="eMed_DeletePopup_CrossIcon" src={ImagePaths.DeleteOutline.default} alt='Close' 
                                      onClick={() => { 
                                        if(commonDisable){
                                          this.setState({ DltTitle: "Test", selectedvalue: testData?.test_id }, () => { this.setState({ deletePopup: true }) })
                                        }
                                       }} />
                                    </Box>
                                    <Box component={'div'} display={'flex'} mb={'0.5vw'}>
                                      <Box flex={0.5}>
                                        <Typography color={'gray'} fontSize={'0.8vw'}>Specimen</Typography>
                                        {testData?.specimen ? testData?.specimen?.length > 12 ?
                                          <Tooltip arrow placement='top' title={testData?.specimen}><Typography fontWeight={600}>{testData?.specimen.slice(0, 12) + '...'}</Typography></Tooltip> :
                                          <Typography fontWeight={600}>{testData?.specimen}</Typography> : '-'}
                                      </Box>
                                      <Box flex={0.5}>
                                        <Typography color={'gray'} fontSize={'0.8vw'}>Amount</Typography>
                                        <Typography fontWeight={600}>{AmountFormat(testData?.test_amt || 0)}</Typography>
                                      </Box>
                                    </Box>
                                    <Box component={'div'} display={'flex'} mb={'0.5vw'}>
                                      <Box flex={0.5}>
                                        <Typography color={'gray'} fontSize={'0.8vw'}>Result Type</Typography>
                                        {testData?.result_type ?
                                          <Tooltip arrow placement='top' title={testData?.result_type === 1 ? 'Range Based' : testData?.result_type === 2 ? 'Positive/Negative' : testData?.result_type === 3 ? "Culture Test" : testData?.result_type === 5 ? 'Text Based' : testData?.result_type === 6 ? "Present/Absent" : "Greater than/Less than"}>
                                            <Box>{testData?.result_type === 1 ? 'Range Based' : testData?.result_type === 2 ? 'Positive/Neg...' : testData?.result_type === 3 ? "Culture Test" : testData?.result_type === 5 ? 'Text Based' : testData?.result_type === 6 ? "Present/Absent" : "Greater than/Les..."}</Box></Tooltip> : '-'}
                                      </Box>
                                      <Box flex={0.5}>
                                        <Typography color={'gray'} fontSize={'0.8vw'}>Method</Typography>
                                        {testData?.method ? testData?.method?.length > 12 ?
                                          <Tooltip placement='top' title={testData?.method} arrow><Typography fontWeight={600}>{testData?.method.slice(0, 12) + '...'}</Typography></Tooltip> :
                                          <Typography fontWeight={600}>{testData?.method}</Typography> : '-'}
                                      </Box>
                                    </Box>
                                    <Box component={'div'} display={'flex'} mb={'0.5vw'}>
                                      <Box flex={0.5}>
                                        <Typography color={'gray'} fontSize={'0.8vw'}>Print Order No.</Typography>
                                        {testData?.print_order ?
                                          <Typography fontWeight={600}>{testData?.print_order}</Typography> : '-'}
                                      </Box>
                                      {showCode ?
                                        <Box flex={0.5}>
                                          <Typography color={'gray'} fontSize={'0.8vw'}>Test Code</Typography>
                                          {testData?.test_code ? <Typography fontWeight={600}>{testData?.test_code}</Typography> : '-'}
                                        </Box> :
                                        <Box flex={0.5}>
                                          <Typography color={'gray'} fontSize={'0.8vw'}>Test ID</Typography>
                                          {testData?.test_id ? <Typography fontWeight={600}>{testData?.test_id}</Typography> : '-'}
                                        </Box>}
                                    </Box>
                                    <Box component={'div'} mb={'0.5vw'}>
                                      <Button startIcon={<EditRounded />}
                                        sx={{ textTransform: 'capitalize' }}
                                        disabled={!commonDisable}
                                        className='eMed_Lab_act_btn'
                                        onClick={() => {
                                          this.setState({
                                            isEditTestDetails: true,
                                            NewEditTestDetails: true,
                                            hasShare:testData?.is_share ? testData?.is_share : false,
                                            addTestPopup: true
                                          }, () => {
                                            let numbers = [];
                                            for (let i = 0; i <= testData.length - 1; i++) {
                                              numbers.push(i + 1)
                                            }
                                            let offtime = testData?.cut_off_time?.split(":")
                                            this.setState({
                                              editGrpData: grpData,
                                              editTestData: testData,
                                              NewdepartmentName : {id: item?.dept_id, dept_name: item?.dept_name},
                                              NewgroupName: {grp_id:grpData?.grp_id, grp_name: grpData?.grp_name },
                                              grpCode: grpData?.grp_test_code,
                                              grpshortName: grpData?.grp_short_name,
                                              NewtestName: testData?.test_name,
                                              testId: testData?.test_id,
                                              testCode: testData?.test_code,
                                              testshortName: testData?.test_short_name,
                                              NewresultType: testData?.result_type,
                                              newPrintOrder: testData?.print_order,
                                              loincCode: testData?.loinc_code,
                                              cptCode: testData?.cpt_code,
                                              sacVal: testData?.sac,
                                              nameSpecimen: (testData?.specimen_master_id && testData?.specimen) ? {id: testData?.specimen_master_id, specimen_name: testData?.specimen} : null,
                                              specQty: testData?.specimen_quantity,
                                              Qtyunit: testData?.specimen_unit,
                                              temperature: testData?.specimen_temp,
                                              Tempunit: testData?.specimen_temp_unit,
                                              nameMethod:(testData?.method_master_id && testData?.method) ? {id: testData?.method_master_id, method_name: testData?.method} : null,
                                              cutOffHour: (offtime && offtime[0]) || null,
                                              cutOffMin: (offtime && offtime[1]) || null,
                                              nameAnalyzer: (testData?.analyser_master_id && testData?.analyser) ? {id: testData?.analyser_master_id, analyser_name: testData?.analyser} : null,
                                              GroupAmt: grpData?.grp_amt,
                                              TestAmt: testData?.test_amt,
                                              testNameLength: numbers,
                                              isBillable: !testData?.is_billable,
                                              duplicateSelected: JSON.stringify(testData),
                                            },()=>{this.renderAddNewTest(); this.getMethodList()})
                                            
                                            // this.setState({
                                            //   ref_data: [{
                                            //     reference_from: '',
                                            //     reference_to: '',
                                            //     panichigh: '',
                                            //     paniclow: '',
                                            //     unit: ''
                                            //   }], range_data: {
                                            //     gender: '',
                                            //     age_from: '',
                                            //     age_to: '',
                                            //     age_from_unit: 'years',
                                            //     age_to_unit: 'years',
                                            //     reference_range: '',
                                            //     description: '',
                                            //     interpretation: '',
                                            //   },
                                            //   selectedData: null
                                            // }, () => { this.handleOpenPopup(testData, grpData, item) })
                                          })
                                        }}
                                      >Edit Test Details</Button>
                                    </Box>
                                    <Box component={'div'} mb={'0.5vw'}>
                                      <Button startIcon={<AddCircleIcon />}
                                        sx={{ textTransform: 'capitalize' }}
                                        disabled={!commonDisable}
                                        className='eMed_Lab_act_btn'
                                        onClick={() => { 
                                          if(testData?.result_type === 3){
                                            this.setState({
                                              EditCultureTest: true,
                                              CulturalTestList: testData?.test_data?.length > 0 ? testData?.test_data : [],
                                              selectedData: JSON.parse(JSON.stringify(testData)),
                                            })
                                          }else{
                                          this.setState({
                                            editTestData: testData,
                                            isAddNewRef: true,
                                            addTestPopup: true,
                                            NewresultType: testData?.result_type,
                                            stepNumber: 1,
                                            duplicateSelected: JSON.stringify(testData),
                                            range_data: {
                                              gender: '',
                                              age_from: '',
                                              age_to: '',
                                              age_from_unit: 'years',
                                              age_to_unit: 'years',
                                              reference_range: '',
                                              description: '',
                                              interpretation: '',
                                            },
                                            ref_data: [{
                                              reference_from: '',
                                              reference_to: '',
                                              panichigh: '',
                                              paniclow: '',
                                              unit: '',
                                            }],
                                            ref_type: [{
                                              reference_type: '',
                                              reference_range: '',
                                              panichigh: '',
                                              paniclow: '',
                                              unit: ''
                                            }],
                                          },()=>{
                                            if (this.state.NewresultType === 5) {
                                              steps.push('Possible Test')
                                            } else {
                                              steps.length = 0
                                              steps.push('Test Details', 'Parameters')
                                            }
                                            this.assignDatas(testData, grpData, item)
                                          })
                                        }
                                          // this.handleOpenPopup(testData, grpData) 
                                        }}
                                      >Add New Referrence</Button>
                                    </Box>
                                  </Box>
                                </TableCell>

                                <TableCell sx={{ padding: '0vw' }}>
                                  {testData?.test_data?.map((rangeData, rangeIndex) => (
                                    <Table key={rangeIndex}>
                                      {testData?.result_type == 3 && rangeIndex == 0 ?
                                        <TableCell style={{ fontSize: "1vw", fontWeight: "600" }}>Kindly click the action icon to view the bacteria details</TableCell>
                                        : null}
                                      {testData?.result_type !== 3 ?
                                        <div>
                                          <TableCell className='eMed_Tbl_rst_Cell_One'>
                                            {rangeData?.gender ?
                                              <Box>
                                                <Typography >
                                                  {`${rangeData?.gender === 'M' ? 'Male' : rangeData?.gender === 'F' ? 'Female' : 'Common'}`}
                                                </Typography>
                                                <Typography fontSize={'0.7vw'}>
                                                  {`${rangeData?.age_from} ${rangeData?.age_from_unit} - ${rangeData?.age_to} ${rangeData?.age_to_unit}`}
                                                </Typography>
                                              </Box>
                                              : '-'}
                                          </TableCell>

                                          <TableCell sx={{ padding: '0vw' }}>
                                            {rangeData?.reference_data ?
                                              rangeData?.reference_data?.map((refData, refIndex) => (
                                                <Table key={refIndex}>

                                                  <TableCell className='eMed_Tbl_rst_Cell_Three'>
                                                    {refData?.reference_type ?
                                                      (refData?.reference_type && refData?.reference_range) ? (refData?.unit + refData?.reference_type + refData?.reference_range)?.length > 18 ?
                                                        <Tooltip placement='top' title={`${refData?.reference_type ? refData?.reference_type : '-'}  ${refData?.reference_range ? refData?.reference_range : '-'} (${refData?.unit ? refData?.unit : '-'})`}>
                                                          <Box>{(`${refData?.reference_type ? refData?.reference_type : '-'}  ${refData?.reference_range ? refData?.reference_range : '-'} (${refData?.unit ? refData?.unit : '-'})`)?.slice(0, 18) + '...'}</Box>
                                                        </Tooltip> :
                                                        <Typography>{`${refData?.reference_type ? refData?.reference_type : '-'}  ${refData?.reference_range ? refData?.reference_range : '-'} (${refData?.unit ? refData?.unit : '-'})`}</Typography> : '-'
                                                      :
                                                      // (refData?.reference_from && refData?.reference_to) ? (refData?.unit + refData?.reference_from + refData?.reference_to)?.length > 18 ?
                                                      (refData?.reference_from || (refData?.reference_from && refData?.reference_to)) ? (refData?.unit + refData?.reference_from + refData?.reference_to)?.length > 18 ?
                                                        <Tooltip placement='top' title={`${refData?.reference_from ? refData?.reference_from : '-'} - ${refData?.reference_to ? refData?.reference_to : '-'} (${refData?.unit ? refData?.unit : '-'})`}>
                                                          <Box>{(`${refData?.reference_from ? refData?.reference_from : '-'} - ${refData?.reference_to ? refData?.reference_to : '-'} (${refData?.unit ? refData?.unit : '-'})`)?.slice(0, 18) + '...'}</Box>
                                                        </Tooltip> :
                                                        <Typography>{`${refData?.reference_from ? refData?.reference_from : '-'} - ${refData?.reference_to ? refData?.reference_to : '-'} (${refData?.unit ? refData?.unit : '-'})`}</Typography> : '-'
                                                    }
                                                  </TableCell>

                                                  <TableCell className='eMed_Tbl_rst_Cell_four'>
                                                    {rangeData?.description?.length > 25 ?
                                                      <Tooltip placement='top' title={rangeData?.description}><Box>{rangeData?.description.slice(0, 25) + '...'}</Box></Tooltip> :
                                                      <Typography>{rangeData?.description ? rangeData?.description : '-'}</Typography>}
                                                  </TableCell>

                                                </Table>
                                              )) : <div style={{ width: "22vw", paddingLeft: "35%" }}>-</div>}
                                          </TableCell>
                                        </div> : null}

                                      {testData?.result_type !== 3 || (testData?.result_type == 3 && rangeIndex == 0) ?
                                        <TableCell sx={{ paddingTop: '0vw' }}>
                                          <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                            <Button
                                              className='eMed_Lab_act_btn'
                                              disabled={!commonDisable}
                                              sx={{ height: '1.5vw', width: '1.5vw' }}
                                              onClick={() => {
                                                if(testData?.result_type === 3){
                                                  this.setState({
                                                    isEdit: true,
                                                    selectedEditIndex: rangeIndex,
                                                    editTestData: testData,
                                                    editGrpData: grpData,
                                                  }
                                                  , () => {this.handleOpenPopup(testData, rangeData); this.assignDatas(testData, grpData, item);} )
                                                }else{
                                                  this.setState({
                                                    isEdit: true,
                                                    selectedEditIndex: rangeIndex,
                                                    addTestPopup: true,
                                                    stepNumber: 1,
                                                    editTestData: testData,
                                                    editGrpData: grpData,
                                                    isEditRefData: true,
                                                    NewresultType: testData?.result_type,
                                                  }, () => {
                                                    this.handleOpenPopup(testData, rangeData)
                                                    this.assignDatas(testData, grpData, item);
                                                    if(testData?.result_type === 5){
                                                      steps.push('Possible Test')
                                                      this.setState({
                                                        PossibleTestList: JSON.parse(JSON.stringify(testData?.possible_result_items)) || []
                                                      })
                                                   }})
                                                }
                                              }}
                                            >
                                              <Tooltip placement='top' title='Edit' arrow>
                                                <Box component={'img'} src={commonDisable ? ImagePaths.LabEditButton.default : ImagePaths.DisabledEdit.default} height={'1.5vw'} width={'1.5vw'} mr={'1vw'} />
                                              </Tooltip>
                                            </Button>
                                            <Button
                                              disabled={!commonDisable}
                                              className='eMed_Lab_act_btn'
                                              sx={{ height: '1.5vw', width: '1.5vw' }}
                                              onClick={() => {
                                                if (testData?.test_data?.length > 1) {
                                                  this.deleteNewReferenceData(testData, rangeIndex)
                                                } else {
                                                  this.errorMessage('Reference Data Should be Atleast One')
                                                }
                                              }}
                                            >
                                              <Tooltip placement='top' title='Delete' arrow>
                                                <Box component={'img'} src={commonDisable ? ImagePaths.Delete.default : ImagePaths.DisabledDelete.default} height={'1.5vw'} width={'1.5vw'} />
                                              </Tooltip>
                                            </Button>
                                          </Box>
                                        </TableCell> : null}

                                    </Table>
                                  ))}
                                </TableCell>
                              </Table>
                            ))}
                          </TableCell>
                        </Table>
                      ))}
                    </Table>
                  </TableCell>
                </TableRow>
              )) : <TableRow>
                <TableCell colSpan={8} sx={{ borderBottom: 'none' }}>
                  <Typography>No Records Found</Typography></TableCell>
              </TableRow>}
            </TableBody>

          </Table>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  resetValues = () => {
    let states = this.state
    let numbers = [];
    for (let i = 0; i <= states?.editTestData.length - 1; i++) {
      numbers.push(i + 1)
    }
    let resetNewRefData = [{
      reference_from: '',
      reference_to: '',
      panichigh: '',
      paniclow: '',
      unit: ''
    }]
    let resetRangeData = {
      gender: '',
      age_from: '',
      age_to: '',
      age_from_unit: 'years',
      age_to_unit: 'years',
      reference_range: '',
      description: '',
      interpretation: '',
    }
    let resetRefType = [{
      reference_type: '',
      reference_range: '',
      panichigh: '',
      paniclow: '',
      unit: ''
    }]
    let assigndata = JSON.parse(this.state.duplicateSelected)
    let offtime = assigndata?.cut_off_time?.split(":")
    this.setState({
      NewtestName: assigndata?.test_name,
      testCode: assigndata?.test_code,
      testshortName: assigndata?.test_short_name,
      NewresultType: assigndata?.result_type,
      newPrintOrder: assigndata?.print_order,
      loincCode: assigndata?.loinc_code,
      cptCode: assigndata?.cpt_code,
      sacVal: assigndata?.sac,
      nameSpecimen: (assigndata?.specimen_master_id && assigndata?.specimen) ? { id: assigndata?.specimen_master_id, specimen_name: assigndata?.specimen } : null,
      specQty: assigndata?.specimen_quantity,
      Qtyunit: assigndata?.specimen_unit,
      temperature: assigndata?.specimen_temp,
      Tempunit: assigndata?.specimen_temp_unit,
      nameMethod: (assigndata?.method_master_id && assigndata?.method) ? { id: assigndata?.method_master_id, method_name: assigndata?.method } : null,
      cutOffHour: (offtime && offtime[0]) || null,
      cutOffMin: (offtime && offtime[1]) || null,
      nameAnalyzer: (assigndata?.analyser_master_id && assigndata?.analyser) ?  { id: assigndata?.analyser_master_id, analyser_name: assigndata?.analyser } : null,
      TestAmt: assigndata?.test_amt,
      testNameLength: numbers,
      ref_data: resetNewRefData,
      ref_type: resetRefType,
      range_data: resetRangeData,
    },()=>{
      if(assigndata?.result_type != 5){
        steps.length = 0
        steps.push('Test Details', 'Parameters')
      }
    })
  }

  stepOne = () => {
    try {
      let { GroupAmt, TestAmt } = this.state
      let states = this.state
      var numbers = [];
      let showCode = localGetItem('lab_test_code') ? JSON.parse(localGetItem('lab_test_code')) : JSON.parse(localGetItem('loggedInUserInfo'))?.lab_test_code
      let nextDis = states.NewdepartmentName && states.NewgroupName && states.NewtestName && states.NewresultType && states.newPrintOrder && states.nameSpecimen && states.GroupAmt
      return (
        <Box component={'div'} sx={{ width: '78.5vw', height: '70.5vh', margin: '.8vw' }}>
          <Box component={'div'} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography fontWeight={'bold'} fontSize={'1vw'}>Department and Group Details</Typography>
            <FormControlLabel control={<Checkbox checked={!this.state.isBillable} onChange={(e) => { this.setState({ isBillable: !this.state.isBillable }) }} />} label={"Is Billable"} />
          </Box>
          <Box component={'div'} sx={{ height: '60vh', }}>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Autocomplete
                size='small'
                clearIcon
                disabled={this.state.isEditTestDetails}
                options={this.state.departmentListData}
                getOptionLabel={(option) => option?.dept_name}
                value={this.state.NewdepartmentName}
                onChange={(e, newValue) => {
                  this.setState({ NewdepartmentName: newValue, NewgroupName: null, NewgrpListData: [], newTestNameLength: [], TestAmt: 0, GroupAmt: 0, NewtestListData: [], NewtestName: "", newPrintOrder: null, nameMethod: null }, () => {
                    this.getNewTestData()
                    this.getMethodList()
                  })
                }}
                InputProps={{ emed_tid: 'dept_name' }}
                sx={{ width: "15.3vw", marginRight: "0.5vw" }}
                renderInput={(params) => (<TextField {...params} size='small' label='Department Name *' />)}
              />
              <Autocomplete
                size='small'
                disabled={this.state.isEditTestDetails}
                options={this.state.NewdepartmentName !== null ? this.state.NewgrpListData : []}
                getOptionLabel={(option) => option.grp_name}
                value={this.state.NewgroupName}
                onChange={(e, newValue) => {
                  this.setState({ NewgroupName: newValue, GroupAmt: newValue?.grp_amt ? newValue?.grp_amt : 0, hasCode: newValue?.grp_test_code ? true : false, grpCode: newValue?.grp_test_code ? newValue?.grp_test_code : '', grpshortName : newValue?.grp_short_name ? newValue?.grp_short_name : '' }, () => {
                    var duplicate = JSON.parse(this.state.duplicateResult)
                    if (this.state.NewdepartmentName) {
                      duplicate?.filter(element => element.dept_id === this.state.NewdepartmentName?.dept_id ? duplicate = [element] : duplicate)
                    }
                    let data = []
                    if (this.state.NewgroupName && duplicate?.length) {
                      duplicate[0].grp_data.forEach(element => {
                        if (element.grp_id === this.state.NewgroupName?.grp_id) {
                          data.push(element)
                        }
                      })
                      duplicate[0].grp_data = data
                    }
                    for (let i = 0; i <= this.state?.NewgroupName?.grp_test_data?.length; i++) {
                      numbers?.push(i + 1)
                    }
                    this.setState({ NewtestName: "", newTestNameLength: numbers, newPrintOrder: numbers.length, NewtestListData: [], TestAmt: 0 }, () => { this.setState({ NewtestListData: data?.[0]?.grp_test_data }) })
                  })
                }}
                InputProps={{ emed_tid: 'grp_name' }}
                sx={{ width: '15.3vw', marginRight: "0.5vw" }}
                renderInput={(params) => (<TextField {...params} label='Group Name *' onChange={(e) => {
                  var newGrp = { grp_name: e.target.value, grp_id: "" }
                  this.setState({
                    NewgroupName: newGrp, GroupAmt: 0, NewtestName: "", newPrintOrder: 1, NewtestListData: [], TestAmt: 0, grpshortName : "" ,
                  })
                }} />)}
              />
              {showCode ?
                <TextField
                  size='small'
                  sx={{ width: '15.3vw', mr: '0.5vw' }}
                  disabled={this.state.isEditTestDetails}
                  label='Group Code'
                  value={this.state.grpCode}
                  onChange={(e) => {
                    let value = e.target.value
                    if (value?.length <= Code_Length || value === '') {
                      this.setState({ grpCode: value })
                    }
                  }}
                  InputProps={{ emed_tid: 'grp_code' }}
                /> : null}
              <TextField
                size='small'
                sx={{ width: '15.3vw', mr: '0.5vw' }}
                disabled={this.state.isEditTestDetails}
                label='Group Short Name'
                value={this.state.grpshortName}
                onChange={(e) => {
                  let value = e.target.value
                  if (CommonValidation.ALPHA_NUMARIC(value) || value === '') {
                    this.setState({ grpshortName: value })
                  }
                }}
                inputProps={{ maxLength: 50 }}
                InputProps={{ emed_tid: 'grp_short_name' }}
              />
              {this.inputTextBox('NewtestName', this.state.NewtestName, 'Test Name *', '15.3vw', '0', null, false)}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', mt: '1vw' }}>
              {showCode ? <TextField
                size='small'
                sx={{ width: '15.3vw', mr: '0.5vw', }}
                label='Test Code'
                value={this.state.testCode}
                onChange={(e) => {
                  let value = e.target.value
                  if (value?.length <= Code_Length || value === '') {
                    this.setState({ testCode: value })
                  }
                }}
                InputProps={{ emed_tid: 'tst_code' }}
              /> : null}
              <TextField
                size='small'
                sx={{ width: '15.3vw', mr: '0.5vw', }}
                label='Test Short Name'
                value={this.state.testshortName}
                onChange={(e) => {
                  let value = e.target.value
                  if (CommonValidation.ALPHA_NUMARIC(value) || value === '') {
                    this.setState({ testshortName: value })
                  }
                }}
                inputProps={{ maxLength: 50 }}
                InputProps={{ emed_tid: 'test_short_name' }}
              />
              <FormControl size='small' sx={{ width: '15.3vw', mr: '0.5vw', }}>
                <InputLabel>Result Type *</InputLabel>
                <Select
                  label='Result Type'
                  disabled={this.state.isEditTestDetails}
                  value={this.state.NewresultType}
                  onChange={(e) => { this.onChangeHandler(e, 'NewresultType') }}
                >
                  {Result_Type_List?.map((item) => (
                    <MenuItem value={item.value}>{item.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Autocomplete
                value={this.state.newPrintOrder}
                disabled={this.state.isEditTestDetails ? false : true}
                options={this.state.isEditTestDetails ? this.state.testNameLength.map((item) => item) : this.state.newTestNameLength.map((item) => item)}
                getOptionLabel={(option) => option}
                onChange={(event, newValue) => {
                  this.setState({ newPrintOrder: newValue })
                }}
                renderInput={(params) => (<TextField {...params} size='small' sx={{ width: '15.3vw', mr: '0.5vw', }} label='Print No. *' />)}
              />
              <TextField
                size='small'
                sx={{ width: '15.3vw' }}
                label='LOINC Code'
                value={this.state.loincCode}
                onChange={(e) => {
                  let value = e.target.value
                  if (CommonValidation.ALPHA_NUM_SPLCHAR(value) || value === '') {
                    this.setState({ loincCode: value })
                  }
                }}
                inputProps={{ maxLength: 50 }}
                InputProps={{ emed_tid: 'loinc_code' }}
              />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', mt: '1vw' }}>
              <TextField
                size='small'
                sx={{ width: '15.3vw', mr: '0.5vw' }}
                label='CPT Code'
                value={this.state.cptCode}
                onChange={(e) => {
                  let value = e.target.value
                  if (CommonValidation.ALPHA_NUM_SPLCHAR(value) || value === '') {
                    this.setState({ cptCode: value })
                  }
                }}
                inputProps={{ maxLength: 50 }}
                InputProps={{ emed_tid: 'cpt_code' }}
              />
              <TextField
                size='small'
                sx={{ width: '15.3vw', mr: '0.5vw' }}
                label='SAC'
                value={this.state.sacVal}
                onChange={(e) => {
                  let value = e.target.value
                  if (CommonValidation.ALPHA_NUM_SPLCHAR(value) || value === '') {
                    this.setState({ sacVal: value })
                  }
                }}
                inputProps={{ maxLength: 50 }}
                InputProps={{ emed_tid: 'sac_val' }}
              />
            </Box>
            <Box mb={'.5vw'} mt={'1vw'}>
              <Typography fontWeight={'bold'} fontSize={'1vw'}>Specimen Details</Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Autocomplete
                size='small'
                clearIcon
                options={this.state.specimenList || []}
                getOptionLabel={(option) => option?.specimen_name}
                value={this.state.nameSpecimen}
                onChange={(e, newValue) => {
                  this.setState({ nameSpecimen: newValue })
                }}
                sx={{ width: "15.3vw", marginRight: "0.5vw" }}
                renderInput={(params) => (<TextField {...params} size='small' label='Specimen *' />)}
              />
              <TextField
                size='small'
                sx={{ width: '15.3vw', mr: '0.5vw' }}
                label='Quantity'
                value={this.state.specQty}
                onChange={(e) => {
                  let value = e.target.value
                  if (CommonValidation.numberOnly(value) || value === '') {
                    this.setState({ specQty: +value })
                  }
                }}
                inputProps={{ maxLength: 10 }}
                InputProps={{ emed_tid: 'specQty' }}
              />
              <Autocomplete
                size='small'
                clearIcon
                disabled={!this.state.specQty}
                options={this.state.refUnitList || []}
                getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.unit}
                value={this.state.Qtyunit}
                onChange={(e, newValue) => {
                  this.setState({ Qtyunit: newValue.unit })
                }}
                sx={{ width: "15.3vw", marginRight: "0.5vw" }}
                renderInput={(params) => (<TextField {...params} size='small' label='Unit' />)}
              />
              <TextField
                size='small'
                sx={{ width: '15.3vw', mr: '0.5vw' }}
                label='Temperature'
                value={this.state.temperature}
                onChange={(e) => {
                  let value = e.target.value
                  if (CommonValidation.numberOnly(value) || value === '') {
                    this.setState({ temperature: +value })
                  }
                }}
                inputProps={{ maxLength: 3 }}
                InputProps={{ emed_tid: 'tempreature' }}
              />
              <Autocomplete
                size='small'
                clearIcon
                disabled={!this.state.temperature}
                options={Temp}
                getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.label}
                value={this.state.Tempunit}
                onChange={(e, newValue) => {
                  this.setState({ Tempunit: newValue.value })
                }}
                sx={{ width: "15.3vw", marginRight: "0.5vw" }}
                renderInput={(params) => (<TextField {...params} size='small' label='Unit' />)}
              />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', mt: '1vw' }}>
              <Autocomplete
                size='small'
                clearIcon
                disabled={!this.state.NewdepartmentName}
                options={this.state.MethodList || []}
                getOptionLabel={(option) => option?.method_name}
                value={this.state.nameMethod}
                onChange={(e, newValue) => {
                  this.setState({ nameMethod: newValue })
                }}
                sx={{ width: "15.3vw", marginRight: "0.5vw" }}
                renderInput={(params) => (<TextField {...params} size='small' label='Method' />)}
              />
              <Autocomplete
                size='small'
                clearIcon
                options={this.state.analyserList || []}
                getOptionLabel={(option) => option?.analyser_name}
                value={this.state.nameAnalyzer}
                onChange={(e, newValue) => {
                  this.setState({ nameAnalyzer: newValue })
                }}
                sx={{ width: "15.3vw", marginRight: "0.5vw" }}
                renderInput={(params) => (<TextField {...params} size='small' label='Analyser Type' />)}
              />
              <Autocomplete
                disableClearable
                size='medium'
                value={this.state.cutOffHour}
                options={this.TimeListArray?.map((item) => item)}
                onChange={(event, newValue) => {
                  this.setState({
                    cutOffHour: newValue,
                  })
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ width: '10vw', marginRight: '0.5vw' }}
                    size='small'
                    label={'Cut Off Hour'}
                  />
                )} />
              <Autocomplete
                disableClearable
                size='medium'
                value={this.state.cutOffMin}
                options={this.TimeListMin?.map((item) => item)}
                onChange={(event, newValue) => {
                  this.setState({
                    cutOffMin: newValue,
                  })
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ width: '10vw', marginRight: '0.5vw' }}
                    size='small'
                    label={'Cut Off Min'}
                  />
                )} />
            </Box>
            <Box mb={'.5vw'} mt={'1vw'}>
              <Typography fontWeight={'bold'} fontSize={'1vw'}>Test Amount</Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', mt: '1vw' }}>
              <TextField
                size='small'
                sx={{ width: '15.3vw', mr: '0.5vw' }}
                disabled={this.state.isEditTestDetails}
                label='Group Amount*'
                name='GroupAmt'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end"><CurrencyRupeeIcon /></InputAdornment>
                  ),
                  emed_tid: 'grp_code'
                }}
                value={this.state.GroupAmt}
                inputProps={{ maxLength: 7 }}
                onChange={(e) => {
                  let value = e.target.value
                  if (CommonValidation.numberOnly(value) || value === '') {
                    this.setState({ GroupAmt: +value })
                  }

                }}
              />
              <TextField
                size='small'
                sx={{ width: '15.3vw', mr: '0.5vw' }}
                label='Test Amount'
                name='TestAmt'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end"><CurrencyRupeeIcon /></InputAdornment>
                  ),
                  emed_tid: 'grp_code'
                }}
                inputProps={{ maxLength: 7 }}
                value={this.state.TestAmt}
                onChange={(e) => {
                  let value = e.target.value
                  if (CommonValidation.numberOnly(value) || value === '') {
                    this.setState({ TestAmt: +value })
                  }

                }}
              />
            </Box>
          </Box>
          <Box component={'div'} sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '.4vw' }}>
            {this.state.isEditTestDetails ? <Button size='small' variant='outlined' sx={{ textTransform: 'capitalize', width: '8vw', marginRight: '1vw' }}
              onClick={() => {
                this.resetValues()
              }}>Reset</Button> : null}
            <Button size='small' variant='contained' sx={{ textTransform: 'capitalize', width: '8vw' }}
              disabled={this.state.disableBtn ? true : !nextDis}
              onClick={() => {
                if (this.state.isEditTestDetails) {
                  this.postNewReferenceData()
                } else {
                  if(this.state.specQty && !this.state.Qtyunit){
                      this.errorMessage('Please Enter the Specimen Qty Unit')
                  }else if(this.state.temperature && !this.state.Tempunit){
                    this.errorMessage('Please Enter the Specimen Tempreture Unit')
                  }else{
                    this.createNewTest()
                  }
                }
              }}>{this.state.isEditTestDetails ? 'Update' : 'Next'}</Button>
          </Box>
        </Box>
      )
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  stepTwo = () => {
    try {
      let { ref_data, range_data, editRangeData, receivedData, ref_type } = this.state
      let states = this.state
      return (
        <Box component={'div'} sx={{ width: '78.5vw', height: '70.5vh', margin: '.8vw' }}>
          <Box component={'div'} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: '.5vw', }}>
            {!(this.state.isAddNewRef || this.state.isEditRefData) &&
              (receivedData?.test_name.length > 90 ? (
                <Tooltip placement='top' title={receivedData?.test_name}>
                  <Typography fontWeight="bold" fontSize="1vw" mr="1vw">
                    {`Test Name : ${receivedData?.test_name?.slice(0, 90)}...`}
                  </Typography>
                </Tooltip>
              ) : (
                <Typography fontWeight="bold" fontSize="1vw" mr="1vw">
                  {`Test Name : ${receivedData?.test_name}`}
                </Typography>
              ))
            }
            <FormControl size='small' sx={{ width: '15.3vw', }}>
              <InputLabel>Result Type</InputLabel>
              <Select
                label='Result Type'
                disabled={(this.state.isAddNewRef) ? false : true}
                value={(this.state.isAddNewRef || this.state.isEditRefData) ? this.state.NewresultType : receivedData?.result_type}
                onChange={(e) => { this.state.isAddNewRef ? this.onChangeHandler(e, 'NewresultType') : this.onChangeHandler(e, 'resultType') }}
              >
                {Result_Type_List?.map((item) => (
                  <MenuItem disabled={ this.state.isAddNewRef ? item.value == 3 : null} value={item.value}>{item.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box component={'div'} sx={{ height: '60vh', overflowY: 'scroll', }}>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Typography fontWeight={'bold'} fontSize={'1vw'}>Add Parameters</Typography>
            </Box>

            {((this.state.isAddNewRef || this.state.isEditRefData) ? this.state.NewresultType : states?.receivedData?.result_type) == 3 ?
              <Box sx={{ display: 'flex', flexDirection: 'row', mt: '1vw' }}>
                <Box>
                  {this.state.containers.map((container, index) => (
                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                      <Box key={container.id}>
                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", padding: "0.75vw" }}>
                          <Autocomplete
                            size='small'
                            options={this.state.BacteriaList}
                            getOptionLabel={(option) => (option?.organism_name)}
                            value={this.state?.CulturalTestData[index]?.BacteriaData}
                            onChange={(event, newValue) => {
                              states.CulturalTestData[index].BacteriaData = newValue
                              states.CulturalTestData[index].AntibioticData = null
                              this.setState({ states },()=>{this.getAntiBacteriaList(newValue);})
                            }}
                            renderInput={(params) => (
                              <TextField {...params} style={{ width: "16vw", marginRight: "0.8vw" }}
                                label={'Bacteria Name*'}
                              />
                            )}
                          />
                          <Box style={{ fontSize: "1vw", marginRight: "1vw" }}>Zone Size (mm)</Box>
                          {this.renderTextBox("S", '13vw', states?.CulturalTestData[index]?.zone_size_s, 'zone_size_s', index)}
                          {this.renderTextBox("I", '13vw', states?.CulturalTestData[index]?.zone_size_i, 'zone_size_i', index)}
                          {this.renderTextBox("R", '13vw', states?.CulturalTestData[index]?.zone_size_r, 'zone_size_r', index)}
                        </Box>
                        <Box style={{ display: "flex", flexDirection: "row", alignItems: "center", padding: "0.75vw", marginTop: "-0.5vw" }}>
                          <Autocomplete
                            size='small'
                            options={this.state?.AntibioticList}
                            disabled={!this.state?.CulturalTestData[index]?.BacteriaData}
                            getOptionLabel={(option) => (option?.antibiotic_name)}
                            value={this.state?.CulturalTestData[index]?.AntibioticData}
                            onChange={(event, newValue) => {
                              states.CulturalTestData[index].AntibioticData = newValue
                              this.setState({ states })
                            }}
                            renderInput={(params) => (
                              <TextField {...params} style={{ width: "16vw", marginRight: "0.65vw" }}
                                label={'Antibiotic Name*'}
                              />
                            )}
                          />
                          <Box style={{ fontSize: "1vw", marginRight: "0.75vw" }}>MIC Break Point</Box>
                          {this.renderTextBox("S", '13vw', states?.CulturalTestData[index]?.mic_s, 'mic_s', index)}
                          {this.renderTextBox("I", '13vw', states?.CulturalTestData[index]?.mic_i, 'mic_i', index)}
                          {this.renderTextBox("R", '13vw', states?.CulturalTestData[index]?.mic_r, 'mic_r', index)}
                        </Box>
                      </Box>
                      <Button disabled={index == 0 ? true : false} className='eMed_Lab_act_btn' onClick={() => { this.DeleteCulture(index) }}>
                        <Box component={'img'} src={index == 0 ? ImagePaths.DisabledDelete.default : ImagePaths.Delete.default} alt='close' height={'1.5vw'} width={'1.5vw'} />
                      </Button>
                    </Box>
                  ))}
                  <Box>
                    <Button
                      startIcon={<AddCircleIcon />}
                      className='eMed_Lab_act_btn'
                      sx={{ textTransform: 'capitalize' }}
                      onClick={() => { this.addContainer() }}
                    >Add New Parameter</Button>
                  </Box>
                </Box>
              </Box> :
              <Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', mt: '1vw' }}>
                  <FormControl size='small' sx={{ width: '15.3vw', mr: '0.5vw' }}>
                    <InputLabel>Gender</InputLabel>
                    <Select
                      label='Result Type'
                      value={this.state.isEdit ? editRangeData?.gender : range_data?.gender}
                      onChange={(e) => { this.onChangeHandler(e, 'gender') }}
                    >
                      {Gender_List?.map((item) => (
                        <MenuItem value={item.value}>{item.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {this.inputTextBox('ageFrom', this.state.isEdit ? editRangeData?.age_from : range_data.age_from, "Age From", '8.3vw', null, null, false)}
                  <FormControl size='small' sx={{ width: '6.5vw', marginBottom: '1vw', ml: "0.5vw", mr: '0.5vw' }}>
                    <Select
                      value={this.state.isEdit ? editRangeData?.age_from_unit : range_data?.age_from_unit}
                      onChange={(e) => { this.onChangeHandler(e, 'fromDays') }}
                    >
                      {Days_List?.map((item) => (
                        <MenuItem value={item.value}>{item.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {this.inputTextBox('ageTo', this.state.isEdit ? editRangeData?.age_to : range_data.age_to, "Age To", '8.3vw', null, null, false)}
                  <FormControl size='small' sx={{ width: '6.5vw', marginBottom: '1vw', marginLeft: "0.5vw" }}>
                    <Select
                      value={this.state.isEdit ? editRangeData?.age_to_unit : range_data.age_to_unit}
                      onChange={(e) => { this.onChangeHandler(e, 'toDays') }}
                    >
                      {Days_List?.map((item) => (
                        <MenuItem value={item.value}>{item.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box>
                  {((this.state.isAddNewRef || this.state.isEditRefData) ? this.state.NewresultType : states?.receivedData?.result_type) === 7 ?
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                      <Box>
                        {this.state.ref_type.map((item, index) => {
                          return (
                            <Box key={index} display={'flex'}>
                              <FormControl size='small' sx={{ width: '14vw', marginBottom: '1vw', marginRight: "0.5vw" }}>
                                <InputLabel>Reference Type</InputLabel>
                                <Select
                                  label='Reference Type'
                                  value={item.reference_type}
                                  onChange={(e) => { this.onChangeHandler(e, 'referanceType', index) }}
                                >
                                  {Referance_Type_List?.map((item) => (
                                    <MenuItem style={{ textAlign: "center", fontSize: "1vw" }} value={item.value}>{item.label}</MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              {this.inputTextBox('refRange', item.reference_range, "Reference Range", '14vw', '0.5vw', index, false)}
                              <Autocomplete
                                disableClearable
                                freeSolo
                                value={item.unit}
                                options={this.state.refUnitList?.length != 0 ? this.state.refUnitList : []}
                                getOptionLabel={option => typeof (option) === "string" ? option : option.unit}
                                onChange={(event, newValue) => {
                                  ref_type[index].unit = newValue.unit
                                  this.setState({ ref_type })
                                }}
                                onInputChange={(e, val) => {
                                  ref_type[index].unit = val
                                  this.setState({ ref_type })
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    sx={{ width: '14vw', mr: '0.5vw' }}
                                    label={'Unit'}
                                    size="small"
                                    InputProps={{
                                      ...params.InputProps,
                                      type: 'search',
                                    }}
                                  />
                                )}
                              />
                              <TextField
                                size='small'
                                sx={{ width: '14vw', mr: '0.5vw' }}
                                label='Panic Low'
                                value={item.paniclow}
                                onChange={(e) => {
                                  ref_type[index].paniclow = e.target.value
                                  this.setState({ ref_type })
                                }}
                                inputProps={{ maxLength: 20 }}
                                InputProps={{ emed_tid: 'panic_low' }}
                              />
                              <TextField
                                size='small'
                                sx={{ width: '14vw', mr: '0.5vw' }}
                                label='Panic High'
                                value={item.panichigh}
                                onChange={(e) => {
                                  ref_type[index].panichigh = e.target.value
                                  this.setState({ ref_type })
                                }}
                                inputProps={{ maxLength: 20 }}
                                InputProps={{ emed_tid: 'panic_high' }}
                              />
                              {((this.state.isAddNewRef || this.state.isEditRefData) ? this.state.NewresultType : states?.receivedData?.result_type) === 7 ?
                                this.state.ref_type?.length > 1 ?
                                  <Button className='eMed_Lab_act_btn' onClick={() => {
                                    var { ref_type } = this.state
                                    ref_type.splice(index, 1)
                                    this.setState({ ref_type })
                                  }}>
                                    <Box component={'img'} src={ImagePaths.Delete.default} alt='close' height={'1vw'} width={'1vw'} />
                                  </Button>
                                  : null : null}
                            </Box>
                          )
                        })}
                        {((this.state.isAddNewRef || this.state.isEditRefData) ? this.state.NewresultType : states?.receivedData?.result_type) === 7 ?
                          <Box>
                            <Button
                              startIcon={<AddCircleIcon />}
                              className='eMed_Lab_act_btn'
                              sx={{ textTransform: 'capitalize' }}
                              onClick={() => { this.onAddNewReferance() }}
                            >Add New Parameter</Button>
                          </Box> : null
                        }
                      </Box>
                    </Box> :
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                      <Box>
                        {this.state.ref_data.map((item, index) => {
                          return (
                            <Box key={index} display={'flex'}>
                              {this.inputTextBox('rangeFrom', item.reference_from, "Range From", '14vw', '0.5vw', index, false)}
                              {this.inputTextBox('rangeTo', item.reference_to, "Range To", '14vw', '0.5vw', index, false)}
                              <Autocomplete
                                disableClearable
                                freeSolo
                                value={item.unit}
                                options={this.state.refUnitList?.length != 0 ? this.state.refUnitList : []}
                                getOptionLabel={option => typeof (option) === "string" ? option : option.unit}
                                onChange={(event, newValue) => {
                                  ref_data[index].unit = newValue.unit
                                  this.setState({ ref_data })
                                }}
                                onInputChange={(e, val) => {
                                  ref_data[index].unit = val
                                  this.setState({ ref_data })
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    sx={{ width: '14vw', mr: '0.5vw' }}
                                    label={'Unit'}
                                    size="small"
                                    InputProps={{
                                      ...params.InputProps,
                                      type: 'search',
                                    }}
                                  />
                                )}
                              />
                              <TextField
                                size='small'
                                sx={{ width: '14vw', mr: '0.5vw' }}
                                label='Panic Low'
                                value={item.paniclow}
                                onChange={(e) => {
                                  ref_data[index].paniclow = e.target.value
                                  this.setState({ ref_type })
                                }}
                                inputProps={{ maxLength: 20 }}
                                InputProps={{ emed_tid: 'grp_code' }}
                              />
                              <TextField
                                size='small'
                                sx={{ width: '14vw', mr: '0.5vw' }}
                                label='Panic High'
                                value={item.panichigh}
                                onChange={(e) => {
                                  ref_data[index].panichigh = e.target.value
                                  this.setState({ ref_type })
                                }}
                                inputProps={{ maxLength: 20 }}
                                InputProps={{ emed_tid: 'grp_code' }}
                              />
                              {this.state.ref_data?.length > 1 ?
                                <Button className='eMed_Lab_act_btn' onClick={() => {
                                  var { ref_data } = this.state
                                  ref_data.splice(index, 1)
                                  this.setState({
                                    ref_data
                                  })
                                }}>
                                  <Box component={'img'} src={ImagePaths.Delete.default} alt='close' height={'1vw'} width={'1vw'} />
                                </Button>
                                : null}
                            </Box>
                          )
                        })}
                        {((this.state.isAddNewRef || this.state.isEditRefData) ? this.state.NewresultType : states?.receivedData?.result_type) === 1 ?
                          <Box>
                            <Button
                              startIcon={<AddCircleIcon />}
                              className='eMed_Lab_act_btn'
                              sx={{ textTransform: 'capitalize' }}
                              onClick={() => { this.onAddNewParameter() }}
                            >Add New Parameter</Button>
                          </Box> : null
                        }
                      </Box>
                    </Box>}
                </Box>
              </Box>}

            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              {/* {((this.state.isAddNewRef || this.state.isEditRefData) ? this.state.NewresultType : states?.receivedData?.result_type) != 3 ? <Box>
                <Typography fontSize={'.9vw'} fontWeight={'bold'}>Reference Range</Typography>
                <TextField
                  size='small'
                  multiline
                  rows={3}
                  sx={{ width: '38.5vw', mr: '0.5vw', mt: '0.5vw' }}
                  label='Reference Range'
                  value={this.state.isEdit ? editRangeData?.reference_range : range_data.reference_range}
                  onChange={(e) => {
                    if (this.state.isEdit) {
                      editRangeData.reference_range = e.target.value
                      this.setState({ range_data })
                    } else {
                      range_data.reference_range = e.target.value
                      this.setState({ editRangeData })
                    }
                  }}
                  InputProps={{ emed_tid: 'grp_code' }}
                />
              </Box> : null} */}
              <Box>
                <Typography fontSize={'.9vw'} fontWeight={'bold'}>Description</Typography>
                <TextField
                  size='small'
                  multiline
                  rows={3}
                  sx={{ width: '77.5vw', mr: '0.5vw', mt: '0.5vw' }}
                  label='Description'
                  value={this.state.isEdit ? editRangeData?.description : range_data.description}
                  onChange={(e) => {
                    if (this.state.isEdit) {
                      editRangeData.description = e.target.value
                      this.setState({ range_data })
                    } else {
                      range_data.description = e.target.value
                      this.setState({ editRangeData })
                    }
                  }}
                  InputProps={{ emed_tid: 'grp_code' }}
                />
              </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', mt: '1vw' }}>
              <Box>
                <Typography fontSize={'.9vw'} fontWeight={'bold'}>Interpretation</Typography>
                <TextField
                  size='small'
                  multiline
                  rows={3}
                  sx={{ width: '77.5vw', mt: '0.5vw' }}
                  label='Interpretation'
                  value={this.state.isEdit ? editRangeData?.interpretation : range_data.interpretation}
                  onChange={(e) => {
                    if (this.state.isEdit) {
                      editRangeData.interpretation = e.target.value
                      this.setState({ range_data })
                    } else {
                      range_data.interpretation = e.target.value
                      this.setState({ editRangeData })
                    }
                  }}
                  InputProps={{ emed_tid: 'grp_code' }}
                />
              </Box>
            </Box>
          </Box>
          <Box component={'div'} sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '.4vw', }}>
            {(this.state.isAddNewRef || this.state.isEditRefData) ? <Button size='small' variant='outlined' sx={{ textTransform: 'capitalize', width: '8vw', marginRight: '1vw' }}
              onClick={() => {
                if (this.state.isEditRefData) {
                  this.setState({
                    addTestPopup: false
                  }, () => {
                    this.resetNewReferrence()
                    this.handleClose()
                  })
                } else {
                  this.resetValues()
                }
              }}>{this.state.isEditRefData ? 'Cancel' : 'Reset'}</Button> : null}
            <Button size='small' variant='contained' sx={{ textTransform: 'capitalize', width: '8vw' }}
              disabled={this.state.disableBtn}
              onClick={() => {
                if ((this.state.isAddNewRef && this.state.NewresultType != 5) || (this.state.isEditRefData && this.state.NewresultType != 5)) {
                  this.postNewReferenceData()
                }
                else if (states?.receivedData?.result_type === 5 || this.state.NewresultType === 5) {
                  this.setState({ stepNumber: 2 })
                } else {
                  this.NewpostNewReferenceData()
                }
              }}>{((this.state.isAddNewRef && this.state.NewresultType != 5) || (this.state.isEditRefData && this.state.NewresultType != 5)) ? 'Update' : (states?.receivedData?.result_type === 5 || this.state.NewresultType === 5) ? 'Next' : 'Complete'}</Button>
          </Box>
        </Box>
      )
    } catch (e) {
      this.errorMessage(e.message)
    }
  }

  stepThree = () => {
    return (
      <Box component={'div'} sx={{ width: '78.5vw', height: '70.5vh', margin: '.8vw' }}>
        <Box component={'div'} >
          <Typography fontWeight={'bold'} fontSize={'1vw'}>Possible Test</Typography>
        </Box>
        <Box component={'div'} sx={{ height: '62vh', marginY: "0.5vw" }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: "space-between" }}>
            <TextField
              sx={{ width: "21vw" }}
              size='small'
              placeholder='Possible Test Result *'
              autoComplete='off'
              label='Possible Test Result *'
              inputProps={{ maxLength: 250 }}
              value={this.state.PossibleResult}
              onChange={(e) => {
                this.setState({
                  PossibleResult: e.target.value
                })
              }}
            />
            <TextField
              placeholder='Comments'
              sx={{ width: "21vw" }}
              autoComplete='off'
              inputProps={{ maxLength: 250 }}
              size='small'
              label='Comments'
              value={this.state.PossibleTestComments}
              onChange={(e) => {
                this.setState({
                  PossibleTestComments: e.target.value
                })
              }}
            />
            <FormControl sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <RadioGroup
                value={this.state.PossibleTestStatus}
                sx={{ display: 'flex', flexDirection: 'row', marginLeft: '1vw' }}
                onChange={(e) => {
                  this.setState({ PossibleTestStatus: e.target.value })
                }}
              >
                <FormControlLabel value={"Abnormal"} control={<Radio size='small' />} label="Abnormal" />
                <FormControlLabel value={"Panic"} control={<Radio size='small' />} label="Panic" />
                <FormControlLabel value={"Default Result"} control={<Radio size='small' />} label="Default Result" />
              </RadioGroup>
            </FormControl>
            <Button
              variant='contained'
              size='small'
              sx={{ textTransform: 'capitalize', width: '8vw' }}
              onClick={() => {
                if (CommonValidation.removeSpace(this.state.PossibleResult)) {
                  let tempObj = {
                    possible_result: this.state.PossibleResult ? CommonValidation.removeSpace(this.state.PossibleResult) : "",
                    comment: this.state.PossibleTestComments ? CommonValidation.removeSpace(this.state.PossibleTestComments) : "",
                    status: this.state.PossibleTestStatus ? this.state.PossibleTestStatus : "",
                  }
                  if (this.state.PossibleEditId !== null) {
                    let List = this.state.PossibleTestList
                    if(List[this.state.PossibleEditId]?.id){
                      tempObj.id = List[this.state.PossibleEditId].id
                    }
                    List[this.state.PossibleEditId] = tempObj
                    this.setState({
                      PossibleTestList: List,
                      PossibleEditId: null,
                      PossibleResult: "",
                      PossibleTestComments: "",
                      PossibleTestStatus: "Abnormal",
                    })
                  } else {
                    this.setState({
                      PossibleTestList: [...this.state.PossibleTestList, tempObj],
                      PossibleEditId: null,
                      PossibleResult: "",
                      PossibleTestComments: "",
                      PossibleTestStatus: "Abnormal",
                    })
                  }
                } else {
                  this.errorMessage("Enter Possible Test Result")
                }
              }}
            >Add</Button>
          </Box>
          <Box >
            <TableContainer sx={{ height: '53vh', overflow: 'auto', marginTop: "1vw" }} component={'div'}>
              <Table stickyHeader size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell className='eMed_Pat_Bill_Tbl_Header'>{"Possible Test"}</TableCell>
                    <TableCell className='eMed_Pat_Bill_Tbl_Header'>{"Comments"}</TableCell>
                    <TableCell className='eMed_Pat_Bill_Tbl_Header' align='center'>{"Abnormal"}</TableCell>
                    <TableCell className='eMed_Pat_Bill_Tbl_Header' align='center'>{"Panic"}</TableCell>
                    <TableCell className='eMed_Pat_Bill_Tbl_Header' align='center'>{"Default Result"}</TableCell>
                    <TableCell className='eMed_Pat_Bill_Tbl_Header' align='center'>{"Action"}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody >
                  {this.state.PossibleTestList?.length > 0 ? this.state.PossibleTestList.map((item, index) => (
                    <>
                      <TableRow key={index}>
                        <TableCell className='eMed_Pat_Tbl_one_style'>{item?.possible_result ? item?.possible_result : "-"}</TableCell>
                        <TableCell className='eMed_Pat_Tbl_one_style'>{item?.comment ? item?.comment : "-"}</TableCell>
                        <TableCell className='eMed_Pat_Tbl_one_style' align='center'>{item?.status === "Abnormal" ? <DoneIcon color='primary' /> : <CloseIcon color='error' />}</TableCell>
                        <TableCell className='eMed_Pat_Tbl_one_style' align='center'>{item?.status === "Panic" ? <DoneIcon color='primary' /> : <CloseIcon color='error' />}</TableCell>
                        <TableCell className='eMed_Pat_Tbl_one_style' align='center'>{item?.status === "Default Result" ? <DoneIcon color='primary' /> : <CloseIcon color='error' />}</TableCell>
                        <TableCell className='eMed_Pat_Tbl_one_style' align='center'>
                          <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                            <Button
                              sx={{ height: '1.5vw', width: '1.5vw' }}
                              onClick={() => {
                                this.setState({
                                  PossibleResult: item?.possible_result,
                                  PossibleTestComments: item?.comment,
                                  PossibleTestStatus: item?.status,
                                  PossibleEditId: index
                                })
                              }}
                            >
                              <Tooltip placement='top' title='Edit' arrow>
                                <Box component={'img'} src={ImagePaths.LabEditButton.default} height={'1.5vw'} width={'1.5vw'} />
                              </Tooltip>
                            </Button>
                            <Button
                              className='eMed_Lab_act_btn'
                              sx={{ height: '1.5vw', width: '1.5vw' }}
                              onClick={() => {
                                let List = this.state.PossibleTestList
                                List?.splice(index, 1)
                                this.setState({
                                  PossibleTestList: List,
                                })
                              }}
                            >
                              <Tooltip placement='top' title='Delete' arrow>
                                <Box component={'img'} src={ImagePaths.Delete.default} height={'1.5vw'} width={'1.5vw'} />
                              </Tooltip>
                            </Button>
                          </Box>
                        </TableCell>
                      </TableRow>
                    </>
                  )) : <TableRow>
                    <TableCell />
                    <TableCell />
                    <TableCell align='center'>
                      <Typography paddingTop={"2vw"}>No Records Found</Typography>
                    </TableCell>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                  </TableRow>}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
        <Box component={'div'} sx={{ display: 'flex', justifyContent: 'flex-end', }}>
          <Button size='small' variant='outlined' sx={{ textTransform: 'capitalize', width: '8vw', marginRight: '1vw' }}
            onClick={() => {
              this.setState({
                stepNumber: 1
              })
            }}>Previous</Button>
          <Button size='small' variant='contained' sx={{ textTransform: 'capitalize', width: '8vw' }}
            disabled={this.state.disableBtn}
            onClick={() => {
              if (this.state.isEditRefData || this.state.isAddNewRef) {
                this.postNewReferenceData()
              } else {
                this.NewpostNewReferenceData()
              }
            }}
          >{this.state.isEditRefData ? 'Update' : 'Complete'}</Button>
        </Box>
      </Box>
    )
  }

  renderAddNewTest = () => {
    try {
      let states = this.state
      return (
        <Modal open={this.state.addTestPopup} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Box component={'div'} sx={{ width: '80vw', height: '85vh', backgroundColor: 'white', borderRadius: '.2vw' }}>
            <Box component={'div'} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: '1vw', borderBottom: '1px solid #E3E2E2' }}>
              <Typography fontSize={'1vw'} fontWeight={'bold'}>Add New Test</Typography>
              <IconButton size='small' onClick={() => { ((this.state.ViewScreen == 1) && (states?.receivedData?.result_type != 3)) ? this.handleWarning() : this.handleClose(); this.resetNewReferrence(); }}><img style={{ width: '1vw', height: '1vw' }} src={ImagePaths.LabCloseButton.default} alt='close' /></IconButton>
            </Box>
            <Box component={'div'} sx={{ display: 'flex', justifyContent: 'center' }}>
              <Stepper activeStep={this.state.stepNumber} sx={{ width: '50vw' }}>
                {steps.map((label, index) => (
                  <Step key={index}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
            {this.state.stepNumber === 0 ?
              this.stepOne()
              : null}
            {this.state.stepNumber === 1 ?
              this.stepTwo()
              : null}
            {this.state.stepNumber === 2 ?
              this.stepThree()
              : null}
          </Box>
        </Modal>
      )
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  render() {
    let commonDisable = CheckAccessFunc("Laboratory", "Settings", "Lab Configuration", "Result Configuration", "SubTab")?.editAccess
    let showCode = localGetItem('lab_test_code') ? JSON.parse(localGetItem('lab_test_code')) : JSON.parse(localGetItem('loggedInUserInfo'))?.lab_test_code
    return (
      <Box component={'div'} sx={{ backgroundColor: Colors.Background }} height={'80vh'} p={'0.5vw'}>
        <Box component={'div'} p={'0.5vw'} sx={{ backgroundColor: 'white' }} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
          <Box component={'div'} display={'flex'} alignItems={'center'}>
            <Box>
              <Autocomplete
                size='small'
                clearIcon={false}
                options={this.state.departmentListData}
                getOptionLabel={(option) => option?.dept_name}
                value={this.state.departmentName}
                onChange={(e, newValue) => {
                  this.setState({ departmentName: newValue, groupName: null, grpListData: [] }, () => {
                    this.getNewReferrenceData()
                  })
                }}
                sx={{ width: "20vw" }}
                renderInput={(params) => (<TextField {...params} size='small' label='Select Department' onChange={(e) => {
                  if (e.target.value === "") {
                    this.setState({
                      groupName: null, grpListData: [], departmentName: this.state.departmentName
                    })
                  }
                }} />)}
              />
            </Box>
            <Box marginLeft={'1vw'}>
              <Autocomplete
                size='small'
                clearIcon
                disabled={this.state.departmentName == null ? true : false}
                options={this.state.departmentName !== null ? this.state.grpListData : []}
                getOptionLabel={(option) => `${showCode ? option?.grp_test_code ? `${option?.grp_test_code} / ` : '' : ''} ${option?.grp_name}`}
                value={this.state.groupName ? this.state.groupName : null}
                onChange={(e, newValue) => {
                  this.setState({ groupName: newValue }, () => {
                    var duplicate = JSON.parse(this.state.duplicateResult)
                    if (this.state.departmentName) {
                      duplicate?.filter(element => element.dept_id === this.state.departmentName?.dept_id ? duplicate = [element] : duplicate)
                    }
                    let data = []
                    if (this.state.groupName) {
                      duplicate[0].grp_data.forEach(element => {
                        if (element.grp_id === this.state.groupName?.grp_id) {

                          data.push(element)
                        }
                      })
                      duplicate[0].grp_data = data
                    }
                    this.setState({ resultConfigData: duplicate, testListData: [], testName: null }, () => { this.setState({ testListData: data?.[0]?.grp_test_data }) })
                  })
                }}
                sx={{ width: '20vw' }}
                renderInput={(params) => (<TextField {...params} label='Select Group Name' onChange={(e) => {
                  if (e.target.value === "") {
                    this.setState({
                      groupName: null, testListData: [], testName: null
                    })
                  }
                }} />)}
              />
            </Box>
            <Box marginLeft={'1vw'}>
              <Autocomplete
                size='small'
                disabled={this.state.groupName == null ? true : false}
                clearIcon
                options={(this.state?.grpListData != [] && this.state.grpListData.length > 0) ? this.state?.testListData : []}
                getOptionLabel={(option) => `${showCode ? option?.test_code ? `${option?.test_code} / ` : '' : ''}${option?.test_name}`}
                value={this.state?.testName ? this.state?.testName : null}
                onChange={(e, newValue) => {
                  this.setState({ testName: newValue }, () => {
                    var duplicate = JSON.parse(this.state.duplicateResult)
                    if (this.state.departmentName) {
                      duplicate?.filter(element => element.dept_id === this.state.departmentName?.id ? duplicate = [element] : duplicate)
                    }
                    if (this.state.groupName) {
                      duplicate[0]?.grp_data?.filter(element => element.grp_id === this.state.groupName?.grp_id ? duplicate[0].grp_data = [element] : duplicate)
                    }
                    let data = []
                    if (this.state.testName) {
                      duplicate[0].grp_data[0].grp_test_data.forEach(element => {
                        if (element.test_id === this.state?.testName?.test_id) {
                          data.push(element)
                        }
                      })
                      duplicate[0].grp_data[0].grp_test_data = data
                    }

                    this.setState({ resultConfigData: duplicate })
                  })
                }}
                sx={{ width: '20vw' }}
                renderInput={(params) => (<TextField {...params} label='Select Test Name' />)}
              />
            </Box>
          </Box>
          <Box display={'flex'} gap={'1vw'}>
          {/* <Tooltip title="Export" placement='top' arrow>
            <Button onClick={() => { this.getResultConfigExcel() }} disabled={this.state.disableBtn} className='eMed_usrconf_btn'>
              <img src={ImagePaths.ExportIcon.default} alt="upload" className='eMed_action_img' />
            </Button>
          </Tooltip> */}
          <Button className='eMed_Lab_act_btn' disabled={!commonDisable} variant='contained' onClick={() => {
            this.setState({
              ref_data: [{
                reference_from: '',
                reference_to: '',
                panichigh: '',
                paniclow: '',
                unit: ''
              }], range_data: {
                gender: '',
                age_from: '',
                age_to: '',
                age_from_unit: 'years',
                age_to_unit: 'years',
                reference_range: '',
                description: '',
                interpretation: '',
              },
              selectedData: null,
              isAddNewRef: false,
              isEditRefData: false,
            }, () => { this.setState({ addTestPopup: true }) })
          }}>{"ADD NEW TEST"}</Button>
        </Box>
        </Box>
        <Box component={'div'}>
          {this.renderResultConfigTable()}
          {this.newReferrencePopup()}
        </Box>
        {this.state.EditPopup ?
          <div>
            {this.EditDialogBox()}
          </div>
          : null}
        {this.state.EditCultureTest ?
          <div>
            {this.EditCulturalTestBox()}
          </div> : null}
        {this.state.addTestPopup ?
          <Box component={'div'}>
            {this.renderAddNewTest()}
          </Box>
          : null}
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.deletePopup ? <ReasonPopup
          title={`Are You Sure ? Do You Want to remove this ${this.state.DltTitle}`}
          AlertPopupClose={this.popupClose.bind(this)}
          label={'Reason'}
          btntext={'Cancel'}
          btnvarient={'outlined'}
          btncolor={'error'}
          btntext1={'Confirm'}
          DeleteNotify={'Entire Configuration will be affected.'}
          btnvarient1={'contained'}
          sendCmt={this.addCancelComments.bind(this)}
          btntextDis={this.state.disableBtn}
          color={'red'}
        /> : null}
        <Loader loaderOpen={this.state.isLoader} />
      </Box>
    )
  }
}
