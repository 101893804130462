import React, { Component } from "react";
import '../Configuration.css';
import { Box, Paper, TextField, Button, Stack, Typography, Tooltip, Grid, FormControl, InputLabel, Select, Menu, MenuItem, IconButton, InputAdornment, Autocomplete, } from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import { withTranslation } from 'react-i18next';
import CommonGridHeader, { CommonDeleteButton, CommonEditButton, DeletePopup, CommonPopUp } from "../../../Components/Common Components/CommonComponents";
import CommonValidation from "../../../Components/CommonFunctions/CommonValidation";
import { Colors } from "../../../Styles/Colors";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ImagePaths } from "../../../Utility/ImagePaths";
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import RestAPIService from "../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../Utility/API/Serviceurls";
import { AmountFormat, CheckAccessFunc } from "../../../Components/CommonFunctions/CommonFunctions";
import Loader from '../../../Components/Loader';
import { CurrencySymbol, OP_IP_CODE_LENGTH } from "../../../Utility/Constants";
import { localGetItem } from "../../../Utility/Services/CacheProviderService";

const ITEM_HEIGHT = 30;
class IPPackageConfig extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serviceTempDetail: [],
            serviceNameandType: [],
            data: [],
            deleteId: null,
            srcTemplate_name: "",
            id: null,
            package_name: "",
            surgary_name: null,
            treatment_name: "",
            service_id: null,
            data: [],
            edit_index: -1,
            selectedItem: {},
            selectedSrcType: {},
            deletedPopup: false,
            anchorEl1: null,
            anchorEl2: null,
            historyData: [],
            historyIndex: -1,
            historyLineItem: {},
            totalAmount: null,
            selectedRow: null,
            isSelected: false,
            isEdit: false,
            current_page: 0,
            rowsPerPage: 10,
            showSuccessResponse: false,
            showErrorResponse: false,
            responseMessage: "",
            clinicAmt: '',
            surgeryAmt: '',
            surgeryNameData: [],
            isSurgeryFee : true,
            package_service_code:'',
            showCode: false
        }
    }

    componentDidMount() {
        let showCode = JSON.parse(localGetItem('op_ip_service_code')) ? JSON.parse(localGetItem('op_ip_service_code')) : false
        this.setState({ showCode: showCode })
        this._getServicePackageDetails();
        this._getServiceNameandType();
        this.getSurgeryNameList()
    }

    getSurgeryNameList = () => {
        try {
            RestAPIService.getAll(Serviceurls.IP_SURGERY_NAME_CONFIG)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            surgeryNameData: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.responseSuccessErrorMessage(error.response.data.message, false)
                    } else {
                        this.responseSuccessErrorMessage(error.message, false)
                    }
                })
        } catch (error) {
            this.responseSuccessErrorMessage(error.message, false);
        }
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    _getServicePackageDetails = () => {
        this.setState({
            serviceTempDetail: [],
            current_page: 0,
            rowsPerPage: 10
        })
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.FO_CONFIG_IPSRCPACKAGE)
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({
                            serviceTempDetail: response.data.data,
                        }, () => {
                            this.state.serviceTempDetail.forEach((item, i) => {
                                item.index = i
                            })
                            this.LoaderFunction(false)
                        });
                    }
                })
                .catch(error => {
                    this.LoaderFunction(false)
                    if (error?.response?.data?.status === "fail") {
                        this.responseSuccessErrorMessage(error.response.data.message, false);
                    }
                });
        } catch (e) {
            this.LoaderFunction(false)
            this.responseSuccessErrorMessage(e.message, false);
        }
    }

    _getServiceNameandType = () => {
        this.setState({
            serviceNameandType: []
        })
        try {
            RestAPIService.getAll(Serviceurls.FO_CONFIG_IPSRCNAME)
                .then(response => {
                    if (response.data.status === "success") {
                        let data = response?.data && response?.data?.data?.length > 0 ? response.data.data : [];
                        let serviceTypeList = [];
                        data.forEach(item => serviceTypeList.push(item.data))
                        this.setState({
                            serviceNameandType: serviceTypeList && serviceTypeList.length > 0 ? serviceTypeList.flat() : []
                        }, () => {
                            // let anesthetist = {
                            //     id:'AF',
                            //     service_name:"Anesthetist Fee",
                            //     service_type:"Anesthetist Fee",
                            //     is_edit_front_office:false,
                            //     amount:''
                            // }
                            // let surgeon = {
                            //     id:'SF',
                            //     service_name:"Surgeon Fee",
                            //     service_type:"Surgeon Fee",
                            //     is_edit_front_office:false,
                            //     amount:''
                            // }
                            // this.state.serviceNameandType.push(anesthetist,surgeon)
                            // this.setState({serviceNameandType:this.state.serviceNameandType})  
                        });
                    }
                })
                .catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.responseSuccessErrorMessage(error.response.data.message, false);
                    }
                });
        } catch (e) {
            this.responseSuccessErrorMessage(e.message, false);
        }
    }

    responseSuccessErrorMessage(message, type) {
        this.setState({
            showSuccessResponse: message ? type : false,
            showErrorResponse: message ? !type : false,
            responseMessage: message
        })
    }
    //start of history popup
    _getHistoryDetails = (historyId) => {
        this.setState({
            historyData: [],
        })
        try {
            RestAPIService.getAll(`${Serviceurls.FO_CONFIG_IPSRCHISTORY}?service_id=${historyId}&condition=package`)
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({
                            historyData: response.data.data
                        }, () => {
                            this.state.historyData.forEach((item, i) => {
                                item.index = i
                            })
                        });
                    }
                })
                .catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.responseSuccessErrorMessage(error.response.data.message, false);
                    }
                });
        } catch (e) {
            this.responseSuccessErrorMessage(e.message, false);
        }
    }

    historyPopUpClose = () => {
        this.setState({
            anchorEl1: null,
            isHistoryClicked: false,
            historyData: []
        })
    }

    _getHistory(historydata) {
        const { t } = this.props;
        historydata?.forEach((item, index) => item.sno = index + 1);
        const historyColumn = [
            { field: 's.no', headerName: t('SNo'), sortable: false, width: 100, renderCell: (index) => index.row.sno },
            {
                field: 'modifiedDetails', headerName: `${t("User")} & ${t("ModifiedDateAndTime")}`, sortable: false, hideable: false, width: 350, renderCell: (cellvalues) => {
                    return (<Stack direction="column" spacing={0.3}>
                        <Typography><span className="eMed_txt_header">{`${t("User")} : `}</span>{cellvalues.row?.user?.length > 20 ? <Tooltip placement="top" title={cellvalues.row?.user} arrow><div>{cellvalues.row?.user.slice(0, 15) + "..."}</div></Tooltip> : cellvalues.row?.user ? cellvalues.row?.user : "-"}</Typography>
                        <Typography sx={{ fontSize: "0.8vw" }}><span className="eMed_txt_header">{`${t("Date")} & ${t("Time")} : `}</span> {cellvalues.row?.modified_date ? cellvalues.row?.modified_date : ""}</Typography>
                    </Stack>)
                }
            },
            {
                field: "package_name_from", headerName: `${t('Previous')}  ${t('PackageName')}`, width: 250, sortable: false, hideable: false, renderCell: (cellvalues) => {
                    return (<div>{cellvalues.row?.package_name_from?.length > 25 ? <Tooltip placement="top" title={cellvalues.row?.package_name_from} arrow><div>{cellvalues.row?.package_name_from.slice(0, 23) + "..."}</div></Tooltip> : cellvalues.row?.package_name_from ? cellvalues.row?.package_name_from : "-"}</div>)
                }
            },
            {
                field: "package_name_to", headerName: `${t('Current')}  ${t('PackageName')}`, width: 250, sortable: false, hideable: false, renderCell: (cellvalues) => {
                    return (<div>{cellvalues.row?.package_name_to?.length > 25 ? <Tooltip placement="top" title={cellvalues.row?.package_name_to} arrow><div>{cellvalues.row?.package_name_to.slice(0, 23) + "..."}</div></Tooltip> : cellvalues.row?.package_name_to ? cellvalues.row?.package_name_to : "-"}</div>)
                }
            },{
                field: "treatment_name_from", headerName: `${t('Previous')}  ${t('Treatment Name')}`, width: 250, sortable: false, hideable: false, renderCell: (cellvalues) => {
                    return (<div>{cellvalues.row?.treatment_name_from?.length > 25 ? <Tooltip placement="top" title={cellvalues.row?.treatment_name_from} arrow><div>{cellvalues.row?.treatment_name_from.slice(0, 23) + "..."}</div></Tooltip> : cellvalues.row?.treatment_name_from ? cellvalues.row?.treatment_name_from : "-"}</div>)
                }
            },
            {
                field: "treatment_name_to", headerName: `${t('Current')}  ${t('Treatment Name')}`, width: 250, sortable: false, hideable: false, renderCell: (cellvalues) => {
                    return (<div>{cellvalues.row?.treatment_name_to?.length > 25 ? <Tooltip placement="top" title={cellvalues.row?.treatment_name_to} arrow><div>{cellvalues.row?.treatment_name_to.slice(0, 23) + "..."}</div></Tooltip> : cellvalues.row?.treatment_name_to ? cellvalues.row?.treatment_name_to : "-"}</div>)
                }
            },
            {
                field: "surgary_name_from", headerName: `${t('Previous')}  ${t('SurgeryName')}`, width: 250, sortable: false, hideable: false, renderCell: (cellvalues) => {
                    return (<div>{cellvalues.row?.surgary_name_from?.length > 25 ? <Tooltip placement="top" title={cellvalues.row?.surgary_name_from} arrow><div>{cellvalues.row?.surgary_name_from.slice(0, 23) + "..."}</div></Tooltip> : cellvalues.row?.surgary_name_from ? cellvalues.row?.surgary_name_from : "-"}</div>)
                }
            },
            {
                field: "surgary_name_to", headerName: `${t('Current')}  ${t('SurgeryName')}`, width: 250, sortable: false, hideable: false, renderCell: (cellvalues) => {
                    return (<div>{cellvalues.row?.surgary_name_to?.length > 25 ? <Tooltip placement="top" title={cellvalues.row?.surgary_name_to} arrow><div>{cellvalues.row?.surgary_name_to.slice(0, 23) + "..."}</div></Tooltip> : cellvalues.row?.surgary_name_to ? cellvalues.row?.surgary_name_to : "-"}</div>)
                }
            },
            {
                field: 'service_type_from', headerName: `${t('Previous')} ${t('ServiceType')}`, sortable: false, hideable: false, width: 250, renderCell: (cellvalues) => {
                    return (
                        <div>{cellvalues.row?.service_type_from?.length > 25 ? <Tooltip placement="top" title={cellvalues.row?.service_type_from} arrow><div>{cellvalues.row?.service_type_from.slice(0, 23) + "..."}</div></Tooltip> : cellvalues.row?.service_type_from ? cellvalues.row?.service_type_from : "-"}</div>
                    )
                }
            },
            {
                field: 'service_type_to', headerName: `${t('Current')} ${t('ServiceType')}`, sortable: false, hideable: false, width: 250, renderCell: (cellvalues) => {
                    return (
                        <div>{cellvalues.row?.service_type_to?.length > 25 ? <Tooltip placement="top" title={cellvalues.row?.service_type_to} arrow><div>{cellvalues.row?.service_type_to.slice(0, 23) + "..."}</div></Tooltip> : cellvalues.row?.service_type_to ? cellvalues.row?.service_type_to : "-"}</div>
                    )
                }
            },
            {
                field: 'amount_from', headerAlign: "right", headerName: `${t('Previous')} ${t('Amount')} (${CurrencySymbol})`, width: 250, sortable: false, hideable: false, align: "right", renderCell: (cellvalues) => {
                    let amountFrom = cellvalues.row?.amount_from !== "No changes" ? AmountFormat(cellvalues.row?.amount_from)?.replace(`${CurrencySymbol}`, "") : cellvalues.row?.amount_from;
                    return (<Typography>{amountFrom !== `${CurrencySymbol}` ? amountFrom : "-"}</Typography>)
                }
            },
            {
                field: 'amount_to', headerAlign: "right", headerName: `${t('Current')} ${t('Amount')} (${CurrencySymbol})`, width: 250, sortable: false, hideable: false, align: "right", renderCell: (cellvalues) => {
                    let amountTo = cellvalues.row?.amount_to !== "No changes" ? AmountFormat(cellvalues.row?.amount_to).replace(`${CurrencySymbol}`, "") : cellvalues.row?.amount_to;
                    return (<Typography>{amountTo !== `${CurrencySymbol}` ? amountTo : "-"}</Typography>)
                }
            }
        ];
        return (
            <Box id="eMed_ipsrvConfig_accTbl">
                <DataGrid
                    rows={historydata}
                    columns={historyColumn}
                    getRowId={(row) => row.sno}
                    disableColumnMenu
                    hideFooter
                    components={{
                        NoRowsOverlay: () => {
                            return (
                                <Stack direction="row" justifyContent="center" alignItems="center" sx={{ height: "40vh" }}>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }
                    }}
                    headerHeight={40}
                />
            </Box>
        )
    }
    // End of history popup

    //Start of left table Function
    handleEdit = (item) => {
        let states = this.state
        item["service_data"].forEach((value, i) => {
            value["index"] = i;
            value["selectedType"] = `${value.service_name} - ${value.service_type}`;
        })
        states["data"] = JSON.parse(JSON.stringify(item.service_data));
        states["id"] = item.id;
        states["package_name"] = item.package_name;
        states?.surgeryNameData.forEach(list => {
            if (list?.surgery_name === item?.surgary_name) {
                states["surgary_name"] = list;
            }
        });
        states["treatment_name"] = item.treatment;
        states["totalAmount"] = item.total_amount ? +(item.total_amount) : 0;
        states["selectedItem"] = {};
        states["selectedSrcType"] = {};
        states["edit_index"] = -1;
        states['package_service_code'] = item?.package_service_code
        this.setState({
            states,
            isEdit: true
        })
    }

    handleDelete = (value) => {
        if (!this.state.isEdit) {
            this.setState({
                deletedPopup: true,
                deleteId: value.id,
                srcTemplate_name: value.package_name
            })
        }
    }

    PopupClose = () => {
        this.setState({
            deletedPopup: false,
            deleteId: null,
            srcTemplate_name: ""
        })
    }

    removeDataList = () => {
        try {
            RestAPIService.delete(`${Serviceurls.FO_CONFIG_IPSRCPACKAGE}?id=${this.state.deleteId ? this.state.deleteId : 0}`)
                .then(response => {
                    if (response.data.status === "success") {
                        this.setState({
                            deletedPopup: false,
                            deleteId: null,
                            srcTemplate_name: ""
                        }, () => {
                            this.responseSuccessErrorMessage(response.data.message, true);
                            this._getServicePackageDetails();
                        })
                    }
                })
                .catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.responseSuccessErrorMessage(error.response.data.message, false);
                    }
                });
        } catch (e) {
            this.responseSuccessErrorMessage(e.message, false);
        }
    }

    MenuItem = (data) => {
        const { t } = this.props
        const multi_option = [
            { value: "History", label: t("History") },
        ]
        return (
            <div>
                <Tooltip title={t("More")} placement='top' arrow>
                    <IconButton
                        onClick={(e) => {
                            this.setState({
                                anchorEl1: e.currentTarget,
                                selectedRow: data.id,
                                isSelected: true
                            })
                        }}
                    >
                        <MoreVertIcon />
                    </IconButton>
                </Tooltip>
                {(this.state.isSelected && this.state.selectedRow == data.id) ?
                    <Menu
                        anchorEl={this.state.anchorEl1}
                        open={Boolean(this.state.anchorEl1)}
                        onClose={() => {
                            this.setState({ anchorEl1: null, })
                        }}>
                        {multi_option.map((option) => (
                            <MenuItem key={option} onClick={() => { this.setState({ anchorEl1: null, isHistoryClicked: true }, () => { this._getHistoryDetails(data.row.id) }) }}>
                                {t(option.label)}
                            </MenuItem>
                        ))}
                    </Menu> : null}
            </div>
        )
    }
    // End of left table Function

    //Start of right div Function
    handleChange = (event) => {
        let states = this.state
        let name = event.target.name;
        let value = event.target.value.trimStart();
        if (name === "package_name" || name === "treatment_name") {
            if(name === "treatment_name"){
                states["surgary_name"] = null
            }
            let isValid = CommonValidation.alphanumValidation(value)
            if (value === "" || isValid) {
                states[name] = value;
            }
        } else if (name === "doctor_share") {
            let isValid = CommonValidation.numberOnly(value)
            if (value === "" || value >= 0 && value?.length <= 7 && isValid) {
                states["selectedItem"]["doctor_share"] = value;
                states["selectedItem"]["amount"] = +value + (states["selectedItem"]["hospital_share"] ? (+states["selectedItem"]["hospital_share"]) : 0);
            }
        } else if (name === "hospital_share") {
            let isValid = CommonValidation.numberOnly(value)
            if (value === "" || value >= 0 && value?.length <= 7 && isValid) {
                states["selectedItem"]["hospital_share"] = value;
                states["selectedItem"]["amount"] = +value + (states["selectedItem"]["doctor_share"] ? (+states["selectedItem"]["doctor_share"]) : 0);
            }
        } else if (name === "surgery_fee") {
            let isValid = CommonValidation.numberOnly(value)
            if (value === "" || value >= 0 && value?.length <= 7 && isValid) {
                states["selectedItem"]["surgery_fee"] = value;
                states["selectedItem"]["amount"] = +value;
            }
        } else if(name === 'package_service_code'){
            if(value?.length <= OP_IP_CODE_LENGTH || value === ''){
                states['package_service_code'] = value
            }
        }
        this.setState({
            states
        }, () => {
            states["selectedItem"]["hospital_percent"] = CommonValidation.amountPercentage(+states["selectedItem"]["hospital_share"], states["selectedItem"]["amount"])
            states["selectedItem"]["doctor_percent"] = CommonValidation.amountPercentage(+states["selectedItem"]["doctor_share"], states["selectedItem"]["amount"])
            this.setState({
                states
            })
        })
    }

    onAdd = () => {
        const { t } = this.props;
        let { data, selectedItem, selectedSrcType, edit_index } = this.state;
        let totalAmount = 0;
        let exist = data?.find(item => item["id"] === selectedSrcType.id);
        let isCrt = true;
        let Message = "";
        if (!selectedItem.service_type && ((!selectedItem.doctor_share && !selectedItem.hospital_share) || (!selectedItem.surgery_fee))) { isCrt = false; Message = `${t("Enter")} ${t("ServiceType")} ${t("and")} ${t("Amount")}` }
        if (!selectedItem.service_type) { isCrt = false; Message = `${t("Enter")} ${t("ServiceType")}` }
        if (this.state.isSurgeryFee && !selectedItem.surgery_fee) {
            if (!selectedItem.surgery_fee) { isCrt = false; Message = `${t("Enter")} ${t("Surgery")} ${t("Amount")}` }
        } else if(!this.state.isSurgeryFee && (!selectedItem.doctor_share || !selectedItem.hospital_share)) {
            if (!selectedItem.doctor_share) { isCrt = false; Message = `${t("Enter")} ${t("Doctor")} ${t("Amount")}` }
            if (!selectedItem.hospital_share) { isCrt = false; Message = `${t("Enter")} ${t("Hospital")} ${t("Amount")}` }
        }
        if (exist) {
            if (edit_index === -1) { isCrt = false; Message = `${exist["selectedType"]} ${t("AlreadyExist")}`; }
            else if (exist["index"] !== edit_index && exist["selectedType"] === selectedItem["service_type"] && exist["id"] === selectedItem["id"]) { isCrt = false; Message = `${exist["selectedType"]} ${t("AlreadyExist")}`; }
            else if (exist["index"] !== edit_index && exist["id"] !== selectedSrcType["id"]) { isCrt = false; Message = `${exist["selectedType"]} ${t("AlreadyExist")}`; }
            else if (exist["index"] === edit_index && exist["id"] === selectedItem["id"] && exist["selectedType"] === selectedItem["service_type"] && exist["amount"] === ((+selectedItem["doctor_share"]) + (+selectedItem["hospital_share"])) && exist['doctor_share'] === +selectedItem["doctor_share"] && exist['hospital_share'] === +selectedItem["hospital_share"]) { isCrt = false; Message = `${exist["selectedType"]} ${t("AlreadyExist")}`; }
            else if (exist["index"] === edit_index && exist["id"] === selectedItem["id"] && exist["selectedType"] === selectedItem["service_type"] && exist["amount"] === ((+selectedItem["surgery_fee"])) && exist['surgery_fee'] === +selectedItem["surgery_fee"]) { isCrt = false; Message = `${exist["selectedType"]} ${t("AlreadyExist")}`; }
        }
        if (isCrt) {
            if ((edit_index !== -1)) {
                if(this.state.isSurgeryFee){
                    selectedSrcType["surgery_fee"] = +(selectedItem["surgery_fee"]);
                }else{
                selectedSrcType["doctor_share"] = +(selectedItem["doctor_share"]);
                selectedSrcType["hospital_share"] = +(selectedItem["hospital_share"]);
                selectedSrcType["doctor_percent"] = +(selectedItem["doctor_percent"]);
                selectedSrcType["hospital_percent"] = +(selectedItem["hospital_percent"]);
                }
                selectedSrcType["amount"] = +(selectedItem["amount"]);
                selectedSrcType["selectedType"] = selectedItem["service_type"];
                selectedSrcType["index"] = edit_index;
                let newList = data.map((item, index) => {
                    return index === edit_index ? JSON.parse(JSON.stringify(selectedSrcType)) : item
                })
                data = JSON.parse(JSON.stringify(newList));
            } else {
                if(this.state.isSurgeryFee){
                    selectedSrcType["surgery_fee"] = +(selectedItem["surgery_fee"]);
                }else{
                selectedSrcType["doctor_share"] = +(selectedItem["doctor_share"]);
                selectedSrcType["hospital_share"] = +(selectedItem["hospital_share"]);
                selectedSrcType["doctor_percent"] = +(selectedItem["doctor_percent"]);
                selectedSrcType["hospital_percent"] = +(selectedItem["hospital_percent"]);
                }
                selectedSrcType["amount"] = +(selectedItem["amount"]);
                selectedSrcType["selectedType"] = selectedItem["service_type"];
                selectedSrcType["index"] = data.length;
                data = [...data, JSON.parse(JSON.stringify(selectedSrcType))]
            }
            data.forEach((acc) => totalAmount = totalAmount + parseFloat(acc["amount"]))
            // if (selectedSrcType['selectedType'] === "Anesthetist Fee - Anesthetist Fee") {
            //     data.forEach((element) => {
            //         switch (element.service_type) {
            //             case "Anesthetist Fee":
            //                 element.hospital_share = +this.state.clinicAmt
            //                 element.doctor_share = +this.state.surgeryAmt
            //                 break;
            //             default:
            //                 break;
            //         }
            //     })
            //     this.setState({ data },() => {
            //             this.setState({
            //                 clinicAmt: '',
            //                 surgeryAmt: ''
            //             })
            //         })
            // } else if (selectedSrcType['selectedType'] === "Surgeon Fee - Surgeon Fee") {
            //     data.forEach((element) => {
            //         switch (element.service_type) {
            //             case "Surgeon Fee":
            //                 element.hospital_share = +this.state.clinicAmt
            //                 element.doctor_share = +this.state.surgeryAmt
            //                 break;
            //             default:
            //                 break;
            //         }
            //     })
            //     this.setState({ data },() => {
            //             this.setState({
            //                 clinicAmt: '',
            //                 surgeryAmt: ''
            //             })
            //         })
            // }
            this.setState({
                data,
                totalAmount: totalAmount ? totalAmount.toFixed(2) : 0,
                service_id: null,
                edit_index: -1,
                selectedItem: {},
                selectedSrcType: {},
                isSurgeryFee: true
            }, () => {
                this.setState({
                    clinicAmt: '',
                    surgeryAmt: ''
                })
            })
        } else {
            this.responseSuccessErrorMessage(Message, false)
        }
    }

    handleEditTbl = (item) => {
        let states = this.state
        if (item["doctor_share"] && item["hospital_share"]) {
            this.setState({
                isSurgeryFee: false
            }, () => {
                states["selectedItem"]["doctor_share"] = item["doctor_share"];
                states["selectedItem"]["hospital_share"] = item["hospital_share"];
                this.setState({
                    states
                })
            })
        } else {
            states["selectedItem"]["surgery_fee"] = item["surgery_fee"];
            this.setState({
                states
            })
        }
        states["selectedItem"]["service_type"] = item["selectedType"];
        states["selectedItem"]["amount"] = item["amount"];
        states["selectedItem"]["id"] = item["id"];
        states["selectedSrcType"] = JSON.parse(JSON.stringify(item));
        states["edit_index"] = item.index;
        states['package_service_code'] = item?.package_service_code
        this.setState({
            states
        })
    }

    handleDeleteTbl = (item) => {
        let { data, edit_index } = this.state;
        let totalAmount = 0;
        if (edit_index === -1) {
            let deletedArr = [...data]
            let index = deletedArr.indexOf(item)
            deletedArr.splice(index, 1)
            deletedArr.forEach((acc) => totalAmount = totalAmount + parseFloat(acc["amount"]))
            this.setState({
                data: JSON.parse(JSON.stringify(deletedArr)),
                totalAmount: totalAmount ? totalAmount.toFixed(2) : 0,
            })
        }
    }
    handleClear = () => {
        let states = this.state;
        states["package_name"] = "";
        states["surgary_name"] = null;
        states["treatment_name"] = "";
        states["selectedItem"] = {};
        states["data"] = [];
        states["id"] = null;
        states["totalAmount"] = null;
        states["edit_index"] = -1;
        states['package_service_code'] = ''
        this.setState({
            states,
            isEdit: false,
            clinicAmt: "",
            surgeryAmt: ''
        })
    }
    renderTextBox(label, statekey, value, width) {
        const { t } = this.props;
        let { serviceNameandType, selectedItem } = this.state;
        let amountValue = value ? value : ((+this.state.clinicAmt) + (+this.state.surgeryAmt))
        return (
            <div id="eMed_srvConfig_divs">
                {
                    statekey === "service_type" ?
                        <FormControl sx={{ width: width ? width : "12vw" }} size="small">
                            <InputLabel>{t(label) + " *"}</InputLabel>
                            <Select
                                value={value ? value : ""}
                                name={statekey}
                                label={t(label) + " *"}
                                onChange={(event) => {
                                    let values = event.target.value;
                                    if (event.target.value != "") {
                                        selectedItem["service_type"] = values;
                                    }
                                    else {
                                        selectedItem = {};
                                    }
                                    selectedItem["doctor_share"] = ""
                                    selectedItem["hospital_share"] = ""
                                    selectedItem["surgery_fee"] = ""
                                    this.setState({
                                        selectedItem
                                    })
                                }}
                                MenuProps={{
                                    style: { maxHeight: 350 },
                                }}
                            >
                                <MenuItem value={""}>{"Select"}</MenuItem>
                                {serviceNameandType?.length > 0 ? serviceNameandType.map((item, index) => {
                                    return <MenuItem key={index} value={`${item.service_name} - ${item.service_type}`} onClick={() => { selectedItem["id"] = item["id"]; this.setState({ selectedSrcType: item, selectedItem, isSurgeryFee : (item.service_category__service_category === "Doctor Consultation Charges" || item.service_category__service_category === "Surgery Charges" || item.service_category__service_category === "Anasthesia Charges") ? false : true }) }} >{`${item.service_name} - ${item.service_type}`}</MenuItem>
                                })
                                    : null}
                            </Select>
                        </FormControl> :
                        statekey === "doctor_share" || statekey === "hospital_share" ?
                            <TextField
                                sx={{ width: width ? width : "10vw" }}
                                size="small"
                                // disabled={selectedItem['service_type'] === "Anesthetist Fee - Anesthetist Fee" || selectedItem['service_type'] === "Surgeon Fee - Surgeon Fee"}
                                autoComplete="off"
                                label={t(label)}
                                name={statekey}
                                required
                                value={amountValue ? amountValue : ""}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">{`${CurrencySymbol}`}</InputAdornment>
                                }}
                                onChange={this.handleChange}
                            /> :
                            statekey === 'surgary_name' ?
                                <Autocomplete
                                    sx={{ width: width ? width : "10vw" }}
                                    size="small"
                                    autoComplete='off'
                                    filterOptions={(options) => options}
                                    options={this.state.surgeryNameData}
                                    getOptionLabel={(option) => typeof (option) === 'string' ? option : option.surgery_name}
                                    value={value ? value : null}
                                    onChange={(e, newValue) => {
                                        this.setState({
                                            surgary_name: newValue,
                                            treatment_name: ""
                                        })
                                    }}
                                    renderInput={(params) => <TextField {...params} label='Surgery Names' InputProps={{
                                        ...params.InputProps,
                                    }} />}
                                />
                                :
                                <TextField
                                    sx={{ width: width ? width : "10vw" }}
                                    size="small"
                                    autoComplete="off"
                                    label={t(label)}
                                    name={statekey}
                                    required={statekey == "treatment_name" ? false : true}
                                    value={value ? value : (value === 0 ? "0" : "")}
                                    onChange={this.handleChange}
                                />
                }
            </div>
        )
    }
    handleSave = () => {
        const { t } = this.props
        let { package_name, surgary_name, treatment_name, data, id, edit_index } = this.state
        let datas = {};
        let isAvl = true;
        let Message = "";
        if (!package_name && (!surgary_name && treatment_name == "") && data.length === 0) { isAvl = false; Message = t("Fillmandatory") }
        else if (!package_name) { isAvl = false; Message = `${t("Enter")} ${t("PackageName")}` }
        else if (!surgary_name && treatment_name == "") { isAvl = false; Message = `${t("Enter")} ${t("Treatement Name or Surgery Name")}` }
        else if (data.length === 0) { isAvl = false; Message = `${t("Enter")} ${t("ServiceType")} ${t("and")} ${t("Amount")}` }
        if (isAvl && edit_index === -1) {
            // api call
            if (id) {
                datas = {
                    "id": id,
                    "package_name": package_name,
                    "surgary_name": surgary_name?.id ? surgary_name?.id : null,
                    "treatment": treatment_name,
                    'package_service_code':this.state.package_service_code ? this.state.package_service_code : '',
                    "service_ids": []
                }
            } else {
                datas = {
                    "id": null,
                    "package_name": package_name,
                    "surgary_name": surgary_name?.id ? surgary_name?.id : null,
                    "treatment": treatment_name,
                    'package_service_code':this.state.package_service_code ? this.state.package_service_code : '',
                    "service_ids": []
                }
            }
            data.forEach((item) => {
                // delete item["selectedType"];
                datas["service_ids"].push(JSON.parse(JSON.stringify(item)))
            })
            try {
                this.LoaderFunction(true)
                RestAPIService.create(datas, Serviceurls.FO_CONFIG_IPSRCPACKAGE)
                    .then(response => {
                        if (response.data.status === "success") {
                            this.handleClear();
                            this._getServicePackageDetails();
                            this.LoaderFunction(false)
                            this.responseSuccessErrorMessage(response.data.message, true);
                        }
                    })
                    .catch(error => {
                        if (error?.response?.data?.status === "fail") {
                            this.LoaderFunction(false)
                            this.responseSuccessErrorMessage(error.response.data.message, false);
                        }
                    });
            } catch (e) {
                this.LoaderFunction(false)
                this.responseSuccessErrorMessage(e.message, false);
            }
        } else {
            this.responseSuccessErrorMessage(Message, false);
        }
    }
    // End of right div Function
    msgClose() {
        this.setState({
            showSuccessResponse: false,
            showErrorResponse: false,
            responseMessage: ''
        })
    }

    onChangeHandler = (e) => {
        let { selectedItem } = this.state
        const { name, value } = e.target
        if (CommonValidation.NumberWithDot(value) || value === "") {
            this.setState({
                [name]: value
            }, () => {
                if (name === "clinicAmt") {
                    this.state.selectedItem.amount = (+value) + (+this.state.surgeryAmt)
                    this.setState({ selectedItem })
                } else if (name === 'surgeryAmt') {
                    this.state.selectedItem.amount = (+value) + (+this.state.clinicAmt)
                    this.setState({ selectedItem })
                }
            })
        }
    }

    inputTextBox = (state, key, label, margin) => {
        return (
            <TextField
                sx={{ width: "11vw", marginLeft: margin, marginBottom: "1.5vw" }}
                size="small"
                label={label}
                name={key}
                value={state}
                onChange={(e) => this.onChangeHandler(e)}
            />
        )
    }

    render() {
        const { t } = this.props;
        let { rowsPerPage, current_page, serviceTempDetail, data, package_name, surgary_name, selectedItem, isHistoryClicked, historyData, treatment_name ,package_service_code} = this.state;
        const columns = [
            { field: 's.no', headerName: t('SNo'), sortable: false, flex: 0.06, renderCell: (index) => index.api.getRowIndex(index.row.id) + 1 },
            {
                field: 'package_name', headerName: t('PackageName'), flex: 0.23, renderCell: (cellvalues) => {
                    return (
                        <div>{cellvalues?.row?.package_name?.length > 20 ? <Tooltip placement="top" title={cellvalues?.row?.package_name} arrow><div>{cellvalues?.row?.package_name.slice(0, 15) + "..."}</div></Tooltip> : cellvalues?.row?.package_name ? cellvalues?.row?.package_name : "-"}</div>
                    )
                }
            },
            {
                field: 'surgary_name', headerName: t('SurgeryName'), flex: 0.21, renderCell: (cellvalues) => {
                    return (
                        <div>{cellvalues?.row?.surgary_name?.length > 20 ? <Tooltip placement="top" title={cellvalues?.row?.surgary_name} arrow><div>{cellvalues?.row?.surgary_name.slice(0, 15) + "..."}</div></Tooltip> : cellvalues?.row?.surgary_name ? cellvalues?.row?.surgary_name : "-"}</div>
                    )
                }
            },
            {
                field: 'treatment', headerName: t('Treatment'), flex: 0.21, renderCell: (cellvalues) => {
                    return (
                        <div>{cellvalues?.row?.treatment?.length > 20 ? <Tooltip placement="top" title={cellvalues?.row?.treatment} arrow><div>{cellvalues?.row?.treatment.slice(0, 15) + "..."}</div></Tooltip> : cellvalues?.row?.treatment ? cellvalues?.row?.treatment : "-"}</div>
                    )
                }
            },
            {
                field: 'total_amount', hearderAlign: "right", headerName: t('PackageAmount') +  ` (${CurrencySymbol})`, flex: 0.17, align: "right", renderCell: (cellvalues) => {
                    let totalAmount = AmountFormat(cellvalues.row?.total_amount)
                    return (<Typography>{totalAmount ? totalAmount.replace(`${CurrencySymbol}`, "") : "0.00"}</Typography>)
                }
            },
            {
                field: 'actions', headerAlign: 'center', headerName: t('Action'), flex: 0.15, sortable: false, hideable: false, align: "center", renderCell: (cellvalue) => {
                    return (
                        <Stack direction="row" alignItems="center" spacing={4}>
                            <Stack direction="row" justifyContent="space-evenly" alignItems="center" spacing={1}>
                                <CommonEditButton disable={!CheckAccessFunc("front_office", "Settings", "IP Service Configuration", "IP Package Configuration", "SubTab")?.editAccess} size="1.5vw" onClick={this.handleEdit.bind(this, cellvalue.row)} />
                                <CommonDeleteButton disable={!CheckAccessFunc("front_office", "Settings", "IP Service Configuration", "IP Package Configuration", "SubTab")?.editAccess} size="1.5vw" onClick={this.handleDelete.bind(this, cellvalue.row)} />
                                {this.MenuItem(cellvalue)}
                            </Stack>
                        </Stack>
                    )
                }
            }
        ]
        const rightColumn = [
            {
                field: 'service', headerName: t('Service'), sortable: false, hideable: false, flex: 0.25, renderCell: ({ row }) => {
                    return (<div>{row["selectedType"]?.length > 14 ? <Tooltip placement="top" title={row["selectedType"]} arrow><div>{row["selectedType"].slice(0, 12) + "..."}</div></Tooltip> : row["selectedType"] ? row["selectedType"] : "-"}</div>)
                }
            },
            {
                field: "hospital_share", headerAlign: "right", headerName: t('Hospital Share') + ` (${CurrencySymbol})`, flex: 0.20, sortable: false, hideable: false, align: "right", renderCell: ({ row }) => {
                    let amount = row["hospital_share"] ? AmountFormat(row["hospital_share"]) : "0.00";
                    return (<Typography>{amount ? amount.replace(`${CurrencySymbol}`, "") : "-"}</Typography>)
                }
            },
            {
                field: "doctor_share", headerAlign: "right", headerName: t('Doctor Share') + ` (${CurrencySymbol})`, flex: 0.20, sortable: false, hideable: false, align: "right", renderCell: ({ row }) => {
                    let amount = row["doctor_share"] ? AmountFormat(row["doctor_share"]) : "0.00";
                    return (<Typography>{amount ? amount.replace(`${CurrencySymbol}`, "") : "-"}</Typography>)
                }
            },
            {
                field: "amount", headerAlign: "right", headerName: t('Total') + ` (${CurrencySymbol})`, flex: 0.20, sortable: false, hideable: false, align: "right", renderCell: ({ row }) => {
                    let amount = row["amount"] ? AmountFormat(row["amount"]) : row["amount"] ? AmountFormat(row["amount"]) : "0.00";
                    return (<Typography>{amount ? amount.replace(`${CurrencySymbol}`, "") : "-"}</Typography>)
                }
            },
            {
                field: 'actions', headerAlign: 'center', headerName: t('Action'), flex: 0.15, sortable: false, hideable: false, align: "center", renderCell: (cellvalues) => {
                    return (
                        <Stack direction="row" alignItems="center" spacing={4}>
                            <Stack direction="row" justifyContent="space-evenly" alignItems="center" spacing={1}>
                                <CommonEditButton size="1.5vw" onClick={this.handleEditTbl.bind(this, cellvalues.row)} />
                                <CommonDeleteButton size="1.5vw" onClick={this.handleDeleteTbl.bind(this, cellvalues.row)} />
                            </Stack>
                        </Stack>)
                }
            }
        ]
        return (
            <Box id="eMed_srvConfig_Container" sx={{ backgroundColor: Colors.configHomeBg }}>
                <Grid container spacing={3} className="eMed_srvConfig_Box">
                    <Grid item xs={7.6}>
                        <Paper className="eMed_srvConfig_Div" >
                            <DataGrid
                                rows={serviceTempDetail}
                                columns={columns}
                                getRowId={(row) => row.id}
                                localeText={{
                                    toolbarColumns: "",
                                    toolbarDensity: "",
                                    toolbarExport: "",
                                    toolbarFilters: "",
                                    toolbarExportPrint: ""
                                }}
                                headerHeight={40}
                                disableSelectionOnClick
                                components={{
                                    Toolbar: CommonGridHeader.CommonToolBar,
                                    NoRowsOverlay: () => {
                                        return (
                                            <Stack direction="row" justifyContent="center" alignItems="center" sx={{ height: "53vh" }}>
                                                {t("NoRecordsFound")}
                                            </Stack>
                                        )
                                    }
                                }}
                                rowCount={serviceTempDetail.length}
                                page={current_page}
                                onPageChange={(newPage) => this.setState({ current_page: newPage })}
                                pageSize={rowsPerPage}
                                onPageSizeChange={(newPageSize) => this.setState({ rowsPerPage: newPageSize })}
                                rowsPerPageOptions={[10, 20, 30]}
                                pagination
                                loading={this.state.isLoader}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={4.4}>
                        <Paper className="eMed_srvConfig_Div">
                            <div className='eMed_srvConfig_rightHead' style={{ backgroundColor: Colors.DataTblHeaderbg }}>
                                <p className='eMed_srvConfig_rightHead_txt'>{`${t("Package")}  ${t("Details")}`}</p>
                            </div>
                            <div className="eMed_ipPackage_Div" style={{overflow:"scroll"}}>
                                <Stack className="eMed_ipPackage_subDiv" direction="row" justifyContent="space-between" alignItems="center" spacing={2} >
                                    {this.renderTextBox("PackageName", "package_name", package_name, "14.5vw")}
                                    {this.state.showCode ? this.renderTextBox('Service Code','package_service_code',package_service_code,'14.5vw') : null}
                                </Stack>
                                <Stack className="eMed_ipPackage_subDiv" marginTop={"-2vw"} direction="row" justifyContent="space-between" alignItems="center" spacing={2} >
                                    {this.renderTextBox("Treatment", "treatment_name", treatment_name, "14.5vw")}
                                    {this.renderTextBox("SurgeryName", "surgary_name", surgary_name, "14.5vw")}
                                </Stack>
                                <div className='eMed_srvConfig_rightHead' style={{ backgroundColor: Colors.DataTblHeaderbg, flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                                    <p className='eMed_srvConfig_rightHead_txt'>{`${t("Package")}  ${t("Split-ups")}`}</p>
                                    <Typography className="eMed_srvConfig_rightHead_txt" style={{ paddingRight: "1.5vw" }}>{AmountFormat(this.state.totalAmount || 0)}</Typography>
                                </div>
                                <Stack className="eMed_ipPackage_subDiv" direction="row" justifyContent="space-between" alignItems="center" sx={{ width: "30vw", paddingLeft: "0.5vw", paddingBottom: "0.5vw" }}>
                                    {this.renderTextBox("Service Name", "service_type", selectedItem.service_type, (this.state.isSurgeryFee ? "15vw" :"11vw"))}
                                    {this.state.isSurgeryFee ?
                                        this.renderTextBox("Surgery Fee", "surgery_fee", selectedItem.surgery_fee, "15vw") :
                                        <Stack direction="row" justifyContent="space-between" alignItems="center" >
                                            {this.renderTextBox("Doctor Fee", "doctor_share", selectedItem.doctor_share, "9vw")}
                                            {this.renderTextBox("Hospital Fee", "hospital_share", selectedItem.hospital_share, "9vw")}
                                        </Stack>}
                                </Stack>
                                <Stack className="eMed_ipPackage_subDiv" direction="row" justifyContent="space-between" alignItems="center" sx={{ width: "31.5vw", paddingTop: 0, paddingBottom: "1.5vw", paddingRight: 0 }} ml={0.5}>
                                    <div>{`Total : ${AmountFormat(selectedItem.amount || 0)}`}</div>
                                    <Button size="small" variant="contained" id="eMed_Config_btn" onClick={() => { this.onAdd(false) }}>{t("Add")}</Button>
                                </Stack>
                                <Box direction="row" justifyContent="space-between" alignItems="center" sx={{ width: "3vw" }} ml={'2vw'}>
                                    {/* {selectedItem.service_type === "Anesthetist Fee - Anesthetist Fee" || selectedItem.service_type === 'Surgeon Fee - Surgeon Fee' ? 
                                    <Box>{this.inputTextBox(this.state.clinicAmt,'clinicAmt','Hospital Share')}
                                    {this.inputTextBox(this.state.surgeryAmt,'surgeryAmt',selectedItem.service_type === "Anesthetist Fee - Anesthetist Fee" ? 'Anesthetist Share' : 'Surgeon Share','1vw')}
                                    </Box>
                                    :null} */}
                                </Box>
                                <div className="eMed_ipPackage_subDiv" style={{ paddingLeft: "0.7vw", paddingRight: "0.7vw", height:"50vh", paddingTop:"0vw" }}>
                                    <DataGrid
                                        rows={data}
                                        columns={rightColumn}
                                        getRowId={(row) => row.index}
                                        disableColumnMenu
                                        hideFooter
                                        components={{
                                            NoRowsOverlay: () => {
                                                return (
                                                    <Stack direction="row" justifyContent="center" alignItems="center" sx={{ height: "20vh" }}>
                                                      {t("NoRecordsFound")}
                                                    </Stack>
                                                  )
                                            }
                                        }}
                                        headerHeight={40}
                                    />
                                </div>
                            </div>
                            <Stack direction="column" sx={{ backgroundColor: Colors.DataTblHeaderbg, height: "7vh" }} alignItems="center" justifyContent="center">
                                {/* <Stack spacing={0.1} direction="column" alignItems="flex-end" justifyContent="flex-end" sx={{ width: "100%" }} mr={7}>
                                    <Typography id="eMed_ipPackage_txt1">{t("TotalAmount")}</Typography>
                                    <Typography id="eMed_ipPackage_txt2">{`${CurrencySymbol} ${this.state.totalAmount ? this.state.totalAmount : 0}`}</Typography>
                                </Stack> */}
                                <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
                                    <Button variant='outlined' size="small" id="eMed_Config_btn" onClick={() => { this.handleClear() }}>{t("Clear")}</Button>
                                    <Button disabled={!CheckAccessFunc("front_office", "Settings", "IP Service Configuration", "IP Package Configuration", "SubTab")?.editAccess} variant='contained' size="small" id="eMed_Config_btn" onClick={() => { this.handleSave() }}>{t("Save")}</Button>
                                </Stack>
                            </Stack>
                        </Paper>
                    </Grid>
                </Grid>
                {this.state.deletedPopup ?
                    <DeletePopup DeletTitle={`${t("deleteMsg")}  ${this.state.srcTemplate_name ? this.state.srcTemplate_name : "-"} ?`}
                        DeleteNotify={t("effectEntireSetting")}
                        deleteAlertPopupClose={this.PopupClose.bind(this)}
                        removeData={this.removeDataList.bind(this)}
                    />
                    : null}
                {
                    this.state.showSuccessResponse ? <ToastMsg severity={"success"} msg={this.state.responseMessage} msgPop={this.msgClose.bind(this)} /> : null
                }
                {
                    this.state.showErrorResponse ? <ToastMsg severity={"error"} msg={this.state.responseMessage} msgPop={this.msgClose.bind(this)} /> : null
                }
                {
                    isHistoryClicked ?
                        <CommonPopUp width={"75vw"} backgroundColor={Colors.white} popUpClose={this.historyPopUpClose.bind(this)} title={t("History")} component={this._getHistory.bind(this, historyData)} />
                        : null
                }
            </Box>
        )
    }
}
export default withTranslation()(IPPackageConfig);