import { Box, Button, Tooltip, Typography } from '@mui/material'
import React, { Component } from 'react'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg'
import CommonGridHeader, { DeletePopup } from '../../../Components/Common Components/CommonComponents'
import { Colors } from '../../../Styles/Colors'
import { DataGrid } from '@mui/x-data-grid'
import { ImagePaths } from '../../../Utility/ImagePaths'
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../Utility/API/Serviceurls'
import { CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions'

export default class RadServiceTemplate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      serviceTemplateData: [],
      page: 0,
      pageSize: 10,
      removeData: false,
      deletePopup: false,
      selectedId: null,
      serviceData: {},
      isEdit: false
    }
  }

  componentDidMount() {
    this.getTestTemplateData()
  }

  getTestTemplateData = () => {
    try {
      RestAPIService.getAll(Serviceurls.RAD_TEST_TEMPLATE_CONFIG)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              serviceTemplateData: response.data.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  deleteServiceTemplateData = () => {
    try {
      this.setState({ removeData: true })
      RestAPIService.delete(Serviceurls.RAD_TEST_TEMPLATE_CONFIG + '?template_id=' + this.state.selectedId)
        .then((response) => {
          if (response.data.status === 'success') {
            this.successMessage(response.data.message)
            this.getTestTemplateData()
            this.setState({ removeData: false, selectedId: null, deletePopup: false })
          }
        }).catch((error) => {
          this.setState({ removeData: false })
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ removeData: false })
      this.errorMessage(error.message)
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  popupClose = () => {
    this.setState({ deletePopup: false })
  }

  onEditHandler = (item) => {
    try {
      this.setState({
        serviceData: item,
        isEdit: true
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onDeleteHandler = (item) => {
    try {
      this.setState({
        deletePopup: true,
        templateName: item?.template_name,
        selectedId: item?.id
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  render() {
    this.state.serviceTemplateData?.forEach((element, index) => element.sno = index + 1)
    const columns = [
      {
        field: 'sno', headerName: 'S.No', flex: 0.06,
        renderCell: (params) => (<Box component={'div'}>{params?.row?.sno}</Box>)
      },
      {
        field: 'template_name', headerName: 'Template Name', flex: 0.3,
        renderCell: (params) => (<Box component={'div'}>
          {params?.row?.template_name ? params?.row?.template_name?.length > 40 ?
            <Tooltip placement='top' title={params?.row?.template_name} arrow><Typography>{params?.row?.template_name?.slice(0, 40) + '...'}</Typography></Tooltip> :
            <Typography>{params?.row?.template_name}</Typography> : '-'}
        </Box>)
      },
      {
        field: 'service_name', headerName: 'Service Name', flex: 0.3,
        renderCell: (params) => (<Box component={'div'}>
          {params?.row?.service_name ? params?.row?.service_name?.length > 40 ?
            <Tooltip placement='top' title={params?.row?.service_name} arrow><Typography>{params?.row?.service_name?.slice(0, 40) + '...'}</Typography></Tooltip> :
            <Typography>{params?.row?.service_name}</Typography> : '-'}
        </Box>)
      },
      {
        field: 'service_type', headerName: 'Service Type', flex: 0.24,
        renderCell: (params) => (<Box component={'div'}>
          {params?.row?.service_type ? params?.row?.service_type?.length > 35 ?
            <Tooltip placement='top' title={params?.row?.service_type} arrow><Typography>{params?.row?.service_type?.slice(0, 35) + '...'}</Typography></Tooltip> :
            <Typography>{params?.row?.service_type}</Typography> : '-'}
        </Box>)
      },
      {
        field: 'action', headerName: 'Action', flex: 0.1, align: 'center', headerAlign: 'center',
        renderCell: (params) => (<Box component={'div'} display={'flex'} justifyContent={'center'}>
          <Button className='Common_Btn_Min_Width'
            disabled={!CheckAccessFunc('Radiology', "Settings", 'Service Template', null, "Tab")?.editAccess}
            onClick={() => {
              this.props.history?.push({ pathname: '/RadiologySettings/ServiceTemplateConfig/Template', state: { templateData: params?.row } })
            }}
          >
            <Box component={'img'} src={ImagePaths.Edit.default} height={'1.5vw'} width={'1.5vw'} />
          </Button>
          <Button className='Common_Btn_Min_Width'
            onClick={() => this.onDeleteHandler(params?.row)}
            disabled={!CheckAccessFunc('Radiology', "Settings", 'Service Template', null, "Tab")?.editAccess}
          >
            <Box component={'img'} src={ImagePaths.Delete.default} height={'1.5vw'} width={'1.5vw'} />
          </Button>
        </Box>)
      }
    ]
    return (
      <Box component={'div'}>
        <Box component={'div'} className='rad_tst_container' sx={{ backgroundColor: Colors.Background }}>
          <Box component={'div'} display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
            <Button variant='contained' sx={{ textTransform: 'capitalize', height: '2vw' }}
              disabled={!CheckAccessFunc('Radiology', "Settings", 'Service Template', null, "Tab")?.editAccess}
              onClick={() => {
                this.props.history?.push({ pathname: '/RadiologySettings/ServiceTemplateConfig/Template' })
              }}
            >Add New Template</Button>
          </Box>
          <Box component={'div'} height={'71vh'} mt={'0.5vw'}>
            <DataGrid
              sx={{ backgroundColor: Colors.white }}
              columns={columns}
              rows={this.state.serviceTemplateData}
              getRowId={(row) => row['sno']}
              page={this.state.page}
              pageSize={this.state.pageSize}
              hideFooterSelectedRowCount
              localeText={{
                toolbarColumns: "",
                toolbarDensity: "",
                toolbarExport: "",
                toolbarFilters: "",
                toolbarExportPrint: ""
              }}
              headerHeight={40}
              components={{
                Toolbar: CommonGridHeader.CommonToolBar,
                NoRowsOverlay: () => (
                  <Box className='eMed_conf_nodata'>
                    {'No Records To Be Shown'}
                  </Box>
                )
              }}
              rowsPerPageOptions={[10, 20, 30]}
              disableSelectionOnClick
              pagination
              onPageChange={(newPage) => { this.setState({ page: newPage }) }}
              onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
              loading={this.state.isLoader}
            />
          </Box>
        </Box>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.deletePopup ? <DeletePopup
          DeletTitle={`Are you sure you want to delete template - ${this.state.templateName} ?`}
          deleteAlertPopupClose={this.popupClose.bind(this)}
          removeData={this.deleteServiceTemplateData.bind(this)}
          DeleteNotify={"It will affect the entire Configuration"}
          disable={this.state.removeData}
        /> : null}
      </Box>
    )
  }
}
