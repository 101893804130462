import { Box, Button, Divider, FormControl, FormControlLabel, FormHelperText, IconButton, InputLabel, Menu, MenuItem, Modal, Radio, RadioGroup, Select, Stack, Tab, Tabs, TextField, Tooltip, Typography } from '@mui/material'
import React, { Component } from 'react'
import { CommonDeleteButton, CommonEditButton, CommonPatientCard, CommonPatientDetails, CommonPopUp, DeletePopup } from '../../../../Components/Common Components/CommonComponents'
import { DataGrid } from '@mui/x-data-grid'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { Colors } from '../../../../Styles/Colors'
import { AmountFormat, CheckAccessFunc, formatDate, timeOnlyConvert } from '../../../../Components/CommonFunctions/CommonFunctions'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation'
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { getCachevalue } from '../../../../Utility/Services/CacheProviderService'
import { withTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import { ImagePaths } from '../../../../Utility/ImagePaths'
import Loader from '../../../../Components/Loader';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { CurrencySymbol } from '../../../../Utility/Constants'
class RoomChange extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isHours: false,
      isDay: false,
      percentage: "",
      discount: "",
      netAmount: 0,
      discountedAmount: 0,
      roomChangeDate: new Date(),
      wardName: "",
      blockName: "",
      floor: "",
      room: "",
      bed: "",
      isEdit: false,
      roomChangeData: [],
      ipPatient: "",
      attenderName: "",
      attenderMobile: "",
      PatientWardList: [],
      PatientBlockList: [],
      PatientFloorList: [],
      PatientBedList: [],
      PatientRoomList: [],
      PatientWard: "",
      PatientBlock: "",
      PatientFloor: "",
      PatientRoom: "",
      PatientBed: "",
      PatientFloorId: "",
      PatientBederror: false,
      PatientBedtext: "",
      bedDataList: [],
      is_day: false,
      is_hourly: false,
      toDate: false,
      billSummaryId: "",
      blockList: [],
      roomTime: null,
      serviceAmount: 0,
      AdvanceAmount: 0,
      paidAmount: 0,
      pendingAmount: 0,
      delSelectedId: "",
      deletePopup: false,
      afterCancel: false,
      WardList: [],
      roomChange: false,
      reason: "",
      activeRoom: true,
      withOutAppointment: {},
      clickRoomChange: false,
      ApproxAmount: 0,
      PatientBedObj: {},
      SelectedTab: "inpatient",
      selectedOption: "",
      OrginalWardList: [],
      CheckOutData: [],
      CheckOutdateTime: new Date(),
      isBtnDisable: false,
      ApprovedAmt: 0, 
      DateTimeChanged: false,
    }
  }

  componentDidMount() {
    let ipPatientData = getCachevalue('IppatientData')
    let ipPatient = JSON.parse(ipPatientData)
    let appointmentDetail = getCachevalue("withoutAppointment");
    let details = JSON.parse(appointmentDetail);
    if (details?.patient_id === ipPatient?.patient_id) {
      this.setState({
        withOutAppointment: details
      }, () => this.getRoomChangeData())
    }
    this.setState({
      ipPatient: ipPatient,
    }, () => {
      this.getRoomChangeData()
      this.getApproxCharges()
      this.getPatientData()
    })
    this.getWardList()
    setInterval(() => { // DateTime refresh on every minute, when the user not change the datetime
      if (!this.state.DateTimeChanged && !this.state.isEdit) {
          const newMinute = new Date().getMinutes();
          if (this.state.roomChangeDate && this.state.roomChangeDate?.getMinutes() !== newMinute) {
              this.setState({
                  roomChangeDate: new Date(),
              });
          }
      }
  }, 1000);

  }

  getApproxCharges = () => {
    let admission_id = this.state.ipPatient?.id || this.state.withOutAppointment?.ip_admission_id;
    try {
      RestAPIService.getAll(Serviceurls.IP_APPROX_AMOUNT + "?ip_admission_id=" + admission_id)
        .then((response) => {
          if (response.data.status === "success") {
            this.setState({
              ApproxAmount: response.data.data?.approx_room_charge
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.status === "fail") {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }


  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message,
      isBtnDisable : false 
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  onChangeHandler = (e) => {
    try {
      const { name, value } = e.target
      switch (name) {
        case 'percentage':
          if ((CommonValidation.DecimalNumber(value) && value <= 100) || value === "") {
            this.state.discount = CommonValidation.calculatePercentageWithoutRoundoff(value, this.state.netAmount)
            this.state.discountedAmount = this.state.netAmount - this.state.discount
            this.setState({
              percentage: value,
              discountedAmount: this.state.discountedAmount
            })
          }
          break;
        case 'discount':
          if ((CommonValidation.NumberWithDot(value)) && value <= this.state.netAmount || value === "") {
            this.state.percentage = CommonValidation.amountPercentage(value, this.state.netAmount)
            this.state.discountedAmount = this.state.netAmount - value
            this.setState({
              discount: value,
              discountedAmount: this.state.discountedAmount
            })
          }
          break;
        default:
          this.setState({
            [name]: value
          })
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  RemoveDuplicates(Array, key) {
    const FilteredArray = Array.reduce((accumulator, current) => {
      if (!accumulator.find((item) => item[key] === current[key])) {
        accumulator.push(current);
      }
      return accumulator;
    }, []);

    return FilteredArray
  }

  SelectRentTypeRadio(DayRent, HourRent) {
    if (DayRent === true && HourRent === undefined) {
      this.setState({ roomTime: "day" })
    }
    else if (DayRent === undefined && HourRent === true) {
      this.setState({ roomTime: "hourly" })
    }
    else if (DayRent === true && HourRent === true) {
      this.setState({ roomTime: "hourly" })
    }
  }

  getWardList = () => {
    try {
      RestAPIService.getAll(Serviceurls.BED_SELECT)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              bedDataList: this.state.SelectedTab === "attender" ? this.RemoveDuplicates(response.data.data, "ward_id").filter((item) => (item.is_attender === true)) :  this.RemoveDuplicates(response.data.data, "ward_id"),
              WardList: response.data.data,
              OrginalWardList: this.RemoveDuplicates(response.data.data, "ward_id"),
            }, () => {
              if (this.state.clickRoomChange && this.state.bedDataList?.length == 1) {
                this.setState({
                  PatientWard: this.state.bedDataList[0].ward_id,
                  PatientBlockList: this.RemoveDuplicates(this.state.WardList, "block_id")
                }, () => {
                  if (this.state.PatientBlockList?.length == 1) {
                    this.setState({
                      PatientBlock: this.state.PatientBlockList[0].block_id,
                      PatientFloorList: this.RemoveDuplicates(this.state.WardList.filter((item) => (item.ward_id === this.state.PatientWard && item.block_id === this.state.PatientBlockList[0].block_id)), "floor")
                    }, () => {
                      if (this.state.PatientFloorList?.length == 1) {
                        this.setState({
                          PatientFloor: this.state.PatientFloorList[0].floor,
                          PatientRoomList: this.RemoveDuplicates(this.state.WardList.filter((item) => (item.ward_id === this.state.PatientWard && item.block_id === this.state.PatientBlock && item.floor == this.state.PatientFloorList[0].floor)), "room_number")
                        }, () => {
                          if (this.state.PatientRoomList?.length == 1) {
                            this.setState({
                              PatientRoom: this.state.PatientRoomList[0].room_number,
                              PatientBedList: this.state.WardList.filter((item) => (item.ward_id === this.state.PatientWard && item.block_id === this.state.PatientBlock && item.floor == this.state.PatientFloor && item.room_number === this.state.PatientRoomList[0].room_number))
                            }, () => {
                              if (this.state.PatientBedList?.length == 1) {
                                this.setState({
                                  PatientBed: this.state.PatientBedList[0].bed_id,
                                  PatientBedObj: this.state.WardList.find((item) => (this.state.PatientBedList[0].bed_id === item.bed_id))
                                }, () => {
                                  this.SelectRentTypeRadio(this.state.PatientBedObj?.is_day, this.state.PatientBedObj?.is_hourly)
                                })
                              }
                            })
                          }
                        })
                      }
                    })
                  }
                })
              }
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.status === 'fail') {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  FloorCount(value) {
    const FloorCountList = [];
    for (let i = 1; i <= value; i++) { FloorCountList.push(i) }
    return FloorCountList;
  }

  getPatientData = () => {
    try {
      let AdmissionId = this.state.ipPatient?.id ? this.state.ipPatient?.id : this.state.withOutAppointment?.ip_admission_id ? this.state.withOutAppointment?.ip_admission_id : ""
      RestAPIService.getAll(Serviceurls.IP_PATIENT_DETAILS + '?admission_id=' + AdmissionId)
        .then((response) => {
          if (response.data.status === "success") {
            this.setState({
              serviceAmount: response.data.data[0].service_amount,
              pendingAmount: response.data.data[0].balance_amount,
              paidAmount: response.data.data[0].paid_amount,
              AdvanceAmount: response.data.data[0].advance_amount,
              attenderMobile: response.data.data[0].attender_mobile,
              attenderName: response.data.data[0].attender_name,
              ApprovedAmt: response.data.data[0].claimed_amount,
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getRoomChangeData = () => {
    try {
      let admission_id = this.state.ipPatient?.id || this.state.withOutAppointment?.ip_admission_id;
      RestAPIService.getAll(Serviceurls.IP_ROOM_CHANGE + `?ip_admission_id=${admission_id}`)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              BothRoomList: response.data,
              roomChangeData: this.state.SelectedTab === "inpatient" ? response.data?.patient_list : response.data?.attender_list,
              // attenderName: response.data.header_data.attender_name,
              // attenderMobile: response.data.header_data.attender_mobile,
              // pendingAmount: response.data.header_data.pending_amount,
              // serviceAmount: response.data.header_data.service_amount,
              // AdvanceAmount: response.data.header_data.advance_amount,
              // paidAmount: response.data.header_data.paid_amount
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.status === 'fail') {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onClearHandler = () => {
    this.setState({
      PatientBed: "",
      PatientRoom: "",
      PatientWard: "",
      PatientFloor: "",
      PatientBlock: "",
      PatientBedList: [],
      PatientRoomList: [],
      PatientFloorList: [],
      PatientBlockList: [],
      netAmount: 0,
      discount: "",
      percentage: "",
      discountedAmount: 0,
      roomChangeDate: new Date(),
      roomTime: null,
      DateTimeChanged: false,
    })
  }

  FindByID(List, Id, IdKey, ValueKey) {
    let Data = ""
    Data = List.length > 0 ? List.filter((item) => (item[IdKey] === Id)) : []
    Data = Data.length === 1 ? Data[0][ValueKey] : ""
    return Data
  }

  postRoomChangeData = () => {
    try {
      let startDate = new Date(this.state.roomChangeDate)
      let date = this.state.roomChangeDate ? DateTime.fromJSDate(startDate).toFormat("yyyy-MM-dd") : ""
      let time = this.state.roomChangeDate ?  DateTime.fromJSDate(startDate).toLocaleString(DateTime.TIME_24_WITH_SECONDS) : ""
      let data = {
        "patient_id": this.state.ipPatient?.patient_id,
        "ip_admission_id": this.state.ipPatient?.id,
        "bed_id": this.state.PatientBed,
        "ward": typeof (this.state.PatientWard) === "string" ? this.state.PatientWard : this.FindByID(this.state.bedDataList, this.state.PatientWard, "ward_id", "ward_name"),
        "block": typeof (this.state.PatientBlock) === "string" ? this.state.PatientBlock : this.FindByID(this.state.PatientBlockList, this.state.PatientBlock, "block_id", "block_name"),
        "floor": this.state.PatientFloor,
        "room": this.state.PatientRoom,
        "room_change_date": date,
        "room_change_time": time,
        "is_hourly": this.state.SelectedTab === "inpatient" ? (this.state.roomTime === "hourly" ? true : false) : false,
        "is_day": this.state.SelectedTab === "inpatient" ? (this.state.roomTime === 'day' ? true : false) : true,
        "is_attender": this.state.SelectedTab === "inpatient" ? false : true
      }
      if (data.floor === "" || data.bed_id === "" || data.block === "" || data.room === "") {
        this.errorMessage('Please Select All Mandatory Fields')
      } else if (!data.is_hourly && !data.is_day) {
        this.errorMessage('Select Either Hours or Day')
      } else if (!this.state.roomChangeDate) {
        this.errorMessage("Enter Date and Time")
      } else if (time === "Invalid DateTime" && date === "Invalid DateTime") {
        this.errorMessage("Invaid Date and Time")
      } else {
        this.LoaderFunction(true)
        RestAPIService.create(data, Serviceurls.IP_ROOM_CHANGE).
          then((response) => {
            if (response.data.status === 'success') {
              this.setState({ isEdit: false, clickRoomChange: false,isBtnDisable : false  }, ()=>this.getPatientData())
              this.onClearHandler()
              this.getRoomChangeData()
              this.getWardList()
              this.LoaderFunction(false)
              this.successMessage(response.data.message)
            }
          }).catch((error) => {
            if (error?.response?.data?.message) {
              this.LoaderFunction(false)
              this.errorMessage(error.response.data.message)
            } else {
              this.LoaderFunction(false)
              this.errorMessage(error.message)
            }
          })
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onClickEditHandler(value) {
    try {
      let date = `${value?.row?.from_date} ${value?.row?.from_time}`
      this.setState({
        isEdit: true,
        PatientWard: value?.row?.ward_name,
        PatientBlock: value?.row?.block_name,
        PatientFloor: value?.row?.floor_number,
        PatientRoom: value?.row?.room_number,
        PatientBed: value?.row?.bed_number,
        selectedRoomId: value?.row?.id,
        toDate: value?.row?.to_date ? true : false,
        netAmount: value?.row?.amount,
        billSummaryId: value?.row?.ip_bill_summary_id,
        roomChangeDate: date,
        roomTime: value?.row?.is_hourly ? 'hourly' : 'day',
        selectedTime: true,
        activeRoom: value?.row?.is_active,
        afterCancel: false,
        percentage: value?.row?.discount_percentage,
        discount: value?.row?.discount_amount,
        discountedAmount: value?.row?.amount - value?.row?.discount_amount
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  postAfterCancelRoomChangeData = () => {
    try {
      let startDate = new Date(this.state.roomChangeDate)
      let date = this.state.roomChangeDate ? DateTime.fromJSDate(startDate).toFormat("yyyy-MM-dd") : ""
      let time = this.state.roomChangeDate ? DateTime.fromJSDate(startDate).toLocaleString(DateTime.TIME_24_WITH_SECONDS) : ""
      let data = {
        "patient_id": this.state.ipPatient?.patient_id,
        "ip_admission_id": this.state.ipPatient?.id,
        "ward": this.FindByID(this.state.bedDataList, this.state.PatientWard, "ward_id", "ward_name"),
        "block": this.FindByID(this.state.PatientBlockList, this.state.PatientBlock, "block_id", "block_name"),
        "floor": this.state.PatientFloor,
        "room": this.state.PatientRoom,
        "room_change_date": date,
        "room_change_time": time,
        "is_hourly": this.state.roomTime === "hourly" ? true : false,
        "is_day": this.state.roomTime === 'day' ? true : false,
        "bed_id": this.state.PatientBed
      }
      RestAPIService.create(data, Serviceurls.IP_ROOM_CHANGE_POST).
        then((response) => {
          if (response.data.status === 'success') {
            this.successMessage(response.data.message)
            this.getRoomChangeData()
            this.getWardList()
            this.onClearHandler()
            this.getPatientData()
          }
        }).catch((error) => {
          if (error?.response?.data?.status === 'fail') {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  putRoomChangeData = () => {
    try {
      let data = {
        "id": this.state.selectedRoomId,
        "ip_admission_id": this.state.ipPatient?.id,
        "patient_id": this.state.ipPatient?.patient_id,
        "patient_account_number": this.state.ipPatient?.patient_account_number,
        "net_amount": this.state.discountedAmount || this.state.discountedAmount == 0 ? +this.state.discountedAmount : this.state.netAmount,
        "total_amount": this.state.netAmount,
        "discount_percentage": this.state.percentage ? +this.state.percentage : 0,
        "discount_amount": this.state.discount ? +this.state.discount : 0,
        "ip_bill_summary_id": this.state.billSummaryId ? this.state.billSummaryId : "",
        "ward_name": this.state.PatientWard
      }
      this.LoaderFunction(true)
      RestAPIService.updateWithOutId(data, Serviceurls.IP_ROOM_CHANGE).
        then((response) => {
          if (response.data.status === 'success') {
            this.setState({ isEdit: false, clickRoomChange: false, isBtnDisable : false, toDate: false },()=>this.getPatientData())
            this.onClearHandler()
            this.getRoomChangeData()
            this.getWardList()
            this.LoaderFunction(false)
            this.successMessage(response.data.message)
          }
        }).catch((error) => {
          this.setState({isBtnDisable : false })
          if (error?.response?.data?.message) {
            this.LoaderFunction(false)
            this.errorMessage(error.response.data.message)
          } else {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onDeleteHandler = (params) => {
    this.setState({
      deletePopup: true,
      delSelectedId: params.row?.id,
      rowValue: params,
      PatientWard: params?.row?.ward_name,
      PatientBlock: params?.row?.block_name,
      PatientFloor: params?.row?.floor_number,
      PatientRoom: params?.row?.room_number,
      PatientBed: params?.row?.bed_number,
      reason: ""
    })
  }

  deleteRoomChangeData = () => {
    try {
      let data = {
        'reason': this.state.reason
      }
      if (data.reason === "") {
        this.errorMessage("Enter Reason")
      } else {
        this.LoaderFunction(true)
        RestAPIService.delete(Serviceurls.IP_ROOM_CHANGE + "?id=" + this.state.delSelectedId, data).
          then((response) => {
            if (response.data.status === 'success') {
              this.setState({
                deletePopup: false,
                afterCancel: true,
                reason: "",
                roomChangeDate: new Date(),
                percentage: "",
                discount: "",
                discountedAmount: 0,
                PatientWard: "",
                PatientBlock: "",
                PatientFloor: "",
                PatientRoom: "",
                PatientBed: ""
              })
              this.getRoomChangeData()
              this.getWardList()
              this.LoaderFunction(false)
              this.successMessage(response.data.message)
              this.getPatientData()
            }
          }).catch((error) => {
            if (error?.response?.data?.message) {
              this.LoaderFunction(false)
              this.errorMessage(error.response.data.message)
            } else {
              this.LoaderFunction(false)
              this.errorMessage(error.message)
            }
          })
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderDeletePopUp = () => {
    const { t } = this.props
    return (
      <Box>
        <Modal open={this.state.deletePopup}>
          <Box className='eMed_room_change_modal'>
            <Box component={'div'} display={'flex'} justifyContent={'space-between'} className='eMed_room_model_header'>
              <Typography variant="h6" pl={'0.5vw'} fontWeight={600}>
                {t("Confirmation")}
              </Typography>
              <Box component={'div'} pt={'0.5vw'} pr={'0.5vw'} sx={{ cursor: "pointer" }}>
                <img onClick={() => this.setState({ deletePopup: false, reason: "" })} src={ImagePaths.Close.default} />
              </Box>
            </Box>
            <Box component={'div'} className='eMed_room_model_content'>
              <Typography>{"Do you want to Cancel the Room ?"}</Typography>
            </Box>
            <Box className='eMed_text_box_room'>
              <TextField
                multiline
                rows={3}
                value={this.state.reason}
                placeholder='Enter Reason'
                label='Reason'
                name='reason'
                onChange={(e) => this.onChangeHandler(e)}
                fullWidth
              />
            </Box>
            <Box component={'div'} className='eMed_room_modal_btn'>
              <Button
                variant='outlined'
                size='small'
                disabled={this.state.withOutAppointment?.is_ip || this.state.ipPatient?.status === "Checked Out"}
                sx={{ width: "10vw", textTransform: "capitalize" }}
                onClick={() => this.deleteRoomChangeData()}
              >{t("CancelRoom")}</Button>
              <Button
                variant='contained'
                size='small'
                disabled={this.state.withOutAppointment?.is_ip}
                sx={{ width: "10vw", textTransform: "capitalize" }}
                onClick={() => {
                  this.setState({
                    deletePopup: false,
                    isEdit: true
                  })
                }}
              >{t("RoomChange")}</Button>
            </Box>

          </Box>
        </Modal>
      </Box>
    )
  }

  MenuItem = (data) => {
    const { t } = this.props
    const multi_option = [
      { value: "AttenderCheckOut", label: t("Check Out") },
    ]
    return (
      <div>
        <Tooltip title={t("More")} placement='top' arrow>
          <IconButton
            disabled={data?.row?.sno !== this.state.roomChangeData?.length || data?.row?.attender_checked_out}
            onClick={(e) => {
              this.setState({
                anchorEl: e.currentTarget,
                selectedRow: data.id,
                isSelected: true
              })
            }}
          >
            <MoreVertIcon />
          </IconButton>
        </Tooltip>
        {(this.state.isSelected && this.state.selectedRow == data.id) ?
          <Menu
            anchorEl={this.state.anchorEl}
            open={Boolean(this.state.anchorEl)}
            onClose={() => {
              this.setState({ anchorEl: null, })
            }}>
            {multi_option.map((option) => (
              <MenuItem key={option}
                onClick={() => {
                  this.setState({ selectedOption: option.value, anchorEl: null }, () => {
                    if (this.state.selectedOption === "AttenderCheckOut") {
                      this.setState({ CheckOutData: data?.row , CheckOutdateTime : new Date()})
                    }
                  })
                }}>
                {t(option.label)}
              </MenuItem>
            ))}
          </Menu> : null}
      </div>
    )
  }

  renderLeftContent = () => {
    const { t } = this.props
    this.state.roomChangeData.forEach((element, index) => element.sno = index + 1)
    const columns = [
      { field: 'sno', sortable: false, headerName: t("SNo"), flex: 0.05 },
      {
        field: 'invoice_number', headerName: t("BillNo"), flex: 0.125, headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.invoice_number ?
          params?.row?.invoice_number?.length > 5 ? <Tooltip placement='top' title={params?.row?.invoice_number} arrow><div>{"..." + params?.row?.invoice_number.slice(9)}</div></Tooltip> :
            params?.row?.invoice_number : '-'}</Box>)
      },
      {
        field: 'from_date', headerName: t("FromDate"), flex: 0.1, headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>
        <Tooltip placement ='top' title={(params?.row?.from_date ? formatDate(params?.row?.from_date) :"-") + " " + (params?.row?.from_time ? timeOnlyConvert(params?.row?.from_time) : null) } arrow>
          <div>{params?.row?.from_date ? formatDate(params?.row?.from_date) : '-'}</div></Tooltip></Box> )
      },
      {
        field: 'to_date', headerName: t("ToDate"), flex: 0.1, headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>
          <Tooltip placement ='top' title ={(params?.row?.to_date ? formatDate(params?.row?.to_date):"-" )+ " " + (params?.row?.to_time ?timeOnlyConvert(params?.row?.to_time):null)} arrow>
         <div> {params?.row?.to_date ? formatDate(params?.row?.to_date) : '-'}</div></Tooltip></Box>)
      },
      {
        field: 'room_details', headerName: t("RoomDetails"), flex: 0.26,
        renderCell: (params) => (<Box component={'div'}>{params?.row?.room_details ? params?.row?.room_details?.length > 27 ?
          <Tooltip placement='top' title={params?.row?.room_details} arrow><div>{params?.row?.room_details.slice(0, 25) + '...'}</div></Tooltip> : params?.row?.room_details : '-'}</Box>)
      },
      {
        field: 'net_amount', headerName: t("TotalAmount"), flex: 0.1, type: 'number',
        renderCell: (params) => (<Box component={'div'}>{AmountFormat(params?.row?.net_amount || 0)}</Box>)
      },
      // { field: 'amount', headerName: t("PayableAmount"), flex: 0.125, type: 'number' },
      {
        field: 'action', headerName: "Action", flex: 0.13, headerAlign: "center", align: "center", sortable: false, hideable: false,
        renderCell: (params) => (<Box component={'div'} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <CommonEditButton size='1.5vw' disable={!params?.row?.is_active || this.state.withOutAppointment?.is_ip || this.state.ipPatient?.status === "Checked Out"} onClick={this.onClickEditHandler.bind(this, params)} />
          <CommonDeleteButton disable={params?.row?.to_date || !params?.row?.is_active || this.state.withOutAppointment?.is_ip || this.state.ipPatient?.status === "Checked Out"} onClick={this.onDeleteHandler.bind(this, params)} size='1.5vw' />
          {(this.state.SelectedTab === "attender") ? this.MenuItem(params) : null}
        </Box>)
      },
    ]
    return (
      <Box component={'div'} className='eMed_room_left_card' sx={{ height: "63vh" }}>
        <Tabs
          value={this.state.SelectedTab}
          onChange={(e, newValue) => {
            this.setState({
              SelectedTab: newValue,
              PatientWardList: [],
              PatientBlockList: [],
              PatientFloorList: [],
              PatientBedList: [],
              PatientRoomList: [],
              PatientWard: "",
              PatientBlock: "",
              PatientFloor: "",
              PatientRoom: "",
              PatientBed: "",
              PatientFloorId: "",
              PatientBederror: false,
              PatientBedtext: "",
              isEdit: false
            }, () => {
              if (this.state.SelectedTab === "attender") {
                this.setState({
                  bedDataList: this.state.OrginalWardList.filter((item) => (item.is_attender === true)),
                  roomChangeData: this.state.BothRoomList?.attender_list ? this.state.BothRoomList?.attender_list : []
                })
              } else {
                this.setState({
                  bedDataList: this.state.OrginalWardList,
                  roomChangeData: this.state.BothRoomList?.patient_list ? this.state.BothRoomList?.patient_list : []
                })
              }
            })
          }}
          sx={{ backgroundColor: Colors.DataTblHeaderbg }}
        >
          <Tab value="inpatient" label="In Patient" className='eMed_tab_header' />
          {this.state.ipPatient?.is_attender === true ? <Tab value="attender" label="Attender" className='eMed_tab_header' /> : null}
          
        </Tabs>
        <DataGrid
          className='eMed_room_grid'
          rows={this.state.roomChangeData}
          columns={columns}
          getRowId={(row) => row.sno}
          hideFooter
          density='compact'
          components={{
            NoRowsOverlay: () => (
              <Box className='eMed_conf_nodata'>
                {t("NoRecordsFound")}
              </Box>
            )
          }}
          disableSelectionOnClick
        />
      </Box>
    )
  }

  renderRightContent = () => {
    const { t } = this.props;
    let states = this.state;
    return (
      <Box component={'div'} className='eMed_room_right_card' sx={{ height: "69.2vh" }}>
        <Box component={'div'} className='eMed_room_right_header'>
          <Typography fontWeight={600} color={Colors.SecondaryText}>{this.state.SelectedTab === "inpatient" ? t("In Patient Room Change") : t("Attender Room Change")}</Typography>
          {this.state.bedDataList?.length === 0 ?
            <Box ml={"1vw"}>
              <FormHelperText sx={{ color: "red" }}>{"Ward is Not Available"}</FormHelperText>
            </Box> : null}
        </Box>
        <Box component={'div'} className='eMed_room_right_content'>
          {this.state.isEdit ?
            <Box component={'div'} className='eMed_room_right_top'>
              <FormControlLabel sx={{ marginLeft: '0.5vw' }}
                onClick={() => {
                  if (!this.state.toDate) {
                    this.setState({
                      isEdit: false,
                      PatientWard: "",
                      PatientBlock: "",
                      PatientFloor: "",
                      PatientRoom: "",
                      PatientBed: "",
                      roomChangeDate: new Date(),
                      clickRoomChange: true
                    })
                  }
                }}
                label="Click For Room Change"
                disabled={this.state.toDate}
                control={<Radio />}
              />
              <Box component={'div'} className='eMed_room_right_top'>
                <TextField className='eMed_room_select_one' label='Ward Name' size='small' value={this.state.PatientWard} />
                <TextField className='eMed_room_select_one' label='Block' size='small' value={this.state.PatientBlock} />
                <TextField className='eMed_room_select_one' label='Floor' size='small' value={this.state.PatientFloor} />
                <TextField className='eMed_room_select_two' label='Room' size='small' value={this.state.PatientRoom} />
                <TextField className='eMed_room_select_two' label='Bed' size='small' value={this.state.PatientBed} />
                <FormControl className='eMed_room_radio'>
                  {
                    this.state.SelectedTab === "inpatient" ?
                      <RadioGroup row value={this.state.roomTime}>
                        <FormControlLabel value={'hourly'} control={<Radio size='small' disabled />} label="Hours" />
                        <FormControlLabel value={'day'} control={<Radio size='small' disabled />} label="Day" />
                      </RadioGroup> :
                      <RadioGroup row value={'day'}>
                        <FormControlLabel value={'day'} control={<Radio size='small' disabled />} label="Day" />
                      </RadioGroup>
                  }
                </FormControl>
              </Box>
            </Box> :
            <Box component={'div'} className='eMed_room_right_top'>
              <FormControl size='small' className='eMed_room_select_one'>
                <InputLabel>{`${t("SelectWardName")} *`}</InputLabel>
                <Select
                  label="Select Ward Name *"
                  value={this.state.PatientWard}
                  disabled={this.state.bedDataList.length === 0}
                  onChange={(e) => {
                    states.PatientBedtext = ""
                    states.PatientBederror = false
                    states.PatientFloorId = ''
                    states.PatientFloorList = []
                    states.PatientBedList = []
                    states.PatientRoomList = []
                    states.PatientBlock = ""
                    states.PatientFloor = ""
                    states.PatientRoom = ""
                    states.PatientBed = ""
                    this.setState({
                      PatientWard: e.target.value,
                      PatientBlockList: this.RemoveDuplicates(this.state.WardList.filter((item) => (item.ward_id === e.target.value)), "block_id"),
                      states
                    }, () => {
                      if (this.state.PatientBlockList.length === 0) {
                        this.errorMessage("Block is Not Avaliable")
                      }
                      if (this.state.clickRoomChange) {
                        if (this.state.PatientBlockList?.length == 1) {
                          this.setState({
                            PatientBlock: this.state.PatientBlockList[0].block_id,
                            PatientFloorList: this.RemoveDuplicates(this.state.WardList.filter((item) => (item.ward_id === this.state.PatientWard && item.block_id === this.state.PatientBlockList[0].block_id)), "floor")
                          }, () => {
                            if (this.state.PatientFloorList?.length == 1) {
                              this.setState({
                                PatientFloor: this.state.PatientFloorList[0].floor,
                                PatientRoomList: this.RemoveDuplicates(this.state.WardList.filter((item) => (item.ward_id === this.state.PatientWard && item.block_id === this.state.PatientBlock && item.floor == this.state.PatientFloorList[0].floor)), "room_number")
                              }, () => {
                                if (this.state.PatientRoomList?.length == 1) {
                                  this.setState({
                                    PatientRoom: this.state.PatientRoomList[0].room_number,
                                    PatientBedList: this.state.WardList.filter((item) => (item.ward_id === this.state.PatientWard && item.block_id === this.state.PatientBlock && item.floor == this.state.PatientFloor && item.room_number === this.state.PatientRoomList[0].room_number))
                                  }, () => {
                                    if (this.state.PatientBedList?.length == 1) {
                                      this.setState({
                                        PatientBed: this.state.PatientBedList[0].bed_id,
                                        PatientBedObj: this.state.WardList.find((item) => (this.state.PatientBedList[0].bed_id === item.bed_id))
                                      }, () => {
                                        this.SelectRentTypeRadio(this.state.PatientBedObj?.is_day, this.state.PatientBedObj?.is_hourly)
                                      })
                                    }
                                  })
                                }
                              })
                            }
                          })
                        }
                      }
                    })
                  }}
                >
                  {this.state.bedDataList.map((item, index) => (
                    <MenuItem key={index} value={item?.ward_id}>{item?.ward_name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl size='small' className='eMed_room_select_one'>
                <InputLabel>{`${t("SelectBlock")} *`}</InputLabel>
                <Select
                  label="Select Block *"
                  value={this.state.PatientBlock}
                  disabled={this.state.PatientBlockList.length === 0}
                  onChange={(e) => {
                    states.PatientFloor = ""
                    states.PatientBedtext = ""
                    states.PatientBederror = false
                    states.PatientBedList = []
                    states.PatientRoomList = []
                    states.PatientRoom = ""
                    states.PatientBed = ""
                    this.setState({
                      PatientBlock: e.target.value,
                      PatientFloorList: this.RemoveDuplicates(this.state.WardList.filter((item) => (item.ward_id === this.state.PatientWard && item.block_id === e.target.value)), "floor"),
                      states
                    }, () => {
                      if (this.state.clickRoomChange) {
                        if (this.state.PatientFloorList?.length == 1) {
                          this.setState({
                            PatientFloor: this.state.PatientFloorList[0].floor,
                            PatientRoomList: this.RemoveDuplicates(this.state.WardList.filter((item) => (item.ward_id === this.state.PatientWard && item.block_id === this.state.PatientBlock && item.floor == this.state.PatientFloorList[0].floor)), "room_number")
                          }, () => {
                            if (this.state.PatientRoomList?.length == 1) {
                              this.setState({
                                PatientRoom: this.state.PatientRoomList[0].room_number,
                                PatientBedList: this.state.WardList.filter((item) => (item.ward_id === this.state.PatientWard && item.block_id === this.state.PatientBlock && item.floor == this.state.PatientFloor && item.room_number === this.state.PatientRoomList[0].room_number))
                              }, () => {
                                if (this.state.PatientBedList?.length == 1) {
                                  this.setState({
                                    PatientBed: this.state.PatientBedList[0].bed_id,
                                    PatientBedObj: this.state.WardList.find((item) => (this.state.PatientBedList[0].bed_id === item.bed_id))
                                  }, () => {
                                    this.SelectRentTypeRadio(this.state.PatientBedObj?.is_day, this.state.PatientBedObj?.is_hourly)
                                  })
                                }
                              })
                            }
                          })
                        }
                      }
                    })
                  }}
                >
                  {this.state.PatientBlockList?.length > 0 ?
                    this.state.PatientBlockList.map((list, index) => (
                      <MenuItem key={index} value={list?.block_id}>{list?.block_name}</MenuItem>
                    )) : null}
                </Select>
              </FormControl>
              <FormControl size='small' className='eMed_room_select_one'>
                <InputLabel>{`${t("SelectFloor")} *`}</InputLabel>
                <Select
                  label="Select Floor *"
                  value={this.state.PatientFloor}
                  disabled={this.state.PatientFloorList.length === 0 || this.state.toDate}
                  onChange={(e) => {
                    states.PatientBedList = []
                    states.PatientBed = ""
                    states.PatientRoom = ""
                    this.setState({
                      PatientFloor: e.target.value,
                      PatientRoomList: this.RemoveDuplicates(this.state.WardList.filter((item) => (item.ward_id === this.state.PatientWard && item.block_id === this.state.PatientBlock && item.floor == e.target.value)), "room_number"),
                      states
                    }, () => {
                      if (this.state.PatientRoomList?.length === 0) {
                        this.errorMessage("Room is Not Avaliable")
                      }
                      if (this.state.clickRoomChange) {
                        if (this.state.PatientRoomList?.length == 1) {
                          this.setState({
                            PatientRoom: this.state.PatientRoomList[0].room_number,
                            PatientBedList: this.state.WardList.filter((item) => (item.ward_id === this.state.PatientWard && item.block_id === this.state.PatientBlock && item.floor == this.state.PatientFloor && item.room_number === this.state.PatientRoomList[0].room_number))
                          }, () => {
                            if (this.state.PatientBedList?.length == 1) {
                              this.setState({
                                PatientBed: this.state.PatientBedList[0].bed_id,
                                PatientBedObj: this.state.WardList.find((item) => (this.state.PatientBedList[0].bed_id === item.bed_id))
                              }, () => {
                                this.SelectRentTypeRadio(this.state.PatientBedObj?.is_day, this.state.PatientBedObj?.is_hourly)
                              })
                            }
                          })
                        }
                      }
                    })

                  }}
                >
                  {this.state.PatientFloorList?.length > 0 ?
                    this.state.PatientFloorList.map((list, index) => (
                      <MenuItem key={index} value={list.floor}>{list.floor}</MenuItem>
                    )) : null}
                </Select>
              </FormControl>
              <FormControl size='small' className='eMed_room_select_two'>
                <InputLabel>{t("Room *")}</InputLabel>
                <Select
                  label="Room *"
                  value={this.state.PatientRoom}
                  disabled={this.state.PatientRoomList.length === 0}
                  onChange={(e) => {
                    this.setState({
                      PatientRoom: e.target.value,
                      PatientBedList: this.state.WardList.filter((item) => (item.ward_id === this.state.PatientWard && item.block_id === this.state.PatientBlock && item.floor == this.state.PatientFloor && item.room_number === e.target.value))
                    }, () => {
                      if (this.state.PatientBedList?.length === 0) {
                        this.errorMessage("No Bed is Avaliable")
                      }
                      if (this.state.clickRoomChange) {
                        if (this.state.PatientBedList?.length == 1) {
                          this.setState({
                            PatientBed: this.state.PatientBedList[0].bed_id,
                            PatientBedObj: this.state.WardList.find((item) => (this.state.PatientBedList[0].bed_id === item.bed_id))
                          }, () => {
                            this.SelectRentTypeRadio(this.state.PatientBedObj?.is_day, this.state.PatientBedObj?.is_hourly)
                          })
                        }
                      }
                    })
                  }}
                >
                  {this.state.PatientRoomList?.length > 0 ?
                    this.state.PatientRoomList.map((list, index) => (
                      <MenuItem key={index} value={list?.room_number}>{list?.room_number}</MenuItem>
                    )) : null}
                </Select>
              </FormControl>
              <FormControl size='small' className='eMed_room_select_two'>
                <InputLabel>{t("Bed *")}</InputLabel>
                <Select
                  label="Bed"
                  value={this.state.PatientBed}
                  disabled={this.state.PatientBedList.length === 0}
                  onChange={(e) => {
                    this.setState({ PatientBed: e.target.value, PatientBedtext: "", PatientBederror: false, PatientBedObj: this.state.WardList.find((item) => (e.target.value === item.bed_id)) }, () => {
                      this.SelectRentTypeRadio(this.state.PatientBedObj?.is_day, this.state.PatientBedObj?.is_hourly)
                    })
                  }
                  }
                >
                  {this.state.PatientBedList?.length > 0 ?
                    this.state.PatientBedList.map((list, index) => (
                      <MenuItem key={index} value={list?.bed_id}>{list?.bed_number}</MenuItem>
                    )) : null}
                </Select>
              </FormControl>
              {
                (Object.keys(this.state.PatientBedObj).length > 0 && this.state.PatientBed !== "" && this.state.SelectedTab === "inpatient") ?
                  <FormControl className='eMed_room_radio'>
                    <RadioGroup row
                      value={this.state.roomTime}
                      onChange={(e) => {
                        this.setState({
                          roomTime: e.target.value
                        })
                      }}
                    >
                      <FormControlLabel value={'hourly'} control={<Radio disabled={!this.state.PatientBedObj?.is_hourly} size='small' />} label="Hours" />
                      <FormControlLabel value={'day'} control={<Radio disabled={!this.state.PatientBedObj?.is_day} size='small' />} label="Day" />
                    </RadioGroup>
                  </FormControl> : null}
            </Box>}


          <Box component={'div'} className='eMed_room_right_bottom'>
            <Box component={'div'} display={'flex'}>
              <Box component={'div'} sx={{ backgroundColor: Colors.ThemeLightColor }} className='eMed_room_box_one'>
                <Typography ml={'0.5vw'} color={'#616161'} fontSize={'0.85vw'} fontWeight={600}>Room Change Date & Time</Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    inputFormat='DD-MM-YYYY & hh:mm A'
                    disabled={true}
                    value={this.state.roomChangeDate}
                    name={"roomChangeDate"}
                    onChange={(newDate) => {
                      if (newDate === null) {
                        this.setState({ roomChangeDate: new Date() })
                      } else {
                        this.setState({ roomChangeDate: newDate })
                      }
                    }
                    }
                    renderInput={(params) => <TextField size='small'
                      {...params}
                      fullWidth
                      variant='standard'
                      className='eMed_room_date_field'
                    />}
                  />
                </LocalizationProvider>
              </Box>
              <Box component={'div'} sx={{ backgroundColor: Colors.ThemeLightColor }} className='eMed_room_box_one'>
                <Typography ml={'0.5vw'} color={'#616161'} fontSize={'0.85vw'} fontWeight={600}>Net Amount</Typography>
                <Typography ml={'0.5vw'} mt={'1.5vw'} fontWeight={600}>{this.state.netAmount ? AmountFormat(this.state.netAmount) : AmountFormat(0)}</Typography>
              </Box>
            </Box>
            <Box component={'div'} display={'flex'}>
              <Box component={'div'} display={'flex'} sx={{ backgroundColor: Colors.ThemeLightColor }} className='eMed_room_box_one'>
                <Box component={'div'} className='eMed_room_text'>
                  <Typography pl={'0.5vw'} color={'#616161'} fontSize={'0.85vw'} fontWeight={600}>{`${t("concession")} %`}</Typography>
                  <TextField
                    size='small'
                    variant='standard'
                    autoComplete='off'
                    className='eMed_room_text_one'
                    placeholder='0 %'
                    value={this.state.percentage}
                    name='percentage'
                    onChange={(e) => this.onChangeHandler(e)}
                    disabled={!states.billSummaryId ? true : false}
                  />
                </Box>
                <Box component={'div'} className='eMed_room_text'>
                  <Typography pl={'0.5vw'} color={'#616161'} fontSize={'0.85vw'} fontWeight={600}>{t("concession")}</Typography>
                  <TextField
                    size='small'
                    variant='standard'
                    autoComplete='off'
                    className='eMed_room_text_one'
                    placeholder='0.00'
                    value={this.state.discount}
                    name='discount'
                    onChange={(e) => this.onChangeHandler(e)}
                    disabled={!states.billSummaryId ? true : false}
                  />
                </Box>
              </Box>
              <Box component={'div'} sx={{ backgroundColor: Colors.ThemeLightColor }} className='eMed_room_box_one'>
                <Typography pl={'0.5vw'} color={'#616161'} fontSize={'0.85vw'} fontWeight={600}>{`After ${t("concession")} Amount`}</Typography>
                <Typography ml={'0.5vw'} mt={'1.5vw'} fontWeight={600}>{this.state.percentage && this.state.discount ? AmountFormat(this.state.discountedAmount) : AmountFormat(0)}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box component={'div'} className='eMed_room_right_footer' display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <Button
            sx={{ marginRight: "0.8vw", textTransform: 'capitalize' }}
            size='small'
            variant='outlined'
            disabled={this.state.withOutAppointment?.is_ip || this.state.ipPatient?.status === "Checked Out"}
            onClick={() => {
              this.setState({
                PatientWard: "",
                PatientBlock: "",
                PatientFloor: "",
                PatientRoom: "",
                PatientBed: "",
                roomChangeDate: new Date(),
                percentage: "",
                discount: "",
                discountedAmount: 0,
                isEdit: false,
                afterCancel: false,
                PatientBlockList: [],
                PatientFloorList: [],
                PatientBedList: [],
                PatientRoomList: [],
                DateTimeChanged: false,
              })
            }}
          >{t("Clear")}</Button>
          <Button
            sx={{ marginLeft: "0.8vw", textTransform: 'capitalize' }}
            size='small'
            variant='contained'
            disabled={CheckAccessFunc("front_office", "In Patients", "Room Change", null, "Tab")?.editAccess ? (this.state.withOutAppointment?.is_ip || this.state.ipPatient?.status === "Checked Out" || this.state.isBtnDisable) : true}
            onClick={() => {
              this.setState({ isBtnDisable : true},()=>{
                if (this.state.toDate) {
                  this.putRoomChangeData()
                }
                //  else if (this.state.afterCancel) {
                //   this.postAfterCancelRoomChangeData()
                // } 
                else {
                  this.postRoomChangeData()
                }
              })
            }}>{t("Add")}</Button>
        </Box>
      </Box>
    )
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  popupClose() {
    this.setState({
      selectedOption: ""
    })
  }
  CheckOutAttender = () => {
    try {
      let startDate = new Date(this.state.CheckOutdateTime)
      let date = this.state.CheckOutdateTime ? DateTime.fromJSDate(startDate).toFormat("yyyy-MM-dd") : ""
      let time = this.state.CheckOutdateTime ? DateTime.fromJSDate(startDate).toLocaleString(DateTime.TIME_24_WITH_SECONDS) : ""
      let data = {
        "patient_id": this.state.ipPatient?.patient_id,
        "admission_id": this.state.ipPatient?.id,
        "bed_id": this.state.CheckOutData?.bed_id,
        "ward": this.state.CheckOutData?.ward_name,
        "block": this.state.CheckOutData?.block_name,
        "floor": this.state.CheckOutData?.floor_number,
        "room": this.state.CheckOutData?.room_number,
        "room_id": this.state.CheckOutData?.id,
        "discharge_date": date,
        "discharge_time": time
      }
      this.LoaderFunction(true)
      RestAPIService.create(data, Serviceurls.IP_ATTENDER_CHECKOUT).
        then((response) => {
          if (response.data.status === 'success') {
            this.setState({ isEdit: false, clickRoomChange: false, CheckOutdateTime : new Date(),selectedOption :""  })
            this.onClearHandler()
            this.getRoomChangeData()
            this.getWardList()
            this.LoaderFunction(false)
            this.successMessage(response.data.message)
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.LoaderFunction(false)
            this.errorMessage(error.response.data.message)
          } else {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  _getattenderCheckout = () => {
    const { t } = this.props;
    let { CheckOutdateTime } = this.state;
    return (
      <Stack direction="column" justifyContent="center" alignItems="flex-start" sx={{ width: "100%" }}>
        <div>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              // open={open}
              // onOpen={() => { this.setState({ open: true }) }}
              // onClose={() => { this.setState({ open: false }) }}
              label={`${t("Checkout")} ${t("DateAndTime")}` + "*"}
              value={CheckOutdateTime ? CheckOutdateTime : null}
              inputFormat='dd-MM-yyyy & hh:mm a'
              minDateTime={new Date(Date.now() - 15 * 24 * 60 * 60 * 1000)}
              maxDateTime={new Date(Date.now() + 1 * 24 * 60 * 60 * 1000)}
              onChange={(value) => {
                if (value === null) {
                  this.setState({ CheckOutdateTime: new Date() })
                } else {
                  this.setState({
                    CheckOutdateTime: value
                  })
                }
              }}
              renderInput={(params) => <TextField size="small" sx={{ width: "16vw" }} {...params}
              />}
            />
          </LocalizationProvider>
        </div>
        <Box>
          <Divider />
        </Box>
      </Stack>
    )
  }


  render() {
    const { t } = this.props
    let attenderDetails = {
      lineOne: t("AttenderName"),
      lineTwo: `${this.state.attenderName ? this.state.attenderName : "-"}`,
      lineFour: t('ContactNumber'),
      LineFive: `${this.state.attenderMobile ? this.state.attenderMobile : '-'}`
    }
    const rupeeImg = CurrencySymbol==='$' ? ImagePaths.Dollor.default : ImagePaths.Rupee.default;
    let serviceAmount = {
      image: rupeeImg,
      height: '1.5vw',
      width: '1.5vw',
      lineOne: t("BillAmount"),
      lineTwo: `${AmountFormat(this.state.serviceAmount || 0)}`,
      // secHead: t('PaidAmount'),
      // secAmt:  `${this.state.paidAmount ? AmountFormat(this.state.paidAmount) : "₹ 0"}`
    }
    let PaidDetails = {
      image: rupeeImg,
      height: '1.5vw',
      width: '1.5vw',
      lineOne: t("Received Amount"),
      lineTwo: `${AmountFormat(this.state.paidAmount || 0)}`,
      // secHead: t('PaidAmount'),
      // secAmt:  `${this.state.paidAmount ? AmountFormat(this.state.paidAmount) : "₹ 0"}`
    }
    let pendingAmount = {
      image: rupeeImg,
      height: '1.5vw',
      width: '1.5vw',
      lineOne: t("BalAmt"),
      lineTwo: `${AmountFormat(this.state.pendingAmount || 0)}`,
      colorCode: +this.state.pendingAmount > 0 ? 'green' : +this.state.pendingAmount === 0 ? "balck" : 'red'
      // secHead: t('ApproxAmount'),
      // secAmt:  `${this.state.ApproxAmount ? AmountFormat(this.state.ApproxAmount) : "₹ 0"}`
    }
    let ApproxDetails = {
      image: rupeeImg,
      height: '1.5vw',
      width: '1.5vw',
      lineOne: t('ApproxAmount'),
      lineTwo: `${AmountFormat(this.state.ApproxAmount || 0)}`
      // lineOne: t("AdvanceAmount"),
      // lineTwo: `${this.state.AdvanceAmount ? AmountFormat(this.state.AdvanceAmount) : "₹ 0"}`
    }
    let AdvanceDetails = {
      image: rupeeImg,
      height: '1.5vw',
      width: '1.5vw',
      lineOne: t('AdvanceAmount'),
      lineTwo: `${AmountFormat(this.state.AdvanceAmount || 0)}`
      // lineOne: t("AdvanceAmount"),
      // lineTwo: `${this.state.AdvanceAmount ? AmountFormat(this.state.AdvanceAmount) : "₹ 0"}`
    }
    let ClaimDetails = {
      image: rupeeImg,
      width: "1.3vw",
      height: "1.3vw",
      lineOne: t('Claimed Amount'),
      lineTwo: `${AmountFormat(this.state.ApprovedAmt || 0)}`
    }
    return (
      <Box component={'div'} className='eMed_room_container'>
        <Box component={'div'} className='eMed_room_header'>
          <Box display={'flex'} >
            <Box className='eMed_nur_notes_details' ml={'0.8vw'}>
              <CommonPatientDetails data={this.state.ipPatient} showDetailed={true} />
            </Box>
            {/* <CommonPatientCard details={attenderDetails} showDetailed={true} /> */}
            <CommonPatientCard details={AdvanceDetails} />
            <CommonPatientCard details={serviceAmount} smallWidth={true}/>
            <CommonPatientCard details={PaidDetails} smallWidth={true}/>
            <CommonPatientCard details={pendingAmount} smallWidth={true}/>
            <CommonPatientCard details={ApproxDetails} smallWidth={true}/>
            <CommonPatientCard details={ClaimDetails} smallWidth={true} />
          </Box>
        </Box>
        <Box component={'div'} className='eMed_room_content'>
          <Box component={'div'} className='eMed_room_left_content'>
            {this.renderLeftContent()}
          </Box>
          <Box component={'div'} className='eMed_room_right_content'>
            {this.renderRightContent()}
          </Box>
          {this.state.deletePopup ? this.renderDeletePopUp() : ""}
        </Box>

        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        <Loader loaderOpen={this.state.isLoader} />
        {(this.state.selectedOption === "AttenderCheckOut") ?
          //  <DeletePopup
          //     DeletTitle={`${t("Are you Sure want to Check-Out the Attender.")}`}
          //     deleteAlertPopupClose={this.popupClose.bind(this)}
          //     removeData={this.CheckOutAttender.bind(this)}
          //     ButtonText={"Check Out"}
          //   />
          <CommonPopUp width={"35vw"} popUpClose={this.popupClose.bind(this)} title={t("Attender Checkout")} justifyRight={true}
            component={this._getattenderCheckout.bind(this)} buttonOne={t("Close")}
            buttonOneAction={this.popupClose.bind(this)} buttonTwo={`${t("Check Out the Attender")}`}
            buttonTwoAction={this.CheckOutAttender.bind(this)} disableBtn2={false} />
          : null}
      </Box>
    )
  }
}


export default withTranslation()(RoomChange)