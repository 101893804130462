import React, { Component } from 'react';
import {
    Box, Button, MenuItem, Paper, Stack, Tooltip, Menu, IconButton,
    Typography
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { withTranslation } from 'react-i18next'
import './Billings.css';
import { AmountsCard } from '../../../Components/CommonCards/CommonCards';
import { ImagePaths } from '../../../Utility/ImagePaths';
import Drawer from '@mui/material/Drawer';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import BillingsFilter from './BillingsFilter';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import { DateTime } from 'luxon';
import PrintPreviewPop from '../../../Components/Common Components/PrintPreviewPop';
import { localGetItem, setCachevalue } from '../../../Utility/Services/CacheProviderService';
import { CheckAccessFunc, ContentInTooltip, formatDate, timeOnlyConvert } from '../../../Components/CommonFunctions/CommonFunctions';
import {DeletePopup,CommonGridToolBarWithFilterTextCustom } from '../../../Components/Common Components/CommonComponents';
import { AmountFormat } from '../../../Components/CommonFunctions/CommonFunctions';
import { CurrencySymbol } from '../../../Utility/Constants';

let userAccess = null;
class SavedBills extends Component {
    constructor(props) {
        super(props)
        let LoggedData = localGetItem("loggedInUserInfo") ? JSON.parse(localGetItem("loggedInUserInfo")) : null
        let PharmacyList = LoggedData?.pharmacy_data ? JSON.parse(JSON.stringify(LoggedData?.pharmacy_data)).map((list) => list?.pharmacy_id) : []
        let LabList = LoggedData?.laboratory_data ? JSON.parse(JSON.stringify(LoggedData?.laboratory_data)).map((list) => list?.laboratory_id) : []
        this.state = {
            page: 0,
            pageSize: 10,
            BillList: [],
            ref: props.location?.pathname,
            FilterOpen: false,
            FilterDatas: {
                "FromDate": DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                "ToDate": DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                "DateIndex": 1,
                "PatientName": "",
                "AccNo": "",
                "MobNo": "",
                "DoctorName": [],
                "BillNumber": "",
                "PaidAmount": "",
                "InPatientSelected": true,
                "OutPatientSelected": true,
                "PharmacySelected": localGetItem("Show_Pharmacy_Bills_In_FO") === "false" ? false : true,
                "LaboratorySelected": true,
                "ImageLabSelected": true,
                "OTSelected": true,
                "PaidSelected": true,
                "PartialPaidSelected": true,
                "CreditSelected": true,
                "CardSelected": true,
                "CashSelected": true,
                "UPISelected": true,
                "BankTransferSelected": true,
                "InsuranceSelected": true,
                "ChequeSelected": true,
                "BillType": ['op', 'ip', 'lab', localGetItem("Show_Pharmacy_Bills_In_FO") === "true" ? 'pharmacy' : "-", localGetItem("Show_Radiology_Bills_In_FO") === "true" ? 'radiology' : "-", 'ot'],
                "PayStatus": [],
                "PayMethods": [],
                "startTime": null,
                "endTime": null,
                "BillModuleType": ['op', 'ip', 'lab', localGetItem("Show_Pharmacy_Bills_In_FO") === "true" ? 'pharmacy' : "-", localGetItem("Show_Radiology_Bills_In_FO") === "true" ? 'radiology' : "-", 'ot'],
                "opCreditBills" : true,
                "ipCreditBills" : true,
                "pharmaCreditBills" : localGetItem("Show_Pharmacy_Bills_In_FO") === "false" ? false : true,
                "labCreditBills" : true,
                "RadiologyBills":localGetItem("Show_Radiology_Bills_In_FO") === "true" ? true : false,
                "RadiologySelected":localGetItem("Show_Radiology_Bills_In_FO") === "true" ? true : false,
                "radiologyCreditBills":localGetItem("Show_Radiology_Bills_In_FO") === "true" ? true : false,
                'MultiPharmaIds': localGetItem("Show_Pharmacy_Bills_In_FO") === "false" ? [] : PharmacyList,
                'MultiLabIds': LabList,
            },
            TotalPatient: 0,
            PaidAmount: 0,
            PendingAmount: 0,
            BillPreviewPop: false,
            AppointmentDetails: {},
            labPatientSavedBillData: {},
            isPharmacy: false,
            SelectedBill: {},
            selectedDeleteItem: {},
            billDeleteClicked: false,
            removeDisable: false,
            disableBtn: false,
            showTokenPrint: false
        }
    }

    componentDidMount() {
        var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
        var UserData = myUser
        userAccess = CheckAccessFunc(UserData?.pharmacy_type === 'Inhouse Pharmacy'? "Pharmacy" : "Standalone Pharmacy", "Billing Transaction", 'Saved Bills', null, "Tab");
        this.setState({
            isPharmacy: UserData?.module_name == "Pharmacy" ? true : false,
            showTokenPrint: UserData?.show_pharmacy_sales_token ? true : false
        }, () => { this.GetBillList() })
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    GetBillList() {
        try {
            var states = this.state

            let start_time = states.FilterDatas.startTime ? new Date(states.FilterDatas.startTime) : ''
            let end_time = states.FilterDatas.endTime ? new Date(states.FilterDatas.endTime) : ''
            let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);

            let doctorId = [];
            states.FilterDatas?.DoctorName.forEach(element => doctorId.push(element?.doctor_id))
            this.LoaderFunction(true)
            RestAPIService.getAll(states.isPharmacy ? Serviceurls.PHARMACY_SAVED_BILL_LIST + "?from_date=" + states.FilterDatas.FromDate + "&to_date=" + states.FilterDatas.ToDate +
                "&start_time=" + setStartTime + "&end_time=" + setEndTime + "&patient_name=" + states.FilterDatas.PatientName + "&mobile_number=" + states.FilterDatas.MobNo +
                "&account_number=" + states.FilterDatas.AccNo + "&doctor_id=" + doctorId + "&bill_number=" + states.FilterDatas.BillNumber
                :
                Serviceurls.COMPLETE_BILLS_GET +
                "?bill_status=saved" +
                "&patient_name=" + states.FilterDatas.PatientName +
                "&account_number=" + states.FilterDatas.AccNo +
                "&mobile_number=" + states.FilterDatas.MobNo +
                "&doctor_id=" + doctorId +
                "&bill_number=" + states.FilterDatas.BillNumber +
                "&paid_amount=" + states.FilterDatas.PaidAmount +
                "&from_date=" + states.FilterDatas.FromDate +
                "&to_date=" + states.FilterDatas.ToDate +
                "&moduel_type=" + states.FilterDatas.BillType +
                "&payment_method=" + states.FilterDatas.PayMethods +
                "&start_time=" + setStartTime +
                "&bills=" + states?.FilterDatas?.BillModuleType +
                "&end_time=" + setEndTime +
                "&pharmacy_id=" + states?.FilterDatas?.MultiPharmaIds +
                "&laboratory_id=" + states?.FilterDatas?.MultiLabIds
            ).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            BillList: response.data.data,
                            TotalPatient: response.data.total_patient ? response.data.total_patient : response?.data?.total_bill,
                            PaidAmount: response.data.paid_amount,
                            PendingAmount: response.data.total_amount,
                        }, () => { this.LoaderFunction(false) })
                    }
                    else {
                        this.LoaderFunction(false)
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === "fail") {
                        this.LoaderFunction(false)
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.LoaderFunction(false)
                        this.errorMessage(error.message)
                    }
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }


    ClosePopUp(Getapicall = false, message = "") {
        this.setState({
            FilterOpen: false,
            BillPreviewPop: false,
        }, () => {
            if (Getapicall && message !== "") {
                this.successMessage(message)
                this.GetBillList()
            }
        })
    }

    PrintReport = () => {
        try {
            var states = this.state

            let start_time = states.FilterDatas.startTime ? new Date(states.FilterDatas.startTime) : ''
            let end_time = states.FilterDatas.endTime ? new Date(states.FilterDatas.endTime) : ''
            let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);

            let doctorId = [];
            states.FilterDatas?.DoctorName.forEach(element => doctorId.push(element?.doctor_id))
            this.setState({disableBtn: true})
            RestAPIService.PrintPDF(Serviceurls.PHARMACY_SAVED_BILL_LIST + "?from_date=" + states.FilterDatas.FromDate + "&to_date=" + states.FilterDatas.ToDate +
                "&start_time=" + setStartTime + "&end_time=" + setEndTime + "&patient_name=" + states.FilterDatas.PatientName + "&mobile_number=" + states.FilterDatas.MobNo +
                "&account_number=" + states.FilterDatas.AccNo + "&doctor_id=" + doctorId + "&bill_number=" + states.FilterDatas.BillNumber + "&export_type=pdf"
            ).
                then((response) => {
                    if (response) {
                        //Create a Blob from the PDF Stream
                        const file = new Blob(
                            [response.data],
                            { type: 'application/pdf' });
                        //Build a URL from the file
                        const fileURL = URL.createObjectURL(file);
                        //Open the URL on new Window
                        window.open(fileURL);
                        this.setState({disableBtn: false})
                    }
                }).catch((error) => {
                    this.errorMessage(error.message)
                    this.setState({disableBtn: false})
                })
        } catch (e) {
            this.errorMessage(e.message)
            this.setState({disableBtn: false})
        }
    }

    PopUpClose() {
        this.setState({
            SelectedBill: {},
        })
    }

    DeleteSavedBill() {
        try {
            this.setState({ removeDisable: true })
            RestAPIService.delete(Serviceurls.PHARMACY_SAVED_BILL_LIST + "?bill_summary_id=" + this.state.SelectedBill?.id)
                .then(response => {
                    if (response.data.status == "success") {
                        this.setState({
                            SelectedBill: {},
                        }, () => {
                            this.GetBillList()
                            this.setState({ removeDisable: false })
                            this.successMessage(response.data?.message)
                        });
                    }
                })
                .catch(e => {
                    if (e?.reponse?.data?.status === 'fail') {
                        this.setState({ removeDisable: false })
                        this.errorMessage(e.reponse.data.status)
                    } else {
                        this.setState({ removeDisable: false })
                        this.errorMessage(e.message)
                    }
                });
        }
        catch (e) {
            this.setState({ removeDisable: false })
            this.errorMessage(e.message)
        }
    }

    MenuItem = (data, fromPharmacy = false) => {
        const { t } = this.props
        let multi_option = []
        if (fromPharmacy) {
            multi_option = [
                { value: "SalesBillPrint", label: t("Sales Bill Print") },
                { value: "TokenPrint", label: t("Token Print") },
            ]
        } else {
            multi_option = [
                // { value: "NextAppointment", label: t("NextAppointment") },
                // { value: "PrintDoctorNotes", label: t("PrintDoctorNotes") },
                // { value: "PrintPrescription", label: t("PrintPrescription") },
                // { value: "ShareViaEmail", label: t("ShareViaEmail") },
                // { value: "ShareViaWhatsApp", label: t("ShareViaWhatsApp") },
                // { value: "ShareViaSMS", label: t("ShareViaSMS") },
                { value: "deleteBill", label: t("Delete") },
            ]
        }
        return (
            <div>
                <Tooltip title={t("More")} placement='top' arrow>
                    <IconButton
                        disabled={this.state.billDeleteClicked}
                        onClick={(e) => {
                            this.setState({
                                anchorEl: e.currentTarget,
                                selectedRow: data.id,
                                isSelected: true
                            })
                        }}
                    >
                        <MoreVertIcon />
                    </IconButton>
                </Tooltip>
                {(this.state.isSelected && this.state.selectedRow == data.id) ?
                    <Menu
                        anchorEl={this.state.anchorEl}
                        open={Boolean(this.state.anchorEl)}
                        onClose={() => {
                            this.setState({ anchorEl: null, })
                        }}>
                        {multi_option.map((option) => (

                            <MenuItem key={option} 
                            onClick={() => {
                                if (fromPharmacy) {
                                    this.getSavedPrint(data?.row, option?.value)
                                } else {
                                    this.setState({
                                        selectedDeleteItem: data.row,
                                        billDeleteClicked: true,
                                        anchorEl: null
                                    })
                                }
                            }}>
                                {t(option.label)}
                            </MenuItem>
                        ))}
                    </Menu> : null}
            </div>
        )
    }

    getSavedPrint = (data, option) => {
        try {
            let url = ''
            if (option === "TokenPrint") {
                url = Serviceurls.PHARMA_BILL_TOKEN_PRINT
            } else {
                url = Serviceurls.PHARMA_SALES_BILL_PRINT
            }
            RestAPIService.getAllPrint(url + `?bill_summary_id=${data?.id ? data?.id : null}&export=pdf`).
                then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    this.setState({ isSelected : false, selectedRow: null})
                }).catch((e) => {
                    if (e?.response?.data?.message) {
                        this.errorMessage(e?.response?.data?.message)
                    } else {
                        this.errorMessage(e?.message)
                    }
                    this.setState({ isSelected : false, selectedRow: null})
                })
        } catch (e) {
            this.errorMessage(e.message)
            this.setState({ isSelected : false, selectedRow: null})
        }
    }

    renderFilter = (data) => {
        this.setState({
            FilterDatas: data,
            FilterOpen: false,
        }, () => { this.GetBillList() })
    }

    GetAppointmentDetails(id, billid) {
        try {
            const { history } = this.props;
            this.setState({disableBtn: true})
            RestAPIService.getAll(Serviceurls.FO_GET_APPOINMENT_DETAILS + "?appointment_id=" + id).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            AppointmentDetails: response.data.data
                        }, () => {
                            setCachevalue(JSON.stringify(this.state.AppointmentDetails), "patientData")
                            history.push({ pathname: "/OPHome/PatientDetails/Billing", state: { AppointmentID: id, InvoiceID: billid } })
                            this.setState({disableBtn: false})
                        })
                    }
                    else {
                        this.errorMessage(response.data.message)
                        this.setState({disableBtn: false})
                    }
                }).catch((error) => {
                    this.setState({disableBtn: false})
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    GetIPAppointmentDetails(id, billid) {
        try {
            const { history } = this.props;
            this.setState({disableBtn: true})
            RestAPIService.getAll(Serviceurls.FO_GET_IP_ADMISSION_DETAILS + "?ip_admission_id=" + id).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            AppointmentDetails: response.data.data
                        }, () => {
                            setCachevalue(JSON.stringify(this.state.AppointmentDetails), "IppatientData")
                            history.push({ pathname: "/IPHome/PatientDetails/ServiceBilling", state: { AppointmentID: id, InvoiceID: billid } })
                            this.setState({disableBtn: false})
                        })
                    }
                    else {
                        this.setState({disableBtn: false})
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.setState({disableBtn: false})
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }
    gridToolBar = () => {
        var { t } = this.props
        let start_time = this.state.FilterDatas.startTime ? new Date(this.state.FilterDatas.startTime) : ''
        let end_time = this.state.FilterDatas.endTime ? new Date(this.state.FilterDatas.endTime) : ''
        let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
        let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);

        let TextArray = [
            { label: "From Date", value: formatDate(this.state.FilterDatas.FromDate) },
            { label: "To Date", value: formatDate(this.state.FilterDatas.ToDate) },
        ]
        if (this.state.FilterDatas.startTime !== null && this.state.FilterDatas.endTime !== null) {
            TextArray.push(
                { label: "Start Time", value: timeOnlyConvert(setStartTime) },
                { label: "End Time", value: timeOnlyConvert(setEndTime) }
            )
        }
        var ExportData = []
            this.state.BillList?.length > 0 && this.state.BillList.map((item) => {
                let data = {"Date And Time":item.invoice_date, "Patient Name":item.patient_name , "Bill Type":item.bill_types ? item.bill_types: "Pharmacy","BillNumber":item.temp_bill_number ? item.temp_bill_number : item.bill_number,"DoctorNurseName":item.doctor_name,'BillAmount':item.amount_net}
                if(this.state.showTokenPrint){
                    data['Token Number'] = item?.temp_invoice_sequence
                }
                ExportData?.push(data)
            }
            // ExportData.push({"Date And Time":item.invoice_date, "Patient Name":item.patient_name , "Bill Type":item.bill_types ? item.bill_types: "Pharmacy","BillNumber":item.temp_bill_number ? item.temp_bill_number : item.bill_number,"DoctorNurseName":item.doctor_name,'BillAmount':item.amount_net})
            )
        return (
            <Box>
                <CommonGridToolBarWithFilterTextCustom data={ExportData} FilterTextArray={TextArray} title={this.state?.isPharmacy ? "Pharmacy Saved Bills":"FO SavedBills Bills"} filename={"SavedBills"}/>
            </Box>
        )
    }
    clearFilter = (data) => {
        this.setState({
            FilterDatas: data
        })
    }

    popupClose = () => {
        this.setState({
            billDeleteClicked: false,
            selectedDeleteItem: {},
        })
    }

    onDeleteBill = () => {
        let { selectedDeleteItem } = this.state
        try {
            let url = ""
            let data = {}

            if (selectedDeleteItem?.bill_types === "Out Patient") {
                url = Serviceurls.FO_PATIENT_APPOINTMENT_SAVED_GET
                data = {
                    id: selectedDeleteItem?.id
                }
            } else if (selectedDeleteItem?.bill_types === "In Patient") {
                url = Serviceurls.IP_PATIENT_INDIVIDUAL_SAVED_BILL
                data = {
                    id: selectedDeleteItem?.id
                }
            } else if (selectedDeleteItem?.bill_types === "Laboratory") {
                url = Serviceurls.LAB_SAVED_BILL_DELETE + "?bill_summary_id=" + selectedDeleteItem?.id + '&laboratory_id=' + selectedDeleteItem?.laboratory_id
            } else if (selectedDeleteItem?.bill_types === "Radiology") {
                url = Serviceurls.RAD_SAVED_BILL_GET + '?id=' + selectedDeleteItem?.id
            }
            this.setState({ removeDisable: true })
            RestAPIService.delete(url, data)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.successMessage(response.data.message)
                        this.GetBillList()
                        this.popupClose()
                        this.setState({ removeDisable: false })
                    }
                    else {
                        this.errorMessage(response.data.message)
                        this.setState({ removeDisable: false })
                    }
                }).catch((error) => {
                    this.errorMessage(error.message)
                    this.setState({ removeDisable: false })
                })
        }
        catch (e) {
            this.errorMessage(e.message)
            this.setState({ removeDisable: false })
        }

    }

    render() {
        const { t } = this.props
        this.state.BillList.forEach((element, index) => element.sno = index + 1)
        const columns = [
            {
                field: "invoice_date", flex: 0.143, headerName: t("DateAndTime"), headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.invoice_date ? params?.row?.invoice_date : "-"}</Box>)
            },
            {
                field: "patient_name", flex: 0.143, headerName: t("PatientName"),
                renderCell: (params) => (<Box component={'div'}>
                    {params?.row?.patient_name ? params?.row?.patient_name?.length > 15 ?
                        <Tooltip placement='top' title={params?.row?.patient_name} arrow>
                            <div>{params?.row?.patient_name.slice(0, 15) + "..."}</div></Tooltip> :
                        params?.row?.patient_name : "-"}</Box>)
            },
            {
                field: "bill_types", flex: 0.143, headerName: t("BillType"),
                renderCell: (params) => (
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                        <Typography textTransform={'capitalize'} component={'div'} fontSize={"0.9vw"} fontWeight={600}>{params?.row?.bill_types ? params?.row?.bill_types : "-"}</Typography>
                        <Typography textTransform={'capitalize'} component={'div'} fontSize={"0.7vw"} >{
                            (params?.row?.bill_types === "Pharmacy" && localGetItem("multiPharmacy") === "true") ? ContentInTooltip(params?.row?.pharmacy_name ? params?.row?.pharmacy_name : "-", 10, "0.8vw") :
                                (params?.row?.bill_types === "Laboratory" && localGetItem("multiLaboratory") === "true") ? ContentInTooltip(params?.row?.laboratory_name ? params?.row?.laboratory_name : "-", 10, "0.8vw") : ""}</Typography>
                    </Box>)
            },
            {
                field: "temp_bill_number", flex: 0.143, headerName: t("BillNumber"), headerAlign: "center", align: "center",
                renderCell: (params) => {
                    let BillNumber = "";
                    if (params?.row?.temp_bill_number && params?.row?.bill_number) { BillNumber = params?.row?.bill_number }
                    else if (params?.row?.bill_number) { BillNumber = params?.row?.bill_number }
                    else if (params?.row?.temp_bill_number) { BillNumber = params?.row?.temp_bill_number }
                    const billArr = BillNumber ? BillNumber?.split("/"): [];
                    return (
                        <Tooltip placement='top' title={BillNumber} arrow>
                            <Box component={'div'}>{billArr?.length > 0  ? '...' + billArr?.slice(-2)?.join("/") : "-"}</Box>
                        </Tooltip>
                    )
                }
            },
            {
                field: "doctor_name", flex: 0.24, headerName: t("DoctorNurseName"),
                renderCell: (params) => (<Box component={'div'}>{(params?.row?.doctor_name) ? (params?.row?.doctor_name) : (params?.row?.nurse_name) ? (params?.row?.nurse_name) : "-"}</Box>)
            },
            {
                field: "amount_net", flex: 0.143, headerName: t('BillAmount')+` (${CurrencySymbol})`, type: "number",
                renderCell: (params) => (<Box component={'div'}>{AmountFormat(params?.row?.amount_net || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
            },
            {
                field: "action", flex: 0.143, headerName: t("Action"), headerAlign: "center", align: "center", sortable: false,
                renderCell: (params) => <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                    <Tooltip title={t("CreateBill")} placement="top" arrow>
                        <Button
                            disabled={this.state.disableBtn ? true : (CheckAccessFunc("front_office", "Billing Transaction", "Saved Bills", null, "Tab")?.editAccess ? (params?.row?.bill_types === "Out Patient" || params?.row?.bill_types === "In Patient" || params?.row?.bill_types === "Laboratory" || params?.row?.bill_types === "Radiology") : false) ? false : true}
                            onClick={() => {
                                if (params?.row?.bill_types === "Out Patient") {
                                    if (params.row.bill_type === "saved") {
                                        this.GetAppointmentDetails(params?.row?.appointment_id, params?.row?.id)
                                    }
                                }
                                else if (params?.row?.bill_types === "In Patient") {
                                    if (params.row.bill_type === "saved") {
                                        // this.setState({ CreditBillPopUp: true, SelectedBill: params.row })
                                        this.GetIPAppointmentDetails(params?.row?.ip_number_id, params?.row?.id)
                                    }
                                }
                                else if (params?.row?.bill_types === "Laboratory") {
                                    this.props.history.push({ pathname: "/Billing", state: { labPatientSavedBillData: params?.row } })
                                    setTimeout(() => {
                                        this.props.history.push({ pathname: "/Billing", state: { labPatientSavedBillData: {} } })
                                    }, 2000)
                                }else if(params?.row?.bill_types === "Radiology"){
                                    this.props.history?.push({ pathname: '/Billing/RadiologyBilling', state: { billSummaryId: params?.row?.id, patientData:  params?.row , SavedFromFO : true} })
                                }
                            }} className='eMed_usrconf_btn' emed_tid="SavedBill_CreateBill_Btn">
                            <img src={(CheckAccessFunc("front_office", "Billing Transaction", "Saved Bills", null, "Tab")?.editAccess ? (params?.row?.bill_types === "Out Patient" || params?.row?.bill_types === "In Patient" || params?.row?.bill_types === "Laboratory" || params?.row?.bill_types === "Radiology") : false) ? ImagePaths.CreateBill.default : ImagePaths.DisabledCreateBill.default} alt="create" className='eMed_action_img' />
                        </Button></Tooltip>
                    {(params?.row?.bill_types === "Out Patient" || params?.row?.bill_types === "In Patient" || params?.row?.bill_types === "Laboratory" || params?.row?.bill_types === "Radiology") ?
                        this.MenuItem(params) :
                        <Box sx={{ width: '2vw' }}></Box>}
                    {/* <Tooltip title={t("PrintBill")} placement="top" arrow>
                        <Button onClick={() => { this.setState({ BillPreviewPop: true, SelectedBill: params.row }) }} className='eMed_usrconf_btn'>
                            <img src={ImagePaths.PrintIcons.default} alt="Create Bill" className='eMed_action_img' />
                        </Button>
                    </Tooltip> */}
                    {/* {this.MenuItem(params)} */}
                </Box>
            }
        ]
        let PharmacyColumns = [
            {
                field: "invoice_date", flex: 0.11, headerName: t("DateAndTime"), headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.invoice_date ? params?.row?.invoice_date : "-"}</Box>)
            },
            {
                field: "patient_name", flex: 0.12, headerName: t("PatientName"),
                renderCell: (params) => (<Box component={'div'}>
                    {params?.row?.patient_name ? params?.row?.patient_name?.length > 20 ?
                        <Tooltip placement='top' title={params?.row?.patient_name} arrow>
                            <div>{params?.row?.patient_name.slice(0, 20) + "..."}</div></Tooltip> :
                        params?.row?.patient_name : "-"}</Box>)
            },{
                field: "patient_account_numbers", flex: 0.11, headerName: t("UHID"), headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.patient_account_numbers ? params?.row?.patient_account_numbers : "-"}</Box>)
            },{
                field: "billing_customer_mobile_no", flex: 0.11, headerName: t("MobileNumber"), headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.billing_customer_mobile_no ? params?.row?.billing_customer_mobile_no : "-"}</Box>)
            },{
                field: "bill_number", flex: 0.11, headerName: t("BillNumber"), headerAlign: "center", align: "center",
                renderCell: (params) => {
                    let BillNumber = "";
                    if (params?.row?.temp_bill_number && params?.row?.bill_number) { BillNumber = params?.row?.bill_number }
                    else if (params?.row?.bill_number) { BillNumber = params?.row?.bill_number }
                    else if (params?.row?.temp_bill_number) { BillNumber = params?.row?.temp_bill_number }
                    const billArr = BillNumber ? BillNumber.split("/") : [];
                    return (
                        <Tooltip placement='top' title={BillNumber} arrow>
                            <Box component={'div'}>{billArr?.length > 0 ? '...' + billArr?.slice(-2)?.join("/") : "-"}</Box>
                        </Tooltip>
                    )
                }
            },
            {
                field: "doctor_name", flex: 0.11, headerName: t("DoctorName"),
                renderCell: (params) => (<Box component={'div'}>{(params?.row?.doctor_name && params?.row?.doctor_id) ? (params?.row?.doctor_name) : (params?.row?.nurse_name) ? (params?.row?.nurse_name) : "-"}</Box>)
            },
            {
                field: "amount_net", flex: 0.11, headerName: t('BillAmount')+` (${CurrencySymbol})`, type: "number",
                renderCell: (params) => (<Box component={'div'}>{AmountFormat(params?.row?.amount_net || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
            }
        ]

        let TokenNumber = {
            field: "temp_invoice_sequence", flex: 0.10, headerName: t("Token Number"), headerAlign: "center", align: "center",
            renderCell: (params) => (
                <Box component={'div'}>
                    {params?.row?.temp_invoice_sequence ? params?.row?.temp_invoice_sequence?.length > 10 ?
                        <Tooltip placement='top' title={params?.row?.temp_invoice_sequence} arrow>
                            <div>{"..." + params?.row?.temp_invoice_sequence?.slice(-10)}</div></Tooltip> :
                        params?.row?.temp_invoice_sequence : "-"}</Box>)
        }

        if (this.state.showTokenPrint) {
            PharmacyColumns?.splice(4, 0, TokenNumber)
        }

        if (userAccess?.editAccess) {
            PharmacyColumns.push(
                {
                    field: "action", flex: 0.11, headerName: t("Action"), headerAlign: "center", align: "center", sortable: false,
                    renderCell: (params) => <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                        <Tooltip title={t("CreateBill")} placement="top" arrow>
                            <Button emed_tid={'sav_crt_btn'} onClick={() => {
                                this.props.history.push({ pathname: "/PharmacyBilling", state: { Patient_ID: params?.row?.patient_id, Bill_ID: params?.row?.id, isFromSavedBill: true, billDetails: params?.row } })
                            }} className='eMed_usrconf_btn'>
                                <img src={ImagePaths.CreateBill.default} alt="create" className='eMed_action_img' />
                            </Button>
                        </Tooltip>
                        <Tooltip title={t("Delete Bill")} placement="top" arrow>
                            <Button emed_tid={'sav_del_btn'} disabled={this.state.removeDisable} onClick={() => { this.setState({ SelectedBill: params?.row }) }} className='eMed_usrconf_btn'>
                                <img src={ImagePaths.Delete.default} alt="create" className='eMed_action_img' />
                            </Button>
                        </Tooltip>
                        {this.state.showTokenPrint ? this.MenuItem(params, true) : false}
                    </Box>
                }
            )
        }
        return (
            <Box component={"div"} className='eMed_Main_container'>
                <Box component={"div"} className='eMed_Top_Container'>
                    <Box component={"div"} className='eMed_Cards_Container'>
                        {AmountsCard(t("Total Patient"), this.state.TotalPatient, true, "white", false, null, 'sav_total_pat')}
                        {AmountsCard(t("Saved Bill Amount"), this.state.PendingAmount, false, "white", false, null, 'sav_bill_amt')}
                    </Box>
                    <Box component={"div"} className='eMed_Action_Container'>
                        <Box component={"div"} className='eMed_Filter_Icon_div'>
                            <Tooltip title="Filter" placement='top' arrow>
                                <Button className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
                                    <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                                </Button>
                            </Tooltip>
                        </Box>
                        {
                            this.state?.isPharmacy ? <Tooltip title="Print" placement='top' arrow>
                                <Button disabled={this.state.disableBtn} onClick={() => { this.PrintReport() }} className='eMed_usrconf_btn' emed_tid='Pharmacy_SavedBill_Print_Btn'>
                                    <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_action_img' />
                                </Button></Tooltip> : null
                        }
                        
                        {/* <Tooltip title="Export" placement='top' arrow>
                            <Button className='eMed_usrconf_btn'>
                                <img src={ImagePaths.LabUploadButton.default} alt="upload" className='eMed_action_img' />
                            </Button></Tooltip> */}
                    </Box>
                </Box>
                <Box component={"div"} className='eMed_Table_Container'>
                    <Stack component={"div"}>
                        <Paper className='eMed_Table_Wrapper' >
                            <DataGrid
                                rows={this.state.BillList}
                                classes={{ main: 'saved_bill' }}
                                columns={this.state?.isPharmacy ? PharmacyColumns : columns}
                                getRowId={(row) => row['sno']}
                                page={this.state.page}
                                pageSize={this.state.pageSize}
                                hideFooterSelectedRowCount
                                localeText={{
                                    toolbarColumns: "",
                                    toolbarDensity: "",
                                    toolbarExport: "",
                                    toolbarFilters: "",
                                    toolbarExportPrint: ""
                                }}
                                headerHeight={40}
                                components={{
                                    Toolbar: this.gridToolBar,
                                    NoRowsOverlay: () => (
                                        <Stack className='eMed_conf_nodata'>
                                            {t("NoRecordsFound")}
                                        </Stack>
                                    )
                                }}
                                rowsPerPageOptions={[10, 20, 30]}
                                disableSelectionOnClick
                                pagination
                                onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                                onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                                loading={this.state.isLoader}
                            />
                        </Paper>
                    </Stack>
                </Box>
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.ClosePopUp.bind(this) }}
                >
                    <BillingsFilter PageName={"SavedBills"} FilterDatas={this.state.FilterDatas} CloseFilter={this.ClosePopUp.bind(this)} FilteredData={this.renderFilter.bind(this)} ClearData={this.clearFilter.bind(this)} Pharmacy={this.state.isPharmacy} callDocApi={true}/>
                </Drawer>
                {
                    this.state.BillPreviewPop ?
                        <PrintPreviewPop
                            URL={Serviceurls.INVOICE_PRINT}
                            Title={t("BillPreview")}
                            BillData={this.state.SelectedBill}
                            ClosePopUp={this.ClosePopUp.bind(this)}
                            ModifyBillButton={CheckAccessFunc("front_office", "Billing Transaction", "Saved Bills", null, "Tab")?.editAccess ? (this.state.SelectedBill.bill_types === "In Patient" || this.state.SelectedBill.bill_types === "Out Patient") : false}
                            cancelBillButton={CheckAccessFunc("front_office", "Billing Transaction", "Saved Bills", null, "Tab")?.editAccess ? (this.state.SelectedBill.bill_types === "In Patient" || this.state.SelectedBill.bill_types === "Out Patient") : false}
                            PrintButton={false}
                            history={this.props.history} /> : null
                }
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {Object.keys(this.state?.SelectedBill).length > 0 ? <DeletePopup
                    DeletTitle={"Are you sure ? Do you want to delete this Saved bill ?"}
                    deleteAlertPopupClose={this.PopUpClose.bind(this)}
                    removeData={this.DeleteSavedBill.bind(this)}
                    testID="Saved_toBill_deleteOk"
                    disable={this.state.removeDisable}
                    BillType="Saved Bill"
                /> : null}
                {this.state.billDeleteClicked ?
                    <DeletePopup
                        DeletTitle={`${t("Are you sure ? Do you want to delete this Saved bill ?")}`}
                        deleteAlertPopupClose={this.popupClose.bind(this)}
                        removeData={this.onDeleteBill}
                        testID="Saved_toBill_deleteOk"
                        disable={this.state.removeDisable}
                        ButtonText="Delete"
                        BillType="Saved Bill"
                    />
                    : null
                }
                {/* <Loader loaderOpen={this.state.isLoader} /> */}
            </Box>
        )
    }
}

export default withTranslation()(SavedBills)


