import { Autocomplete, Box, Button, Drawer, Stack, TextField, Tooltip, Typography } from '@mui/material'
import React, { Component } from 'react'
import '../pharmaReports.css';
import { Colors } from '../../../../Styles/Colors';
import { AmountsCard } from '../../../../Components/CommonCards/CommonCards';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { DataGrid } from '@mui/x-data-grid';
import { CommonGridToolBarWithFilterText } from '../../../../Components/Common Components/CommonComponents';
import { formatDate, splitDate, AmountFormat } from '../../../../Components/CommonFunctions/CommonFunctions';
import { withTranslation } from 'react-i18next';
import CommonDatePicker from '../../../../Components/Common Components/CommonDatePicker';
import { DateTime } from 'luxon';
import { CurrencySymbol } from '../../../../Utility/Constants';


const hsnValue = [
  { label: "HSN Code Rate Wise", value: "CodeRateWise" },
  { label: "HSN Code Wise", value: "CodeWise" }
]
class PurchaseHSNWise extends Component {

  constructor(props) {
    super(props)
    this.state = {
      hsn_Data: [],
      page: 0,
      pageSize: 10,
      total_Amt: 0,
      filterOpen: false,
      tax_Amt: 0,
      gst_Amt: 0,
      isLoader: false,
      dateIndex: 1,
      fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      reportType: { label: "HSN Code Rate Wise", value: "CodeRateWise" },
      "days":30
    }
  }

  componentDidMount() {
    this.getHSNWiseData()
  }

  getHSNWiseData = () => {
    try {
      let value = this.state.reportType?.value === "CodeWise" ? "wo_gst" : 'gst'
      this.LoaderFunction(true);
      RestAPIService.getAll(Serviceurls.PHARMA_HSN_REPORT + `?from_date=${this.state.fromDate}&to_date=${this.state.toDate}&report_type=${value}`)
        .then((response) => {
          if (response.data.status === 'success') {
            this.LoaderFunction(false);
            this.setState({
              hsn_Data: response.data.data,
              tax_Amt: response.data?.final_taxable_value,
              gst_Amt: response.data?.final_gst_value,
              total_Amt: response.data?.final_total_value,
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.LoaderFunction(false);
            this.errorMessage(error.response.data.message)
          } else {
            this.LoaderFunction(false);
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.LoaderFunction(false);
      this.errorMessage(error.message)
    }
  }

  getVendorBillExcel = () => {
    try {
      let value = this.state.reportType?.value === "CodeWise" ? "wo_gst" : 'gst'
      RestAPIService.excelGet(Serviceurls.PHARMA_HSN_REPORT + `?from_date=${this.state.fromDate}&to_date=${this.state.toDate}&report_type=${value}&export=excel`).
        then((response) => {
          if (response.data) {
            var pom = document.createElement('a');
            var csvContent = response.data; //here we load our csv data 

            let filename = response.headers["content-disposition"].split("filename=")[1]
            // var blob = new Blob([csvContent], {type: 'data:application/vnd.ms-excel;base64'});

            var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
            var url = URL.createObjectURL(blob);
            pom.href = url;
            pom.setAttribute('download', filename ? filename : 'registry.xlsx');
            pom.click();
          }
        }).catch((error) => {
          if (error.response?.data?.message) {
            this.errorMessage(error.response?.data?.message);
          } else {
            this.errorMessage(error.message);
          }
        })
    } catch (error) {
      this.errorMessage(error.message);
    }
  }

  getPurchaseHsnWisePrint = () => {
    try {
      this.setState({disableBtn: true})
      let value = this.state.reportType?.value === "CodeWise" ? "wo_gst" : 'gst'
      RestAPIService.getAllPrint(Serviceurls.PHARMA_HSN_REPORT + `?from_date=${this.state.fromDate}&to_date=${this.state.toDate}&report_type=${value}&export=pdf`).
        then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.setState({disableBtn: false})
        }).catch((error) => {
          if(error?.response?.data?.message){
            this.errorMessage(error?.response?.data?.message)
          }else{
            this.errorMessage(error.message)
          }
          this.setState({disableBtn: false})
        })
    } catch (error) {
      this.errorMessage(error.message)
      this.setState({disableBtn: false})
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  ClosePopUp() {
    this.setState({
      filterOpen: false,
    })
  }

  filterDate = (from, to, index) => {
    this.setState({
      fromDate: from,
      toDate: to,
      dateIndex: index
    })
  }

  renderFilterScreen = () => {
    const { t } = this.props;
    let states = this.state;
    return (
      <Box component={"div"} className='eMed_rts_filter_container'>
        <Box component={"div"} className='eMed_filter_Top'>
          <Typography variant='h6' fontWeight={600}>{t('Filter')}</Typography>
          <Button emed_tid='purord_close' onClick={() => { this.setState({ filterOpen: false }) }} className='eMed_usrconf_btn'>
            <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
          </Button>
        </Box>
        <Box className='eMed_rts_middle' height={'35vh'}>
          <Box sx={{ width: "40%", margin: "1vw", marginLeft: "14vw", }}>
            <CommonDatePicker
              FromDate={this.state.fromDate}
              ToDate={this.state.toDate}
              DateIndex={this.state.dateIndex}
              SelectedDates={this.filterDate.bind(this)}
              HideAllMenu={true}
              days={this.state.days}
            />
          </Box>
          <Box className='eMed_rts_dropdown'>
            <Autocomplete
              size='small'
              sx={{ width: "20vw" }}
              options={hsnValue}
              getOptionLabel={(option) => option?.label}
              value={this.state.reportType}
              onChange={(event, newValue) => {
                this.setState({ reportType: newValue })
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('Select HSN Wise')}
                  InputProps={{
                    ...params.InputProps,
                    emed_tid: "HsnWise"
                  }}
                />
              )}
            />
          </Box>
        </Box>
        <Stack spacing={2} direction="row" className='eMed_rts_btn' alignItems="center" justifyContent="center">
          <Button variant='outlined' size="small"
            emed_tid='fil_clear'
            onClick={() => {
              this.setState({
                fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                dateIndex: 1,
                reportType: { label: "HSN Code Rate Wise", value: "CodeRateWise" },
              }, () => this.getHSNWiseData())
            }}
            id="eMed_Config_btn">{t("Clear")}</Button>
          <Button variant='contained' size="small"
            emed_tid='fil_search'
            onClick={() => {
              this.getHSNWiseData()
              this.setState({
                filterOpen: false,
              })
            }}
            id="eMed_Config_btn">{t("Search")}</Button>
        </Stack>
      </Box>
    )
  }

  gridToolBar = () => {
    var { t } = this.props
    let TextArray = [
      { label: "From Date", value: formatDate(this.state.fromDate) },
      { label: "To Date", value: formatDate(this.state.toDate) },
    ]
    return (
      <Box>
        <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
      </Box>

    )
  }

  closeFilter = () => {
    this.setState({ filterOpen: false })
  }

  render() {
    const { t } = this.props
    this.state.hsn_Data.forEach((element, index) => element.sno = index + 1)
    const columns = [
      { field: 'sno', headerName: t('SNo'), sortable: false, flex: 0.07 },
      {
        field: 'hsn_code', headerName: "HSN Code", flex: 0.15, align: 'center', headerAlign: 'center',
        renderCell: (params) => (<Box component={'div'}>
          {params?.row?.hsn_code?.length > 15 ? <Tooltip placement='top' title={params?.row?.hsn_code} arrow><div>{params?.row?.hsn_code?.slice(0, 15) + '...'}</div></Tooltip> :
            params?.row?.hsn_code ? params?.row?.hsn_code : '-'}
        </Box>)
      },
      {
        field: 'gst_percent', headerName: "Tax %", flex: 0.12, type: 'number',
        renderCell: (params) => (<Box component={'div'}>
          {params?.row?.gst_percent ? params?.row?.gst_percent : '-'}
        </Box>)
      },
      {
        field: 'total_qty', headerName: "Total Qty", flex: 0.14, type: "number",
        renderCell: (params) => (<Box>{params.row?.total_qty ? params.row?.total_qty : '0'}</Box>)
      },
      {
        field: 'total_value', headerName: `${"Total Amount"} (${CurrencySymbol})`, flex: 0.15, type: "number",
        renderCell: (params) => (<Box>{params.row?.total_value ? AmountFormat(params.row?.total_value).replace(`${CurrencySymbol}`, "") : '0.00'}</Box>)
      },
      {
        field: 'taxable_value', headerName: `${"Taxable Amount"} (${CurrencySymbol})`, flex: 0.15, type: "number",
        renderCell: (params) => (<Box>{params.row?.taxable_value ? AmountFormat(params.row?.taxable_value).replace(`${CurrencySymbol}`, "") : '0.00'}</Box>)
      },
      {
        field: 'cgst_amount', headerName: `${"CGST Amount"} (${CurrencySymbol})`, flex: 0.14, type: "number",
        renderCell: (params) => (<Box>{(params.row?.cgst_amount) ? AmountFormat(params.row?.cgst_amount).replace(`${CurrencySymbol}`, "") : '0.00'}</Box>)
      },
      {
        field: 'sgst_amount', headerName: `${"SGST Amount"} (${CurrencySymbol})`, flex: 0.14, type: "number",
        renderCell: (params) => (<Box>{(params.row?.sgst_amount) ? AmountFormat(params.row?.sgst_amount).replace(`${CurrencySymbol}`, "") : '0.00'}</Box>)
      },
    ]
    if (this.state.reportType?.value === "CodeWise") {
      columns.splice(2, 1);
    }
    return (
      <Box component={'div'} height={'80vh'} sx={{ backgroundColor: Colors.Background }}>
        <Box component={'div'} className='eMed_Pharma_rts_header'>
          <Box component={'div'} flex={0.8} display={'flex'}>
            {AmountsCard("Taxable Amount", this.state.tax_Amt, false, "white", false, null, 'rpt_consoli_pur_total')}
            {AmountsCard("Total Amount", this.state.total_Amt, false, "white", false, null, 'rpt_consoli_tot_total')}
            {AmountsCard("GST Amount", this.state.gst_Amt, false, "white", false, null, 'rpt_consoli_gst_total')}
          </Box>
          <Box flex={0.2} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} marginRight={'0.5vw'}>
            <Button emed_tid="rpt_consoli_flt" className='eMed_Pharma_rts_btn' onClick={() => this.setState({ filterOpen: true })}>
              <Box component={'img'} src={ImagePaths.Filter.default} alt='filter' height={'2.3vw'} width={'2.3vw'} />
            </Button>
            <Button emed_tid="rpt_consoli_flt" className='eMed_Pharma_rts_btn' disabled={this.state.disableBtn} onClick={() => this.getPurchaseHsnWisePrint()}>
              <Box component={'img'} src={ImagePaths.LabPrintIcon.default} alt='filter' height={'2.3vw'} width={'2.3vw'} />
            </Button>
            {/* <Button emed_tid="rpt_purOver_export" className='eMed_Pharma_rts_btn' onClick={() => this.getVendorBillExcel()}>
              <Box component={'img'} src={ImagePaths.ExportIcon.default} alt='export' height={'2.3vw'} width={'2.3vw'} />
            </Button> */}
          </Box>
        </Box>
        <Box component={'div'} className='eMed_Pharma_rts_tbl_card'>
          <DataGrid
            className='eMed_Pharma_table'
            rows={this.state.hsn_Data}
            columns={columns}
            page={this.state.page}
            pageSize={this.state.pageSize}
            getRowId={(row) => row['sno']}
            localeText={{
              toolbarColumns: "",
              toolbarDensity: "",
              toolbarExport: "",
              toolbarFilters: "",
              toolbarExportPrint: ""
            }}
            components={{
              Toolbar: this.gridToolBar,
              NoRowsOverlay: () => (
                <Stack className='eMed_conf_nodata'>
                  {t("NoRecordsFound")}
                </Stack>
              )
            }}
            onPageChange={(newPage) => { this.setState({ page: newPage }) }}
            onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
            rowsPerPageOptions={[10, 20, 30]}
            headerHeight={40}
            disableSelectionOnClick
            pagination
            loading={this.state.isLoader}
          />
        </Box>

        <Drawer
          anchor={'right'}
          open={this.state.filterOpen}
          ModalProps={{ onBackdropClick: this.closeFilter }}
        >
          {this.renderFilterScreen()}
        </Drawer>

        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
      </Box>
    )
  }
}

export default withTranslation()(PurchaseHSNWise)