import { Box, Button, Drawer, Stack, Tooltip } from '@mui/material'
import React, { Component } from 'react'
import '../pharmaReports.css';
import { Colors } from '../../../../Styles/Colors';
import { AmountsCard } from '../../../../Components/CommonCards/CommonCards';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { DataGrid } from '@mui/x-data-grid';
import { CommonGridToolBarWithFilterText } from '../../../../Components/Common Components/CommonComponents';
import { formatDate, splitDate, AmountFormat } from '../../../../Components/CommonFunctions/CommonFunctions';
import { withTranslation } from 'react-i18next';
import PharmaFilter from '../PharmaFilter';
import { DateTime } from 'luxon';
import { CurrencySymbol } from '../../../../Utility/Constants';

class PurchaseDetailed extends Component {

    constructor(props) {
        super(props)
        this.state = {
            purchaseDetailedData: [],
            page: 0,
            pageSize: 10,
            rowCount: 0,
            filterDatas: {
                'fromDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                'toDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                "dateIndex": 1,
                "drugName": '',
                "vendorName": '',
                "status": [],
                "days":null,
                "billNumber":'',
                "dosageType": [],
                "is_bill_wise": false,
            },
            pageName: 'purchaseDetailed',
            filterOpen: false,
            totalAmount: 0,
            cgst: 0,
            sgst: 0,
            discountAmount: 0,
            netAmount: 0,
            isLoader: false,
        }
    }

    componentDidMount() {
        this.getPurchaseDetailedData()
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    renderFilter = (data) => {
        this.setState({
            filterDatas: data,
            filterOpen: false,
            page:0,
        }, () => { this.getPurchaseDetailedData() })
    }

    ClosePopUp() {
        this.setState({
            filterOpen: false,
        })
    }

    clearFilter = (data) => {
        this.setState({
            filterDatas: data
        })
    }

    gridToolBar = () => {
        var { t } = this.props
        let TextArray = [
            { label: "From Date", value: formatDate(this.state.filterDatas.fromDate) },
            { label: "To Date", value: formatDate(this.state.filterDatas.toDate) },
        ]
        return (
            <Box>
                <CommonGridToolBarWithFilterText FilterTextArray={TextArray} noExport={true} />
            </Box>

        )
    }

    getPurchaseDetailedData = () => {
        try {
            let drugName = this.state.filterDatas.drugName?.replaceAll('&','%26')
            this.LoaderFunction(true);
            let filteredStatus =[]
            this.state.filterDatas.status.forEach(element => filteredStatus.push(element.value))
            let billWise = this.state.filterDatas?.is_bill_wise ? 'grn_wise' : 'bill_wise'
            RestAPIService.getAll(Serviceurls.PHARMA_PURCHASE_DETAILED_REPORT_GET + `?from_date=${this.state.filterDatas.fromDate}&to_date=${this.state.filterDatas.toDate}&page=${this.state.page + 1}&page_size=${this.state.pageSize}&drug_name=${drugName}&vendor_name=${this.state.filterDatas.vendorName}&bill_number=${this.state.filterDatas.billNumber}&status=${filteredStatus}&dosage_type=${this.state.filterDatas.dosageType}&report_type=${billWise}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.LoaderFunction(false);
                        this.setState({
                            purchaseDetailedData: response.data.data?.result,
                            totalAmount: response.data.data?.total_amount,
                            cgst: response.data.data?.total_cgst_amount,
                            sgst: response.data.data?.total_sgst_amount,
                            discountAmount: response.data.data?.total_discount_amount,
                            netAmount: response.data.data?.total_net_total,
                            rowCount: response.data.data?.total_count
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.LoaderFunction(false);
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.LoaderFunction(false);
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getPurchaseDetailedExcel = () => {
        try {
            this.setState({disableBtn: true})
            let filteredStatus =[]
            this.state.filterDatas.status.forEach(element => filteredStatus.push(element.value))
            let drugName = this.state.filterDatas.drugName?.replaceAll('&','%26')
            let billWise = this.state.filterDatas?.is_bill_wise ? 'grn_wise' : 'bill_wise'
            RestAPIService.excelGet(Serviceurls.PHARMA_PURCHASE_DETAILED_REPORT_GET + `?from_date=${this.state.filterDatas.fromDate}&to_date=${this.state.filterDatas.toDate}&page=${this.state.page + 1}&page_size=${this.state.pageSize}&drug_name=${drugName}&vendor_name=${this.state.filterDatas.vendorName}&bill_number=${this.state.filterDatas.billNumber}&status=${filteredStatus}&dosage_type=${this.state.filterDatas.dosageType}&report_type=${billWise}&export=excel`).
                then((response) => {
                    if (response.data) {
                        var pom = document.createElement('a');
                        var csvContent = response.data; //here we load our csv data 

                        let filename = response.headers["content-disposition"].split("filename=")[1]
                        // var blob = new Blob([csvContent], {type: 'data:application/vnd.ms-excel;base64'});

                        var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
                        var url = URL.createObjectURL(blob);
                        pom.href = url;
                        pom.setAttribute('download', filename ? filename : 'registry.xlsx');
                        pom.click();
                        this.setState({disableBtn: false})
                    }
                }).catch((error) => {
                    if (error.response?.data?.message) {
                        this.errorMessage(error.response?.data?.message);
                    } else {
                        this.errorMessage(error.message);
                    }
                    this.setState({disableBtn: false})
                })
        } catch (error) {
            this.errorMessage(error.message);
            this.setState({disableBtn: false})
        }
    }

    purchaseDetailedPrint = () => {
        try {
            let filteredStatus =[]
            this.state.filterDatas.status.forEach(element => filteredStatus.push(element.value))
            let drugName = this.state.filterDatas.drugName?.replaceAll('&','%26')
            let billWise = this.state.filterDatas?.is_bill_wise ? 'grn_wise' : 'bill_wise'
            this.setState({disabled: true})
            RestAPIService.getAll(Serviceurls.PHARMA_PURCHASE_DETAILED_REPORT_GET + `?from_date=${this.state.filterDatas.fromDate}&to_date=${this.state.filterDatas.toDate}&page=${this.state.page + 1}&page_size=${this.state.pageSize}&drug_name=${drugName}&vendor_name=${this.state.filterDatas.vendorName}&bill_number=${this.state.filterDatas.billNumber}&status=${filteredStatus}&dosage_type=${this.state.filterDatas.dosageType}&report_type=${billWise}&export=pdf`).
            then((response) => {
              const file = new Blob(
                [response.data],
                { type: 'application/pdf' });
              const fileURL = URL.createObjectURL(file);
              window.open(fileURL);
              this.setState({disabled: false})
            }).catch((error) => {
              if(error?.response?.data?.message){
                this.errorMessage(error?.response?.data?.message)
              }else{
                this.errorMessage(error.message)
              }
              this.setState({disabled: false})
            })
        } catch (error) {
          this.errorMessage(error.message)
          this.setState({disabled: false})
        }
      }

    render() {
        const { t } = this.props
        this.state.purchaseDetailedData.forEach((element, index) => element.sno = index + 1)
        const columns = [
            {
                field: 'invoice_number', headerName: "Bill No", flex: 0.056,
                renderCell: (params) => (<Box component={'div'}>
                    {params?.row?.invoice_number?.length > 6 ? <Tooltip placement='top' title={params?.row?.invoice_number} arrow><div>{params?.row?.invoice_number?.slice(0, 6) + '...'}</div></Tooltip> :
                        params?.row?.invoice_number ? params?.row?.invoice_number : '-'}
                </Box>)
            },
            {
                field: 'bill_date', headerName: "Bill Date", flex: 0.055, align: 'center', headerAlign: 'center',
                renderCell: (params) => {
                    const billDate = formatDate(params?.row?.bill_date);
                    return (<Box component={'div'}>
                    {params?.row?.bill_date?.length > 8 ? <Tooltip placement='top' title={billDate} arrow><div>{billDate?.slice(0, 8) + '...'}</div></Tooltip> 
                    :params?.row?.bill_date ? billDate : '-'}
                </Box>)}
            },
            {
                field: 'grn_number', headerName: "GRN No", flex: 0.055,
                renderCell: ({ row }) => (<Box component={'div'}>
                    {row?.grn_number?.length > 8 ? <Tooltip placement='top' title={row?.grn_number} arrow>
                        <div>{row?.grn_number?.slice(0, 6) + '...'}</div>
                    </Tooltip> :
                        row?.grn_number ? row?.grn_number : '-'}
                </Box>)
            },
            {
                field: 'grn_date', headerName: "GRN Date", flex: 0.055, align: 'center', headerAlign: 'center',
                renderCell: (params) => {
                    const grnDate = splitDate(params?.row?.grn_date);
                    return(<Box component={'div'}>
                    {grnDate?.length > 8 ? <Tooltip placement='top' title={grnDate} arrow><div>{grnDate?.slice(0, 8) + '...'}</div></Tooltip> :
                        params?.row?.grn_date ? grnDate : '-'}
                </Box>)}
            },
            {
                field: 'vendor_name', headerName: "Vendor Name", flex: 0.055,
                renderCell: (params) => (<Box component={'div'}>
                    {params?.row?.vendor_name?.length > 7 ? <Tooltip placement='top' title={params?.row?.vendor_name} arrow><div>{params?.row?.vendor_name?.slice(0, 7) + '...'}</div></Tooltip> :
                        params?.row?.vendor_name ? params?.row?.vendor_name : '-'}
                </Box>)
            },
            {
                field: 'batch_no', headerName: "Batch No", flex: 0.055,
                renderCell: (params) => (<Box component={'div'}>
                    {params?.row?.batch_no?.length > 6 ? <Tooltip placement='top' title={params?.row?.batch_no} arrow><div>{params?.row?.batch_no?.slice(0, 6) + '...'}</div></Tooltip> :
                        params?.row?.batch_no ? params?.row?.batch_no : '-'}
                </Box>)
            }, {
                field: 'brand_name', headerName: "Brand Name", flex: 0.055,
                renderCell: (params) => (<Box component={'div'}>
                    {params?.row?.brand_name?.length > 7 ? <Tooltip placement='top' title={params?.row?.brand_name} arrow><div>{params?.row?.brand_name?.slice(0, 6) + '...'}</div></Tooltip> :
                        params?.row?.brand_name ? params?.row?.brand_name : '-'}
                </Box>)
            }, {
                field: 'cgst_percentage', headerName: `${"GST"} (%)`, flex: 0.055, type: "number",
                renderCell: (parmas) => (<Box>{(parmas.row?.cgst_percentage && parmas.row?.sgst_percentage) ? (parmas.row?.cgst_percentage + parmas.row?.sgst_percentage) : '0'}</Box>)
            }, {
                field: 'sgst_amount', headerName: `${"GST"} (${CurrencySymbol})`, flex: 0.055, type: "number",
                renderCell: (parmas) => (<Box>{(parmas.row?.cgst_amount && parmas.row?.sgst_amount) ? AmountFormat(+parmas.row?.cgst_amount + +parmas.row?.sgst_amount)?.replace(`${CurrencySymbol}`, "") : '0.00'}</Box>)
            },
            {
                field: 'dosage_type', headerName: `${"Type/Strength"}`, flex: 0.055,
                renderCell: (parmas) => (<Box>{(parmas.row?.dosage_type + parmas.row?.dosage_strength)?.length > 7 ?
                    <Tooltip placement='top' title={`${parmas.row?.dosage_type} / ${parmas.row?.dosage_strength}`}><div>{(`${parmas.row?.dosage_type} / ${parmas.row?.dosage_strength}`).slice(0, 7) + "..."}</div></Tooltip>
                    : (parmas.row?.dosage_type && parmas.row?.dosage_strength) ? `${parmas.row?.dosage_type ? parmas.row?.dosage_type : '-'} / ${parmas.row?.dosage_strength ? parmas.row?.dosage_strength : '-'}` : '-'}</Box>)
            },
            {
                field: 'stock_in_quantity', headerName: `${"Quantity"}`, flex: 0.055, type: "number",
                renderCell: (parmas) => (<Box>{parmas.row?.stock_in_quantity ? parmas.row?.stock_in_quantity : '0'}</Box>)
            },
            {
                field: 'free_quantity', headerName: `${"Free Quantity"}`, flex: 0.055, type: "number",
                renderCell: (parmas) => (<Box>{parmas.row?.free_quantity ? parmas.row?.free_quantity : '0'}</Box>)
            },
            {
                field: 'amount', headerName: `${"Taxable Amount"} (${CurrencySymbol})`, flex: 0.055, type: "number",
                renderCell: (parmas) => (<Box>{parmas.row?.amount ? AmountFormat(parmas.row?.amount)?.replace(`${CurrencySymbol}`, "") : '0.00'}</Box>)
            },
            {
                field: 'total_buying_price', headerName: `${'Buying Price'} (${CurrencySymbol})`, flex: 0.055, type: "number",
                renderCell: (parmas) => (<Box>{parmas.row?.total_buying_price ? AmountFormat(parmas.row?.total_buying_price)?.replace(`${CurrencySymbol}`, "") : '0.00'}</Box>)
            },
            {
                field: 'price_per_quantity', headerName: `${'Price Unit'}`, flex: 0.055, type: "number",
                renderCell: (parmas) => (<Box>{parmas.row?.price_per_quantity ? parmas.row?.price_per_quantity : '0'}</Box>)
            },
            {
                field: 'mrp_per_quantity', headerName: `${'MRP/QTY'} (${CurrencySymbol})`, flex: 0.055, type: "number",
                renderCell: (parmas) => (<Box>{parmas.row?.mrp_per_quantity ? AmountFormat(parmas.row?.mrp_per_quantity)?.replace(`${CurrencySymbol}`, "") : '0.00'}</Box>)
            },
            {
                field: 'discount_amount', headerName: `${"Discount Amt"} (${CurrencySymbol})`, flex: 0.055, type: "number",
                renderCell: ({ row }) => {
                    const discountAmount = AmountFormat(row?.discount_amount || 0)?.replace(`${CurrencySymbol}`, "");
                    return (<Box component={'div'}>
                        {discountAmount?.length > 8 ? <Tooltip placement='top' title={discountAmount} arrow>
                            <div>{discountAmount?.slice(0, 6) + '...'}</div>
                        </Tooltip> :
                            discountAmount}
                    </Box>)
                }
            },,
            {
                field: 'epr_value', headerName: `${'EPR Value'} (${CurrencySymbol})`, flex: 0.055, type: "number",
                renderCell: ({row}) => (<Box>{AmountFormat(row?.epr_value || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
            },
        ]
        return (
            <Box component={'div'} height={'80vh'} sx={{ backgroundColor: Colors.Background }}>
                <Box component={'div'} className='eMed_Pharma_rts_header'>
                    <Box component={'div'} flex={0.8} display={'flex'}>
                        {AmountsCard("Taxable Amount", this.state.totalAmount, false, "white", false, null, 'rpt_purDlt_total_amt')}
                        {AmountsCard("CGST", this.state.cgst, false, "white", false, null, 'rpt_purDlt_cgst')}
                        {AmountsCard("SGST", this.state.sgst, false, "white", false, null, 'rpt_purDlt_sgst')}
                        {AmountsCard("Discount Amount", this.state.discountAmount, false, "white", false, null, 'rpt_purDlt_dis_amt')}
                        {AmountsCard("Net Amount", this.state.netAmount, false, "white", false, null, 'rpt_purDlt_net_amt')}
                    </Box>
                    <Box flex={0.2} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} marginRight={'0.5vw'}>
                        <Button emed_tid="rpt_purDlt_flt" className='eMed_Pharma_rts_btn' onClick={() => this.setState({ filterOpen: true })}>
                            <Box component={'img'} src={ImagePaths.Filter.default} alt='filter' height={'2.3vw'} width={'2.3vw'} />
                        </Button>
                        <Button className='eMed_Pharma_rts_btn' disabled={this.state.disabled}>
                            <Box component={'img'} src={ImagePaths.LabPrintIcon.default} alt='print' height={'2.3vw'} width={'2.3vw'}  onClick={() => this.purchaseDetailedPrint()}/>
                        </Button>
                        <Button emed_tid="rpt_purDlt_export" className='eMed_Pharma_rts_btn' disabled={this.state.disableBtn} onClick={() => this.getPurchaseDetailedExcel()}>
                            <Box component={'img'} src={ImagePaths.ExportIcon.default} alt='export' height={'2.3vw'} width={'2.3vw'} />
                        </Button>
                    </Box>
                </Box>
                <Box component={'div'} className='eMed_Pharma_rts_tbl_card'>
                    <DataGrid
                        className='eMed_Pharma_table'
                        rows={this.state.purchaseDetailedData}
                        columns={columns}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        getRowId={(row) => row['sno']}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        initialState={{
                            columns:{
                                columnVisibilityModel:{
                                    grn_number: false,
                                    epr_value: false,
                                    discount_amount: false
                                }
                            }
                        }}
                        rowCount={this.state.rowCount}
                        onPageChange={(newPage) => { this.setState({ page: newPage }, () => this.getPurchaseDetailedData()) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize }, () => this.getPurchaseDetailedData())}
                        rowsPerPageOptions={[10, 20, 30]}
                        headerHeight={40}
                        disableSelectionOnClick
                        pagination
                        paginationMode='server'
                        loading={this.state.isLoader}
                    />
                </Box>

                <Drawer
                    anchor={'right'}
                    open={this.state.filterOpen}
                    ModalProps={{ onBackdropClick: this.ClosePopUp.bind(this) }}
                >
                    <PharmaFilter pageName={this.state.pageName} filterDatas={this.state.filterDatas} closeFilter={this.ClosePopUp.bind(this)} filteredData={this.renderFilter.bind(this)} ClearData={this.clearFilter.bind(this)} calDosType={true}/>
                </Drawer>

                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}

export default withTranslation()(PurchaseDetailed)