import {
  Autocomplete, Box, Button, Checkbox, Collapse, FormControlLabel,
  IconButton, InputAdornment, Radio, RadioGroup, Table, TableBody, TableCell,
  TableHead, TablePagination, TableRow, TextField, Tooltip, Typography
} from '@mui/material'
import React, { Component } from 'react'
import './RadSetting.css'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg'
import { Colors } from '../../../Styles/Colors'
import { ImagePaths } from '../../../Utility/ImagePaths'
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation'
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { DeletePopup } from '../../../Components/Common Components/CommonComponents'
import SearchIcon from '@mui/icons-material/Search';
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../Utility/API/Serviceurls'
import { localGetItem } from '../../../Utility/Services/CacheProviderService'
import { AmountFormat, CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions'
import { OP_IP_CODE_LENGTH } from '../../../Utility/Constants'

let isUser = null;

export default class RadServiceConfig extends Component {
  constructor(props) {
    super(props)
    this.state = {
      serviceData: [],
      serviceType: '',
      serviceName: '',
      serviceAmount: '',
      serviceList: [],
      isEdit: false,
      selectedIndex: null,
      selectedId: null,
      selectedLftIndex: null,
      selectedRowIndex: null,
      isLftEdit: false,
      dupServiceData: '[]',
      rateEdit: false,
      unitEdit: false,
      discountEdit: false,

      loading: false,
      removeData: false,
      tblPage: 0,
      tblPageSize: 10,
      searchValue: '',
      removeData: false,
      deletedServiceType: '',

      ServiceWiseType: "DoctorWise",
      PatientFee: { total: "", doctor_fee: "", hospital_fee: "" },
      HolidayFee: { total: "", doctor_fee: "", hospital_fee: "" },
      EmergencyFee: { total: "", doctor_fee: "", hospital_fee: "" },
      holidayAmount: "",
      emergencyAmount: "",
      DoctorName: "",
      DoctorList: [],
      DeletableServicesNameIds: [],
      DeletabeleServices: [],
      serviceCode: '',
      serviceCodeEnable: false,
    }
  }

  componentDidMount() {
    let details = JSON.parse(localGetItem('loggedInUserInfo'))
    let serviceCode = JSON.parse(localGetItem("radiology_service_code"))
    this.setState({
      clinicId: details?.clinic_id,
      serviceCodeEnable: serviceCode ? serviceCode : false
    }, () => {
      this.getServiceConfigData()
      this.getDoctorList()
    })
  }

  getServiceConfigData = () => {
    try {
      RestAPIService.getAll(Serviceurls.RAD_SERVICE_CONFIG)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              serviceData: response.data.data,
              dupServiceData: JSON.stringify(response.data.data)
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }
  getDoctorList = () => {
    try {
      RestAPIService.getAll(Serviceurls.FO_CONFIG_OPDOCTORNAME + "?clinic_id=" + this.state.clinicId)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              DoctorList: response.data.data.filter((item) => (item.specialization_active === true))
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  postServiceData = () => {
    try {
      let data = {
        deleted_ids: this.state.DeletableServicesNameIds ? this.state.DeletableServicesNameIds : [],
        service_data: this.state.serviceList
      }
      if (this.state.isLftEdit) {
        data['service_id'] = this.state.selectedId
      }
      this.setState({ postLoad: true })
      RestAPIService.create(data, Serviceurls.RAD_SERVICE_CONFIG)
        .then((response) => {
          if (response.data.status === 'success') {
            this.successMessage(response.data.message)
            this.onClearPostDatas()
            this.getServiceConfigData()
            this.setState({ postLoad: false })
          }
        }).catch((error) => {
          this.setState({ postLoad: false })
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ postLoad: false })
      this.errorMessage(error.message)
    }
  }

  deleteServiceConfigData = () => {
    try {
      let data = {}
      this.setState({ removeData: true })
      RestAPIService.delete(Serviceurls.RAD_SERVICE_CONFIG + `?service_id=${this.state.DeletabeleServices}`, data)
        .then((response) => {
          if (response.data.status === 'success') {
            this.successMessage(response.data.message)
            this.getServiceConfigData()
            this.setState({
              selectedId: null,
              deletePopup: false,
              removeData: false,
              DeletabeleServices: []
            })
          }
        }).catch((error) => {
          this.setState({ removeData: false })
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ removeData: false })
      this.errorMessage(error.message)
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  onChangeHandler = (e, names) => {
    try {
      let value = e.target.value
      let alpha = CommonValidation.alphabetOnly(value)
      let numberOnly = CommonValidation.NumberWithDot(value)
      switch (names) {
        case 'serviceName':
          if (alpha || value === '') {
            this.setState({ serviceName: value })
          }
          break;
        case 'serviceType':
          if (alpha || value === '') {
            this.setState({ serviceType: value })
          }
          break;
        case 'serviceAmount':
          if ((numberOnly && +value <= 1000000) || value === '') {
            this.setState({ serviceAmount: value })
          }
          break;
        case 'holidayAmount':
          if ((numberOnly && +value <= 1000000) || value === '') {
            this.setState({ holidayAmount: value })
          }
          break;
        case 'emergencyAmount':
          if ((numberOnly && +value <= 1000000) || value === '') {
            this.setState({ emergencyAmount: value })
          }
          break;
        case 'serviceCode':
          if (value?.length <= OP_IP_CODE_LENGTH || value === '') {
            this.setState({ serviceCode: value })
          }
          break;
        default:
          this.setState({ [names]: value })
          break;
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  inputBox = (names, values, label, width, mr, fullWidth, enable) => {
    try {
      return (
        <TextField
          autoComplete='off'
          sx={{ width: width, mr: mr }}
          fullWidth={fullWidth}
          label={label}
          size='small'
          name={names}
          value={values ? values : ""}
          onChange={(e) => this.onChangeHandler(e, names)}
          InputProps={enable ? {
            endAdornment: <Box component={'img'} src={ImagePaths.RupeeOff.default} height={'1vw'} width={'1vw'} />
          } : null}
          inputProps={{ emed_tid: names }}
        />
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  checkValidation = (isDoctorWise) => {
    try {
      let message = null;
      if (isDoctorWise) {
        if (this.state.DoctorName === '' && this.state.serviceType === '') {
          message = 'Enter all mandatory fields'
        } else if (this.state.DoctorName === '') {
          message = 'Select the doctor'
        } else if (this.state.serviceType === '') {
          message = 'Enter Service Type'
        }
        else if (!this.state.PatientFee?.total) {
          message = 'Enter Patient fee'
        }
        else if (!this.state.HolidayFee?.total) {
          message = 'Enter Holiday fee'
        }
        else if (!this.state.EmergencyFee?.total) {
          message = 'Enter emergency fee'
        }
        else {
          this.state.serviceList?.some((element, index) => {
            if (this.state.selectedIndex !== index) {
              if (CommonValidation.removeSpace(element?.service_name) === CommonValidation.removeSpace(this.state.DoctorName)) {
                message = 'Service Name already exists'
                return true
              } else if (this.state.serviceCode !== "" && CommonValidation.removeSpace(element?.rad_service_code) === CommonValidation.removeSpace(this.state.serviceCode)) {
                message = 'Service Code already exists'
                return true
              }
            }
            return false
          })
        }
        return message;
      } else {
        if (this.state.serviceName === '' && this.state.serviceType === '') {
          message = 'Enter all mandatory fields'
        } else if (this.state.serviceType === '') {
          message = 'Enter Service Type'
        } else if (this.state.serviceName === '') {
          message = 'Enter Service Name'
        } else if (this.state.serviceAmount === '') {
          message = 'Enter Service Amount'
        } else {
          this.state.serviceList?.some((element, index) => {
            if (this.state.selectedIndex !== index) {
              if (CommonValidation.removeSpace(element?.service_name) === CommonValidation.removeSpace(this.state.serviceName)) {
                message = 'Service Name already exists'
                return true;
              } else if (this.state.serviceCode !== "" && CommonValidation.removeSpace(element?.rad_service_code) === CommonValidation.removeSpace(this.state.serviceCode)) {
                message = 'Service Code already exists'
                return true;
              }
            }
            return false
          })
        }
        return message;
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  addServiceList = (isDoctorWise = false) => {
    try {
      let serviceList = {}
      if (isDoctorWise) {
        serviceList = {
          id: this.state.IndividualServiceId ? this.state.IndividualServiceId : null,
          config_type: "Doctor Wise",
          is_edit_discount: this.state.discountEdit ? this.state.discountEdit : false,
          is_edit_unit: this.state.unitEdit ? this.state.unitEdit : false,
          is_edit_amount: this.state.rateEdit ? this.state.rateEdit : false,
          doctor_id: this.state.DoctorName ? this.state.DoctorList.find((item) => (item?.doctor_name === this.state.DoctorName))?.doctor_id : null,
          service_type: CommonValidation.removeSpace(this.state.serviceType) ? CommonValidation.removeSpace(this.state.serviceType) : '',
          rad_service_code: CommonValidation.removeSpace(this.state.serviceCode) ? CommonValidation.removeSpace(this.state.serviceCode) : '',
          service_name: this.state.DoctorName ? this.state.DoctorName : '',
          service_data: {
            normal_charges: {
              total: this.state.PatientFee?.total ? +this.state.PatientFee?.total : 0,
              doctor_fee: this.state.PatientFee?.doctor_fee ? +this.state.PatientFee?.doctor_fee : 0,
              hospital_fee: this.state.PatientFee?.hospital_fee ? +this.state.PatientFee?.hospital_fee : 0
            },
            holiday_charges: {
              total: this.state.HolidayFee?.total ? +this.state.HolidayFee?.total : 0,
              doctor_fee: this.state.HolidayFee?.doctor_fee ? +this.state.HolidayFee?.doctor_fee : 0,
              hospital_fee: this.state.HolidayFee?.hospital_fee ? +this.state.HolidayFee?.hospital_fee : 0
            },
            emergency_charges: {
              total: this.state.EmergencyFee?.total ? +this.state.EmergencyFee?.total : 0,
              doctor_fee: this.state.EmergencyFee?.doctor_fee ? +this.state.EmergencyFee?.doctor_fee : 0,
              hospital_fee: this.state.EmergencyFee?.hospital_fee ? +this.state.EmergencyFee?.hospital_fee : 0
            },
          },
        }
      } else {
        serviceList = {
          id: this.state.IndividualServiceId ? this.state.IndividualServiceId : null,
          config_type: "Service Wise",
          is_edit_discount: this.state.discountEdit ? this.state.discountEdit : false,
          is_edit_unit: this.state.unitEdit ? this.state.unitEdit : false,
          is_edit_amount: this.state.rateEdit ? this.state.rateEdit : false,
          doctor_id: null,
          service_type: CommonValidation.removeSpace(this.state.serviceType) ? CommonValidation.removeSpace(this.state.serviceType) : '',
          service_name: CommonValidation.removeSpace(this.state.serviceName) ? CommonValidation.removeSpace(this.state.serviceName) : '',
          rad_service_code: CommonValidation.removeSpace(this.state.serviceCode) ? CommonValidation.removeSpace(this.state.serviceCode) : '',
          service_data: {
            normal_charges: { total: this.state.serviceAmount ? +this.state.serviceAmount : 0 },
            holiday_charges: { total: this.state.holidayAmount ? +this.state.holidayAmount : 0 },
            emergency_charges: { total: this.state.emergencyAmount ? +this.state.emergencyAmount : 0 },
          },
        }
      }

      let check = this.checkValidation(isDoctorWise)
      if (check === null) {
        let data = CommonValidation.updateOrInsertList(this.state.serviceList, serviceList, this.state.selectedIndex)
        this.setState({ serviceList: data }, () => this.onClearHandler())
      } else {
        this.errorMessage(check)
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onEditRgtHandler = (item, index) => {
    try {
      if (item?.config_type === "Service Wise") {
        this.setState({
          ServiceWiseType: "ServiceWise",
          isEdit: true,
          serviceName: item?.service_name,
          serviceCode: item?.rad_service_code ? item?.rad_service_code : '',
          serviceType: item?.service_type,
          serviceAmount: item?.service_data?.normal_charges?.total ? item?.service_data?.normal_charges?.total : 0,
          holidayAmount: item?.service_data?.holiday_charges?.total ? item?.service_data?.holiday_charges?.total : 0,
          emergencyAmount: item?.service_data?.emergency_charges?.total ? item?.service_data?.emergency_charges?.total : 0,
          selectedIndex: index,
          unitEdit: item?.is_edit_unit,
          rateEdit: item?.is_edit_amount,
          discountEdit: item?.is_edit_discount,
          IndividualServiceId: item?.id ? item?.id : null
        })
      } else {
        this.setState({
          ServiceWiseType: "DoctorWise",
          isEdit: true,
          DoctorName: item?.service_name ? item?.service_name : "",
          serviceType: item?.service_type ? item?.service_type : "",
          serviceCode: item?.rad_service_code ? item?.rad_service_code : '',
          PatientFee: item?.service_data?.normal_charges,
          HolidayFee: item?.service_data?.holiday_charges,
          EmergencyFee: item?.service_data?.emergency_charges,
          selectedIndex: index,
          unitEdit: item?.is_edit_unit,
          rateEdit: item?.is_edit_amount,
          discountEdit: item?.is_edit_discount,
          IndividualServiceId: item?.id ? item?.id : null
        })
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onClearHandler = (ClearServiceType = false) => {
    try {
      this.setState({
        serviceName: '',
        serviceAmount: '',
        serviceType: ClearServiceType ? "" : this.state.serviceType,
        DoctorName: '',
        isEdit: false,
        selectedIndex: null,
        unitEdit: false,
        rateEdit: false,
        discountEdit: false,
        PatientFee: { total: "", doctor_fee: "", hospital_fee: "" },
        HolidayFee: { total: "", doctor_fee: "", hospital_fee: "" },
        EmergencyFee: { total: "", doctor_fee: "", hospital_fee: "" },
        holidayAmount: "",
        emergencyAmount: "",
        IndividualServiceId: null,
        serviceCode: '',
        serviceList: ClearServiceType ? [] : this.state.serviceList,
        isLftEdit : false
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderFeesSplit = (label, StateValue, StateKey, width) => {
    return (
      <Box sx={{ width: width }}>
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "1vw", paddingRight: '1vw' }}>
          <Typography sx={{ fontSize: "0.8vw" }}>{label}</Typography>
          <Typography sx={{ fontSize: "0.8vw", fontWeight: 600 }}>{AmountFormat(StateValue?.total || 0)}</Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "0.5vw" }}>
          <TextField
            autoComplete='off'
            sx={{ mr: "0.5vw" }}
            label={"Doctor Fee"}
            size='small'
            value={StateValue?.doctor_fee ? StateValue.doctor_fee : ""}
            onChange={(e) => {
              let value = e.target.value
              let states = this.state
              if ((CommonValidation.numberOnly(value) && +value <= 1000000) || value === "") {
                states[StateKey].doctor_fee = value
                states[StateKey].total = +value + (states[StateKey].hospital_fee ? +states[StateKey].hospital_fee : 0)
                this.setState({ states })
              }
            }}
            // InputProps={{
            //   endAdornment: <Box component={'img'} src={ImagePaths.RupeeOff.default} height={'1vw'} width={'1vw'} />
            // }}
            inputProps={{ emed_tid: `${StateKey}_doctor_fee` }}
          />
          <TextField
            autoComplete='off'
            // sx={{ width: "13vw" }}
            label={"Hospital Fee"}
            size='small'
            value={StateValue?.hospital_fee ? StateValue.hospital_fee : ""}
            onChange={(e) => {
              let value = e.target.value
              let states = this.state
              if ((CommonValidation.numberOnly(value) && +value <= 1000000) || value === "") {
                states[StateKey].hospital_fee = value
                states[StateKey].total = +value + (states[StateKey].doctor_fee ? +states[StateKey].doctor_fee : 0)
                this.setState({ states })
              }
            }}
            // InputProps={{
            //   endAdornment: <Box component={'img'} src={ImagePaths.RupeeOff.default} height={'1vw'} width={'0.5vw'} />
            // }}
            inputProps={{ emed_tid: `${StateKey}_hospital_fee` }}
          />

        </Box>
      </Box>
    )
  }

  renderRgtScreen = () => {
    try {
      return (
        <Box component={'div'}>
          <Box component={'div'} sx={{ backgroundColor: Colors.Background }} className='rad_rgt_header'>
            <Typography fontWeight={600}>Service Configuration</Typography>
            <RadioGroup
              value={this.state.ServiceWiseType}
              sx={{ display: 'flex', flexDirection: 'row', marginLeft: '1vw' }}
              onChange={(e) => {
                this.setState({ ServiceWiseType: e.target.value }, () => { this.onClearHandler() })
              }}
            >
              <FormControlLabel value={"DoctorWise"} control={<Radio size='small' />} label="Doctor Wise" />
              <FormControlLabel value={"ServiceWise"} control={<Radio size='small' />} label="Service Wise" />
            </RadioGroup>
          </Box>
          <Box component={'div'} className='rad_rgt_content'>
            {
              this.state.ServiceWiseType === "ServiceWise" ?
                <Box>
                  <Box display={'flex'} flexDirection={this.state.serviceCodeEnable ? "column" : 'row'} justifyContent={!this.state.serviceCodeEnable && "space-between"} gap={"0.5vw"}>
                    <Box component={'div'} mt={'0.5vw'} sx={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", marginTop: "0.5vw" }}>
                      {this.inputBox('serviceType', this.state.serviceType, 'Service Type *', '14vw', '0.5vw', true)}
                    </Box>
                    <Box component={'div'} mt={'0.5vw'} sx={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", marginTop: "0.5vw" }}>
                      {this.inputBox('serviceName', this.state.serviceName, 'Service Name *', '14vw', '0.5vw', false, false)}
                      {this.state.serviceCodeEnable && this.inputBox('serviceCode', this.state.serviceCode, 'Service Code', '14vw', '0.5vw', false, false)}
                    </Box>
                  </Box>
                  <Box component={'div'} mt={'0.5vw'}>
                    <Typography fontSize={'0.9vw'} fontWeight={600}>Service Amount Configuration</Typography>
                  </Box>
                  <Box component={'div'} mt={'0.5vw'}>

                    {this.inputBox('serviceAmount', this.state.serviceAmount, 'Service Amount *', '14vw', '0.5vw', false, true)}
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", marginTop: "1vw" }}>
                      {this.inputBox('holidayAmount', this.state.holidayAmount, 'Holiday Amount *', '14vw', '0.5vw', false, true)}
                      {this.inputBox('emergencyAmount', this.state.emergencyAmount, 'Emergency Amount *', '14vw', '0.5vw', false, true)}

                    </Box>
                    {/* <Button variant='contained' sx={{ textTransform: 'capitalize' }} emed_tid='rad_srv_rgt_add'
                      onClick={() => this.addServiceList()}
                      disabled={isUser?.is_user && CheckAccessFunc('Radiology', "Settings", 'Service Name & Type Configuration', null, "Tab")?.editAccess}
                    >Add</Button> */}
                  </Box>
                </Box> :
                <Box >
                  <Box display={'flex'} flexDirection={this.state.serviceCodeEnable ? "column" : 'row'} justifyContent={!this.state.serviceCodeEnable && "space-between"} gap={"0.5vw"}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", marginTop: "0.5vw" }}>
                      {this.inputBox('serviceType', this.state.serviceType, 'Service Type *', '14vw', '0.5vw', false, false)}
                    </Box>
                    <Box component={'div'} mt={'0.5vw'} sx={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", marginTop: "0.5vw" }}>
                      <Autocomplete
                        disableClearable
                        size='small'
                        value={this.state.DoctorName}
                        options={this.state.DoctorList}
                        getOptionLabel={(option) => typeof (option) === 'string' ? option : (option?.doctor_name)}
                        onChange={(event, newValue) => {
                          this.setState({
                            DoctorName: newValue?.doctor_name,
                          })
                        }}
                        renderInput={(params) => (
                          <TextField {...params} required label={'Select Doctor'} sx={{ width: "14vw" }} />
                        )}
                      />
                      {this.state.serviceCodeEnable && this.inputBox('serviceCode', this.state.serviceCode, 'Service Code', '14vw', '0.5vw', false, false)}
                    </Box>
                  </Box>
                  <Box component={'div'} mt={'0.5vw'}>
                    <Typography fontSize={'0.9vw'} fontWeight={600}>Service Amount Configuration</Typography>
                  </Box>
                  {this.renderFeesSplit('Patient Fee *', this.state.PatientFee, 'PatientFee', "31vw")}
                  <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: "space-between" }}>
                    {this.renderFeesSplit('Holiday Fee *', this.state.HolidayFee, 'HolidayFee', "15vw")}
                    {this.renderFeesSplit('Emergency Fee *', this.state.EmergencyFee, 'EmergencyFee', "15vw")}
                  </Box>
                </Box>
            }
            <Box component={'div'} display={'flex'} justifyContent={"center"} marginTop={"0.5vw"}>
              <FormControlLabel control={<Checkbox size='small' checked={this.state.unitEdit} onChange={(e) => this.setState({ unitEdit: e.target.checked })} />} label="Edit Unit" />
              <FormControlLabel control={<Checkbox size='small' checked={this.state.rateEdit} onChange={(e) => this.setState({ rateEdit: e.target.checked })} />} label="Edit Rate" />
              <FormControlLabel control={<Checkbox size='small' checked={this.state.discountEdit} onChange={(e) => this.setState({ discountEdit: e.target.checked })} />} label="Edit Discount" />
              <Button variant='contained' size='small' sx={{ textTransform: 'capitalize' }} emed_tid='rad_srv_rgt_add'
                onClick={() => this.addServiceList(this.state.ServiceWiseType === "DoctorWise")}
                disabled={isUser?.is_user && CheckAccessFunc('Radiology', "Settings", 'Service Name & Type Configuration', null, "Tab")?.editAccess}
              >Add</Button>
            </Box>
            <Box component={'div'} mt={'0.5vw'} border={'1px solid lightgray'} maxHeight={this.state.ServiceWiseType === "ServiceWise" ? (this.state.serviceCodeEnable ?  "23vh" : '32vh') : this.state.serviceCodeEnable ? "14.5vh" : '24vh'} overflow={'auto'}>
              <Table stickyHeader size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell className='rad_rgt_tbl_head'>
                      <Typography fontWeight={'600'} fontSize={'0.9vw'}>Service Name</Typography>
                    </TableCell>
                    <TableCell className='rad_rgt_tbl_head'>
                      <Typography fontWeight={'600'} fontSize={'0.9vw'}>Type</Typography>
                    </TableCell>
                    <TableCell className='rad_rgt_tbl_head' align='center'>
                      <Typography fontWeight={'600'} fontSize={'0.9vw'}>Action</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.serviceList?.length > 0 ? this.state.serviceList?.map((item, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>
                          {item?.service_name ? item?.service_name?.length > 15 ?
                            <Tooltip placement='top' title={item?.service_name} arrow><Box fontSize={'0.9vw'}>{item?.service_name?.slice(0, 15) + '...'}</Box></Tooltip> :
                            <Typography fontSize={'0.9vw'}>{item?.service_name}</Typography> : '-'}
                        </TableCell>
                        <TableCell>
                          {item?.config_type ? item?.config_type?.length > 14 ?
                            <Tooltip placement='top' title={item?.config_type} arrow><Box fontSize={'0.9vw'}>{item?.config_type?.slice(0, 14) + '...'}</Box></Tooltip> :
                            <Typography fontSize={'0.9vw'}>{item?.config_type}</Typography> : '-'}
                        </TableCell>
                        <TableCell>
                          <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                            <Button className='Common_Btn_Min_Width' sx={{ height: '1.5vw' }} emed_tid={'rad_srv_rgt_edt'}
                              disabled={isUser?.is_user && CheckAccessFunc('Radiology', "Settings", 'Service Name & Type Configuration', null, "Tab")?.editAccess}
                              onClick={() => this.onEditRgtHandler(item, index)}>
                              <Box component={'img'} src={ImagePaths.Edit.default} height={'1.5vw'} width={'1.5vw'} />
                            </Button>
                            <Button className='Common_Btn_Min_Width' sx={{ height: '1.5vw' }} emed_tid='rad_srv_rgt_dlt'
                              disabled={isUser?.is_user && CheckAccessFunc('Radiology', "Settings", 'Service Name & Type Configuration', null, "Tab")?.editAccess}
                              onClick={() => {
                                if (!this.state.isEdit) {
                                  let IdList = this.state.DeletableServicesNameIds ? this.state.DeletableServicesNameIds : []
                                  if (item?.id) {
                                    IdList = [...IdList, item?.id]
                                  }
                                  this.state.serviceList?.splice(index, 1)
                                  this.setState({ serviceList: this.state.serviceList, DeletableServicesNameIds: IdList })
                                } else {
                                  this.errorMessage('You have clicked edit button.So,you cannot able to delete')
                                }
                              }}
                            >
                              <Box component={'img'} src={ImagePaths.Delete.default} height={'1.5vw'} width={'1.5vw'} />
                            </Button>
                          </Box>
                        </TableCell>
                      </TableRow>
                    )
                  }) :
                    <TableRow>
                      <TableCell colSpan={3} align='center'>No Records To Be Shown</TableCell>
                    </TableRow>}
                </TableBody>
              </Table>
            </Box>
          </Box>
          <Box component={'div'} className='rad_rgt_btn_card' sx={{ backgroundColor: Colors.Background }}>
            <Button className='rad_rgt_btn' variant='outlined' emed_tid='rad_srv_rgt_clr'
              onClick={() => this.onClearHandler(true)}
            >Clear</Button>
            <Button className='rad_rgt_btn' variant='contained' emed_tid='rad_srv_rgt_save'
              onClick={() => this.postServiceData()}
              disabled={(isUser?.is_user && ('Radiology', "Settings", 'Service Name & Type Configuration', null, "Tab")?.editAccess) || this.state.postLoad || this.state.serviceList?.length === 0}
            >Save</Button>
          </Box>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  popupClose = () => {
    this.setState({ deletePopup: false })
  }

  onEditlftHandler = (item, index) => {
    try {
      this.setState({
        isLftEdit: true,
        serviceList: item?.data,
        selectedRowIndex: index,
        serviceType: item?.service_type ? item?.service_type : "",
        selectedId: item?.id,
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onDeleteHandler = (item) => {
    try {
      if (!this.state.isLftEdit) {
        this.setState({
          deletePopup: true,
          selectedId: item?.id,
          deletedServiceType: item?.service_type,
          DeletabeleServices: item?.data.map((list) => (list?.id))
        })
      } else {
        this.errorMessage("Edit has been clicked.So,Please clear or Update service")
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onClearPostDatas = () => {
    try {
      this.setState({
        serviceType: '',
        serviceName: '',
        serviceCode: '',
        serviceAmount: '',
        holidayAmount: '',
        emergencyAmount: '',
        serviceList: [],
        isEdit: false,
        selectedIndex: null,
        selectedId: null,
        selectedLftIndex: null,
        selectedRowIndex: null,
        isLftEdit: false,
        deletePopup: false,

      }, () => { this.onClearHandler(true) })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onSearchHandler = (e) => {
    try {
      this.setState({
        searchValue: e.target.value
      }, () => {
        let duplicateServiceData = JSON.parse(this.state.dupServiceData)
        if (this.state.searchValue !== '') {
          duplicateServiceData = duplicateServiceData.filter((item) => {
            return (
              (item?.service_type === '' ? '' : item?.service_type?.toLowerCase()?.includes(this.state.searchValue?.toLowerCase())))
          })
          this.setState({ serviceData: duplicateServiceData })
        } else {
          this.setState({ serviceData: duplicateServiceData })
        }
      })

    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderLftScreen = () => {
    try {
      this.state.serviceData?.slice(
        this.state.tblPage * this.state.tblPageSize,
        this.state.tblPage * this.state.tblPageSize +
        this.state.tblPageSize)?.forEach((element, index) => {
          let page = this.state.tblPage
          let pageSize = this.state.tblPageSize
          let serial = (page * pageSize) + (index + 1)
          element.serial = serial
        })
      return (
        <Box component={'div'}>
          <Box component={'div'} height={'6vh'} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} pr={'0.5vw'}>
            <TextField
              size='small'
              variant='standard'
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>)
              }}
              placeholder='Search...'
              onChange={(e) => this.onSearchHandler(e)}
              autoComplete='off'
            />
          </Box>
          <Box component={'div'} border={'1px solid lightgray'} height={'62.5vh'} overflow={'auto'}>
            <Table size='small' stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className='rad_rgt_tbl_head'>
                    <Typography fontWeight={600} fontSize={'0.9vw'}>S.No</Typography>
                  </TableCell>
                  <TableCell className='rad_rgt_tbl_head'>
                    <Typography fontWeight={600} fontSize={'0.9vw'}>Service Type</Typography>
                  </TableCell>
                  <TableCell className='rad_rgt_tbl_head'>
                    <Typography fontWeight={600} fontSize={'0.9vw'}>No. Of Services</Typography>
                  </TableCell>
                  <TableCell className='rad_rgt_tbl_head' align='center'>
                    <Typography fontWeight={600} fontSize={'0.9vw'}>Action</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.serviceData?.length > 0 ? this.state.serviceData?.slice(
                  this.state.tblPage * this.state.tblPageSize,
                  this.state.tblPage * this.state.tblPageSize +
                  this.state.tblPageSize)?.map((item, index) => (
                    <><TableRow
                      key={index}
                      // sx={{ cursor: "pointer" }}
                      >
                      <TableCell>{item?.serial}</TableCell>
                      <TableCell>
                        {item?.service_type ? item?.service_type?.length > 15 ?
                          <Tooltip placement='top' title={item?.service_type} arrow><Typography fontSize={'0.9vw'}>{item?.service_type?.slice(0, 15) + '...'}</Typography></Tooltip> :
                          <Typography fontSize={'0.9vw'}>{item?.service_type}</Typography> : '-'}
                      </TableCell>
                      <TableCell>
                        <Typography fontSize={'0.9vw'}>{item?.data?.length}</Typography>
                      </TableCell>
                      <TableCell>
                        <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                          <Button className='Common_Btn_Min_Width' sx={{ height: '1.5vw' }} emed_tid='rad_srv_lft_edt'
                            disabled={isUser?.is_user && CheckAccessFunc('Radiology', "Settings", 'Service Name & Type Configuration', null, "Tab")?.editAccess}
                            onClick={() => this.onEditlftHandler(item, index)}>
                            <Box component={'img'} src={ImagePaths.Edit.default} height={'1.5vw'} width={'1.5vw'} />
                          </Button>
                          <Button className='Common_Btn_Min_Width' sx={{ height: '1.5vw' }} emed_tid='rad_srv_lft_edt'
                            disabled={isUser?.is_user && CheckAccessFunc('Radiology', "Settings", 'Service Name & Type Configuration', null, "Tab")?.editAccess}
                            onClick={() => { this.onDeleteHandler(item, index) }}
                          >
                            <Box component={'img'} src={ImagePaths.Delete.default} height={'1.5vw'} width={'1.5vw'} />
                          </Button>
                          <IconButton emed_tid='rad_srv_lft_drp'
                            size='small'
                            onClick={() => {
                              this.setState({ selectedLftIndex: this.state.selectedLftIndex === index ? null : index }, () => { this.onClearHandler(true) })
                            }}
                          >
                            {this.state.selectedLftIndex === index ?
                              <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>

                      <TableRow>
                        {this.renderSecondTable(item, index)}
                      </TableRow></>
                  )) :
                  <TableRow>
                    <TableCell colSpan={4} align='center'>No Records To Be Shown</TableCell>
                  </TableRow>}
              </TableBody>
            </Table>
          </Box>
          <TablePagination
            className='eMed_tbl_pagination'
            sx={{ borderTop: '1px solid lightgray' }}
            component='div'
            rowsPerPageOptions={[10, 20, 30]}
            count={this.state.serviceData?.length}
            rowsPerPage={this.state.tblPageSize}
            page={this.state.tblPage}
            onPageChange={(event, newPage) => { this.setState({ tblPage: newPage }) }}
            onRowsPerPageChange={(event) => { this.setState({ tblPageSize: event.target.value, tblPage: 0 }) }}
          />
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderSecondTable = (item, index) => {
    try {
      return (
        <><TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={this.state.selectedLftIndex === index} timeout={'auto'}>
            <Box margin={1} className='rad_lft_scd_tbl'>
              <Table size='small' stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell className='rad_rgt_tbl_head'>
                      <Typography fontWeight={600} fontSize={'0.9vw'}>S.No</Typography>
                    </TableCell>
                    <TableCell className='rad_rgt_tbl_head'>
                      <Typography fontWeight={600} fontSize={'0.9vw'}>Service Name</Typography>
                    </TableCell>
                    <TableCell className='rad_rgt_tbl_head'>
                      <Typography fontWeight={600} fontSize={'0.9vw'}>Service Amount</Typography>
                    </TableCell>
                    <TableCell className='rad_rgt_tbl_head'>
                      <Typography fontWeight={600} fontSize={'0.9vw'}>Holiday Amount</Typography>
                    </TableCell>
                    <TableCell className='rad_rgt_tbl_head'>
                      <Typography fontWeight={600} fontSize={'0.9vw'}>Emergency Amount</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {item?.data?.length > 0 ? item?.data?.map((secItem, secIndex) => (
                    <TableRow key={secIndex}>
                      <TableCell>
                        <Typography fontSize={'0.9vw'}>{secIndex + 1}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography fontSize={'0.9vw'}>{secItem?.service_name ? secItem?.service_name?.length > 20 ?
                          <Tooltip placement='top' title={secItem?.service_name} arrow><Typography fontSize={'0.9vw'}>{secItem?.service_name?.slice(0, 20) + '...'}</Typography></Tooltip> :
                          <Typography fontSize={'0.9vw'}>{secItem?.service_name}</Typography> : '-'
                        }</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography fontSize={'0.9vw'}>{AmountFormat(secItem?.service_data?.normal_charges?.total ? secItem?.service_data?.normal_charges?.total : 0)}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography fontSize={'0.9vw'}>{AmountFormat(secItem?.service_data?.holiday_charges?.total ? secItem?.service_data?.holiday_charges?.total : 0)}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography fontSize={'0.9vw'}>{AmountFormat(secItem?.service_data?.emergency_charges?.total ? secItem?.service_data?.emergency_charges?.total : 0)}</Typography>
                      </TableCell>
                    </TableRow>
                  )) :
                    <TableRow>
                      <TableCell align='center' colSpan={3}>No Records To Be Shown</TableCell>
                    </TableRow>}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell></>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  render() {
    return (
      <Box component={'div'}>
        <Box component={'div'} className='rad_srv_container' sx={{ backgroundColor: Colors.Background }}>
          <Box component={'div'} className='rad_lft_container'>
            {this.renderLftScreen()}
          </Box>
          <Box component={'div'} className='rad_rgt_container'>
            {this.renderRgtScreen()}
          </Box>
        </Box>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.deletePopup ? <DeletePopup
          DeletTitle={`Are you Sure to Delete the ${this.state.deletedServiceType} ?`}
          deleteAlertPopupClose={this.popupClose.bind(this)}
          removeData={this.deleteServiceConfigData.bind(this)}
          DeleteNotify={"It will affect the entire Configuration"}
          disable={this.state.removeData}
        /> : null}
      </Box>
    )
  }
}
