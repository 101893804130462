import { Box, IconButton, Stack, Tooltip, Typography, Checkbox } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { ImagePaths } from '../../../../Utility/ImagePaths'
import './IpPatientDetails.css'
import { CommonPatientCard, CommonPatientDetails } from '../../../../Components/Common Components/CommonComponents';
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../Utility/API/Serviceurls'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import { AmountFormat } from '../../../../Components/CommonFunctions/CommonFunctions'
import { getCachevalue, localGetItem } from '../../../../Utility/Services/CacheProviderService'
import { CurrencySymbol } from '../../../../Utility/Constants'

class IpPatientDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      pageSize: 10,
      serviceListData: [],
      receiptListData: [],
      roomSatusData: [],
      cleaningSatusData: [],
      admissionDetailsData: [],
      patientName: "",
      mobileNo: "",
      attenderMobile: "",
      attenderdetails: "",
      medico: "",
      ipNumber: "",
      police: "",
      adDate: "",
      labReportData: [],
      paidAmt: "",
      pendingAmt: "",
      serviceAmt: "",
      AdvanceAmount: "",
      attenderMobile: "",
      attenderName: "",
      ipPatientData: {},
      withOutAppointment: {},
      ApproxAmount:0,
      CreditLmt: 0,
      PharmaCreditLmt:0,
      LastVisitObject : {},
      patient_add_line1: "",
      patient_add_line2: "",
      patient_area_name: "",
      patient_pincode: "",
      patient_city: "",
      patient_state: "",
      ApprovedAmt: 0,
      ipbillGen: false,
      moveOtRequest: false,
    }
  }

  componentDidMount() {
    let IpPatientDetails = getCachevalue('IppatientData')
    let patientData = JSON.parse(IpPatientDetails)
    let appointmentDetail = getCachevalue("withoutAppointment");
    let details = JSON.parse(appointmentDetail);
    this.setState({
      ipPatientData: patientData,
      withOutAppointment: details,
      ipbillGen: patientData.is_ready_for_final_bill,
      moveOtRequest: patientData.move_to_ot_request,
    }, () => {
      this.getPatientDetails()
      this.getServiceAndReceiptData()
      this.getRoomChangeDetailsData()
      this.getAddmissionDetailsData()
      this.getLaboratoryDetailsData()
      this.getApproxCharges()
      this.getLastVisitDateOutstandingAmount()
    })
  }

  successErrorMessage = (message, type) => {
    if (type === "error") {
      this.setState({
        isErrorMsg: true,
        isErrorMsgText: message
      })
    } else {
      this.setState({
        successMsg: true,
        successMsgText: message
      })
    }
  }

  getPatientDetails = (isFromIpAction = false) => {
    let admission_id = this.state.ipPatientData?.id || this.state.withOutAppointment?.ip_admission_id || this.props?.ip_admission_id;
    try {
      RestAPIService.getAll(Serviceurls.FO_PATIENT_DETAILS + "?admission_id=" + admission_id)
        .then((response) => {
          if (response.data.status === "success") {
            this.setState({
              paidAmt: response.data.data[0]?.paid_amount === 0 ? `0` : response.data.data[0].paid_amount,
              pendingAmt: response.data.data[0]?.balance_amount === 0 ? `0` : response.data.data[0].balance_amount,
              serviceAmt: response.data.data[0]?.service_amount === 0 ? `0` : response.data.data[0].service_amount,
              AdvanceAmount: response.data.data[0]?.advance_amount === 0 ? `0` : response.data.data[0].advance_amount,
              attenderName: response.data.data[0]?.attender_name ? response.data.data[0].attender_name : "-",
              attenderMobile: response.data.data[0]?.attender_mobile ? response.data.data[0].attender_mobile : "-",
              ApprovedAmt: response.data.data[0].claimed_amount ? response.data.data[0].claimed_amount : 0,
              ipbillGen: isFromIpAction ? response.data.data[0].is_ready_for_final_bill : this.state.ipbillGen,
              moveOtRequest: response.data.data[0]?.move_to_ot_request ? response.data.data[0]?.move_to_ot_request : this.state.moveOtRequest,
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.status === "fail") {
            this.successErrorMessage(error.response.data.message, "error")
          } else {
            this.successErrorMessage(error.message, "error")
          }
        })
    } catch (error) {
      this.successErrorMessage(error.message, "error")
    }
  }

  getLastVisitDateOutstandingAmount = () => {
    try {
      let admission_id = this.state.ipPatientData?.id || this.state.withOutAppointment?.ip_admission_id || this.props?.ip_admission_id;
      RestAPIService.getAll(Serviceurls.FO_PATIENT_LAST_VISIT_DATE_AND_BALANCE_AMOUNT + "?patient_id=" + this.state.ipPatientData?.patient_id + "&module=IP&branch_id=" + localGetItem("BranchId") + "&admission_id=" + admission_id).
        then((response) => {
          if (response.data.status === "success") {
              let Date = response.data?.last_visited_date ? response.data.last_visited_date : ""
              let Amount = response.data?.total_outstanding ? response.data.total_outstanding : 0
              let PatientStatus =  response.data?.admission_status ? response.data?.admission_status : ""
              let AdvanceBalance =  response.data?.advance_balance ? response.data?.advance_balance : 0
              let Obj = {"last_visited_date" : Date, "total_outstanding" : Amount , "status" : PatientStatus , advance_balance : AdvanceBalance}
              this.setState({
                LastVisitObject : Obj
              })
          }
          else {
            this.successErrorMessage(response.data.message, "error")
          }
        }).catch((error) => {
          this.successErrorMessage(error.message, "error")
        })
    } catch (e) {
      this.successErrorMessage(e.message, "error")
    }
  }

  getServiceAndReceiptData = () => {
    let admission_id = this.state.ipPatientData?.id || this.state.withOutAppointment?.ip_admission_id || this.props?.ip_admission_id;
    try {
      RestAPIService.getAll(Serviceurls.FO_PATIENT_SERVICE_RECEIPT + "?admission_id=" + admission_id + "&branch_id=" + localGetItem("BranchId"))
        .then((response) => {
          if (response.data.status === "success") {
            this.setState({
              serviceListData: response.data.service_list,
              receiptListData: response.data.receipt_list
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.status === "fail") {
            this.successErrorMessage(error.response.data.message, 'error')
          } else {
            this.successErrorMessage(error.message, 'error')
          }
        })
    } catch (error) {
      this.successErrorMessage(error.message, 'error')
    }
  }
  getApproxCharges = () => {
    let admission_id = this.state.ipPatientData?.id || this.state.withOutAppointment?.ip_admission_id || this.props?.ip_admission_id;
    try {
      RestAPIService.getAll(Serviceurls.IP_APPROX_AMOUNT + "?ip_admission_id=" + admission_id)
        .then((response) => {
          if (response.data.status === "success") {
            this.setState({
              ApproxAmount : response.data.data?.approx_room_charge
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.status === "fail") {
            this.successErrorMessage(error.response.data.message, 'error')
          } else {
            this.successErrorMessage(error.message, 'error')
          }
        })
    } catch (error) {
      this.successErrorMessage(error.message, 'error')
    }
  }

  getAddmissionDetailsData = () => {
    let admission_id = this.state.ipPatientData?.id || this.state.withOutAppointment?.ip_admission_id || this.props?.ip_admission_id;
    try {
      RestAPIService.getAll(Serviceurls.FO_PATIENT_ADMISSION + "?admission_id=" + admission_id)
        .then((response) => {
          if (response.data.status === "success") {
            this.setState({
              patientName: response.data.ip_admission[0].patien_name,
              mobileNo: response.data.ip_admission[0].mobile_number,
              police: response.data.ip_admission[0].police_station,
              attenderMobile: response.data.ip_admission[0].attender_mobile,
              adDate: response.data.ip_admission[0].admission_date,
              ipNumber: response.data.ip_admission[0].ip_number,
              medico: response.data.ip_admission[0].medico_legal_case ? "Yes" : "No",
              attenderdetails: response.data.ip_admission[0].attender_name,
              CreditLmt: response.data.ip_admission[0].ip_admission_credit_limit,
              PharmaCreditLmt: response.data.ip_admission[0].ip_pharmacy_credit_limit,
              patient_add_line1: response.data.ip_admission[0].patient_add_line1,
              patient_add_line2: response.data.ip_admission[0].patient_add_line2,
              patient_area_name: response.data.ip_admission[0].patient_area_name,
              patient_pincode: response.data.ip_admission[0].patient_pincode,
              patient_city: response.data.ip_admission[0].patient_city,
              patient_state: response.data.ip_admission[0].patient_state,
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.status === "fail") {
            this.successErrorMessage(error.response.data.message, 'error')
          } else {
            this.successErrorMessage(error.message, 'error')
          }
        })
    } catch (error) {
      this.successErrorMessage(error.message, 'error')
    }
  }

  getLaboratoryDetailsData = () => {
    let admission_id = this.state.ipPatientData?.id || this.state.withOutAppointment?.ip_admission_id || this.props?.ip_admission_id;
    try {
      RestAPIService.getAll(Serviceurls.FO_PATIENT_LABORATORY + "?admission_id=" + admission_id)
        .then((response) => {
          if (response.data.status === "success") {
            this.setState({
              labReportData: response.data.ip_admission
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.status === "fail") {
            this.successErrorMessage(error.response.data.message, 'error')
          } else {
            this.successErrorMessage(error.message, 'error')
          }
        })
    } catch (error) {
      this.successErrorMessage(error.message, 'error')
    }
  }

  getRoomChangeDetailsData = () => {
    let admission_id = this.state.ipPatientData?.id || this.state.withOutAppointment?.ip_admission_id || this.props?.ip_admission_id;
    try {
      RestAPIService.getAll(Serviceurls.FO_PATIENT_ROOM_CHANGE + "?admission_id=" + admission_id)
        .then((response) => {
          if (response.data.status === "success") {
            this.setState({
              roomSatusData: response.data.ip_admission
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.status === "fail") {
            this.successErrorMessage(error.response.data.message, 'error')
          } else {
            this.successErrorMessage(error.message, 'error')
          }
        })
    } catch (error) {
      this.successErrorMessage(error.message, 'error')
    }
  }

  ipActionPost = (forOTReq) => {
    try {
      let admission_id = this.state.ipPatientData?.id || this.state.withOutAppointment?.ip_admission_id || this.props?.ip_admission_id;
      let data = {
        ip_admission_id: admission_id,
        is_ready_for_final_bill: this.state.ipbillGen,
        move_to_ot_request: this.state.moveOtRequest,
        is_ip_ot_request: forOTReq ? true : false,
      }
      RestAPIService.create(data, Serviceurls.FO_PATIENT_DETAILS_IP_ACTION).
        then((response) => {
          if (response.data.status === "success") {
            this.successErrorMessage(response.data.message);
            this.getPatientDetails(true);
          } else {
            this.successErrorMessage(response.data.message, 'error')
          }
        }).catch(error => {
          if (error?.response?.data?.status === "fail") {
            this.successErrorMessage(error.response.data.message, 'error')
          } else {
            this.successErrorMessage(error.message, 'error')
          }
        })
    } catch (error) {
      this.successErrorMessage(error.message, 'error')
    }
  }

  renderTableBox = (names, gridRow, gridCol, image) => {
    const { t } = this.props
    return (
      <Box component={'div'} className='eMed_patient_grid'>
        <Box component={'div'} className='eMed_patient_table_header'>
          <Typography className='eMed_patient_text'>{names}</Typography>
          {/* <Box className='eMed_patient_img'>
            <img className='eMed_patient_img_res' src={image} alt='' />
          </Box> */}
        </Box>
        <Box>
          <Box>
            <DataGrid
              className='eMed_patient_datagrid'
              rows={gridRow}
              columns={gridCol}
              getRowId={(row) => row?.id}
              page={this.state.page}
              pageSize={this.state.pageSize}
              components={{
                NoRowsOverlay: () => (
                  <Stack className='eMed_conf_nodata'>
                    {t("NoRecordsFound")}
                  </Stack>
                )
              }}
              onPageChange={(newPage) => { this.setState({ page: newPage }) }}
              onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
              pagination
              headerHeight={40}
              disableSelectionOnClick
              density='compact'
              rowHeight={40}
            />
          </Box>
        </Box>
      </Box>
    )
  }

  getPatientAdmissionPrint = () => {
    try {
      let admission_id = this.state.ipPatientData?.id || this.state.withOutAppointment?.ip_admission_id || this.props?.ip_admission_id;
      RestAPIService.getAll(Serviceurls.IP_PATIENT_ADMISSION_PRINT + "?ip_admission_id=" + admission_id).
        then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }).catch((error) => {
          this.successErrorMessage(error.response?.data?.message,'error');
        })
    } catch (error) {
      this.successErrorMessage(error.message, 'error')
    }
  }

  renderAdmissionData = (patName, ipNumber, mobileNo, adDate, medico, policeStation, attenderdetails, attenderMobile, CreditLmt, PharmaCreditLmt) => {
    let states = this.state;
    const { t } = this.props
    return (
      <Box component={'div'} className='eMed_patient_grid'>
        <Box component={'div'} className='eMed_patient_table_header'>
          <Typography className='eMed_patient_text'>{"Admission Details"}</Typography>
          <Box className='eMed_patient_img'component={'div'} visibility={this.state.withOutAppointment?.ip_admission_id ? "hidden" : "none"}
            onClick={() => {
              this.props.history.push({pathname:"/IPHome/CreateAppointment", state: {is_PatientDetails: true , isPatientDischarged: this.state.ipPatientData?.status === "Checked Out"}})
            }}>
            <IconButton>
            <img className='eMed_patient_img_res' src={ImagePaths.Edit.default} alt='' />
            </IconButton>
          </Box>
          <Box className='eMed_patient_img'component={'div'}
            onClick={() => {
              this.getPatientAdmissionPrint()
            }}>
            <IconButton>
            <img className='eMed_patient_img_res' src={ImagePaths.PrintIcons.default} alt='' />
            </IconButton>
          </Box>
        </Box>
        <Box component={'div'} className='eMed_patient_admin'>
          <Box component={'div'} display='flex' mb={2} mt={1}>
            <Typography flex={0.45} pl='0.5vw'>{t("PatientName")}</Typography>
            <Typography flex={0.55} fontWeight={600} fontSize={"0.9vw"}>{patName ? patName : "-"}</Typography>
          </Box>
          <Box component={'div'} display='flex' mb={2}>
            <Typography flex={0.45} pl='0.5vw'>{t("IPID")}</Typography>
            <Typography flex={0.55} fontWeight={600} fontSize={"0.9vw"}>{ipNumber ? ipNumber : "-"}</Typography>
          </Box>
          <Box component={'div'} display='flex' mb={2}>
            <Typography flex={0.45} pl='0.5vw'>{t("MobileNumber")}</Typography>
            <Typography flex={0.55} fontWeight={600} fontSize={"0.9vw"}>{mobileNo ? mobileNo : "-"}</Typography>
          </Box>
          <Box component={'div'} display='flex' mb={2}>
            <Typography flex={0.45} pl='0.5vw'>{t("Patient Address")}</Typography>
            <Box component={'div'} flex={0.55}>
              <Typography fontWeight={600} fontSize={"0.9vw"}>{states.patient_add_line1?.length > 25 ?
                <Tooltip placement='top' title={states.patient_add_line1} arrow>
                  <Typography fontWeight={600} fontSize={"0.9vw"}>{states.patient_add_line1.slice(0, 24) + "..."}</Typography></Tooltip>
                : states.patient_add_line1 ? `${states.patient_add_line1}, ` : ""}</Typography>

              <Typography fontWeight={600} fontSize={"0.9vw"}>{states.patient_add_line2?.length > 25 ?
                <Tooltip placement='top' title={states.patient_add_line2} arrow>
                  <Typography fontWeight={600} fontSize={"0.9vw"}>{states.patient_add_line2.slice(0, 24) + "..."}</Typography></Tooltip>
                : states.patient_add_line2 ? `${states.patient_add_line2}, ` : ""}</Typography>

              <Typography fontWeight={600} fontSize={"0.9vw"}>{states.patient_area_name ?
                `${states.patient_area_name}, ` : ""}</Typography>

              <Typography fontWeight={600} fontSize={"0.9vw"}>{states.patient_city ? `${states.patient_city}, ` : ""}</Typography>
              <Typography fontWeight={600} fontSize={"0.9vw"}>{states.patient_state && states.patient_pincode ?
                `${states.patient_state} - ${states.patient_pincode}`
                : states.patient_state ? states.patient_state : states.patient_pincode ? states.patient_pincode : ""}</Typography>
            </Box>
          </Box>
          <Box component={'div'} display='flex' mb={2}>
            <Typography flex={0.45} pl='0.5vw'>{t("AdmissionDate")}</Typography>
            <Typography flex={0.55} fontWeight={600} fontSize={"0.9vw"}>{adDate ? adDate : "-"}</Typography>
          </Box>
          <Box component={'div'} display='flex' mb={2}>
            <Typography flex={0.45} pl='0.5vw'>{t("IPCreditLmt")}</Typography>
            <Typography flex={0.55} fontWeight={600} fontSize={"0.9vw"}>{CreditLmt ? AmountFormat(CreditLmt) : `${CurrencySymbol} 0.00`}</Typography>
          </Box>
          <Box component={'div'} display='flex' mb={2}>
            <Typography flex={0.45} pl='0.5vw'>{t("PharmaCreditLmt")}</Typography>
            <Typography flex={0.55} fontWeight={600} fontSize={"0.9vw"}>{PharmaCreditLmt ? AmountFormat(PharmaCreditLmt) : `${CurrencySymbol} 0.00`}</Typography>
          </Box>
          {this.state.LastVisitObject?.status === "Admitted" ?
            <Box component={'div'} display='flex' mb={2}>
              <Typography flex={0.45} pl='0.5vw'>{t("Last Admission Date")}</Typography>
              <Typography flex={0.55} fontWeight={600} fontSize={"0.9vw"}>{this.state.LastVisitObject?.last_visited_date ? this.state.LastVisitObject?.last_visited_date : "-"}</Typography>
            </Box> : null}
          <Box component={'div'} display='flex' mb={2}>
            <Typography flex={0.45} pl='0.5vw'>{t("Previous Credit Balance")}</Typography>
            <Typography flex={0.55} fontWeight={600} fontSize={"0.9vw"}>{this.state.LastVisitObject?.total_outstanding ? AmountFormat(this.state.LastVisitObject?.total_outstanding) : `${CurrencySymbol} 0.00`}</Typography>
          </Box>
          <Box component={'div'} display='flex' mb={2}>
            <Typography flex={0.45} pl='0.5vw'>{t("Previous Advance Balance")}</Typography>
            <Typography flex={0.55} fontWeight={600} fontSize={"0.9vw"}>{this.state.LastVisitObject?.advance_balance ? AmountFormat(this.state.LastVisitObject?.advance_balance) : `${CurrencySymbol} 0.00`}</Typography>
          </Box>
          <Box component={'div'} display='flex' mb={2}>
            <Typography flex={0.45} pl='0.5vw'>{t("MedicoLegal")}</Typography>
            <Typography flex={0.55} fontWeight={600} fontSize={"0.9vw"}>{medico ? medico : "-"}</Typography>
          </Box>
          <Box component={'div'} display='flex' mb={2}>
            <Typography flex={0.45} pl='0.5vw'>{t("PoliceStation")}</Typography>
            <Typography flex={0.55} fontWeight={600} fontSize={"0.9vw"}>{policeStation ? policeStation : "-"}</Typography>
          </Box>
          <Box component={'div'} display='flex' mb={2}>
            <Typography flex={0.45} pl='0.5vw'>{t("AttenderDetails")}</Typography>
            <Typography flex={0.55} fontWeight={600} fontSize={"0.9vw"}>{attenderdetails ? attenderdetails : "-"}</Typography>
          </Box>
          <Box component={'div'} display='flex' mb={0.5}>
            <Typography flex={0.45} pl='0.5vw'>{t("AttenderMobile")}</Typography>
            <Typography flex={0.55} fontWeight={600} fontSize={"0.9vw"}>{attenderMobile ? attenderMobile : "-"}</Typography>
          </Box>
        </Box>
      </Box>
    )
  }

  renderCheckbox = (label, statekey, subTxt, isDisable = false) => {
    const { t } = this.props;
    let states = this.state;
    return (
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ height: "4.5dvw" }}>
      <Stack>
        <Typography id="eMed_permission_checkTxt"> {t(label)} </Typography>
        {subTxt ? <Typography id="eMed_permission_TimeTxt">{`${t(subTxt)}`}</Typography> : null}
      </Stack>
      <Checkbox
        size="small"
        checked={states[statekey]}
        disabled={isDisable || this.state.ipPatientData?.is_final_bill_generated}
        onChange={(e) => { this.setState({ [statekey]: e.target.checked }, () => {statekey === "moveOtRequest" ? this.ipActionPost(true) : this.ipActionPost()}) }}
      />
    </Stack>)
  }

  renderIpAction = () => {
    return (
      <Box component={'div'} className='eMed_patient_grid'>
        <Box component={'div'} className='eMed_patient_table_header' borderBottom={"1px solid #E3E2E2"}>
          <Typography className='eMed_patient_text'>IP Actions</Typography>
        </Box>
        <Box className='eMed_patient_datagrid' overflow={"auto"}>
          {this.renderCheckbox("Ready for Final Bill Generation", "ipbillGen", "", this.state.ipPatientData?.is_final_bill_generated)}
          {this.renderCheckbox("Move to OT Request", "moveOtRequest", "", this.state.ipPatientData?.is_ot_created)}
        </Box>
      </Box>
    )
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }
  render() {
    const { t, fromBillingDetails } = this.props
    const ServiceList = [
      {
        field: "created_date", headerName: t("Date"), flex: 0.33, headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.created_date ? params?.row?.created_date : "-"}</Box>)
      },
      {
        field: "invoice_number", headerName: t("BillNo"), flex: 0.33, headerAlign: "center", align: "center",
        renderCell: (params) => {
          let BillNumber = params?.row?.invoice_number ? params?.row?.invoice_number : "-"
          let SplitedArray = BillNumber.split("/")
          return (
            <Box component={'div'}>{
              BillNumber?.length > 20 ? <Tooltip placement='top' title={BillNumber} arrow>
                <Box>
                  {BillNumber ? "..." + `/${SplitedArray[SplitedArray.length - 1]}` : BillNumber}
                </Box>
              </Tooltip> :
                BillNumber?.length > 5 ? <Tooltip placement='top' title={BillNumber} arrow>
                  <Box>
                    {BillNumber ? "..." + `/${SplitedArray[SplitedArray.length - 2]}/${SplitedArray[SplitedArray.length - 1]}` : BillNumber}
                  </Box>
                </Tooltip>
                  : BillNumber}
            </Box>
          )
        }
      },
      {
        field: "service_amount", headerName: `${t("Amount")} (${CurrencySymbol})`, flex: 0.33, type: "number",
        renderCell: ({ row }) => {
          let amounts = AmountFormat(row?.service_amount)
          return (<Box component={'div'}>{amounts ? amounts.replace(`${CurrencySymbol}`, "") : "-"}</Box>)
        }
      }
    ]
    const ReceiptList = [
      {
        field: "invoice_date", headerName: t("Date"), flex: 0.33, headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.invoice_date ? params?.row?.invoice_date : "-"}</Box>)
      },
      {
        field: "invoice_number", headerName: t("ReceiptNo"), flex: 0.33, headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{
          params?.row?.invoice_number ? <Tooltip placement='top' title={params?.row?.invoice_number} arrow>
            <Box>{params?.row?.invoice_number.length > 5 ?
              params?.row?.invoice_number.slice(0, 12) + "..." : params?.row?.invoice_number}</Box>
          </Tooltip> : "-"}</Box>)
      },
      {
        field: "net_amount", headerName: `${t("Amount")} (${CurrencySymbol})`, flex: 0.33, type: "number",
        renderCell: ({ row }) => {
          let amounts = AmountFormat(row?.net_amount)
          return (<Box component={'div'}>{amounts ? amounts.replace(`${CurrencySymbol}`, "") : "-"}</Box>)
        }
      }
    ]
    const RoomChangeList = [
      {
        field: "from_dates", headerName: t("FromDate"), flex: 0.33, headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.from_dates ? params?.row?.from_dates : "-"}</Box>)
      },
      {
        field: "to_dates", headerName: t("ToDate"), flex: 0.33, headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.to_dates ? params?.row?.to_dates : "-"}</Box>)
      },
      {
        field: "ward_name", headerName: t("Ward/Room/bed"), flex: 0.33, type: "number",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.ward_name ? (`${params?.row?.ward_name}/${params?.row?.room_number}/${params?.row?.bed_number}`)?.length > 9 ?
          <Tooltip placement='top' title={`${params?.row?.ward_name}/${params?.row?.room_number}/${params?.row?.bed_number}`} arrow><div>{(`${params?.row?.ward_name}/${params?.row?.room_number}/${params?.row?.bed_number}`).slice(0, 8) + '...'}</div></Tooltip> :
          `${params?.row?.ward_name} / ${params?.row?.room_number} / ${params?.row?.bed_number}` : "-"}</Box>)
      }
    ]
    const CLeaningList = [
      {
        field: "date", headerName: t("Date"), flex: 0.5, headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.date ? params?.row?.date : "-"}</Box>)
      },
      {
        field: "bill_no", headerName: t("Status"), flex: 0.5,
        renderCell: (params) => (<Box component={'div'}>{params?.row?.bill_no ? params?.row?.bill_no : "-"}</Box>)
      }
    ]
    const AssessmentList = [
      {
        field: "date", headerName: t("FormName"), flex: 0.5,
        renderCell: (params) => (<Box component={'div'}>{params?.row?.date ? params?.row?.date : "-"}</Box>)
      },
      {
        field: "bill_no", headerName: t("Status"), flex: 0.5,
        renderCell: (params) => (<Box component={'div'}>{params?.row?.date ? params?.row?.date : "-"}</Box>)
      }
    ]
    const LabList = [
      {
        field: "created_date", headerName: t("Date"), flex: 0.33, headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.created_date ? params?.row?.created_date : "-"}</Box>)
      },
      {
        field: "laboratory_name", headerName: t("Report"), flex: 0.33,
        renderCell: (params) => (<Box component={'div'}>{params?.row?.laboratory_name ? params?.row?.laboratory_name?.length > 10 ?
          <Tooltip placement='top' title={params?.row?.laboratory_name} arrow><div>{params?.row?.laboratory_name.slice(0, 10) + "..."}</div></Tooltip> : params?.row?.laboratory_name : "-"}</Box>)
      },
      {
        field: "status", headerName: t("Status"), flex: 0.33,
        renderCell: (params) => (<Box component={'div'}>{params?.row?.status ? params?.row?.status?.length > 10 ?
          <Tooltip placement='top' title={params?.row?.status} arrow><div>{params?.row?.status.slice(0, 10) + "..."}</div></Tooltip> : params?.row?.status : "-"}</Box>)
      }
    ]
    const columnsEight = [
      {
        field: "date", headerName: t("Date"), flex: 0.33, headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.date ? params?.row?.date : "-"}</Box>)
      },
      {
        field: "bill_no", headerName: t("BillNo"), flex: 0.33, headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.bill_no ? params?.row?.bill_no : "-"}</Box>)
      },
      {
        field: "amount", headerName: `${t("Amount")} (${CurrencySymbol})`, flex: 0.33, type: "number",
        renderCell: ({ row }) => {
          let amounts = AmountFormat(row?.amount)
          return (<Box component={'div'}>{amounts ? amounts.replace(`${CurrencySymbol}`, "") : "-"}</Box>)
        }
      }
    ]
    let details = {
      lineOne: fromBillingDetails ? t("Emergency Contact") : t("AttenderName"),
      lineTwo: `${this.state.attenderName ? this.state.attenderName : '-'}`,
      lineFour: t('ContactNumber'),
      LineFive: `${this.state.attenderMobile ? this.state.attenderMobile : '-'}`
    }
    let serviceAmt = {
      image: CurrencySymbol === '$' ? ImagePaths.Dollor.default : ImagePaths.Rupee.default,
      height: "1.3vw",
      width: "1.3vw",
      lineOne: t("BillAmount"),
      lineTwo: this.state.serviceAmt ? `${AmountFormat(this.state.serviceAmt)}` : `${CurrencySymbol} 0.00`,
      // secHead: t('PaidAmount'),
      // secAmt: this.state.paidAmt ? `${AmountFormat(this.state.paidAmt).replace("₹", "")}` : "₹ 0"
    }

    let PaidDetails = {
      image: CurrencySymbol === '$' ? ImagePaths.Dollor.default : ImagePaths.Rupee.default,
      height: "1.3vw",
      width: "1.3vw",
      lineOne: t("Received Amount"),
      lineTwo: this.state.paidAmt ? `${AmountFormat(this.state.paidAmt)}` : `${CurrencySymbol} 0.00`,
      // secHead: t('PaidAmount'),
      // secAmt: this.state.paidAmt ? `${AmountFormat(this.state.paidAmt).replace("₹", "")}` : "₹ 0"
    }

    let PendingDetails = {
      image: CurrencySymbol === '$' ? ImagePaths.Dollor.default : ImagePaths.Rupee.default,
      height: "1.3vw",
      width: "1.3vw",
      lineOne: t("BalAmt"),
      lineTwo: this.state.pendingAmt ? `${AmountFormat(this.state.pendingAmt)}` : `${CurrencySymbol} 0.00`,
      colorCode: +this.state.pendingAmt > 0 ? 'green' : +this.state.pendingAmt === 0 ? "balck" : 'red'
      // secHead: t('ApproxAmount'),
      // secAmt: this.state.ApproxAmount ? this.state.ApproxAmount : "₹ 0"
    }

    let ApproxDetails = {
      image: CurrencySymbol === '$' ? ImagePaths.Dollor.default : ImagePaths.Rupee.default,
      height: "1.3vw",
      width: "1.3vw",
      lineOne: t('ApproxAmount'),
      lineTwo: this.state.ApproxAmount ? `${AmountFormat(this.state.ApproxAmount)}` : `${CurrencySymbol} 0.00`,
    }

    let AdvanceDetails = {
      image: CurrencySymbol === '$' ? ImagePaths.Dollor.default : ImagePaths.Rupee.default,
      height: "1.3vw",
      width: "1.3vw",
      lineOne: t('AdvanceAmount'),
      lineTwo: this.state.AdvanceAmount ? `${AmountFormat(this.state.AdvanceAmount)}` : `${CurrencySymbol} 0.00`,
    }
    let ClaimDetails = {
      image: CurrencySymbol === '$' ? ImagePaths.Dollor.default : ImagePaths.Rupee.default,
      width: "1.3vw",
      height: "1.3vw",
      lineOne: t('Claimed Amount'),
      lineTwo: `${this.state.ApprovedAmt ? AmountFormat(this.state.ApprovedAmt) : `${CurrencySymbol} 0.00`}`
    }
    let { patientName, ipNumber, mobileNo, adDate, medico, police, attenderdetails, attenderMobile, CreditLmt, PharmaCreditLmt} = this.state
    return (
      <Box component={'div'} className='eMed_patient_container'>
        <Box component={'div'} className='eMed_patient_header'>
          <Box display={'flex'} alignItems='center' marginLeft={'0.6vw'}>
            <Box className='eMed_nur_notes_details' sx={{ marginLeft: "0.2vw" }}>
              <CommonPatientDetails data={this.state.ipPatientData} showDetailed={!fromBillingDetails} />
            </Box>
            {/* <CommonPatientCard details={details} showDetailed={true}/> */}
            {fromBillingDetails ? null : <>
              <CommonPatientCard details={AdvanceDetails}/>
              <CommonPatientCard details={serviceAmt} smallWidth={true}/>
              <CommonPatientCard details={PaidDetails} smallWidth={true}/>
              <CommonPatientCard details={PendingDetails} smallWidth={true}/>
              <CommonPatientCard details={ApproxDetails} smallWidth={true}/>
              <CommonPatientCard details={ClaimDetails} smallWidth={true} />
            </>}
          </Box>
        </Box>
        <Box component={'div'} className='eMed_patient_card'>
          <Box component={'div'} className='eMed_patient_content'>
            {this.renderTableBox("Service List", this.state.serviceListData, ServiceList, ImagePaths.PrintIcons.default)}
            {this.renderTableBox("Receipt List", this.state.receiptListData, ReceiptList, ImagePaths.PrintIcons.default)}
            {this.renderTableBox("Room Status", this.state.roomSatusData, RoomChangeList, ImagePaths.PrintIcons.default)}
            {this.renderTableBox("Laboratory Report Status", this.state.labReportData, LabList, ImagePaths.PrintIcons.default)}
            {/* {this.renderTableBox("Cleaning Status", "", CLeaningList, ImagePaths.PrintIcons.default)} */}
          </Box>
          <Box component={'div'} className='eMed_patient_content'>
            {this.renderAdmissionData(patientName, ipNumber, mobileNo, adDate, medico, police, attenderdetails, attenderMobile, CreditLmt, PharmaCreditLmt)}
            {this.renderIpAction()}
            {/* {this.renderTableBox("Intial Assessment Status", "", AssessmentList, ImagePaths.PrintIcons.default)} */}

            {/* {this.renderTableBox("-", "", columnsEight, ImagePaths.PrintIcons.default)} */}
          </Box>
        </Box>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
      </Box>
    )
  }
}

export default withTranslation()(IpPatientDetails)