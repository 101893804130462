import React, { Component } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, IconButton, Stack, InputLabel, FormHelperText, Tooltip, Box, Collapse, InputAdornment, Typography, Stepper, Step, StepLabel, Tab, Modal } from '@mui/material'
import './home.css'
import { ImagePaths } from '../../../Utility/ImagePaths';
import { Button } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Autocomplete from '@mui/material/Autocomplete';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import DatePicker from '../../../Components/DatePicker/DatePicker';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import CommonGridHeader, { BillSuccessPoPUp, CommonPatientDetails } from "../../../Components/Common Components/CommonComponents";
import CreditBillPopUp from '../../FrontOffice/BillingTransaction/CreditBillPopUp';
import { CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions';
import { Colors } from '../../../Styles/Colors';
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import SearchIcon from '@mui/icons-material/Search';
import EditHistoryPopup from '../../FrontOffice/BillingTransaction/EditHistoryPopup';
import { getCachevalue, localGetItem, setCachevalue } from '../../../Utility/Services/CacheProviderService';
import CreditBillCompletePoP from '../../FrontOffice/BillingTransaction/CreditBillCompletePoP';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import Loader from '../../../Components/Loader';
import { DataGrid } from '@mui/x-data-grid';


const ITEM_HEIGHT = 48;
const specimanList = [
    { "service_name": "calcium", "lab_type": "Self" },
    { "service_name": "glucose", "lab_type": "Self" },
    { "service_name": "sodium", "lab_type": "Self" },
    { "service_name": "pottassium", "lab_type": "Self" },
    { "service_name": "chloride", "lab_type": "Self" },
    { "service_name": "blood urea nitrogen(BUN)", "lab_type": "Self" },
    { "service_name": "creatinine", "lab_type": "Self" }
]
const multi_option = [
    { "label": "ViewPreviousReport", "value": "View Previous Report" },
    { "label": "ReceivePayment", "value": "Receive Payment" },
    { "label": "BarCode", "value": "Common Barcode" },
    { "label": "Cancel Bill", "value": "Cancel Bill" },
    { "label": "Delete Bill", "value": "Delete Bill" }

]
const Previous_List = [
    {
        invoice_number: "1232"
    },
    {
        invoice_number: "12223"
    }
]
class Status extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            rowsPerPage: 5,
            statusSummaryList: [],
            isSpecimanClick: false,
            specimanList: specimanList,
            consumablesName: null,
            consumableValue: "",
            consumableUnit: "",
            consumableList: [],
            sampleCollectedIn: [],
            isFilterOption: false,
            selectedIndex: "",
            isMultiOPtion: false,
            selectedOption: "",
            anchorEl: null,
            billSummaryID: "",
            reason: "",
            isErrorMsg: false,
            isErrorMsgText: "",
            pageSize: 10,
            selectedTableRow: {},
            outSourceOptionList: [],
            isViewReport: false,
            previousReportList: Previous_List,
            specimanSuggessionList: [],
            selectConsumables: null,
            outSourceList: [],
            isErrorMsg: false,
            isErrorMsgText: "",
            outSourceList: [],
            preReportList: [],
            search_key: "",
            patientName: "",
            mobileNumber: "",
            QueryFromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            QueryToDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            dateFilter: 1,
            isSpecimanComplete: false,
            isSelected: false,
            isCancelBill: false,
            CreditBillPopUp: false,
            amountData: {},
            disableBtn: false,

            //New Table
            tableSelectedIndex: null,
            tblPage: 0,
            tblPageSize: 10,
            TableSearchText: "",
            resetSourceOptionList: [],
            BillModifyHistoryItem: {},

            // specimen pop up screen states 
            // patient state
            patientDetails: null,

            // tab 1 state
            specimanSamples: [],
            selectedSamples: [],
            // hasSelection: false,
            stepNumber: 0,
            tabValue: '1',

            // tab 2 state
            collectedSpecimanSamples: [],
            buttonDisabledStates: {},

            // consumables state
            // state for store api call data
            consumables: [],

            // state for store for data
            consumablesName: null,
            name: '',
            quantity: '',
            unit: '',

            // add consumables list
            selectedConsumableList: [],
            consumableTableList: [],
            // state for sending the data
            consumablesList: [],
            extra_consumablesList: []

        }
    }
    componentDidMount() {
        this.getStatusSpecimanList()
        if (getCachevalue('LabStatusDateFilter')) {
            this.setState({
                dateFilter: +getCachevalue('LabStatusDateFilter')
            }, () => {
                this.handleDate()
            })
        } else {
            this.getStatusSummaryList()
            setCachevalue(this.state.dateFilter, 'LabStatusDateFilter')
        }
    }

    componentDidUpdate(PrevProps) {
        if (PrevProps.search_key != this.props.search_key) {
            var { search_key } = this.props
            this.setState({
                search_key: search_key ? search_key : ""
            }, () => {
                var data = parseInt(this.state.search_key)
                let type = typeof (data ? data : "")

                if (type == "number") {
                    this.setState({
                        mobileNumber: this.state.search_key ? this.state.search_key : "",
                        patientName: ""
                    }, () => {
                        this.getStatusSummaryList()
                    })
                }
                else {
                    this.setState({
                        patientName: this.state.search_key ? this.state.search_key : "",
                        mobileNumber: ""
                    }, () => {
                        this.getStatusSummaryList()
                    })
                }
            })
        }
    }

    getReceivePaymentAmountData = (cellvalues) => {
        try {
            let params = ""
            let ipAdmissionId = cellvalues?.ip_admission_id
            let op_appointment_id = cellvalues?.appointment_id
            let lab_bill_summary_id = cellvalues?.lab_bill_summary_id

            if ((ipAdmissionId !== null && lab_bill_summary_id !== null)) {
                params = "?ip_admission_id=" + ipAdmissionId
            } else if ((ipAdmissionId === null && op_appointment_id !== null && lab_bill_summary_id !== null)) {
                params = "?appointment_id=" + op_appointment_id
            } else if ((ipAdmissionId === null && op_appointment_id === null && lab_bill_summary_id !== null)) {
                params = "?lab_bill_summary=" + lab_bill_summary_id
            }

            RestAPIService.getAll(Serviceurls.LAB_STATUS_AND_TEST_REPORT_AMOUNT_GET + params).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            amountData: response.data.data
                        })
                    }
                }).catch((error) => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: error?.response?.data?.message
                    })
                })
        } catch (error) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: error.message
            })
        }
    }

    getCommonBarCodePrint = (params) => {
        try {
            let admissionId = this.state.SelectedBill?.ip_admission_id ? this.state.SelectedBill?.ip_admission_id : ''
            let appointmentId = this.state.SelectedBill?.appointment_id ? this.state.SelectedBill?.appointment_id : ''
            RestAPIService.getAll(Serviceurls.COMMON_BARCODE_PRINT + "?patient_id=" + this.state.SelectedBill?.patient_id + "&appointment_id=" + appointmentId + "&admission_id=" + admissionId)
                .then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }).catch((error) => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: error.response.data.message
                    })
                })
        } catch (e) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message
            })
        }
    }

    PrintBarcode = () => {
        try {
            var details = this.state.selectedTableRow
            this.setState({ disableBtn: true })
            RestAPIService.getAllPrint(Serviceurls.SPECIMEN_BARCODE_PRINT + `?patient_id=${details.patient_id}&result_summary_id=${details.id}`).
                then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    this.setState({ disableBtn: false })
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.setState({
                            isErrorMsg: true,
                            isErrorMsgText: error.response.data.message,
                            disableBtn: false,
                        })
                    } else {
                        this.setState({
                            isErrorMsg: true,
                            isErrorMsgText: error.message,
                            disableBtn: false,
                        })
                    }

                })
        } catch (error) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: t("PrintError"),
                disableBtn: false,
            })
        }
    }

    collectedSamplePrintBarcode = (index) => {
        const selectedSpecimenTblData = this.state.collectedSpecimanSamples[index];
    
        if (!selectedSpecimenTblData?.s_id) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: "No specimen s_id found for this test",
            });
            return;
        }
    
        // Disable the specific button
        this.setState((prevState) => ({
            buttonDisabledStates: {
                ...prevState.buttonDisabledStates,
                [index]: true, // Disable only the clicked button
            },
        }));
    
        try {
            const details = this.state.selectedTableRow;
            RestAPIService.getAllPrint(
                `${Serviceurls.SPECIMEN_BARCODE_PRINT}?patient_id=${details.patient_id}&result_summary_id=${details.id}&s_id=${selectedSpecimenTblData.s_id}`
            )
                .then((response) => {
                    const file = new Blob([response.data], { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    
                    // Re-enable the specific button
                    this.setState((prevState) => ({
                        buttonDisabledStates: {
                            ...prevState.buttonDisabledStates,
                            [index]: false, // Re-enable the clicked button
                        },
                    }));
                })
                .catch((error) => {
                    this.handlePrintError(error, index);
                });
        } catch (error) {
            this.handlePrintError(error, index);
        }
    };
    
    handlePrintError = (error, index) => {
        const errorMessage = error?.response?.data?.message || error.message || "Print Error";
        this.setState((prevState) => ({
            isErrorMsg: true,
            isErrorMsgText: errorMessage,
            buttonDisabledStates: {
                ...prevState.buttonDisabledStates,
                [index]: false, // Re-enable the button on error
            },
        }));
    };
    

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            isSuccessMsg: false,
            isSuccessMsgText: "",
        })
    }

    ClosePopUp(Getapicall = false, message = "") {
        this.setState({
            CreditBillPopUp: false,
            anchorEl: false
        })
    }

    CustomDates = (FromDate, ToDate) => {
        this.setState({
            QueryFromDate: FromDate,
            QueryToDate: ToDate,
            datePickerPop: false
        }, () => {
            let localData = {
                "QueryFromDate": FromDate,
                "QueryToDate": ToDate,
            }
            setCachevalue(JSON.stringify(localData), 'LabStatusDateCustumFilter')
            this.getStatusSummaryList()
        })
    }

    PopClose = () => {
        this.setState({
            datePickerPop: false,
            QueryFromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            QueryToDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            dateFilter: 1,
        }, () => this.getStatusSummaryList())
    }

    DateHelperText = () => {
        let fromDate = this.dateConvertion(this.state.QueryFromDate)
        let toDate = this.dateConvertion(this.state.QueryToDate)
        if (this.state.dateFilter === 0) {
            return ""
        }
        else if (this.state.dateFilter === 1) {
            return DateTime.now().toFormat('dd-MM-yyyy')
        }
        else if (this.state.dateFilter === 2) {
            return DateTime.now().minus({ days: 1 }).toFormat('dd-MM-yyyy')
        }
        else if (this.state.dateFilter === 3) {
            // return `${fromDate}  to  ${toDate}`
            const startOfPreviousWeek = DateTime.now().minus({ weeks: 1 }).startOf('week');
            const endOfPreviousWeek = DateTime.now().minus({ weeks: 1 }).endOf('week');
            return `${startOfPreviousWeek.minus({ days: 1 }).toFormat('dd-MM-yyyy')} to ${endOfPreviousWeek.minus({ days: 1 }).toFormat('dd-MM-yyyy')}`

        }
        else if (this.state.dateFilter === 4) {
            // return `${fromDate}  to  ${toDate}`
            const startOfPreviousMonth = DateTime.now().minus({ month: 1 }).startOf('month');
            const endOfPreviousMonth = DateTime.now().minus({ month: 1 }).endOf('month');
            return `${startOfPreviousMonth.toFormat('dd-MM-yyyy')} to ${endOfPreviousMonth.toFormat('dd-MM-yyyy')}`
        }
        else if (this.state.dateFilter === 5) {
            return `${this.state.QueryFromDate.split("-").reverse().join("-")}  to  ${this.state.QueryToDate.split("-").reverse().join("-")}`
        }
    }

    handleDate = () => {
        var today = DateTime.now().toFormat('yyyy-MM-dd')
        var yesterday = DateTime.now().minus({ days: 1 }).toFormat('yyyy-MM-dd')
        // var startweek = DateTime.now().minus({ days: 7 }).toFormat('yyyy-MM-dd')
        // var startmonth = DateTime.now().minus({ days: 30 }).toFormat('yyyy-MM-dd')
        var startOfPreviousWeek = DateTime.now().minus({ weeks: 1 }).startOf('week').minus({ days: 1 }).toFormat('yyyy-MM-dd')
        var endOfPreviousWeek = DateTime.now().minus({ weeks: 1 }).endOf('week').minus({ days: 1 }).toFormat('yyyy-MM-dd')
        const startOfPreviousMonth = DateTime.now().minus({ month: 1 }).startOf('month').toFormat('yyyy-MM-dd');
        const endOfPreviousMonth = DateTime.now().minus({ month: 1 }).endOf('month').toFormat('yyyy-MM-dd');
        setCachevalue(this.state.dateFilter, 'LabStatusDateFilter')
        if (this.state.dateFilter === 0) {
            this.setState({
                QueryFromDate: "",
                QueryToDate: "",
            }, () => this.getStatusSummaryList())
        }
        else if (this.state.dateFilter === 1) {
            this.setState({
                QueryFromDate: today,
                QueryToDate: today,
            }, () => this.getStatusSummaryList()
            )

        }
        else if (this.state.dateFilter === 2) {
            this.setState({
                QueryFromDate: yesterday,
                QueryToDate: yesterday,
            }, () => this.getStatusSummaryList())
        }
        else if (this.state.dateFilter === 3) {
            this.setState({
                QueryFromDate: startOfPreviousWeek,
                QueryToDate: endOfPreviousWeek,
            }, () => this.getStatusSummaryList())
        }
        else if (this.state.dateFilter === 4) {
            this.setState({
                QueryFromDate: startOfPreviousMonth,
                QueryToDate: endOfPreviousMonth,
            }, () => this.getStatusSummaryList())
        }
        else if (this.state.dateFilter === 5) {
            if (getCachevalue("LabStatusDateCustumFilter") !== null) {
                let CustumData = JSON.parse(getCachevalue("LabStatusDateCustumFilter"))
                this.setState({
                    QueryFromDate: CustumData?.QueryFromDate,
                    QueryToDate: CustumData?.QueryToDate,
                }, () => this.getStatusSummaryList())
            } else {
                this.setState({ datePickerPop: true })
            }
        }
    }

    // // prev api call for prev screen
    // getSpecimanCollectedValue = (id) => {
    //     RestAPIService.getAll(Serviceurls.LAB_PATENT_SPECIMAN_COLLECTED + "?result_summary_id=" + id)
    //         .then(response => {
    //             if (response.data.status == "success") {
    //                 var data = response.data.data
    //                 this.setState({
    //                     outSourceOptionList: data.out_source,
    //                     sampleCollectedIn: data.extra_consumables ? data.extra_consumables : [],
    //                     consumableList: data.consumables
    //                 }, () => {
    //                     this.state.outSourceOptionList && this.state.outSourceOptionList.forEach(data => { data.out_source_id = "" })
    //                     this.setState({
    //                         resetSourceOptionList: JSON.stringify(this.state.outSourceOptionList)
    //                     })
    //                 })
    //             } else {
    //                 this.setState({
    //                     isErrorMsg: true,
    //                     isErrorMsgText: response.data.message
    //                 })
    //             }
    //         }).catch((error) => {
    //             this.setState({
    //                 isErrorMsg: true,
    //                 isErrorMsgText: error.response.data.message
    //             })
    //         })
    // }

    // specimen pop up get call in sample tab && collection
    getPreSpecimanCollectedValue = (id, type = false) => {
        const { selectedTableRow } = this.state
        RestAPIService.getAll(Serviceurls.LAB_PRE_SPECIMAN_COLLECTED + "?result_summary_id=" + selectedTableRow.id)
            .then(response => {
                if (response.data.status == "success") {
                    // samples data
                    var data = response.data.test_datas
                    var patient = response.data.bill_data
                    this.setState({
                        specimanSamples: data,
                        patientDetails: patient
                    })

                    if(data.length > 0 && type){
                        this.setState({
                            tabValue: '1'
                        })
                    }
                } else {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: response.data.message
                    })
                }
            }).catch((error) => {
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: error.response.data.message
                })
            })
    }
    getPostSpecimanCollectedValue = (id) => {
        var { selectedTableRow } = this.state
        RestAPIService.getAll(Serviceurls.LAB_POST_SPECIMAN_COLLECTED + "?result_summary_id=" + selectedTableRow.id)
            .then(response => {
                if (response.data.status == "success") {
                    // samples data
                    var data = response.data.test_datas
                    this.setState({
                        collectedSpecimanSamples: data,
                    })
                } else {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: response.data.message
                    })
                }
            }).catch((error) => {
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: error.response.data.message
                })
            })
    }
    getCurrentTestConsumableValue = (id) => {
        const { selectedTableRow } = this.state;

        RestAPIService.getAll(Serviceurls.LAB_TEST_CONSUMABLE + "?result_summary_id=" + selectedTableRow.id)
            .then(response => {
                if (response.data.status === "success") {
                    const data = response.data;
                    
                    // Add unique IDs to consumableList and extra_consumablesList
                    const consumableListWithIds = data.consumables.map((item, index) => ({
                        ...item,
                        id: `consumable-${index}`, // Add a unique ID
                    }));

                    const extraConsumablesListWithIds = data.extra_consumables.map((item, index) => ({
                        ...item,
                        id: `extra-consumable-${index + consumableListWithIds.length}`, // Unique ID based on the combined length
                    }));

                    // Update state with merged lists
                    this.setState((prevState) => ({
                        consumableList: consumableListWithIds,
                        extra_consumablesList: extraConsumablesListWithIds,
                    }));
                } else {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: response.data.message,
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: error.response?.data?.message || "An error occurred",
                });
            });
    };

    getPreviousReport = () => {
        RestAPIService.getAll(Serviceurls.LAB_PREVIOUS_REPORT + "?patient_id=" + this.state.selectedTableRow?.patient_id)
            .then(response => {
                if (response.data.status == "success") {
                    this.setState({
                        preReportList: response.data.data
                    })
                } else {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: response.data.message
                    })
                }
            }).catch((error) => {
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: error.response.data.message
                })
            })
    }
    getStatusSpecimanList = () => {
        RestAPIService.getAll(Serviceurls.LAB_CONSUMABLES_LIST_GET)
            .then(response => {
                if (response.data.status == "success") {
                    this.setState({
                        specimanSuggessionList: response.data.data,
                        consumables: response.data.data,
                    })
                } else {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: response.data.message
                    })
                }
            }).catch((error) => {
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: error.response.data.message
                })
            })
    }

    LoaderFunction = (key) => {
        this.setState({
          isLoader: key
        })
      }

    getStatusSummaryList = () => {
        this.setState({
            statusSummaryList: []
        })
        this.LoaderFunction(true)
        RestAPIService.getAll(Serviceurls.LAB_PATINET_STATUS + "?result_status=Result Inprogress" + "&search_key1=" + this.state.patientName + "&search_key2=" + this.state.mobileNumber + "&from_date=" + this.state.QueryFromDate + "&to_date=" + this.state.QueryToDate)
            .then(response => {
                if (response.data.status == "success") {
                    this.setState({
                        statusSummaryList: response.data.data
                    }, () => {
                        var { statusSummaryList } = this.state
                        statusSummaryList.forEach(data => { data.is_multi_option = false; });
                        this.LoaderFunction(false)
                    })
                } else {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: response?.data?.message
                    })
                    this.LoaderFunction(false)
                }
            }).catch((error) => {
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: error?.response?.data?.message
                })
                this.LoaderFunction(false)
            })
    }

    // // prev api call for post sample
    // onClickSavedChanges = () => {
    //     var { selectedTableRow } = this.state
    //     try {
    //         var data = {
    //             "result_summary_id": selectedTableRow.id,
    //             "out_source": this.state.outSourceOptionList,
    //             "extra_consumables": this.state.sampleCollectedIn,
    //             "consumables": this.state.consumableList
    //         }
    //         RestAPIService.create(data, Serviceurls.LAB_PATENT_SPECIMAN_COLLECTED)
    //             .then(response => {
    //                 if (response.data.status == "success") {
    //                     var data = response.data.data
    //                     this.getStatusSummaryList()
    //                     this.setState({
    //                         isSuccessMsg: true,
    //                         isSuccessMsgText: response.data.message,
    //                         isSpecimanClick: false
    //                     })
    //                 }

    //             }).catch(e => {
    //                 this.setState({
    //                     isErrorMsg: true,
    //                     isErrorMsgText: e.response.data.message
    //                 })
    //             })
    //     }
    //     catch (e) {
    //         this.setState({
    //             isErrorMsg: true,
    //             isErrorMsgText: e.message
    //         })
    //     }
    // }

    // collect data from the table to collected sample table api call
    onClickSavedChanges = () => {
        var { selectedSamples, selectedTableRow } = this.state
        const { consumableList, extra_consumablesList, selectedConsumableList } = this.state;
        var test_data = selectedSamples
        var dateTime = "yyyy-MM-dd" + " " + "HH:mm:ss"
        var data = {
            "result_summary_id": selectedTableRow.id,
            "test_data": test_data,
            "specimen_collected_date": DateTime.now().toFormat(dateTime),
            "consumables": consumableList.map(({ id, ...rest }) => ({ ...rest })),
            "extra_consumables": extra_consumablesList.map(({ id, ...rest }) => ({ ...rest })),
        }
        try {
            RestAPIService.create(data, Serviceurls.LAB_PRE_SPECIMAN_COLLECTED)
                .then(response => {
                    if (response.data.status == "success") {
                        var data = response.data.data
                        this.getStatusSummaryList()
                        this.getPostSpecimanCollectedValue()
                        this.state.tabValue = '2'
                        this.setState({
                            isSuccessMsg: true,
                            isSuccessMsgText: response.data.message,
                            // isSpecimanClick: false
                            // hasSelection: false,
                            selectedSamples: []
                        })
                    }
                }).catch(e => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: e.response.data.message
                    })
                })
        }
        catch (e) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message
            })
        }
    }

    // finish the specimen collection process
    onFinishSavedChanges = () => {
        const { selectedTableRow, consumablesList, extra_consumablesList, selectedConsumableList } = this.state;

        // Combine the existing extra_consumablesList with the form values from selectedConsumableList
        const finalExtraConsumables = [
            ...extra_consumablesList,
            ...selectedConsumableList
        ];

        const data = {
            result_summary_id: selectedTableRow.id,
            consumables: consumablesList.map(({ id, ...rest }) => ({ ...rest })), // Exclude 'id'
            extra_consumables: finalExtraConsumables.map(({ id, stock_in_qty, product_name, material_type, product_type, isUserAdded, ...rest }) => ({ ...rest })), // Exclude 'id',stock_in_qty, product_name, material_type, product_type, isUserAdded,
        };

        // console.log(JSON.stringify(data, null, 2));

        try {
            RestAPIService.create(data, Serviceurls.LAB_TEST_CONSUMABLE)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.getStatusSummaryList();
                        this.getCurrentTestConsumableValue();
                        this.specimanClose();
                        this.setState({
                            isSuccessMsg: true,
                            isSuccessMsgText: response.data.message,
                            isSpecimanClick: false,
                            selectedConsumableList: [],
                            extra_consumablesList: [],
                        });
                    }
                })
                .catch((e) => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: e.response?.data?.message || "An error occurred",
                    });
                });
        } catch (e) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message,
            });
        }
    };


    onCancelPost = () => {
        try {
            var data = {
                "bill_summary_id": this.state.billSummaryID,
                "cancel_reason": this.state.reason,
                "appointment_id": this.state.selectedTableRow.appointment_id ? this.state.selectedTableRow.appointment_id : null,
                "ip_admission_id": this.state.selectedTableRow.ip_admission_id ? this.state.selectedTableRow.ip_admission_id : null,
                "ip_number": this.state.selectedTableRow.ip_number ? this.state.selectedTableRow.ip_number : null
            }
            if (this.state.reason == "") {
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: "Please enter the bill cancel reason"
                })
            } else {
                RestAPIService.create(data, Serviceurls.LAB_CANCEL_BILL)
                    .then(response => {
                        if (response.data.status == "success") {
                            var datas = response.data
                            this.setState({
                                isSuccessMsgText: datas.message,
                                isSuccessMsg: true,
                                isCancelBill: false,
                                anchorEl: false,
                                reason: ""
                            }, () => {
                                this.getStatusSummaryList()
                            })
                        }
                    }).catch(e => {
                        this.setState({
                            isErrorMsg: true,
                            isErrorMsgText: e.message
                        })
                    })
            }
        }
        catch (e) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message
            })
        }
    }
    onDeleteBill = () => {
        try {
            let serviceUrl = Serviceurls.LAB_CANCEL_BILL + "?bill_summary_id=" + parseInt(this.state.billSummaryID)
            RestAPIService.delete(serviceUrl, {})
                .then(response => {
                    if (response.data.status == "success") {
                        var datas = response.data
                        this.setState({
                            isSuccessMsgText: datas.message,
                            isSuccessMsg: true,

                        }, () => {
                            this.props.getSavedBillStatus(true)
                            this.props.getTransmitBillCount(true)
                            this.getStatusSummaryList()
                        })
                    }
                }).catch(e => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: e.message
                    })
                })
        }
        catch (e) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message
            })
        }
    }
    renderSpecimanBottomView = (header, value) => {
        return (
            <div className='Lab_status_speciman_bottom'>
                <p className='Lab_Status_Header1'>{header}</p>
                <p className='Lab_Status_split'>{" : "}</p>
                <p className='Lab_Status_Value'>{value}</p>
            </div>
        )
    }
    renderTextField = (title, value, key, flex) => {
        const { t } = this.props
        return (
            <div className='consumable_container'>
                {key == "consumablesName" ?
                    <Autocomplete
                        disabled={this.state.selectedTableRow.status == "Specimen Collected" ? true : false}
                        options={this.state.specimanSuggessionList}
                        getOptionLabel={(options) => options.product_name}
                        onChange={(e, value) => {

                            this.setState({
                                consumableUnit: value ? value.unit : value,
                                selectConsumables: value,
                                consumableValue: ""
                            })
                        }}
                        value={this.state.selectConsumables}
                        id="combo-box-demo"
                        sx={{ width: "10vw" }}
                        renderInput={(params) => <TextField {...params} label={t("Consumables")} size='small' />}
                    /> :
                    < TextField
                        disabled={(this.state.selectedTableRow.status == "Specimen Collected") || key === "consumableUnit" || this.state.selectConsumables == null ? true : false}
                        name={title}
                        className="consumablesField"
                        sx={{}}
                        size="small"
                        inputProps={{
                            style: {
                                height: "1vh",
                                width: '4vw',
                                marginLeft: "0.4vw"
                            },
                        }}
                        placeholder={t(title)}
                        value={value}
                        onChange={(e) => {
                            var states = this.state
                            if (key == "consumableValue") {
                                var { selectConsumables } = this.state
                                let data = e.target.value
                                if (selectConsumables.stock_in_qty && (+data <= selectConsumables?.stock_in_qty) || data == "") {
                                    states[key] = e.target.value
                                    this.setState({
                                        states
                                    })
                                }
                            } else {
                                states[key] = e.target.value
                                this.setState({ states })
                            }
                        }}
                        type={"text"}
                    />
                }
            </div>

        )
    }
    clearData = () => {
        this.setState({
            outSourceOptionList: JSON.parse(this.state.resetSourceOptionList),
            sampleCollectedIn: [],
            consumablesName: "",
            consumableValue: "",
            consumableUnit: "",
            selectConsumables: null
        })
    }
    validCheck = (data) => {
        var { sampleCollectedIn } = this.state
        var duplicate = false
        if (sampleCollectedIn?.length > 0) {
            for (let i = 0; i < sampleCollectedIn.length; i++) {
                if (sampleCollectedIn[i].product_ms_id == data.product_ms_id) {
                    duplicate = true
                }
                if (sampleCollectedIn[i].product_ms_id == data.product_ms_id) {
                    duplicate = true
                }
            }
        }
        return duplicate
    }
    dateConvertion = (d) => {
        var dateForm = new Date(d)
        let data = DateTime.fromJSDate(dateForm).toFormat("dd-MM-yyyy")
        return data
    }
    testReportPrint = () => {
        RestAPIService.getAll(Serviceurls.LAB_TEST_REPORT_PRINT + "?export_type=pdf").
            then((response) => {
                const file = new Blob(
                    [response.data],
                    { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            }).catch((error) => {
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: error.reponse.data.message
                })
            })
    }
    individualReportPrint = (id) => {
        try {
            RestAPIService.getAll(Serviceurls.LAB_PATIENT_RESULT_PRINT_HISTORY + "?result_summary_id=" + id)
                .then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }).catch((error) => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: error.response.data.message
                    })
                })
        } catch (e) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message
            })
        }
    }
    CrebillPosted(message = "", receiptNO = null, phrReceipt = null, error = false) {
        this.setState({
            CreditBillPopUp: false,
            receiptID: receiptNO,
            pharmareceipt: phrReceipt

        }, () => {
            if (error) {
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: message,
                    anchorEl: false
                })
            } else {
                this.setState({
                    isSuccessMsg: true,
                    isSuccessMsgText: message,
                    anchorEl: false
                })
            }
            this.getStatusSummaryList()
            this.getStatusSpecimanList()
        })
    }
    ClosesuccessPopUp = () => {
        this.setState({
            receiptID: null,
            pharmareceipt: null
        })
    }
    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }
    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }
    renderStatusTable = () => {
        try {
            let filtereddata = this.state.statusSummaryList.filter((data) => {
                return (
                    (data?.patient_name === null ? null : data?.patient_name.toLowerCase().includes(this.state.TableSearchText.toLowerCase()))
                );
            });
            filtereddata?.slice(
                this.state.tblPage * this.state.tblPageSize,
                this.state.tblPage * this.state.tblPageSize +
                this.state.tblPageSize)?.forEach((element, index) => {
                    let page = this.state.tblPage
                    let pageSize = this.state.tblPageSize
                    let serial = (page * pageSize) + (index + 1)
                    element.serial = serial
                })
            return (
                <Box component={'div'} border={'1px solid lightgray'} height={'67vh'} overflow={'auto'}>
                    <Box component={'div'} height={'57vh'} overflow={'auto'}>
                        <Table stickyHeader size='small'>
                            <TableHead>
                                <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>S.No</TableCell>
                                <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Patient Details</TableCell>
                                <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Mobile Number</TableCell>
                                <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Module Type</TableCell>
                                <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Total Test</TableCell>
                                <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }} />
                            </TableHead>
                            <TableBody>
                                {filtereddata?.length > 0 && filtereddata?.slice(
                                    this.state.tblPage * this.state.tblPageSize,
                                    this.state.tblPage * this.state.tblPageSize +
                                    this.state.tblPageSize)?.map((item, index) => (
                                        <><TableRow key={index}
                                            onClick={() => {
                                                this.setState({
                                                    tableSelectedIndex: this.state.tableSelectedIndex === index ? null : index
                                                })
                                            }}
                                        >
                                            <TableCell>{item?.serial}</TableCell>
                                            <TableCell>
                                                <div style={{ fontSize: '0.9vw', fontWeight: '600' }}>{`${item?.patient_name} ${item?.patient_age}`}</div>
                                                <div style={{ fontSize: '0.8vw', color: Colors.grayShade }}>{`${item?.patient_account_number ? item?.patient_account_number : '-'} | ${item?.patient_tag_name ? item?.patient_tag_name : '-'}`}</div>
                                            </TableCell>
                                            <TableCell>{item?.patient_mobile}</TableCell>
                                            <TableCell>{item?.bill_type}</TableCell>
                                            <TableCell>{`${item?.completed_test} / ${item?.total_test}`}</TableCell>
                                            <TableCell>
                                                <IconButton
                                                    size='small'
                                                    onClick={() => {
                                                        this.setState({
                                                            tableSelectedIndex: this.state.tableSelectedIndex === index ? null : index
                                                        })
                                                    }}
                                                >
                                                    {this.state.tableSelectedIndex === index ?
                                                        <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                            <TableRow>
                                                {this.renderStatusSecondTable(item, index)}
                                            </TableRow></>
                                    ))}
                            </TableBody>
                            {
                                filtereddata?.length === 0 ?
                                    <TableBody>
                                        <TableRow>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell><Typography sx={{ marginTop: "11vh", marginBottom:"36vh", width: "100%" , textAlign : "center"}}>{"No Records Found"}</Typography></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableBody>
                                    : null
                            }
                        </Table>
                    </Box>

                    <TablePagination
                        className='eMed_tbl_pagination'
                        sx={{ borderTop: '1px solid lightgray' }}
                        component='div'
                        rowsPerPageOptions={[10, 20, 30]}
                        count={filtereddata?.length}
                        rowsPerPage={this.state.tblPageSize}
                        page={this.state.tblPage}
                        onPageChange={(event, newPage) => { this.setState({ tblPage: newPage }) }}
                        onRowsPerPageChange={(event) => { this.setState({ tblPageSize: event.target.value, tblPage: 0 }) }}
                    />
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }
    disableMoreOptions(item) {
        let { selectedTableRow } = this.state
        switch (item?.value) {
            case "ReceivePayment":
                if (selectedTableRow?.payment_status === 'paid') {
                    return true
                } else {
                    return false
                }
            case "ModifyBill":
                if (selectedTableRow?.bill_type === "credit" && selectedTableRow?.payment_status === "not paid" && selectedTableRow?.status !== "Specimen Collected") {
                    return false
                } else {
                    return true
                }
            default: return false
        }
    }
    renderMenu = (secTblItem) => {
        try {
            let { selectedTableRow } = this.state
            let RoleData = localGetItem("loggedInUserInfo") === null ? {} : JSON.parse(localGetItem("loggedInUserInfo"))
            let ModifyBillAccess = RoleData?.is_user ? (RoleData?.permission_access?.common?.modify_credit_bill ? RoleData?.permission_access?.common?.modify_credit_bill : false) : true
            let cancelBillApproval = RoleData?.is_user ? (RoleData?.permission_access?.common?.cancel_bill_approval ? RoleData?.permission_access?.common?.cancel_bill_approval : false) : true
            let menuList = [
                { "value": "ViewPreviousReport", "label": "View Previous Report" },
                { "value": "ReceivePayment", "label": "Receive Payment" },
                { "value": "BarCode", "label": "Common Barcode" },
                { "value": "ModifyBill", "label": "Modify Bill" },  // will be sliced when no access
                { "value": "ModifyHistory", "label": "Modify History" },
                { "value": "CancelBill", "label": "Cancel Bill" },
                // { "value": "DeleteBill", "label": "Delete Bill" }
            ]
            if (!ModifyBillAccess) {
                menuList.splice(3, 1);
            }
            if (!cancelBillApproval || secTblItem?.status !== "Result Inprogress") {
                menuList.pop();
            }
            return (
                <Menu
                    id='long-menu'
                    MenuListProps={{
                        'aria-labelledby': 'long-button',
                    }}
                    anchorEl={this.state.anchorEl}
                    open={Boolean(this.state.anchorEl) && secTblItem?.id === this.state.selectedTableRow?.id}
                    onClose={(e) => {
                        this.setState({
                            anchorEl: null
                        })
                    }}
                    elevation={2}
                >
                    {menuList?.map((item, index) => (
                        <MenuItem key={index}
                            disabled={this.disableMoreOptions(item)}
                            onClick={() => {
                                this.setState({
                                    selectedOption: item.value
                                }, () => {
                                    if (this.state.selectedOption == "ReceivePayment") {
                                        // this.props.selectedTab(0, this.state.selectedTableRow, "isReceivePayment", false)
                                        this.setState({ SelectedBill: this.state.selectedTableRow, CreditBillPopUp: true })
                                    }
                                    if (this.state.selectedOption == "ViewPreviousReport") {
                                        this.setState({
                                            isViewReport: true
                                        })
                                    }
                                    if (this.state.selectedOption == "BarCode") {
                                        this.setState({
                                            SelectedBill: this.state.selectedTableRow
                                        }, () => this.getCommonBarCodePrint(secTblItem))
                                    }
                                    if (this.state.selectedOption == "CancelBill") {
                                        let Data = {
                                            id: this.state.selectedTableRow?.lab_bill_summary_id,
                                            patient_id: this.state.selectedTableRow?.patient_id,
                                            laboratory_id: null,
                                            amount_net: this.state.selectedTableRow?.amount_net,
                                            appointment_id: this.state.selectedTableRow?.appointment_id,
                                            ip_admission_id: this.state.selectedTableRow?.ip_admission_id
                                        }

                                        this.props.cancelBillClick(Data)
                                    }
                                    if (this.state.selectedOption == "DeleteBill") {
                                        this.onDeleteBill()
                                    }
                                    if (this.state.selectedOption == "ModifyBill") {
                                        let Data = {
                                            id: this.state.selectedTableRow?.lab_bill_summary_id,
                                            patient_id: this.state.selectedTableRow?.patient_id,
                                            laboratory_id: null,
                                            amount_net: this.state.selectedTableRow?.amount_net,
                                            appointment_id: this.state.selectedTableRow?.appointment_id,
                                            ip_admission_id: this.state.selectedTableRow?.ip_admission_id,
                                            isForEditBill: true
                                        }
                                        this.props.cancelBillClick(Data)
                                    }
                                    if (this.state.selectedOption == "ModifyHistory") {
                                        this.setState({ BillModifyHistoryItem: this.state.selectedTableRow })
                                    }
                                })
                            }}
                        >{item?.label}</MenuItem>
                    ))}
                </Menu>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }
    renderStatusSecondTable = (item, index) => {
        try {
            return (
                <><TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                    <Collapse in={this.state.tableSelectedIndex === index} timeout={'auto'}>
                        <Box component={'div'} margin={1} className='eMed_Lab_Sts_Tbl_Two'>
                            <Table size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Date</TableCell>
                                        <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Bill No</TableCell>
                                        <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Bill Type</TableCell>
                                        <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Barcode Number</TableCell>
                                        <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Result Status</TableCell>
                                        <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Total Test</TableCell>
                                        <TableCell sx={{ fontSize: '0.9vw', fontWeight: 600, backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {item?.data?.map((secTblItem, secTblIndex) => (
                                        <TableRow key={secTblIndex} sx={{ backgroundColor: secTblItem?.bill_type === "cancelled" ? Colors.disableComponentColor : "white" }}>
                                            <TableCell>{secTblItem?.created_date__date}</TableCell>
                                            <TableCell>{secTblItem?.bill_number}</TableCell>
                                            <TableCell>
                                                <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignContent: 'center', flexDirection: 'column' }}>
                                                    <Typography sx={{ fontWeight: 600, fontSize: "0.875rem", textTransform: 'capitalize' }}>{secTblItem?.bill_type ? secTblItem?.bill_type : "-"}</Typography>
                                                    <Typography sx={{ fontSize: "0.875rem", textTransform: 'capitalize' }}>{secTblItem?.payment_status ? secTblItem?.payment_status : "-"}</Typography>
                                                </Box>
                                            </TableCell>
                                            <TableCell>{secTblItem?.barcode}</TableCell>
                                            <TableCell sx={{ color: secTblItem?.bill_type === "cancelled" ? Colors.red : "black" }}>{secTblItem?.bill_type === "cancelled" ? "Cancelled" : secTblItem?.status}</TableCell>
                                            <TableCell>{` ${secTblItem?.completed_test} / ${secTblItem?.total_test}`}</TableCell>
                                            <TableCell>
                                                <Box component={'div'} display={'flex'} alignItems={'center'}>
                                                     <Tooltip placement='top' title={'Specimen'}>
                                                            <IconButton
                                                            className='Common_Btn_Min_Width'
                                                            disabled={!CheckAccessFunc("Laboratory", "Home", "Status", null, "Tab")?.editAccess || secTblItem?.bill_type === "cancelled"}
                                                            onClick={() => {
                                                                if (secTblItem?.bill_type === "cancelled") {
    
                                                                } else {
                                                                    this.setState({
                                                                        isSpecimanClick: true,
                                                                        selectedTableRow: secTblItem,
                                                                        stepNumber: 0,
                                                                        tabValue: secTblItem?.status == "Specimen Collected" ? '2' : '1',
                                                                    }, () => {
                                                                        this.getPreSpecimanCollectedValue(secTblItem?.id, true)
                                                                        this.getPostSpecimanCollectedValue(secTblItem?.id)
                                                                        this.getCurrentTestConsumableValue(secTblItem?.id);
                                                                    })
                                                                }
                                                            }}
                                                            >
                                                            <Box component={'img'} src={secTblItem?.status !== "Specimen Collected" ? ImagePaths.Speciman.default : ImagePaths.ResultDisable.default} height={'1.8vw'} width={'1.8vw'} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    <IconButton className='Common_Btn_Min_Width'
                                                        disabled={!CheckAccessFunc("Laboratory", "Home", "Status", null, "Tab")?.editAccess || secTblItem?.bill_type === "cancelled"}
                                                        onClick={() => {
                                                            if (secTblItem?.status !== "Specimen Collected") {
                                                                if (secTblItem?.bill_type !== "cancelled") {
                                                                    this.errorMessage('Kindly collect the specimen before entering the result')
                                                                }
                                                            } else {
                                                                // this.props.selectedTab()
                                                            }
                                                        }}
                                                    >
                                                        {secTblItem?.status === "Specimen Collected" ?
                                                            <Link to={{ pathname: "/Results", state: { resultList: secTblItem } }}>
                                                                <Box component={'img'} src={ImagePaths.Result.default} height={'1.8vw'} width={'1.8vw'} position={'relative'} top={'0.2vw'} />
                                                            </Link> :
                                                            <Box component={'div'}>
                                                                <Box component={'img'} src={ImagePaths.Result.default} height={'1.8vw'} width={'1.8vw'} position={'relative'} top={'0.2vw'} />
                                                            </Box>
                                                        }
                                                    </IconButton>
                                                    <Button className='Common_Btn_Min_Width'
                                                        aria-label="more"
                                                        id="long-button"
                                                        aria-controls={this.state.isSelected ? 'long-menu' : undefined}
                                                        aria-expanded={this.state.isSelected ? 'true' : undefined}
                                                        aria-haspopup="true"
                                                        disabled={CheckAccessFunc("Laboratory", "Home", "Status", null, "Tab")?.editAccess ? secTblItem?.bill_type == "cancelled" ? true : false : true}
                                                        onClick={(e) => {
                                                            this.setState({
                                                                anchorEl: e.currentTarget,
                                                                billSummaryID: secTblItem?.lab_bill_summary,
                                                                selectedTableRow: secTblItem,
                                                                isSelected: true
                                                            }, () => {
                                                                this.getPreviousReport()
                                                                // this.getReceivePaymentAmountData(secTblItem)
                                                            })

                                                        }}
                                                    >
                                                        <MoreVertIcon sx={{ color: 'black' }} />
                                                    </Button>
                                                </Box>
                                                {this.renderMenu(secTblItem)}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell></>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    HistoryPopClose() { this.setState({ BillModifyHistoryItem: {} }) }

    // specimen pop up functions and reuseable codes
    // close specimen pop up func
    handleCloseDialog = () => {
        this.setState({ isSpecimanClick: false, stepNumber: 0, tabValue: '1' });
    };
    // tab change event
    handleTabChange = (event, newValue) => {
        this.setState({ tabValue: newValue, 
            // hasSelection: false
         });
    };
    // collecting the row function
    handleSelectionChange = (specimenType, container, tests) => (newSelection) => {
        const selectedRows = newSelection.map(id => {
            const test = tests.find(test => test.result_item_id === id);
            return {
                id: test.result_item_id
            };
        });
    
        
        this.setState((prevState) => {
            const updatedSamples = [...prevState.selectedSamples];
            const existingIndex = updatedSamples.findIndex(
                (sample) => {                        
                    return sample.specimen === specimenType
                }
            );
            // checking the selected values
            // const hasSelection = updatedSamples?.length > 0 ? true : false;
            
            if (newSelection.length === 0) {
                // If no rows are selected for this specimenType, remove it entirely
                return {
                    selectedSamples: updatedSamples.filter(
                        (sample) => sample.specimen !== specimenType
                    ),
                    // hasSelection,
                };
            }
            
            if (existingIndex !== -1) {
                // If specimenType already exists, update its tests
                updatedSamples[existingIndex] = {
                    specimen: specimenType,
                    container,
                    tests: selectedRows,
                };
            } else {
                // Otherwise, add a new entry
                updatedSamples.push({
                    specimen: specimenType,
                    container,
                    tests: selectedRows,
                });
            }
                 
            return {
                selectedSamples: updatedSamples,
                // hasSelection,
            };
        });
    };
    

    handleConsumableChange = (event, newValue) => {
        this.setState({
            consumablesName: newValue,
            unit: newValue ? newValue.unit : "",
        });
    };

    handleQuantityChange = (event) => {
        this.setState({ quantity: event.target.value });
    };

    handleAddConsumable = () => {
        const { consumablesName, quantity, selectedConsumableList } = this.state;

        if (consumablesName && quantity) {
            const newConsumable = {
                id: selectedConsumableList.length + 1, // Unique ID for the DataGrid
                ...consumablesName,
                brand_name: consumablesName.product_name,
                quantity,
                isUserAdded: true,
            };

            // Add the new consumable to both selectedConsumableList and extra_consumablesList
            this.setState((prevState) => ({
                selectedConsumableList: [...prevState.selectedConsumableList, newConsumable],
                consumablesName: null,
                quantity: "",
                unit: "",
            }));
        } else {
            if(!consumablesName){
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: "Please add Consumables"
                })
            } else {
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: "Please Enter Quantity"
                })
            }
        }
    };

    // render table function for both sample and specimen collected table
    renderDataTable = (type, container, tests, selectionTableType = null, selection) => {
        const columns = [
            {
                field: 'groupName',
                headerName: 'Group Name',
                flex: 1,
                renderCell: ({ row }) => (
                    <div>
                        {row?.groupName?.length > 15 ? (
                            <Tooltip placement="top" title={row?.groupName} arrow>
                                <div>{row?.groupName.slice(0, 14) + "..."}</div>
                            </Tooltip>
                        ) : row?.groupName ? (
                            row?.groupName
                        ) : (
                            "-"
                        )}
                    </div>
                ),
            },
            {
                field: 'testName',
                headerName: 'Test Name',
                flex: 1,
                renderCell: ({ row }) => (
                    <div>
                        {row?.testName?.length > 16 ? (
                            <Tooltip placement="top" title={row?.testName} arrow>
                                <div>{row?.testName.slice(0, 15) + "..."}</div>
                            </Tooltip>
                        ) : row?.testName ? (
                            row?.testName
                        ) : (
                            "-"
                        )}
                    </div>
                ),
            },
            {
                field: 'method',
                headerName: 'Method',
                flex: 1,
                align: "center",
                headerAlign: "center",
                renderCell: ({ row }) => (
                    <div>
                        {row?.method?.length > 16 ? (
                            <Tooltip placement="top" title={row?.method} arrow>
                                <div>{row?.method.slice(0, 15) + "..."}</div>
                            </Tooltip>
                        ) : row?.method ? (
                            row?.method
                        ) : (
                            "-"
                        )}
                    </div>
                ),
            },
            {
                field: 'quantity',
                headerName: 'Quantity',
                flex: 1,
                align: "center",
                headerAlign: "center",
                renderCell: ({ row }) => (
                    <div>
                        {row?.quantity?.length > 16 ? (
                            <Tooltip placement="top" title={row?.quantity} arrow>
                                <div>{row?.quantity.slice(0, 15) + "..."}</div>
                            </Tooltip>
                        ) : row?.quantity ? (
                            row?.quantity
                        ) : (
                            "-"
                        )}
                    </div>
                ),
            },
            {
                field: 'temperature',
                headerName: 'Temperature',
                flex: 1,
                align: "center",
                headerAlign: "center",
                renderCell: ({ row }) => (
                    <div>
                        {row?.temperature?.length > 16 ? (
                            <Tooltip placement="top" title={row?.temperature} arrow>
                                <div>{row?.temperature.slice(0, 15) + "..."}</div>
                            </Tooltip>
                        ) : row?.temperature ? (
                            row?.temperature
                        ) : (
                            "-"
                        )}
                    </div>
                ),
            },
            {
                field: 'cot',
                headerName: 'COT',
                flex: 1,
                align: "center",
                headerAlign: "center",
                renderCell: ({ row }) => (
                    <div>
                        {row?.cot?.length > 16 ? (
                            <Tooltip placement="top" title={row?.cot} arrow>
                                <div>{row?.cot.slice(0, 15) + "..."}</div>
                            </Tooltip>
                        ) : row?.cot ? (
                            row?.cot
                        ) : (
                            "-"
                        )}
                    </div>
                ),
            },
            {
                field: 'labType',
                headerName: 'Lab Type',
                flex: 1,
                renderCell: ({ row }) => (
                    <div>
                        {row?.labType?.length > 20 ? (
                            <Tooltip placement="top" title={row?.labType} arrow>
                                <div>{row?.labType.slice(0, 19) + "..."}</div>
                            </Tooltip>
                        ) : row?.labType ? (
                            row?.labType
                        ) : (
                            "-"
                        )}
                    </div>
                ),
            },
        ];
    
        const getRows = tests.map((test) => ({
            id: test.result_item_id,
            ...test,
        }));
    
        return (
            <Paper sx={{ width: '100%', marginBottom: '1vw' }}>
                <DataGrid
                    rows={getRows}
                    columns={columns}
                    autoHeight
                    checkboxSelection={selectionTableType}
                    disableSelectionOnClick={selection}
                    onSelectionModelChange={this.handleSelectionChange(type, container, tests)}
                    density="compact"
                    rowHeight={60}
                    hideFooter
                />
            </Paper>
        );
    };    

    handleDeleteConsumable = (id) => {
        this.setState((prevState) => ({
            selectedConsumableList: prevState.selectedConsumableList.filter(item => item.id !== id),
        }));
    };


    render() {
        const { t } = this.props
        // patient information function for rendering patient information in common patient component
        const patientInfo = (count) => {
            return (
                <>
                    {/* <Typography fontWeight={600} sx={{ color: "grey", textTransform: "uppercase", marginBottom: "1vh", fontSize: "0.8rem" }}>Patient Information</Typography> */}
                    <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "12vh" }}>
                        <CommonPatientDetails data={this.state.patientDetails} />

                        {/* Display IP if present; otherwise, display OP if present */}
                        {this.state.patientDetails?.ip_number ? (
                            <Box>
                                <Typography fontWeight={500} sx={{ color: "black", fontSize: "0.8vw", fontWeight: "bold" }}>IP Number</Typography>
                                <Typography sx={{ fontSize: "0.8vw" }}>{this.state.patientDetails.ip_number}</Typography>
                            </Box>
                        ) : this.state.patientDetails?.op_number ? (
                            <Box>
                                <Typography fontWeight={500} sx={{ color: "black", fontSize: "0.8vw", fontWeight: "bold" }}>OP Number</Typography>
                                <Typography sx={{ fontSize: "0.8vw" }}>{this.state.patientDetails.op_number}</Typography>
                            </Box>
                        ) : null}

                        {/* Display Bill Number */}
                        {
                             
                                <Box>
                                    <Typography fontWeight={500} sx={{ color: "black", fontSize: "0.8vw", fontWeight: "bold" }}>Bill Number</Typography>
                                    <Typography sx={{ fontSize: "0.8vw" }}>{this.state.patientDetails?.invoice_number || "N/A"}</Typography>
                                </Box> 
                        }

                        {
                            this.state.stepNumber == 1 ?
                                <Box>
                                    <Typography fontWeight={500} sx={{ color: "black", fontSize: "0.8vw", fontWeight: "bold" }}>Patient Type</Typography>
                                    <Typography sx={{ fontSize: "0.8vw" }}>{this.state.patientDetails?.patient_type || "N/A"}</Typography>
                                </Box> : null
                        }

                        {/* Display Number of Tests */}
                        <Box>
                            <Typography fontWeight={500} sx={{ color: "black", fontSize: "0.8vw", fontWeight: "bold" }}>No of Test</Typography>
                            <Typography sx={{ fontSize: "0.8vw" }}>{this.state.stepNumber !== 1 ? count + "/" + this.state.patientDetails?.total_no_of_test : this.state.patientDetails?.total_no_of_test}</Typography>
                        </Box>

                        {/* Display Payment Status */}
                        {this.state.stepNumber !== 1 ?
                            <Box>
                                <Typography fontWeight={500} sx={{ color: "black", fontSize: "0.8vw", fontWeight: "bold" }}>Payment Status</Typography>
                                <div style={{ display: "flex", gap: "1vh" }}>
                                    <Typography sx={{ fontSize: "0.9rem", textTransform:"capitalize" , color: this.state.patientDetails?.payment_status == 'paid' ? "green" : this.state.patientDetails?.payment_status == 'not paid' ? "red" : this.state.patientDetails?.payment_status == 'partial' ? "orange" : null }}>
                                        {this.state.patientDetails?.payment_status}
                                    </Typography>
                                </div>
                            </Box> : null
                        }
                    </Box >

                </>
            )
        }

        // consumable header
        const consumableColumns = [
            { field: 'brand_name', headerName: 'Consumables', flex: 1 },
            { field: 'quantity', headerName: 'Quantity', flex: 1 },
            { field: 'unit', headerName: 'Unit', flex: 1 },
            {
                field: 'action',
                headerName: 'Action',
                flex: 1,
                renderCell: (params) => (
                    params.row.isUserAdded ? (
                        <Button
                            sx={{ color: 'red' }}
                            onClick={() => this.handleDeleteConsumable(params.row.id)} 
                        >
                            <IconButton size='small' sx={{ color: "red" }}>
                                <Tooltip title={'Delete'} placement={'top'} arrow>
                                    <DeleteIcon/>
                                </Tooltip>
                            </IconButton>
                        </Button>
                    ) : <Button
                        sx={{ color: 'gray' }}
                        disabled
                    >
                        <IconButton size='small'>
                            <Tooltip title={'Delete'} placement={'top'} arrow>
                                <DeleteIcon />
                            </Tooltip>
                        </IconButton>
                    </Button>
                ),
            },
        ];

        if (!this.state.isSpecimanClick) {
            this.state.selectedConsumableList = []
            this.state.consumableTableList = []
            this.state.consumablesName = null
            this.state.name = ''
            this.state.quantity = ''
            this.state.unit = ''
        }

        function formatDateTime(dateTimeString) {
            const dateObj = new Date(dateTimeString);
        
            // Format the date (DD-MM-YYYY)
            const day = String(dateObj.getDate()).padStart(2, '0');
            const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are 0-based
            const year = dateObj.getFullYear();
        
            // Format the time (12-hour format with AM/PM)
            let hours = dateObj.getHours();
            const minutes = String(dateObj.getMinutes()).padStart(2, '0');
            const seconds = String(dateObj.getSeconds()).padStart(2, '0');
            const amPm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12 || 12; // Convert to 12-hour format
        
            // Combine the formatted date and time
            return `${day}-${month}-${year} ${hours}:${minutes}:${seconds} ${amPm}`;
        }
        
        





        return (
            <div className='Lab_Status_Container' >
                <div className='lab_status_sub_header'>
                    <div className='eMed_sts_date'>
                        <Box className="eMed_Search_Box_">
                            <TextField
                                variant='standard'
                                placeholder='Search'
                                value={this.state.TableSearchText}
                                onChange={(e) => { this.setState({ TableSearchText: e.target.value }) }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{ width: '15vw' }} />
                        </Box>
                        <FormControl >
                            <InputLabel>{t("SelectDate")}</InputLabel>
                            <Select sx={{ width: "10vw" }}
                                size='small'
                                onChange={(event) => { this.setState({ dateFilter: event.target.value }, () => this.handleDate()) }}
                                value={this.state.dateFilter}
                                labelId="demo-simple-select-required-label"
                                label={t("SelectDate")}
                            >
                                {/* <MenuItem value={0}>{t("All")}</MenuItem> */}
                                <MenuItem value={1}>{t("Today")}</MenuItem>
                                <MenuItem value={2}>{t("Yesterday")}</MenuItem>
                                <MenuItem value={3}>{t("LastWeek")}</MenuItem>
                                <MenuItem value={4}>{t("LastMonth")}</MenuItem>
                                <MenuItem value={5} onClick={() => { this.setState({ datePickerPop: true }) }} >{t("Custom")}</MenuItem>
                            </Select>
                            <FormHelperText id="eMed_form_helpertext">{this.DateHelperText()}</FormHelperText>
                        </FormControl>
                    </div>
                    {this.state.datePickerPop ? <DatePicker PopClose={this.PopClose} CustomDates={this.CustomDates} /> : null}
                    {/* <button className= "lab_status_printBtn" ><img className="Lab_Status_Subcontainer_Img"src={ImagePaths.PrintOff.default} alt={"print"}
                     onClick={() => {
                        this.testReportPrint()
                     }} 
                    /></button>
                    <button className= "lab_status_printBtn" ><img className="Lab_Status_Subcontainer_Img"src={ImagePaths.ExportOff.default} alt={"export"} /></button> */}
                </div>
                <div className='labDataGrid'>
                    {/* <DataGrid
                        rows={this.state.statusSummaryList}
                        columns={columns}
                        hideFooterSelectedRowCount
                        getRowId={(row) => row.id}
                        // getRowClassName={(params) =>  params.row.lab_bill_summary__status == "Cancelled" ? 'Lab_report_hightlight' : null}
                        pageSize={this.state.pageSize}
                        onPageSizeChange={(newPageSize) => {
                            this.setState({ pageSize: newPageSize })
                        }}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: CommonGridHeader.CommonToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        headerHeight={40}
                    /> */}
                    {this.renderStatusTable()}
                    {this.state.selectedOption == "Cancel Bill" ?
                        <Dialog
                            className="emed_DialogboxOrder"
                            open={this.state.isCancelBill}
                            onClose={() => {
                                this.setState({
                                    isCancelBill: false,
                                    reason: ""
                                })
                            }}
                            maxWidth={"md"}
                        >

                            <div className='Lab_cancel_bill'>
                                <div className='Lab_Status_Close_Button'>
                                    <IconButton size='small'
                                        onClick={() => {
                                            this.setState({
                                                isCancelBill: false,
                                                reason: ""

                                            })
                                        }}
                                    > <img width="2vw" height="2vw" src={ImagePaths.Close.default} className="labCancelStyle" alt='close'></img></IconButton>
                                </div>
                                <h1>{t("Conformation")}</h1>
                                <Typography>{t("ToRemoveBill")}</Typography>
                                <TextField id="outlined-basic" sx={{ height: "10vh", width: "56.5vw" }} inputProps={{
                                    style: {
                                        height: "5vh"
                                    }
                                }} value={this.state.reason} placeholder='Enter the reason' variant="outlined" className='CancelBillComment' onChange={(e) => {
                                    this.setState({
                                        reason: e.target.value
                                    })
                                }} />
                                {/* <h1>{"Refund"}</h1>
                            <div className="Lab_Cancel_Bill_Radio">
                                <p className="Lab_Status_Radio_Text">{t("ModeOfPayment")}</p>    
                                <div className="Lab_Home_Radio_Button1">
                                    <RadioGroup
                                        row
                                        value={this.state.selectedPaymentType}
                                        onClick={(e) => {
                                           
                                        }}
                                    >
                                        <FormControlLabel value={1} control={<Radio size="small" />} label={t("Cash")} />
                                        <FormControlLabel value={2} control={<Radio size="small" />} label={t("Card")} />
                                        <FormControlLabel value={3} control={<Radio size="small" />} label={t("CashandCard")} />
                                        <FormControlLabel value={4}control={<Radio size="small" />} label={t("UPI")} />
                                        <FormControlLabel value={5} control={<Radio size="small" />} label={t("BankTransfer")} />
                                        <FormControlLabel value={6} control={<Radio size="small" />} label={t("Insurance")} />
                                    </RadioGroup>
                                </div>
                            </div> */}
                                <div className="Lab_Cancel_Popup">
                                    <div>
                                        <p className="emedhub_popup_footer_Text emedhub_popup_footer_Text2">{t("CancelHelpMessage1")}</p>
                                        <p className="emedhub_popup_footer_Text emedhub_popup_footer_Text2">{t("CancelHelpMessage2")}</p>
                                    </div>
                                    <Button size="small" sx={{ backgroundColor: "red" }} variant={this.props.buttonTwo ? "outlined" : "contained"} onClick={() => { this.onCancelPost() }}>{"Cancel Bill"}</Button>

                                </div>
                            </div>
                        </Dialog> : null}
                </div>

                {/* ============================================= */}
                {/* speciman collection starts*/}
                {this.state.isSpecimanClick
                    ?
                    <Dialog
                        open={this.state.isSpecimanClick}
                        onClose={() => this.setState({ isSpecimanClick: false, stepNumber: 0, tabValue: '1', selectedSamples: [] })}
                        fullWidth
                        maxWidth='85dvw'
                    >
                        {/* <Box component={"div"} className='eMed_Doc_Notes_Counsellor_Pop_Up'
                        // style={{ width: "80vw", height: "90vh", maxHeight: "100%" }}
                        > */}
                        {/* header */}
                        <div className="sample_specimen_collect_popup">
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <p style={{ fontWeight: 600 }}>{t("Sample Collection")}</p>
                            </div>
                            <img
                                src={ImagePaths.LabCloseButton.default}
                                alt=""
                                onClick={() => this.setState({ isSpecimanClick: false, stepNumber: 0, tabValue: '1', 
                                    selectedSamples: []
                                 })}
                                className="eMed_sts_samp_collect"
                            />
                        </div>

                        {/* stepper */}
                        <Box sx={{ width: "100%", height: "7vh", display: "flex", justifyContent: "center", padding: "1vw", borderBottom: "0.9px solid #e0dede" }}>
                            <Stepper activeStep={this.state.stepNumber} sx={{ width: '50vw' }}>
                                {['Sample Collection', 'Consumable'].map((label, index) => (
                                    <Step key={index}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </Box>

                        {/* Step 1: Sample Collection */}
                        {this.state.stepNumber === 0 && (
                            <Box sx={{ padding: "2vh" }}>
                                {/* patient details */}
                                {
                                    patientInfo(
                                        this.state.tabValue === '1'
                                            ? this.state.specimanSamples?.reduce((total, item) => total + (item.tests?.length || 0), 0)
                                            : this.state.collectedSpecimanSamples?.reduce((total, item) => total + (item.tests?.length || 0), 0)
                                    )
                                }

                                {/* Tabs */}
                                <TabContext value={this.state.tabValue}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: "0.5vh" }}>
                                        <TabList onChange={this.handleTabChange} textColor="secondary" indicatorColor="secondary">
                                            <Tab label="Sample" value="1" sx={{ fontWeight: "600" }} onClick={() => { this.getPreSpecimanCollectedValue() }} />
                                            <Tab label="Collected Sample" value="2" sx={{ fontWeight: "600" }} onClick={() => { 
                                                this.getPreSpecimanCollectedValue()
                                                this.setState({ selectedSamples: [] }) }} disabled={this.state.collectedSpecimanSamples.length > 0 ? false : true}/>
                                        </TabList>
                                    </Box>
                                    <Box sx={{ height: 300, overflow: 'scroll' }}>
                                        {/* tab panel for sample collection */}
                                        <TabPanel value="1" className='specimen_collect_tab'>
                                            {/* Loop through each specimen type */}
                                            {
                                                this.state.specimanSamples.length > 0 ?
                                                    this.state.specimanSamples.map((speciman, index) => {
                                                        return (
                                                            // specimen header
                                                            <Box key={index} sx={{ marginTop: "0.5vh" }}>
                                                                <Typography variant="subtitle1" sx={{ display: "flex", gap: "2vh" }}>
                                                                    <span>
                                                                        <span style={{ color: "black", fontWeight:"bold" }}>Specimen:</span> <span style={{ marginLeft: "1.5vh" }}>
                                                                            {speciman.specimenType ? speciman.specimenType.length > 12 ?
                                                                                <Tooltip placement="top" title={speciman.specimenType} arrow>
                                                                                    <span>{speciman.specimenType.slice(0, 22) + "..."}</span>
                                                                                </Tooltip>
                                                                                : speciman.specimenType ? speciman.specimenType : "-" : "-"}
                                                                        </span>
                                                                    </span>
                                                                    <span>
                                                                        <span style={{ color: "black", fontWeight:"bold" }}>Container:</span> <span style={{ marginLeft: "1.5vh" }}>
                                                                            {speciman.container ? speciman.container.length > 23 ?
                                                                                <Tooltip placement="top" title={speciman.container} arrow>
                                                                                    <span>{speciman.container.slice(0, 22) + "..."}</span>
                                                                                </Tooltip>
                                                                                : speciman.container ? speciman.container : "-" : "-"}
                                                                        </span>
                                                                    </span>
                                                                    <span style={{display:"flex",gap:"2vh",alignItems:"center"}}>
                                                                        <span style={{ color: "black", fontWeight:"bold" }}>Color:</span> 
                                                                        {
                                                                            speciman?.container_color ? 
                                                                                <div className="emed_ColorShowgrid" type="color" name="favourite Color" style={{ backgroundColor: speciman?.container_color ? speciman?.container_color : "N/A", width: "2vw", height: "1.2vw" ,marginLeft :"-0.2vw",marginTop:"0"}} > </div>
                                                                             : 
                                                                                <div>N/A</div>
                                                                        } 
                                                                    </span>
                                                                </Typography>
                                                                {/* sample table for each specimen type */}
                                                                {this.renderDataTable(speciman.specimenType, speciman.container, speciman.tests, true, true)}
                                                            </Box>
                                                        )
                                                    })
                                                    :
                                                    <Typography variant="subtitle1" fontWeight={600} color="primary" >All Specimens are moved to collected Specimen</Typography>
                                            }
                                        </TabPanel>
                                        {/* tab panel for collected sample */}
                                        <TabPanel value="2" className='specimen_collect_tab'>
                                            {
                                                this.state.collectedSpecimanSamples.length > 0 ?
                                                    this.state.collectedSpecimanSamples.map((speciman, index) => {
                                                        return (
                                                            // specimen header
                                                            <Box key={index} sx={{ marginTop: "0.5vh" }}>
                                                                <Typography variant="subtitle1" sx={{ display: "flex",justifyContent:"space-between",alignItems:"center"}}>
                                                                    <div style={{display:"flex",gap:"2vh",alignItems:"center"}}>
                                                                        <span>
                                                                            <span style={{ color: "black", fontWeight:"bold" }}>SID Date & Time:</span> <span style={{ marginLeft: "1.5vh" }}>{speciman.sid_date ? formatDateTime(speciman.sid_date) : "-"}</span>
                                                                        </span>
                                                                        <span>
                                                                            <span style={{ color: "black", fontWeight:"bold" }}>SID No:</span> <span style={{ marginLeft: "1.5vh" }}>{speciman.sid_no ? speciman.sid_no : "-"}</span>
                                                                        </span>
                                                                        <span>
                                                                            <span style={{ color: "black", fontWeight:"bold" }}>Specimen:</span> <span style={{ marginLeft: "1.5vh" }}>
                                                                                {speciman.specimenType ? speciman.specimenType.length > 13 ?
                                                                                    <Tooltip placement="top" title={speciman.specimenType} arrow>
                                                                                        <span>{speciman.specimenType.slice(0, 12) + "..."}</span>
                                                                                    </Tooltip>
                                                                                    : speciman.specimenType ? speciman.specimenType : "-" : "-"}
                                                                            </span>
                                                                        </span>
                                                                        <span>
                                                                            <span style={{ color: "black", fontWeight:"bold" }}>Container:</span> <span style={{ marginLeft: "1.5vh" }}>
                                                                                {speciman.container ? speciman.container.length > 13 ?
                                                                                    <Tooltip placement="top" title={speciman.container} arrow>
                                                                                        <span>{speciman.container.slice(0, 12) + "..."}</span>
                                                                                    </Tooltip>
                                                                                    : speciman.container ? speciman.container : "-" : "-"}
                                                                            </span>
                                                                        </span>
                                                                        <span style={{display:"flex",gap:"2vh",alignItems:"center"}}>
                                                                            <span style={{ color: "black", fontWeight:"bold" }}>Color:</span> 
                                                                            {
                                                                                speciman?.container_color ? 
                                                                                    <div className="emed_ColorShowgrid" type="color" name="favourite Color" style={{ backgroundColor: speciman?.container_color ? speciman?.container_color : "N/A", width: "2vw", height: "1.2vw" ,marginLeft :"-0.2vw",marginTop:"0"}} > </div>
                                                                                : 
                                                                                    <div>N/A</div>
                                                                            } 
                                                                        </span>
                                                                    </div>
                                                                    {/* =================== */}
                                                                    {/* print function */}
                                                                    {/* =================== */}
                                                                    <Button
                                                                        size="small"
                                                                        variant="contained"
                                                                        sx={{ textTransform: 'capitalize', width: '10vw',marginBottom:"0.5vh" }}
                                                                        disabled={this.state.buttonDisabledStates[index]}
                                                                        onClick={() => { this.collectedSamplePrintBarcode(index) }}
                                                                    >
                                                                       Print Barcode
                                                                    </Button>
                                                                </Typography>

                                                                {/* sample table for each specimen type */}
                                                                {this.renderDataTable(speciman.specimenType, speciman.container, speciman.tests, false, true)}
                                                            </Box>
                                                        )
                                                    })
                                                    :
                                                    <Typography variant="subtitle1" fontWeight={600} color="primary" >No Specimens have been to collected</Typography>
                                            }
                                        </TabPanel>
                                    </Box>
                                </TabContext>
                            </Box>
                        )}

                        {/* Step 2: Consumables */}
                        {this.state.stepNumber === 1 && (
                            <Box sx={{ padding: "2vh" }}>
                                {/* patient details */}
                                {
                                    patientInfo()
                                }

                                {/* form */}
                                {/* consumables name api call & unit api call*/}
                                <Box sx={{ display: "flex", justifyContent: "flex-start", gap: "1vh", alignItems: "center" }}>
                                    <Autocomplete
                                        sx={{ width: "15vw", marginTop: "1vw" }}
                                        size="small"
                                        options={this.state.consumables}
                                        getOptionLabel={(option) => option.product_name}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Select Consumables*" />
                                        )}
                                        value={this.state.consumablesName}
                                        onChange={this.handleConsumableChange}
                                    />

                                    {/* TextField for quantity */}
                                    <TextField
                                        sx={{ width: "12vw", marginTop: "1vw" }}
                                        size="small"
                                        label="Quantity*"
                                        value={this.state.quantity}
                                        onChange={this.handleQuantityChange}
                                    />

                                    {/* TextField for unit (read-only) */}
                                    <TextField
                                        sx={{ width: "12vw", marginTop: "1vw" }}
                                        size="small"
                                        disabled
                                        label="Unit"
                                        value={this.state.unit}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />

                                    {/* Button to add consumable */}
                                    <Button
                                        variant="contained"
                                        size="small"
                                        sx={{
                                            height: "2.7vw",
                                            width: "8vw",
                                            textTransform: "capitalize",
                                            marginTop: "1vw",
                                        }}
                                        onClick={this.handleAddConsumable}
                                    >
                                        Add
                                    </Button>
                                </Box>
                                {/* table */}
                                <Box sx={{ height: 300, width: '100%', marginTop: '1vh' }}>
                                    <DataGrid
                                        rows={[
                                            ...(this.state.selectedConsumableList || []),
                                            ...(this.state.consumableList || []),
                                            ...(this.state.extra_consumablesList || [])
                                        ]}
                                        columns={consumableColumns}
                                        // pageSize={5}
                                        // rowsPerPageOptions={[5]}
                                        disableSelectionOnClick
                                        hideFooterPagination
                                        hideFooter
                                        density='compact'
                                    />
                                </Box>
                            </Box>
                        )}

                        {/* Footer */}
                        <Box sx={{ backgroundColor: 'white', display: 'flex', justifyContent: 'flex-end', gap: "1vh", padding: "1vh" }}>
                            {/* Close / Back Button */}
                            <Button
                                size="small"
                                variant="outlined"
                                sx={{ textTransform: 'capitalize', width: '8vw' }}
                                onClick={() => {
                                    // First action - Close the dialog
                                    this.setState({ isSpecimanClick: false, stepNumber: 0, tabValue: '1', selectedConsumableList: [] });
                                }}
                            >
                                {"Close"}
                            </Button>

                            {/* Collect Specimen Button */}
                            {this.state.stepNumber === 1 || this.state.tabValue === '2' ? null :
                                <Button
                                    size="small"
                                    variant="contained"
                                    sx={{ textTransform: 'capitalize', width: '12vw' }}
                                    disabled={this.state.selectedSamples.length > 0 ? false : true}
                                    onClick={() => {
                                        // Collect Specimen action - moves to the Collected Sample tab and saves changes
                                        this.setState({ tabValue: '2' }, () => {
                                            this.onClickSavedChanges();
                                        });
                                    }}
                                >
                                    Collect Specimen
                                </Button>
                            }

                            {/* Back to Sample Collection tab Button */}
                            {this.state.stepNumber === 1 || this.state.tabValue === '2' ? 
                                <Button
                                size="small"
                                variant="contained"
                                sx={{ textTransform: 'capitalize', width: '8vw' }}
                                onClick={() => {
                                    // back Specimen action - moves to the Collected Sample tab and saves changes
                                    this.setState({stepNumber: 0, tabValue: '1' }, () => {
                                        this.getPreSpecimanCollectedValue()
                                    });
                                }}
                            >
                                Back
                            </Button>
                            :
                                null
                            }

                            {/* Next Button */}
                            <Button
                                size="small"
                                variant="contained"
                                sx={{ textTransform: 'capitalize', width: '12vw' }}
                                disabled={this.state.stepNumber === 1 && this.state.selectedConsumableList.length === 0}
                                onClick={() => {
                                    const { stepNumber } = this.state;

                                    if (stepNumber === 0) {
                                        // Move to Consumable step
                                        this.setState({ stepNumber: 1 });
                                    } else if (stepNumber === 1) {
                                        // Final step - Finish logic if needed
                                        this.onFinishSavedChanges();
                                    }
                                }}
                            >
                                {this.state.stepNumber === 1 ? "Finish" : "Add Consumables"}
                            </Button>
                        </Box>
                        {/* </Box> */}
                    </Dialog >
                    : null
                }

                {/* {this.state.isSpecimanClick
                    ?
                    <Dialog
                        className="emed_DialogboxOrder"
                        open={this.state.isSpecimanClick}
                        onClose={this.specimanClose}
                        maxWidth={"md"}
                    >
                        <div className='emed_Dialogboxwidth_Lab_status'>
                            <div className='sample_collect_popup'>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <p>{t("Sample Collection")}</p>
                                    <Tooltip title={'Print Barcode'}>
                                        <IconButton emed_tid='Lab_Status_Print_btn' disabled={this.state.disableBtn} onClick={() => { this.PrintBarcode() }}>
                                            <img style={{ marginLeft: '0.6vw', width: '1.3vw', height: '1.3vw', cursor: 'pointer' }}
                                                src={ImagePaths.PrintIcons.default} alt='' />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <img
                                    src={ImagePaths.LabCloseButton.default}
                                    alt=""
                                    onClick={() => {
                                        this.setState({
                                            isSpecimanClick: false,
                                            consumableUnit: "",
                                            consumableValue: "",
                                            selectConsumables: null
                                        })
                                    }}
                                    className='eMed_sts_samp_collect'
                                />
                            </div>
                            <div className='Lab_status_table_container'>
                                <div className='Lab_Status_Tables_Main'>
                                    <TableContainer className='Lab_Status_Tables_Con' component={Paper}>
                                        <Table stickyHeader size="small">
                                            <TableHead id=' ' sx={{ height: "4vh" }} >
                                                <TableRow>
                                                    <TableCell id="lab_home_table_Head" sx={{ flex: 0.4 }} align="left" >{t("ServiceDescription")}</TableCell>
                                                    <TableCell id="lab_home_table_Head" align="left" sx={{ flex: 0.6 }}>{t("Laboratory")}</TableCell>

                                                </TableRow>
                                            </TableHead>
                                            <TableBody className="Lab_Status_Table_body" sx={{ height: "7vh" }}>
                                                {this.state.outSourceOptionList && this.state.outSourceOptionList.length > 0 ?
                                                    this.state.outSourceOptionList.map((item, index) => {
                                                        return (
                                                            <TableRow>
                                                                <TableCell align="left">{item.test_name}</TableCell>
                                                                <TableCell align="left">
                                                                    <FormControl id="eMed_dropDown_Lab_status">
                                                                        <Select
                                                                            disabled={selectedTableRow.status == "Specimen Collected" ? true : false}
                                                                            sx={{ height: "4vh" }}
                                                                            size='small'
                                                                            label="Age"
                                                                            value={outSourceOptionList[index].out_source__outsource_laboratory_name ? outSourceOptionList[index].out_source__outsource_laboratory_name : "Self"}
                                                                            onChange={(e) => {
                                                                                var { outSourceOptionList } = this.state
                                                                                let data = item.out_source_list
                                                                                if (e.target.value === 'Self') {
                                                                                    outSourceOptionList[index].out_source__outsource_laboratory_name = null
                                                                                    outSourceOptionList[index].out_source_id = ''
                                                                                } else {
                                                                                    for (let i = 0; i < data.length; i++) {
                                                                                        if (data[i].out_source__outsource_laboratory_name === e.target.value) {
                                                                                            outSourceOptionList[index].out_source_id = data[i].out_source_id
                                                                                        }
                                                                                    }
                                                                                    outSourceOptionList[index].out_source__outsource_laboratory_name = e.target.value
                                                                                }
                                                                                this.setState({
                                                                                    outSourceOptionList
                                                                                })
                                                                            }}
                                                                        >
                                                                            <MenuItem key={1} value={"Self"}>{t("Self")}</MenuItem>
                                                                            {item.out_source_list && item.out_source_list.length > 0 && item.out_source_list.map((data) => (
                                                                                <MenuItem key={1} value={data.out_source__outsource_laboratory_name}>{data.out_source__outsource_laboratory_name}</MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                </TableCell>
                                                            </TableRow>)
                                                    }) :
                                                    <TableRow>
                                                        <TableCell align="center">{t("NoRecordsFound")}</TableCell>
                                                        <TableCell align="center"></TableCell>
                                                    </TableRow>
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TableContainer className='Lab_Status_Tables_Con1' component={Paper} >
                                        <Table stickyHeader size="small" >
                                            <TableHead id='Lab_Home_Table_Header' sx={{ height: "4vh" }} >
                                                <TableRow >
                                                    <TableCell id="lab_home_table_Head" sx={{ flex: 0.5 }} align="left"> {t("Sample Collected In")}</TableCell>
                                                    <TableCell id="lab_home_table_Head" sx={{ flex: 0.2 }} align="left">{t("Quantity")}</TableCell>
                                                    <TableCell id="lab_home_table_Head" sx={{ flex: 0.3 }} align="left">{t("Unit")}</TableCell>
                                                    <TableCell id="lab_home_table_Head">{t("Action")}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody className="Lab_Status_Table_body">
                                                {this.state.sampleCollectedIn && this.state.sampleCollectedIn.length > 0 ?
                                                    this.state.sampleCollectedIn.map((item, index) => {
                                                        return (
                                                            <TableRow sx={{ height: "4vh" }}>
                                                                <TableCell align="left" sx={{ flex: 0.5 }}>{item.brand_name}</TableCell>
                                                                <TableCell align="left" sx={{ flex: 0.3 }} >
                                                                    <div className="Lab_Extra_Speciman">
                                                                        <Typography>{item.quantity}</Typography>
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell sx={{ flex: 0.2 }}>
                                                                    <Typography>{item.unit ? item.unit : 0}</Typography>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <img className='eMed_TestConfig_DeleteIcon eMed_cursor' src={ImagePaths.Delete.default}
                                                                        onClick={() => {
                                                                            if (selectedTableRow.status !== "Specimen Collected") {
                                                                                var { sampleCollectedIn } = this.state
                                                                                sampleCollectedIn.splice(index, 1)
                                                                                this.setState({
                                                                                    sampleCollectedIn
                                                                                })
                                                                            }
                                                                        }}
                                                                        alt="Delete"
                                                                    />
                                                                </TableCell>
                                                                <TableCell></TableCell>
                                                            </TableRow>
                                                        )
                                                    })
                                                    :
                                                    <TableRow>
                                                        <TableCell align="center">{t("NoRecordsFound")}</TableCell>
                                                        <TableCell align="center"></TableCell>
                                                        <TableCell align="center"></TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>}
                                                <TableRow>
                                                    <TableCell sx={{ flex: 0.5 }} align="left">
                                                        {this.renderTextField(t("EnterConsumables"), this.state.consumablesName, "consumablesName")}
                                                    </TableCell>
                                                    <TableCell>
                                                        <div className='Lab_status_Consumable'>
                                                            {this.renderTextField("", this.state.consumableValue, "consumableValue")}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>
                                                        {this.renderTextField("Units", this.state.consumableUnit, "consumableUnit")}
                                                    </TableCell>
                                                    <TableCell sx={{ flex: 0.2 }} >
                                                        <Button variant="contained" id="eMed_Btn_Text" sx={{ height: "4vh" }}
                                                            disabled={selectedTableRow.status == "Specimen Collected" ? true : false}
                                                            onClick={() => {
                                                                var { sampleCollectedIn } = this.state
                                                                if (this.state.consumableValue !== "") {
                                                                    var valid = this.validCheck(selectConsumables)
                                                                    if (valid) {
                                                                        this.setState({
                                                                            isErrorMsg: true,
                                                                            isErrorMsgText: t("NameDuplicated")
                                                                        })
                                                                    }
                                                                    if (selectConsumables == null) {
                                                                        this.setState({
                                                                            isErrorMsg: true,
                                                                            isErrorMsgText: "Please select the consumables"
                                                                        })
                                                                    } else if (selectConsumables && Object.keys(selectConsumables).length > 0 && this.state.consumableValue == "") {
                                                                        this.setState({
                                                                            isErrorMsg: true,
                                                                            isErrorMsgText: "Please enter the consumable quantity"
                                                                        })
                                                                    }
                                                                    else if (selectConsumables && Object.keys(selectConsumables).length > 0 && this.state.consumableValue && this.state.consumableUnit && !valid) {
                                                                        sampleCollectedIn.push({ "brand_name": selectConsumables.product_name, "quantity": this.state.consumableValue, "unit": this.state.consumableUnit, "product_ms_id": selectConsumables.product_ms_id })
                                                                        this.setState({
                                                                            sampleCollectedIn
                                                                        }, () => {
                                                                            this.setState({
                                                                                consumablesName: "",
                                                                                consumableValue: "",
                                                                                consumableUnit: "",
                                                                                selectConsumables: null
                                                                            })
                                                                        })
                                                                    }
                                                                } else {
                                                                    this.setState({
                                                                        isErrorMsg: true,
                                                                        isErrorMsgText: "Please Enter Quantity"
                                                                    })
                                                                }
                                                            }}
                                                        >{t("Add")}</Button>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                                <div className="Lab_Home_Table_Container_status2">
                                    <TableContainer className='ConsumableTableContainer' component={Paper} >

                                        <Table stickyHeader size="small" >
                                            <TableHead id='Lab_Home_Table_Header' sx={{ height: "4vh" }} >
                                                <TableRow className='consumable_style'>
                                                    <TableCell sx={{ flex: 0.4 }} id="lab_home_table_Head"  >{t("Consumables")} </TableCell>
                                                    <TableCell sx={{ flex: 0.3 }} id="lab_home_table_Head"  >{t("Quantity")}</TableCell>
                                                    <TableCell sx={{ flex: 0.3 }} id="lab_home_table_Head"  >{t("Unit")}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody className="Lab_Status_Table_body">
                                                {this.state.consumableList && this.state.consumableList.length > 0 ?
                                                    this.state.consumableList.map((item, index) => {
                                                        return (
                                                            <TableRow sx={{ height: "4vh" }}>
                                                                <TableCell sx={{ flex: 0.4 }} align="left">{item.brand_name}</TableCell>
                                                                <TableCell sx={{ flex: 0.3 }} align="left">{item.quantity}</TableCell>
                                                                <TableCell sx={{ flex: 0.3 }} align="left" >{item.unit}</TableCell>
                                                            </TableRow>)
                                                    }) :
                                                    <TableRow>
                                                        <TableCell align="center">{t("NoRecordsFound")}</TableCell>
                                                        <TableCell align="center"></TableCell>
                                                        <TableCell align="center"></TableCell>

                                                    </TableRow>}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </div>
                        </div>
                        <div className='Lab_status_sample_bottom'>
                            <Button variant="outlined" size="small" disabled={selectedTableRow.status == "Specimen Collected" ? true : false} sx={{ width: "13vw", marginRight: "1vw" }} onClick={() => { this.clearData() }}>{t("Reset")}</Button>
                            <Button variant="contained" size="small" disabled={selectedTableRow.status == "Specimen Collected" ? true : false} sx={{ width: "13vw", marginRight: "1vw" }} onClick={() => {
                                this.onClickSavedChanges()
                            }}>{t("SaveChanges")}</Button>
                        </div>
                    </Dialog>
                    : null
                } */}
                {/* speciman collection ends*/}
                {/* ============================================= */}
                <div>
                    {this.state.selectedOption == "ViewPreviousReport" ?
                        < Dialog
                            className="emed_DialogboxOrder"
                            open={this.state.isViewReport}
                            onClose={() => {
                                this.setState({
                                    isViewReport: false
                                })
                            }}
                            maxWidth={"md"}
                        >
                            <div className='PreviousReportPopUp'>
                                <div className='sample_collect_popup'>
                                    <p>{t("PreviousReport")}</p>
                                    <Button variant="text" onClick={() => {
                                        this.setState({
                                            selectedOption: "",
                                            isViewReport: false
                                        })
                                    }}>X</Button>
                                </div>
                                <TableContainer className='Lab_Previous_Report_Con' component={Paper}>
                                    <Table stickyHeader size="small">
                                        <TableHead id=' ' sx={{ height: "4vh" }} >
                                            <TableRow>
                                                <TableCell id="lab_home_table_Head" sx={{ flex: 0.4 }} align="left" >{t("InvoiceNumber")}</TableCell>
                                                <TableCell id="lab_home_table_Head" sx={{ flex: 0.3 }} align="left" >{t("Invoice Date")}</TableCell>
                                                <TableCell id="lab_home_table_Head" align="left" sx={{ flex: 0.3 }}> {t("Action")} </TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody className="Lab_Status_Table_body" sx={{ height: "7vh" }}>
                                            {this.state.preReportList && this.state.preReportList.length > 0 ?
                                                this.state.preReportList?.map((item) => {
                                                    return (
                                                        <TableRow>
                                                            <TableCell sx={{ flex: 0.4 }} align="left">
                                                                <Typography>{item.bill_number}</Typography>
                                                                {item?.is_outsource && <Typography fontSize={'.7vw'} color={'#888888'}>(Outsource Test)</Typography>}
                                                            </TableCell>
                                                            <TableCell align="left" sx={{ flex: 0.3 }} >{item.bill_date ? (item.bill_date.split("T")[0]).split("-").reverse().join("-") : "-"}</TableCell>
                                                            <TableCell align="left" sx={{ flex: 0.3 }}>
                                                                <IconButton size='small' disabled={item?.is_outsource}
                                                                    onClick={() => {
                                                                        this.individualReportPrint(item.id)
                                                                    }}
                                                                >
                                                                    <img width="30vw" height="30vh"
                                                                        src={item?.is_outsource ? ImagePaths.LabPrintIconOff.default : ImagePaths.PrintIcons.default}
                                                                        className="labImageStyle" alt='print' />
                                                                </IconButton>
                                                            </TableCell>

                                                        </TableRow>)
                                                }) :
                                                <TableRow>
                                                    <TableCell align="center">{t("NoRecordsFound")}</TableCell>
                                                    <TableCell align="center"></TableCell>
                                                    <TableCell align="center"></TableCell>
                                                </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </Dialog> : null
                    }
                </div>
                {
                    this.state.isErrorMsg ?
                        <ToastMsg
                            severity={'error'}
                            msg={this.state.isErrorMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : this.state.isSuccessMsg ?
                            <ToastMsg
                                severity={'success'}
                                msg={this.state.isSuccessMsgText}
                                msgPop={this.msgClose.bind(this)}
                            /> : null
                }
                {
                    this.state.CreditBillPopUp ?
                        <CreditBillCompletePoP
                            modules={['lab']}
                            Module_ID_Key_Name={"bill_id"}
                            Module_ID_Key_Value={this.state.SelectedBill?.lab_bill_summary_id}
                            POST_Module_ID_Key_Name={this.state.SelectedBill?.ip_admission_id ? "ip_admission_id" : this.state.SelectedBill?.appointment_id ? "appointment_id" : "bill_id"}
                            POST_Module_ID_Key_Value={this.state.SelectedBill?.ip_admission_id ? this.state.SelectedBill?.ip_admission_id : this.state.SelectedBill?.appointment_id ? this.state.SelectedBill?.appointment_id : this.state.SelectedBill?.lab_bill_summary_id}
                            BillData={this.state.SelectedBill}
                            CrebillPosted={this.CrebillPosted.bind(this)}
                            ClosePopUp={this.ClosePopUp.bind(this)}
                            CollectedIn={"LAB"}
                        /> : null
                }
                {
                    Object.keys(this.state.BillModifyHistoryItem).length > 0 ? <EditHistoryPopup BillType={"LAB"} BillId={this.state.BillModifyHistoryItem?.lab_bill_summary_id} SelectedBill={this.state.BillModifyHistoryItem} HistoryPopClose={this.HistoryPopClose.bind(this)} sendClinicId={true} /> : null
                }
                {
                    this.state.receiptID || this.state.pharmareceipt ?
                        <BillSuccessPoPUp creditreceipt={this.state.receiptID} phrma_creditreceipt={this.state.pharmareceipt} ClosePopUp={this.ClosesuccessPopUp.bind(this)} /> : null
                }
                <Loader loaderOpen={this.state.isLoader} />
            </div >
        );
    }
    specimanClose = () => {
        this.setState({
            isSpecimanClick: false,
            resetSourceOptionList: []
        })
    }
    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage
        });
    };
    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: +event.target.value,
            page: 0
        });
    };
}
export default withTranslation()(Status);