import React, { Component } from 'react'
import { Box, Button, Drawer, Stack, Tooltip } from '@mui/material'
import '../pharmaReports.css';
import { Colors } from '../../../../Styles/Colors';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { DataGrid } from '@mui/x-data-grid';
import { CommonGridToolBarWithFilterText } from '../../../../Components/Common Components/CommonComponents';
import { formatDate, AmountFormat } from '../../../../Components/CommonFunctions/CommonFunctions';
import { withTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import PharmaFilter from '../PharmaFilter';
import { AmountsCard } from '../../../../Components/CommonCards/CommonCards';
import { CurrencySymbol } from '../../../../Utility/Constants';

class UserPerformance extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userData: [],
            page: 0,
            pageSize: 10,
            filterOpen: false,
            isLoader: false,
            filterDatas: {
                'fromDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                'toDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                "dateIndex": 1,
                "days": 30,
                "UserList": [],
                "UserDetails": [],
            },
            pageName: 'userSalesPerformance',
            total_bill_amount: 0,
            total_return_amount: 0,
            total_amount: 0,
        }
    }

    componentDidMount() {
        this.getUserPerformanceData();
        this.getUserDetails();
    }

    getUserDetails = () => {
        try {
            RestAPIService.getAll(`${Serviceurls.USER_ACCESS_GET_LIST}?user_modules=Pharmacy&active_status=true`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState((prevState) => ({
                            ...prevState,
                            filterDatas: {
                                ...prevState.filterDatas,
                                UserList: response?.data?.data || []
                            }
                        }))
                    }
                }).catch((error) => {
                    this.errorMessage(error?.response?.data?.message || error?.message);
                })
        } catch (error) {
            this.errorMessage(error)
        }
    }

    getUserPerformanceData = () => {
        try {
            const { filterDatas } = this.state;
            const userName = filterDatas?.UserDetails?.map(element => element.user_name);
            this.LoaderFunction(true);
            RestAPIService.getAll(`${Serviceurls.PHARMA_USER_SALES_PERFORMANCE}?from_date=${filterDatas?.fromDate}&to_date=${filterDatas?.toDate}&user=${userName}`)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.LoaderFunction(false);
                        this.setState({
                            userData: response?.data?.data ? response.data.data : [],
                            total_bill_amount: response?.data?.total_bill_amount ? response.data.total_bill_amount : 0,
                            total_return_amount: response?.data?.total_return_amount ? response.data.total_return_amount : 0,
                            total_amount: response?.data?.total_amount ? response.data.total_amount : 0,
                        })
                    }
                }).catch((error) => {
                    this.errorMessage(error?.response?.data?.message || error?.message);
                    this.LoaderFunction(false);
                })
        } catch (error) {
            this.LoaderFunction(false);
            this.errorMessage(error.message)
        }
    }

    getUserPerformancePrint = () => {
        try {
            this.setState({ disableBtn: true })
            const { filterDatas } = this.state;
            const userName = filterDatas?.UserDetails?.map(element => element.user_name);
            RestAPIService.getAll(`${Serviceurls.PHARMA_USER_SALES_PERFORMANCE}?from_date=${filterDatas?.fromDate}&to_date=${filterDatas?.toDate}&user=${userName}&export=pdf`)
                .then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    this.setState({ disableBtn: false })
                }).catch((error) => {
                    this.errorMessage(error?.response?.data?.message || error?.message);
                    this.setState({ disableBtn: false })
                })
        } catch (error) {
            this.errorMessage(error.message)
            this.setState({ disableBtn: false })
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    renderFilter = (data) => {
        this.setState({
            filterDatas: data,
            filterOpen: false,
        }, () => { this.getUserPerformanceData() })
    }

    ClosePopUp() {
        this.setState({
            filterOpen: false,
        })
    }

    clearFilter = (data) => {
        this.setState({
            filterDatas: data
        })
    }

    gridToolBar = () => {
        let TextArray = [
            { label: "From Date", value: formatDate(this.state.filterDatas.fromDate) },
            { label: "To Date", value: formatDate(this.state.filterDatas.toDate) },
        ]
        return (
            <Box>
                <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
            </Box>
        )
    }

    closeFilter = () => {
        this.setState({ filterOpen: false })
    }

    render() {
        const { t } = this.props
        this.state.userData?.forEach((element, index) => element.sno = index + 1)
        const columns = [
            { field: 'sno', headerName: t('SNo'), sortable: false, flex: 0.08 },
            {
                field: 'user_name', headerName: t("Username"), flex: 0.24,
                renderCell: ({ row }) => (<Box component={'div'}>{row?.user_name ? (row?.user_name?.length > 26 ?
                    <Tooltip placement="top" title={row?.user_name}><div>{row?.user_name?.slice(0, 26)}</div></Tooltip> : row?.user_name) : '-'}
                </Box>)
            },
            {
                field: 'no_of_bills', headerName: t("No Of Bills"), flex: 0.17, type: "number",
                renderCell: ({ row }) => (<Box component={'div'}>{row?.no_of_bills ? row?.no_of_bills : '0'}
                </Box>)
            },
            {
                field: 'total_bill_amount', headerName: `${t("Total Bill Amount")} (${CurrencySymbol})`, flex: 0.17, type: "number",
                renderCell: ({ row }) => (<Box component={'div'}> {(row?.total_bill_amount ? AmountFormat(row?.total_bill_amount)?.replace(`${CurrencySymbol}`, "") : '0.00')}</Box>)
            },
            {
                field: 'no_of_return_bills', headerName: t("No of  Return bills"), flex: 0.17, type: "number",
                renderCell: ({ row }) => (<Box>{row?.no_of_return_bills ? row?.no_of_return_bills : '0'}</Box>)
            },
            {
                field: 'total_return_amount', headerName: `${t("Total Return Amount")} (${CurrencySymbol})`, flex: 0.17, type: "number",
                renderCell: ({ row }) => (<Box>{row?.total_return_amount ? AmountFormat(row?.total_return_amount)?.replace(`${CurrencySymbol}`, "") : '0.00'}</Box>)
            },
        ]
        return (
            <Box component={'div'} height={'80dvh'} sx={{ backgroundColor: Colors.Background }}>
                <Box component={'div'} className='eMed_Pharma_rts_header'>
                    <Box component={'div'} flex={0.8} display={'flex'}>
                        <Box component={'div'} flex={0.8} display={'flex'}>
                            {AmountsCard("Bill Amount", this.state.total_bill_amount, false, "white", false, null, "up_billAmt_testID")}
                            {AmountsCard("Return Amount", this.state.total_return_amount, false, "white", false, null, "up_rtnAmt_testID")}
                            {AmountsCard("Total Amount", this.state.total_amount, false, "white", false, null, "up_tltAmt_testID")}
                        </Box>
                    </Box>

                    <Box flex={0.8} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} marginRight={'0.5dvw'}>
                        <Button emed_tid="up_rpt_flt" className='eMed_Pharma_rts_btn' onClick={() => this.setState({ filterOpen: true })}>
                            <Box component={'img'} src={ImagePaths.Filter.default} alt='filter' height={'2.3dvw'} width={'2.3dvw'} />
                        </Button>
                        <Button emed_tid="up_rpt_print" className='eMed_Pharma_rts_btn' disabled={this.state.disableBtn} onClick={() => { this.getUserPerformancePrint() }}>
                            <Box component={'img'} src={ImagePaths.LabPrintIcon.default} alt='print' height={'2.3dvw'} width={'2.3dvw'} />
                        </Button>
                    </Box>
                </Box>
                <Box component={'div'} className='eMed_Pharma_rts_tbl_card'>
                    <DataGrid
                        className='eMed_Pharma_table'
                        rows={this.state.userData || []}
                        columns={columns}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        getRowId={(row) => row['sno']}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        disableSelectionOnClick
                        headerHeight={40}
                        pagination
                        loading={this.state.isLoader}
                    />
                </Box>

                <Drawer
                    anchor={'right'}
                    open={this.state.filterOpen}
                    ModalProps={{ onBackdropClick: this.closeFilter }}
                >
                    <PharmaFilter
                        pageName={this.state.pageName}
                        filterDatas={this.state.filterDatas}
                        closeFilter={this.ClosePopUp.bind(this)}
                        filteredData={this.renderFilter.bind(this)}
                        ClearData={this.clearFilter.bind(this)} />
                </Drawer>

                {this.state?.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state?.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state?.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state?.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}
export default withTranslation()(UserPerformance)