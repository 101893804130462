import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Box, Button, InputAdornment, OutlinedInput, Paper, Stack, Typography } from '@mui/material'
import ToastMsg from '../../../../../../Components/ToastMsg/ToastMsg'
import { CommonPatientCard, CommonPatientDetails, ConsultantPatientCard } from '../../../../../../Components/Common Components/CommonComponents'
import { getCachevalue } from '../../../../../../Utility/Services/CacheProviderService'
import Loader from '../../../../../../Components/Loader'
import { Colors } from '../../../../../../Styles/Colors'
import CommonValidation from '../../../../../../Components/CommonFunctions/CommonValidation'
import RestAPIService from '../../../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../../../Utility/API/Serviceurls'
import { AmountFormat } from '../../../../../../Components/CommonFunctions/CommonFunctions'

class ClaimDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ipPatientData: {},
            TotalBillAmt: 0,
            PendingAmt: 0,
            ApprovedAmt: "",
            isDisabled: false,
            CreditDetails: [],
            receivedAmt: 0,
            DisallowanceAmt: "",
            TDSAmount: "",
            TotalClaimAmt: ""
        }
    }

    componentDidMount() {
        let ipPatientData = getCachevalue("IppatientData")
        let patientData = JSON.parse(ipPatientData)
        this.setState({
            ipPatientData: patientData,
        }, () => { this.getPatientData() })
    }

    getPatientData = () => {
        try {
            let AdmissionId = this.state?.ipPatientData?.id ? this.state?.ipPatientData?.id : ""
            RestAPIService.getAll(Serviceurls.IP_PATIENT_DETAILS + '?admission_id=' + AdmissionId)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            TotalBillAmt: response?.data?.data[0]?.service_amount,
                            TotalClaimAmt: response?.data?.data[0]?.claimed_amount ? response?.data?.data[0]?.claimed_amount : "",
                            isDisabled: response?.data?.data[0]?.claimed_amount > 0 || !response?.data?.data[0]?.is_final_bill_generated ? true : false,
                            PendingAmt: response?.data?.data[0]?.is_final_bill_generated ? ((+response?.data?.data[0]?.service_amount - (response?.data?.data[0]?.paid_amount)) - (response?.data?.data[0]?.claimed_amount ? +response?.data?.data[0]?.claimed_amount : 0)) : 0,
                            CreditDetails: response?.data?.data[0],
                            receivedAmt: response?.data?.data[0]?.paid_amount,
                            ApprovedAmt: response?.data?.data[0]?.approved_amount,
                            DisallowanceAmt: response?.data?.data[0]?.disallowance_amount,
                            TDSAmount: response?.data?.data[0]?.tds_amount
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    LoaderFunction = (key = false) => {
        this.setState({
            isLoader: key
        })
    }

    handleClear = () => {
        this.setState({
            ApprovedAmt: "",
            DisallowanceAmt: "",
            TDSAmount: "",
            TotalClaimAmt: ""
        })
    }

    handleSave = () => {
        let Company_Name = this.state?.CreditDetails?.credit_type === "Insurance credit" ? this.state?.CreditDetails?.insurance_name : this.state?.CreditDetails?.credit_type === "Corprate credit" ? this.state?.CreditDetails?.employer_name : null;
        let ID_No = this.state?.CreditDetails?.credit_type === "Insurance credit" ? this.state?.CreditDetails?.policy_no : this.state?.CreditDetails?.credit_type === "Corprate credit" ? this.state?.CreditDetails?.id_no : null;
        this.setState({ isDisabled: true })
        try {
            let states = this.state;
            var data = {
                "ip_admission_id": states?.ipPatientData?.id,
                "claim_amount": +states?.TotalClaimAmt,
                "primary_payment_type": this.state?.CreditDetails?.credit_type === "Corprate credit" ? 'Corprate credit' : this.state?.CreditDetails?.credit_type === "Insurance credit" ? 'Insurance credit' : null,
                "policy_company_name": Company_Name,
                "policy_number": ID_No,
                "approved_amount": +states?.ApprovedAmt,
                "disallowance_amount": +states?.DisallowanceAmt,
                "tds_amount": +states?.TDSAmount
            }
            RestAPIService.create(data, Serviceurls.PRE_AUTHORIZATION_POST)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            isDisabled: false
                        }, () => { this.getPatientData() })
                        this.successMessage(response.data.message)
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderLeftContent = () => {
        const { t } = this.props
        return (
            <div>
                <Box component={'div'} className='eMed_IP_Discharge_Header'>
                    <Box>
                        <Typography paddingLeft={"0.5vw"} fontWeight={600}>{t('Claim Amount Details')}</Typography>
                    </Box>
                </Box>
                {this.state?.CreditDetails?.credit_type === "Corprate credit" ?
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "50vh" }}>
                        There was no insurance linked with this patient
                    </div> :
                    <div>
                        <Box component={'div'} style={{ padding: "0.5vw", display: "flex", flexDirection: "column", marginLeft: "1.5vw" }}>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: "1vw" }}>
                                <div style={{ width: "12vw", display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                                    <div style={{ fontWeight: 600 }}>Total Bill Amount</div>
                                    <div style={{ fontSize: "0.9vw" }}>{"(After Generate Final Bill)"}</div>
                                </div>
                                {this.state.CreditDetails?.is_final_bill_generated && this.state?.CreditDetails?.policy_status !== "Not Approved" ? <div style={{ marginLeft: "2vw" }}>{`:  ${AmountFormat(this.state?.TotalBillAmt)}`}</div> :
                                    this.state?.CreditDetails?.policy_status === "Not Approved" ? <Typography sx={{ fontSize: "0.8vw", color: Colors.red, fontWeight: 600, ml: "2vw" }}>: Pre Authorization Not Approved</Typography> :
                                        <Typography sx={{ fontSize: "0.8vw", color: Colors.red, fontWeight: 600, ml: "2vw" }}>: Final Bill Not Generated</Typography>}
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: "1.5vw" }}>
                                <div style={{ width: "12vw", fontWeight: 600 }}>Received Amount</div>
                                <div style={{ marginLeft: "2vw" }}>{`:  ${AmountFormat(this.state.receivedAmt)}`}</div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: "1.5vw" }}>
                                <div style={{ width: "12vw", fontWeight: 600 }}>Approved Amount</div>
                                <div>
                                    <OutlinedInput
                                        value={this.state.ApprovedAmt ? this.state.ApprovedAmt : ""}
                                        disabled={this.state?.isDisabled || this.state?.CreditDetails?.policy_status === "Not Approved"}
                                        startAdornment={<InputAdornment position="start">₹</InputAdornment>}
                                        sx={{ height: "2vw", fontSize: "0.9vw", width: "8.5vw", marginLeft: "2vw" }}
                                        onChange={(e) => {
                                            let value = e.target.value;
                                            let isValid = false;
                                            isValid = CommonValidation.NumberWithDot(value);
                                            if ((value === "" || (isValid && value > 0)) && e.target.value <= this.state.TotalBillAmt) {
                                                this.setState({
                                                    ApprovedAmt: e.target.value,
                                                    TotalClaimAmt: e.target.value, DisallowanceAmt: "", TDSAmount: ""
                                                })
                                            }
                                        }} />
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: "1.5vw" }}>
                                <div style={{ width: "12vw", fontWeight: 600 }}>Disallowance Amount</div>
                                <div>
                                    <OutlinedInput
                                        value={this.state.DisallowanceAmt ? this.state.DisallowanceAmt : ""}
                                        disabled={this.state?.isDisabled && (this.state.ApprovedAmt > 0) || this.state?.CreditDetails?.policy_status === "Not Approved"}
                                        startAdornment={<InputAdornment position="start">₹</InputAdornment>}
                                        sx={{ height: "2vw", fontSize: "0.9vw", width: "8.5vw", marginLeft: "2vw" }}
                                        onChange={(e) => {
                                            let value = e.target.value;
                                            let isValid = false;
                                            let ClaimAmount = ((+this.state.ApprovedAmt - +this.state.TDSAmount) - +e.target.value)
                                            isValid = CommonValidation.NumberWithDot(value);
                                            if ((value === "" || (isValid && value > 0)) && e.target.value <= +this.state.ApprovedAmt) {
                                                this.setState({
                                                    DisallowanceAmt: e.target.value,
                                                    TotalClaimAmt: ClaimAmount
                                                })
                                            }
                                        }} />
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: "1.5vw" }}>
                                <div style={{ width: "12vw", fontWeight: 600 }}>TDS</div>
                                <div>
                                    <OutlinedInput
                                        value={this.state.TDSAmount ? this.state.TDSAmount : ""}
                                        disabled={this.state?.isDisabled && (this.state.ApprovedAmt > 0) || this.state?.CreditDetails?.policy_status === "Not Approved"}
                                        startAdornment={<InputAdornment position="start">₹</InputAdornment>}
                                        sx={{ height: "2vw", fontSize: "0.9vw", width: "8.5vw", marginLeft: "2vw" }}
                                        onChange={(e) => {
                                            let value = e.target.value;
                                            let isValid = false;
                                            let ClaimAmount = ((+this.state.ApprovedAmt - +this.state.DisallowanceAmt) - +e.target.value)
                                            isValid = CommonValidation.NumberWithDot(value);
                                            if ((value === "" || (isValid && value > 0)) && e.target.value <= +this.state.ApprovedAmt) {
                                                this.setState({
                                                    TDSAmount: e.target.value,
                                                    TotalClaimAmt: ClaimAmount
                                                })
                                            }
                                        }} />
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: "1.5vw" }}>
                                <div style={{ width: "12vw", fontWeight: 600 }}>Claim Amount</div>
                                <div>
                                    <OutlinedInput
                                        value={this.state.TotalClaimAmt > 0 ? this.state.TotalClaimAmt : 0}
                                        disabled={true}
                                        startAdornment={<InputAdornment position="start">₹</InputAdornment>}
                                        sx={{ height: "2vw", fontSize: "0.9vw", width: "8.5vw", marginLeft: "2vw" }}
                                        onChange={(e) => {
                                            this.setState({
                                                TotalClaimAmt: e.target.value
                                            })
                                        }} />
                                </div>
                            </div>
                            {/* <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: "2vw" }}>
                        <div style={{ width: "12vw", fontWeight: 600 }}>Pending Amount</div>
                        <div style={{ marginLeft: "2vw" }}>{`:  ${(this.state.PendingAmt).toFixed(2)}`}</div>
                        </div> */}
                        </Box>
                        <Stack spacing={2} direction="row" id='eMed_recPayment_rightBtnCon' sx={{ marginTop: "1.5vw", marginRight: "1vw" }} alignItems="center" justifyContent="flex-end">
                            <Button disabled={this.state?.isDisabled || this.state?.CreditDetails?.policy_status === "Not Approved"} variant='outlined' size="small" id="eMed_recPayment_btn" onClick={() => { this.handleClear() }}>{t("Clear")}</Button>
                            <Button disabled={(this.state.isDisabled || this.state.TotalClaimAmt <= 0) || this.state?.CreditDetails?.policy_status === "Not Approved"} variant='contained' size="small" id="eMed_recPayment_btn" onClick={() => { this.handleSave() }}>{t("Save")}</Button>
                        </Stack>
                    </div>}
            </div>
        )
    }

    render() {
        const { t } = this.props
        var { ipPatientData } = this.state
        let attender = {
            lineOne: t("AttenderName"),
            lineTwo: `${ipPatientData.attender_name ? ipPatientData.attender_name : '-'}`,
            lineFour: t('ContactNumber'),
            LineFive: `${ipPatientData.attender_mobile ? ipPatientData.attender_mobile : '-'}`
        }
        let consultant = {
            lineOne: t("ConsultantName"),
            lineTwo: `${ipPatientData.doctor_name ? ipPatientData.doctor_name : '-'}`,
        }
        return (
            <Box sx={{ backgroundColor: Colors.ComponentBgColor }}>
                <Box component={'div'} marginLeft={'0.5vw'} className='eMed_patient_header'>
                    <Box display={'flex'} alignItems='center'>
                        <Box className='eMed_Patient_Details_Long eMed_patient_box'>
                            <CommonPatientDetails data={this.state.ipPatientData} showDetailed={true} />
                        </Box>
                        <CommonPatientCard details={attender} showDetailed={true} />
                        <ConsultantPatientCard name={consultant} />
                    </Box>
                </Box>
                <Box display={'flex'} flexDirection={'row'} marginTop={"0.5vw"}>
                    <Paper elevation={3} className='eMed_DocNotes_Left_Main'>
                        {this.renderLeftContent()}
                    </Paper>
                </Box>
                <Loader loaderOpen={this.state.isLoader} />
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}
export default withTranslation()(ClaimDetails);