import { Box, Button, Drawer, Tooltip, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'
import React, { Component } from 'react'
import '../pharmaReports.css';
import { Colors } from '../../../../Styles/Colors';
import { AmountsCard } from '../../../../Components/CommonCards/CommonCards';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { AmountFormat } from '../../../../Components/CommonFunctions/CommonFunctions';
import { withTranslation } from 'react-i18next';
import PharmaFilter from '../PharmaFilter';
import { DateTime } from 'luxon';
import Loader from '../../../../Components/Loader';
import { CurrencySymbol } from '../../../../Utility/Constants';
import { localGetItem } from '../../../../Utility/Services/CacheProviderService';

class PurchaseGST extends Component {

  constructor(props) {
    super(props)
    this.state = {
      filterDatas: {
        'fromDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
        'toDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
        "dateIndex": 1,
        "days":null,
        "is_bill_wise": false,
      },
      page: 0,
      rowsPerPage: 10,
      purchaseGSTData: [],
      taxAmt: 0,
      gstAmt: 0,
      isLoader: false,
      pageName: 'purchaseGST',
      tcsAmt:false,
      total_tcs_amount:0
    }
  }

  componentDidMount() {
    let tcsAmt = JSON.parse(localGetItem('loggedInUserInfo'))
        this.setState({tcsAmt:tcsAmt?.tcs_purchase_applicable})
    this.getPurchaseGSTReport()
  }

  getPurchaseGSTReport = () => {
    try {
      let billWise = this.state.filterDatas?.is_bill_wise ? 'grn_wise' : 'bill_wise'
      this.LoaderFunction(true);
      RestAPIService.getAll(Serviceurls.PHARMA_PURCHASE_GST + `?from_date=${this.state.filterDatas.fromDate}&to_date=${this.state.filterDatas.toDate}&page=${this.state.page + 1}&page_size=${this.state.rowsPerPage}&report_type=${billWise}`)
        .then((response) => {
          if (response.data.status === 'success') {
            this.LoaderFunction(false);
            this.setState({
              purchaseGSTData: response.data.data,
              taxAmt: response.data.grand_total_amount,
              gstAmt: response.data.grand_gst_amount,
              total_tcs_amount: response.data.total_tcs_amount
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.LoaderFunction(false);
            this.errorMessage(error.response.data.message)
          } else {
            this.LoaderFunction(false);
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getPurchaseGSTexport = () => {
    try {
      let billWise = this.state.filterDatas?.is_bill_wise ? 'grn_wise' : 'bill_wise'
      this.setState({disableBtn: true})
      RestAPIService.excelGet(Serviceurls.PHARMA_PURCHASE_GST + `?from_date=${this.state.filterDatas.fromDate}&to_date=${this.state.filterDatas.toDate}&page=${this.state.page + 1}&page_size=${this.state.rowsPerPage}&report_type=${billWise}&export_type=excel`).
        then((response) => {
          if (response.data) {
            var pom = document.createElement('a');
            var csvContent = response.data; //here we load our csv data 

            let filename = response?.headers["content-disposition"]?.split("filename=")[1]
            // var blob = new Blob([csvContent], {type: 'data:application/vnd.ms-excel;base64'});

            var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
            var url = URL.createObjectURL(blob);
            pom.href = url;
            pom.setAttribute('download', filename ? filename : 'registry.xlsx');
            pom.click();
            this.setState({disableBtn: false})
          }
        }).catch((error) => {
          if (error.response?.data?.message) {
            this.errorMessage(error.response?.data?.message);
          } else {
            this.errorMessage(error.message);
          }
          this.setState({disableBtn: false})
        })
    } catch (error) {
      this.errorMessage(error.message);
      this.setState({disableBtn: false})
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  renderFilter = (data) => {
    this.setState({
      filterDatas: data,
      filterOpen: false,
      page:0,
    }, () => {
      this.getPurchaseGSTReport()
    })
  }

  ClosePopUp() {
    this.setState({
      filterOpen: false,
    })
  }

  clearFilter = (data) => {
    this.setState({
      filterDatas: data
    })
  }
  renderTable = () => {
    const { t } = this.props
    return (
      <Box Component={'div'}>
        <Paper sx={{ width: '100%', height: '100%' }}>
          <TableContainer sx={{ height: '55vh' }}>
            <Table stickyHeader size='small' >
              <TableHead>
                <TableRow>
                  <TableCell id='eMed_config_TableRow1' sx={{ borderRight: '1px solid #CBCBCB' }} rowSpan={2}>{t('VendorName')}</TableCell>
                  <TableCell id='eMed_config_TableRow1' align='center' colSpan={2}>0%</TableCell>
                  <TableCell id='eMed_config_TableRow1' align='center' colSpan={2}>5%</TableCell>
                  <TableCell id='eMed_config_TableRow1' align='center' colSpan={2}>12%</TableCell>
                  <TableCell id='eMed_config_TableRow1' align='center' colSpan={2}>18%</TableCell>
                  <TableCell id='eMed_config_TableRow1' align='center' sx={{ borderRight: '1px solid #CBCBCB' }} colSpan={2}>Others</TableCell>
                  <TableCell id='eMed_config_TableRow1' align='right' rowSpan={2}>{`${t('Totaltacamo') + `(${CurrencySymbol})`}`}</TableCell>
                  <TableCell id='eMed_config_TableRow1' align='right' rowSpan={2}>{`${t('TotalGSTAmount') + `(${CurrencySymbol})`}`}</TableCell>
                  {this.state.tcsAmt ? 
                  <TableCell id='eMed_config_TableRow1' align='right' rowSpan={2}>{`${t('TCS') + `(${CurrencySymbol})`}`}</TableCell> : null}
                  <TableCell id='eMed_config_TableRow1' align='right' rowSpan={2}>{`${t('Total Net Amount') + `(${CurrencySymbol})`}`}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell id='eMed_config_TableRow1' align='right' sx={{ position: 'sticky', top: '2.35vw' }} >{`${t('TaxAmt') + `(${CurrencySymbol})`}`}</TableCell>
                  <TableCell id='eMed_config_TableRow1' align='right' sx={{ position: 'sticky', top: '2.35vw', borderRight: '1px solid #CBCBCB' }}>{`${t('gstAmt') + `(${CurrencySymbol})`}`}</TableCell>
                  <TableCell id='eMed_config_TableRow1' align='right' sx={{ position: 'sticky', top: '2.35vw' }}>{`${t('TaxAmt') + `(${CurrencySymbol})`}`}</TableCell>
                  <TableCell id='eMed_config_TableRow1' align='right' sx={{ position: 'sticky', top: '2.35vw', borderRight: '1px solid #CBCBCB' }}>{`${t('gstAmt') + `(${CurrencySymbol})`}`}</TableCell>
                  <TableCell id='eMed_config_TableRow1' align='right' sx={{ position: 'sticky', top: '2.35vw' }} >{`${t('TaxAmt') + `(${CurrencySymbol})`}`}</TableCell>
                  <TableCell id='eMed_config_TableRow1' align='right' sx={{ position: 'sticky', top: '2.35vw', borderRight: '1px solid #CBCBCB' }}>{`${t('gstAmt') + `(${CurrencySymbol})`}`}</TableCell>
                  <TableCell id='eMed_config_TableRow1' align='right' sx={{ position: 'sticky', top: '2.35vw' }}>{`${t('TaxAmt') + `(${CurrencySymbol})`}`}</TableCell>
                  <TableCell id='eMed_config_TableRow1' align='right' sx={{ position: 'sticky', top: '2.35vw', borderRight: '1px solid #CBCBCB' }}>{`${t('gstAmt') + `(${CurrencySymbol})`}`}</TableCell>
                  <TableCell id='eMed_config_TableRow1' align='right' sx={{ position: 'sticky', top: '2.35vw' }}>{`${t('TaxAmt') + `(${CurrencySymbol})`}`}</TableCell>
                  <TableCell id='eMed_config_TableRow1' align='right' sx={{ position: 'sticky', top: '2.35vw', borderRight: '1px solid #CBCBCB' }}>{`${t('gstAmt') + `(${CurrencySymbol})`}`}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.purchaseGSTData
                  .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                  .map((data) => {
                    return (
                      <TableRow>
                        <TableCell sx={{ borderRight: '1px solid #CBCBCB' }}>{
                          data.vendor_name.length > 10 ?
                            <Tooltip title={data.vendor_name} placement='top'>
                              <div>{data.vendor_name ? data.vendor_name.slice(0, 10) + '...' : '-'}</div>
                            </Tooltip> : <div>{data.vendor_name ? data.vendor_name : '-'}</div>}</TableCell>
                        <TableCell align='right'>{AmountFormat(data?.zero_dict?.net_amount || 0)?.replace(`${CurrencySymbol}`, "")}</TableCell>
                        <TableCell align='right' sx={{ borderRight: '1px solid #CBCBCB' }}>{AmountFormat(data.zero_dict.gst_amount || 0)?.replace(`${CurrencySymbol}`, "")}</TableCell>
                        <TableCell align='right'>{AmountFormat(data?.five_dict?.net_amount || 0)?.replace(`${CurrencySymbol}`, "")}</TableCell>
                        <TableCell align='right' sx={{ borderRight: '1px solid #CBCBCB' }}>{AmountFormat(data.five_dict.gst_amount || 0)?.replace(`${CurrencySymbol}`, "")}</TableCell>
                        <TableCell align='right'>{AmountFormat(data?.twelve_dict?.net_amount || 0)?.replace(`${CurrencySymbol}`, "")}</TableCell>
                        <TableCell align='right' sx={{ borderRight: '1px solid #CBCBCB' }}>{AmountFormat(data.twelve_dict.gst_amount || 0)?.replace(`${CurrencySymbol}`, "")}</TableCell>
                        <TableCell align='right'>{AmountFormat(data?.eighteen_dict?.net_amount || 0)?.replace(`${CurrencySymbol}`, "")}</TableCell>
                        <TableCell align='right' sx={{ borderRight: '1px solid #CBCBCB' }}>{AmountFormat(data.eighteen_dict.gst_amount || 0)?.replace(`${CurrencySymbol}`, "")}</TableCell>
                        <TableCell align='right'>{AmountFormat(data?.others_dict?.net_amount || 0)?.replace(`${CurrencySymbol}`, "")}</TableCell>
                        <TableCell align='right' sx={{ borderRight: '1px solid #CBCBCB' }}>{AmountFormat(data.others_dict?.gst_amount || 0)?.replace(`${CurrencySymbol}`, "")}</TableCell>
                        <TableCell align='right' >{AmountFormat(data?.total_taxable_amount || 0)?.replace(`${CurrencySymbol}`, "")}</TableCell>
                        <TableCell align='right'>{AmountFormat(data?.total_gst_amount || 0)?.replace(`${CurrencySymbol}`, "")}</TableCell>
                        {this.state.tcsAmt ? 
                        <TableCell align='right'>{AmountFormat(data?.tcs_amount || 0)?.replace(`${CurrencySymbol}`, "")}</TableCell> : null}
                        <TableCell align='right'>{AmountFormat(data?.grand_total_amount || 0)?.replace(`${CurrencySymbol}`, "")}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            {this.state.purchaseGSTData.length === 0 ?
              <div className='eMed_TestConfig_NoRecords'>
                {t('NoRecordsFound')}
              </div> : null}
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 15, 20]}
            component="div"
            count={this.state.purchaseGSTData.length}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            onPageChange={(event, newPage) => {
              this.setState({ page: newPage }
                , () => { this.getPurchaseGSTReport() }
              )
            }}
            onRowsPerPageChange={(event) => {
              this.setState({ rowsPerPage: event.target.value, page: 0 }
                , () => { this.getPurchaseGSTReport() }
              )
            }}
          />
        </Paper>
      </Box>
    )
  }

  PurchaseGSTPrint = () => {
    try {
        this.setState({disabled: true})
        let billWise = this.state.filterDatas?.is_bill_wise ? 'grn_wise' : 'bill_wise'
      RestAPIService.getAll(Serviceurls.PHARMA_PURCHASE_GST + `?from_date=${this.state.filterDatas.fromDate}&to_date=${this.state.filterDatas.toDate}&page=${this.state.page + 1}&page_size=${this.state.rowsPerPage}&report_type=${billWise}&export_type=pdf`).
        then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.setState({disabled:false})
        }).catch((error) => {
          if(error?.response?.data?.message){
            this.errorMessage(error?.response?.data?.message)
          }else{
            this.errorMessage(error.message)
          }
          this.setState({disabled: false})
        })
    } catch (error) {
      this.errorMessage(error.message)
      this.setState({disabled: false})
    }
  }

  render() {
    const { t } = this.props
    var gst_Split = this.state.gstAmt / 2
    return (
      <Box component={'div'} height={'80vh'} sx={{ backgroundColor: Colors.Background }}>
        <Box component={'div'} className='eMed_Pharma_rts_header'>
          <Box component={'div'} flex={0.8} display={'flex'}>
            {AmountsCard("Total Taxable Amount", this.state.taxAmt, false, "white", false, null, 'rpt_purgst_tax_amt')}
            {AmountsCard("Total GST Amount", this.state.gstAmt, false, "white", false, null, 'rpt_purgst_gst_amt')}
            {AmountsCard("CGST Amount", gst_Split, false, "white", false, null, 'rpt_purgst_cgst')}
            {AmountsCard("SGST Amount", gst_Split, false, "white", false, null, 'rpt_purgst_sgst')}
            {this.state.tcsAmt ? AmountsCard("TCS", this.state.total_tcs_amount, false, "white", false, null, 'rpt_purgst_tcs') : null}
          </Box>
          <Box flex={0.2} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} marginRight={'0.5vw'}>
            <Button emed_tid="rpt_purgst_flt" className='eMed_Pharma_rts_btn' onClick={() => this.setState({ filterOpen: true })}>
              <Box component={'img'} src={ImagePaths.Filter.default} alt='filter' height={'2.3vw'} width={'2.3vw'} />
            </Button>
            <Button className='eMed_Pharma_rts_btn ' disabled={this.state.disabled}>
              <Box component={'img'} src={ImagePaths.LabPrintIcon.default} alt='print' height={'2.3vw'} width={'2.3vw'} onClick={() => this.PurchaseGSTPrint()}/>
            </Button>
            <Button emed_tid="rpt_purgst_exp" disabled={this.state.disableBtn ? true : this.state.purchaseGSTData.length > 0 ? false : true} className='eMed_Pharma_rts_btn' onClick={() => { this.getPurchaseGSTexport() }}>
              <Box component={'img'} src={ImagePaths.ExportIcon.default} alt='export' height={'2.3vw'} width={'2.3vw'} />
            </Button>
          </Box>
        </Box>
        <Box component={'div'} className='eMed_Pharma_rts_tbl_card'>
          {this.renderTable()}
        </Box>
        <Drawer
          anchor={'right'}
          open={this.state.filterOpen}
          ModalProps={{ onBackdropClick: this.ClosePopUp.bind(this) }}
        >
          <PharmaFilter pageName={this.state.pageName} filterDatas={this.state.filterDatas} closeFilter={this.ClosePopUp.bind(this)} filteredData={this.renderFilter.bind(this)} ClearData={this.clearFilter.bind(this)} />
        </Drawer>
        <Loader loaderOpen={this.state.isLoader} />
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
      </Box>
    )
  }
}

export default withTranslation()(PurchaseGST)