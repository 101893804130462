import {
    Autocomplete, Box, Button, Checkbox, FormControlLabel, IconButton,
    InputAdornment, Menu, MenuItem, Modal, Radio, RadioGroup, Stack, Table,
    TableBody, TableCell, TableContainer, TableHead, TableRow, TextField,
    Tooltip, Typography
} from '@mui/material'
import React, { Component } from 'react'
import { ImagePaths } from '../../../Utility/ImagePaths'
import {
    BillSuccessPoPUp, CommonCorporateAndInsuranceDetails,
    CommonPatientDetails, PharmaDiscardPop
} from '../../../Components/Common Components/CommonComponents'
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg'
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../Utility/API/Serviceurls'
import { withTranslation } from 'react-i18next'
import { AmountFormat } from '../../../Components/CommonFunctions/CommonFunctions'
import { Colors } from '../../../Styles/Colors';
import './PharmaHome.css';
import SearchIcon from '@mui/icons-material/Search';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateTime } from 'luxon'
import Loader from '../../../Components/Loader';
import { localGetItem } from '../../../Utility/Services/CacheProviderService';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { CurrencySymbol } from '../../../Utility/Constants';

let RoleData = null;
class ReturnMultipleBill extends Component {
    constructor(props) {
        super(props)
        this.QuantityRef = React.createRef();
        this.DrugNameRef = React.createRef();
        this.state = {
            discount: '',
            batchNo: '',
            drugDetailsData: [],
            pharmacyTblData: [
                {
                    drug_name: '',
                    dosage_strength: '',
                    invoice_number: '',
                    dosage_type: '',
                    batch_no: '',
                    expiry_date: '',
                    physical_box_no: '',
                    cost_per_quantity: '',
                    quantity: '',
                    return_quantity: '',
                    total_cost: '',
                    discount_percentage: '',
                    total_price: '',
                    hsn_code: '',
                    stock_in_quantity: '',
                    Drug_sugg_list: [],
                    gst_percentage: null,
                    total_return_price: "",
                    bill_discount: "",
                    payment_status: '',
                    id: '',
                    bill_types: '',
                    stock_list_ids: ''
                }
            ],
            isCard: false,
            isCash: false,
            isUPI: false,
            isBankTranfer: false,
            isCheque: false,
            isInsurance: false,
            CashAmount: "",
            CardAmount: "",
            CardTransactionNumber: "",
            UPINumber: "",
            UPIAmount: "",
            BankTfrAmount: "",
            BankTfrNumber: "",
            ChequeNumber: "",
            ChequeAmount: "",
            InsuranceAmount: "",
            InsuCompanyName: "",
            InsuPolicyNumber: "",
            InsuTransactionNumber: "",
            InsuValidity: '',
            subAmount: 0,
            totalPercentage: '',
            totalDiscount: '',
            roundOff: 0,
            netAmount: 0,
            creditPayment: false,
            receivePayment: false,
            savedBill: false,
            isCredit: false,
            DurgNameCurrentIndex: null,
            QuantityCurrentIndex: null,
            PatientDetails: {},
            BillDetails: {},
            PatientSearchClicked: false,
            SearchedPatientList: [],
            DoctorsList: [],
            doctordetails: {},
            PatientMobileNumber: "",
            PatientName: "",
            invoiceDateTime: new Date(),
            Patient_Id: null,
            Bill_ID: null,
            DateTimerOpen: false,
            ReceivedCashAmount: "",
            CashBalanceAmount: "",
            isFromInvoice: false,
            isForReturn: false,
            isFromSavedBill: false,
            isForInvoiceEdit: false,
            isFromIpRequest: false,
            isFromIpReturn: false,
            isFromPrescription: false,
            ShowPrescriptonHistory: false,
            CompletedBillID: null,
            ip_transmit_id: null,
            ConfigSetting: {},
            LoggedRole: null,
            totalBuyingPrice: 0,
            ShowHistory: false,
            SearchingDrugIndex: false,
            SearchingBatchIndex: false,
            isBillsFetched: false,
            InvoiceNumber: null,
            CorpCompanyName: "",
            CorpIDNumber: "",
            PrescHistoryData: null,
            invoiceNumber: '',
            OutStandingAmount: 0,
            ReturnedNetAmount: 0,
            prescription_transmitted_id: null,
            SelectedModuleType: 'IP',
            OpIPNumberList: [],
            OpIPNumberClicked: false,
            ReturnbillPopup: false,
            return_select_method: "Bill_Refund",
            BillListData: [],
            totalrefundAmount: 0,
            isForOnlyView: false,
            Popuptabledata: [],
            return_type: this.props.Returntype
        }
    }

    componentDidMount() {
        this.getDoctorsList()
        if (this.props.MasterBillData && Object.keys(this.props.MasterBillData).length > 0) {
            this.GetBillingDatas()
        }
        // this.GetPharmaSettings()
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.Returntype != this.props.Returntype) {
            this.setState({
                return_type: this.props.Returntype
            })
        }
    }

    GetPharmaSettings() {
        try {
            RestAPIService.getAll(Serviceurls.PHARMA_CONFIG_SETTINGS).
                then((response) => {
                    if (response.data.status === "succes") {
                        // localSetItem("PharmaSettings", JSON.stringify(response.data?.data))
                        // if (Object.keys(this.state.ConfigSetting) === 0) {
                        this.setState({ ConfigSetting: response.data?.data, return_type: response.data?.data?.return_bills_condition })
                        // }
                    }
                }).catch((error) => {
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    GetBillingDatas() {
        try {
            RestAPIService.getAll(Serviceurls.PHARMACY_BULK_RETURN_POST + `?summary_id=${this.props.MasterBillData?.id}`)
                .then((response) => {
                    if (response.data.status === "success") {
                        let ResponseData = response.data?.data ? response.data?.data[0] : {}
                        let PatielDeatilsObj = {
                            "patient_account_number": ResponseData?.patient_account_number
                        }
                        let BillLineItems = []
                        ResponseData?.bill_line_item?.forEach((item) => {
                            item.return_quantity = item.quantity
                            item.drug_name = item.drug_ms__drug_name
                            item.dosage_strength = item.drug_ms__drug_strength
                            item.dosage_type = item.drug_ms__dosagetype__dosage_type
                            item.total_return_price = item.net_total_amount
                            BillLineItems.push(item)
                        })
                        this.setState({
                            isForOnlyView: true,
                            PatientMobileNumber: ResponseData?.billing_customer_mobile_no,
                            PatientName: ResponseData?.billing_customer_name,
                            invoiceDateTime: ResponseData?.invoice_date,
                            SelectedOPorIpNumber: this.props.MasterBillData?.ip_admission ? ResponseData?.ip_number : ResponseData?.ip_number,
                            PatientDetails: PatielDeatilsObj,
                            SelectedModuleType: this.props.MasterBillData?.ip_admission ? "IP" : "OP",
                            pharmacyTblData: BillLineItems,
                            MasterBillDetails: ResponseData,
                            CashAmount: ResponseData?.cash_amount ? ResponseData?.cash_amount : "",
                            isCash: ResponseData?.cash_amount ? true : false,
                            isBankTranfer: ResponseData?.bank_amount ? true : false,
                            isUPI: ResponseData?.upi_amount ? true : false,
                            BankTfrAmount: ResponseData?.bank_amount ? ResponseData?.bank_amount : "",
                            BankTfrNumber: ResponseData?.bank_number ? ResponseData?.bank_number : "",
                            UPIAmount: ResponseData?.upi_amount ? ResponseData?.upi_amount : "",
                            UPINumber: ResponseData?.upi_number ? ResponseData?.upi_number : ""
                        })
                    }
                    else {
                        this.errorMessage(response.data?.message)
                    }
                }).catch((error) => {
                    this.errorMessage(error?.message)
                })
        }
        catch (e) {
            this.errorMessage(e?.message)
        }
    }

    getDoctorsList() {
        try {
            RestAPIService.getAll(Serviceurls.PHARMA_DOCTOR_LIST)
                .then((response) => {
                    if (response.data.status === "success") {
                        let DocList = []
                        response.data?.data.forEach((item) => {
                            item.displayKey = `${item?.name} | ${item?.doctor_reg_no}`
                            DocList.push(item)
                        })
                        this.setState({
                            DoctorsList: response.data?.data
                        })
                    }
                    else {
                        this.errorMessage(response.data?.message)
                    }
                }).catch((error) => {
                    this.errorMessage(error?.message)
                })
        }
        catch (e) {
            this.errorMessage(e?.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    DrugSearchLoader = (index) => {
        this.setState({
            SearchingDrugIndex: index
        })
    }
    BatchearchLoader = (index) => {
        this.setState({
            SearchingBatchIndex: index
        })
    }


    getBrandListData = (index) => {
        try {
            let { pharmacyTblData } = this.state
            if (pharmacyTblData[index]['drug_name'] !== "") {
                this.DrugSearchLoader(index)
                let QueryParams = ""
                if (this.state.PatientDetails?.ip_id) {
                    QueryParams = `?search=${CommonValidation.removeSpace(pharmacyTblData[index]['drug_name'])}&ip_id=${this.state.PatientDetails?.ip_id}`
                } else if (this.state.PatientDetails?.op_id) {
                    QueryParams = `?search=${CommonValidation.removeSpace(pharmacyTblData[index]['drug_name'])}&op_id=${this.state.PatientDetails?.op_id}`
                }
                RestAPIService.getAll(Serviceurls.PHARMA_RETURN_DRUG_GET + QueryParams)
                    .then((response) => {
                        if (response.data.status === 'success') {
                            if (response.data?.data?.length > 0) {
                                pharmacyTblData[index]['Drug_sugg_list'] = response.data.data
                                this.setState({ pharmacyTblData }, () => {
                                    this.DrugSearchLoader(null)
                                })
                            } else if (response.data?.data?.length === 0) {
                                this.DrugSearchLoader(null)
                                this.errorMessage("No Drug Found")
                            } else {
                                this.DrugSearchLoader(null)
                                this.errorMessage(response?.data?.message)
                            }
                        }
                    }).catch((error) => {
                        if (error?.response?.data?.message) {
                            this.DrugSearchLoader(null)
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.DrugSearchLoader(null)
                            this.errorMessage(error.message)
                        }
                    })
            }
            else {
                this.errorMessage("Enter Brand Name")
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getDrugDetailsData = (index, FromDrug = false) => {
        try {
            let { pharmacyTblData, PatientDetails } = this.state
            this.BatchearchLoader(index)
            let QueryParams = ""
            let DrugMsID = this.state.pharmacyTblData[index]?.drug_ms_id ? this.state.pharmacyTblData[index]?.drug_ms_id : this.state.pharmacyTblData[index]?.drug_id
            if (this.state.PatientDetails?.ip_id) {
                QueryParams = `?drug_ms_id=${DrugMsID}&ip_id=${this.state.PatientDetails?.ip_id}`
            } else if (this.state.PatientDetails?.op_id) {
                QueryParams = `?drug_ms_id=${DrugMsID}&op_id=${this.state.PatientDetails?.op_id}`
            }
            RestAPIService.getAll(Serviceurls.PHARMA_RETURN_BILLING_DRUG_DETAILS_GET + QueryParams)
                .then((response) => {
                    if (response.data.status === 'success') {
                        let batchSelected = false
                        let newValue = response.data.data
                        pharmacyTblData?.forEach(element => {
                            if (((element?.invoice_number === newValue[0]['invoice_number']) && (element?.drug_ms_id === newValue[0]['drug_ms_id']) && ((element?.batch_no === newValue[0]['batch_no'])))) {
                                batchSelected = true
                            }
                        })
                        pharmacyTblData[index]['Bill_Sugg_list'] = response.data.data
                        pharmacyTblData[index]['selected_bill'] = response.data.data
                        if (!batchSelected || response.data.data?.length > 1) {
                            if (response.data.data?.length > 0) {
                                // pharmacyTblData[index]['Bill_Sugg_list'] = response.data.data
                                // pharmacyTblData[index]['selected_bill'] = response.data.data
                                pharmacyTblData[index]['quantity'] = response.data.data[0]?.quantity
                                pharmacyTblData[index]['return_quantity'] = ''
                                if (response.data.data?.length === 1) {
                                    let rtnDetails = localGetItem("pharmacy_rtn_period")
                                    let rtnData = JSON.parse(rtnDetails)
                                    let billDate = response.data.data[0]?.bill_summary__invoice_date?.slice(0, 10)
                                    let isReturn = false
                                    if (rtnData?.rtn_type === 'Days') {
                                        let beforeDate = CommonValidation.getDateBefore(rtnData?.rtn_value)
                                        let configDate = DateTime.fromJSDate(beforeDate).toFormat('yyyy-MM-dd')
                                        if (new Date(billDate) >= new Date(configDate)) {
                                            isReturn = true;
                                        }
                                    } else if (rtnData?.rtn_type === 'Weeks') {
                                        let beforeDate = CommonValidation.getDateBeforeWeeks(rtnData?.rtn_value)
                                        let configDate = DateTime.fromJSDate(beforeDate).toFormat('yyyy-MM-dd')
                                        if (new Date(billDate) >= new Date(configDate)) {
                                            isReturn = true;
                                        }
                                    } else if (rtnData?.rtn_type === 'Months') {
                                        let beforeDate = CommonValidation.getDateBeforeMonths(rtnData?.rtn_value)
                                        let configDate = DateTime.fromJSDate(beforeDate).toFormat('yyyy-MM-dd')
                                        if (new Date(billDate) >= new Date(configDate)) {
                                            isReturn = true;
                                        }
                                    }
                                    if (isReturn) {
                                        pharmacyTblData[index]['invoice_number'] = response.data.data[0]?.invoice_number
                                        pharmacyTblData[index]['bill_summary_id'] = response.data.data[0]?.bill_summary_id
                                        pharmacyTblData[index]['returned_qty'] = response.data.data[0]?.returned_qty
                                        pharmacyTblData[index]['discount_percentage'] = response.data.data[0]?.discount_percentage
                                        pharmacyTblData[index]['expiry_date'] = response.data.data[0]?.expiry_date
                                        pharmacyTblData[index]['physical_box_no'] = response.data.data[0]?.physical_box_no
                                        pharmacyTblData[index]['batch_no'] = response.data.data[0]?.batch_no
                                        pharmacyTblData[index]['cost_per_quantity'] = response.data.data[0]?.cost_per_quantity
                                        pharmacyTblData[index]['hsn_code'] = response.data.data[0]?.hsn_code
                                        pharmacyTblData[index]['stock_in_quantity'] = response.data.data[0]?.stock_in_quantity
                                        pharmacyTblData[index]['buying_price_per_piece'] = response.data.data[0]?.buying_price_per_piece
                                        pharmacyTblData[index]['drug_ms_id'] = response.data.data[0]?.drug_ms_id
                                        pharmacyTblData[index]['schedule'] = response.data.data[0]?.schedule
                                        pharmacyTblData[index]['total_cost'] = ""
                                        pharmacyTblData[index]['total_price'] = ""
                                        pharmacyTblData[index]['id'] = response.data.data[0]?.id
                                        pharmacyTblData[index]['stock_list_ids'] = response.data.data[0]?.stock_list_ids
                                        pharmacyTblData[index]['bill_types'] = response.data.data[0]?.bill_types
                                        pharmacyTblData[index]['payment_status'] = response.data.data[0]?.payment_status
                                        pharmacyTblData[index]['bill_discount'] = response.data.data[0]?.bill_discount
                                        pharmacyTblData[index]['gst_percentage'] = response.data.data[0]?.gst_percentage
                                    } else {
                                        this.errorMessage("Invoice Date exceeded Return Period ")
                                    }
                                }
                                this.setState({ pharmacyTblData, QuantityCurrentIndex: index }, () => {
                                    this.BatchearchLoader(null)
                                    this.calculateDiscountTotalPrice(index)
                                })
                                this.QuantityRef?.current?.focus();
                            } else {
                                pharmacyTblData[index]['Bill_Sugg_list'] = []
                                this.errorMessage("Drug was not billed for this patient")
                                this.setState({ pharmacyTblData, QuantityCurrentIndex: index }, () => { this.BatchearchLoader(null) })
                            }
                        } else {
                            this.BatchearchLoader(null)
                        }
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.BatchearchLoader(null)
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.BatchearchLoader(null)
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }


    postPharmacyBillData = (checkUnPaid) => {
        try {
            let states = this.state
            let url = Serviceurls.PHARMACY_BULK_RETURN_POST
            let Lineitems = []
            this.state.pharmacyTblData.forEach((item) => {
                if (item.drug_name !== "" && item?.return_quantity !== "") {
                    let BillItem = {
                        "drug_id": item.drug_ms_id,
                        "hsn_code": item.hsn_code,
                        "batch_no": item.batch_no,
                        "expiry_date": item.expiry_date,
                        "physical_box_no": item.physical_box_no,
                        "cost_per_quantity": item.cost_per_quantity,
                        "total_cost": item.total_cost,
                        "discount_percentage": +item.discount_percentage,
                        "general_discount_percentage": +item.bill_discount,
                        "general_discount": +item.bill_discount_amount,
                        "gst_percentage": item.gst_percentage ? item.gst_percentage : 0,
                        "quantity": +item.return_quantity,
                        "net_total_amount": +item.total_return_price,
                        "bill_summary_id": item.bill_summary_id,
                        "invoice_number": item.invoice_number,
                        "sub_total_amount": item.sub_total_amount,
                        "id": item.id,
                        "stock_list_ids": item.stock_list_ids,
                        "bill_type": item.bill_types,
                        "payment_status": item.payment_status,
                        "return_received_amount": item.payment_status === "paid" || item.payment_status === "partial" ? this.state.ReturnbillPopup ? +item?.return_amount : +item.total_return_price : 0,
                        "add_to_advance": null,
                        "gst_percentage": item.gst_percentage
                    }
                    Lineitems.push(BillItem)
                }
            })

            let Datetime = new Date(this.state.invoiceDateTime)
            let date = DateTime.fromJSDate(Datetime).toFormat("yyyy-MM-dd")
            let time = DateTime.fromJSDate(Datetime).toLocaleString(DateTime.TIME_24_SIMPLE)

            let PaymentTypeData = [];
            if (this.state.isCash) {
                PaymentTypeData.push(1)
            }
            if (this.state.isCard) {
                PaymentTypeData.push(2)
            }
            if (this.state.isUPI) {
                PaymentTypeData.push(3)
            }
            if (this.state.isBankTranfer) {
                PaymentTypeData.push(5)
            }
            let data = {
                "gen_refund_receipt": states?.netAmount > 0 ? true : false,
                "invoice_date": `${date} ${time}:00`,
                "billing_customer_name": states.PatientName,
                "billing_customer_mobile_no": states?.PatientMobileNumber,
                "doctor_name": states.doctordetails?.name ? states.doctordetails?.name : states?.PatientDetails?.doctor_name,
                "patient_id": states.PatientDetails?.patient_id,
                "patient_account_number": states?.PatientDetails?.patient_account_number,
                "ip_number": states?.PatientDetails?.ip_id ? states?.SelectedOPorIpNumber : null,
                "ip_admission_id": states?.PatientDetails?.ip_id ? states?.PatientDetails?.ip_id : null,
                "appointment_id": states?.PatientDetails?.ip_id ? null : states?.PatientDetails?.op_id,
                "discount_amount": 0,
                "taxable_amount": states.ReturnedNetAmount,
                "general_discount": 0,
                "cgst_amount": 0,
                "sgst_amount": 0,
                "igst_amount": 0,
                "tax_amount": 0,
                "round_off": states.roundOff,
                "delivery_charge": 0,
                "gross_amount": states.subAmount,
                "grand_total": states.subAmount,
                "net_amount": states.ReturnedNetAmount ? states.ReturnedNetAmount : (states.netAmount),
                "bill_line_items": Lineitems,
                "bill_type": "Return",
                "received_amount": states.netAmount,
                "cash_amount": +states.CashAmount,
                "upi_amount": +states.UPIAmount,
                "bank_amount": +states.BankTfrAmount,
                "cheque_amount": +states.ChequeAmount,
                "payment_modes": PaymentTypeData ? PaymentTypeData : [],
                "doctor_id": states.doctordetails?.doctor_id,
                "reference_no": states.BankTfrNumber,
                "transaction_id": states.UPINumber
            }
            let amount = (+states.UPIAmount) + (+states.CashAmount) + (+states.BankTfrAmount)
            let AmtIsMatched = states?.ReturnbillPopup ? (amount != states.totalrefundAmount) : false
            if ((states.UPIAmount !== '' && states.UPINumber === '') || (states.CardAmount !== '' && states.CardTransactionNumber === '')) {
                this.errorMessage('Enter Reference Number')
            } else if (AmtIsMatched) {
                this.errorMessage("Entered Amount is Not Matched With Refundable Amount")
            } else if (((states.isCash == false && states.isCard == false && states.isUPI == false && states.isBankTranfer == false && states.isCheque == false) && (!checkUnPaid) && (!states?.ReturnbillPopup)) || ((states?.totalrefundAmount != 0) && states?.ReturnbillPopup && (states.isCash == false && states.isCard == false && states.isUPI == false && states.isBankTranfer == false && states.isCheque == false))) {
                this.errorMessage("Please Select Mode of payment")
            } else {
                this.setState({ isLoader: true })
                RestAPIService.create(data, url)
                    .then((response) => {
                        if (response.data.status === 'success') {
                            this.setState({
                                ReturnbillPopup: false,
                                CompletedBillID: response.data.data?.id,
                                invoiceNumber: response?.data?.data?.invoice_number
                            }, () => {
                                this.setState({ isLoader: false })
                                this.successMessage(response.data.message)
                                // this.DiscardAction(true)
                            })
                        }
                    }).catch(error => {
                        if (error?.response?.data?.message) {
                            this.setState({ isLoader: false })
                            this.errorMessage(error.response.data.message)
                            this.setState({
                                creditPayment: false,
                                receivePayment: false,
                                savedBill: false
                            })
                        } else {
                            this.setState({ isLoader: false })
                            this.errorMessage(error.message)
                            this.setState({
                                creditPayment: false,
                                receivePayment: false,
                                savedBill: false
                            })
                        }
                    })
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }



    calculateDiscountTotalPrice = (index) => {
        try {
            let { pharmacyTblData } = this.state
            let totalCost = pharmacyTblData[index]['total_cost']
            // let discount = pharmacyTblData[index]['discount_percentage'] + (pharmacyTblData[index]['bill_discount'] ? +pharmacyTblData[index]['bill_discount'] : 0)
            let discount = pharmacyTblData[index]['discount_percentage']
            let discountValue = CommonValidation.calculatePercentageWithoutRoundoff(discount, totalCost)
            let TotalNetCost = totalCost - discountValue
            pharmacyTblData[index]['sub_total_amount'] = +(totalCost - discountValue).toFixed(2)
            let BillDiscount = (pharmacyTblData[index]['bill_discount'] ? +pharmacyTblData[index]['bill_discount'] : 0)
            let BillDiscountValue = CommonValidation.calculatePercentageWithoutRoundoff(BillDiscount, TotalNetCost)
            pharmacyTblData[index]['bill_discount_amount'] = BillDiscountValue
            pharmacyTblData[index]['total_return_price'] = +(TotalNetCost - BillDiscountValue).toFixed(2)
            this.setState({ pharmacyTblData }, () => {
                this.calculateTotalDiscount()
                this.SetFullCashAmount()
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    calculateCostAndTotal = (value, index) => {
        try {
            let { pharmacyTblData } = this.state
            pharmacyTblData[index]['total_cost'] = +value * pharmacyTblData[index]['cost_per_quantity']
            let totalCost = pharmacyTblData[index]['total_cost']
            let discount = pharmacyTblData[index]['discount_percentage']
            let discountValue = CommonValidation.calculatePercentageWithoutRoundoff(discount, totalCost)
            let TotalNetCost = totalCost - discountValue
            pharmacyTblData[index]['sub_total_amount'] = +(totalCost - discountValue).toFixed(2)
            let BillDiscount = (pharmacyTblData[index]['bill_discount'] ? +pharmacyTblData[index]['bill_discount'] : 0)
            let BillDiscountValue = CommonValidation.calculatePercentageWithoutRoundoff(BillDiscount, TotalNetCost)
            pharmacyTblData[index]['bill_discount_amount'] = BillDiscountValue
            pharmacyTblData[index]['total_return_price'] = +(TotalNetCost - BillDiscountValue).toFixed(2)
            this.setState({ pharmacyTblData }, () => this.calculateTotalDiscount())
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    calculateTotalDiscount = () => {
        try {
            this.state.totalDiscount = CommonValidation.calculatePercentageWithoutRoundoff(this.state.subAmount, this.state.totalPercentage)
            if (!this.state.isForReturn) {
                this.state.totalPercentage = CommonValidation.amountPercentage(this.state.totalDiscount, this.state.subAmount)
            }
            this.setState({
                totalDiscount: this.state.totalDiscount,
                totalPercentage: this.state.totalPercentage
            }, () => { this.SetFullCashAmount() })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onChangeHandler = (e, index) => {
        try {
            const { name, value } = e.target
            switch (name) {
                case 'quantity':
                    if (this.state.pharmacyTblData[index]['drug_name']) {
                        if (((CommonValidation.numberOnly(value) && value?.length < 7) || value === '')) {
                            this.state.pharmacyTblData[index]['quantity'] = value
                            this.setState({
                                pharmacyTblData: this.state.pharmacyTblData
                            }, () => {
                                this.calculateCostAndTotal(value, index, false)
                                this.calculateMarginProfit()
                            })
                        }
                    } else {
                        this.errorMessage('Select Brand Name')
                    }
                    break;
                case 'return_quantity':
                    if (this.state.pharmacyTblData[index]['drug_name']) {
                        if ((CommonValidation.numberOnly(value) && value?.length < 7) && (value != 0)) {
                            this.state.pharmacyTblData[index]['return_quantity'] = value
                            this.setState({
                                pharmacyTblData: this.state.pharmacyTblData
                            }, () => {
                                this.calculateCostAndTotal(value, index, true)
                                this.calculateMarginProfit()
                                if ((this.state?.isForReturn || this.state?.isFromIpReturn) && +this.state.netAmount === 0 && this.state.subAmount && +this.state.subAmount > 0) {
                                    this.setState({
                                        isCard: false,
                                        isCash: false,
                                        isUPI: false,
                                        isBankTranfer: false,
                                        isCheque: false,
                                        isInsurance: false,
                                        CashAmount: "",
                                        CardAmount: "",
                                        CardTransactionNumber: "",
                                        UPINumber: "",
                                        UPIAmount: "",
                                        BankTfrAmount: "",
                                        BankTfrNumber: "",
                                        ChequeNumber: "",
                                        ChequeAmount: "",
                                        InsuranceAmount: "",
                                        InsuCompanyName: "",
                                        InsuPolicyNumber: "",
                                        InsuTransactionNumber: "",
                                        InsuValidity: '',
                                    })
                                }
                            })
                        } else if ((value === '') || (value === 0)) {
                            this.state.pharmacyTblData[index]['return_quantity'] = ''
                            this.state.pharmacyTblData[index]['total_cost'] = ''
                            this.state.pharmacyTblData[index]['total_return_price'] = ''
                            this.setState({
                                totalDiscount: 0,
                                pharmacyTblData: this.state.pharmacyTblData
                            })
                        }
                    } else {
                        this.errorMessage('Select Brand Name')
                    }
                    break;
                case 'discount':
                    if (this.state.pharmacyTblData[index]['quantity']) {
                        if ((CommonValidation.DecimalNumber(value) && value <= 100) || value === '') {
                            this.state.pharmacyTblData[index]['discount'] = value
                            this.setState({
                                pharmacyTblData: this.state.pharmacyTblData
                            }, () => {
                                this.calculateDiscountTotalPrice(index)
                                this.calculateMarginProfit()
                            })
                        }
                    } else {
                        this.errorMessage('Enter Quantity')
                    }
                    break;
                case 'bill_discount':
                    if (this.state.pharmacyTblData[index]['return_quantity']) {
                        if ((CommonValidation.DecimalNumber(value) && value <= 100) || value === '') {
                            this.state.pharmacyTblData[index]['bill_discount'] = value
                            this.setState({
                                pharmacyTblData: this.state.pharmacyTblData
                            }, () => {
                                this.calculateDiscountTotalPrice(index)
                                this.calculateMarginProfit()
                                this.state.pharmacyTblData.forEach((item, billIndex) => {
                                    if (this.state.pharmacyTblData[index]['bill_summary_id'] === item?.bill_summary_id) {
                                        this.state.pharmacyTblData[billIndex]['bill_discount'] = value
                                        this.setState({ pharmacyTblData: this.state.pharmacyTblData }, () => {
                                            this.calculateDiscountTotalPrice(billIndex)
                                        })
                                    }
                                })
                            })
                        }
                    } else {
                        this.errorMessage('Enter Quantity')
                    }
                    break;
                case 'totalDiscount':
                    if ((CommonValidation.NumberWithDot(value) && value >= 1 && value <= this.state.subAmount) || value === "") {
                        this.state.totalPercentage = CommonValidation.amountPercentage(value, this.state.subAmount)
                        this.setState({
                            totalDiscount: value
                        }, () => {
                            this.calculateMarginProfit()
                        })
                    }
                    break;
                case 'totalPercentage':
                    if ((CommonValidation.DecimalNumber(value) && value >= 1 && +value <= 100) || value === "") {
                        this.state.totalDiscount = CommonValidation.calculatePercentageWithoutRoundoff(value, this.state.subAmount)
                        this.setState({
                            totalPercentage: value
                        }, () => {
                            this.calculateMarginProfit()
                        })
                    }
                    break;
                default:
                    this.setState({
                        [name]: value
                    })
                    break;
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    disabled = (names, index) => {
        try {
            let disable = true
            switch (names) {
                case 'quantity':
                    if (this.state.pharmacyTblData[index]['drug_name'] && !this.state.isFromInvoice && !this.state.isFromIpReturn && ((this.state.isFromSavedBill && this.state.LoggedRole === "user") ? (RoleData?.permission_access?.pharma?.isEditSave) : true)) {
                        disable = false
                    }
                    break;
                case 'discount':
                    if (this.state.pharmacyTblData[index]['quantity'] && !this.state.isFromInvoice && !this.state.isFromIpReturn && (this.state.LoggedRole !== "user" || (RoleData?.permission_access?.pharma?.isDiscountEdit && RoleData?.permission_access?.pharma?.isEditSave))) {
                        disable = false
                    }
                    break;
                case 'batch_no':
                    if (!this.state.isForOnlyView && this.state.pharmacyTblData[index]['drug_name'] && this.state.pharmacyTblData[index]['dosage_strength'] && this.state.pharmacyTblData[index]['dosage_type'] && !this.state.isFromInvoice && !this.state.isFromIpReturn && ((this.state.isFromSavedBill && this.state.LoggedRole === "user") ? (RoleData?.permission_access?.pharma?.isEditSave) : true)) {
                        disable = false
                    }
                    break;
                case 'drug_name':
                    disable = false;
                    break;
                case 'return_quantity':
                    if (this.state.pharmacyTblData[index]['drug_name']) {
                        disable = false;
                    }
                    break;
                case 'bill_discount':
                    if (this.state.pharmacyTblData[index]['drug_name']) {
                        disable = false;
                    }
                    break;
                default:
                    disable = true;
                    break;
            }
            return disable;
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    inputTextBox = (values, names, labels, width, index, height, disable) => {
        return (
            <TextField
                inputRef={this.state.isFromIpRequest ? null : ((names === "return_quantity" && this.state.QuantityCurrentIndex === index) ? this.QuantityRef : null)}
                autoFocus
                size='small'
                disabled={disable ? disable : this.disabled(names, index) || this.state.isForOnlyView}
                variant='standard'
                sx={{ width: width }}
                placeholder={"0"}
                onKeyDown={(e) => { if (e.key === "Enter") { this.DrugNameRef?.current.focus() } }}
                name={names}
                autoComplete='off'
                inputProps={{
                    emed_tid: labels,
                    style: {
                        height: height ? height : '2vh', marginTop: '0.5vw', textAlign: 'center',
                        color: (names === "return_quantity" && this.state.pharmacyTblData[index]["drug_name"] !== "") ? ((this.state.pharmacyTblData[index]["quantity"] - (this.state.pharmacyTblData[index]["returned_qty"] ? +this.state.pharmacyTblData[index]["returned_qty"] : 0)) < this.state.pharmacyTblData[index]["return_quantity"] ? "red" : null) : null
                    }
                }}
                value={values}
                onChange={(e) => { this.onChangeHandler(e, index) }}
                error={(names === "return_quantity" && this.state.pharmacyTblData[index]["drug_name"] !== "") ? ((this.state.pharmacyTblData[index]["quantity"] - (this.state.pharmacyTblData[index]["returned_qty"] ? +this.state.pharmacyTblData[index]["returned_qty"] : 0)) < this.state.pharmacyTblData[index]["return_quantity"] ? true : false) : false}
            />
        )
    }

    renderTextBox = (names, value, height, diasble) => {
        try {
            return (
                <TextField
                    autoFocus
                    variant='standard'
                    autoComplete='off'
                    size='small'
                    InputProps={{
                        startAdornment: names === "totalDiscount" ? <InputAdornment position="start">₹</InputAdornment> : null,
                        endAdornment: names === "totalPercentage" ? <InputAdornment position="start">%</InputAdornment> : null
                    }}
                    className={'eMed_discount_txt'}
                    disabled={diasble || this.state.isForReturn || this.state.isFromIpReturn || (this.state.isFromInvoice)}
                    inputProps={{ style: { height: height }, emed_tid: names }}
                    name={names}
                    value={value}
                    onChange={(e) => { this.onChangeHandler(e, names) }}
                />
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    autocompleteOnChangeHandler = (newValue, names, index) => {
        try {
            let { pharmacyTblData, PatientDetails } = this.state
            let avoid_drugDiscount = this.state.ConfigSetting;
            let avoidDiscountIP = (avoid_drugDiscount?.show_discount_ip && PatientDetails?.ip_admission_id);
            let avoidDiscountOP = (avoid_drugDiscount?.show_discount_op && PatientDetails?.appointment_id);
            let NewArray = []; NewArray.push(newValue)

            // if (pharmacyTblData[index][names]?.length > 0) {
            switch (names) {
                case 'drug_name':
                    pharmacyTblData[index][names] = newValue['drug_name']
                    pharmacyTblData[index]['dosage_strength'] = newValue['dosage_strength']
                    pharmacyTblData[index]['dosage_type'] = newValue['dosage_type']
                    pharmacyTblData[index]['drug_ms_id'] = newValue['drug_ms_id']
                    pharmacyTblData[index]['quantity'] = ""
                    pharmacyTblData[index]['total_cost'] = ""
                    pharmacyTblData[index]['total_price'] = ""
                    pharmacyTblData[index]['discount'] = ""
                    pharmacyTblData[index]['id'] = newValue['id']
                    pharmacyTblData[index]['stock_list_ids'] = newValue['stock_list_ids']
                    pharmacyTblData[index]['bill_types'] = newValue['bill_types']
                    pharmacyTblData[index]['payment_status'] = newValue['payment_status']
                    pharmacyTblData[index]['gst_percentage'] = newValue['gst_percentage']
                    this.setState({ pharmacyTblData, QuantityCurrentIndex: index }, () => this.getDrugDetailsData(index, true))
                    this.QuantityRef?.current?.focus();
                    break;
                case 'batch_no':
                    let batchSelected = false
                    pharmacyTblData?.forEach(element => {
                        if (((element?.invoice_number === newValue['invoice_number']) && (element?.drug_ms_id === newValue['drug_ms_id']) && ((element?.batch_no === newValue['batch_no'])) && (element.expiry_date === newValue['expiry_date']) && (element.cost_per_quantity === newValue['cost_per_quantity']))) {
                            batchSelected = true
                        }
                    })
                    if (!batchSelected) {
                        pharmacyTblData[index]['invoice_number'] = newValue['invoice_number']
                        pharmacyTblData[index]['bill_summary_id'] = newValue['bill_summary_id']
                        pharmacyTblData[index]['returned_qty'] = newValue['returned_qty']
                        pharmacyTblData[index]['discount_percentage'] = newValue['discount_percentage']
                        pharmacyTblData[index]['batch_no'] = newValue['batch_no']
                        pharmacyTblData[index]['expiry_date'] = newValue['expiry_date']
                        pharmacyTblData[index]['cost_per_quantity'] = newValue['cost_per_quantity']
                        pharmacyTblData[index]['stock_in_quantity'] = newValue['stock_in_quantity']
                        pharmacyTblData[index]['drug_ms_id'] = newValue['drug_ms_id']
                        pharmacyTblData[index]['buying_price_per_piece'] = newValue['buying_price_per_piece']
                        pharmacyTblData[index]['quantity'] = newValue['quantity']
                        pharmacyTblData[index]['total_cost'] = ""
                        pharmacyTblData[index]['total_price'] = ''
                        pharmacyTblData[index]['return_quantity'] = ''
                        pharmacyTblData[index]['id'] = newValue['id']
                        pharmacyTblData[index]['stock_list_ids'] = newValue['stock_list_ids']
                        pharmacyTblData[index]['bill_types'] = newValue['bill_types']
                        pharmacyTblData[index]['payment_status'] = newValue['payment_status']
                        pharmacyTblData[index]['bill_discount'] = newValue['bill_discount']
                        pharmacyTblData[index]['gst_percentage'] = newValue['gst_percentage']
                        if (PatientDetails?.ip_admission_id) { pharmacyTblData[index]['discount'] = avoidDiscountIP ? 0 : newValue['sales_discount_for_bill'] }
                        else if (PatientDetails?.appointment_id) { pharmacyTblData[index]['discount'] = avoidDiscountOP ? 0 : newValue['sales_discount_for_bill'] }
                        else { pharmacyTblData[index]['discount'] = newValue['sales_discount_for_bill'] }
                        pharmacyTblData[index]['schedule'] = newValue['schedule']
                        pharmacyTblData[index]['hsn_code'] = newValue['hsn_code']
                        pharmacyTblData[index]['physical_box_no'] = newValue['physical_box_no']
                        pharmacyTblData[index]['selected_bill'] = NewArray
                        // pharmacyTblData[index]['Bill_Sugg_list'] = newValue
                        this.setState({ pharmacyTblData, QuantityCurrentIndex: index, BatchAnchor: null, CurrentBatchIndex: null }, () => {
                            this.calculateCostAndTotal(0, index, false)
                            this.calculateMarginProfit()
                            this.calculateDiscountTotalPrice(index)
                            // if (pharmacyTblData[index]['quantity'] === "") { 
                            this.QuantityRef?.current?.focus()
                            //  }
                        })
                    } else {
                        this.errorMessage(`${newValue['batch_no']} Batch No Already Exists`)
                    }
                    break;
                default: break;
            }
            // }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onInputChangeAutoCompleteHandler = (value, names, index) => {
        try {
            let { pharmacyTblData } = this.state
            switch (names) {
                case 'drug_name':
                    this.state.pharmacyTblData[index]['drug_name'] = value
                    this.setState({ pharmacyTblData })
                    break;
                case 'batch_no':
                    this.state.pharmacyTblData[index]['batch_no'] = value
                    this.setState({ pharmacyTblData })
                    break;
                default: break;
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderAmountTextFields = (label, key, Icon) => {
        var states = this.state
        let { t } = this.props;
        return (
            <TextField
                inputProps={{ emed_tid: label }}
                sx={{ padding: 0, margin: 0, width: "10vw" }}
                className='eMed_CrdPop_Amount_TextFields'
                size='small'
                disabled={this.state.isForOnlyView || this.state.forCancel || (this.state.isForReturn ? false : this.state.isFromInvoice)}
                label={t(label)}
                autoComplete='off'
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <img src={Icon} alt={label} className='eMed_action_img_small' />
                        </InputAdornment>
                    ),
                }}
                value={states[key]}
                onChange={(e) => {
                    let number = CommonValidation.NumberWithDot(e.target.value);
                    if ((number && +e.target.value > 0) || e.target.value === "") {
                        states[key] = e.target.value
                        this.setState({
                            states
                        }, () => {
                            if (key === "CashAmount") {
                                this.setState({
                                    CashBalanceAmount: this.state.ReceivedCashAmount === "" ? "" : +e.target.value - +this.state.ReceivedCashAmount
                                })
                            }
                        })
                    }
                }}
            />
        )
    }

    renderNumberTextFields = (label, key, Right = false) => {
        var states = this.state
        let { t } = this.props;
        return (
            <TextField
                className='eMed_CrdPop_Amount_TextFields'
                sx={{ marginLeft: Right ? "1vw" : 0, width: "10vw" }}
                inputProps={{ maxLength: 20, emed_tid: label }}
                disabled={this.state.isForOnlyView || this.state.forCancel || key === "CashBalanceAmount" || (key === "ReceivedCashAmount" ? (this.state.CashAmount === "" ? true : false) : false) || (this.state.isForReturn ? false : this.state.isFromInvoice)}
                focused={key === "CashBalanceAmount"}
                size='small'
                label={t(label)}
                value={states[key]}
                autoComplete='off'
                onChange={(e) => {
                    let number = CommonValidation.gstnumberValidation(e.target.value);
                    let onlynumber = CommonValidation.numberOnly(e.target.value);
                    if (key === "ReceivedCashAmount") {
                        if (onlynumber || e.target.value === "") {
                            this.setState({
                                ReceivedCashAmount: e.target.value,
                                CashBalanceAmount: e.target.value === "" ? "" : +this.state.CashAmount - +e.target.value
                            })
                        }
                    }
                    else {
                        if (number || e.target.value === "") {
                            states[key] = e.target.value.toUpperCase()
                            this.setState({
                                states
                            })
                        }
                    }
                }
                }
            />
        )
    }

    handleCheckBox = (e, key, refundAmount) => {
        try {
            var states = this.state
            states[key] = e.target.checked
            this.setState({ states }, () => {
                if (!states.isCash) { this.setState({ CashAmount: "", CashBalanceAmount: "", ReceivedCashAmount: "" }) }
                if (!states.isCard) { this.setState({ CardAmount: "", CardTransactionNumber: "",ReceivedCashAmount: "",CashBalanceAmount: "", }) }
                if (!states.isUPI) { this.setState({ UPIAmount: "", UPINumber: "",ReceivedCashAmount: "",CashBalanceAmount: "", }) }
                if (!states.isBankTranfer) { this.setState({ BankTfrAmount: "", BankTfrNumber: "",ReceivedCashAmount: "",CashBalanceAmount: "", }) }
                if (refundAmount || (states.ReturnbillPopup && refundAmount == 0)) {
                    this.setState({
                        netAmount: refundAmount,
                        totalrefundAmount: refundAmount,
                        states
                    }, () => { this.SetFullCashAmount() })
                } else {
                    this.SetFullCashAmount()
                }
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    SetFullCashAmount = () => {
        let states = this.state
        if (states.isCash && !states.isCard && !states.isUPI && !states.isBankTranfer && (!states?.isFromInvoice || states?.isForReturn)) {
            this.setState({ CashAmount: this.state.netAmount, })  // Fetch Full amount in Cash Amount, when only the cash is selected
        }
        if (!states?.subAmount) {
            this.setState({
                isCard: false,
                isCash: false,
                isUPI: false,
                isBankTranfer: false,
                isCheque: false,
                isInsurance: false,
                CashAmount: "",
                CardAmount: "",
                CardTransactionNumber: "",
                UPINumber: "",
                UPIAmount: "",
                BankTfrAmount: "",
                BankTfrNumber: "",
                ChequeNumber: "",
                ChequeAmount: "",
                InsuranceAmount: "",
                InsuCompanyName: "",
                InsuPolicyNumber: "",
                InsuTransactionNumber: "",
                InsuValidity: '',
            })
        }
    }
    calculateMarginProfit = () => {
        var { pharmacyTblData } = this.state
        // calculate margin cumulative sum
        for (let i = 0; i < pharmacyTblData.length; i++) {
            let total_buying_price_drug = 0
            if (pharmacyTblData[i].batch_no != "") {
                let profit = parseFloat(pharmacyTblData[i]["cost_per_quantity"]) - parseFloat(pharmacyTblData[i]["buying_price_per_piece"]).toFixed(2)
                pharmacyTblData[i]["margin_profit"] = (profit * (+(pharmacyTblData[i]["quantity"])))

                total_buying_price_drug = (parseFloat(pharmacyTblData[i]["buying_price_per_piece"] * (+(pharmacyTblData[i]["quantity"]))))
                pharmacyTblData[i]["total_buying_price_per_drug"] = total_buying_price_drug

                this.setState({
                    pharmacyTblData
                }, () => {
                    // calculate total margin profit
                    const margin_profit = this.state.pharmacyTblData && this.state.pharmacyTblData.length > 0 && this.state.pharmacyTblData.map((item) => (item.batch_no === "" ? 0 : +(item.margin_profit))).reduce((prev, curr) => (prev + curr));
                    // calculate total buying price
                    const total_buying_price = this.state.pharmacyTblData && this.state.pharmacyTblData.length > 0 && this.state.pharmacyTblData.map(item => (item.batch_no === "" ? 0 : +(item.total_buying_price_per_drug))).reduce((prev, curr) => (prev + curr));

                    this.setState({
                        totalMarginProfit: margin_profit,
                        totalBuyingPrice: total_buying_price
                    })
                })
            }
        }

    }

    billingCalculation = (params = [], type) => {
        try {
            let amount = 0;
            switch (type) {
                case 'totalServiceAmount':
                    params.forEach(element => {
                        amount = amount + (element.total_return_price ? element.total_return_price : 0)
                    })
                    if (this.state.subAmount !== +amount) {
                        this.setState({
                            subAmount: +amount
                        }, () => {
                            this.calculateTotalDiscount()
                        })
                    }
                    return AmountFormat(this.state.subAmount || 0)
                case 'marginProfit':
                    let finalMarginProfit = this.state.netAmount - this.state.totalBuyingPrice
                    return AmountFormat(+this.state.netAmount === 0 ? 0 : (finalMarginProfit ? parseFloat(finalMarginProfit).toFixed(2) : 0))
                case 'roundOff':
                    this.state.roundOff = CommonValidation.roundOff(this.state.subAmount, this.state.totalDiscount)
                    return AmountFormat(+this.state.roundOff === 0 ? 0 : (this.state.roundOff > 0 ? `+ ${this.state.roundOff}` : `- ${this.state.roundOff * -1}`));
                case 'payableAmount':
                    // if (this.state.isForReturn || this.state.isFromIpReturn) {
                    let discount = this.state.totalDiscount ? this.state.totalDiscount : 0
                    let netpayableAmount = Math.round(this.state.subAmount - discount)
                    // if (this.state.ReturnedNetAmount !== (this.state.subAmount ? Math.round(+netpayableAmount) : 0)) {
                    //     this.setState({
                    //         ReturnedNetAmount: this.state.subAmount ? Math.round(+netpayableAmount) : 0
                    //     })
                    // }
                    // OutStandingValue = netpayableAmount ? (Math.round(+netpayableAmount) - this.state.OutStandingAmount) : 0
                    // if (this.state.netAmount !== (Math.round(+OutStandingValue) > 0 ? Math.round(+OutStandingValue) : 0)) {
                    //     this.setState({
                    //         netAmount: Math.round(+OutStandingValue) > 0 ? Math.round(+OutStandingValue) : 0
                    //     }, () => { this.SetFullCashAmount() })
                    // }
                    if (this.state.netAmount !== +netpayableAmount) {
                        this.setState({
                            netAmount: netpayableAmount
                        })
                    }
                    return (this.state.netAmount && this.state.netAmount > 0) ? AmountFormat(this.state.netAmount || 0) : AmountFormat(0)
                // } else {
                //     let discount = this.state.totalDiscount ? this.state.totalDiscount : 0
                //     payableAmount = this.state.subAmount - discount
                //     if (this.state.netAmount !== (Math.round(+payableAmount))) {
                //         this.setState({
                //             netAmount: Math.round(+payableAmount)
                //         }, () => { this.SetFullCashAmount() })
                //     }
                //     return AmountFormat(this.state.netAmount)
                // }
                case 'outstandingAmount':
                    return (this.state.OutStandingAmount && this.state.OutStandingAmount > 0) ? AmountFormat(this.state.OutStandingAmount || 0) : "0.00"
                default: break;
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onAddHandler = (names, index) => {
        try {
            if (this.state.pharmacyTblData[index]['drug_name']) {
                if (names === 'quantity' || names === 'discount') {
                    if (index === this.state.pharmacyTblData?.length - 1) {
                        let duplicateTbl = {
                            drug_name: '',
                            dosage_strength: '',
                            return_quantity: '',
                            invoice_number: '',
                            dosage_type: '',
                            batch_no: '',
                            expiry_date: '',
                            physical_box_no: '',
                            cost_per_quantity: '',
                            quantity: '',
                            total_cost: '',
                            discount: '',
                            total_price: '',
                            hsn_code: '',
                            stock_in_quantity: '',
                            Drug_sugg_list: [],
                            Bill_Sugg_list: [],
                            total_return_price: "",
                            bill_discount: "",
                            id: '',
                            stock_list_ids: '',
                            payment_status: '',
                            bill_types: ''
                        }
                        this.state.pharmacyTblData = [...this.state.pharmacyTblData, duplicateTbl]
                        this.setState({
                            pharmacyTblData: this.state.pharmacyTblData
                        }, () => {
                            this.setState({
                                drugDetailsData: []
                            }, () => {
                                // this.DrugNameRef?.current.focus()
                            })
                        })
                    }
                }
            } else {
                this.errorMessage('Enter All Fields')
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onClearTableData = () => {
        let TableHasValues = false
        this.state.pharmacyTblData.forEach((list) => {
            if (list.drug_name !== "") { TableHasValues = true }
        })
        if (TableHasValues) {
            this.setState({ ShowDiscard: true })
        } else {
            this.DiscardAction(true)
        }
    }

    PharmacyPatientSearch = (ShowErrMsg = true) => {
        try {
            let { t } = this.props;
            this.setState({ isPatientisSearching: true })
            let LoggedDetails = localGetItem("loggedInUserInfo") === null ? {} : JSON.parse(localGetItem("loggedInUserInfo"))
            let url = ''
            if (LoggedDetails?.pharmacy_type === "Standalone Pharmacy") {
                url = Serviceurls.PHARMA_HEADER_SEARCH + `?search_key=${CommonValidation.removeSpace(this.state.PatientMobileNumber)}`
            } else {
                url = Serviceurls.APPOINTMENT_HOME + `?search_key=${CommonValidation.removeSpace(this.state.PatientMobileNumber)}&clinic_appointment=true`
            }
            RestAPIService.getAll(url).
                then((response) => {
                    if (response.data.status === "success") {
                        const uniquePatients = Object.values(response.data.data.reduce((acc, curr) => {
                            acc[curr.patient_id] = curr;
                            return acc;
                        }, {}));
                        this.setState({
                            SearchedPatientList: uniquePatients,
                            isPatientisSearching: false
                        }, () => {
                            if (this.state.PatientMobileNumber !== "") {
                                if (uniquePatients?.length > 0) {
                                    this.setState({ PatientSearchClicked: true })
                                } else {
                                    if (ShowErrMsg) { this.errorMessage(t("NoRecordsFound")) }
                                }
                            }
                        })
                    }
                    else {
                        this.setState({ isPatientisSearching: false })
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.setState({ isPatientisSearching: false })
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.setState({ isPatientisSearching: false })
            this.errorMessage(e.message)
        }
    }

    GetPatientAdmissionHistory = (Patient_Id = null, Op_Id = null, Ip_Id = null, OpIpNumSearch = "") => {
        try {
            let { t } = this.props;
            this.setState({ isAdmHistorySearching: Patient_Id ? true : false })
            let url = ''
            if (Patient_Id) {
                url = Serviceurls.PHARMA_PATIENT_ADMISSION_HISTORY + `?module=${this.state.SelectedModuleType}&patient_id=${Patient_Id}`
            } else if (Op_Id) {
                url = Serviceurls.PHARMA_PATIENT_ADMISSION_HISTORY + `?module=${this.state.SelectedModuleType}&op_id=${Op_Id}`
            } else if (Ip_Id) {
                url = Serviceurls.PHARMA_PATIENT_ADMISSION_HISTORY + `?module=${this.state.SelectedModuleType}&ip_id=${Ip_Id}`
            } else if (OpIpNumSearch && this.state.SelectedModuleType === "OP") {
                url = Serviceurls.PHARMA_PATIENT_ADMISSION_HISTORY + `?module=${this.state.SelectedModuleType}&op_number=${OpIpNumSearch}`
            } else if (OpIpNumSearch && this.state.SelectedModuleType === "IP") {
                url = Serviceurls.PHARMA_PATIENT_ADMISSION_HISTORY + `?module=${this.state.SelectedModuleType}&ip_number=${OpIpNumSearch}`
            }
            RestAPIService.getAll(url).
                then((response) => {
                    if (response.data.status === "success") {
                        let Response = response.data.data
                        if (Patient_Id) {
                            if (Response.length > 0) {
                                this.setState({
                                    OpIPNumberList: Response,
                                    OpIPNumberClicked: true,
                                    isAdmHistorySearching: false
                                })
                            } else {
                                this.setState({
                                    isAdmHistorySearching: false
                                }, () => { this.errorMessage("Patient has no appoinments") })
                            }
                        } else if (Op_Id || Ip_Id || OpIpNumSearch) {
                            if (Object.keys(Response)?.length > 0) {
                                this.setState({
                                    PatientDetails: Response,
                                    PatientName: Response?.patient_name,
                                    PatientMobileNumber: Response?.mobile_number,
                                    OutStandingAmount: Response?.outstanding_amount ? Response?.outstanding_amount : 0,
                                    doctordetails: this.state.DoctorsList?.find((item) => (item?.doctor_id === Response?.doctor_id))
                                })
                            } else {
                                this.errorMessage("No Records Found")
                            }
                        }
                    }
                    else {
                        this.setState({ isAdmHistorySearching: false })
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.setState({ isAdmHistorySearching: false })
                    if(error.response.data.status === 'fail'){
                        this.errorMessage(error.response.data.message)
                    }else{
                        this.errorMessage(error.message)
                    }
                })
        }
        catch (e) {
            this.setState({ isAdmHistorySearching: false })
            this.errorMessage(e.message)
        }
    }

    renderSearch(label, statekey, img, Data = []) {
        const { t } = this.props;
        let states = this.state;
        return (
            <Box className="eMed_Ip_Est_ptCard" height={"3.5vw"} width={(statekey === "PatientMobileNumber" || statekey === "invoiceDateTime") ? "14vw" : "18vw"}>
                <Box component={'div'} className='eMed_pharma_header_icon_warp'>
                    <img className='eMed_pharma_header_icon_img' src={img ? img : ImagePaths.Male.default} alt="male" />
                </Box>
                <Box component={'div'} >
                    {statekey === "ipopNumber" ?
                        <Box display={'flex'}>
                            <Box width={"14.5vw"}>
                                {/* <Typography fontSize={"0.8vw"} color={"#685c5c"} fontWeight={500}>{states.SelectedModuleType === "ip" ? "IP Number" : states.SelectedModuleType === "op"  ? "OP Number" : "IP / OP Number"}</Typography> */}
                                {/* {PatientDetails?.length > 27 ?
                                    <Tooltip placement='top' title={PatientDetails} arrow>
                                        <Typography fontSize={"0.9vw"}>{PatientDetails.slice(0, 27) + "..."}</Typography>
                                    </Tooltip>
                                    : <Typography fontSize={"0.9vw"}>{PatientDetails}</Typography>} */}
                                <TextField
                                    sx={{ width: "15vw" }}
                                    inputProps={{ maxLength: 40, emed_tid: "IP / OP Number" }}
                                    label={states.SelectedModuleType === "IP" ? "IP Number" : states.SelectedModuleType === "OP" ? "OP Number" : "IP / OP Number"}
                                    variant='standard'
                                    size="small"
                                    autoComplete="off"
                                    name={"OPorIpNumber"}
                                    value={states["SelectedOPorIpNumber"] ? states["SelectedOPorIpNumber"] : ""}
                                    placeholder={"Search"}
                                    onKeyDown={(event) => {
                                        if (event.key === "Enter" && event.target.value?.length > 2) {
                                            this.GetPatientAdmissionHistory(null, null, null, event.target.value)
                                        }
                                    }}
                                    disabled={Object.keys(this.props.MasterBillData).length > 0}
                                    onChange={(e) => {
                                        let value = e.target.value
                                        // let AlphaNum = CommonValidation.alphanumValidation(value);
                                        // if (AlphaNum || value === "") {
                                        states["SelectedOPorIpNumber"] = value
                                        states["PatientName"] = ""
                                        states["PatientMobileNumber"] = ""
                                        // }
                                        this.setState({
                                            states, OPIPNumberAnchor: e.currentTarget, PatientDetails: {}, Patient_Id: null, Bill_ID: null, doctordetails: {}, pharmacyTblData: [
                                                {
                                                    drug_name: '',
                                                    dosage_strength: '',
                                                    invoice_number: '',
                                                    return_quantity: '',
                                                    dosage_type: '',
                                                    batch_no: '',
                                                    expiry_date: '',
                                                    physical_box_no: '',
                                                    cost_per_quantity: '',
                                                    quantity: '',
                                                    total_cost: '',
                                                    discount: '',
                                                    total_price: '',
                                                    hsn_code: '',
                                                    stock_in_quantity: '',
                                                    Drug_sugg_list: [],
                                                    total_return_price: "",
                                                    bill_discount: ""
                                                }
                                            ],
                                        }, () => {
                                            // if (statekey === "PatientMobileNumber" && CommonValidation.numberOnly(states.PatientMobileNumber) && states.PatientMobileNumber.length === 10) {
                                            //     this.PharmacyPatientSearch(false)
                                            // } else {
                                            //     this.setState({ SearchedPatientList: [] })
                                            // }
                                        })
                                    }}
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment position="end">
                                                {
                                                    this.state.isAdmHistorySearching ?
                                                        <Box sx={{ display: 'flex', alignItems: "center", justifyContent: "center" }}>
                                                            <InputAdornment position="end"><CircularProgress size={15} /></InputAdornment>
                                                        </Box> :
                                                        <Box sx={{ display: 'flex', alignItems: "center", justifyContent: "center" }}>
                                                            <InputAdornment position="end"><SearchIcon size={15} /></InputAdornment>
                                                        </Box>}
                                            </InputAdornment>
                                    }}
                                />
                            </Box>
                            <Menu
                                // anchorEl={this.state.OPIPNumberAnchor}
                                open={this.state.OpIPNumberClicked}
                                className='eMed_Pharma_IPOP_Search_Pop'
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                onClose={() => {
                                    this.setState({
                                        OpIPNumberList: [],
                                        OpIPNumberClicked: false
                                    })
                                }}
                                elevation={1}
                            >
                                {
                                    this.state.OpIPNumberList.length > 0 ?
                                        this.state.OpIPNumberList.map((list, index) => (
                                            <MenuItem emed_tid="Patient_mobile_suggestions"
                                                autoFocus={index === 0}
                                                onClick={() => {
                                                    this.setState({
                                                        OpIPNumberList: [],
                                                        OpIPNumberClicked: false,
                                                        SelectedOPorIpNumber: this.state.SelectedModuleType === "OP" ? list?.op_number : list?.ip_number
                                                    }, () => {
                                                        if (this.state.SelectedModuleType === "OP") {
                                                            this.GetPatientAdmissionHistory(null, list?.op_id, null)
                                                        } else {
                                                            this.GetPatientAdmissionHistory(null, null, list?.ip_id)
                                                        }
                                                    })
                                                }}
                                                sx={{
                                                    backgroundColor: list?.is_current ? '#f8af7d' : 'white',
                                                    '&:hover': { backgroundColor: list?.is_current ? '#fa944d' : '#f0f0f0', },
                                                    '&:focus': { backgroundColor: list?.is_current ? '#fa944d' : '#ded9d9', outline: 'none', },
                                                    width: '30vw'
                                                }}
                                                key={index}>
                                                <Box width={'16vw'}><Typography fontSize={'.9vw'}>{this.state.SelectedModuleType === "OP" ? `${(list?.op_number || "-")} - (${list?.date || ''})` : `${(list?.ip_number || "-")} - (${list?.date || ''})`}</Typography></Box>
                                            </MenuItem>
                                        )) : null
                                }
                            </Menu>
                        </Box>
                        : statekey === "doctordetails" ?
                            <Autocomplete
                                disabled={Object.keys(this.props.MasterBillData).length > 0}
                                size='small'
                                clearIcon={false}
                                options={Data}
                                label={t(label)}
                                getOptionLabel={(item) => (typeof (item.displayKey) === "string" ? item.displayKey : "")}
                                value={states[statekey] ? states[statekey] : ""}
                                ListboxProps={{ style: { maxHeight: 300 } }}
                                onChange={(event, newValue) => {
                                    states[statekey] = newValue
                                    this.setState({ states })
                                }}
                                sx={{ width: '15vw' }}
                                renderInput={(params) => (
                                    <TextField
                                        disabled={this.state.isFromIpRequest || this.state.isFromIpReturn || this.state.isFromPrescription}
                                        size='small'
                                        {...params}
                                        placeholder='Enter Doctor Name Here'
                                        label={t(label)}
                                        autoComplete='off'
                                        variant='standard'
                                        inputProps={{
                                            ...params.inputProps,
                                            'emed_tid': 'doctor name',
                                        }}
                                    />
                                )}
                            />
                            :
                            statekey === "invoiceDateTime" ?
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateTimePicker
                                        disabled={true}
                                        emed_tid="Invoice DateTime"
                                        open={states.DateTimerOpen}
                                        onOpen={() => { this.setState({ DateTimerOpen: true }) }}
                                        onClose={() => { this.setState({ DateTimerOpen: false }) }}
                                        maxDateTime={new Date(Date.now())}
                                        label={t(label)}
                                        value={states[statekey]}
                                        inputFormat='DD-MM-YYYY & hh:mmA'
                                        onChange={(value) => {
                                            if (value === null) {
                                                states[statekey] = new Date()
                                            } else {
                                                states[statekey] = value
                                            }
                                            this.setState({ states })
                                        }}
                                        renderInput={(params) => <TextField
                                            size="small" {...params}
                                            variant='standard'
                                            required
                                            autoComplete='off'
                                            onKeyDown={(e) => e.preventDefault()}
                                            onClick={() => { this.setState({ DateTimerOpen: true }) }}
                                            sx={{ width: "12vw" }}
                                        />}
                                    />
                                </LocalizationProvider>
                                :
                                statekey === "PatientName" ? <Box width={"15vw"}>
                                    <Typography fontSize={"0.8vw"}>Name : {states?.PatientName}</Typography>
                                    <Typography fontSize={"0.8vw"}>UHID : {states?.PatientDetails?.patient_account_number ? states?.PatientDetails?.patient_account_number : "-"}</Typography>
                                </Box> :
                                    <TextField
                                        disabled={statekey === "PatientName" || this.state.isForOnlyView}
                                        sx={{ width: statekey === "PatientMobileNumber" ? "12vw" : "15vw" }}
                                        inputProps={{ maxLength: 40, emed_tid: statekey === "PatientMobileNumber" ? "Patient Mobile Number" : "Patient Name" }}
                                        label={label}
                                        variant='standard'
                                        size="small"
                                        autoComplete="off"
                                        name={statekey}
                                        value={states[statekey] ? states[statekey] : ""}
                                        placeholder={statekey === "PatientMobileNumber" ? "Search" : "Patient Name"}
                                        onKeyDown={(event) => {
                                            if (event.key === "Enter" && event.target.value?.length > 2) {
                                                this.PharmacyPatientSearch()
                                            }
                                        }}
                                        onChange={(e) => {
                                            let value = e.target.value
                                            let alpha = CommonValidation.alphabetOnly(value);
                                            let number = CommonValidation.numberOnly(value);
                                            let AlphaNum = CommonValidation.alphanumValidation(value);
                                            if (statekey === "PatientMobileNumber") {
                                                if (AlphaNum || value === "") {
                                                    states[statekey] = value
                                                    states["PatientName"] = ""
                                                    states["SelectedOPorIpNumber"] = ""
                                                }
                                            } else if (statekey === "PatientName") {
                                                if (alpha || value === "") {
                                                    states[statekey] = value
                                                    if (states?.PatientDetails?.patient_id) {
                                                        states["PatientMobileNumber"] = ""
                                                    }
                                                }
                                            } else {
                                                if (alpha || value === "") {
                                                    states[statekey] = value
                                                }
                                            }
                                            this.setState({
                                                states, MobileNumberAnchor: e.currentTarget, PatientDetails: {}, Patient_Id: null, Bill_ID: null, doctordetails: {}, pharmacyTblData: [
                                                    {
                                                        drug_name: '',
                                                        dosage_strength: '',
                                                        invoice_number: '',
                                                        return_quantity: '',
                                                        dosage_type: '',
                                                        batch_no: '',
                                                        expiry_date: '',
                                                        physical_box_no: '',
                                                        cost_per_quantity: '',
                                                        quantity: '',
                                                        total_cost: '',
                                                        discount: '',
                                                        total_price: '',
                                                        hsn_code: '',
                                                        stock_in_quantity: '',
                                                        Drug_sugg_list: [],
                                                        total_return_price: "",
                                                        bill_discount: ""
                                                    }
                                                ],
                                            }, () => {
                                                if (statekey === "PatientMobileNumber" && CommonValidation.numberOnly(states.PatientMobileNumber) && states.PatientMobileNumber.length === 10) {
                                                    this.PharmacyPatientSearch(false)
                                                } else {
                                                    this.setState({ SearchedPatientList: [] })
                                                }
                                            })
                                        }}
                                        InputProps={{
                                            endAdornment: statekey === "PatientMobileNumber" ?
                                                <InputAdornment position="end">
                                                    {
                                                        this.state.isPatientisSearching ?
                                                            <Box sx={{ display: 'flex', alignItems: "center", justifyContent: "center" }}>
                                                                <InputAdornment position="end"><CircularProgress size={15} /></InputAdornment>
                                                            </Box> :
                                                            <IconButton size="small"
                                                                disabled={states.PatientMobileNumber === "" || this.state.isFromInvoice || this.state.isFromSavedBill || this.state.isForInvoiceEdit || this.state.isFromIpRequest || this.state.isFromIpReturn || this.state.isFromPrescription}
                                                                onClick={() => {
                                                                    if (states.PatientMobileNumber.length > 2) {
                                                                        this.PharmacyPatientSearch()
                                                                    }
                                                                }}>
                                                                <SearchIcon />
                                                            </IconButton>}
                                                </InputAdornment> : null
                                        }}
                                    />}
                </Box>
                <Menu
                    open={this.state.PatientSearchClicked}
                    className='eMed_Pharma_Mobile_Search_Pop'
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    onClose={() => {
                        this.setState({
                            SearchedPatientList: [],
                            PatientSearchClicked: false
                        })
                    }}
                    elevation={1}
                >
                    {
                        this.state.SearchedPatientList.length > 0 ?
                            this.state.SearchedPatientList.map((list, index) => (
                                <MenuItem emed_tid="Patient_mobile_suggestions"
                                    autoFocus={index === 0}
                                    onClick={() => {
                                        this.setState({
                                            SearchedPatientList: [],
                                            PatientSearchClicked: false,
                                        }, () => { this.GetPatientAdmissionHistory(list?.patient_id) })
                                    }}
                                    key={index}>
                                    <Box><CommonPatientDetails data={list} /></Box>
                                </MenuItem>
                            )) : null
                    }
                </Menu>
            </Box>
        )
    }

    deleteLineItems = (index = null) => {
        try {
            let BillingLineItems = []
            this.state.pharmacyTblData.forEach((item) => { if (item.drug_name !== "") { BillingLineItems.push(item) } })
            if (index === null || (index === 0 && BillingLineItems.length === 1)) {
                this.setState({
                    pharmacyTblData: [
                        {
                            drug_name: '',
                            dosage_strength: '',
                            invoice_number: '',
                            return_quantity: '',
                            dosage_type: '',
                            batch_no: '',
                            expiry_date: '',
                            physical_box_no: '',
                            cost_per_quantity: '',
                            quantity: '',
                            total_cost: '',
                            discount: '',
                            total_price: '',
                            hsn_code: '',
                            stock_in_quantity: '',
                            Drug_sugg_list: [],
                            total_return_price: "",
                            bill_discount: ""
                        }
                    ],
                    isCard: false,
                    isCash: false,
                    isUPI: false,
                    isBankTranfer: false,
                    isCheque: false,
                    isInsurance: false,
                    CashAmount: "",
                    CardAmount: "",
                    CardTransactionNumber: "",
                    UPINumber: "",
                    UPIAmount: "",
                    BankTfrAmount: "",
                    BankTfrNumber: "",
                    ChequeNumber: "",
                    ChequeAmount: "",
                    InsuranceAmount: "",
                    InsuCompanyName: "",
                    InsuPolicyNumber: "",
                    InsuTransactionNumber: "",
                    InsuValidity: '',
                    subAmount: 0,
                    totalPercentage: '',
                    totalDiscount: '',
                    roundOff: 0,
                    netAmount: 0,
                    ReturnedNetAmount: 0,
                })
            } else {
                if (index !== this.state.pharmacyTblData.length - 1) {
                    this.state.pharmacyTblData.splice(index, 1)
                    this.setState({ pharmacyTblData: this.state.pharmacyTblData, totalDiscount: "" }, () => this.calculateTotalDiscount())
                } else {
                    this.state.pharmacyTblData[index] = {
                        drug_name: '',
                        dosage_strength: '',
                        return_quantity: '',
                        invoice_number: '',
                        dosage_type: '',
                        batch_no: '',
                        expiry_date: '',
                        physical_box_no: '',
                        cost_per_quantity: '',
                        quantity: '',
                        total_cost: '',
                        discount: '',
                        total_price: '',
                        hsn_code: '',
                        stock_in_quantity: '',
                        Drug_sugg_list: [],
                        total_return_price: "",
                        bill_discount: ""
                    }
                    this.setState({ pharmacyTblData: this.state.pharmacyTblData, totalDiscount: "" }, () => this.calculateTotalDiscount())
                }
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    FullAmountEntered() {
        try {
            let states = this.state;
            let CashAmt = states.CashAmount === "" ? 0 : +states.CashAmount;
            let CardAmt = states.CardAmount === "" ? 0 : +states.CardAmount;
            let UPIAmt = states.UPIAmount === "" ? 0 : +states.UPIAmount;
            let bankTransferAmt = states.BankTfrAmount === "" ? 0 : +states.BankTfrAmount;
            let ChequeAmt = states.ChequeAmount === "" ? 0 : +states.ChequeAmount;
            let InsuranceAmt = states.InsuranceAmount === "" ? 0 : +states.InsuranceAmount;
            let visibility = this.state.pharmacyTblData?.every(item => item.payment_status === 'not paid' || item.payment_status === '')
            if (visibility) {
                return true
            } else {
                if ((CashAmt + CardAmt + UPIAmt + bankTransferAmt + ChequeAmt + InsuranceAmt) == +states.netAmount && +states.netAmount !== 0) {
                    return true
                } else if (+states.netAmount === 0 && states.subAmount && +states.subAmount > 0 && (CashAmt + CardAmt + UPIAmt + bankTransferAmt + ChequeAmt + InsuranceAmt) === 0) {
                    return true
                }
                else {
                    return false
                }
            }
        }
        catch (e) {
            return false
        }
    }

    BillItemsIsValid = () => {
        try {
            let Flag = false
            let BillingLineItems = []
            this.state.pharmacyTblData.forEach((item) => { if (item.drug_name !== "") { BillingLineItems.push(item) } })
            if (BillingLineItems.length > 0) {
                for (let i = 0; i < BillingLineItems.length; i++) {
                    if ((BillingLineItems[i]["drug_name"] !== "" && BillingLineItems[i]["invoice_number"] !== "" && BillingLineItems[i]["quantity"] !== "" && BillingLineItems[i]["return_quantity"] !== "" && BillingLineItems[i]["quantity"] !== "0" && BillingLineItems[i]["return_quantity"] !== "0") && BillingLineItems.length > 0) {
                        if ((BillingLineItems[i]["return_quantity"] <= BillingLineItems[i]["quantity"]) && (BillingLineItems[i]["return_quantity"] <= (+BillingLineItems[i]["quantity"] - BillingLineItems[i]["returned_qty"])) && BillingLineItems[i]["return_quantity"] !== "" && BillingLineItems[i]["quantity"] !== "" && BillingLineItems[i]["quantity"] !== "0" && BillingLineItems[i]["return_quantity"] !== "0") {
                            Flag = true
                        } else {
                            if ((BillingLineItems[i]["return_quantity"] > BillingLineItems[i]["quantity"])) {
                                Flag = false
                                this.errorMessage(`${BillingLineItems[i]["drug_name"]} Return Quantity is More then Billed Quantity`)
                                break
                            } else if ((BillingLineItems[i]["return_quantity"] > (+BillingLineItems[i]["quantity"] - BillingLineItems[i]["returned_qty"]))) {
                                Flag = false
                                this.errorMessage(`${BillingLineItems[i]["drug_name"]}, ALreday Returned ${BillingLineItems[i]["returned_qty"]} Quantity. Balance Quantity is ${(+BillingLineItems[i]["quantity"] - BillingLineItems[i]["returned_qty"])}`)
                                break
                            } else if (BillingLineItems.some((list, index) => (list?.bill_summary_id === BillingLineItems[i]["bill_summary_id"] && list?.drug_id === BillingLineItems[i]["drug_id"] && list?.batch_no === BillingLineItems[i]["batch_no"] && index !== i))) {
                                Flag = false
                                this.errorMessage(`${BillingLineItems[i]["drug_name"]} from ${BillingLineItems[i]["invoice_number"]} has duplicate.`)
                                break
                            }
                        }
                    } else {
                        if (BillingLineItems[i]["drug_name"] === "") { this.errorMessage("No drug were Entered") }
                        else if (BillingLineItems[i]["invoice_number"] === "") { this.errorMessage(`${BillingLineItems[i]["drug_name"]} "Bill were Not Selected`) }
                        else if (BillingLineItems[i]["return_quantity"] === "" || BillingLineItems[i]["quantity"] === "0") { this.errorMessage(`${BillingLineItems[i]["drug_name"]} Return Quantity is Not Entered`) }
                        else { this.errorMessage("Invalid Bill Items") }
                        Flag = false
                        break
                    }
                }
                return Flag
            } else {
                Flag = false
                this.errorMessage("No Drug were Added")
                return Flag
            }

        } catch (e) {
            this.errorMessage(e.message)
        }
    }

    Validation = () => {
        try {
            let states = this.state;
            let isHaveScheduledDrug = false
            for (let i = 0; i < this.state.pharmacyTblData.length; i++) {
                if (this.state.pharmacyTblData[i]?.drug_name !== "") {
                    isHaveScheduledDrug = this.isScheduledDrug(i)
                    if (isHaveScheduledDrug) { break }
                }
            }
            let DoctorValidation = isHaveScheduledDrug ? (states.doctordetails?.doctor_id ? true : false) : true
            if (states.PatientName !== "" && this.BillItemsIsValid()) {
                let checkPaid = this.state.pharmacyTblData?.every(item => (item?.payment_status === 'paid' || item?.payment_status === ''));
                let checkUnPaid = this.state.pharmacyTblData?.every(item => item?.payment_status === 'not paid' || item?.payment_status === '');
                if (checkPaid || checkUnPaid) {
                    this.postPharmacyBillData(checkUnPaid)
                } else if (!checkPaid || !checkUnPaid) {

                    const invoicesStr = JSON.stringify(this.state.pharmacyTblData);
                    const invoices = JSON.parse(invoicesStr);
                    const invoiceNumbers = new Set();
                    let hasDuplicate = false;
                    invoices.forEach(invoice => {
                        if (invoiceNumbers.has(invoice.invoice_number)) {
                            hasDuplicate = true;
                            const existingInvoice = invoices.find(item => item.invoice_number === invoice.invoice_number);

                            existingInvoice.quantity = +existingInvoice.quantity + +invoice.quantity;
                            existingInvoice.total_return_price = +existingInvoice.total_return_price + +invoice.total_return_price;
                            existingInvoice.number_of_items = (existingInvoice.number_of_items || 1) + 1;
                            // if (existingInvoice.selected_bill[0]) {
                            //     existingInvoice.selected_bill[0].paid_amount = +existingInvoice.selected_bill[0].paid_amount + +invoice.selected_bill[0].paid_amount;
                            //     // existingInvoice.selected_bill[0].outstanding_amount = +existingInvoice.selected_bill[0].outstanding_amount + +invoice.selected_bill[0].outstanding_amount;
                            // } else {
                            //     existingInvoice.selected_bill.paid_amount = +existingInvoice.selected_bill.paid_amount + +invoice.selected_bill.paid_amount;
                            //     // existingInvoice.selected_bill.outstanding_amount = +existingInvoice.selected_bill.outstanding_amount + +invoice.selected_bill.outstanding_amount;
                            // }
                        } else {
                            invoiceNumbers.add(invoice.invoice_number);
                            invoice.number_of_items = 1;
                        }
                    });
                    if (hasDuplicate) {
                        const uniqueInvoices = invoices.filter((invoice, index) => {
                            return invoices.findIndex(item => item.invoice_number === invoice.invoice_number) === index;
                        });
                        this.setState({
                            Popuptabledata: uniqueInvoices,
                            ReturnbillPopup: true,
                        });
                    } else {
                        this.setState({
                            Popuptabledata: this.state.pharmacyTblData,
                            ReturnbillPopup: true,
                        })
                    }
                }
            } else {
                if (states.PatientName === "") { this.errorMessage("Enter Patient Name") }
                else if (!DoctorValidation && !this.state.isForReturn && !this.state.isFromIpReturn) { this.errorMessage("Bill Contains a Scheduled drug, so please select the doctor") }
            }
        } catch (e) {
            this.errorMessage(e.message)
        }
    }

    renderdisableButton = (visible, returnMethod) => {
        // if(!visible ? false : this.state?.isLoader ? true : returnMethod == true ? true : !this.FullAmountEntered())
        if (this.state?.isLoader || returnMethod) {
            return true
        } else if (!visible && !returnMethod) {
            return false
        } else {
            return !this.FullAmountEntered()
        }
    }

    renderPaymentButton = (visible, returnMethod) => {
        let states = this.state
        try {
            return (
                <Box component={'div'} display={'flex'} sx={{ marginTop: "-3vw" }}>
                    {/* <Box flex={0.7} display={'flex'} alignItems={'center'} marginLeft={'1vw'} >
                        {(+states.ReturnedNetAmount > 0 && +states.OutStandingAmount > 0) ?
                            <Box component={'div'} pl={'1vw'} marginTop={'0.5vw'} overflow={'scroll'} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                <ErrorOutlineIcon sx={{ fontSize: "1.3vw", marginRight: '0.5vw', color: 'red' }} />
                                <Typography className='eMed_ReturnCredit_Bill_Label'>{`₹ ${states?.ReturnedNetAmount ? (states?.ReturnedNetAmount < states?.OutStandingAmount ? states?.ReturnedNetAmount : states?.OutStandingAmount) : 0} will be deducted from Outstanding Amount`}</Typography>
                            </Box> : null}
                    </Box> */}
                    <Box flex={1} display={'flex'} justifyContent={'flex-end'}>
                        {
                            this.state.isForOnlyView ?
                                <>
                                    <Typography sx={{ margin: '0.75vw 3vw 0.5vw 0vw' }} fontSize={"0.9vw"} fontWeight={600} color={"red"}>*E. & O.E</Typography>
                                    <Button emed_tid="bill_receive_payment_btn" sx={{ margin: '0.5vw 1vw 0.5vw 0vw', textTransform: 'capitalize' }}
                                        size='small'
                                        variant='contained'
                                        onClick={() => { this.props.history.push({ pathname: '/PharmacyInvoice/Invoice' }) }}
                                    >{"Back To Overall Bills"}</Button>
                                </>
                                :
                                <>
                                    <Typography sx={{ margin: '0.75vw 3vw 0.5vw 0vw' }} fontSize={"0.9vw"} fontWeight={600} color={"red"}>*E. & O.E</Typography>
                                    <Button emed_tid="bill_clear_btn" sx={{ margin: '0.5vw 1vw 0.5vw 0vw', textTransform: 'capitalize' }}
                                        disabled={this.state.isForReturn ? false : this.state.isFromInvoice}
                                        size='small'
                                        variant='outlined'
                                        onClick={() => this.onClearTableData()}
                                    >Clear</Button>
                                    <Button emed_tid="bill_receive_payment_btn" sx={{ margin: '0.5vw 1vw 0.5vw 0vw', textTransform: 'capitalize', width: "10vw" }}
                                        size='small'
                                        variant='contained'
                                        disabled={this.renderdisableButton(visible, returnMethod)}
                                        onClick={() => { this.Validation() }}
                                    >{"Return"}</Button>
                                </>
                        }

                    </Box>
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderReturnMethod = () => {
        let checkPaid = this.state.pharmacyTblData?.filter(bill => bill.payment_status === "paid");
        let checkUnPaid = this.state.pharmacyTblData?.filter(bill => bill.payment_status === "not paid");
        let checkPartial = this.state.pharmacyTblData?.filter(bill => bill.payment_status === "partial");
        if ((this.state.return_type && ((checkPaid.length > 0) && (checkUnPaid.length == 0 && checkPartial.length == 0))) || (this.state.return_type && checkUnPaid.length > 0 && (checkPaid.length == 0 && checkPartial.length == 0))) {
            return false
        } else if (!this.state.return_type) {
            return false
        } else {
            return true
        }
    }

    customPharmaFooter = (params) => {
        try {
            const { t } = this.props
            let states = this.state
            let checkPaid = this.state.pharmacyTblData?.every(item => (item?.payment_status === 'paid' || item?.payment_status === ''));
            let checkUnPaid = this.state.pharmacyTblData?.every(item => (item?.payment_status === 'not paid' || item?.payment_status === ''));
            let visible = checkPaid || (this.state.MasterBillDetails?.cash_amount || this.state.MasterBillDetails?.card_amount || this.state.MasterBillDetails?.upi_amount)
            let returnMethod = this.renderReturnMethod()
            return (
                <Box component={'div'} height={'10vw'} className='eMed_Phrama_Table_Container'>
                    <Box component={'div'} display={'flex'}>
                        <Box flex={0.75} overflow={'scroll'}>
                            {/* <Box component={'div'} border={'1px solid lightgray'} pl={'1vw'} marginTop={'0.5vw'} height={'9vw'} overflow={'scroll'} visibility={+states.netAmount === 0 && states.subAmount && +states.subAmount > 0 ? "hidden" : "visible"}> */}
                            <Box component={'div'} border={'1px solid lightgray'} pl={'1vw'} marginTop={'0.5vw'} height={'9vw'} overflow={'scroll'} visibility={visible ? 'visible' : 'hidden'}>
                                <Box component={'div'} style={{ marginTop: "0.5vw" }} className='eMed_Pharma_payment_card'>
                                    <Typography fontWeight={600} fontSize={'0.9vw'}>Mode Of Payment</Typography>
                                </Box>
                                <Box component={'div'} className=''>
                                    <Box>
                                        <FormControlLabel disabled={this.state.isForOnlyView} className='eMed_CrdPop_CheckBoxDiv' control={<Checkbox emed_tid="cash_checkbox" checked={this.state.isCash} onChange={(e) => { this.handleCheckBox(e, "isCash") }} size='small' />} label={t("Cash")} />
                                        <FormControlLabel disabled={this.state.isForOnlyView} className='eMed_CrdPop_CheckBoxDiv' control={<Checkbox emed_tid="upi_checkbox" checked={this.state.isUPI} onChange={(e) => { this.handleCheckBox(e, "isUPI") }} size='small' />} label={t("UPI")} />
                                        <FormControlLabel disabled={this.state.isForOnlyView} className='eMed_CrdPop_CheckBoxDiv' sx={{ width: "10vw" }} control={<Checkbox emed_tid="bank_trans_checkbox" checked={this.state.isBankTranfer} onChange={(e) => { this.handleCheckBox(e, "isBankTranfer") }} size='small' />} label={t("BankTransfer")} />
                                    </Box>
                                    <Box component={'div'} display={'flex'} ml={'0.5vw'}>
                                        {
                                            this.state.isCash ?
                                                <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"}>
                                                    {this.renderAmountTextFields("CashAmount", "CashAmount", ImagePaths.CashIcon.default)}
                                                    {states.isCash && !states.isCard && !states.isUPI && !states.isBankTranfer && !states.isInsurance ?
                                                        <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"}>
                                                            {this.renderNumberTextFields("Cash Collected", "ReceivedCashAmount", true)}
                                                            {this.renderNumberTextFields("Balance Amount", "CashBalanceAmount", true)}
                                                        </Box> : null
                                                    }
                                                </Box> : null
                                        }
                                        {
                                            this.state.isCard ?
                                                <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"}>
                                                    {this.renderAmountTextFields("CardAmount", "CardAmount", ImagePaths.CardIcon.default)}
                                                    {this.renderNumberTextFields("CardTransactionNumber", "CardTransactionNumber", true)}
                                                </Box>
                                                : null
                                        }
                                        {
                                            this.state.isUPI ?
                                                <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"}>
                                                    {this.renderAmountTextFields("UPIAmount", "UPIAmount", ImagePaths.UPIIcon.default)}
                                                    {this.renderNumberTextFields("UPITransactionNumber", "UPINumber", true)}
                                                </Box>
                                                : null
                                        }
                                        {
                                            this.state.isBankTranfer ?
                                                <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"}>
                                                    {this.renderAmountTextFields("BankTransferAmount", "BankTfrAmount", ImagePaths.BankTransferIcon.default)}
                                                    {this.renderNumberTextFields("BankTransactionNumber", "BankTfrNumber", true)}
                                                </Box>
                                                : null
                                        }
                                        {
                                            this.state.isCheque ?
                                                <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"}>
                                                    {this.renderAmountTextFields("ChequeAmount", "ChequeAmount", ImagePaths.ChequeIcon.default)}
                                                    {this.renderNumberTextFields("ChequeTransactionNumber", "ChequeNumber", true)}
                                                </Box>
                                                : null
                                        }
                                        {
                                            this.state.isInsurance ?
                                                <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"}>
                                                    {this.renderAmountTextFields("InsuranceAmount", "InsuranceAmount", ImagePaths.ChequeIcon.default)}
                                                    {this.renderNumberTextFields("Company Name *", "InsuCompanyName", true)}
                                                    {this.renderNumberTextFields("Policy Number *", "InsuPolicyNumber", true)}
                                                    {this.renderNumberTextFields("Transaction Number", "InsuTransactionNumber", true)}
                                                    {this.renderNumberTextFields("Validity", "InsuValidity", true)}
                                                </Box>
                                                : null
                                        }
                                    </Box>
                                </Box>
                            </Box>
                        </Box>

                        <Box component={'div'} flex={0.25}>
                            <Box component={'div'} className='eMed_bill_tbl_footer'>
                                <Typography mt={0.5} fontSize={'0.9vw'}>{t("Sub Amount")}</Typography>
                                <Typography emed_tid="total_sub_amount" mt={0.5} mr={'0.8vw'}>{this.billingCalculation(params, 'totalServiceAmount')}</Typography>
                            </Box>
                            {/* <Box component={'div'} className='eMed_bill_tbl_footer'>
                                <Typography mt={0.5} fontSize={'0.9vw'}>{t("Outstanding Amount")}</Typography>
                                <Typography emed_tid="total_sub_amount" mt={0.5} mr={'0.8vw'}>{this.billingCalculation(params, 'outstandingAmount')}</Typography>
                            </Box> */}
                            {/* <Box component={'div'} className='eMed_bill_tbl_footer'>
                                <Typography mt={0.5} fontSize={'0.9vw'}>{`${t("Discount")} %`}</Typography>
                                {this.renderTextBox('totalPercentage', this.state.totalPercentage, '2vh', disable)}
                                {this.renderTextBox('totalDiscount', this.state.totalDiscount, '2vh', disable)}
                            </Box> */}
                            <Box component={'div'} className='eMed_bill_tbl_footer'>
                                <Typography mt={0.5} fontSize={'0.9vw'}>{t("RoundOff")}</Typography>
                                <Typography emed_tid="total_roundoff_amount" mt={0.5} mr={'0.8vw'}>
                                    {this.billingCalculation(params, 'roundOff')}
                                </Typography>
                            </Box>
                            <Box component={'div'} className='eMed_bill_tbl_footer' borderTop={"2px solid gray"}>
                                <Typography mt={0.5} fontSize={'0.9vw'} fontWeight='600'>{t("Refundable Amount")}</Typography>
                                <Typography emed_tid="total_payable_amount" mt={0.5} mr={'0.8vw'} fontWeight='600'>
                                    {this.billingCalculation(params, 'payableAmount')}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    {this.renderPaymentButton(visible, returnMethod)}
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    isScheduledDrug(index) {
        try {
            if (
                this.state.pharmacyTblData[index]?.schedule &&
                this.state.pharmacyTblData[index]?.schedule !== null &&
                this.state.pharmacyTblData[index]?.schedule !== "" &&
                this.state.pharmacyTblData[index]?.drug_name &&
                this.state.pharmacyTblData[index]?.drug_name !== "" &&
                this.state.pharmacyTblData[index]?.batch_no &&
                this.state.pharmacyTblData[index]?.batch_no !== "") {
                return true
            } else {
                return false
            }
        } catch (e) {
            return false
        }
    }

    renderRefund = (data, item, roundOff) => {
        let BillReceivedAmount = roundOff ? Math.round(data?.paid_amount) : data?.paid_amount
        let totalBillNetAmount = data?.net_amount
        let ReturnedNetAmount = Math.round(item.total_return_price)
        let PendingAmt = data?.outstanding_amount
        let refundAmount = 0
        if (this.state.return_select_method === "Bill_Refund") {
            if (BillReceivedAmount == 0 || (ReturnedNetAmount > BillReceivedAmount && ReturnedNetAmount < PendingAmt) ||
                (ReturnedNetAmount > BillReceivedAmount && ReturnedNetAmount == PendingAmt) || (ReturnedNetAmount > BillReceivedAmount && ReturnedNetAmount > PendingAmt)) {
                refundAmount = (BillReceivedAmount).toFixed(2)
            } else if ((BillReceivedAmount == totalBillNetAmount) || (ReturnedNetAmount < BillReceivedAmount && ReturnedNetAmount > PendingAmt) ||
                (ReturnedNetAmount == BillReceivedAmount && ReturnedNetAmount > PendingAmt) || (ReturnedNetAmount < BillReceivedAmount && ReturnedNetAmount < PendingAmt) ||
                (ReturnedNetAmount < BillReceivedAmount && ReturnedNetAmount == PendingAmt) || (ReturnedNetAmount == BillReceivedAmount && ReturnedNetAmount < PendingAmt) ||
                (ReturnedNetAmount == BillReceivedAmount && ReturnedNetAmount == PendingAmt)) {
                refundAmount = (ReturnedNetAmount).toFixed(2)
            }
        }
        else {
            if (BillReceivedAmount == 0) {
                refundAmount = (BillReceivedAmount).toFixed(2)
            } else if (BillReceivedAmount == totalBillNetAmount) {
                refundAmount = (ReturnedNetAmount).toFixed(2)
            } else if ((ReturnedNetAmount > BillReceivedAmount && ReturnedNetAmount < PendingAmt) || (ReturnedNetAmount > BillReceivedAmount && ReturnedNetAmount == PendingAmt) ||
                (ReturnedNetAmount < BillReceivedAmount && ReturnedNetAmount < PendingAmt) || (ReturnedNetAmount < BillReceivedAmount && ReturnedNetAmount == PendingAmt) ||
                (ReturnedNetAmount == BillReceivedAmount && ReturnedNetAmount < PendingAmt) || (ReturnedNetAmount == BillReceivedAmount && ReturnedNetAmount == PendingAmt)) {
                refundAmount = 0
            } else if ((ReturnedNetAmount > BillReceivedAmount && ReturnedNetAmount > PendingAmt) || (ReturnedNetAmount < BillReceivedAmount && ReturnedNetAmount > PendingAmt) || (ReturnedNetAmount == BillReceivedAmount && ReturnedNetAmount > PendingAmt)) {
                refundAmount = (ReturnedNetAmount - PendingAmt).toFixed(2)
            }
        }
        return refundAmount
    }

    renderAdjust = (data, item, roundOff) => {
        let BillReceivedAmount = roundOff ? Math.round(data?.paid_amount) : data?.paid_amount
        let totalBillNetAmount = data?.net_amount
        let ReturnedNetAmount = Math.round(item.total_return_price)
        let PendingAmt = data?.outstanding_amount
        let returnAmount = 0
        if (this.state.return_select_method === "Bill_Refund") {
            if (BillReceivedAmount == 0) {
                returnAmount = (ReturnedNetAmount).toFixed(2)
            } else if ((BillReceivedAmount == totalBillNetAmount) || (ReturnedNetAmount < BillReceivedAmount && ReturnedNetAmount > PendingAmt) ||
                (ReturnedNetAmount == BillReceivedAmount && ReturnedNetAmount > PendingAmt) || (ReturnedNetAmount < BillReceivedAmount && ReturnedNetAmount < PendingAmt) ||
                (ReturnedNetAmount < BillReceivedAmount && ReturnedNetAmount == PendingAmt) || (ReturnedNetAmount < BillReceivedAmount && ReturnedNetAmount == PendingAmt) ||
                (ReturnedNetAmount == BillReceivedAmount && ReturnedNetAmount < PendingAmt) || (ReturnedNetAmount == BillReceivedAmount && ReturnedNetAmount == PendingAmt)) {
                returnAmount = 0
            } else if ((ReturnedNetAmount > BillReceivedAmount && ReturnedNetAmount < PendingAmt) || (ReturnedNetAmount > BillReceivedAmount && ReturnedNetAmount == PendingAmt) ||
                (ReturnedNetAmount > BillReceivedAmount && ReturnedNetAmount > PendingAmt)) {
                returnAmount = (ReturnedNetAmount - BillReceivedAmount).toFixed(2)
            }
        } else {
            if (BillReceivedAmount == 0 || (ReturnedNetAmount > BillReceivedAmount && ReturnedNetAmount < PendingAmt) ||
                (ReturnedNetAmount > BillReceivedAmount && ReturnedNetAmount == PendingAmt) || (ReturnedNetAmount < BillReceivedAmount && ReturnedNetAmount < PendingAmt) ||
                (ReturnedNetAmount < BillReceivedAmount && ReturnedNetAmount == PendingAmt) || (ReturnedNetAmount == BillReceivedAmount && ReturnedNetAmount < PendingAmt) ||
                (ReturnedNetAmount == BillReceivedAmount && ReturnedNetAmount == PendingAmt)) {
                returnAmount = (ReturnedNetAmount).toFixed(2)
            } else if (BillReceivedAmount == totalBillNetAmount) {
                returnAmount = 0
            } else if ((ReturnedNetAmount > BillReceivedAmount && ReturnedNetAmount > PendingAmt) || (ReturnedNetAmount < BillReceivedAmount && ReturnedNetAmount > PendingAmt) ||
                (ReturnedNetAmount == BillReceivedAmount && ReturnedNetAmount > PendingAmt)) {
                returnAmount = (PendingAmt).toFixed(2)
            }
        }
        return returnAmount
    }

    renderBillAdjustTable = () => {
        try {
            let { t } = this.props
            return (
                <Box className="eMed_Emp_billing_left" sx={{ backgroundColor: Colors.ComponentBgColor, marginLeft: "0", marginY: "0.5vw", marginRight: "1vw", height: "55vh" }}>
                    <TableContainer component={'div'} className='eMed_Return_Billing_Table' >
                        <Table aria-label="collapsible table" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ width: "10%" }} className='eMed_Pat_Bill_Tbl_Header' align='center'>{"Bill No"}</TableCell>
                                    <TableCell sx={{ width: "10%" }} className='eMed_Pat_Bill_Tbl_Header' align='center'>{"No of item"}</TableCell>
                                    {/* <TableCell sx={{ width: "10%" }} className='eMed_Pat_Bill_Tbl_Header' align='center'>{"QTY"}</TableCell> */}
                                    <TableCell sx={{ width: "10%" }} className='eMed_Pat_Bill_Tbl_Header' align='center'>{"Bill Type"}</TableCell>
                                    <TableCell sx={{ width: "10%" }} className='eMed_Pat_Bill_Tbl_Header' align='center'>{"Bill Amount"}</TableCell>
                                    <TableCell sx={{ width: "10%" }} className='eMed_Pat_Bill_Tbl_Header' align='center'>{"Paid"}</TableCell>
                                    <TableCell sx={{ width: "15%" }} className='eMed_Pat_Bill_Tbl_Header' align='center'>{"Balance Amount"}</TableCell>
                                    <TableCell sx={{ width: "15%" }} className='eMed_Pat_Bill_Tbl_Header' align='center'>{"Return Amount"}</TableCell>
                                    <TableCell sx={{ width: "10%" }} className='eMed_Pat_Bill_Tbl_Header' align='center'>{"Refund"}</TableCell>
                                    <TableCell sx={{ width: "10%" }} className='eMed_Pat_Bill_Tbl_Header' align='center'>{"Adjust"}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.ReturnbillPopup && this.state.Popuptabledata && this.state.Popuptabledata.length > 0 ? this.state.Popuptabledata.map((item, index) => (
                                    item?.invoice_number ?
                                        <TableRow key={index}>
                                            <TableCell className='eMed_Pat_Tbl_one_style' align='center'>{item?.invoice_number ? item.invoice_number : "-"}</TableCell>
                                            <TableCell className='eMed_Pat_Tbl_one_style' align='center'>{item?.number_of_items ? item.number_of_items : "1"}</TableCell>
                                            <TableCell className='eMed_Pat_Tbl_one_style' align='center'>{
                                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                                                    <Typography component={'div'} fontSize={"0.9vw"} fontWeight={600}>{item?.bill_types ? item?.bill_types : "-"}</Typography>
                                                    <Typography component={'div'} fontSize={"0.8vw"} >{(item?.payment_status ? item.payment_status : "-")}</Typography>
                                                </Box>
                                            }</TableCell>
                                            <TableCell className='eMed_Pat_Tbl_one_style' align='center'>{item?.selected_bill[0]?.net_amount ? (item?.selected_bill[0]?.net_amount).toFixed(2) : item?.selected_bill?.net_amount ? (item?.selected_bill?.net_amount).toFixed(2) : "0"}</TableCell>
                                            <TableCell className='eMed_Pat_Tbl_one_style' align='center'>{item?.selected_bill[0]?.paid_amount ? Math.round(item?.selected_bill[0]?.paid_amount) : item?.selected_bill?.paid_amount ? Math.round(item?.selected_bill?.paid_amount) : "0"}</TableCell>
                                            <TableCell className='eMed_Pat_Tbl_one_style' align='center'>{(item?.selected_bill[0]?.net_amount || item?.selected_bill[0]?.paid_amount) ? (item?.selected_bill[0]?.net_amount - Math.round(item?.selected_bill[0]?.paid_amount)) : (item?.selected_bill?.net_amount || item?.selected_bill?.paid_amount) ? (item?.selected_bill?.net_amount - Math.round(item?.selected_bill?.paid_amount)) : "0"}</TableCell>
                                            <TableCell className='eMed_Pat_Tbl_one_style' align='center'>{item?.total_return_price ? Math.round(item.total_return_price) : "0"}</TableCell>
                                            <TableCell className='eMed_Pat_Tbl_one_style' align='center'><Typography component={'div'} fontSize={"0.9vw"} fontWeight={600}>{this.renderRefund((item?.selected_bill[0] ? item?.selected_bill[0] : item?.selected_bill), item, true)}</Typography></TableCell>
                                            <TableCell className='eMed_Pat_Tbl_one_style' align='center'>{<Typography component={'div'} fontSize={"0.9vw"} fontWeight={600}>{this.renderAdjust((item?.selected_bill[0] ? item?.selected_bill[0] : item?.selected_bill), item, true)}</Typography>}</TableCell>
                                        </TableRow> : null
                                )) : null}
                            </TableBody>
                        </Table>
                        {this.state.pharmacyTblData?.length === 0 ?
                            <Stack className='eMed_conf_nodata'>
                                {t("NoRecordsFound")}
                            </Stack> : null}
                    </TableContainer>
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderAmountcard = (value, Title, ml) => {
        return (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", border: "1px solid black", width: "12vw", height: "4vw", padding: "0.65vw", marginLeft: ml }}>
                <div style={{ fontSize: "1.1vw", fontWeight: 600 }}>{Math.round(+value)}</div>
                <div style={{ fontSize: "0.9vw" }}>{Title}</div>
            </div>
        )
    }

    renderReturnBillPopup = () => {
        try {
            let states = this.state
            const { t } = this.props
            let TotBillAmt = 0; let TotRefundAmt = 0; let TotAdjustAmt = 0;
            if (this.state.Popuptabledata && this.state.Popuptabledata.length > 0 && this.state.ReturnbillPopup) {
                this.state.Popuptabledata.forEach((item) => {
                    if (item?.selected_bill?.length > 0) {
                        TotBillAmt += +(item?.selected_bill[0]?.net_amount)
                    }
                })
                this.state.Popuptabledata.forEach((item, index) => {
                    if (item?.selected_bill?.length > 0) {
                        TotRefundAmt += (+this.renderRefund((item?.selected_bill[0] ? item?.selected_bill[0] : item?.selected_bill), item, false))
                        states.Popuptabledata[index]["return_amount"] = +this.renderRefund((item?.selected_bill[0] ? item?.selected_bill[0] : item?.selected_bill), item, false)
                    }
                })
                this.state.pharmacyTblData.forEach((item, index) => {
                    if (item?.selected_bill?.length > 0) {
                        states.pharmacyTblData[index]["return_amount"] = +this.renderRefund((item?.selected_bill[0] ? item?.selected_bill[0] : item?.selected_bill), item, false)
                    }
                })
                this.state.Popuptabledata.forEach((item) => {
                    if (item?.selected_bill?.length > 0) {
                        TotAdjustAmt += (+this.renderAdjust((item?.selected_bill[0] ? item?.selected_bill[0] : item?.selected_bill), item, false))
                    }
                })
                // this.state.pharmacyTblData.forEach((item) => {
                //     if (item?.Bill_Sugg_list[0]) {
                //         creditAmount += (+(item?.Bill_Sugg_list[0]?.outstanding_amount))
                //     }
                // })
                // this.state.pharmacyTblData.forEach((item) => {
                //     if (item?.Bill_Sugg_list[0]) {
                //         paidAmount += (+item?.Bill_Sugg_list[0]?.paid_amount)
                //     }
                // })
            }
            return (
                <Modal open={this.state.ReturnbillPopup}>
                    <Box className='eMed_New_Ip_Popup' sx={{ width: "65vw", height: "95vh", borderRadius: "0px", overflow: "auto" }}>
                        <Box component={'div'} mb={'0.5vw'} position={'sticky'} sx={{ borderBottom: "1px solid black" }}>
                            <Box display={'flex'} justifyContent={'space-between'} paddingLeft={'1vw'} alignItems={'center'} height={'3vw'}>
                                <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Return Bill</Typography>
                                <Button onClick={() => this.setState({ ReturnbillPopup: false, isCash: false, isUPI: false, isBankTranfer: false, return_select_method: "Bill_Refund", Popuptabledata: [] })}>
                                    <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
                                </Button>
                            </Box>
                        </Box>
                        <Box component={'div'} ml={'1vw'} height={'75vh'} overflow={'auto'}>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-end", justifyContent: "space-between" }}>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-end" }}>
                                    {this.renderAmountcard(TotBillAmt, "Total Bill Amount", 0)}
                                </div>
                                <div>
                                    <RadioGroup value={this.state.return_select_method}
                                        sx={{ display: 'flex', flexDirection: 'row' }}
                                        onClick={(e) => {
                                            this.setState({
                                                return_select_method: e.target.value,
                                                isCash: false,
                                                isUPI: false,
                                                isBankTranfer: false,
                                                UPIAmount: "",
                                                CashAmount: "",
                                                BankTfrAmount: ""
                                            })
                                        }}
                                    >
                                        <FormControlLabel value={'Bill_Refund'} control={<Radio size='small' />} label='Max. Bill Refund' />
                                        <FormControlLabel value={'Bill_Adjust'} control={<Radio size='small' />} label='Max. Bill Adjust' />
                                    </RadioGroup>
                                </div>
                            </div>
                            {this.renderBillAdjustTable()}
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-end" }}>
                                {this.renderAmountcard(this.state.netAmount, "Total Return Amount", 0)}
                                {this.renderAmountcard(TotRefundAmt, "Total Refund Amount", "0.5vw")}
                                {this.renderAmountcard(TotAdjustAmt, "Bill Adjust", "0.5vw")}
                            </div>
                        </Box>
                        <Box component={'div'} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", paddingLeft: "1vw", paddingRight: "1vw" }}>
                            <Box overflow={'scroll'}>
                                {TotRefundAmt == 0 ? null :
                                    <Box component={'div'} borderTop={'1px solid lightgray'} pl={'1vw'} marginTop={'0.5vw'} height={'9vw'} overflow={'scroll'} visibility={'visible'}>
                                        <Box component={'div'} style={{ marginTop: "0.5vw" }} className='eMed_Pharma_payment_card'>
                                            <Typography fontWeight={600} fontSize={'0.9vw'}>Mode Of Payment</Typography>
                                        </Box>
                                        <Box component={'div'} className=''>
                                            <Box>
                                                <FormControlLabel disabled={(this.state.isCredit || this.state.subAmount <= 0) ? true : (this.state.isForReturn ? false : this.state.isFromInvoice)} className='eMed_CrdPop_CheckBoxDiv' control={<Checkbox emed_tid="cash_checkbox" checked={this.state.isCash} onChange={(e) => { this.handleCheckBox(e, "isCash", Math.round(TotRefundAmt)) }} size='small' />} label={t("Cash")} />
                                                <FormControlLabel disabled={(this.state.isCredit || this.state.subAmount <= 0) ? true : (this.state.isForReturn ? false : this.state.isFromInvoice)} className='eMed_CrdPop_CheckBoxDiv' control={<Checkbox emed_tid="upi_checkbox" checked={this.state.isUPI} onChange={(e) => { this.handleCheckBox(e, "isUPI", Math.round(TotRefundAmt)) }} size='small' />} label={t("UPI")} />
                                                <FormControlLabel disabled={(this.state.isCredit || this.state.subAmount <= 0) ? true : (this.state.isForReturn ? false : this.state.isFromInvoice)} className='eMed_CrdPop_CheckBoxDiv' sx={{ width: "10vw" }} control={<Checkbox emed_tid="bank_trans_checkbox" checked={this.state.isBankTranfer} onChange={(e) => { this.handleCheckBox(e, "isBankTranfer", Math.round(TotRefundAmt)) }} size='small' />} label={t("BankTransfer")} />
                                            </Box>
                                            <Box component={'div'} display={'flex'} ml={'0.5vw'}>
                                                {
                                                    this.state.isCash ?
                                                        <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"}>
                                                            {this.renderAmountTextFields("CashAmount", "CashAmount", ImagePaths.CashIcon.default)}
                                                            {states.isCash && !states.isCard && !states.isUPI && !states.isBankTranfer && !states.isInsurance ?
                                                                <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"}>
                                                                    {this.renderNumberTextFields("Cash Collected", "ReceivedCashAmount", true)}
                                                                    {this.renderNumberTextFields("Balance Amount", "CashBalanceAmount", true)}
                                                                </Box> : null
                                                            }
                                                        </Box> : null
                                                }
                                                {
                                                    this.state.isUPI ?
                                                        <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"}>
                                                            {this.renderAmountTextFields("UPIAmount", "UPIAmount", ImagePaths.UPIIcon.default)}
                                                            {this.renderNumberTextFields("UPITransactionNumber", "UPINumber", true)}
                                                        </Box>
                                                        : null
                                                }
                                                {
                                                    this.state.isBankTranfer ?
                                                        <Box component={"div"} display={"flex"} flexDirection={"row"} ml={"0.5vw"}>
                                                            {this.renderAmountTextFields("BankTransferAmount", "BankTfrAmount", ImagePaths.BankTransferIcon.default)}
                                                            {this.renderNumberTextFields("BankTransactionNumber", "BankTfrNumber", true)}
                                                        </Box>
                                                        : null
                                                }
                                            </Box>
                                        </Box>
                                    </Box>}
                            </Box>
                            <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-end'} alignItems={'center'} p={'0.5vw'}>
                                <Button
                                    sx={{ height: '2vw', width: '5vw', mr: '1vw' }}
                                    variant='outlined'
                                    onClick={() => { this.setState({ ReturnbillPopup: false, isCash: false, isUPI: false, isBankTranfer: false, return_select_method: "Bill_Refund", Popuptabledata: [] }) }}
                                >BACK</Button>
                                <Button
                                    sx={{ height: '2vw', width: '5vw', mr: '1vw' }}
                                    variant='contained'
                                    onClick={() => {
                                        if (TotRefundAmt == 0) {
                                            this.setState({
                                                totalrefundAmount: TotRefundAmt,
                                            }, () => {
                                                this.postPharmacyBillData()
                                            })
                                        } else { this.postPharmacyBillData() }
                                    }}
                                >CONFIRM</Button>
                            </Box>
                        </Box>
                    </Box>
                </Modal>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderDrugColumn = (index, item) => {
        let frequent = "Frequently Brought Medicine"
        let { pharmacyTblData } = this.state
        return (
            <Box>
                <Tooltip title={pharmacyTblData[index]['drug_name']?.length > 18 ? (pharmacyTblData[index]['drug_name'] ? pharmacyTblData[index]['drug_name'] : "") : ""} placement="top" arrow>
                    <TextField
                        inputRef={this.DrugNameRef}
                        disabled={Object.keys(this.state.PatientDetails).length === 0 || this.state.isForOnlyView}
                        size='small'
                        variant='standard'
                        autoComplete='off'
                        value={item.drug_name}
                        onChange={(e) => {
                            if (e.target.value !== "") {
                                pharmacyTblData[index]['drug_name'] = e.target.value
                                pharmacyTblData[index]['dosage_strength'] = ""
                                pharmacyTblData[index]['invoice_number'] = ""
                                pharmacyTblData[index]['dosage_strength'] = ""
                                pharmacyTblData[index]['batch_no'] = ""
                                pharmacyTblData[index]['dosage_type'] = ""
                                pharmacyTblData[index]['physical_box_no'] = ""
                                pharmacyTblData[index]['cost_per_quantity'] = ""
                                pharmacyTblData[index]['total_cost'] = ""
                                pharmacyTblData[index]['quantity'] = ""
                                pharmacyTblData[index]['return_quantity'] = ""
                                pharmacyTblData[index]['discount_percentage'] = ""
                                pharmacyTblData[index]['total_price'] = ""
                                pharmacyTblData[index]['hsn_code'] = ""
                                pharmacyTblData[index]['stock_in_quantity'] = ""
                                pharmacyTblData[index]['expiry_date'] = ""
                                pharmacyTblData[index]['Drug_sugg_list'] = []
                                pharmacyTblData[index]['selected_bill'] = []
                                delete pharmacyTblData[index]['Bill_Sugg_list']
                                this.setState({ pharmacyTblData }, () => {
                                    if (CommonValidation.removeSpace(pharmacyTblData[index]['drug_name'])?.length > 2) {
                                        this.setState({
                                            DurgNameCurrentIndex: index
                                        }, () => { this.getBrandListData(index) })
                                    }
                                })
                            } else if (e.target.value === "" && index !== (pharmacyTblData.length - 1)) {
                                this.state.pharmacyTblData.splice(index, 1)
                                this.setState({ pharmacyTblData: this.state.pharmacyTblData })
                            } else {
                                pharmacyTblData[index]['drug_name'] = e.target.value
                                this.setState({ pharmacyTblData })
                            }
                        }}
                        className={this.isScheduledDrug(index) ? 'eMed_DrugBox_Scheduled' : null}
                        onKeyDown={(e) => {
                            this.setState({
                                DrugNameAnchor: e.currentTarget,
                                FocusDrugList: e.key === "ArrowDown" ? true : false
                            }, () => {
                                if (e.key === 'Enter' && pharmacyTblData[index]['drug_name']?.length > 2) {
                                    this.setState({
                                        DurgNameCurrentIndex: index
                                    }, () => { this.getBrandListData(index) })
                                }
                            })
                        }}
                        inputProps={{ emed_tid: "bill_drug_name", style: { color: this.isScheduledDrug(index) ? 'rgb(150, 13, 11)' : null, paddingLeft: '0.4vw', fontSize: '0.9vw' } }}
                        InputProps={{
                            endAdornment: this.state.SearchingDrugIndex === index ? <InputAdornment position="end"><CircularProgress size={15} /></InputAdornment> : null,
                        }}

                    />
                </Tooltip>


                <Menu
                    open={this.state.DurgNameCurrentIndex === index && item.Drug_sugg_list && item.Drug_sugg_list?.length > 0 && item.drug_name !== ""}
                    className="eMed_Drug_Sugg_Pop"
                    anchorEl={this.state.DrugNameAnchor}
                    onClose={() => {
                        this.setState({ DurgNameCurrentIndex: null })
                    }}
                    autoFocus={false}
                    disableAutoFocusItem
                    disableAutoFocus
                >
                    <Box width={"18vw"} >
                        <Box sx={{ display: 'flex', backgroundColor: Colors.ThemeLightColor, paddingY: '0.3vw' }}>
                            <Tooltip placement='top' title={frequent}><Typography width={"90%"} fontWeight={'600'} fontSize={"0.8vw"} paddingLeft={'1vw'}>{frequent}</Typography></Tooltip>
                            {/* <Typography width={"30%"} textAlign={"center"} fontWeight={'600'} fontSize={"0.8vw"}>Quantity</Typography> */}
                        </Box>
                        <Box maxHeight={'14vw'} overflow={'scroll'}>
                            {
                                (item.Drug_sugg_list && item.Drug_sugg_list.length > 0) ?
                                    item.Drug_sugg_list.map((list, Drugindex) => (
                                        <MenuItem
                                            onKeyDown={(e) => {
                                                if (Drugindex === item.Drug_sugg_list.length - 1 && e.key === "ArrowDown") { e.stopPropagation() }
                                                else if (Drugindex === 0 && e.key === "ArrowUp") {
                                                    e.stopPropagation()
                                                    this.setState({ DurgNameCurrentIndex: index })
                                                }
                                            }}
                                            emed_tid="bill_drug_sugg_li"
                                            key={Drugindex}
                                            autoFocus={this.state.FocusDrugList && Drugindex === 0}
                                            onClick={() => {
                                                this.setState({
                                                    DurgNameCurrentIndex: null
                                                }, () => {
                                                    this.onAddHandler("quantity", index)
                                                    this.autocompleteOnChangeHandler(list, "drug_name", index)
                                                })
                                            }}
                                            sx={{ display: 'flex', flexDirection: 'row' }}>
                                            <Box width={"90%"}>
                                                <CommonCorporateAndInsuranceDetails data={list} fromPharmaBilling={true} />
                                            </Box>
                                            {/* <Typography width={"30%"} fontSize={"0.8vw"} textAlign={"center"}>{list?.in_stock}</Typography> */}
                                        </MenuItem>
                                    )) : null
                            }
                        </Box>
                    </Box>
                </Menu>
            </Box>
        )
    }
    renderBatchColumn = (index, item) => {
        return (
            <Box>
                <TextField
                    inputRef={this.BatchNameRef}
                    disabled={this.disabled('batch_no', index)}
                    size='small'
                    variant='standard'
                    autoComplete='off'
                    value={item.invoice_number}
                    onKeyDown={(e) => {
                        e.preventDefault()
                        // this.setState({ BatchAnchor: e.currentTarget })
                    }}
                    onFocus={(e) => {
                        this.setState({
                            BatchAnchor: e.currentTarget,
                            CurrentBatchIndex: index
                        }, () => {
                            e.preventDefault()
                            if ((!this.state.pharmacyTblData[index]['Bill_Sugg_list'])) {
                                this.getDrugDetailsData(index, false)
                            }
                        })
                    }}
                    inputProps={{ emed_tid: "bill_invoice_number", style: { paddingLeft: '0.4vw', fontSize: '0.8vw', fontWeight: 600, color: item?.payment_status == "not paid" ? "red" : "green" } }}
                    InputProps={{
                        endAdornment: this.state.SearchingBatchIndex === index ? <InputAdornment position="end"><CircularProgress size={15} /></InputAdornment> : <InputAdornment position="end"><ArrowDropDownIcon size={15} /></InputAdornment>,
                    }}

                />
                <Menu
                    open={this.state.CurrentBatchIndex === index}
                    className="eMed_Drug_Sugg_Pop"
                    anchorEl={this.state.BatchAnchor}
                    onClose={() => {
                        this.setState({ BatchAnchor: null, CurrentBatchIndex: null })
                    }}
                    autoFocus={true}
                    disableAutoFocusItem
                    disableAutoFocus
                    disableRestoreFocus
                >
                    <Box width={"40vw"} >
                        <Box sx={{ display: 'flex', backgroundColor: Colors.ThemeLightColor, paddingY: '0.4vw' }} emed_tid={"bill_batch_no_items"}>
                            <Typography width={"25%"} fontWeight={600} fontSize={'0.8vw'} paddingLeft={'1vw'}>Bill No</Typography>
                            <Typography width={"20%"} fontWeight={600} fontSize={'0.8vw'}>Batch No</Typography>
                            <Typography width={"15%"} fontWeight={600} fontSize={'0.8vw'} >Bill Type</Typography>
                            <Typography width={"20%"} fontWeight={600} fontSize={'0.8vw'}>Expiry Date</Typography>
                            <Typography width={"10%"} fontWeight={600} fontSize={'0.8vw'}>Qty</Typography>
                            <Typography width={"10%"} fontWeight={600} fontSize={'0.8vw'} >{`Cost/Qty  (${CurrencySymbol})`}</Typography>
                        </Box>
                        <Box maxHeight={'14vw'} overflow={'scroll'}>
                            {
                                (item.Bill_Sugg_list && item.Bill_Sugg_list.length > 0) ?
                                    item.Bill_Sugg_list.map((list, Drugindex) => {
                                        var datePart = list?.expiry_date ? list?.expiry_date.match(/\d+/g) : []
                                        var ExpiryDate = datePart.length === 3 ? datePart[1] + '-' + datePart[0] : "-";
                                        return (
                                            <MenuItem
                                                onKeyDown={(e) => {
                                                    if (Drugindex === item.Bill_Sugg_list.length - 1 && e.key === "ArrowDown") { e.stopPropagation() }
                                                    else if (Drugindex === 0 && e.key === "ArrowUp") {
                                                        e.stopPropagation()
                                                        this.setState({ DurgNameCurrentIndex: index })
                                                    }
                                                }}
                                                emed_tid={`batch_suggestion`}
                                                key={Drugindex}
                                                autoFocus={true}
                                                onClick={() => {
                                                    let rtnDetails = localGetItem("pharmacy_rtn_period")
                                                    let rtnData = JSON.parse(rtnDetails)
                                                    let billDate = list?.bill_summary__invoice_date?.slice(0, 10)
                                                    let isReturn = false
                                                    if (rtnData?.rtn_type === 'Days') {
                                                        let beforeDate = CommonValidation.getDateBefore(rtnData?.rtn_value)
                                                        let configDate = DateTime.fromJSDate(beforeDate).toFormat('yyyy-MM-dd')
                                                        if (new Date(billDate) >= new Date(configDate)) {
                                                            isReturn = true;
                                                        }
                                                    } else if (rtnData?.rtn_type === 'Weeks') {
                                                        let beforeDate = CommonValidation.getDateBeforeWeeks(rtnData?.rtn_value)
                                                        let configDate = DateTime.fromJSDate(beforeDate).toFormat('yyyy-MM-dd')
                                                        if (new Date(billDate) >= new Date(configDate)) {
                                                            isReturn = true;
                                                        }
                                                    } else if (rtnData?.rtn_type === 'Months') {
                                                        let beforeDate = CommonValidation.getDateBeforeMonths(rtnData?.rtn_value)
                                                        let configDate = DateTime.fromJSDate(beforeDate).toFormat('yyyy-MM-dd')
                                                        if (new Date(billDate) >= new Date(configDate)) {
                                                            isReturn = true;
                                                        }
                                                    }if(isReturn){
                                                        this.autocompleteOnChangeHandler(list, 'batch_no', index)
                                                    }else{
                                                        this.errorMessage("Invoice Date exceeded Return Period")
                                                    }
                                                }}
                                                sx={[{ display: 'flex', flexDirection: 'row', backgroundColor: list.batch_expiry_alert ? '#EE9E4F' : 'white', '&:hover': list.batch_expiry_alert ? { backgroundColor: '#f0bc89' } : null }]}>
                                                <Box sx={{ display: 'flex', flexDirection: 'row', width: "100%" }}>
                                                    <Typography width={"25%"} fontSize={'0.8vw'} color={list?.payment_status == "not paid" ? 'red' : "green"}>{list?.invoice_number}</Typography>
                                                    {list?.batch_no ? list?.batch_no?.length > 12 ?
                                                        <Tooltip placement='top' title={list?.batch_no} arrow>
                                                            <Typography width={"20%"} fontSize={'0.8vw'}>{list?.batch_no.slice(0, 10) + "..."}</Typography>
                                                        </Tooltip>
                                                        : <Typography width={"20%"} fontSize={'0.8vw'}>{list?.batch_no}</Typography> : "-"}
                                                    <Typography width={"15%"} fontSize={'0.8vw'}>{list?.payment_status}</Typography>
                                                    <Typography width={"20%"} fontSize={'0.8vw'} paddingLeft={"0.5vw"}>{ExpiryDate}</Typography>
                                                    <Typography width={"10%"} fontSize={'0.8vw'} paddingLeft={"0.5vw"}>{list?.quantity}</Typography>
                                                    <Typography width={"10%"} fontSize={'0.8vw'} textAlign={'center'}>{AmountFormat(list?.cost_per_quantity || 0)?.replace(`${CurrencySymbol}`, "")}</Typography>
                                                </Box>
                                            </MenuItem>
                                        )
                                    }) : <Typography textAlign={"center"} paddingY={"0.5vw"} width={"100%"} fontSize={'0.8vw'}>{"No Bills Found"}</Typography>
                            }
                        </Box>
                    </Box>
                </Menu>
            </Box>
        )
    }

    DiscardAction = (flag) => {
        if (flag) {
            const EmptyBillItem = [
                {
                    drug_name: '',
                    dosage_strength: '',
                    return_quantity: '',
                    invoice_number: '',
                    dosage_type: '',
                    batch_no: '',
                    expiry_date: '',
                    physical_box_no: '',
                    cost_per_quantity: '',
                    quantity: '',
                    total_cost: '',
                    discount: '',
                    total_price: '',
                    hsn_code: '',
                    stock_in_quantity: '',
                    Drug_sugg_list: [],
                    total_return_price: "",
                    bill_discount: "",
                    payment_status: '',
                    bill_type: '',
                    id: '',
                    stock_list_ids: ''
                }
            ]
            try {
                this.setState({
                    pharmacyTblData: EmptyBillItem,
                    drugDetailsData: [],
                    isCard: false,
                    isCash: false,
                    isUPI: false,
                    isBankTranfer: false,
                    isCheque: false,
                    isInsurance: false,
                    ChequeAmount: '',
                    CashAmount: '',
                    CardAmount: '',
                    BankTfrAmount: '',
                    UPIAmount: '',
                    CardTransactionNumber: '',
                    ChequeNumber: '',
                    BankTfrNumber: '',
                    UPINumber: '',
                    netAmount: 0,
                    subAmount: 0,
                    roundOff: 0,
                    totalDiscount: '',
                    totalPercentage: '',
                    DurgNameCurrentIndex: null,
                    PatientDetails: {},
                    BillDetails: {},
                    PatientSearchClicked: false,
                    SearchedPatientList: [],
                    doctordetails: {},
                    PatientMobileNumber: "",
                    PatientName: "",
                    invoiceDateTime: new Date(),
                    ShowDiscard: false,
                    Patient_Id: null,
                    ReceivedCashAmount: "",
                    CashBalanceAmount: "",
                    isFromInvoice: false,
                    isForReturn: false,
                    isFromSavedBill: false,
                    isForInvoiceEdit: false,
                    isFromIpRequest: false,
                    isFromIpReturn: false,
                    isFromPrescription: false,
                    ShowHistory: false,
                    ShowPrescriptonHistory: false,
                    CompletedBillID: null,
                    Bill_ID: null,
                    SearchingDrugIndex: null,
                    SearchingBatchIndex: null,
                    isBillsFetched: false,
                    InvoiceNumber: null,
                    isCredit: false,
                    savedBill: false,
                    PrescHistoryData: null,
                    PrescriptionId: null,
                    OutStandingAmount: 0,
                    ReturnedNetAmount: 0,
                    SelectedOPorIpNumber: "",
                    SelectedModuleType: "IP"
                })
            } catch (error) {
                this.errorMessage(error.message)
            }
        } else {
            this.setState({ ShowDiscard: false })
        }
    }

    clearSinglePayment = (item) => {
        if (item === "2") {
            this.setState({
                CardTransactionNumber: "",
            })
        } else if (item === "4") {
            this.setState({
                UPINumber: "",
            })
        } else if (item === "5") {
            this.setState({
                BankTfrNumber: ""
            })
        } else if (item === "6") {
            this.setState({
                InsuCompanyName: "",
                InsuPolicyNumber: "",
                InsuTransactionNumber: "",
                InsuValidity: "",
            })
        }
        this.setState({
            secondAmount: ""
        })
    }

    ClosePopUp() {
        this.setState({ CompletedBillID: null }, () => {
            if (this.state.isFromSavedBill) { this.props?.getPharmaSavedBillCount(true) }
            if (this.state.isFromPrescription) { this.props?.getPharmaPrescriptionCount(true) }
            this.DiscardAction(true)
        })
    }

    errorAleart = (msg) => {
        this.errorMessage(msg)
    }


    render() {
        let { t } = this.props
        return (
            <Box component={'div'} className='eMed_Pharma_Billing_container' height={"98%"}>
                <Box component={'div'} height={'5vw'} className='eMEd_pharma_Home_header'>
                    <Box>
                        <RadioGroup
                            value={this.state.SelectedModuleType}
                            onChange={(e) => {
                                this.setState({
                                    SelectedModuleType: e.target.value,
                                    SelectedOPorIpNumber: "",
                                    PatientName: "",
                                    PatientMobileNumber: "",
                                    PatientDetails: {}, Patient_Id: null, Bill_ID: null, doctordetails: {}, pharmacyTblData: [
                                        {
                                            drug_name: '',
                                            dosage_strength: '',
                                            invoice_number: '',
                                            return_quantity: '',
                                            dosage_type: '',
                                            batch_no: '',
                                            expiry_date: '',
                                            physical_box_no: '',
                                            cost_per_quantity: '',
                                            quantity: '',
                                            total_cost: '',
                                            discount: '',
                                            total_price: '',
                                            hsn_code: '',
                                            stock_in_quantity: '',
                                            Drug_sugg_list: [],
                                            total_return_price: "",
                                            bill_discount: ""
                                        }
                                    ],
                                }, () => {
                                    if (Object.keys(this.state.PatientDetails).length > 0) {
                                        this.GetPatientAdmissionHistory(this.state.PatientDetails?.patient_id)
                                    }
                                })
                            }}
                        >
                            <FormControlLabel value="IP" control={<Radio disabled={this.state.isForOnlyView} size='small' />} label="IP" />
                            <FormControlLabel value="OP" control={<Radio disabled={this.state.isForOnlyView} size='small' />} label="OP" />
                        </RadioGroup>
                    </Box>
                    {this.renderSearch((this.state.PatientDetails?.patient_account_number && this.state.PatientDetails?.patient_account_number !== "null") ? "Patient Mobile" : "Name/UHID/Mobile", "PatientMobileNumber", ImagePaths.MobileCardIcon.default)}
                    {this.renderSearch("IP / OP Number", "ipopNumber", ImagePaths.IPNumberCard.default, this.state.DoctorsList)}
                    {this.renderSearch("Patient Name", "PatientName", ImagePaths.AvatarCardIcon.default)}
                    {this.renderSearch("Consultant Name | Reg No", "doctordetails", ImagePaths.Doctor_Icon.default, this.state.DoctorsList)}
                    {this.renderSearch("Return Date & Time", "invoiceDateTime", ImagePaths.CalenderIcon.default,)}
                </Box>
                <Box component={'div'}>
                    <Box component={'div'}>
                        <TableContainer sx={{ height: '45vh' }} className='eMed_Phrama_Table_Container'>
                            <Table stickyHeader size='small' sx={{ overflowY: 'scroll' }}>
                                <TableHead>
                                    <TableRow sx={{ display: 'flex' }}>
                                        <TableCell sx={{ flex: 0.008 }} className='eMed_Pharma_tbl_header'>
                                            <Button disabled={this.state.isForOnlyView} emed_tid={"bill_delete_all"} className='eMed_Pharma_del_btn' onClick={() => this.deleteLineItems()}>
                                                <Box component={'img'} mt={'0.2vw'} src={ImagePaths.Delete.default} alt='del' height={'1.3vw'} width={'1.3vw'} />
                                            </Button>
                                        </TableCell>
                                        <TableCell sx={{ flex: 0.008 }} className='eMed_Pharma_tbl_header'>S.No</TableCell>
                                        <TableCell sx={{ flex: 0.18 }} className='eMed_Pharma_tbl_header'>Brand Name</TableCell>
                                        <TableCell sx={{ flex: 0.09 }} align='center' className='eMed_Pharma_tbl_header'>Strength/Type</TableCell>
                                        {/* <TableCell align='center' sx={{ flex: 0.066 }} className='eMed_Pharma_tbl_header'>Type</TableCell> */}
                                        <TableCell align='center' sx={{ flex: this.state.isForOnlyView ? 0.266 : 0.25 }} className='eMed_Pharma_tbl_header'>Bill No</TableCell>
                                        <TableCell align='center' sx={{ flex: 0.1 }} className='eMed_Pharma_tbl_header'>Batch</TableCell>
                                        <TableCell align='center' sx={{ flex: 0.066 }} className='eMed_Pharma_tbl_header'>ExpDate</TableCell>
                                        <TableCell align='center' sx={{ flex: 0.066 }} className='eMed_Pharma_tbl_header'>Cost/Qty</TableCell>
                                        {this.state.isForOnlyView ? null : <TableCell align='center' sx={{ flex: 0.066 }} className='eMed_Pharma_tbl_header'>Balance.Qty</TableCell>}
                                        <TableCell align='center' sx={{ flex: 0.066 }} className='eMed_Pharma_tbl_header'>Return.Qty</TableCell>
                                        <TableCell align='center' sx={{ flex: 0.066 }} className='eMed_Pharma_tbl_header'>{`Total.Cost`}</TableCell>
                                        <TableCell align='center' sx={{ flex: 0.02 }} className='eMed_Pharma_tbl_header'>{`Disc%`}</TableCell>
                                        <TableCell align='center' sx={{ flex: 0.046 }} className='eMed_Pharma_tbl_header'>Bill.Disc%</TableCell>
                                        <TableCell align='right' sx={{ flex: 0.066 }} className='eMed_Pharma_tbl_header'>{`Total(₹)`}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.pharmacyTblData.map((item, index) => {
                                        var datePart = item?.expiry_date ? item?.expiry_date.match(/\d+/g) : []
                                        var ExpiryDate = datePart.length === 3 ? datePart[1] + '-' + datePart[0] : "-";
                                        return (
                                            <Tooltip title={this.state.isFromIpRequest && item?.stock_in_quantity === 0 ? "No Stock Available" : ""} placement='top' arrow>
                                                <TableRow sx={{ display: 'flex', backgroundColor: this.state.isFromIpRequest && item?.stock_in_quantity === 0 ? Colors.disableComponentColor : null }} key={index}>
                                                    <TableCell sx={{ flex: 0.008 }}>
                                                        <Button disabled={this.state.isForOnlyView} emed_tid={"bill_delete_item"} className='eMed_Pharma_del_btn' onClick={() => this.deleteLineItems(index)}>
                                                            <Box component={'img'} mt={'0.5vw'} src={ImagePaths.Delete.default} alt='del' height={'1.1vw'} width={'1.1vw'} />
                                                        </Button>
                                                    </TableCell>
                                                    <TableCell align='center' sx={{ flex: 0.008, paddingTop: '0.8vw' }}>{index + 1}</TableCell>
                                                    <TableCell sx={{ flex: 0.19, paddingTop: '0.8vw' }}>{this.renderDrugColumn(index, item)}</TableCell>
                                                    <TableCell align='center' emed_tid="bill_dosage_strength" sx={{ flex: 0.095, paddingTop: '0.8vw' }} className='eMed_Pharma_tbl_Body'>
                                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                                            {item?.dosage_strength ? item?.dosage_strength?.length > 5 ?
                                                                <Tooltip placement='top' title={item?.dosage_strength} arrow>
                                                                    <div style={{ fontSize: "0.875rem" }}>{item?.dosage_strength.slice(0, 4) + ".."}</div>
                                                                </Tooltip> : <div style={{ fontSize: "0.875rem" }}>{item?.dosage_strength}</div> : "-"}
                                                            {" / "}
                                                            {item?.dosage_type ? item?.dosage_type?.length > 5 ?
                                                                <Tooltip placement='top' title={item?.dosage_type} arrow>
                                                                    <div style={{ fontSize: "0.875rem" }}>{item?.dosage_type.slice(0, 5) + "..."}</div></Tooltip> :
                                                                <div style={{ fontSize: "0.875rem" }}>{item?.dosage_type}</div> : "-"}
                                                        </div>
                                                    </TableCell>
                                                    {/* <TableCell align='center' emed_tid="bill_dosage_type" sx={{ flex: 0.066, paddingTop: '0.8vw' }}>
                                                        {item?.dosage_type ? item?.dosage_type?.length > 5 ?
                                                            <Tooltip placement='top' title={item?.dosage_type} arrow>
                                                                <div style={{ fontSize: "0.875rem" }}>{item?.dosage_type.slice(0, 5) + "..."}</div></Tooltip> :
                                                            item?.dosage_type : "-"}
                                                    </TableCell> */}
                                                    <TableCell sx={{ flex: this.state.isForOnlyView ? 0.266 : 0.25, paddingTop: '0.8vw' }} >
                                                        {this.renderBatchColumn(index, item)}
                                                    </TableCell>
                                                    <TableCell align='center' emed_tid="bill_expiry_date" sx={{ flex: 0.1, paddingTop: '0.8vw' }}>
                                                        {item?.batch_no ? item?.batch_no?.length > 10 ?
                                                            <Tooltip placement='top' title={item?.batch_no} arrow>
                                                                <div style={{ fontSize: "0.875rem" }}>{item?.batch_no.slice(0, 7) + "..."}</div></Tooltip> :
                                                            item?.batch_no : "-"}
                                                    </TableCell>
                                                    <TableCell align='center' emed_tid="bill_expiry_date" sx={{ flex: 0.066, paddingTop: '0.8vw' }}>{ExpiryDate}</TableCell>
                                                    <TableCell emed_tid="bill_cost_per_quantity" align='center' sx={{ flex: 0.066, paddingTop: '0.8vw' }}>{AmountFormat(item?.cost_per_quantity || 0)?.replace(`${CurrencySymbol}`, "")}</TableCell>
                                                    {this.state.isForOnlyView ? null : <TableCell emed_tid="bill_cost_per_quantity" align='center' sx={{ flex: 0.066, paddingTop: '0.8vw' }}>
                                                        {item?.quantity ? (item?.quantity - (item?.returned_qty ? item?.returned_qty : 0)) : '-'}
                                                    </TableCell>}
                                                    <TableCell sx={{ flex: 0.066 }}>
                                                        {this.inputTextBox(item?.return_quantity, 'return_quantity', 'Return Qty', '4vw', index)}
                                                    </TableCell>
                                                    <TableCell emed_tid="bill_total_cost" align='center' sx={{ flex: 0.066, paddingTop: '0.8vw' }}>{AmountFormat(item?.total_cost || 0)?.replace(`${CurrencySymbol}`, "")}</TableCell>
                                                    <TableCell align='center' sx={{ flex: 0.06, paddingTop: '0.8vw' }}>{item?.discount_percentage ? item?.discount_percentage : '0'}</TableCell>
                                                    <TableCell align='center' sx={{ flex: 0.06, paddingTop: '0.8vw' }}>
                                                        {/* {item?.physical_box_no ? item?.physical_box_no : '-'} */}
                                                        {item?.bill_discount ? item?.bill_discount : '0'}
                                                        {/* {this.inputTextBox(item?.bill_discount, 'bill_discount', 'Disc', '4vw', index)} */}
                                                    </TableCell>
                                                    <TableCell align='right' emed_tid="bill_total_price" sx={{ flex: 0.066, paddingTop: '0.8vw' }}>
                                                        {AmountFormat(item?.total_return_price || 0)?.replace(`${CurrencySymbol}`, "")}
                                                    </TableCell>
                                                </TableRow>
                                            </Tooltip>)
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {this.customPharmaFooter(this.state.pharmacyTblData)}
                        {this.renderReturnBillPopup()}
                        {this.state.isErrorMsg ?
                            <ToastMsg
                                severity={'error'}
                                msg={this.state.isErrorMsgText}
                                msgPop={this.msgClose.bind(this)}
                            />
                            : null}
                        {this.state.successMsg ?
                            <ToastMsg
                                severity={'success'}
                                msg={this.state.successMsgText}
                                msgPop={this.msgClose.bind(this)}
                                testId={this.state.invoiceNumber}
                            />
                            : null}
                        <Loader loaderOpen={this.state.isLoader} />
                        {this.state.ShowDiscard ? <PharmaDiscardPop SendAction={this.DiscardAction.bind(this)} /> : null}
                        {this.state.CompletedBillID !== null ? <BillSuccessPoPUp isDirectPrint={true} InvoiceId={this.state.CompletedBillID} isFromPharmacy={true} ClosePopUp={this.ClosePopUp.bind(this)} testID={"Ph_BillSuc"} error={this.errorAleart.bind(this)} successTxt={"Return Processed Sucessfully"}/> : null}
                    </Box>
                </Box>
            </Box>
        )
    }
}
export default withTranslation()(ReturnMultipleBill)
