import { Box, Button, Drawer, Tooltip, Typography } from '@mui/material'
import React, { Component } from 'react'
import { Colors } from '../../../Styles/Colors'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg'
import './RadBillTrans.css'
import { AmountsCard } from '../../../Components/CommonCards/CommonCards'
import { ImagePaths } from '../../../Utility/ImagePaths'
import { DataGrid } from '@mui/x-data-grid'
import { DateTime } from 'luxon'
import { AmountFormat, CheckAccessFunc, formatDate, timeOnlyConvert } from '../../../Components/CommonFunctions/CommonFunctions'
import { CommonGridToolBarWithFilterText } from '../../../Components/Common Components/CommonComponents'
import { CurrencySymbol } from '../../../Utility/Constants'
import RadBillingFilter from './RadBillingFilter'
import { localGetItem } from '../../../Utility/Services/CacheProviderService'
import RestAPIService from '../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../Utility/API/Serviceurls'

let isUser = null;

export default class RadReceiptBills extends Component {
  constructor(props) {
    super(props)
    this.state = {
      receiptBillsData: [],
      totalBill: 0,
      totalAmount: 0,
      page: 0,
      pageSize: 10,
      FilterDatas: {
        "FromDate": DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
        "ToDate": DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
        "DateIndex": 1,
        "PaidAmount": "",
        "BillNumber": "",
        "PatientName": "",
        "InPatientSelected": true,
        "OutPatientSelected": true,
        "BillType": ['op', 'ip', 'radiology'],
        "PayMethods": [1, 2, 3, 4, 5, 6],
        "CardSelected": true,
        "CashSelected": true,
        "UPISelected": true,
        "BankTransferSelected": true,
        "InsuranceSelected": true,
        "ChequeSelected": true,
        "combined": true,
        "BillReceiptSelected": true,
        "AdvanceTypeSelected": true,
        "AdvanceRefundTypeSelected": true,
        "RefundTypeSelected": true,
        "CancelledTypeSelected": true,
        "RecieptType": ['bill', 'advance', 'refund', 'cancelled', 'advance refund'],
        "startTime": null,
        "endTime": null,
        "days": null,
        "OPBills": true,
        "IPBills": true,
        "LabBills": true,
        "new_Receipt_Type": ['op', 'ip', 'radiology'],
        "BillModuleType": ['op', 'ip', 'radiology'],
        "opCreditBills": true,
        "ipCreditBills": true,
        'radiologyCreditBills': true,
        'RadiologyBills': true,
        "RadiologySelected": true,
        "AgainstCredit": 'both'
      },
      cash: 0,
      card: 0,
      upi: 0,
      bank: 0,
      cheque: 0
    }
  }

  componentDidMount() {
    isUser = JSON.parse(localGetItem('loggedInUserInfo'))
    this.getReceiptBillData()
  }

  getReceiptBillData = () => {
    try {
      let states = this.state
      let start_time = states.FilterDatas.startTime ? new Date(states.FilterDatas.startTime) : ''
      let end_time = states.FilterDatas.endTime ? new Date(states.FilterDatas.endTime) : ''
      let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
      let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);

      RestAPIService.getAll(Serviceurls.RAD_RECEIPT_BILL_TRANS_GET +
        "?from_date=" + this.state.FilterDatas.FromDate +
        "&to_date=" + this.state.FilterDatas.ToDate +
        "&start_time=" + setStartTime +
        "&end_time=" + setEndTime +
        "&patient_name=" + this.state.FilterDatas.PatientName +
        // "&patient_acc_number=" + this.state.FilterDatas.AccNo +
        // '&mobile_number=' + this.state.FilterDatas.MobNo +
        "&receipt_number=" + this.state.FilterDatas.BillNumber +
        "&paid_amount=" + this.state.FilterDatas.PaidAmount +
        "&against_credit=" + (this.state.FilterDatas.AgainstCredit === "both" ? "" : this.state.FilterDatas.AgainstCredit) +
        "&receipt_type=" + this.state.FilterDatas.RecieptType +
        "&is_combined=" + this.state.FilterDatas.combined +
        "&moduel_type=" + this.state.FilterDatas.new_Receipt_Type +
        // "&paid_in=" + this.state.FilterDatas.BillType +
        "&payment_method=" + this.state.FilterDatas.PayMethods
      )
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              receiptBillsData: response.data.data,
              totalBill: response.data.total_bill,
              totalAmount: response.data.total_amount,
              cash: response.data.cash_amt,
              card: response.data.card_amt,
              upi: response.data.upi_amt,
              bank: response.data.bank_amt,
              cheque: response.data.cheque_amt
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error?.response?.data?.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getReceiptBillsPrint = () => {
    try {
      let states = this.state
      let start_time = states.FilterDatas.startTime ? new Date(states.FilterDatas.startTime) : ''
      let end_time = states.FilterDatas.endTime ? new Date(states.FilterDatas.endTime) : ''
      let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
      let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);

      this.setState({ disableBtn: true })
      RestAPIService.getAll(Serviceurls.RAD_RECEIPT_BILL_TRANS_GET +
        "?from_date=" + this.state.FilterDatas.FromDate +
        "&to_date=" + this.state.FilterDatas.ToDate +
        "&start_time=" + setStartTime +
        "&end_time=" + setEndTime +
        "&patient_name=" + this.state.FilterDatas.PatientName +
        // "&patient_acc_number=" + this.state.FilterDatas.AccNo +
        // '&mobile_number=' + this.state.FilterDatas.MobNo +
        "&receipt_number=" + this.state.FilterDatas.BillNumber +
        "&paid_amount=" + this.state.FilterDatas.PaidAmount +
        "&against_credit=" + (this.state.FilterDatas.AgainstCredit === "both" ? "" : this.state.FilterDatas.AgainstCredit) +
        "&receipt_type=" + this.state.FilterDatas.RecieptType +
        "&is_combined=" + this.state.FilterDatas.combined +
        "&moduel_type=" + this.state.FilterDatas.new_Receipt_Type +
        // "&paid_in=" + this.state.FilterDatas.BillType +
        "&payment_method=" + this.state.FilterDatas.PayMethods + "&export_type=pdf"
      ).then((response) => {
        const file = new Blob(
          [response.data],
          { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        this.setState({ disableBtn: false })
      }).catch((error) => {
        this.setState({ disableBtn: false })
        if (error?.response?.data?.message) {
          this.errorMessage(error.response.data.message)
        } else {
          this.errorMessage(error.message)
        }
      })
    } catch (error) {
      this.setState({ disableBtn: false })
      this.errorMessage(error.message)
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  gridToolBar = () => {
    var { t } = this.props
    let start_time = this.state.FilterDatas.startTime ? new Date(this.state.FilterDatas.startTime) : ''
    let end_time = this.state.FilterDatas.endTime ? new Date(this.state.FilterDatas.endTime) : ''
    let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
    let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);

    let TextArray = [
      { label: "From Date", value: formatDate(this.state.FilterDatas.FromDate) },
      { label: "To Date", value: formatDate(this.state.FilterDatas.ToDate) },
    ]
    if (this.state.FilterDatas.startTime !== null && this.state.FilterDatas.endTime !== null) {
      TextArray.push(
        { label: "Start Time", value: timeOnlyConvert(setStartTime) },
        { label: "End Time", value: timeOnlyConvert(setEndTime) }
      )
    }
    return (
      <Box>
        <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
      </Box>
    )
  }

  ClosePopUp(Getapicall = false, message = "") {
    this.setState({
      FilterOpen: false,
      BillPreviewPop: false,
      CreditBillPopUp: false
    }, () => {
      if (Getapicall && message !== "") {
        this.successMessage(message)
      }
    })
  }

  renderFilter = (data) => {
    this.setState({
      FilterDatas: data,
      FilterOpen: false,
    }, () => { this.getReceiptBillData() })
  }

  clearFilter = (data) => {
    this.setState({
      FilterDatas: data
    })
  }

  GetBillPrint = (id) => {
    try {
      let clinicID = localGetItem("loggedInUserInfo") !== null ? `&clinic_id=${JSON.parse(localGetItem("loggedInUserInfo"))?.clinic_id}` : ""
      this.setState({ disableBtn: true })
      RestAPIService.getAll(Serviceurls.RECEIPT_INDIV_PRINT + `?receipt_id=` + id + clinicID).
        then((response) => {
          this.setState({ disableBtn: false })
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }).catch((error) => {
          this.setState({ disableBtn: false })
          this.errorMessage(error.response?.data?.message, false);
        })
    } catch (error) {
        this.errorMessage(error.message, false)
    }
}

  render() {
    this.state.receiptBillsData?.forEach((element, index) => element.sno = index + 1)
    const columns = [
      {
        field: 'receipt_number', headerName: 'Receipt Details', flex: 0.125,
        renderCell: (params) => {
          return (
            <Box component={'div'} emed_tid='emed_rad_rcpt_dtls'>
              <Typography fontSize={'0.9vw'} fontWeight={600}>{params?.row?.receipt_number ?
                params?.row?.receipt_number?.length > 9 ?
                  <Tooltip placement='top' title={params?.row?.receipt_number}><div style={{ fontSize: '0.9vw', fontWeight: 600 }}>{params?.row?.receipt_number?.slice(0, 9) + '...'}</div></Tooltip> :
                  params?.row?.receipt_number : '-'}</Typography>
              <Typography color={Colors.grayShade} fontSize={'0.8vw'}>{params?.row?.invoice_date ? params?.row?.invoice_date : '-'}</Typography>
            </Box>
          )
        }
      },
      {
        field: 'patient_name', headerName: 'Patient Details', flex: 0.125,
        renderCell: (params) => (<Box component={'div'} emed_tid='rad_bill_trns_pat_name_cre'>
        <Typography fontSize={'0.9vw'} fontWeight={600}>{params?.row?.patient_names ?
          params?.row?.patient_names?.length > 10 ?
            <Tooltip placement='top' title={params?.row?.patient_names + " " + params?.row?.patient_age + '/' + params?.row?.patient_gender?.toUpperCase()}><div style={{ fontWeight: 600, fontSize: '0.9vw' }}>{params?.row?.patient_names?.slice(0, 10) + '...' + (params?.row?.patient_age ? params?.row?.patient_age : '-') + '/' + (params?.row?.patient_gender ? params?.row?.patient_gender?.toUpperCase() : '-')}</div></Tooltip> :
            (params?.row?.patient_names + " " + (params?.row?.patient_age ? params?.row?.patient_age : '-') + '/' + (params?.row?.patient_gender ? params?.row?.patient_gender?.toUpperCase() : '-')) : '-'}</Typography>
        <Typography fontSize={'0.8vw'} color={Colors.grayShade}>{params?.row?.patient_acc_number ? params?.row?.patient_acc_number : '-'}</Typography>
      </Box>)
      },
      {
        field: "service_id", flex: 0.125, headerName: `${"Bill No"}`, headerAlign: "center", align: "center",
        renderCell: (params) => {
          const billArr = params?.row?.service_id ? params?.row?.service_id?.split("/") : [];
          return (
            <Tooltip placement='top' title={params?.row?.service_id} arrow>
              <Box component={'div'} emed_tid='emed_rad_descp' fontSize={'0.9vw'} fontWeight={600}>{billArr?.length > 0 ? '...' + billArr?.slice(-2)?.join("/") : "-"}</Box>
            </Tooltip>
          )
        }
      },
      {
        field: 'receipt_type', headerName: 'Bill Type', flex: 0.125,
        renderCell: (params) => {
          return (
            <Box component={'div'} emed_tid={'emed_rad_bill_type'}>
              <Typography fontSize={'0.9vw'} fontWeight={600}>{params?.row?.receipt_type}</Typography>
              <Typography fontSize={"0.8vw"} color={Colors?.grayShade}>{` Paid in : ${params?.row?.collected_in ? params?.row?.collected_in : "-"}`}</Typography>
            </Box>
          )
        }
      },
      {
        field: 'receipt_for', headerName: 'Receipt For', flex: 0.125,
        renderCell: (params) => {
          return (
            <Box component={'div'} emed_tid='emed_rad_rcpt_type'>
              {params?.row?.receipt_for ? (params?.row?.receipt_for === "Bill Receipt" && params?.row?.against_credit) ? <Box>
                <Typography fontSize={"0.9vw"} fontWeight={600}>Bill Receipt</Typography>
                <Typography fontSize={"0.8vw"} color={Colors?.grayShade}>Against Credit</Typography>
              </Box> : <Typography fontWeight={600} fontSize={'0.9vw'}>{params?.row?.receipt_for ? params?.row?.receipt_for : '-'}</Typography> : "-"}
            </Box>
          )
        }
      },
      {
        field: 'amount_received', headerName: `${'Payment Details'} (${CurrencySymbol})`, flex: 0.125, headerAlign: 'left', align: 'left',
        renderCell: (params) => {
          return (
            <Box component={'div'} emed_tid='emed_rad_pay_dtls'>
              <Typography fontSize={'0.9vw'} fontWeight={600}>{AmountFormat(params.row.amount_received || 0)?.replace(`${CurrencySymbol}`, "")}</Typography>
              <Typography fontSize={'0.8vw'} color={Colors.grayShade}>{params?.row?.payment_mode_types ? params?.row?.payment_mode_types?.length > 14 ?
                <Tooltip placement='top' title={params?.row?.payment_mode_types} arrow>
                  <div style={{ fontSize: '0.8vw', color: Colors.grayShade }}>{params?.row?.payment_mode_types.slice(0, 12) + "..."}</div></Tooltip> :
                params?.row?.payment_mode_types : "-"}</Typography>
            </Box>
          )
        }
      },
      {
        field: 'created_by', headerName: 'Collected By', flex: 0.125,
        renderCell: (params) => {
          return (
            <Box component={'div'} emed_tid='emed_rad_coll_by'>
              <Typography fontWeight={600} fontSize={'0.9vw'}>{params?.row?.created_by ? params?.row?.created_by?.length > 15 ?
                <Tooltip placement='top' title={params?.row?.created_by}><div style={{ fontWeight: 600, fontSize: '0.9vw' }}>{params?.row?.created_by?.slice(0, 15) + '...'}</div></Tooltip> :
                params?.row?.created_by : '-'}</Typography>
            </Box>
          )
        }
      },
      {
        field: "action", flex: 0.125, headerName: "Action", headerAlign: "center", align: "center", sortable: false,
        renderCell: (params) => <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
          <Tooltip title={'Print'} placement="top" arrow>
            <Button onClick={() => { this.GetBillPrint( params.row?.id) }} className='eMed_usrconf_btn' emed_tid='eMed_rad_prt_btn'>
              <img src={ImagePaths.PrintIcons.default} alt="Create Bill" className='eMed_action_img' />
            </Button>
          </Tooltip>
          {CheckAccessFunc('Radiology', "Billing Transaction", 'Receipt Bills', null, "Tab")?.editAccess ?
            '' : null}
          {/* {this.MenuItem(params)} */}
        </Box>
      }
    ]
    return (
      <Box component={'div'} sx={{ backgroundColor: Colors.Background }}>
        <Box component={'div'} className='rad_bill_trns_container'>
          <Box component={'div'} className='rad_bill_trns_header_card'>
            <Box component={'div'} className='rad_bill_trns_cards'>
              {AmountsCard('Total Bill', this.state.totalBill, true, "white", false, null, 'rad_bill_trns_tlt_bill_recp')}
              {AmountsCard('Total Amount', this.state.totalAmount, false, "white", false, null, 'rad_bill_trns_tlt_amt_recp')}
              {AmountsCard('Cash', this.state.cash, false, "white", false, null, 'rad_bill_trns_cash_recp')}
              {AmountsCard('Card', this.state.card, false, "white", false, null, 'rad_bill_trns_card_recp')}
              {AmountsCard('UPI', this.state.upi, false, "white", false, null, 'rad_bill_trns_upi_recp')}
              {AmountsCard('Bank Transfer', this.state.bank, false, "white", false, null, 'rad_bill_trns_bank_recp')}
              {AmountsCard('Cheque', this.state.cheque, false, "white", false, null, 'rad_bill_trns_che_recp')}
            </Box>
            <Box component={'div'} className='rad_bill_trns_btns'>
              <Tooltip placement='top' title={'Filter'} arrow>
                <Button className='Common_Btn_Min_Width rad_bill_btn' emed_tid='rad_bill_trns_flt_recp'
                  onClick={() => this.setState({ FilterOpen: true })}>
                  <Box component={'img'} src={ImagePaths.Filter.default} height={'2vw'} width={'2vw'} />
                </Button>
              </Tooltip>
              <Tooltip placement='top' title={'Print'} arrow>
                <Button className='Common_Btn_Min_Width rad_bill_btn' emed_tid='rad_bill_trns_prt_recp'
                  onClick={() => this.getReceiptBillsPrint()}
                  disabled={this.state.disableBtn}
                >
                  <Box component={'img'} src={ImagePaths.LabPrintIcon.default} height={'2vw'} width={'2vw'} />
                </Button>
              </Tooltip>
              {/* <Tooltip placement='top' title={'Export'} arrow>
                <Button className='Common_Btn_Min_Width rad_bill_btn' emed_tid='rad_bill_trns_exp_recp'>
                  <Box component={'img'} src={ImagePaths.ExportIcon.default} height={'2vw'} width={'2vw'} />
                </Button>
              </Tooltip> */}
            </Box>
          </Box>
          <Box component={'div'} className='rad_bill_trns_content_card'>
            <DataGrid
              sx={{ backgroundColor: Colors.white }}
              columns={columns}
              rows={this.state.receiptBillsData}
              getRowId={(row) => row['sno']}
              page={this.state.page}
              pageSize={this.state.pageSize}
              hideFooterSelectedRowCount
              localeText={{
                toolbarColumns: "",
                toolbarDensity: "",
                toolbarExport: "",
                toolbarFilters: "",
                toolbarExportPrint: ""
              }}
              headerHeight={40}
              components={{
                Toolbar: this.gridToolBar,
                NoRowsOverlay: () => (
                  <Box className='eMed_conf_nodata'>
                    {'No Records To Be Shown'}
                  </Box>
                )
              }}
              rowsPerPageOptions={[10, 20, 30]}
              disableSelectionOnClick
              pagination
              onPageChange={(newPage) => { this.setState({ page: newPage }) }}
              onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
              loading={this.state.isLoader}
            />
          </Box>
        </Box>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        <Drawer
          anchor={'right'}
          open={this.state.FilterOpen}
          ModalProps={{ onBackdropClick: this.ClosePopUp.bind(this) }}
        >
          <RadBillingFilter PageName={"ReceiptBills"} FilterDatas={this.state.FilterDatas} CloseFilter={this.ClosePopUp.bind(this)} FilteredData={this.renderFilter.bind(this)} ClearData={this.clearFilter.bind(this)} fromReceipt={true} />
        </Drawer>
      </Box>
    )
  }
}
