import React, { Component } from 'react'
import '../Configuration.css';
import { Box, Typography, FormControl, InputLabel, Select, MenuItem, TextField, Table, TableBody, TableCell, TableRow, Button, TableContainer, Paper, TableHead, Stack, IconButton, Tooltip, Autocomplete } from '@mui/material'
import { withTranslation } from 'react-i18next';
import { ImagePaths } from '../../../Utility/ImagePaths';
import { Colors } from '../../../Styles/Colors';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import { IP_routeList } from '../../../Utility/Constants';
import Loader from '../../../Components/Loader';
import { CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions';
import { localGetItem } from '../../../Utility/Services/CacheProviderService';
import { ToolsFieldWithMic } from '../../../Components/Common Components/CommonComponents';

const ITEM_HEIGHT = 80;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: '15vw',
        },
    },
};
class DisSumTemplateAddConfig extends Component {
    constructor(props) {
        super(props)
        let LoggedData = localGetItem("loggedInUserInfo") ? JSON.parse(localGetItem("loggedInUserInfo")) : null
        let PharmacyList = LoggedData?.pharmacy_data ? JSON.parse(JSON.stringify(LoggedData?.pharmacy_data)) : []
        this.state = {
            selectLayout: '',
            disLayoutDesign: [],
            AlldisLayoutDesign: [],
            temName: '',
            selectMEAN: '',
            selectConsumptions: '',
            mediRoute: '',
            mediName: '',
            mediDosage: '',
            mediInstruction: '',
            medicationTbl: [],
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: '',
            selectedIndex: null,
            codeICDapi: [],
            icdCode: '',
            selecticd: null,
            icdADTblData: [],
            icdDDTblData: [],
            postDisSumData: {},
            selectDiet: '',
            selectActivity: '',
            adviceDischarge: '',
            typeAnaesthesia: '',
            nameAnaesthesia: '',
            temID: null,
            updataLayoutData: [],
            splItem: [],
            AllsplItem: [],
            selectSpeciality: null,
            specTxt: '',
            selectDischargeProvisional: "",
            selectAdmissionProvisional: "",
            catagoryList: [],
            brandnameList: [],
            selectedCategory: '',
            PharmacyList: PharmacyList,
            selectedPharmacy: PharmacyList?.length ? PharmacyList[0]?.pharmacy_id : null
        }
    }

    componentDidMount() {
        this.getLayoutList()
        this.getICDcode()
        this.getSpeciality()
        this.getCatagoryList()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.history?.location?.state?.selectedID !== this.state.temID && prevProps.history?.location?.state?.selectedID !== null && prevProps.history?.location?.state?.selectedID !== undefined) {
            this.setState({
                temID: prevProps.history?.location?.state?.selectedID
            }, () => {
                this.props.history.push({ state: { selectedID: null } })
                setTimeout(()=>{this.getDisSumTemplate()}, 1000) 
            })
        }
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    getSpeciality = () => {
        try {
            RestAPIService.getAll(Serviceurls.FO_CONFIG_OPSPECIALIZATION)
                .then((response => {
                    if (response.data.status === 'success') {
                        this.setState({
                            splItem: response.data.data ? response.data.data : [],
                            AllsplItem: response.data.data ? response.data.data : [],
                        })
                    }
                })).catch(e => {
                    if (e?.response?.data?.message) {
                        this.errorMessage(e.response.data.message)
                    } else {
                        this.errorMessage(e.message)
                    }

                })
        } catch (e) {
            this.errorMessage(e.message)
        }
    }


    getCatagoryList = () => {
        try {
            RestAPIService.getAll(Serviceurls.IP_DIS_SUM_CATAGORY)
                .then((response => {
                    if (response.data.status === 'success') {
                        this.setState({
                            catagoryList: response.data.data ? response.data.data : []
                        })
                    }
                })).catch(e => {
                    if (e?.response?.data?.message) {
                        this.errorMessage(e.response.data.message)
                    } else {
                        this.errorMessage(e.message)
                    }

                })
        } catch (e) {
            this.errorMessage(e.message)
        }
    }


    getLayoutList = () => {
        try {
            RestAPIService.getAll(Serviceurls.DIS_SUM_LAYOUT)
                .then(response => {
                    if (response.data.status === 'success') {
                        this.setState({
                            disLayoutDesign: response.data ? response.data.data : [],
                            AlldisLayoutDesign: response.data ? response.data.data : [],
                        })
                    }
                }).catch(e => {
                    if (e.response?.data?.status === "fail") {
                        this.errorMessage(e.response.data.message)
                    } else {
                        this.errorMessage(e.message)
                    }
                })

        } catch (e) {
            this.errorMessage(e.message)
        }
    }
    getICDcode = () => {
        try {
            RestAPIService.getAll(Serviceurls.GET_ICD_CODE + '?search_key=' + this.state.icdCode)
                .then(response => {
                    if (response.data.status === 'success') {
                        this.setState({
                            codeICDapi: response.data ? response.data.data : []
                        })
                    }
                }).catch(e => {
                    if (e.response?.data?.status === "fail") {
                        this.errorMessage(e.response.data.message)
                    } else {
                        this.errorMessage(e.message)
                    }
                })

        } catch (e) {
            this.errorMessage(e.message)
        }
    }
    postDisSumTemplate = () => {
        try {
            var states = this.state
            var data = {
                "layout_name_id": states.selectLayout ? states.selectLayout : null,
                "template_name": states.temName ? states.temName : '',
                "template_data": states.postDisSumData ? states.postDisSumData : {}
            }
            if (states.temID) {
                data["discharge_summary_id"] = states.temID
            }
            this.LoaderFunction(true)
            RestAPIService.create(data, Serviceurls.IP_TEMPLATE_DIS_SUM)
                .then(response => {
                    if (response.data.status === "success") {
                        this.LoaderFunction(false)
                        this.successMessage(response?.data?.message)
                        setTimeout(() => {
                            this.props.history.push('/Settings/TemplateConfig/DisSumTemplateConfig')
                        }, 1000);

                    }
                }).catch(e => {
                    if (e.response?.data?.status === "fail") {
                        this.LoaderFunction(false)
                        this.errorMessage(e.response.data.message)
                    } else {
                        this.LoaderFunction(false)
                        this.errorMessage(e.message)
                    }
                })
        } catch (e) {
            this.errorMessage(e.message)
        }
    }
    _getBrandList = (brand_name) => {
        const { t } = this.props;
        this.setState({
            brandnameList: []
        })
        if (brand_name) {
            try {
                var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
                var UserData = myUser
                RestAPIService.getAll(Serviceurls.PHARMA_BILLING_BRAND_SEARCH + `?search=${encodeURIComponent(brand_name)}&pharmacy_id=${this.state.selectedPharmacy}`)
                    .then(response => {
                        if (response.data.status === "success") {
                            this.setState({
                                brandnameList: response.data.data,
                            });
                        }
                    })
                    .catch(error => {
                        if (error?.response?.data?.status === "fail") {
                            this.errorMessage(error.response.data.message);
                        }
                    });
            } catch (e) {
                this.errorMessage(e.message);
            }
        }
    }
    getDisSumTemplate = () => {
        try {
            RestAPIService.getAll(Serviceurls.IP_TEMPLATE_DIS_SUM + "?template_id=" + this.state.temID)
                .then(response => {
                    if (response.data.status === 'success') {
                        this.setState({
                            updataLayoutData: response.data ? response.data.data : []
                        }, () => { this.editDisSumLayout(response.data.data) })
                    }
                }).catch(e => {
                    if (e.response?.data?.status === "fail") {
                        this.errorMessage(e.response.data.message)
                    } else {
                        this.errorMessage(e.message)
                    }
                })
        } catch (e) {
            this.errorMessage(e.message)
        }
    }
    editDisSumLayout = (data) => {
        var tem_Data = data.template_data
        this.setState({
            selectLayout: data.layout_name_id,
            selectSpeciality: data.speciality_id,
            selectedCategory: data.category_id,
            temName: data.template_name,
        }, () => {
            var states = this.state
            var data = states.AlldisLayoutDesign.filter((item) => (item.id == states.selectLayout))
            var keys = {}
            data[0]?.dis_sum_json?.forEach((element) => {
                keys[element.field_name] = tem_Data[element.field_name]

            })
            var splID = states.splItem.filter((item) => item.id === states.selectSpeciality)
            this.setState({
                postDisSumData: keys,
                icdADTblData: keys?.admission_diagnosis ? keys?.admission_diagnosis : [],
                typeAnaesthesia: keys?.name_anaesthetist_and_type_of_anaesthesia?.type_of_anaesthesia ? keys?.name_anaesthetist_and_type_of_anaesthesia?.type_of_anaesthesia : '',
                nameAnaesthesia: keys?.name_anaesthetist_and_type_of_anaesthesia?.name_of_anaesthetist ? keys?.name_anaesthetist_and_type_of_anaesthesia?.name_of_anaesthetist : '',
                icdDDTblData: keys?.discharge_diagnosis ? keys?.discharge_diagnosis : [],
                selectDischargeProvisional: tem_Data.discharge_provisional_diagnosis ? tem_Data.discharge_provisional_diagnosis : "",
                selectAdmissionProvisional: tem_Data.provisional_diagnosis ? tem_Data.provisional_diagnosis : "",
                medicationTbl: keys?.medication ? keys?.medication : [],
                selectDiet: keys?.discharge_instruction?.diet ? keys?.discharge_instruction?.diet : '',
                selectActivity: keys?.discharge_instruction?.acivity ? keys?.discharge_instruction?.acivity : '',
                adviceDischarge: keys?.discharge_instruction?.advice_discharge ? keys?.discharge_instruction?.advice_discharge : '',
                invetigation: keys?.investigation_and_test_report?.investigation ? keys?.investigation_and_test_report?.investigation : '',
                selectSpeciality: splID[0]?.name,
            })
        })
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }
    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    selectLayoutCon = () => {
        var states = this.state
        const { t } = this.props
        return (
            <Box className='eMed_Dis_Layout_selectCon1'>
                <FormControl size='small' className='eMed_refdoc_txtbox'>
                    <InputLabel id="demo-simple-select-label">{t('Select Category')}</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={this.state.selectedCategory}
                        label={t('Select Category')}
                        MenuProps={MenuProps}
                        onChange={(e) => {
                            this.setState({
                                selectedCategory: e.target.value
                            }, () => {
                                var states = this.state;
                                var filterLayout = states.AlldisLayoutDesign ? states.AlldisLayoutDesign.filter((item) => item.category_id === states.selectedCategory) : [];
                                var ID = filterLayout.map((item) => item.speciality_id);
                                var filterSpl = states.AllsplItem.filter((item) => ID.includes(item.id));
                                this.setState({
                                    splItem: filterSpl,
                                    selectSpeciality: null,
                                    selectLayout: "",
                                    disLayoutDesign: [],
                                });
                            })
                        }}
                    >
                        {states.catagoryList?.length > 0 ? states.catagoryList.map((item) => (
                            <MenuItem key={item.id} value={item.id}>{item.category_name}</MenuItem>
                        )) : <Typography align='center'>{t('NDF')}</Typography>}


                    </Select>
                </FormControl>
                <Autocomplete
                    noOptionsText={states.specTxt === '' ? t('PLS') : t('NDF')}
                    options={states.splItem}
                    disabled={this.state.selectedCategory ? false : true}
                    getOptionLabel={(options) => typeof (options) === 'string' ? options : options.name__name}
                    onChange={(e, value) => {
                        this.setState({
                            selectSpeciality: value,
                        }, () => {
                            if (value != null) {
                                var states = this.state
                                var filterLayout = states.AlldisLayoutDesign ? states.AlldisLayoutDesign.filter((item) => item.speciality_id === value.id) : null
                                this.setState({
                                    disLayoutDesign: filterLayout
                                })
                            } else {
                                this.getLayoutList()
                                this.setState({ selectLayout: '' })
                            }
                        })
                    }}
                    value={states.selectSpeciality ? states.selectSpeciality : null}
                    size='small'
                    className='eMed_refdoc_txtbox'
                    disablePortal
                    id="Spec"
                    renderInput={(params) => <TextField onChange={(event) => {
                        const value = event.target.value
                        if (value === "" || value != "") { this.setState({ specTxt: value }) }
                    }} {...params} label={t('selectSpecial')} />}
                />
                <FormControl size='small' className='eMed_refdoc_txtbox'>
                    <InputLabel id="demo-simple-select-label">{t('SelecttheLayout')}</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        disabled={this.state.selectSpeciality ? false : true}
                        value={this.state.selectLayout}
                        label={t('SelecttheLayout')}
                        onChange={this.handleSelcLayout}
                        MenuProps={MenuProps}
                    >
                        {states.disLayoutDesign?.length > 0 ? states.disLayoutDesign.map((item) => (
                            <MenuItem value={item.id}>{item.layout_name}</MenuItem>
                        )) : <Typography align='center'>{t('NDF')}</Typography>}


                    </Select>
                </FormControl>
                <TextField
                    size='small'
                    className='eMed_DisSum_TxtBox'
                    id="outlined-basic"
                    label={t('TemplateName')}
                    variant="outlined"
                    value={states.temName}
                    onChange={(event) => {
                        var alphanum = CommonValidation.alphanumValidation(event.target.value)
                        if (alphanum || event.target.value === '') {
                            this.setState({
                                temName: event.target.value
                            })
                        }
                    }} />
            </Box>
        )
    }
    handleSelcLayout = (event) => {
        this.setState({
            selectLayout: event.target.value
        }, () => {
            var states = this.state
            var data = states.disLayoutDesign.filter((item) => (item.id == states.selectLayout))
            var keys = {}
            data[0]?.dis_sum_json?.forEach((element) => {
                keys[element.field_name] = ""

            })
            this.setState({
                postDisSumData: keys
            })
        })
        this.renderDisSumEditLayout()
    }

    setEditedData = (stateName, value, postState) => {
        let states = this.state
        if(postState != "" && postState != undefined){
          states.postDisSumData[postState] = value
          this.setState({
            states
          },()=>{
            this.setState({
              isTemplateChanged: false
            })
          })
        }else{
          states[stateName] = value
          this.setState({ states },()=>{
            this.setState({
              isTemplateChanged: false
            })
          })
        }    
      } 

    renderDisSumEditLayout = () => {
        var states = this.state
        var data = states.disLayoutDesign
        var data1 = data.filter((item) => (item.id == states.selectLayout))
        return (
            <Box className='eMed_Dis_Sum_Tem_lay_Scroll'>
                {data1.length === 1 ? data1[0].dis_sum_json.map((item) => {
                    if (item.label === "Admission Diagnosis" && item.is_active === true) {
                        return (
                            <Box>
                                {this.renderadmissionDiagnosis("Admission Diagnosis", item.is_mantatory)}
                                <Box className='eMed_DisSum_Lay_CommonBox1'>
                                    {/* <TextField
                                        sx={{ width: "90vw" }}
                                        id="icd-code"
                                        multiline={true}
                                        // rows={3}
                                        name={"selectAdmissionProvisional"}
                                        label={"Admission Provisional Diagnosis"}
                                        variant="outlined"
                                        value={states.selectAdmissionProvisional ? states.selectAdmissionProvisional : ""}
                                        onChange={(event) => {
                                            this.setState({
                                                selectAdmissionProvisional: event.target.value
                                            })
                                        }}
                                    /> */}
                                    <ToolsFieldWithMic
                                        Title={"Admission Provisional Diagnosis"} 
                                        EditorRef={states.selectAdmissionProvisional ? states.selectAdmissionProvisional : ''} 
        
                                        saveData={this.setEditedData.bind(this)} 
                                        isFrom="dischargeSummary"
                                        minHeight={"12vh"}
                                        overallHeight={"auto"}
                                        stateName='selectAdmissionProvisional'  
                                    />
                                </Box>
                            </Box>
                        )
                    } else if (item.label === "Discharge Diagnosis" && item.is_active === true) {
                        return (
                            <Box>
                                {this.renderadmissionDiagnosis("Discharge Diagnosis", item.is_mantatory)}
                                <Box className='eMed_DisSum_Lay_CommonBox1'>
                                    {/* <TextField
                                        sx={{ width: "90vw" }}
                                        id="icd-code"
                                        multiline={true}
                                        // rows={3}
                                        name={"selectDischargeProvisional"}
                                        label={"Discharge Provisional Diagnosis"}
                                        variant="outlined"
                                        value={states.selectDischargeProvisional}
                                        onChange={(event) => {
                                            this.setState({
                                                selectDischargeProvisional: event.target.value
                                            })
                                        }}
                                    /> */}
                                    <ToolsFieldWithMic
                                        Title={"Discharge Provisional Diagnosis"} 
                                        EditorRef={states.selectDischargeProvisional ? states.selectDischargeProvisional : ''} 
        
                                        saveData={this.setEditedData.bind(this)} 
                                        isFrom="dischargeSummary"
                                        minHeight={"12vh"}
                                        overallHeight={"auto"}
                                        stateName='selectDischargeProvisional'  
                                    />
                                </Box>
                            </Box>
                        )
                    } else if (item.label === 'Name Anaesthetist and Type of Anaesthesia' && item.is_active === true) {
                        return (
                            <Box className='eMed_DisSum_Tem_Anaesthsia' sx={{display: "flex", justifyContent: "space-between", alignItems: "flex-start", gap: "2vw", marginLeft: '1vw', width:'90vw'}}>
                                {/* <TextField
                                    size='small'
                                    label={item.is_mantatory ? "Type of Anaesthesia*" : "Type of Anaesthesia"}
                                    sx={{ width: '44vw' }}
                                    id="icd-cde"
                                    placeholder='Enter Report Name'
                                    variant="outlined"
                                    value={this.state.typeAnaesthesia}
                                    onChange={(event) => {
                                        this.setState({
                                            typeAnaesthesia: event.target.value
                                        })
                                    }}
                                /> */}
                                <div style={{width:"48%"}}>
                                    <ToolsFieldWithMic
                                        Title={item.is_mantatory ? "Type of Anaesthesia*" : "Type of Anaesthesia"} 
                                        EditorRef={states.typeAnaesthesia ? states.typeAnaesthesia : ''} 
                                        saveData={this.setEditedData.bind(this)} 
                                        isFrom="dischargeSummary"
                                        minHeight={"12vh"}
                                        overallHeight={"auto"}
                                        stateName='typeAnaesthesia'  
                                    />
                                </div>
                                {/* <TextField
                                    size='small'
                                    label={item.is_mantatory ? "Name of Anaesthetist*" : "Name of Anaesthetist"}
                                    sx={{ width: '44vw' }}
                                    id="icd-cde"
                                    placeholder='Enter Report Name'
                                    variant="outlined"
                                    value={this.state.nameAnaesthesia}
                                    onChange={(event) => {
                                        this.setState({
                                            nameAnaesthesia: event.target.value
                                        })
                                    }}
                                /> */}
                                <div style={{width:"48%"}}>
                                    <ToolsFieldWithMic
                                        Title={item.is_mantatory ? "Name of Anaesthetist*" : "Name of Anaesthetist"} 
                                        EditorRef={states.nameAnaesthesia ? states.nameAnaesthesia : ''} 
                                        saveData={this.setEditedData.bind(this)} 
                                        isFrom="dischargeSummary"
                                        minHeight={"12vh"}
                                        overallHeight={"auto"}
                                        stateName='nameAnaesthesia'  
                                    />
                                </div>
                            </Box>
                        )
                    } else if (item.label === "Medication" && item.is_active === true) {
                        return (
                            this.renderMedication(item.is_mantatory)
                        )
                    } else if (item.label === "Discharge Instruction" && item.is_active === true) {
                        return (
                            this.renderDisInstruction(item.is_mantatory)
                        )
                    } else if (item.label === 'Investigation and Test Report' && item.is_active === true) {
                        return (
                            <Box className='eMed_DisSum_Lay_CommonBox1'>
                                {/* <TextField
                                    label={item.is_mantatory ? "Investigation*" : "Investigation"}
                                    sx={{ width: '90vw' }}
                                    id="icd-cde"
                                    multiline={true}
                                    // rows={3}
                                    variant="outlined"
                                    value={this.state.invetigation}
                                    onChange={(event) => {
                                        this.setState({
                                            invetigation: event.target.value
                                        })
                                    }}
                                /> */}
                                <ToolsFieldWithMic
                                    Title={item.is_mantatory ? "Investigation*" : "Investigation"} 
                                    EditorRef={states.invetigation ? states.invetigation : ''} 
                                    saveData={this.setEditedData.bind(this)} 
                                    isFrom="dischargeSummary"
                                    minHeight={"12vh"}
                                    overallHeight={"auto"}
                                    stateName='invetigation'  
                                />
                                {this.renderTestTbl()}
                            </Box>
                        )
                    }
                    else if (item.label === 'In Hospital Medication') {
                        // Do Nothing --- dont want to add this in template --- client confirmed -- by ravi cre
                    }
                    else {
                        return (
                            this.renderTextBoxs(item.label, '90vw', item.field_name, item.is_mantatory, true, item.is_active)
                        )
                    }
                }) : <Box className='eMed_DisSum_Tem_Nolayout'>
                    <Typography color={'#888888'}>{'Please Select the Layout'}</Typography>
                </Box>}


            </Box>
        )
    }

    renderadmissionDiagnosis = (label, mantatory) => {
        var states = this.state
        var { icdADTblData, icdDDTblData } = this.state
        const { t } = this.props
        return (
            <Box className='eMed_DisSum_Lay_CommonBox'>
                <Box className='eMed_DisSum_Lay_AdmDig'>
                    <Typography>{mantatory ? label + "*" : label}</Typography>
                    <Autocomplete
                        noOptionsText={states.icdCode === '' ? t('PLS') : t('NDF')}
                        options={states.codeICDapi}
                        getOptionLabel={(options) => options.icd_code + " | " + options.icd_short_desc + " | " + options.icd_desc}
                        onChange={(e, value) => {

                            this.setState({
                                testRow: value,
                                selecticd: value
                            })
                        }}
                        value={states.selecticd}
                        size='small'
                        className='eMed_DisSum_Icd_TxtBox'
                        disablePortal
                        id="combobox-demo"
                        renderInput={(params) => <TextField onChange={(event) => {
                            const value = event.target.value
                            if (value === "" || value != "") { this.setState({ icdCode: value }, () => { this.getICDcode() }) }
                        }} {...params} placeholder="Enter ICD Code / Short Description / Long Description" />}
                    />
                    <Button id='eMed_Config_btn' onClick={() => { label === "Admission Diagnosis" ? this.addAdmissionDig() : this.addDisDignosis() }} variant='contained'>Add</Button>
                </Box>
                <Box sx={{ marginTop: '1vw' }}>
                    <Paper elevation={0}>
                        <TableContainer sx={{ minHeight: '23vh' }} component={Paper}>
                            <Table sx={{ minWidth: '30vw' }} aria-label="simple table">
                                <TableHead sx={{ backgroundColor: Colors.DataTblHeaderbg }}>
                                    <TableRow>
                                        <TableCell width={'5vw'}>S.No</TableCell>
                                        <TableCell width={'20vw'}>ICD 10 Codes</TableCell>
                                        <TableCell width={'35vw'}>Short Description</TableCell>
                                        <TableCell width={'35vw'}>Long Description</TableCell>
                                        <TableCell width={'5vw'}>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                {label === "Admission Diagnosis" ? icdADTblData?.length > 0 ? icdADTblData.map((item, index) => (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell width={'5vw'}>{index + 1}</TableCell>
                                            <TableCell width={'20vw'}>{item.icd_code}</TableCell>
                                            <TableCell width={'35vw'}>
                                                {item.icd_short_desc?.length > 40 ?
                                                    <Tooltip title={item.icd_short_desc} placement='top' arrow>
                                                        <Typography>{item.icd_short_desc.slice(0, 40) + '...'}</Typography>
                                                    </Tooltip> : <Typography>{item.icd_short_desc}</Typography>}
                                            </TableCell>
                                            <TableCell width={'35vw'}>
                                                {item.icd_desc?.length > 40 ?
                                                    <Tooltip title={item.icd_desc} placement='top' arrow>
                                                        <Typography>{item.icd_desc.slice(0, 40) + '...'}</Typography>
                                                    </Tooltip> : <Typography>{item.icd_desc}</Typography>}</TableCell>
                                            <TableCell width={'5vw'}>
                                                <Box className='eMed_Dis_Tem_ActionBtn'>
                                                    <Tooltip title={'Delete'} placement='top' arrow>
                                                        <IconButton onClick={() => {
                                                            icdADTblData.splice(index, 1)
                                                            this.setState({
                                                                icdADTblData
                                                            })
                                                        }}>
                                                            {<img className='eMed_DisSum_Lay_StarIcon' src={ImagePaths.Delete.default} alt='Delete' />}
                                                        </IconButton>
                                                    </Tooltip>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )) : null : icdDDTblData?.length > 0 ? icdDDTblData.map((item, index) => (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell width={'5vw'}>{index + 1}</TableCell>
                                            <TableCell width={'20vw'}>{item.icd_code}</TableCell>
                                            <TableCell width={'35vw'}>
                                                {item.icd_short_desc?.length > 40 ?
                                                    <Tooltip title={item.icd_short_desc} placement='top' arrow>
                                                        <Typography>{item.icd_short_desc.slice(0, 40) + '...'}</Typography>
                                                    </Tooltip> : <Typography>{item.icd_short_desc}</Typography>}
                                            </TableCell>
                                            <TableCell width={'35vw'}>
                                                {item.icd_desc?.length > 40 ?
                                                    <Tooltip title={item.icd_desc} placement='top' arrow>
                                                        <Typography>{item.icd_desc.slice(0, 40) + '...'}</Typography>
                                                    </Tooltip> : <Typography>{item.icd_desc}</Typography>}</TableCell>
                                            <TableCell width={'5vw'}>
                                                <Box className='eMed_Dis_Tem_ActionBtn'>
                                                    <Tooltip title={'Delete'} placement='top' arrow>
                                                        <IconButton onClick={() => {
                                                            icdDDTblData.splice(index, 1)
                                                            this.setState({
                                                                icdDDTblData
                                                            })
                                                        }}>
                                                            {<img className='eMed_DisSum_Lay_StarIcon' src={ImagePaths.Delete.default} alt='Delete' />}
                                                        </IconButton>
                                                    </Tooltip>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )) : null}
                            </Table>
                            {label === "Admission Diagnosis" ? icdADTblData?.length === 0 ?
                                <Box className='eMed_Dis_Template_NoRecord'>
                                    <Typography >{t('NoRecordsFound')}</Typography>
                                </Box> : null : icdDDTblData?.length === 0 ?
                                <Box className='eMed_Dis_Template_NoRecord'>
                                    <Typography >{t('NoRecordsFound')}</Typography>
                                </Box> : null}
                        </TableContainer>
                    </Paper>
                </Box>
            </Box>
        )
    }
    addAdmissionDig = () => {
        var states = this.state
        var duplicate = this.admissonDiagdulpicate()
        if (states.selecticd &&
            !duplicate) {
            states["icdADTblData"]?.push({
                "icd_code": states.selecticd.icd_code,
                "icd_short_desc": states.selecticd.icd_short_desc,
                "icd_desc": states.selecticd.icd_desc
            })
            this.setState({ states }, () => { this.setState({ selecticd: null, icdCode: '', codeICDapi: [] }) })
        } else if (duplicate) {
            this.errorMessage('Already ICD Code Added')
        } else {
            this.errorMessage("Please Enter the ICD Details")
        }
    }
    addDisDignosis = () => {
        var states = this.state
        var duplicate = this.DischargeDiagdulpicate()
        if (states.selecticd &&
            !duplicate) {
            states["icdDDTblData"].push({
                "icd_code": states.selecticd.icd_code,
                "icd_short_desc": states.selecticd.icd_short_desc,
                "icd_desc": states.selecticd.icd_desc
            })
            this.setState({ states }, () => { this.setState({ selecticd: null, icdCode: '', codeICDapi: [] }) })
        } else if (duplicate) {
            this.errorMessage('Already ICD Code Added')
        } else {
            this.errorMessage("Please Enter the ICD Details")
        }
    }
    admissonDiagdulpicate = () => {
        var { selecticd, icdADTblData } = this.state
        var duplicate = false
        if (selecticd?.icd_desc) {
            for (let i = 0; i < icdADTblData.length; i++) {
                if (icdADTblData[i].icd_code == selecticd.icd_code) {
                    duplicate = true
                }
            }
        }
        return duplicate
    }
    DischargeDiagdulpicate = () => {
        var { selecticd, icdDDTblData } = this.state
        var duplicate = false
        if (selecticd?.icd_desc) {
            for (let i = 0; i < icdDDTblData.length; i++) {
                if (icdDDTblData[i].icd_code == selecticd.icd_code) {
                    duplicate = true
                }
            }
        }
        return duplicate
    }
    renderTextBoxs = (label, width, key, mantatory, isrow, is_active) => {
        var states = this.state
        if (is_active) {
            return (
                <Box className='eMed_DisSum_Lay_CommonBox1'>
                    {/* <TextField
                        sx={{ width: width }}
                        id="icd-code"
                        multiline={isrow}
                        // rows={3}
                        name={key}
                        label={mantatory ? label + "*" : label}
                        variant="outlined"
                        value={states.postDisSumData[key] ? states.postDisSumData[key] : ''}
                        onChange={(event) => {
                            states.postDisSumData[key] = event.target.value
                            this.setState({
                                states
                            })
                        }}
                    /> */}
                    <div className="discharge_Sum_outer_container">
                        <ToolsFieldWithMic
                        Title={mantatory ? label + "*" : label}
                        EditorRef={states.postDisSumData[key] ? states.postDisSumData[key] : ''} 
                        saveData={this.setEditedData.bind(this)} 
                        isFrom="dischargeSummary"
                        minHeight={"12vh"}
                        overallHeight={"auto"}
                        postState={key}  
                        />
                    </div>
                </Box>
            )
        }
    }
    renderTestTbl = () => {
        const { t } = this.props
        return (
            <Box className='eMed_DisSum_Lay_CommonBox1'>
                <Typography>{"Test Reports"}</Typography>
                <Paper elevation={0}>
                    <TableContainer sx={{ minHeight: '23vh' }} component={Paper}>
                        <Table sx={{ minWidth: '30vw' }} aria-label="simple table">
                            <TableHead sx={{ backgroundColor: Colors.DataTblHeaderbg }}>
                                <TableRow>
                                    <TableCell width={'5vw'}>{"Test Name/Test Group"}</TableCell>
                                    <TableCell width={'18vw'}>{"Tested On"}</TableCell>
                                    <TableCell width={'18vw'}>{"Turn Over Time"}</TableCell>
                                    <TableCell width={'18vw'}>{"Action"}</TableCell>
                                </TableRow>
                            </TableHead>
                        </Table>
                        <Box className='eMed_Dis_Template_NoRecord'>
                            <Typography >{t('NoRecordsFound')}</Typography>
                        </Box>
                    </TableContainer>
                </Paper>
            </Box>
        )
    }


    handleDragStart = (index, event) => {
        event.dataTransfer.setData("text/plain", index);
        this.setState({ dragIndex: index });
    };

    handleDragOver = (index, event) => {
        event.preventDefault();
        const { medicationTbl, dragIndex } = this.state;
        if (dragIndex !== -1 && dragIndex !== index) {
            const draggedItem = medicationTbl[dragIndex];
            const updatedItems = medicationTbl.filter((item, i) => i !== dragIndex);
            updatedItems.splice(index, 0, draggedItem);
            this.setState({ medicationTbl: updatedItems, dragIndex: index });
        }
    };

    handleDragEnd = () => {
        this.setState({ dragIndex: -1 });
    };
    renderMedication = (mantatory) => {
        const { t } = this.props
        var states = this.state
        return (
            <Box className='eMed_DisSum_Lay_CommonBox1'>
                {/* <Typography>{mantatory ? 'Medication*' : 'Medication'}</Typography> */}
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', alignItems: "center" }}>
                        <Typography>{mantatory ? 'Medication*' : 'Medication'}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: "center" }}>
                        <FormControl size='small' sx={{ display: this.state.PharmacyList?.length > 1 ? 'inline' : 'none' }}>
                            <InputLabel>Select Pharmacy</InputLabel>
                            <Select
                                label='select Pharmacy'
                                value={this.state.selectedPharmacy}
                                onChange={(e) => this.setState({ selectedPharmacy: e.target.value })}
                            >
                                {this.state.PharmacyList?.length > 0 && this.state.PharmacyList?.map((item, index) => (
                                    <MenuItem value={item?.pharmacy_id} key={index}>{item?.pharmacy_name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
                <Box className='eMed_DisSum_Tem_MediTextBox'>
                    {this.medicationTextBox('Medicine Name', states.mediName, 'mediName')}
                    {this.medicationTextBox('Dosage Type / Strength', states.mediDosage, 'mediDosage')}
                    {this.medicationTextBox('Timing', states.selectMEAN, 'selectMEAN')}
                    {/* <FormControl size='small' sx={{ width: '12vw' }}>
                        <InputLabel id="demo-simple-selet-label">M-A-E-N</InputLabel>
                        <Select
                            labelId="demo-simple-selet-label"
                            id="demo-simple-select"
                            value={this.state.selectMEAN}
                            label="M-A-E-N"
                            onChange={(event) => {
                                this.setState({
                                    selectMEAN: event.target.value
                                })
                            }}
                        >
                            <MenuItem value={'Morning'}>Morning</MenuItem>
                            <MenuItem value={'Afternoon'}>Afternoon</MenuItem>
                            <MenuItem value={'Evening'}>Evening</MenuItem>
                            <MenuItem value={'Night'}>Night</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl size='small' sx={{ width: '12vw' }}>
                        <InputLabel id="demo-simple-label">Consumptions</InputLabel>
                        <Select
                            labelId="demo-simple-label"
                            id="demo-simple-select"
                            value={this.state.selectConsumptions}
                            label="Consumables"
                            onChange={(event) => {
                                this.setState({
                                    selectConsumptions: event.target.value
                                })
                            }}
                        >
                            <MenuItem value={'Empty Stomach'}>Empty Stomach</MenuItem>
                            <MenuItem value={'Before Food'}>Before Food</MenuItem>
                            <MenuItem value={'With Food'}>With Food</MenuItem>
                            <MenuItem value={'After Food'}>After Food</MenuItem>
                            <MenuItem value={'External Use'}>External Use</MenuItem>
                        </Select>
                    </FormControl> */}
                    {this.medicationTextBox('Route', states.mediRoute, 'mediRoute')}
                    {this.medicationTextBox('Instructions', states.mediInstruction, 'mediInstruction')}
                    <Box>
                        <Tooltip title='Add' placement='top' arrow>
                            <IconButton onClick={() => { this.addMedication() }}>
                                {<img id='eMed_activeBtn' src={ImagePaths.AddIcon.default} alt='Add' />}
                            </IconButton>
                        </Tooltip>
                        <Tooltip title='Clear' placement='top' arrow>
                            <IconButton onClick={() => { this.clearMedication() }}>
                                {<img id='eMed_activeBtn' src={ImagePaths.ClearIcon.default} alt='Clear' />}
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Box>
                <Paper elevation={0}>
                    <TableContainer sx={{ minHeight: '23vh', marginTop: '1vw' }} component={Paper}>
                        <Table sx={{ minWidth: '30vw' }} aria-label="simple table">
                            <TableHead sx={{ backgroundColor: Colors.DataTblHeaderbg }}>
                                <TableRow>
                                    <TableCell width={'5vw'}>S.No</TableCell>
                                    <TableCell width={'18vw'}>Medicine Name</TableCell>
                                    <TableCell width={'18vw'}>Dosage Type / Strength</TableCell>
                                    <TableCell width={'18vw'}>Timing</TableCell>
                                    <TableCell width={'18vw'}>Route</TableCell>
                                    <TableCell width={'18vw'}>Instruction</TableCell>
                                    <TableCell width={'5vw'}>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            {states.medicationTbl?.length > 0 ? states.medicationTbl.map((item, index) => (
                                <TableBody>
                                    <TableRow
                                        draggable
                                        onDragStart={(event) => this.handleDragStart(index, event)}
                                        onDragOver={(event) => this.handleDragOver(index, event)}
                                        onDragEnd={this.handleDragEnd}
                                    >
                                        <TableCell width={'5vw'}>
                                            <img style={{ width: '0.9vw', height: "0.9vw", marginRight: '0.5vw' }} src={ImagePaths.DragIndicator.default} alt="Dot" />
                                            {index + 1}
                                        </TableCell>
                                        <TableCell width={'18vw'}>
                                            {item?.medicinename?.length > 15 ?
                                                <Tooltip title={item.medicinename} placement='top' arrow>
                                                    <Typography>{item.medicinename.slice(0, 15) + '...'}</Typography>
                                                </Tooltip> : <Typography>{item.medicinename}</Typography>}
                                        </TableCell>
                                        <TableCell width={'18vw'}>
                                            {item?.dosage?.length > 15 ?
                                                <Tooltip title={item.dosage} placement='top' arrow>
                                                    <Typography>{item.dosage.slice(0, 15) + '...'}</Typography>
                                                </Tooltip> : <Typography>{item.dosage}</Typography>}
                                        </TableCell>
                                        <TableCell width={'18vw'}>{item.maen}</TableCell>
                                        <TableCell width={'18vw'}>
                                            {item?.route?.length > 15 ?
                                                <Tooltip title={item.route} placement='top' arrow>
                                                    <Typography>{item.route.slice(0, 15) + '...'}</Typography>
                                                </Tooltip> : <Typography>{item.route}</Typography>}
                                        </TableCell>
                                        <TableCell width={'18vw'}>
                                            {item?.instruction?.length > 20 ?
                                                <Tooltip title={item.instruction} placement='top' arrow>
                                                    <Typography>{item.instruction.slice(0, 20) + '...'}</Typography>
                                                </Tooltip> : <Typography>{item.instruction}</Typography>}
                                        </TableCell>
                                        <TableCell width={'5vw'}>
                                            <Box className='eMed_Dis_Tem_ActionBtn'>
                                                <Tooltip title={'Eidt'} placement='top' arrow>
                                                    <IconButton onClick={() => { this.editMedication(item, index) }}>
                                                        {<img className='eMed_DisSum_Lay_TblDel' src={ImagePaths.Edit.default} alt='Edit' />}
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title={'Delete'} placement='top' arrow>
                                                    <IconButton onClick={() => {
                                                        states.medicationTbl.splice(index, 1)
                                                        this.setState({
                                                            states,
                                                            mediRoute: '',
                                                            mediName: '',
                                                            mediDosage: '',
                                                            selectMEAN: '',
                                                            selectConsumptions: '',
                                                            mediInstruction: '',
                                                        })
                                                    }}>
                                                        {<img className='eMed_DisSum_Lay_TblDel' src={ImagePaths.Delete.default} alt='Delete' />}
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )) : null}
                        </Table>
                        {states.medicationTbl?.length === 0 ?
                            <Box className='eMed_Dis_Template_NoRecord'>
                                <Typography >{t('NoRecordsFound')}</Typography>
                            </Box> : null}
                    </TableContainer>
                </Paper>
            </Box>
        )
    }

    duplicateCheck = () => {
        var { mediRoute, mediName, mediDosage, selectMEAN, selectConsumptions, mediInstruction, medicationTbl, selectedIndex } = this.state
        var duplicate = ''
        if (!(mediRoute.trim()) || !(mediName.trim()) || !(mediDosage.trim()) || !(selectMEAN.trim())) {
            duplicate = "Kindly Enter the Mandatory Fields"
        } else if (mediRoute || mediName || mediDosage || selectMEAN || mediInstruction) {
            for (let i = 0; i < medicationTbl.length; i++) {
                if (medicationTbl[i].route == mediRoute && medicationTbl[i].medicinename == mediName && medicationTbl[i].dosage == mediDosage && medicationTbl[i].maen == selectMEAN &&
                    medicationTbl[i].instruction == mediInstruction) {
                    duplicate = "Already Value Added"
                }
            }
        }
        return duplicate
    }
    addMedication = () => {
        var states = this.state
        var duplicate = this.duplicateCheck()
        var data = {
            'route': states.mediRoute.trim(),
            'medicinename': states.mediName.trim(),
            'dosage': states.mediDosage.trim(),
            'maen': states.selectMEAN,
            // 'consumption': states.selectConsumptions,
            'instruction': states.mediInstruction.trim()
        }
        if (states.selectedIndex != null && !duplicate) {
            states.medicationTbl[states.selectedIndex] = data
            this.setState({
                states
            }, () => { this.clearMedication() })
        } else if (duplicate) {
            this.errorMessage(duplicate)
        } else {
            if (states.mediRoute &&
                states.mediName &&
                states.mediDosage &&
                states.selectMEAN) {

                states['medicationTbl'].push(data)
                this.setState({
                    states
                }, () => { this.clearMedication() })
            } else {
                this.errorMessage('Please Enter All the Feilds')
            }
        }
    }
    editMedication = (item, index) => {
        this.setState({
            mediRoute: item.route,
            mediName: item.medicinename,
            mediDosage: item.dosage,
            selectMEAN: item.maen,
            selectConsumptions: item.consumption,
            mediInstruction: item.instruction,
            selectedIndex: index
        })
    }
    clearMedication = () => {
        this.setState({
            mediRoute: '',
            mediName: '',
            mediDosage: '',
            selectMEAN: '',
            selectConsumptions: '',
            mediInstruction: '',
            selectedIndex: null,
        })
    }
    medicationTextBox = (label, value, name) => {
        var states = this.state
        let { fromBillSummary } = this.props;
        return (
            <Box >
                {
                    name === "mediName" ?
                        <Autocomplete
                            disableClearable
                            value={value ? value : ""}
                            sx={{ width: "20vw" }}
                            size="small"
                            options={states.brandnameList.length != 0 ? states["brandnameList"] : []}
                            getOptionLabel={option => typeof (option) === "string" ? option : `${option?.drug_name} - ${option?.generic_name}`}
                            onChange={(event, newbrand) => {
                                states["mediName"] = `${newbrand?.drug_name} - ${newbrand?.generic_name}`;
                                states["mediDosage"] = `${newbrand?.dosage_type ? newbrand?.dosage_type : ""} / ${newbrand?.dosage_strength ? newbrand?.dosage_strength : ""}`;
                                this.setState({
                                    states
                                })
                            }}
                            onInputChange={(event, newBrand) => {
                                if (newBrand.length > 2) {
                                    this._getBrandList(newBrand);
                                } else {
                                    this.setState({ brandnameList: [] })
                                }
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={"Medicine Name"}
                                    autoComplete="off"
                                    size="small"
                                    InputProps={{
                                        ...params.InputProps,
                                    }}
                                    onChange={(event) => {
                                        states["mediName"] = event.target.value
                                        this.setState({
                                            states
                                        })
                                    }}
                                />
                            )}
                        />
                        : name === "mediRoute" ?
                            <Autocomplete
                                freeSolo
                                disableClearable
                                value={value ? value : ""}
                                sx={{ width: "15vw" }}
                                size="small"
                                options={(IP_routeList.length != 0) ? IP_routeList : []}
                                getOptionLabel={option => typeof (option) === "string" ? option : option.value}
                                onChange={(event, newRoute) => {
                                    states["mediRoute"] = newRoute.value
                                    this.setState({
                                        states
                                    })
                                }}
                                onInputChange={(e, value) => {
                                    states["mediRoute"] = value
                                    this.setState({ states })
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={label}
                                        autoComplete="off"
                                        size="small"
                                        InputProps={{
                                            ...params.InputProps,
                                        }}
                                    />
                                )}
                            />
                            :
                            <TextField
                                label={label}
                                value={value}
                                name={name}
                                size='small'
                                inputProps={{ readOnly: (states.isFinish === true || fromBillSummary) ? true : false }}
                                variant='outlined'
                                onChange={(event) => {
                                    const name = event.target.name
                                    const value = event.target.value
                                    if (value != "" || value === '') {
                                        this.setState({
                                            [name]: value
                                        })
                                    }
                                }}
                            />}
            </Box>
        )
    }
    renderDisInstruction = (mantatory) => {
        const { t } = this.props
        return (
            <Box className='eMed_DisSum_Lay_disBox' height={'auto'} >
                <Box className='eMed_DisSum_Lay_CommonBox'>
                    <Typography>{mantatory ? 'Discharge Instruction*' : 'Discharge Instruction'}</Typography>
                    <Box className='eMed_DisSum_Lay_Dietcon' sx={{display: "flex", justifyContent: "space-between", alignItems: "flex-start", gap: "2vw", marginBottom: "2vw"}}>
                        {/* <FormControl size='small' className='eMed_DisSum_Template_Diet'>
                            <InputLabel id="Dis_lay_Diet">{t('Diet')}</InputLabel>
                            <Select
                                labelId="Dis_lay_Diet"
                                id="Dis_lay_Diet"
                                value={this.state.selectDiet}
                                label={t('Diet')}
                                onChange={(event) => {
                                    this.setState({
                                        selectDiet: event.target.value
                                    })
                                }}
                            >
                                <MenuItem value={1}>Fruit</MenuItem>
                                <MenuItem value={2}>Milk</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl size='small' className='eMed_DisSum_Template_Diet'>
                            <InputLabel id="Dis_lay_Diet">{t('Activity')}</InputLabel>
                            <Select
                                labelId="Dis_lay_Diet"
                                id="Dis_lay_Diet"
                                value={this.state.selectActivity}
                                label={t('Activity')}
                                onChange={(event) => {
                                    this.setState({
                                        selectActivity: event.target.value
                                    })
                                }}
                            >
                                <MenuItem value={1}>Walking</MenuItem>
                                <MenuItem value={2}>Running</MenuItem>
                            </Select>
                        </FormControl> */}

                        {/* <TextField className='eMed_DisSum_Template_Diet'
                            autoComplete="off"
                            size="small"
                            label={t('Diet')}
                            value={this.state.selectDiet}
                            onChange={(event) => {
                                this.setState({
                                    selectDiet: event.target.value
                                })
                            }}
                        /> */}
                        <div style={{width:"48%"}}>
                            <ToolsFieldWithMic
                                Title={t('Diet')} 
                                EditorRef={this.state.selectDiet ? this.state.selectDiet : ''} 
                                saveData={this.setEditedData.bind(this)} 
                                isFrom="dischargeSummary"
                                minHeight={"12vh"}
                                overallHeight={"auto"}
                                stateName='selectDiet'  
                            />
                        </div>
                        {/* <TextField className='eMed_DisSum_Template_Diet'
                            autoComplete="off"
                            size="small"
                            label={t('Activity')}
                            value={this.state.selectActivity}
                            onChange={(event) => {
                                this.setState({
                                    selectActivity: event.target.value
                                })
                            }}
                        /> */}
                        <div style={{width:"48%"}}>
                            <ToolsFieldWithMic
                                Title={t('Activity')} 
                                EditorRef={this.state.selectActivity ? this.state.selectActivity : ''} 
                                saveData={this.setEditedData.bind(this)} 
                                isFrom="dischargeSummary"
                                minHeight={"12vh"}
                                overallHeight={"auto"}
                                stateName='selectActivity'  
                            />
                        </div>
                    </Box>
                    {/* <TextField
                        sx={{ width: '87.3vw', marginTop: '1vw' }}
                        id="icd-code"
                        multiline
                        // rows={3}
                        value={this.state.adviceDischarge}
                        label={t('AdviceonDischarge*')}
                        variant="outlined"
                        onChange={(event) => {
                            this.setState({
                                adviceDischarge: event.target.value
                            })
                        }}
                    /> */}
                    <ToolsFieldWithMic
                        Title={t('AdviceonDischarge*')} 
                        EditorRef={this.state.adviceDischarge ? this.state.adviceDischarge : ''}
                        saveData={this.setEditedData.bind(this)} 
                        isFrom="dischargeSummary"
                        minHeight={"12vh"}
                        overallHeight={"auto"}
                        stateName='adviceDischarge'  
                    />
                </Box>
            </Box>
        )
    }

    saveTemplate = () => {
        try {
            var states = this.state
            var data = states.disLayoutDesign.filter((item) => (item.id == states.selectLayout))
            var isAdmissionDiagnosis = false
            var isMedication = false
            var isInvestigation = false
            var isExamination = false
            data[0].dis_sum_json.find((item) => {
                if (item.label === "Admission Diagnosis") {
                    isAdmissionDiagnosis = true
                } else if (item.label === "Investigation and Test Report") {
                    isInvestigation = true
                } else if (item.label === "Medication") {
                    isMedication = true
                } else if (item.label === "Examination") {
                    isExamination = true
                }
            })
            states.postDisSumData.admission_diagnosis = states.icdADTblData.length > 0 && isAdmissionDiagnosis ? states.icdADTblData : []
            states.postDisSumData["provisional_diagnosis"] = (isAdmissionDiagnosis && states?.selectAdmissionProvisional?.length > 0) ? states.selectAdmissionProvisional : ""
            states.postDisSumData.discharge_diagnosis = states.icdDDTblData.length > 0 ? states.icdDDTblData : ''
            states.postDisSumData["discharge_provisional_diagnosis"] = (states?.selectDischargeProvisional?.length > 0) ? states.selectDischargeProvisional : ""
            states.postDisSumData.medication = states.medicationTbl.length > 0 && isMedication ? states.medicationTbl : ''
            states.postDisSumData.examination = states?.postDisSumData?.examination && isExamination ? states.postDisSumData.examination : ''
            states.postDisSumData.discharge_instruction = (states.selectDiet || states.selectActivity) || states.adviceDischarge ? {
                "diet": states.selectDiet,
                "acivity": states.selectActivity,
                "advice_discharge": states.adviceDischarge
            } : ''
            states.postDisSumData.name_anaesthetist_and_type_of_anaesthesia = states.typeAnaesthesia && states.nameAnaesthesia ? {
                "type_of_anaesthesia": states.typeAnaesthesia,
                "name_of_anaesthetist": states.nameAnaesthesia
            } : ''
            states.postDisSumData.investigation_and_test_report = ((states.testData || states.invetigation) && isInvestigation) ? {
                "investigation": states.invetigation,
                "test_report": states.testData ? states.testData : []
            } : ''
            this.setState({
                states
            })
            if (this.mantatoryFeilds()) {
                this.postDisSumTemplate()
            }
        } catch (e) {
            this.errorMessage(e.message)
        }
    }
    mantatoryFeilds = () => {
        var states = this.state
        var data = states.disLayoutDesign.filter((item) => (item.id == states.selectLayout))
        var ManFeild = data[0].dis_sum_json

        let flag = true;
        for (let i = 0; i < ManFeild.length; i++) {
            if (ManFeild[i].is_mantatory === true && flag && ManFeild[i].is_active === true) {
                switch (ManFeild[i].label) {
                    case "Admission Diagnosis":
                        if (ManFeild[i].label == "Admission Diagnosis" && states?.icdADTblData.length === 0 && states.selectAdmissionProvisional === "") {
                            flag = false
                            this.errorMessage(`${ManFeild[i].label} is Mantatory`)
                            break
                        }
                        break;
                    case "Discharge Diagnosis":
                        if (ManFeild[i].label == "Discharge Diagnosis" && states?.icdDDTblData.length === 0 && states.selectDischargeProvisional === "") {
                            flag = false
                            this.errorMessage(`${ManFeild[i].label} is Mantatory`)
                            break
                        }
                        break;
                    default:
                        if (states.postDisSumData[ManFeild[i].field_name] === '') {
                            flag = false
                            this.errorMessage(`${ManFeild[i].label} is Mantatory`)
                            break
                        } else {
                            flag = true
                        }
                }
            }
        }
        return flag
    }
    ClearTemplateData = () => {
        var states = this.state
        var data = states.disLayoutDesign.filter((item) => (item.id == states.selectLayout))
        var keys = {}
        data[0]?.dis_sum_json?.forEach((element) => {
            keys[element.field_name] = ''

        })
        this.setState({
            postDisSumData: keys,
            icdADTblData: [],
            icdDDTblData: [],
            typeAnaesthesia: '',
            nameAnaesthesia: '',
            medicationTbl: [],
            selectDiet: '',
            selectActivity: '',
            adviceDischarge: '',
        })
        this.renderDisSumEditLayout()
    }
    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }
    render() {
        const { t } = this.props
        return (
            <Box Component='div' paddingTop={'1vw'} className='eMed_usrconf_container'>
                <Box className='eMed_Dis_Template_MainCon'>
                    <Box className='eMed_Dis_Tem_HeadCon'>
                        <Typography padding={'0.5vw'}>{t('DischargeSummary')}</Typography>
                    </Box>
                    {this.selectLayoutCon()}
                    <hr />
                    {this.renderDisSumEditLayout()}
                    <Box className='eMed_Dis_Sum_Tem_BtnGroup'>
                        <Stack spacing={2} direction="row">
                            {this.state.temID ? <Button color='error'
                                disabled={!CheckAccessFunc("front_office", "Settings", "Template Configuration", "Discharge Summary Template Configuration", "SubTab")?.editAccess}
                                id='eMed_Config_btn' className='eMed_DisSum_Lay_select2' variant="outlined" onClick={() => {
                                    this.props.history.push({ pathname: '/Settings/TemplateConfig/DisSumTemplateConfig/', state: { deleteID: this.state.temID } })
                                }}>{t('Delete')}</Button> : null}
                            <Button id='eMed_Config_btn' className='eMed_DisSum_Lay_select2' variant="outlined" onClick={() => { this.ClearTemplateData() }}>{t('Clear')}</Button>
                            <Button id='eMed_Config_btn' disabled={this.state.selectLayout === '' || !CheckAccessFunc("front_office", "Settings", "Template Configuration", "Discharge Summary Template Configuration", "SubTab")?.editAccess ? true : false} className='eMed_DisSum_Lay_select2' onClick={() => { this.saveTemplate() }} variant="contained">{t('Save')}</Button>
                        </Stack>
                    </Box>
                </Box>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                <Loader loaderOpen={this.state.isLoader} />
            </Box>
        )
    }
}
export default withTranslation()(DisSumTemplateAddConfig);