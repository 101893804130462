import { Box, Button, Drawer, Stack, Tooltip } from '@mui/material'
import React, { Component } from 'react'
import '../pharmaReports.css';
import { Colors } from '../../../../Styles/Colors';
import { AmountsCard } from '../../../../Components/CommonCards/CommonCards';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { DataGrid } from '@mui/x-data-grid';
import { CommonGridToolBarWithFilterText } from '../../../../Components/Common Components/CommonComponents';
import { formatDate, AmountFormat } from '../../../../Components/CommonFunctions/CommonFunctions';
import { withTranslation } from 'react-i18next';
import PharmaFilter from '../PharmaFilter';
import { DateTime } from 'luxon';
import { CurrencySymbol } from '../../../../Utility/Constants';

class PurchaseReturn extends Component {

    constructor(props) {
        super(props)
        this.state = {
            purchaseReturnData: [],
            page: 0,
            pageSize: 10,
            rowCount: 0,
            filterDatas: {
                'fromDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                'toDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                "dateIndex": 1,
                "days":null
            },
            pageName: 'purchaseReturn',
            filterOpen: false,
            priceQuantity: 0,
            totalPrice: 0,
            isLoader: false,
        }
    }

    componentDidMount() {
        this.getPurchaseReturnData()
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    renderFilter = (data) => {
        this.setState({
            filterDatas: data,
            filterOpen: false,
            page: 0,
        }, () => { this.getPurchaseReturnData() })
    }

    ClosePopUp() {
        this.setState({
            filterOpen: false,
        })
    }

    clearFilter = (data) => {
        this.setState({
            filterDatas: data
        })
    }

    gridToolBar = () => {
        var { t } = this.props
        let TextArray = [
            { label: "From Date", value: formatDate(this.state.filterDatas.fromDate) },
            { label: "To Date", value: formatDate(this.state.filterDatas.toDate) },
        ]
        return (
            <Box>
                <CommonGridToolBarWithFilterText FilterTextArray={TextArray} noExport={true} />
            </Box>

        )
    }

    getPurchaseReturnData = () => {
        try {
            this.LoaderFunction(true);
            RestAPIService.getAll(Serviceurls.PHARMA_PURCHASE_RETURN_REPORT_GET + `?from_date=${this.state.filterDatas.fromDate}&to_date=${this.state.filterDatas.toDate}&page=${this.state.page + 1}&page_size=${this.state.pageSize}`)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.LoaderFunction(false);
                        this.setState({
                            purchaseReturnData: Array.isArray(response.data.data?.result) ? response.data.data?.result : [],
                            totalPrice: response.data.data?.total_price,
                            priceQuantity: response.data.data?.total_price_per_quantity,
                            rowCount: response.data.data?.total_count
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.LoaderFunction(false);
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.LoaderFunction(false);
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getPurchaseReturnExcel = () => {
        try {
            this.setState({disableBtn: true})
            RestAPIService.excelGet(Serviceurls.PHARMA_PURCHASE_RETURN_REPORT_GET + `?from_date=${this.state.filterDatas.fromDate}&to_date=${this.state.filterDatas.toDate}&page=${this.state.page + 1}&page_size=${this.state.pageSize}&export=excel`).
                then((response) => {
                    if (response.data) {
                        var pom = document.createElement('a');
                        var csvContent = response.data; //here we load our csv data 

                        let filename = response.headers["content-disposition"].split("filename=")[1]
                        // var blob = new Blob([csvContent], {type: 'data:application/vnd.ms-excel;base64'});

                        var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
                        var url = URL.createObjectURL(blob);
                        pom.href = url;
                        pom.setAttribute('download', filename ? filename : 'registry.xlsx');
                        pom.click();
                        this.setState({disableBtn: false})
                    }
                }).catch((error) => {
                    if (error.response?.data?.message) {
                        this.errorMessage(error.response?.data?.message);
                    } else {
                        this.errorMessage(error.message);
                    }
                    this.setState({disableBtn: false})
                })
        } catch (error) {
            this.errorMessage(error.message);
            this.setState({disableBtn: false})
        }
    }
 

    PurchaeReturnPrint = () => {
        try {
          this.setState({disabled: true})
          RestAPIService.getAll(Serviceurls.PHARMA_PURCHASE_RETURN_REPORT_GET + `?from_date=${this.state.filterDatas.fromDate}&to_date=${this.state.filterDatas.toDate}&page=${this.state.page + 1}&page_size=${this.state.pageSize}&export=pdf`).
            then((response) => {
              const file = new Blob(
                [response.data],
                { type: 'application/pdf' });
              const fileURL = URL.createObjectURL(file);
              window.open(fileURL);
              this.setState({disabled: false})
            }).catch((error) => {
              if(error?.response?.data?.message){
                this.errorMessage(error?.response?.data?.message)
              }else{
                this.errorMessage(error.message)
              }
              this.setState({disabled: false})
            })
        } catch (error) {
          this.errorMessage(error.message)
          this.setState({disabled: false})
        }
      }
    render() {
        const { t } = this.props
        this.state.purchaseReturnData?.forEach((element, index) => element.sno = index + 1)
        const columns = [
            {
                field: 'po_return_date', headerName: "Return Date", flex: 0.083, align: 'center', headerAlign: 'center',
                renderCell: (params) => (<Box component={'div'}>
                    {params?.row?.po_return_date?.length > 12 ? <Tooltip placement='top' title={params?.row?.po_return_date} arrow><div>{params?.row?.po_return_date?.slice(0, 12) + '...'}</div></Tooltip> :
                        params?.row?.po_return_date ? formatDate(params?.row?.po_return_date) : '-'}
                </Box>)
            },
            {
                field: 'epr_valuepo_return_number', headerName: "Return No", flex: 0.083, type: "number",
                renderCell: (parmas) => (<Box>{parmas.row?.po_return_number ? parmas.row?.po_return_number : '-'}</Box>)
            },
            {
                field: 'invoice_number', headerName: "Invoice No", flex: 0.083, type: "number",
                renderCell: (params) => (<Box>{params.row?.invoice_number ? params.row?.invoice_number?.length > 10 ? <Tooltip placement='top' title={params?.row?.invoice_number} arrow><div>{params?.row?.invoice_number?.slice(0, 7) + '...'}</div></Tooltip> : params?.row?.invoice_number : '-'}</Box>)
            },
            {
                field: 'vendor_name', headerName: "Vendor Name", flex: 0.083,
                renderCell: (params) => (<Box component={'div'}>
                    {params?.row?.vendor_name?.length > 12 ? <Tooltip placement='top' title={params?.row?.vendor_name} arrow><div>{params?.row?.vendor_name?.slice(0, 12) + '...'}</div></Tooltip> :
                        params?.row?.vendor_name ? params?.row?.vendor_name : '-'}
                </Box>)
            },
            {
                field: 'batch_no', headerName: "Batch No.", flex: 0.083,
                renderCell: (params) => (<Box component={'div'}>
                    {params?.row?.batch_no?.length > 12 ? <Tooltip placement='top' title={params?.row?.batch_no} arrow><div>{params?.row?.batch_no?.slice(0, 12) + '...'}</div></Tooltip> :
                        params?.row?.batch_no ? params?.row?.batch_no : '-'}
                </Box>)
            },
            {
                field: 'brand_name', headerName: "Brand Name", flex: 0.083,
                renderCell: (params) => (<Box component={'div'}>
                    {params?.row?.brand_name?.length > 12 ? <Tooltip placement='top' title={params?.row?.brand_name} arrow><div>{params?.row?.brand_name?.slice(0, 12) + '...'}</div></Tooltip> :
                        params?.row?.brand_name ? params?.row?.brand_name : '-'}
                </Box>)
            },
            {
                field: 'generic_name', headerName: "Generic Name", flex: 0.083, type: "number",
                renderCell: (params) => (<Box component={'div'}>
                    {params?.row?.generic_name?.length > 12 ? <Tooltip placement='top' title={params?.row?.generic_name} arrow><div>{params?.row?.generic_name?.slice(0, 12) + '...'}</div></Tooltip> :
                        params?.row?.generic_name ? params?.row?.generic_name : '-'}
                </Box>)
            },
            {
                field: 'dosage_type', headerName: "Dosage Type", flex: 0.083, type: "number",
                renderCell: (parmas) => (<Box>{parmas.row?.dosage_type ? parmas.row?.dosage_type : '-'}</Box>)
            },
            {
                field: 'dosage_strength', headerName: "Dosage Strength", flex: 0.083, type: "number",
                renderCell: (parmas) => (<Box>{parmas.row?.dosage_strength ? parmas.row?.dosage_strength : '0'}</Box>)
            },
            {
                field: 'quantity', headerName: "Quantity", flex: 0.083, type: "number",
                renderCell: (parmas) => (<Box>{parmas.row?.quantity ? parmas.row?.quantity : '0'}</Box>)
            },
            {
                field: 'price_per_quantity', headerName: `${"PP/Qty"} (${CurrencySymbol})`, flex: 0.083, type: "number",
                renderCell: (parmas) => (<Box>{AmountFormat(parmas.row?.price_per_quantity || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
            },
            {
                field: 'cgst_amount', headerName: `${"CGST"} (${CurrencySymbol})`, flex: 0.083, type: "number",
                renderCell: ({ row }) => (<Box>{AmountFormat(row?.cgst_amount || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
            },
            {
                field: 'sgst_amount', headerName: `${"SGST"} (${CurrencySymbol})`, flex: 0.083, type: "number",
                renderCell: ({ row }) => (<Box>{AmountFormat(row?.sgst_amount || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
            },
            {
                field: 'taxable_amount', headerName: `${"Taxable Amount"} (${CurrencySymbol})`, flex: 0.083, type: "number",
                renderCell: ({ row }) => (<Box>{AmountFormat(row?.taxable_amount || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
            },
            {
                field: 'total_price', headerName: `${"Total Price"} (${CurrencySymbol})`, flex: 0.083, type: "number",
                renderCell: ({ row }) => (<Box>{AmountFormat(row?.total_price || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
            },
        ]
        return (
            <Box component={'div'} height={'80vh'} sx={{ backgroundColor: Colors.Background }}>
                <Box component={'div'} className='eMed_Pharma_rts_header'>
                    <Box component={'div'} flex={0.8} display={'flex'}>
                        {AmountsCard("Purchase Price/Quantity", this.state.priceQuantity, false, "white", false, null, 'rpt_purRtn_price_qty')}
                        {AmountsCard("Total", this.state.totalPrice, false, "white", false, null, 'rpt_purRtn_total')}
                    </Box>
                    <Box flex={0.2} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} marginRight={'0.5vw'}>
                        <Button emed_tid="rpt_purRtn_flt" className='eMed_Pharma_rts_btn' onClick={() => this.setState({ filterOpen: true })}>
                            <Box component={'img'} src={ImagePaths.Filter.default} alt='filter' height={'2.3vw'} width={'2.3vw'} />
                        </Button>
                        <Button className='eMed_Pharma_rts_btn' disabled={this.state.disabled}>
                            <Box component={'img'} src={ImagePaths.LabPrintIcon.default} alt='print' height={'2.3vw'} width={'2.3vw'} onClick={() => this.PurchaeReturnPrint()}/>
                        </Button>
                        <Button emed_tid="rpt_purRtn_export" className='eMed_Pharma_rts_btn' disabled={this.state.disableBtn} onClick={() => this.getPurchaseReturnExcel()}>
                            <Box component={'img'} src={ImagePaths.ExportIcon.default} alt='export' height={'2.3vw'} width={'2.3vw'} />
                        </Button>
                    </Box>
                </Box>
                <Box component={'div'} className='eMed_Pharma_rts_tbl_card'>
                    <DataGrid
                        className='eMed_Pharma_table'
                        rows={this.state.purchaseReturnData}
                        columns={columns}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        getRowId={(row) => row['sno']}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        rowCount={this.state.rowCount}
                        onPageChange={(newPage) => { this.setState({ page: newPage }, () => this.getPurchaseReturnData()) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize }, () => this.getPurchaseReturnData())}
                        rowsPerPageOptions={[10, 20, 30]}
                        headerHeight={40}
                        disableSelectionOnClick
                        pagination
                        paginationMode='server'
                        loading={this.state.isLoader}
                    />
                </Box>

                <Drawer
                    anchor={'right'}
                    open={this.state.filterOpen}
                    ModalProps={{ onBackdropClick: this.ClosePopUp.bind(this) }}
                >
                    <PharmaFilter pageName={this.state.pageName} filterDatas={this.state.filterDatas} closeFilter={this.ClosePopUp.bind(this)} filteredData={this.renderFilter.bind(this)} ClearData={this.clearFilter.bind(this)} />
                </Drawer>

                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}

export default withTranslation()(PurchaseReturn)