import {
  Box, Button, Chip, Divider, Grid, Tab, Table,
  TableBody, TableCell, TableContainer, TableHead,
  TableRow, Tabs, TextField, Tooltip, Typography,
  Modal, Paper, IconButton, OutlinedInput, FormControl, InputLabel, Select, MenuItem, Checkbox,
  FormControlLabel, Radio, Autocomplete, Stack, Dialog
} from '@mui/material'
import React, { Component } from 'react'
import { ImagePaths } from '../../../../Utility/ImagePaths';
import './IpPatientDetails.css';
import { DateTimePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { AmountFormat, CheckAccessFunc, formatDate } from '../../../../Components/CommonFunctions/CommonFunctions'
import { withTranslation } from 'react-i18next';
import CreditBillPopUp from '../../BillingTransaction/CreditBillPopUp';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { BillSuccessPoPUp, CommonPatientCard, CommonPatientDetails, DeletePopup, ReasonPopupWithRefund } from '../../../../Components/Common Components/CommonComponents';
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation';
import { DateTime } from 'luxon';
import SearchIcon from '@mui/icons-material/Search';
import { clearCachevalue, getCachevalue, localGetItem } from '../../../../Utility/Services/CacheProviderService';
import { Colors } from '../../../../Styles/Colors';
import InputAdornment from '@mui/material/InputAdornment';
import Loader from '../../../../Components/Loader';
import moment from 'moment';
import { AddCircle } from '@material-ui/icons';
// import { CurrencyRupee } from '@mui/icons-material';
import PercentIcon from '@mui/icons-material/Percent';
import CircularProgress from '@mui/material/CircularProgress';
import { off } from 'process';
import { CurrencySymbol } from '../../../../Utility/Constants';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';


const Unit_Types = [
  { label: `${CurrencySymbol}`, value: 'rupee' },
  { label: '%', value: 'percentage' }
]

class ServiceBilling extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: JSON.parse(localGetItem('op_ip_service_code')) ? "three" : 'one',
      serviceTemplateData: [],
      serviceDescriptionData: [],
      serviceSearch: "",
      descriptionSerach: "",
      templateDate: new Date(),
      descripDate: new Date(),
      roomDate: new Date(),
      DateTimeChanged: false,
      wardName: "",
      serviceBilling: true,
      service: "",
      description: "",
      serviceAmount: "",
      descriptionAmount: "",
      serviceData: [],
      descriptionData: [],
      serviceList: [],
      descriptionList: [],
      billingList: [],
      totalServiceAmount: 0,
      isEditunit: false,
      isEditDiscount: false,
      isEditRate: false,
      totalDiscountedAmount: "",
      totalDiscountedPercentage: "",
      totalPayableAmount: 0,
      roundOff: "",
      data: {},
      service_amount: "",
      balance_amount: "",
      paid_amount: "",
      advance_amount: 0,
      attender_mobile: "",
      attender_name: "",
      noDiscountAmount: 0,
      ipPatientData: {},
      newService: [],
      newDescription: [],
      newDescriptionName: "",
      serviceListStringyfy: [],
      temp: [],
      templateId: "",
      textDiscountedAmount: 0,
      IpSavedBill: false,
      ipBillList: [],
      invoice_id: null,
      withOutAppointment: {},
      isButtonDisabled: false,
      SuccessInvoice_ID: null,
      bill_receipt_id: null,
      isDeleteCliked: false,
      deleteIndex: null,
      deleteService: "",
      isDeleteAll: false,
      serviceTemplateDataNew: "[]",
      serviceDescriptionDataNew: "[]",
      deletedIds: [],
      ApproxAmount: 0,
      Dateopen: false,
      ForCancel: false,
      cancelBillClicked: false,
      selectedRefundMode: '',
      upi_ref_num: '',
      selectedBillMode: 'cancel_bill',
      cancelReceipt: true,
      BillRefundData: {},
      AmountNet: 0,
      per_access: '',
      removable_ids: [],
      selectedWard: "",
      selectedWardId: "",
      selectedWardList: {},
      WardList: [],
      Timepopup: false,
      selectedService: {},
      ChangedUnit: 0,
      hourField: null,
      minuteField: null,
      endTimeOpen: false,
      startTimeOpen: false,
      selectedIndex: null,
      discountReasonPopup: false,
      discountReason: '',
      serviceDiscountpopup: false,
      discountHospitalFee: '',
      discountDoctorFee: '',
      selectedType: 'rupee',
      discountIndex: null,
      dupliSelectedData: '{}',
      selectedData: null,
      discountPercentDoctorFee: '',
      discountPercentHospitalFee: '',
      wardName: '',
      serviceCategory: '',
      MinimumAmount: null,
      timeFormate: false,
      OutstandingAmount: 0,
      CancelledNetAmount: 0,
      CancelledBillNumber: "",
      dupNewDescription: '[]',
      searchServiceName: '',
      minimumTime: null,
      isForEditBill: false,
      savedDiscountReason: '',
      advCheck: false,
      IpCompleteBill: false,
      isCurrentIp: false,
      totalDeductedAmount: 0,
      BillReceivedAmount: 0,
      paymentMode: null,
      docSplitPopup: false,
      disableSplitSharePopup: localGetItem("allow_doctor_fees_split_up") === "false" ? true : false,
      isDiscountBlock: false,
      IndivDiscountComments: "",
      consultDocName: "",
      consultAmount: "",
      creditChange: false,
      paymentCreditType: 'patientcredit',
      insuranceData: [],
      corporateData: [],
      disable: false,
      ApprovedAmt: 0,
      concessionRestrictAmt: 0,
      billConcessionType: '',
      DisableBtn: false,

      // serviceCode
      serviceCodeSearch: '',
      serviceCodeDataList: [],
      dupServiceCodeDataList: '[]',
      frequentServiceCodeList: [],
      selectedServiceCode: null,
      serviceCodeText: '',
      serviceNameCodeText: '',
      serviceTypeCodeText: '',
      serviceCodeAmt: 0,
      selectedServiceCodeWard: null,
      serviceCodeData: null,
      selectedServiceCodeText: '',
      selectedServiceNameCodeText: '',
      selectedServiceTypeCodeText: '',
      serviceCodeList: [],
      showCode: false,
      selectedPackageCodeText: '',
      packageCodeText: '',
      selectedServiceTempId: null,
      serviceCodeList2: []
    }
  }

  componentDidMount() {
    let concession = localGetItem('billing_concession_type')
    let showCode = JSON.parse(localGetItem('op_ip_service_code')) ? JSON.parse(localGetItem('op_ip_service_code')) : false
    this.setState({
      billConcessionType: concession,
      showCode: showCode
    })
    let CancelBilldatafromLocal = getCachevalue("IPCancelBilldata") ? getCachevalue("IPCancelBilldata") : "{}";
    let CancelBillData = JSON.parse(CancelBilldatafromLocal)
    if (this.props.history?.location?.state?.AppointmentID !== this.state.ipPatientData?.appointment_id && this.props.history?.location?.state?.AppointmentID !== "") {
      this.setState({
        invoice_id: this.props.history?.location?.state?.InvoiceID,
        appointment_id: this.props.history?.location?.state?.AppointmentID,
        ForCancel: (this.props.history?.location?.state?.ForCancel && this.props.history?.location?.state?.isForEditBill !== true),
        AmountNet: this.props.history?.location?.state?.AmountNet,
        isForEditBill: this.props.history?.location?.state?.isForEditBill ? true : false
      }, () => {
        this.getBillDatas(this.state.invoice_id, this.state.appointment_id)
        this.props.history.push({ state: { AppointmentID: "", InvoiceID: "", isCancelBillFetched: this.props.history?.location?.state?.ForCancel ? true : false, FromBills: this.props.history?.location?.state?.FromBills } })
      })
    }
    else if (CancelBillData?.AppointmentID !== this.state.ipPatientData?.appointment_id && CancelBillData?.AppointmentID !== "") {
      this.setState({
        invoice_id: CancelBillData?.InvoiceID,
        appointment_id: CancelBillData?.AppointmentID,
        ForCancel: (CancelBillData?.ForCancel && CancelBillData?.isForEditBill !== true),
        AmountNet: CancelBillData?.AmountNet,
        isForEditBill: CancelBillData?.isForEditBill ? true : false
      }, () => {
        this.getBillDatas(this.state.invoice_id, this.state.appointment_id)
        this.props.history.push({ state: { AppointmentID: "", InvoiceID: "", isCancelBillFetched: CancelBillData?.ForCancel ? true : false, FromBills: CancelBillData?.FromBills } })
      })
    }
    else {
      this.props.history.push({ state: { AppointmentID: "", InvoiceID: "", isCancelBillFetched: false } })
    }
    var Permission = JSON.parse(localGetItem("loggedInUserInfo"))
    this.setState({ per_access: Permission.permission_access })
    if (!this.props.history?.location?.state?.ForCancel || this.props.history?.location?.state?.isForEditBill) {
      this.getServiceTemplateData()
      this.getServiceDescriptionData()
    }

    let ipPatientData = getCachevalue("IppatientData")
    let patientData = JSON.parse(ipPatientData)
    let appointmentDetail = getCachevalue("withoutAppointment");
    let details = JSON.parse(appointmentDetail);
    if (details?.patient_id === patientData?.patient_id) {
      this.setState({
        withOutAppointment: details
      }, () => this.getPatientData())
    }
    this.setState({
      ipPatientData: patientData,
      paymentCreditType: patientData?.primary_payment_type === "Patient credit" ? 'patientcredit' :
        patientData?.primary_payment_type === "Corprate credit" ? 'corporatecredit' :
          patientData?.primary_payment_type === "Insurance credit" ? 'insurancecredit' : 'patientcredit',
    }, () => {
      this.setState({
        isButtonDisabled: this.state.ipPatientData?.is_disable_bill ? this.state.ipPatientData?.is_disable_bill : false
      })
      this.getPatientData()
      this.getApproxCharges()
      if (this.state.ipPatientData?.id && !this.state.ForCancel && !this.state.isForEditBill) { this.getAutoBillServices(this.state.ipPatientData?.id) }
    })

    setInterval(() => { // DateTime refresh on every minute, when the user not change the datetime
      if (!this.state.DateTimeChanged) {
        const newMinute = new Date().getMinutes();
        if (this.state.templateDate?.getMinutes() && this.state.templateDate?.getMinutes() !== newMinute) {
          this.setState({
            templateDate: new Date(),
          });
        }
        if (this.state.descripDate?.getMinutes() && this.state.descripDate?.getMinutes() !== newMinute) {
          this.setState({
            descripDate: new Date(),
          });
        }
      }
    }, 1000);
    this.getIpServiceCodeData()
  }

  getIpServiceCodeData = () => {
    try {
      RestAPIService.getAll(Serviceurls.IP_SERVICE_CODE_LIST)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              serviceCodeDataList: response.data.data,
              dupServiceCodeDataList: JSON.stringify(response.data.data),
              frequentServiceCodeList: response.data.frequent_service_codes
            })
          }
        }).catch(e => {
          if (e?.response?.data?.status === 'fail') {
            this.successErrorMessgae(e.response.data.message, 'error')
          } else {
            this.successErrorMessgae(e.message, 'error')
          }
        })
    } catch (error) {
      this.successErrorMessgae(error.message, 'error')
    }
  }

  getBillDatas = (InvoiceId, AdmissionId) => {
    try {
      this.setState({ isBillServicesLoading: true })
      RestAPIService.getAll(Serviceurls.IP_RECEIVE_PAYMENT_GET + `?ip_bill_summary_id=${InvoiceId}&ip_admission_id=${AdmissionId}`)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              ipBillList: response.data.data[0]?.service_description,
            }, () => {
              this.state.ipBillList.forEach(element => {
                element.discountPercentage = element.discount_percentage ? element.discount_percentage : ""
                element.discountAmount = element.discount_amount ? element.discount_amount : 0
                element.amount = ((this.state.ForCancel || this.state.isForEditBill) && !element.service_rate_per_unit) ? element.total_amount : element.service_rate_per_unit
                element.old_time_taken = element.service_old_units ? element.service_old_units : null
                // element.totalAmount = element.service_units?.split(" ")[1] == "hrs" ? this.CalculateHourAmount(element.service_rate_per_unit, element.service_units?.split(" ")[0], element.service_old_units,element?.minimum_time,+element?.minimum_amount) : +element.service_rate_per_unit * element.service_units
                // element.unit = element.service_units?.split(" ")[1] == "hrs" ? element.service_units?.split(" ")[1] : +element.service_units
                // element.time_taken = element.service_units?.split(" ")[1] == "hrs" ? element.service_units?.split(" ")[0] : null
                element.totalAmount = ((this.state.ForCancel || this.state.isForEditBill) && !element.service_rate_per_unit) ? element.total_amount : element.service_units?.split(" ")[1] == "hrs" ? this.CalculateHourAmount(element.service_rate_per_unit, element.service_units?.split(" ")[0], element.service_old_units, element?.minimum_time, +element?.minimum_amount) : +element.service_rate_per_unit * element.service_units
                element.unit = ((this.state.ForCancel || this.state.isForEditBill) && !element.service_rate_per_unit) ? 1 : element.service_units?.split(" ")[1] == "hrs" ? element.service_units?.split(" ")[1] : +element.service_units
                element.time_taken = ((this.state.ForCancel || this.state.isForEditBill) && !element.service_rate_per_unit) ? null : element.service_units?.split(" ")[1] == "hrs" ? element.service_units?.split(" ")[0] : null
                element.noDiscountAmount = element.total_amount ? element.total_amount : 0
                element.template = this.state.service
                element.templateId = element.ip_template_id ? element.ip_template_id : null
                element.is_template = true
                element.doctor_percentage = element.doctor_percentage ? element.doctor_percentage : 0
                element.hospital_percentage = element.hospital_percentage ? element.hospital_percentage : 0
                element.doctor_fee = element.new_doctor_fee
                element.hospital_fee = element.new_hospital_fee
                element.discount_doctor_fee = (element.old_doctor_fee - element.new_doctor_fee).toFixed(2)
                element.discount_hospital_fee = (element.old_hospital_fee - element.new_hospital_fee).toFixed(2)
                element.discount_doctor_percentage = CommonValidation.amountPercentage(element.discount_doctor_fee, element.old_doctor_fee)
                element.discount_hospital_percentage = CommonValidation.amountPercentage(element.discount_hospital_fee, element.old_hospital_fee)
                element.is_auto_billing = element.is_auto_billing
                element.start_date = element.procedure_start_time ? element.procedure_start_time : moment(this.state.ipPatientData?.admission_date)
                // element.start_date = (element.procedure_start_time && element.service_units?.split(" ")[1] == "hrs") ? element.procedure_start_time : (moment(this.state.ipPatientData?.admission_date) && element.service_units?.split(" ")[1] == "hrs") ? moment(this.state.ipPatientData?.admission_date) : null
                // element.end_date = element.procedure_end_time
                element.min_amount = element.minimum_amount ? element.minimum_amount : 0
                element.individual_discount_comments = element.individual_discount_comments ? element.individual_discount_comments : ""
                element.isSplitBlock = false
                element.other_consultant_name = element?.other_consultant_name ? element?.other_consultant_name : null
                if ((element?.service_category__service_category === "Doctor Consultation Charges") || (element?.service_category__service_category === "Surgery Charges") || (element?.service_category__service_category === "Anasthesia Charges")) {
                  element.ip_service = {
                    "total_amt": element.old_doctor_fee + element.old_hospital_fee,
                    "doctor_fee": element.new_doctor_fee,
                    "amount_type": element.amount_type,
                    "hospital_fee": element.new_hospital_fee,
                    "doctor_percentage": element.doctor_percentage ? element.doctor_percentage : 0,
                    "hospital_percentage": element.hospital_percentage ? element.hospital_percentage : 0,
                    "discount_doctor_fee": Math.round(element.old_doctor_fee - element.new_doctor_fee),
                    "discount_hospital_fee": Math.round(element.old_hospital_fee - element.new_hospital_fee),
                    "original_doctor_fee": element.old_doctor_fee,
                    "original_hospital_fee": element.old_hospital_fee,
                    "discount_doctor_percentage": CommonValidation.amountPercentage(element.discount_doctor_fee, element.old_doctor_fee),
                    "discount_hospital_percentage": CommonValidation.amountPercentage(element.discount_hospital_fee, element.old_hospital_fee),
                  }
                }
                this.state.billingList.push(element)
              })
              let paymentModeValue = null;
              if (response.data.data[0]?.payment_mode?.length === 1) {
                if ((response.data.data[0]?.payment_mode[0] === 1) || (response.data.data[0]?.payment_mode[0] === 3) || (response.data.data[0]?.payment_mode[0] === 5)) {
                  paymentModeValue = response.data.data[0]?.payment_mode[0];
                }
              }
              this.setState({
                billingList: this.state.billingList,
                discountReason: response.data.data[0]?.remarks,
                // OutstandingAmount: response.data.data[0]?.ip_outstanding_amount ? response.data.data[0]?.ip_outstanding_amount : 0,
                BillReceivedAmount: response.data.data[0]?.received_amount ? response.data.data[0]?.received_amount : 0,
                totalDiscountedAmount: response.data.data[0]?.discount_amount,
                totalDiscountedPercentage: response.data.data[0]?.discount_percentage,
                templateDate: (this.state.ForCancel || this.state.isForEditBill) ? response.data.data[0]?.invoice_date : this.state.templateDate,
                descripDate: (this.state.ForCancel || this.state.isForEditBill) ? response.data.data[0]?.invoice_date : this.state.templateDate,
                DateTimeChanged: (this.state.ForCancel || this.state.isForEditBill) ? true : false,
                CancelledBillNumber: response.data.data[0]?.bill_no ? response.data.data[0]?.bill_no : "",
                savedDiscountReason: response.data.data[0]?.remarks,
                isCurrentIp: response.data.data[0]?.is_current_ip ? response.data.data[0]?.is_current_ip : false,
                paymentMode: paymentModeValue,
              }, () => {
                // this.calculateTotalDiscount()
                this.setState({ isBillServicesLoading: false })
              })
            })
          }
        }).catch((error) => {
          this.setState({ isBillServicesLoading: false })
          if (error?.response?.data?.status) {
            this.successErrorMessgae(error.response.data.message, 'error')
          } else {
            this.successErrorMessgae(error.message, 'error')
          }
        })
    } catch (error) {
      this.setState({ isBillServicesLoading: false })
      this.successErrorMessgae(error.message, 'error')
    }
  }


  getAutoBillServices = (AdmissionId = null) => {
    try {
      RestAPIService.getAll(Serviceurls.IP_AUTOBILLING_GET + "?admission_id=" + AdmissionId)
        .then((response) => {
          if (response.data.status === "success") {
            let BillList = response.data.data;

            BillList.forEach(element => {
              let TotalAmt = 0
              var MinAmt = 0
              let MinTime = 0
              element?.consultant_charge.forEach(list => {
                if (element?.consultant_charge.length == 1) {
                  TotalAmt = list?.service_amount ? list?.service_amount : list.total_amount
                  MinAmt = list?.minimum_amount ? list?.minimum_amount : 0
                  MinTime = list?.minimum_time ? list?.minimum_time : '00:00'
                } else if (element?.consultant_charge.length > 1) {
                  if (this.state.ipPatientData?.ward_name === list.ward_name) {
                    TotalAmt = list?.service_amount ? list?.service_amount : list.total_amount
                    MinAmt = list?.element?.min_service_amount ? list?.element?.min_service_amount : 0
                    MinTime = list?.element?.minimum_time ? list?.element?.minimum_time : '00:00'
                  }
                }
              })


              element.discountPercentage = element.discount_percentage ? element.discount_percentage : ""
              element.discountAmount = element.discount_amount ? element.discount_amount : 0
              element.amount = +TotalAmt
              element.totalAmount = element.time_taken ? this.CalculateHourAmount(element.amount, element.time_taken, element.time_taken, MinTime, +MinAmt) : +TotalAmt
              element.unit = "1"
              element.noDiscountAmount = element.total_amount ? element.total_amount : 0
              element.is_edit_discount = false
              element.is_edit_amount = false
              element.is_edit_unit = false
              element.is_auto_billing = true
              element.min_amount = +MinAmt
              element.minimum_time = MinTime
              element.isSplitBlock = false
              element.isDiscountBlock = false
              this.state.billingList.push(element)
            })
            this.setState({
              billingList: this.state.billingList,
            }, () => {
              // this.calculateTotalDiscount()
              this.setState({
                totalDiscountedAmount: '',
                totalDiscountedPercentage: ''
              })
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.status === "fail") {
            this.successErrorMessgae(error.response.data.message, 'error')
          } else {
            this.successErrorMessgae(error.message, 'error')
          }
        })
    } catch (error) {
      this.successErrorMessgae(error.message, 'error')
    }
  }

  getApproxCharges = () => {
    let AdmissionId = this.state.ipPatientData?.id ? this.state.ipPatientData?.id : this.state.withOutAppointment?.ip_admission_id ? this.state.withOutAppointment?.ip_admission_id : this.state.appointment_id
    try {
      RestAPIService.getAll(Serviceurls.IP_APPROX_AMOUNT + "?ip_admission_id=" + AdmissionId)
        .then((response) => {
          if (response.data.status === "success") {
            this.setState({
              ApproxAmount: response.data.data?.approx_room_charge
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.status === "fail") {
            this.successErrorMessgae(error.response.data.message, 'error')
          } else {
            this.successErrorMessgae(error.message, 'error')
          }
        })
    } catch (error) {
      this.successErrorMessgae(error.message, 'error')
    }
  }
  getInsuranceDetails = () => {
    try {
      RestAPIService.getAll(Serviceurls.FO_INSURANCE_LIST)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              insuranceData: response.data.data,
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.status) {
            this.successErrorMessgae(error.response.data.message, 'error')
          } else {
            this.successErrorMessgae(error.message, 'error')
          }
        })
    } catch (error) {
      this.successErrorMessgae(error.message, 'error')
    }
  }
  getCorporateDetails = () => {
    try {
      RestAPIService.getAll(Serviceurls.FO_CONFIG_CORPORATE)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              corporateData: response.data.data,
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.status) {
            this.successErrorMessgae(error.response.data.message, 'error')
          } else {
            this.successErrorMessgae(error.message, 'error')
          }
        })
    } catch (error) {
      this.successErrorMessgae(error.message, 'error')
    }
  }


  successErrorMessgae = (message, type) => {
    if (type === 'error') {
      this.setState({
        isErrorMsg: true,
        isErrorMsgText: message,
        DisableBtn: false
      })
    } else {
      this.setState({
        successMsg: true,
        successMsgText: message,
        DisableBtn: false
      })
    }
  }
  concessionManditoryPopup = () => {
    if (this.state.names === "totalDiscountedPercentage" || "totalDiscountedAmount") {
      this.setState({
        discountReasonPopup: true
      })
    }
  }

  concessionManditoryPopup = (names) => {
    if ((this.state.totalDiscountedPercentage || this.state.totalDiscountedAmount) && (this.state.discountReason === "")) {
      if (names === 'totalDiscountedPercentage' || names === 'totalDiscountedAmount') {
        this.setState({
          discountReasonPopup: true
        })
      }
    }
  }

  inputTextBox = (names, value, key, placeholder, height, diasble) => {
    let search = names === "serviceSearch" || names === "descriptionSerach"
    return (
      <TextField

        // onKeyDown={(e) => {
        //   if (e.key === "Enter") {
        //     if (names === "serviceSearch") {
        //       this.getServiceTemplateData()
        //     }
        //     if (names === "descriptionSerach") {
        //       this.getServiceDescriptionData()
        //     }
        //   }
        // }}
        // InputProps={{
        //   startAdornment: names === "totalDiscountedAmount" ? <InputAdornment position="start">₹</InputAdornment> : null,
        //   endAdornment: names === "totalDiscountedPercentage" ? <InputAdornment position="start">%</InputAdornment> : 
        //   names === "serviceSearch" || names === "descriptionSerach" ? <InputAdornment 
        //   onClick={() => { names === "serviceSearch" ? this.getServiceTemplateData() : this.getServiceDescriptionData()  }} 
        //   id='eMed_Serivce_Search' position="end"><SearchIcon sx={{color: '#04B7B1'}}/></InputAdornment> : null,
        // }}

        autoComplete='off'
        size='small'
        className={search ? 'eMed_bill_search' : 'eMed_discount_txt'}
        placeholder={placeholder}
        disabled={(diasble || this.state.ForCancel)}
        inputProps={{ style: { height: height, fontSize: '0.8vw' } }}
        onBlur={(e) => { this.concessionManditoryPopup(names) }}
        name={names}
        value={value}
        onChange={(e) => { this.onChangeTableHandler(e, key) }}
      />
    )
  }

  calculateTotalDiscount = () => {
    this.state.totalDiscountedAmount = CommonValidation.calculatePercentage(this.state.totalServiceAmount, this.state.totalDiscountedPercentage)
    this.state.totalDiscountedPercentage = CommonValidation.amountPercentage(this.state.totalDiscountedAmount, this.state.totalServiceAmount)
    this.setState({
      totalDiscountedAmount: this.state.totalDiscountedAmount,
      totalDiscountedPercentage: this.state.totalDiscountedPercentage
    })
  }

  onChangeTableHandler = (e, key) => {
    const { t } = this.props
    try {
      const { name, value } = e.target
      let { billingList } = this.state
      switch (name) {
        case 'rateUnit':
          if ((CommonValidation.NumberWithDot(value) && value >= 1) || value === "") {
            if (((billingList[key]?.service_category__service_category === "Doctor Consultation Charges") || (billingList[key]?.service_category__service_category === "Surgery Charges") || (billingList[key]?.service_category__service_category === "Anasthesia Charges"))) {
              billingList[key]['totalAmount'] = billingList[key]["time_taken"] ? this.CalculateHourAmount(value, billingList[key]['time_taken'], (billingList[key]["old_time_taken"] ? billingList[key]["old_time_taken"] : billingList[key]['time_taken']), billingList[key]['minimum_time'], billingList[key]['min_amount']) : (billingList[key]['unit'] * value)
              billingList[key]['amount'] = +value
              billingList[key]['ip_service']['doctor_fee'] = CommonValidation.calculatePercentage(billingList[key]['ip_service']['doctor_percentage'], (value * billingList[key]['unit']))
              billingList[key]['ip_service']['hospital_fee'] = CommonValidation.calculatePercentage(billingList[key]['ip_service']['hospital_percentage'], (value * billingList[key]['unit']))
              billingList[key]['ip_service']['total_amount'] = billingList[key]['unit'] * +value
              billingList[key]['ip_service']['discount_hospital_fee'] = billingList[key]['ip_service']['discount_hospital_percentage'] ? CommonValidation.calculatePercentage(billingList[key]['ip_service']['discount_hospital_percentage'], billingList[key]['ip_service']['hospital_fee']) : 0;
              billingList[key]['ip_service']['discount_doctor_fee'] = billingList[key]['ip_service']['discount_doctor_percentage'] ? CommonValidation.calculatePercentage(billingList[key]['ip_service']['discount_doctor_percentage'], billingList[key]['ip_service']['doctor_fee']) : 0;
              billingList[key]['discountAmount'] = billingList[key]['ip_service']['discount_doctor_fee'] + billingList[key]['ip_service']['discount_hospital_fee']
              billingList[key]['discountPercentage'] = CommonValidation.amountPercentage(billingList[key]['discountAmount'], billingList[key]['ip_service']['total_amount'])

            } else {
              billingList[key]['totalAmount'] = billingList[key]["time_taken"] ? this.CalculateHourAmount(value, billingList[key]['time_taken'], (billingList[key]["old_time_taken"] ? billingList[key]["old_time_taken"] : billingList[key]['time_taken']), billingList[key]['minimum_time'], billingList[key]['min_amount']) : (billingList[key]['unit'] * value)
              billingList[key]['discountAmount'] = CommonValidation.calculatePercentage(billingList[key]['totalAmount'], billingList[key]['discountPercentage'])
              billingList[key]['discountPercentage'] = CommonValidation.amountPercentage(billingList[key]['discountAmount'], billingList[key]['totalAmount'])
              billingList[key]['amount'] = value
            }
            this.setState({ billingList }, () => {
              // this.calculateTotalDiscount()
              this.setState({
                totalDiscountedAmount: '',
                totalDiscountedPercentage: ''
              })
            })
          }
          break;
        case 'percentage':
          if ((CommonValidation.DecimalNumber(value) && value >= 1 && +value <= 100) || value === "") {
            billingList[key]['discountAmount'] = CommonValidation.calculatePercentage(value, billingList[key]['totalAmount'])
            billingList[key]['discountPercentage'] = value
            this.setState({ billingList }, () => {
              // this.calculateTotalDiscount()
              this.setState({
                totalDiscountedAmount: '',
                totalDiscountedPercentage: ''
              })
            })
          }

          break;
        case 'discountAmount':
          let totalAmount = billingList[key]['totalAmount']
          if (CommonValidation.NumberWithDot(value) && value >= 1 && value <= totalAmount || value === "") {
            billingList[key]['discountPercentage'] = CommonValidation.amountPercentage(value, billingList[key]['totalAmount'])
            billingList[key]['discountAmount'] = value
            this.setState({ billingList }, () => {
              // this.calculateTotalDiscount()
              this.setState({
                totalDiscountedAmount: '',
                totalDiscountedPercentage: ''
              })
            })
          }
          break;
        case 'totalDiscountedPercentage':
          let percentRestrict = +this.state.billConcessionType === 1 ? 100 : CommonValidation.amountPercentage(this.state.concessionRestrictAmt, this.state.totalServiceAmount)
          if ((CommonValidation.DecimalNumber(value) && value > 0 && +value <= percentRestrict) || value === "") {
            this.state.totalDiscountedAmount = CommonValidation.calculatePercentage(value, this.state.totalServiceAmount)
            this.setState({
              totalDiscountedPercentage: value
            })
          }
          break;
        case 'totalDiscountedAmount':
          if (CommonValidation.NumberWithDot(value) && value > 0 && value <= this.state.concessionRestrictAmt || value === "") {
            this.state.totalDiscountedPercentage = CommonValidation.amountPercentage(value, this.state.totalServiceAmount)
            this.setState({
              totalDiscountedAmount: value
            })
          }
          break;
        case "serviceSearch":
          this.setState({
            serviceSearch: value
          }, () => {
            if (this.state.serviceSearch === "") {
              // this.getServiceTemplateData()
              this.setState({
                service: "",
                serviceTemplateData: JSON.parse(this.state.serviceTemplateDataNew)
              })
            } else {
              // this.getServiceTemplateData()
              this.setState({
                service: "",
                serviceTemplateData: JSON.parse(this.state.serviceTemplateDataNew)?.filter((list) => (list?.package_name?.toLowerCase()?.includes(this.state.serviceSearch?.toLowerCase())))
              })
            }
          })
          break;
        case "descriptionSerach":
          this.setState({
            descriptionSerach: value
          }, () => {
            // if (this.state.descriptionSerach === "") {
            //   // this.getServiceDescriptionData()
            //   this.setState({
            //     newDescriptionName: "",
            //     description: "",
            //     newDescription: [],
            //     dupNewDescription: '[]',
            //     searchServiceName: '',
            //   })
            // } else {
            //   // this.getServiceDescriptionData()
            // }
            if (this.state.descriptionSerach === "") {
              // this.getServiceTemplateData()
              this.setState({
                newDescriptionName: "",
                description: "",
                newDescription: [],
                dupNewDescription: '[]',
                searchServiceName: '',
                serviceDescriptionData: JSON.parse(this.state.serviceDescriptionDataNew)
              })
            } else {
              // this.getServiceTemplateData()
              this.setState({
                newDescriptionName: "",
                description: "",
                newDescription: [],
                dupNewDescription: '[]',
                searchServiceName: '',
                serviceDescriptionData: JSON.parse(this.state.serviceDescriptionDataNew)?.filter((list) => (list?.service_type?.toLowerCase()?.includes(this.state.descriptionSerach?.toLowerCase())))
              })
            }
          })
          break;
        default:
          this.setState({
            [name]: value
          })
          break;
      }
    } catch (error) {
      this.successErrorMessgae(error.message, 'error')
    }
  }

  CalculateServiceValues = (index) => {
    try {
      let { billingList } = this.state
      billingList[index].totalAmount = Math.round(billingList[index].unit * billingList[index].amount)
      billingList[index].ip_service.total_amt = Math.round(billingList[index].unit * billingList[index].amount)
      billingList[index].ip_service.original_doctor_fee = CommonValidation.calculatePercentage(billingList[index]?.ip_service?.doctor_percentage, billingList[index]?.ip_service?.total_amt)
      billingList[index].ip_service.original_hospital_fee = CommonValidation.calculatePercentage(billingList[index]?.ip_service?.hospital_percentage, billingList[index]?.ip_service?.total_amt)
      billingList[index].ip_service.discount_hospital_fee = billingList[index].ip_service?.discount_hospital_percentage ? CommonValidation.calculatePercentage(billingList[index].ip_service?.discount_hospital_percentage, billingList[index].ip_service.original_hospital_fee) : 0;
      billingList[index].ip_service.discount_doctor_fee = billingList[index].ip_service?.discount_doctor_percentage ? CommonValidation.calculatePercentage(billingList[index].ip_service?.discount_doctor_percentage, billingList[index].ip_service.original_doctor_fee) : 0;
      billingList[index].discountAmount = billingList[index]?.ip_service?.discount_doctor_fee + billingList[index]?.ip_service?.discount_hospital_fee
      billingList[index].discountPercentage = CommonValidation.amountPercentage(billingList[index]?.discountAmount, billingList[index]?.ip_service?.total_amt)
      billingList[index].ip_service.doctor_fee = CommonValidation.formatAmount(billingList[index].ip_service.original_doctor_fee - (billingList[index].ip_service.discount_doctor_fee ? billingList[index].ip_service.discount_doctor_fee : 0))
      billingList[index].ip_service.hospital_fee = CommonValidation.formatAmount(billingList[index].ip_service.original_hospital_fee - (billingList[index].ip_service.discount_hospital_fee ? billingList[index].ip_service.discount_hospital_fee : 0))
      billingList[index].discountAmount = billingList[index]?.ip_service?.discount_doctor_fee + billingList[index]?.ip_service?.discount_hospital_fee
      billingList[index].discountPercentage = CommonValidation.amountPercentage(billingList[index]?.discountAmount, billingList[index]?.ip_service?.total_amt)
      this.setState({ billingList }, () => {
        // this.calculateTotalDiscount()
        this.setState({
          totalDiscountedAmount: '',
          totalDiscountedPercentage: ''
        })
      })
    } catch (error) {
      this.successErrorMessgae(error.message, 'error')
    }
  }

  onChangeUnit = (item, type, index) => {
    try {
      let { billingList } = this.state
      switch (type) {
        case 'increase':
          if (item.unit >= 1) {
            item.unit++
            item.totalAmount = item.unit * item.amount
            if (((item?.service_category__service_category === "Doctor Consultation Charges") || (item?.service_category__service_category === "Surgery Charges") || (item?.service_category__service_category === "Anasthesia Charges")) && (item?.ip_service?.doctor_fee || item?.ip_service?.hospital_fee || item?.ip_service?.discount_doctor_percentage || item?.ip_service?.discount_hospital_percentage)) {
              this.setState({ billingList }, () => {
                this.CalculateServiceValues(index)
              })

            } else {
              item.discountAmount = CommonValidation.calculatePercentage(item.totalAmount, item.discountPercentage)
              item.discountPercentage = CommonValidation.amountPercentage(item.discountAmount, item.totalAmount)
              this.setState({ billingList }, () => {
                // this.calculateTotalDiscount()
                this.setState({
                  totalDiscountedAmount: '',
                  totalDiscountedPercentage: ''
                })
              })
            }

          }
          break;
        case 'decrease':
          if (item.unit > 1) {
            item.unit--
            item.totalAmount = item.unit * item.amount
            if (((item?.service_category__service_category === "Doctor Consultation Charges") || (item?.service_category__service_category === "Surgery Charges") || (item?.service_category__service_category === "Anasthesia Charges")) && (item?.ip_service?.doctor_fee || item?.ip_service?.hospital_fee || item?.ip_service?.discount_doctor_percentage || item?.ip_service?.discount_hospital_percentage)) {
              this.setState({ billingList }, () => {
                this.CalculateServiceValues(index)
              })

            } else {
              item.discountAmount = CommonValidation.calculatePercentage(item.totalAmount, item.discountPercentage)
              item.discountPercentage = CommonValidation.amountPercentage(item.discountAmount, item.totalAmount)
              this.setState({ billingList }, () => {
                // this.calculateTotalDiscount()
                this.setState({
                  totalDiscountedAmount: '',
                  totalDiscountedPercentage: ''
                })
              })
            }
          }
          break;
        default:
          break;
      }
    } catch (error) {
      this.successErrorMessgae(error.message, 'error')
    }
  }

  ClosePopUp() {
    this.setState({
      show: false,
      SuccessInvoice_ID: null,
      bill_receipt_id: null,
    })
  }

  onClearHandler = () => {
    this.setState({
      serviceAmount: 0,
      descriptionAmount: 0,
      description: "",
      service: "",
      serviceSearch: "",
      descriptionSerach: "",
      newDescriptionName: "",
      newDescription: [],
      serviceList: [],
      descriptionList: [],
      serviceTemplateData: JSON.parse(this.state.serviceTemplateDataNew),
      serviceDescriptionData: JSON.parse(this.state.serviceDescriptionDataNew),
      MinimumAmount: null,
      searchServiceName: '',
      dupNewDescription: '[]',
      minimumTime: null,
      consultDocName: "",
      consultAmount: "",
      serviceCodeList: [],
      serviceCodeList2: [],
      selectedServiceCode: null,
      serviceCodeText: '',
      serviceNameCodeText: '',
      serviceTypeCodeText: '',
      serviceCodeAmt: 0,
      selectedServiceCodeWard: null,
      serviceCodeData: null,
      selectedServiceCodeText: '',
      selectedServiceNameCodeText: '',
      selectedServiceTypeCodeText: '',
      packageCodeText: '',
      selectedPackageCodeText: '',
      selectedServiceTempId: null
    })
  }

  onClearLeftContent = (DeleteAllDatas = true) => {
    this.state.billingList.forEach(element => {
      this.state.removable_ids.push(element.line_item_id)
    })
    this.setState({
      billingList: [],
      serviceList: [],
      totalServiceAmount: 0,
      totalDiscountedAmount: "",
      totalDiscountedPercentage: "",
      payableAmount: 0,
      descriptionList: [],
      newDescription: [],
      descriptionAmount: 0,
      serviceAmount: 0,
      isEditDiscount: false,
      isEditRate: false,
      isEditunit: false,
      isDeleteAll: false,
      serviceTemplateData: JSON.parse(this.state.serviceTemplateDataNew),
      serviceDescriptionData: JSON.parse(this.state.serviceDescriptionDataNew),
      removable_ids: this.state.removable_ids,
      description: '',
      newDescriptionName: '',
      service: '',
      discountReason: "",
      savedDiscountReason: "",
      concessionRestrictAmt: '',
      serviceCodeList: [],
      serviceCodeList2: [],
      selectedServiceCode: null,
      serviceCodeText: '',
      serviceNameCodeText: '',
      serviceTypeCodeText: '',
      serviceCodeAmt: 0,
      selectedServiceCodeWard: null,
      serviceCodeData: null,
      selectedServiceCodeText: '',
      selectedServiceNameCodeText: '',
      selectedServiceTypeCodeText: '',
      packageCodeText: '',
      selectedPackageCodeText: ''
    })
    if (DeleteAllDatas) {
      this.setState({
        OutstandingAmount: 0,
        CancelledNetAmount: 0,
        ForCancel: false,
        isForEditBill: false,
        templateDate: new Date(),
        descripDate: new Date(),
        DateTimeChanged: false,
        invoice_id: null,
        removable_ids: [],
        advCheck: false,
        IpCompleteBill: false,
        isCurrentIp: false,
        totalDeductedAmount: 0,
        BillReceivedAmount: 0,
        concessionRestrictAmt: ''
      })
    }
  }

  CrebillPosted(message, invoice_id, receipt_id = null) {
    this.onClearLeftContent()
    this.setState({
      show: false,
      value: this.state.showCode ? 'three' : 'one',
      SuccessInvoice_ID: invoice_id,
      bill_receipt_id: receipt_id,
      discountReason: '', removable_ids: [], savedDiscountReason: ''
    }, () => {
      this.successErrorMessgae(message, 'success')
      this.getPatientData()
      this.getServiceTemplateData()
      this.getServiceDescriptionData()
      this.getApproxCharges()
    })
  }

  onClickChipHandler = (item, fromAuto = false) => {
    let TotalAmt = 0
    let MinAmt = 0
    let MinTime = 0
    let WardName = []
    let selectedWard = ""
    let selectedWardId = ""
    let states = this.state
    let fromCode = item?.consultant_charge
    if (item?.consultant_charge && this.state.value == "two") {
      item?.consultant_charge.forEach(element => {
        if (item?.consultant_charge.length == 1) {
          TotalAmt = element?.service_amount ? element?.service_amount : element.total_amount
          MinAmt = item?.unit == "hrs" ? element?.minimum_amount ? element?.minimum_amount : null : null
          MinTime = item?.unit == "hrs" ? element?.minimum_time ? element?.minimum_time : null : null
        } else {
          if (states.ipPatientData?.ward_name === element.ward_name) {
            TotalAmt = element?.service_amount ? element?.service_amount : element.total_amount
            MinAmt = element?.min_service_amount ? element?.min_service_amount : null
            MinTime = element?.minimum_time ? element?.minimum_time : null
            selectedWard = element.ward_name
            selectedWardId = element.id
          }
          var dataObject = {
            id: element.id,
            ward: element.ward_name,
            amount: element?.service_amount ? element?.service_amount : element.total_amount,
            minimum_amount: element?.min_service_amount ? element?.min_service_amount : null,
            minimum_time: element?.minimum_time ? element?.minimum_time : null
          };
          WardName.push(dataObject)
        }
      })
    } else if (fromCode && this.state.value == "three") {
      fromCode.forEach(element => {
        if (fromCode?.length == 1) {
          TotalAmt = element?.service_amount ? element?.service_amount : element.total_amount
          MinAmt = item?.unit == "hrs" ? element?.minimum_amount ? element?.minimum_amount : null : null
          MinTime = item?.unit == "hrs" ? element?.minimum_time ? element?.minimum_time : null : null
        } else {
          if (states.ipPatientData?.ward_name === element.ward_name) {
            TotalAmt = element?.service_amount ? element?.service_amount : element.total_amount
            MinAmt = element?.min_service_amount ? element?.min_service_amount : null
            MinTime = element?.minimum_time ? element?.minimum_time : null
            selectedWard = element.ward_name
            selectedWardId = element.id
          }
          var dataObject = {
            id: element.id,
            ward: element.ward_name,
            amount: element?.service_amount ? element?.service_amount : element.total_amount,
            minimum_amount: element?.min_service_amount ? element?.min_service_amount : null,
            minimum_time: element?.minimum_time ? element?.minimum_time : null
          };
          WardName.push(dataObject)
        }
      })
    }
    if (fromAuto) {
      this.setState({
        serviceCodeText: '',
        serviceNameCodeText: '',
        serviceTypeCodeText: '',
        codeData: null,
        serviceCodeData: item,
        selectedServiceCodeText: item?.ip_service_code ? item?.ip_service_code : item?.package_service_code,
        selectedServiceNameCodeText: item?.service_name ? item?.service_name : '',
        selectedServiceTypeCodeText: item?.service_type ? item?.service_type : '',
        selectedPackageCodeText: item?.is_package ? item?.package_name : '',
        selectedWard: selectedWard,
        selectedWardId: selectedWardId,
        WardList: WardName,
        MinimumAmount: MinAmt ? MinAmt : null,
        minimumTime: MinTime ? MinTime : null,
        serviceCodeAmt: item?.total_amount ? item?.total_amount : item?.amount ? item?.amount : TotalAmt,
        selectedServiceTempId: item?.is_package ? item?.id : null
      })

    } else {
      this.setState({
        service: item?.package_name,
        serviceAmount: item?.total_amount ? item?.total_amount : null,
        serviceData: item?.service_data,
        descriptionAmount: item?.amount ? item?.amount : (+TotalAmt) ? (+TotalAmt) : 0,
        descriptionData: item,
        templateId: item?.id,
        newDescriptionName: item?.service_name,
        selectedWard: selectedWard,
        selectedWardId: selectedWardId,
        WardList: WardName,
        MinimumAmount: MinAmt ? MinAmt : null,
        minimumTime: MinTime ? MinTime : null,

        serviceCodeText: item?.ip_service_code ? item?.ip_service_code : item?.package_service_code,
        serviceNameCodeText: item?.service_name ? item?.service_name : '',
        serviceTypeCodeText: item?.service_type ? item?.service_type : '',
        serviceCodeData: null,
        serviceCodeAmt: item?.total_amount ? item?.total_amount : item?.amount ? item?.amount : TotalAmt,
        codeData: item,
        selectedServiceCodeText: '',
        selectedServiceNameCodeText: '',
        selectedServiceTypeCodeText: '',
        packageCodeText: item?.is_package ? item?.package_name : '',
        selectedServiceTempId: item?.is_package ? item?.id : null
      })
    }
  }

  onAddHandler = () => {
    let states = this.state
    try {
      if (this.state.value === 'one') {
        let templateServiceList = [];

        this.state.serviceTemplateData.forEach(element => {
          templateServiceList.push(element.service_data)
        })
        if (this.state.service !== "") {
          let templateFlag = false;
          this.state.billingList.forEach(element => {
            if (this.state.service === element.template_name) {
              templateFlag = true
            }
          })
          this.state.serviceData.forEach((element) => {
            if (((element.service_category__service_category === "Doctor Consultation Charges") || (element.service_category__service_category === "Surgery Charges") || (element.service_category__service_category === "Anasthesia Charges"))) {
              element.totalAmount = element.amount
            } else {
              element.min_amount = states?.MinimumAmount ? states?.MinimumAmount : 0
              element.minimum_time = states?.minimumTime ? states?.minimumTime : '00:00'
              element.start_date = moment(this.state.ipPatientData?.admission_date)
              element.end_date = null
              element.changed_start_time = null
              element.changed_end_time = null
              element.old_time_taken = element.time_taken
              element.totalAmount = element.unit == "hrs" ? this.CalculateHourAmount(element.amount, element.time_taken, element.old_time_taken, element.minimum_time, +element.min_amount) : element.amount
            }
            element.unit = "1"
            element.discountPercentage = ""
            element.discountAmount = ""
            element.template_name = this.state.service
            element.templateId = this.state.templateId
            element.is_template = true
            element.selected_Ward = this.state.selectedWard ? this.state.selectedWard : element.consultant_charge[0].ward_name
            element.is_auto_billing = false
            element.isSplitBlock = false
            element.ip_service = {
              'selected_ward_name': element.selected_ward ? element.selected_ward : '',
              'total_amount': element.totalAmount ? element.totalAmount : 0,
              'amount': element.amount ? element.amount : 0,
              'doctor_fee': element.doctor_share ? element.doctor_share : '',
              'hospital_fee': element.hospital_share ? element.hospital_share : '',
              'doctor_percentage': element.doctor_percent ? element.doctor_percent : 0,
              'hospital_percentage': element.hospital_percent ? element.hospital_percent : 0,
              'unit': element.unit ? element.unit : 0,
              'service_category': element.service_category__service_category ? element.service_category__service_category : '',
              'service_amount': element.service_amount ? element.service_amount : 0
            }
            if (!templateFlag) {
              this.state.serviceList.push(element)
            } else {
              this.successErrorMessgae("Service Template Already Exist", 'error')
              this.onClearHandler()
            }
          })
          this.state.billingList = this.state.billingList.concat(this.state.serviceList)
          this.setState({
            billingList: this.state.billingList
          }, () => {
            this.onClearHandler()
            // this.calculateTotalDiscount()
            this.setState({
              totalDiscountedAmount: '',
              totalDiscountedPercentage: ''
            })
          })
        } else {
          this.successErrorMessgae("Please, Select Service Template", 'error')
        }
      } else if (this.state.value === 'two') {
        if ((this.state.newDescriptionName !== "" && this.state?.newDescriptionName !== "Consultation Other") || (this.state?.newDescriptionName === "Consultation Other" && this.state?.consultDocName != "" && this.state?.consultAmount != "")) {
          if ((this.state.WardList.length > 1 && this.state.selectedWard !== "") || (this.state.WardList.length < 1)) {
            let object = this.state.descriptionData
            if (((object.service_category__service_category === "Doctor Consultation Charges") || (object.service_category__service_category === "Surgery Charges") || (object.service_category__service_category === "Anasthesia Charges"))) {
              object.totalAmount = states.descriptionAmount
              object.amount = states.descriptionAmount
            }
            else {
              object.min_amount = states?.MinimumAmount ? states?.MinimumAmount : null
              object.minimum_time = states?.minimumTime ? states?.minimumTime : '00:00'
              object.ward_name = states?.wardDetails?.ward ? states?.wardDetails?.ward : states.selectedWard
              object.ward_id = states?.wardDetails?.id ? states?.wardDetails?.id : states.selectedWardId
              object.amount = states.descriptionAmount
              object.start_date = moment(this.state.ipPatientData?.admission_date)
              object.end_date = null
              object.changed_start_time = null
              object.changed_end_time = null
              object.old_time_taken = object.time_taken
              object.totalAmount = object.unit == "hrs" ? this.CalculateHourAmount(object.amount, object.time_taken, object.old_time_taken, object?.minimum_time, +object.min_amount) : states.descriptionAmount
              if (this.state?.newDescriptionName === "Consultation Other" && object.service_category__service_category === "Others") {
                object.other_consultant_name = states?.consultDocName
              }
            }
            object.unit = "1";
            object.discountPercentage = ""
            object.discountAmount = ""
            object.noDiscountAmount = ""
            object.is_template = false
            object.is_auto_billing = false
            object.isSplitBlock = false
            object.selected_Ward = this.state.selectedWard ? this.state.selectedWard : object.consultant_charge[0].ward_name
            if (object.fee_type === 'all') {
              object.ip_service = {
                'selected_ward_name': object.consultant_charge[0].ward_name ? object.consultant_charge[0].ward_name : '',
                'total_amount': object.consultant_charge[0].total_amount ? +object.consultant_charge[0].total_amount : object.consultant_charge[0].service_amount,
                'amount': object.amount ? +object.amount : 0,
                'doctor_fee': object.consultant_charge[0].doctor_fee ? object.consultant_charge[0].doctor_fee : '',
                'hospital_fee': object.consultant_charge[0].hospital_fee ? object.consultant_charge[0].hospital_fee : '',
                'doctor_percentage': object.consultant_charge[0].doctor_percent ? object.consultant_charge[0].doctor_percent : 0,
                'hospital_percentage': object.consultant_charge[0].hospital_percent ? object.consultant_charge[0].hospital_percent : 0,
                'unit': object.unit,
                'service_category': object.service_category__service_category ? object.service_category__service_category : '',
                'service_amount': object.consultant_charge[0].service_amount ? object.consultant_charge[0].service_amount : 0
              }
            } else {
              object?.consultant_charge?.forEach(element => {
                if (element.ward_name === object.selected_Ward) {
                  object.ip_service = {
                    'selected_ward_name': element.ward_name ? element.ward_name : '',
                    'total_amount': element.total_amount ? element.total_amount : object.totalAmount,
                    'amount': object.amount ? object.amount : 0,
                    'doctor_fee': element.doctor_fee ? element.doctor_fee : '',
                    'hospital_fee': element.hospital_fee ? element.hospital_fee : '',
                    'doctor_percentage': element.doctor_percent ? element.doctor_percent : 0,
                    'hospital_percentage': element.hospital_percent ? element.hospital_percent : 0,
                    'unit': object.unit,
                    'service_category': object.service_category__service_category ? object.service_category__service_category : '',
                    'service_amount': element.service_amount ? element.service_amount : 0
                  }
                }
              })
            }

            let flag = false;
            this.state.billingList.forEach(element => {
              if (element.service_name === object.service_name && element.service_type === object.service_type && element?.amount === object?.amount ||
                element.service_name === object.service_name && element.service_type === object.service_type && element?.totalAmount === object?.totalAmount) {
                flag = true
              }
            })
            if (!flag) {
              this.state.descriptionList.push(object)
              this.state.billingList = this.state.billingList.concat(this.state.descriptionList)
              this.setState({
                billingList: this.state.billingList,
                WardList: []
              }, () => {
                this.onClearHandler()
                // this.calculateTotalDiscount()
                this.setState({
                  totalDiscountedAmount: '',
                  totalDiscountedPercentage: ''
                })
              })
            } else {
              this.setState({
                WardList: []
              })
              this.successErrorMessgae("Service Name Already Exist", 'error')
              this.onClearHandler()
            }
          }
          else {
            if (this.state.selectedWard === "") {
              this.successErrorMessgae("Please, Select the Ward", 'error')
            }
          }
        } else {
          if (this.state?.newDescriptionName === "Consultation Other") {
            if (this.state?.consultDocName === "") {
              this.successErrorMessgae("Please, Select Doctor Name", 'error')
            } else if (this.state?.consultAmount === "") {
              this.successErrorMessgae("Please, Enter Consultation Amount", 'error')
            }
          } else {
            this.successErrorMessgae("Please, Select Service Name", 'error')
          }
        }
      }
    } catch (error) {
      this.successErrorMessgae('Please, Select Service Template or Description', 'error')
    }
  }

  onAddHandlerThree = () => {
    try {
      let states = this.state
      if (this.state.selectedServiceTempId) {
        let templateServiceList = [];

        this.state.serviceTemplateData.forEach(element => {
          templateServiceList.push(element.service_data)
        })
        // if (this.state.serviceCodeText !== "" || this.state.selectedServiceCodeText) {
          let templateFlag = false;
          this.state.billingList.forEach(element => {
            if (
              (this.state.serviceCodeText === element.package_service_code) ||
              (this.state.selectedServiceCodeText === element.package_service_code) ||
              (this.state.selectedPackageCodeText === element.package_name)
            ) {
              templateFlag = true
            }
          })
          let codeData = this.state.codeData ? this.state.codeData : this.state.serviceCodeData
          codeData?.service_data?.forEach((element) => {
            if (((element.service_category__service_category === "Doctor Consultation Charges") || (element.service_category__service_category === "Surgery Charges") || (element.service_category__service_category === "Anasthesia Charges"))) {
              element.totalAmount = element.amount
            } else {
              element.min_amount = states?.MinimumAmount ? states?.MinimumAmount : 0
              element.minimum_time = states?.minimumTime ? states?.minimumTime : '00:00'
              element.start_date = moment(this.state.ipPatientData?.admission_date)
              element.end_date = null
              element.changed_start_time = null
              element.changed_end_time = null
              element.old_time_taken = element.time_taken
              element.totalAmount = element.unit == "hrs" ? this.CalculateHourAmount(element.amount, element.time_taken, element.old_time_taken, element.minimum_time, +element.min_amount) : element.amount
            }
            element.unit = "1"
            element.discountPercentage = ""
            element.discountAmount = ""
            element.template_name = this.state.service
            element.templateId = this.state.templateId
            element.is_template = true
            element.selected_Ward = this.state.selectedWard ? this.state.selectedWard : element.consultant_charge[0].ward_name
            element.is_auto_billing = false
            element.isSplitBlock = false
            element.package_service_code = this.state.serviceCodeText ? this.state.serviceCodeText : this.state.selectedServiceCodeText
            element.ip_service = {
              'selected_ward_name': element.selected_ward ? element.selected_ward : '',
              'total_amount': element.totalAmount ? element.totalAmount : 0,
              'amount': element.amount ? element.amount : 0,
              'doctor_fee': element.doctor_share ? element.doctor_share : '',
              'hospital_fee': element.hospital_share ? element.hospital_share : '',
              'doctor_percentage': element.doctor_percent ? element.doctor_percent : 0,
              'hospital_percentage': element.hospital_percent ? element.hospital_percent : 0,
              'unit': element.unit ? element.unit : 0,
              'service_category': element.service_category__service_category ? element.service_category__service_category : '',
              'service_amount': element.service_amount ? element.service_amount : 0
            }
            if (!templateFlag) {
              this.state.serviceCodeList.push(element)
            } else {
              this.successErrorMessgae("Service Code Already Exist", 'error')
              this.onClearHandler()
            }
          })
          this.state.billingList = this.state.billingList.concat(this.state.serviceCodeList)
          this.setState({
            billingList: this.state.billingList
          }, () => {
            this.onClearHandler()
            // this.calculateTotalDiscount()
            this.setState({
              totalDiscountedAmount: '',
              totalDiscountedPercentage: ''
            })
          })
        // } else {
        //   this.successErrorMessgae("Please, Select Service Code", 'error')
        // }
      } else {
        if ((this.state.WardList.length > 1 && this.state.selectedWard !== "") || (this.state.WardList.length < 1)) {
          let object = this.state.codeData ? this.state.codeData : this.state.serviceCodeData
          if (((object.service_category === "Doctor Consultation Charges") || (object.service_category === "Surgery Charges") || (object.service_category === "Anasthesia Charges"))) {
            object.totalAmount = states.serviceCodeAmt
            object.amount = states.serviceCodeAmt
          }
          else {
            object.min_amount = states?.MinimumAmount ? states?.MinimumAmount : null
            object.minimum_time = states?.minimumTime ? states?.minimumTime : '00:00'
            object.ward_name = states?.wardDetails?.ward ? states?.wardDetails?.ward : states.selectedWard
            object.ward_id = states?.wardDetails?.id ? states?.wardDetails?.id : states.selectedWardId
            object.amount = states.serviceCodeAmt
            object.start_date = moment(this.state.ipPatientData?.admission_date)
            object.end_date = null
            object.changed_start_time = null
            object.changed_end_time = null
            object.old_time_taken = object.time_taken
            object.totalAmount = object.unit == "hrs" ? this.CalculateHourAmount(object.amount, object.time_taken, object.old_time_taken, object?.minimum_time, +object.min_amount) : states.serviceCodeAmt
          }
          object.unit = "1";
          object.discountPercentage = ""
          object.discountAmount = ""
          object.noDiscountAmount = ""
          object.is_template = false
          object.is_auto_billing = false
          object.isSplitBlock = false
          object.selected_Ward = this.state.selectedWard ? this.state.selectedWard : object.consultant_charge[0].ward_name
          if (object.fee_type === 'all') {
            object.ip_service = {
              'selected_ward_name': object.consultant_charge[0].ward_name ? object.consultant_charge[0].ward_name : '',
              'total_amount': object.consultant_charge[0].total_amount ? +object.consultant_charge[0].total_amount : object.consultant_charge[0].service_amount,
              'amount': object.amount ? +object.amount : 0,
              'doctor_fee': object.consultant_charge[0].doctor_fee ? object.consultant_charge[0].doctor_fee : '',
              'hospital_fee': object.consultant_charge[0].hospital_fee ? object.consultant_charge[0].hospital_fee : '',
              'doctor_percentage': object.consultant_charge[0].doctor_percent ? object.consultant_charge[0].doctor_percent : 0,
              'hospital_percentage': object.consultant_charge[0].hospital_percent ? object.consultant_charge[0].hospital_percent : 0,
              'unit': object.unit,
              'service_category': object.service_category ? object.service_category : '',
              'service_amount': object.consultant_charge[0].service_amount ? object.consultant_charge[0].service_amount : 0
            }
          } else {
            object?.consultant_charge?.forEach(element => {
              if (element.ward_name === object.selected_Ward) {
                object.ip_service = {
                  'selected_ward_name': element.ward_name ? element.ward_name : '',
                  'total_amount': element.total_amount ? element.total_amount : object.totalAmount,
                  'amount': object.amount ? object.amount : 0,
                  'doctor_fee': element.doctor_fee ? element.doctor_fee : '',
                  'hospital_fee': element.hospital_fee ? element.hospital_fee : '',
                  'doctor_percentage': element.doctor_percent ? element.doctor_percent : 0,
                  'hospital_percentage': element.hospital_percent ? element.hospital_percent : 0,
                  'unit': object.unit,
                  'service_category': object.service_category ? object.service_category : '',
                  'service_amount': element.service_amount ? element.service_amount : 0
                }
              }
            })
          }

          let flag = false;
          this.state.billingList.forEach(element => {
            if (
              (element.ip_service_code === object.ip_service_code && element.amount === object.amount) &&
              element.service_name === object.service_name &&
              element.service_type === object.service_type &&
              (element?.amount === object?.amount || element.service_name === object.service_name) &&
              element.service_type === object.service_type && element?.totalAmount === object?.totalAmount
            ) {
              flag = true
            }
          })
          if (!flag) {
            this.state.serviceCodeList2.push(object)
            this.state.billingList = this.state.billingList.concat(this.state.serviceCodeList2)
            this.setState({
              billingList: this.state.billingList,
              WardList: []
            }, () => {
              this.onClearHandler()
              // this.calculateTotalDiscount()
              this.setState({
                totalDiscountedAmount: '',
                totalDiscountedPercentage: ''
              })
            })
          } else {
            this.setState({
              WardList: []
            })
            this.successErrorMessgae("Service Code Already Exist", 'error')
            this.onClearHandler()
          }
        }
        else {
          if (this.state.selectedWard === "") {
            this.successErrorMessgae("Please, Select the Ward", 'error')
          }
        }
      }
    } catch (error) {
      this.successErrorMessgae('Please, Select Service Code', 'error')
    }
  }

  convertTimeToDecimal(time) {
    let [hours, minutes] = time?.split(':').map(Number);
    return hours * 60 + minutes;
  }

  CalculateHourAmount(ratePerHour, changedTime, fixedTime, minTime, minAmount) {
    let fMinTime = typeof (minTime) === 'number' ? (minTime < 9 ? `00:0${minTime}` : `00:${minTime}`) : minTime
    let fixedTimes = this.convertTimeToDecimal(fixedTime);
    let changedTimes = this.convertTimeToDecimal(changedTime);
    let minTimes = this.convertTimeToDecimal(fMinTime ? fMinTime : '00:00');
    let changeAndFixedTime = changedTimes - fixedTimes
    let time = changeAndFixedTime > 0 ? changeAndFixedTime : 0
    let timeCalculateTime = (time === 0 && minTimes === 0) ? 0 : (Math.ceil(time / minTimes) === Infinity ? 0 : Math.ceil(time / minTimes))
    let totalCost = ((+ratePerHour) + (timeCalculateTime * minAmount))
    // let totalCost = Math.ceil(changedTimes / fixedTimes) * ratePerHour;

    return totalCost;
  }

  billingCalculation = (params, type) => {
    let amount = 0; let payableAmount = 0;
    switch (type) {
      case 'newAmount':
        let noDiscountAmount = 0;
        params.forEach(element => {
          noDiscountAmount = (+noDiscountAmount) + (element.unit === 'hrs' ? this.CalculateHourAmount(element.amount, element.time_taken, element.old_time_taken ? element.old_time_taken : element.time_taken, element?.minimum_time, element?.min_amount) : ((+element.unit) * (+element.amount)))
        })
        return AmountFormat(+noDiscountAmount)
      case 'discountAmount':
        let discountedAmount = 0;
        params.forEach(element => {
          discountedAmount = +discountedAmount + (+element.discountAmount)
        })
        return AmountFormat(+discountedAmount);
      case 'totalServiceAmount':
        let totalAmt = 0;
        params.forEach(element => {
          totalAmt = totalAmt + ((element.ip_service?.hospital_fee || element.ip_service?.hospital_fee === 0) ? element.ip_service?.hospital_fee : (element.totalAmount - element.discountAmount))
          amount = amount + (element.totalAmount - element.discountAmount)
          this.state.totalServiceAmount = +amount
          this.state.concessionRestrictAmt = +this.state.billConcessionType === 1 ? +amount : +totalAmt
        })
        return AmountFormat(this.state.totalServiceAmount)
      case 'roundOff':
        this.state.roundOff = CommonValidation.roundOff(this.state.totalServiceAmount, this.state.totalDiscountedAmount)
        return this.state.roundOff;
      case 'payableAmount':
        if (this.state?.ForCancel) {
          this.state.totalPayableAmount = Math.round(+this.state.BillReceivedAmount)
          return AmountFormat(this.state.totalPayableAmount)
        }
        else if (this.state.advCheck) {
          var PayAmt = 0
          let discount = this.state.totalDiscountedAmount ? this.state.totalDiscountedAmount : 0
          payableAmount = this.state.totalServiceAmount - discount
          if (this.state.totalPayableAmount !== Math.round(+payableAmount)) {
            this.setState({ totalPayableAmount: Math.round(+payableAmount) })
          }
          if ((this.state.advance_amount) >= (payableAmount)) {
            PayAmt = Math.round(+payableAmount)
          } else {
            PayAmt = +(this.state.advance_amount)
          }
          if (this.state.totalDeductedAmount !== PayAmt && PayAmt) {
            this.setState({
              totalDeductedAmount: PayAmt ? PayAmt : 0
            })
          }
          return AmountFormat(+(Math.round(payableAmount)) - +(this.state.totalDeductedAmount))

        } else {
          let discount = this.state.totalDiscountedAmount ? this.state.totalDiscountedAmount : 0
          payableAmount = this.state.totalServiceAmount - discount
          if (this.state.totalPayableAmount !== Math.round(+payableAmount)) {
            this.setState({ totalPayableAmount: Math.round(+payableAmount) })
          }
          return AmountFormat(this.state.totalPayableAmount)
        }
      default:
        break;
    }
  }

  onClickReceiveAndCreditPayment = () => {
    if (this.state.discountReason === '' && ((this.state.totalDiscountedPercentage !== '' && this.state.totalDiscountedPercentage !== 0) || (this.state.totalDiscountedAmount !== '' && this.state.totalDiscountedAmount !== 0))) {
      this.successErrorMessgae("Reason is Mandatory.Click Note icon to Enter Reason.", 'error')
    } else {
      let noDiscountAmount = 0;
      this.state.billingList.forEach(element => {
        noDiscountAmount = noDiscountAmount + (element.time_taken ? this.CalculateHourAmount(+element?.amount, element?.time_taken, (element?.old_time_taken ? element?.old_time_taken : element?.time_taken), element?.minimum_time, +element?.min_amount) : (+element.amount) * (+element.unit))
      })
      let AfterDeducted = (this.state.totalPayableAmount) - (this.state.totalDeductedAmount)
      let data = {
        ip_admission_id: this.state.ipPatientData?.id,
        net_amount: this.state.totalPayableAmount,
        received_amount: this.state.totalPayableAmount,
        discount_percentage: this.state.totalDiscountedPercentage,
        discount_amount: this.state.totalDiscountedAmount,
        invoice_date: this.state.templateDate,
        total_bill_amount: this.state.totalPayableAmount,
        total_paid_amount: AfterDeducted,
        total_balance: 0,
        service_line_item: this.state.billingList,
        noDiscountAmount: noDiscountAmount,
        patientData: this.state.ipPatientData,
        serviceAmount: this.state.totalServiceAmount,
        removable_ids: this.state.removable_ids,
        discountReason: this.state?.discountReason ? this.state.discountReason : "",
        remain_amount: AfterDeducted,
        advCheck: this.state.advCheck,
        totalDeductedAmount: this.state.totalDeductedAmount,
        other_consultant_name: this.state.consultDocName
      }
      this.setState({
        data: data
      }, () => {
        if (this.state.billingList.length > 0 && this.state.totalServiceAmount !== 0 && +this.state.totalDiscountedPercentage !== 100) {
          this.setState({ show: true })
        } else if (this.state.billingList.length > 0 && (this.state.totalServiceAmount === 0 || +this.state.totalDiscountedPercentage === 100)) {
          this.postCreditBill(true)
        } else {
          this.successErrorMessgae("No Service has added", 'error')
        }
      })
    }
  }

  TimeFieldCalculation = (item) => {
    let { billingList } = this.state
    let Hours = (item.hour == "" ? "00" : (item.hour).length < 2 ? ("0" + item.hour) : item.hour) + ":" + (item.minute == "" ? "00" : (item.minute).length < 2 ? ("0" + item.minute) : item.minute) + ":" + "00"
    item.totalAmount = this.CalculateHourAmount(item.amount, Hours, item.old_time_taken, item?.minimum_time, +item?.min_amount)
    item.time_taken = Hours.split(":")[0] + ":" + Hours.split(":")[1]
    this.setState({ billingList }, () => {
      item.field = false
      this.setState({
        billingList
      })
    })
  }
  TimeFieldEnable = (Time, item) => {
    if (item.is_edit_unit && this.state.isEditunit) {
      let { billingList } = this.state
      item.hour = Time.split(":")[0];
      item.minute = Time.split(":")[1]
      this.setState({
        billingList
      }, () => {
        item.field = true
        this.setState({
          billingList
        })
      })
    }
  }

  formatDateToDDMMYYYY = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 to get correct month
    const year = String(date.getFullYear());

    return `${day}:${month}:${year}`;
  }
  buttonOneAction = () => {
    let { selectedService } = this.state;
    if (!selectedService?.procedure_start_time && !selectedService?.procedure_end_time) {
      selectedService["start_date"] = (selectedService?.changed_start_time == null ? moment(this.state.ipPatientData?.admission_date) : selectedService?.changed_start_time);
      selectedService["end_date"] = selectedService?.changed_end_time;
    } else {
      selectedService["start_date"] = selectedService?.start_date
      selectedService["end_date"] = selectedService?.end_date
    }
    this.setState({
      selectedService
    }, () => {
      this.setState({ Timepopup: false })
    })
  }
  buttonTwoAction = (item) => {
    let { billingList, ChangedUnit, timeFormate } = this.state
    let changedTime = ChangedUnit == 0 ? item.service_units : ChangedUnit
    if (this.state?.selectedService?.end_date && this.state?.selectedService?.end_date != null && !timeFormate) {
      var timeParts = changedTime.split(":");
      var hours = parseFloat(timeParts[0]);
      var minutes = parseFloat(timeParts[1]);
      if (hours >= 0 || minutes >= 0) {
        item.totalAmount = this.CalculateHourAmount(item.amount, changedTime, item.old_time_taken, item?.minimum_time, +item?.min_amount)
        item.time_taken = changedTime
        item.discountAmount = CommonValidation.calculatePercentage(item.totalAmount, item.discountPercentage)
        item.discountPercentage = CommonValidation.amountPercentage(item.discountAmount, item.totalAmount)
        item.changed_start_time = item.start_date
        item.changed_end_time = item.end_date
        this.setState({ billingList, Timepopup: false }, () => {
          // this.calculateTotalDiscount()
          this.setState({
            totalDiscountedAmount: '',
            totalDiscountedPercentage: ''
          })
        })
      } else {
        this.successErrorMessgae("Enter Correct End Date/Time", 'error')
      }
    } else {
      this.successErrorMessgae("Enter Correct End Date/Time", 'error')
    }
  }
  CalculateTimeDiff = () => {
    var startTime = new Date(this.state?.selectedService?.start_date);
    var endTime = new Date(this.state?.selectedService?.end_date);

    const startTimes = new Date(startTime);
    const endTimes = new Date(endTime);

    if (endTimes > startTimes) {
      this.setState({
        timeFormate: false
      })
    } else {
      this.setState({
        timeFormate: true
      })
    }

    startTime.setSeconds(0);
    endTime.setSeconds(0);

    var timeDiffMillis = endTime - startTime;
    var hours = Math.floor(timeDiffMillis / (1000 * 60 * 60));
    var minutes = Math.floor((timeDiffMillis % (1000 * 60 * 60)) / (1000 * 60));

    let formattedHours = hours > 9 ? hours.toString() : `0${hours}`;
    let formattedMinutes = minutes > 9 ? minutes.toString() : `0${minutes}`;

    let changedTime = `${formattedHours}:${formattedMinutes}:00`;
    this.setState({
      ChangedUnit: changedTime
    })
  }
  DateConvertion = (date) => {
    let outputDateString = date.getFullYear() + '-' +
      ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
    return outputDateString
  }
  TimeConvertion = (inputDate) => {
    let outputDateString = inputDate.getFullYear() + '-' +
      ('0' + (inputDate.getMonth() + 1)).slice(-2) + '-' +
      ('0' + inputDate.getDate()).slice(-2) + 'T' +
      ('0' + inputDate.getHours()).slice(-2) + ':' +
      ('0' + inputDate.getMinutes()).slice(-2) + ':' +
      ('0' + inputDate.getSeconds()).slice(-2);
    return moment(outputDateString)
  }

  getPatientData = () => {
    try {
      let AdmissionId = this.state.ipPatientData?.id ? this.state.ipPatientData?.id : this.state.withOutAppointment.ip_admission_id ? this.state.withOutAppointment.ip_admission_id : this.state.appointment_id
      RestAPIService.getAll(Serviceurls.IP_PATIENT_DETAILS + '?admission_id=' + AdmissionId)
        .then((response) => {
          if (response.data.status === "success") {
            this.setState({
              service_amount: response.data.data[0].service_amount,
              balance_amount: response.data.data[0].balance_amount,
              paid_amount: response.data.data[0].paid_amount,
              advance_amount: response.data.data[0].advance_amount,
              attender_mobile: response.data.data[0].attender_mobile,
              attender_name: response.data.data[0].attender_name,
              patientData: response.data.data[0],
              paymentCreditType: response.data.data[0]?.credit_type === "Patient credit" ? 'patientcredit' :
                response.data.data[0]?.credit_type === "Corprate credit" ? 'corporatecredit' :
                  response.data.data[0]?.credit_type === "Insurance credit" ? 'insurancecredit' : 'patientcredit',
              ApprovedAmt: response.data.data[0].claimed_amount,
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.successErrorMessgae(error.response.data.message, 'error')
          } else {
            this.successErrorMessgae(error.message, 'error')
          }
        })
    } catch (error) {
      this.successErrorMessgae(error.message, 'error')
    }
  }

  getServiceTemplateData = () => {
    try {
      RestAPIService.getAll(Serviceurls.FO_CONFIG_IPSRCPACKAGE + '?package_name=' + this.state.serviceSearch)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              serviceTemplateData: response.data.data,
              serviceTemplateDataNew: JSON.stringify(response.data.data)
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.status) {
            this.successErrorMessgae(error.response.data.message, 'error')
          } else {
            this.successErrorMessgae(error.message, 'error')
          }
        })
    } catch (error) {
      this.successErrorMessgae(error.message, 'error')
    }
  }

  getServiceDescriptionData = () => {
    try {
      RestAPIService.getAll(Serviceurls.FO_CONFIG_IPSRCNAME + '?service_type=' + this.state.descriptionSerach)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              serviceDescriptionData: response?.data?.data,
              serviceDescriptionDataNew: JSON.stringify(response?.data?.data),
              newDescription: [],
              description: "",
              newDescription: [],
              dupNewDescription: '[]',
              searchServiceName: '',
            }, () => {
              const Consultation =
              {
                data: [],
                service_category: "Others",
                service_type: "Consultation Other",
                surgery_id: null,
                service_name: "Others",
                consultant_charge: [
                  {
                    service_amount: 0,
                    ward_name: "All"
                  }
                ],
                is_edit_amount: false,
                is_edit_discount: false,
                is_edit_unit: false,
                service_category__service_category: "Others",
                other_consultant_name: ""
              }
              this.setState(prevState => ({
                serviceDescriptionData: [...prevState.serviceDescriptionData, Consultation],
                serviceDescriptionDataNew: JSON.stringify([...prevState.serviceDescriptionData, Consultation])
              }));
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.status) {
            this.successErrorMessgae(error.response.data.message, 'error')
          } else {
            this.successErrorMessgae(error.message, 'error')
          }
        })
    } catch (error) {
      this.successErrorMessgae(error.message, 'error')
    }
  }

  postCreditBill = (fromReceivePayment = false) => {
    try {
      let startDate = new Date(this.state.templateDate)
      let Aptime = DateTime.fromJSDate(startDate).toLocaleString(DateTime.TIME_24_SIMPLE);
      let invoice_date = this.state.templateDate ? `${DateTime.fromJSDate(startDate).toFormat("yyyy-MM-dd")} ${Aptime}:00` : ""
      let service_line_items = []; let noDiscountAmount = 0;
      this.state.billingList.forEach(element => {
        let timeTakenStr = typeof element?.time_taken === "string" && element.time_taken?.split(':');
        let timeTaken = timeTakenStr?.length > 0 ? timeTakenStr?.slice(0, 2)?.join(':') : element?.time_taken;
        let Unit = timeTaken ? (timeTaken + " " + "hrs") : element.unit
        let old_Unit = element.old_time_taken ? element.old_time_taken : element.time_taken ? element.time_taken : null
        let timeAmount = element.time_taken ? this.CalculateHourAmount(+element?.amount, element.time_taken, old_Unit, element?.minimum_time, element?.min_amount) : 0
        service_line_items.push({
          service_name: element.service_name ? CommonValidation.removeSpace(element.service_name) : '',
          service_type: element.service_type ? CommonValidation.removeSpace(element.service_type) : '',
          service_units: Unit ? Unit : '1',
          service_old_units: old_Unit ? old_Unit : null,
          service_rate_per_unit: + element.amount,
          service_amount: element.time_taken ? (+timeAmount - element.discountAmount) : +(element.totalAmount - element.discountAmount),
          discount_amount: +element.discountAmount,
          discount_percentage: +element.discountPercentage,
          total_amount: (element.time_taken ? +timeAmount : +element.amount * +element.unit),
          status: fromReceivePayment ? "paid" : this.state.IpSavedBill ? 'not paid' : 'credit',
          id: this.state.invoice_id ? element.line_item_id : null,
          template_id: element.templateId ? element.templateId : element?.ip_template_id ? element?.ip_template_id : null,
          service_id: element.ip_service_id ? element.ip_service_id : element?.id ? element?.id : element.service_id ? element.service_id : null,
          service_category_id: element.service_category_id ? element.service_category_id : null,
          amount_type: element.amount_type ? element.amount_type : 'rupee',
          doctor_fee: element?.ip_service?.doctor_fee ? element?.ip_service?.doctor_fee : element?.doctor_share ? element?.doctor_share : element.new_doctor_fee ? element.new_doctor_fee : 0,
          hospital_fee: element?.ip_service?.hospital_fee ? element?.ip_service?.hospital_fee : element?.hospital_share ? element?.hospital_share : element.new_hospital_fee ? element.new_hospital_fee : 0,
          doctor_percentage: element?.ip_service?.doctor_percentage ? element?.ip_service?.doctor_percentage : element?.doctor_percent ? element?.doctor_percent : element.doctor_percentage ? element.doctor_percentage : 0,
          hospital_percentage: element?.ip_service?.hospital_percentage ? element?.ip_service?.hospital_percentage : element?.hospital_percent ? element?.hospital_percent : element.hospital_percentage ? element.hospital_percentage : 0,
          old_doctor_fee: element?.ip_service?.original_doctor_fee ? element?.ip_service?.original_doctor_fee : element?.ip_service?.doctor_fee ? element?.ip_service?.doctor_fee : element?.doctor_share ? element?.doctor_share : 0,
          old_hospital_fee: element?.ip_service?.original_hospital_fee ? element?.ip_service?.original_hospital_fee : element?.ip_service?.hospital_fee ? element?.ip_service?.hospital_fee : element?.hospital_share ? element?.hospital_share : 0,
          // old_doctor_fee: element?.ip_service?.original_doctor_fee ? element?.ip_service?.original_doctor_fee : element?.ip_service?.doctor_fee ? element?.ip_service?.doctor_fee : element?.old_doctor_fee ? element?.old_doctor_fee : element?.doctor_share ? element?.doctor_share : 0,
          // old_hospital_fee: element?.ip_service?.original_hospital_fee ? element?.ip_service?.original_hospital_fee : element?.ip_service?.hospital_fee ? element?.ip_service?.hospital_fee : element?.old_hospital_fee ? element?.old_hospital_fee : element?.hospital_share ? element?.hospital_share : 0,
          new_doctor_fee: element?.ip_service?.doctor_fee ? element?.ip_service?.doctor_fee : 0,
          new_hospital_fee: element?.ip_service?.hospital_fee ? element?.ip_service?.hospital_fee : 0,
          is_auto_billing: element?.is_auto_billing,
          procedure_start_time: element?.start_date ? element?.start_date : null,
          procedure_end_time: element?.end_date ? element?.end_date : null,
          minimum_amount: element.time_taken && element?.min_amount ? element?.min_amount : null,
          minimum_time: element.minimum_time ? (typeof (element.minimum_time) === 'number' ? (element.minimum_time < 9 ? `00:0${element.minimum_time}` : `00:${element.minimum_time}`) : element.minimum_time) : '00:00',
          individual_discount_comments: element?.individual_discount_comments ? element?.individual_discount_comments : "",
          other_consultant_name: element?.other_consultant_name ? element?.other_consultant_name : null
        })
        noDiscountAmount = noDiscountAmount + (element.time_taken ? this.CalculateHourAmount(+element?.amount, element.time_taken, old_Unit, element?.minimum_time, element?.min_amount) : element.unit * +element.amount)
      })
      let data = {}
      let creditType = this.state.paymentCreditType === "patientcredit" ? "Patient credit" :
        this.state.paymentCreditType === "corporatecredit" ? "Corprate credit" :
          this.state.paymentCreditType === "insurancecredit" ? "Insurance credit" : 'Patient credit'
      if (this.state.IpCompleteBill) {
        data = {
          "ip_admission_id": this.state.ipPatientData?.id,
          "net_amount": this.state.totalPayableAmount,
          "paid_amount": 0,
          "received_amount": 0,
          "detect_from_advance": this.state.advCheck,
          "amount_detect_from_advance": this.state.totalDeductedAmount,
          "discount_percentage": this.state.totalDiscountedPercentage ? +this.state.totalDiscountedPercentage : 0,
          "discount_amount": this.state.totalDiscountedAmount ? +this.state.totalDiscountedAmount : 0,
          "invoice_date": invoice_date,
          "payment_mode": [],
          "cash_amount": 0,
          "card_amount": 0,
          "upi_amount": 0,
          "bank_transfer_amount": 0,
          "cheque_amount": 0,
          "card_number": "",
          "upi_ref_no": "",
          "bank_ref_no": "",
          "cheque_ref_no": "",
          "payment_status": "paid",
          "payment_date": null,
          "total_bill_amount": this.state.totalPayableAmount,
          "total_paid_amount": 0,
          "total_sub_amount": +(this.state.totalServiceAmount),
          "total_balance": this.state.totalPayableAmount,
          "service_line_item": service_line_items,
          "bill_type": "regular",
          "total_amount": 0,
          'removable_ids': this.state.removable_ids,
          'remarks': this.state.discountReason ? this.state.discountReason : ''
        }
        if (this.state.invoice_id) { data["bill_id"] = this.state.invoice_id }
      }
      else if (this.state.IpSavedBill) {
        data = {
          "ip_admission_id": this.state.ipPatientData?.id,
          "net_amount": this.state.totalPayableAmount,
          "detect_from_advance": this.state.advCheck,
          "amount_detect_from_advance": this.state.totalDeductedAmount,
          "paid_amount": 0,
          "received_amount": 0,
          "discount_percentage": this.state.totalDiscountedPercentage ? +this.state.totalDiscountedPercentage : 0,
          "discount_amount": this.state.totalDiscountedAmount ? +this.state.totalDiscountedAmount : 0,
          "invoice_date": invoice_date,
          "payment_mode": [],
          "cash_amount": 0,
          "card_amount": 0,
          "upi_amount": 0,
          "bank_transfer_amount": 0,
          "cheque_amount": 0,
          "card_number": "",
          "upi_ref_no": "",
          "bank_ref_no": "",
          "cheque_ref_no": "",
          "payment_status": "not paid",
          "payment_date": null,
          "total_bill_amount": this.state.totalPayableAmount,
          "total_paid_amount": 0,
          "total_sub_amount": +(this.state.totalServiceAmount),
          "total_balance": this.state.totalPayableAmount,
          "service_line_item": service_line_items,
          "bill_type": "saved",
          "total_amount": 0,
          'removable_ids': this.state.removable_ids,
          'remarks': this.state.discountReason ? this.state.discountReason : ''
        }
        if (this.state.invoice_id) { data["bill_id"] = this.state.invoice_id }
      } else {
        data = {
          "ip_admission_id": this.state.ipPatientData?.id,
          "net_amount": this.state.totalPayableAmount,
          "detect_from_advance": this.state.advCheck,
          "amount_detect_from_advance": this.state.totalDeductedAmount,
          "paid_amount": 0,
          "received_amount": 0,
          "discount_percentage": this.state.totalDiscountedPercentage ? +this.state.totalDiscountedPercentage : 0,
          "discount_amount": this.state.totalDiscountedAmount ? +this.state.totalDiscountedAmount : 0,
          "invoice_date": invoice_date,
          "payment_mode": fromReceivePayment ? [1] : [],
          "cash_amount": 0,
          "card_amount": 0,
          "upi_amount": 0,
          "bank_transfer_amount": 0,
          "cheque_amount": 0,
          "card_number": "",
          "upi_ref_no": "",
          "bank_ref_no": "",
          "cheque_ref_no": "",
          "payment_status": fromReceivePayment ? "paid" : this.state.totalDeductedAmount ? "partial" : "not paid",
          "payment_date": null,
          "total_bill_amount": this.state.totalPayableAmount,
          "total_paid_amount": 0,
          "total_sub_amount": +(this.state.totalServiceAmount),
          "total_balance": this.state.totalPayableAmount,
          "service_line_item": service_line_items,
          "bill_type": fromReceivePayment ? "regular" : "credit",
          "total_amount": noDiscountAmount,
          // 'removable_ids': this.state.removable_ids,
          'remarks': this.state.discountReason ? this.state.discountReason : '',
          removable_ids: this.state.removable_ids,
          credit_type: creditType,
          insurance_name: creditType === "Insurance credit" ? (this.state.selInsName ? this.state.selInsName : this.state.patientData?.insurance_name ? this.state.patientData?.insurance_name : '') : "",
          policy_no: creditType === "Insurance credit" ? (this.state.selPolicyNo ? this.state.selPolicyNo : this.state.patientData?.policy_no ? this.state.patientData?.policy_no : '') : "",
          employer_id: creditType === "Corprate credit" ? (this.state.selCompanyID ? this.state.selCompanyID : this.state?.patientData?.employer_id ? this.state?.patientData?.employer_id : null) : null,
          employee_no: creditType === "Corprate credit" ? (this.state.selEmployeeNo ? this.state.selEmployeeNo : this.state?.patientData?.id_no ? this.state?.patientData?.id_no : '') : ""
        }
        if (this.state.invoice_id) { data["bill_id"] = this.state.invoice_id }
      }
      if (!this.state.IpSavedBill && (this.state.discountReason === '' && ((this.state.totalDiscountedPercentage !== '' && this.state.totalDiscountedPercentage !== 0) || (this.state.totalDiscountedAmount !== '' && this.state.totalDiscountedAmount !== 0)))) {
        this.successErrorMessgae("Reason is Mandatory.Click Note Icon for enter Reason", 'error')
      } else {
        this.setState({ disable: true })
        this.lodaerFunction(true)
        RestAPIService.create(data, Serviceurls.IP_SERVICE_BILL_POST)
          .then((response) => {
            if (response.data.status === 'success') {
              this.setState({ disable: false, DisableBtn: false })
              this.lodaerFunction(false)
              this.getApproxCharges()
              // this.successErrorMessgae(response.data.message, 'success')
              this.getPatientData()
              this.getServiceTemplateData()
              this.getServiceDescriptionData()
              this.setState({ value: this.state.showCode ? 'three' : 'one', IpSavedBill: false, discountReason: '' })
              if (fromReceivePayment) {
                this.CrebillPosted(response.data.message, response.data.bill_id)
                this.setState({ removable_ids: [], savedDiscountReason: '' })
              }
              else {
                this.setState({ disable: false, DisableBtn: false })
                this.successErrorMessgae(response.data.message, 'success')
                this.setState({ removable_ids: [], savedDiscountReason: '' })
                if (this.state.isForEditBill) {
                  clearCachevalue("IPCancelBilldata");
                  clearCachevalue("isForCancelBill");
                  if (this.props.history?.location?.state?.FromBills) {
                    this.onClearLeftContent()
                    this.props.history.push({ pathname: "/IPHome/PatientDetails/ServiceBilling" })
                  } else {
                    this.props.history.push({ pathname: '/BillingTransaction/OverAllBills' })
                  }
                }
              }
              this.onClearLeftContent()
            }
          }).catch((error) => {
            this.setState({ disable: false, DisableBtn: false })
            this.setState({ IpSavedBill: false }, () => {
              if (error?.response?.data?.message) {
                this.lodaerFunction(false)
                this.successErrorMessgae(error.response.data.message, 'error')
              } else {
                this.lodaerFunction(false)
                this.successErrorMessgae(error.message, 'error')
              }
            })
          })
      }
    } catch (error) {
      this.setState({ disable: false, DisableBtn: false })
      this.setState({ IpSavedBill: false }, () => {
        this.successErrorMessgae(error.message, 'error')
      })
    }
  }

  rightBottomCard = (title, value, names, serviceAmount) => {
    const { t } = this.props
    var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
    var UserData = myUser;
    return (
      <Box component={'div'}>
        <Box className='eMed_right_sub_grid' justifyContent={'center'}>
          <Box component={'div'} className='eMed_date_picker_grid' >
            <Typography marginLeft={'0.5vw'} color='#616161' fontWeight={'600'}>{title}</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                open={this.state.Dateopen}
                onOpen={() => { this.setState({ Dateopen: true }) }}
                onClose={() => { this.setState({ Dateopen: false }) }}
                inputFormat='DD-MM-YYYY & hh:mm A'
                disabled={true} // this.state.isForEditBill --> previous condition
                value={value}
                name={names}
                minDate={UserData?.is_user ? new Date() : this.state.ipPatientData?.admission_date}
                maxDate={UserData?.is_user ? new Date() : new Date((new Date).getFullYear(), (new Date).getMonth(), (new Date).getDate() + 1)}
                onChange={(newDate) => {
                  if (newDate === null && names === "templateDate") {
                    this.setState({ templateDate: new Date(), DateTimeChanged: false })
                  } else if (names === "descripDate" && newDate === null) {
                    this.setState({ descripDate: new Date(), DateTimeChanged: false })
                  } else {
                    this.setState({ templateDate: newDate, descripDate: newDate, DateTimeChanged: true })
                  }
                }}
                renderInput={(params) => <TextField size='small' autoComplete='off'
                  onKeyDown={(e) => e.preventDefault()}
                  onClick={() => { this.setState({ Dateopen: true }) }}
                  {...params}
                  fullWidth
                  variant='standard'
                  sx={{ marginTop: "1vw", marginLeft: "0.5vw", marginRight: "0.5vw", width: "15vw" }}
                />}
              />
            </LocalizationProvider>
          </Box>
          <Box component={'div'} className='eMed_date_picker_grid_amount'>
            <Typography ml={'0.5vw'} color='#616161' fontWeight={'600'}>{t("ServiceAmount")}</Typography>
            <Typography variant='h6' ml={'0.5vw'} mt={'1vw'} fontWeight={'600'}>{serviceAmount ? AmountFormat(serviceAmount) : AmountFormat(0)}</Typography>
          </Box>
        </Box>
        <Box component={'div'} display='flex' justifyContent={'center'} position='relative' top={'0.5vw'} >
          <Button variant='outlined'
            size='small'
            className='eMed_bill_btn_one'
            disabled={(this.state.withOutAppointment?.is_ip || this.state.ForCancel)}
            onClick={() => { this.onClearHandler() }}
          >{t("Clear")}</Button>
          <Button variant='contained'
            size='small'
            className='eMed_bill_btn_one'
            disabled={(this.state.withOutAppointment?.is_ip || this.state.isButtonDisabled || this.state.ForCancel)}
            onClick={() => {
              if (this.state.value === 'three') {
                this.onAddHandlerThree()
              } else {
                this.onAddHandler()
              }
            }}
          >{t("Add")}</Button>
        </Box>
      </Box>
    )
  }

  serviceTemplateTab = () => {
    const { t } = this.props
    return (
      <Box component={'div'}>
        <Box component={'div'} margin='0.5vw'>
          {this.inputTextBox('serviceSearch', this.state.serviceSearch, "", 'Search Service package...', "", false)}
          {/* <Box component={'span'}
            onClick={() => { this.getServiceTemplateData() }}><SearchIcon className='eMed_search_icon' /></Box> */}
        </Box>
        <Box component={'div'} className='eMed_op_Bill_list'>
          {this.state.serviceTemplateData?.length > 0 ? this.state.serviceTemplateData.map((item, index) => (
            <Chip
              className='eMed_chip'
              size="small"
              label={item.package_name?.length > 15 ? <Tooltip placement='top' title={item?.package_name}><div style={{ fontSize: '0.7vw' }}>{item?.package_name?.slice(0, 15) + '...'}</div></Tooltip> : item?.package_name}
              variant={item.package_name === this.state.service ? 'contained' : 'outlined'}
              key={index}
              onClick={() => { this.onClickChipHandler(item) }}
              color={'primary'}
              clickable
            />
          )) : <Typography display={'flex'} justifyContent={'center'} position={'relative'} top={'4vw'}>{t("NoRecordsFound")}</Typography>}
        </Box>
        {this.rightBottomCard("Bill Date and Time", this.state.templateDate, "templateDate", this.state.serviceAmount)}
      </Box>
    )
  }

  serviceDescriptionTab = () => {
    const { t } = this.props
    return (
      <Box component={'div'}>
        <Box component={'div'} margin='0.5vw'>
          {this.inputTextBox('descriptionSerach', this.state.descriptionSerach, "", 'Search Service Description...', "", false)}
          {/* <Box component={'span'}
            onClick={() => { this.getServiceDescriptionData() }}><SearchIcon className='eMed_search_icon' /></Box> */}
        </Box>
        <Box component={'div'} className='eMed_bill_list'>
          <Box component={'div'} className='eMed_service_type'>
            {/* <Divider textAlign='left' sx={{ marginLeft: '0.5vw' }}>
                                <Typography component={'p'} className='eMed_Service_title'>Service Name</Typography></Divider> */}
            <Box sx={{ height: '7vw', overflow: 'scroll' }}>
              {this.state.serviceDescriptionData?.length > 0 ? this.state.serviceDescriptionData.map((item, index) => (
                <Chip
                  className='eMed_chip'
                  size="small"
                  label={item.service_type?.length > 15 ? <Tooltip placement='top' title={item.service_type}><div style={{ fontSize: '0.7vw' }}>{item.service_type?.slice(0, 15) + '...'}</div></Tooltip> : item.service_type}
                  variant={((item?.service_category === this.state.serviceCategory) && (item.service_type === this.state.description)) ? 'contained' : 'outlined'}
                  key={index}
                  onClick={() => {
                    let newDescription = []
                    item?.data?.forEach((item) => { newDescription.push(item) })
                    this.setState({
                      newDescription: newDescription,
                      dupNewDescription: JSON.stringify(newDescription),
                      description: item?.service_type,
                      newDescriptionName: "",
                      descriptionAmount: 0,
                      WardList: [],
                      serviceCategory: item?.service_category,
                      searchServiceName: ''
                    }, () => {
                      if (item?.service_type === "Consultation Other") {
                        this.setState({
                          descriptionData: item,
                          newDescriptionName: item?.service_type
                        })
                      }
                    })
                  }}
                  color={'primary'}
                />
              )) : <Typography display={'flex'} justifyContent={'center'} position={'relative'} top={'4vw'}>{t("NoRecordsFound")}</Typography>}
            </Box>
          </Box>
          {this.state.serviceDescriptionData?.length > 0 ?
            <Box component={'div'} className='eMed_service_type'>
              <Box component={'div'} className="eMed_Filter_Header" style={{ flexDirection: "row", justifyContent: "space-between" }}>
                <Typography marginLeft={"1vw"} >Service Names</Typography>
                {this.state?.WardList.length > 0 ?
                  <FormControl sx={{ width: "14.5vw", height: "2.5vw" }} size="small">
                    <InputLabel>{t("Ward Name")}</InputLabel>
                    <Select
                      value={this.state.selectedWard}
                      label={t("Ward Name")}
                      onChange={(e) => {
                        this.setState({
                          selectedWard: e.target.value
                        })
                      }}
                    >
                      {
                        this.state?.WardList?.map((list, index) => (
                          <MenuItem onClick={() => {
                            this.setState({ MinimumAmount: list?.minimum_amount, minimumTime: list?.minimum_time, descriptionAmount: list?.amount, wardDetails: list })
                          }} key={index} value={list.ward} >{list.ward}</MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl> : null}
              </Box>
              {this.state.description === "Consultation Other" ?
                <Box sx={{ height: '7vw', overflow: 'scroll', display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "space-between" }}>
                  <TextField
                    inputProps={{ maxLength: 50 }}
                    variant="standard"
                    sx={{ width: "15vw", marginLeft: "0.5vw", marginTop: "0.5vw" }}
                    size='small'
                    label={"Doctor Name"}
                    value={this.state.consultDocName ? this.state?.consultDocName : ""}
                    autoComplete="off"
                    onChange={(e) => {
                      var isSpecial = /^[ A-Za-z.,/]*$/.test(e.target.value)
                      if (e.target.value === "" || isSpecial) {
                        this.setState({
                          consultDocName: e.target.value,
                          newDescriptionName: "Consultation Other"
                        })
                      }
                    }}
                  />
                  <TextField
                    inputProps={{ maxLength: 50 }}
                    variant="standard"
                    sx={{ width: "15vw", marginLeft: "0.5vw", marginTop: "0.5vw" }}
                    size='small'
                    label={"Amount"}
                    value={this.state?.consultAmount ? this.state?.consultAmount : ""}
                    autoComplete="off"
                    onChange={(e) => {
                      let onlynumber = CommonValidation.numberOnly(e.target.value);
                      if (onlynumber || e.target.value === "") {
                        this.setState({
                          consultAmount: e.target.value,
                          descriptionAmount: e.target.value,
                          serviceAmount: e.target.value,
                        })
                      }
                    }}
                  />
                </Box> :
                <Box sx={{ height: '7vw', overflow: 'scroll' }}>
                  {this.state.description ?
                    <Box component={'div'}>
                      <TextField
                        size='small'
                        inputProps={{ style: { height: '', fontSize: '0.8vw' } }}
                        sx={{ m: '0.5vw', width: '20vw' }}
                        placeholder='Search Service Name'
                        value={this.state.searchServiceName}
                        onChange={(e) => {
                          this.setState({ searchServiceName: e.target.value }, () => {
                            let dupNewDescription = JSON.parse(this.state.dupNewDescription)
                            let filterServiceName = dupNewDescription?.filter((item) => {
                              let itemServiceName = CommonValidation.removeSpace(item?.service_name)
                              let searchServiceName = CommonValidation.removeSpace(this.state.searchServiceName)
                              return (
                                searchServiceName ?
                                  itemServiceName === null ? null : itemServiceName?.toLowerCase()?.includes(searchServiceName?.toLowerCase()) :
                                  item
                              )
                            })
                            this.setState({ newDescription: filterServiceName }, () => this.setState({ newDescriptionName: '' }))
                          })
                        }}
                      />
                    </Box> : null}
                  {this.state.newDescription.map((item, index) => (
                    <Chip
                      className='eMed_chip'
                      size="small"
                      label={item.service_name?.length > 15 ? <Tooltip placement='top' title={item.service_name}><div style={{ fontSize: '0.7vw' }}>{item.service_name?.slice(0, 15) + '...'}</div></Tooltip> : item.service_name}
                      variant={item.service_name === this.state.newDescriptionName ? 'contained' : 'outlined'}
                      key={index}
                      onClick={() => { this.onClickChipHandler(item) }}
                      color={'primary'}
                    />
                  ))}
                </Box>}
            </Box> : null}
        </Box>
        {/* {this.rightBottomCard("Bill Date and Time", this.state.descripDate, "descripDate", (this.state.MinimumAmount ? this.state.MinimumAmount : this.state.descriptionAmount))} */}
        {this.rightBottomCard("Bill Date and Time", this.state.descripDate, "descripDate", (this.state.descriptionAmount))}
      </Box>
    )
  }

  handleDelete = () => {
    this.state.removable_ids.push(this.state.billingList[this.state.deleteIndex].line_item_id)
    this.state.billingList.splice(this.state.deleteIndex, 1)
    // this.state.billingList.splice(this.state.deleteIndex, 1).forEach(element => {
    //   deletedIds.push(element.id)
    // })
    this.state.serviceList.splice(this.state.deleteIndex, 1)
    let decIndex = this.state.serviceList?.length > 0 ? this.state.serviceList?.length : 0
    this.state.descriptionList.splice(this.state.deleteIndex - decIndex, 1)
    this.setState({
      billingList: this.state.billingList,
      serviceList: this.state.serviceList,
      descriptionList: this.state.descriptionList,
      isDeleteCliked: false,
      deleteIndex: null,
      deleteService: "",
      description: "",
      totalServiceAmount: this.state.billingList.length === 0 ? 0 : this.state.totalServiceAmount,
      newDescriptionName: "",
      service: "",
      serviceAmount: 0,
      descriptionAmount: 0,
      selectedServiceCode: null,
      serviceCodeText: '',
      serviceNameCodeText: '',
      serviceTypeCodeText: '',
      serviceCodeAmt: 0,
      selectedServiceCodeWard: null,
      serviceCodeData: null,
      selectedServiceCodeText: '',
      selectedServiceNameCodeText: '',
      selectedServiceTypeCodeText: '',
      packageCodeText: '',
      selectedPackageCodeText: ''
      // deletedIds:this.state.deletedIds.concat(deletedIds)
    }, () => {
      // this.calculateTotalDiscount()
      this.setState({
        totalDiscountedAmount: '',
        totalDiscountedPercentage: ''
      })
    })
  }

  popupClose = (data) => {
    if (data) {
      this.setState({
        isDeleteAll: false
      })
    }
    this.setState({
      isDeleteCliked: false,
      deleteIndex: null,
      deleteService: ""
    })
  }

  serviceCodeTab = () => {
    try {
      return (
        <Box component={'div'}>
          <Box component={'div'} height={'38dvh'}>
            <Box component={'div'} p={'0.5vw'} borderBottom={'2px solid lightgray'}>
              <Autocomplete
                size='small'
                sx={{ width: '25vw' }}
                options={this.state.serviceCodeDataList}
                getOptionLabel={(option) => typeof (option) === 'string' ? option : `${option.ip_service_code ? option?.ip_service_code : option?.package_service_code ? option?.package_service_code : '-'} ${option?.package_name ? ` - ${option?.package_name}` : ` - ${option.service_type} - ${option.service_name}`}`}
                value={this.state.serviceCodeData}
                onChange={(e, value) => { this.setState({ selectedWard: '', WardList: [] }, () => this.onClickChipHandler(value, true)) }}
                renderInput={(params) => <TextField {...params} placeholder='Search Service Code...' InputProps={{ ...params.InputProps, emed_tid: 'srv_code_srh' }} />}
              />
              <Box component={'div'}>
                {this.state.frequentServiceCodeList?.length > 0 ? this.state.frequentServiceCodeList?.map((item, index) => (
                  <Chip
                    className='eMed_chip'
                    label={item.ip_service_code ? item?.ip_service_code : item?.package_service_code}
                    size="small"
                    key={index}
                    onClick={() => { this.setState({ selectedWard: '', WardList: [] }, () => this.onClickChipHandler(item)) }}
                    variant={(((item.ip_service_code ? item.ip_service_code : item?.package_service_code) != this.state.serviceCodeText)) ? 'outlined' : 'contained'}
                    color={'primary'}
                  />
                )) : null}
              </Box>
            </Box>
            {this.state.selectedPackageCodeText || this.state.packageCodeText ?
              <Box component={'div'} pl={'0.5vw'}>
                <Box component={'div'} sx={{ backgroundColor: Colors.Background }} justifyContent={'space-between'} height={'4dvh'} display={'flex'} alignItems={'center'}>
                  <Typography fontSize={'0.9vw'} fontWeight={600}>Package Details</Typography>
                </Box>
                <Box component={'div'}>
                  <Box component={'div'} display={'flex'}>
                    <Typography fontSize={'0.9vw'} fontWeight={600} flex={'0.25'}>Package Code :</Typography>
                    <Typography flex={'0.75'} fontSize={'0.9vw'} fontWeight={600}>{this.state.serviceCodeText ? this.state.serviceCodeText : this.state.selectedServiceCodeText}</Typography>
                  </Box>
                  <Box component={'div'} display={'flex'}>
                    <Typography fontSize={'0.9vw'} fontWeight={600} flex={'0.25'}>Package Name :</Typography>
                    <Typography flex={'0.75'} fontSize={'0.9vw'} fontWeight={600}>{this.state.packageCodeText ? this.state.packageCodeText : this.state.selectedPackageCodeText}</Typography>
                  </Box>
                </Box>
              </Box> :
              // this.state.serviceCodeText || this.state.selectedServiceCodeText ?
                <Box component={'div'} pl={'0.5vw'}>
                  <Box component={'div'} sx={{ backgroundColor: Colors.Background }} p={'0vw 0.5vw'} height={'7dvh'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography fontSize={'0.9vw'} fontWeight={600}>Service Details</Typography>
                    {this.state?.WardList.length > 0 ?
                      <FormControl size='small'>
                        <InputLabel>Ward Name</InputLabel>
                        <Select
                          sx={{ width: "14.5vw", height: '2.3vw', fontSize: '0.9vw' }}
                          value={this.state.selectedWard}
                          onChange={(e) => {
                            this.setState({
                              selectedWard: e.target.value
                            })
                          }}
                          label='Ward Name'
                        >
                          {
                            this.state?.WardList?.map((list, index) => (
                              <MenuItem onClick={() => {
                                this.setState({ MinimumAmount: list?.minimum_amount, minimumTime: list?.minimum_time, serviceCodeAmt: list?.amount, wardDetails: list })
                              }} key={index} value={list.ward} >{list.ward}</MenuItem>
                            ))
                          }
                        </Select>
                      </FormControl>
                      : null}
                  </Box>
                  <Box component={'div'}>
                    <Box component={'div'} display={'flex'}>
                      <Typography fontSize={'0.9vw'} fontWeight={600} flex={'0.25'}>Service Code :</Typography>
                      <Typography flex={'0.75'} fontSize={'0.9vw'} fontWeight={600}>{this.state.serviceCodeText ? this.state.serviceCodeText : this.state.selectedServiceCodeText}</Typography>
                    </Box>
                    <Box component={'div'} display={'flex'}>
                      <Typography fontSize={'0.9vw'} fontWeight={600} flex={'0.25'}>Service Type :</Typography>
                      <Typography flex={'0.75'} fontSize={'0.9vw'} fontWeight={600}>{this.state.serviceTypeCodeText ? this.state.serviceTypeCodeText : this.state.selectedServiceTypeCodeText}</Typography>
                    </Box>
                    <Box component={'div'} display={'flex'}>
                      <Typography fontSize={'0.9vw'} fontWeight={600} flex={'0.25'}>Service Name :</Typography>
                      <Typography flex={'0.75'} fontSize={'0.9vw'} fontWeight={600}>{this.state.serviceNameCodeText ? this.state.serviceNameCodeText : this.state.selectedServiceNameCodeText}</Typography>
                    </Box>
                  </Box>
                </Box>}
          </Box>
          {this.rightBottomCard("Bill Date and Time", this.state.templateDate, "templateDate", this.state.serviceCodeAmt)}
        </Box>
      )
    } catch (error) {
      this.successErrorMessgae(error.message, 'error')
    }
  }

  renderRightContent = () => {
    const { t } = this.props
    return (
      <Box component={'div'} className='eMed_bill_right_card'>
        <Grid xs={12} item className='eMed_right_grid_one'>
          <Tabs
            value={this.state.value}
            onChange={(e, newValue) => this.setState({
              value: newValue,
              service: "",
              serviceAmount: 0,
              newDescriptionName: "",
              description: "",
              descriptionAmount: 0,
              descriptionSerach: "",
              serviceSearch: "",
              WardList: [],
              selectedServiceCode: null,
              serviceCodeText: '',
              serviceNameCodeText: '',
              serviceTypeCodeText: '',
              serviceCodeAmt: 0,
              selectedServiceCodeWard: null,
              serviceCodeData: null,
              selectedServiceCodeText: '',
              selectedServiceNameCodeText: '',
              selectedServiceTypeCodeText: '',
              packageCodeText: '',
              selectedPackageCodeText: ''
            }, () => {
              // this.getServiceDescriptionData()
              // this.getServiceTemplateData()
            })}
            sx={{ backgroundColor: Colors.DataTblHeaderbg }}
            className='eMed_bill_Tab'
          >
            <Tab value="one" label="Service Package" className='eMed_tab_header eMed_bill_Tab' />
            <Tab value="two" label="Service Description" className='eMed_tab_header eMed_bill_Tab' />
            {this.state.showCode ?
              <Tab value="three" label="Service Code" className='eMed_tab_header eMed_bill_Tab' /> : null
            }
          </Tabs>
          {this.state.value === "one" ? this.serviceTemplateTab() : this.state.value === "two" ? this.serviceDescriptionTab() : this.state.value === 'three' ? this.serviceCodeTab() : ""}
        </Grid>
      </Box>
    )
  }

  lodaerFunction = (key) => {
    this.setState({
      isLoader: key,
      isButtonDisabled: key
    }, () => {
      setTimeout(() => {
        this.setState({
          isLoader: false,
          isButtonDisabled: false
        })
      }, 2000);
    })
  }

  customFooter = (params) => {
    const { t } = this.props
    // let { patientData } = this.state
    let disable = this.state.billingList.length > 0 ? false : true
    var states = this.state
    var RemainAmt = +(states.totalPayableAmount) - +(states.totalDeductedAmount)

    // let creditPopup = false
    // let ins_name = patientData?.insurance_name ? patientData?.insurance_name : this.state.selInsName
    // let ins_no = patientData?.policy_no ? patientData?.policy_no : this.state.selPolicyNo
    // let comp_name = patientData?.employer_name ? patientData?.employer_name : this.state.selCompanyName
    // let employee_no = patientData?.id_no ? patientData?.id_no : patientData?.employee_id ? patientData?.employee_id : this.state.selEmployeeNo

    // if(patientData?.is_billed === false){
    //     if(!comp_name && !ins_name){
    //         creditPopup = true

    // }else if(this.state.paymentCreditType !== 'insurancecredit' && ins_name){
    //     creditPopup = false
    //     patientData['is_billed'] = true
    //     this.setState({
    //         paymentCreditType: 'insurancecredit',
    //         selPayemtnCreditType: 'insurancecredit',
    //         selInsName: ins_name,
    //         selPolicyNo: ins_no,
    //         patientData: patientData,
    //     })

    //     }else if(this.state.paymentCreditType === 'patientcredit' && comp_name){
    //         creditPopup = false
    //         patientData['is_billed'] = true
    //         this.setState({
    //             paymentCreditType: 'corporatecredit',
    //             selPayemtnCreditType: 'corporatecredit',
    //             selCompanyName: comp_name,
    //             selEmployeeNo: employee_no,
    //             selCompanyID : this.state.selCompanyID ? this.state.selCompanyID : patientData?.employer_id ? patientData?.employer_id : '',
    //             patientData: patientData,
    //         })
    //     }
    // }else{
    //   if((this.state.paymentCreditType === "insurancecredit" && !ins_name) || (this.state.paymentCreditType === "corporatecredit" && !comp_name)){
    //     this.setState({
    //       paymentCreditType: 'patientcredit',
    //       selPayemtnCreditType: 'patientcredit',
    //     })
    //   }
    // }
    return (
      <Box component={'div'} className='eMed_table_footer'>
        <Box component={'span'} className='eMed_bill_footer_left'>
          <Box component={'div'} className='eMed_bill_tbl_footer_IP'>
            <Typography mt={0.5} sx={{ color: Colors.SecondaryText, fontSize: "0.9vw" }}>{t("Gross Amount")}</Typography>
            <Typography mt={0.5} mr={'0.8vw'}>{this.billingCalculation(params, 'newAmount')}</Typography>
          </Box>
          <Box component={'div'} className='eMed_bill_tbl_footer_IP'>
            <Typography mt={0.5} sx={{ color: Colors.SecondaryText, fontSize: "0.9vw" }}>{t("Service Concession Amount")}</Typography>
            <Typography mt={0.5} mr={'0.8vw'}>{this.billingCalculation(params, 'discountAmount')}</Typography>
          </Box>
          <Box component={'div'} className='eMed_bill_tbl_footer_IP'>
            <Typography mt={0.5} sx={{ color: Colors.SecondaryText, fontSize: "0.9vw" }}>{t("SubAmount")}</Typography>
            <Typography mt={0.5} mr={'0.8vw'}>{this.billingCalculation(params, 'totalServiceAmount')}</Typography>
          </Box>
          <Box component={'div'} className='eMed_bill_tbl_footer_IP' sx={{ width: "24vw" }}>
            <Typography mt={0.5} sx={{ color: Colors.SecondaryText, fontSize: "0.9vw" }}>{t("concession")}</Typography>
            {this.inputTextBox('totalDiscountedPercentage', this.state.totalDiscountedPercentage, params, "0", "0.7vw", disable)}
            {this.inputTextBox('totalDiscountedAmount', this.state.totalDiscountedAmount, params, "0.00", "0.7vw", disable)}
            {this.state.totalDiscountedPercentage || this.state.totalDiscountedAmount ?
              <Tooltip placement='top' title={this.state.discountReason ? this.state.discountReason : this.state.savedDiscountReason}>
                <Box onClick={() => this.setState({ discountReasonPopup: this.state.ForCancel ? false : true, discountReason: this.state.discountReason ? this.state.discountReason : this.state.savedDiscountReason })} component={'img'} src={ImagePaths.LabReportOff.default} width={'1.5vw'} height={'1.5vw'} sx={{ cursor: 'pointer' }} alignSelf={'center'} />
              </Tooltip> :
              <Box component={'img'} src={ImagePaths.BillTransUser.default} width={'1vw'} height={'1vw'} alignSelf={'center'} />
            }
            {+this.state.billConcessionType === 1 ? null :
              <Tooltip placement='top' title={`Maximum concession should be ${CurrencySymbol} ${this.state.concessionRestrictAmt}`}>
                <ErrorOutlineIcon color='primary' sx={{ height: '1.3vw', width: '1.3vw' }} />
              </Tooltip>
            }
          </Box>
          <Box component={'div'} className='eMed_bill_tbl_footer_IP'>
            <Typography mt={0.5} sx={{ color: Colors.SecondaryText, fontSize: "0.9vw" }}>{t("RoundOff")}</Typography>
            <Typography mt={0.5} mr={'0.8vw'}>
              {this.billingCalculation(params, 'roundOff')}
            </Typography>
          </Box>
          <Box component={'div'} className='eMed_bill_tbl_footer_IP'>
            <Typography mt={0.5} sx={{ color: Colors.SecondaryText, fontSize: "0.9vw" }}>{t("Net Amount")}</Typography>
            <Typography mt={0.5} mr={'0.8vw'}>
              {AmountFormat(this.state.totalPayableAmount ? this.state.totalPayableAmount : 0)}
            </Typography>
          </Box>

        </Box>
        <Box component={'span'} className='eMed_bill_footer_right'>
          <Box component={'div'} position={'relative'} left={"8vw"}>
            {(this.state?.ForCancel || this.state.isForEditBill) ? null :
              <Box component={'div'} className='eMed_bill_tbl_footer'>
                <Box component={'div'} className='emedhub_toolbar_button'>
                  <Checkbox
                    sx={{ mr: 0.3, mt: 0.5 }}
                    disabled={(states.advance_amount) == 0 || this.state.billingList.length === 0}
                    size='small'
                    checked={states.advCheck}
                    onClick={() => {
                      if (!states.advCheck) {
                        var PayAmt = 0
                        if ((states.advance_amount) >= (states.totalPayableAmount)) {
                          PayAmt = +(states.totalPayableAmount)
                        } else {
                          PayAmt = +(states.advance_amount)
                        }

                        this.setState({
                          totalDeductedAmount: PayAmt ? PayAmt : 0
                        })
                      } else {
                        this.setState({
                          totalDeductedAmount: 0
                        })
                      }

                      this.setState({
                        advCheck: !states.advCheck
                      })
                    }}
                  />
                  <Typography mt={0.5} sx={{ color: Colors.SecondaryText, fontSize: "0.9vw" }}>{t("DedctfrmAdv")}</Typography>
                </Box>
                <Typography mt={0.5} mr={'0.8vw'}>{AmountFormat(states.advance_amount || 0)}</Typography>
              </Box>
            }
            <Box component={'div'} className='eMed_bill_tbl_footer'>
              <Typography mt={0.5} sx={{ color: Colors.SecondaryText, fontSize: "0.9vw" }} fontWeight='600'>{this.state?.ForCancel ? "Refundable Amount" : t("PayableAmount")}</Typography>
              <Typography mt={0.5} mr={'0.8vw'} fontWeight='600'>
                {this.billingCalculation(params, 'payableAmount')}
              </Typography>
            </Box>

          </Box>
          <Box component={'div'} position={'relative'} left={"8vw"} marginTop={'1vw'}>
            {states?.paymentCreditType === 'patientcredit' ? null :
              states?.paymentCreditType === 'insurancecredit' ?
                <Box component={'div'} sx={{ height: '2.5vw', width: '22vw', }}>
                  <Typography fontSize={'0.6vw'} color={'#616161'} fontWeight={'bold'}>{`( Insurance:  ${states.selInsName ? states.selInsName : states?.patientData?.insurance_name ? states?.patientData?.insurance_name : '-'}`}</Typography>
                  <Typography fontSize={'0.6vw'} color={'#616161'} fontWeight={'bold'}>{`Policy No:${states.selPolicyNo ? states.selPolicyNo : states?.patientData?.policy_no ? states?.patientData?.policy_no : '-'} )`}</Typography>
                </Box> :
                states?.paymentCreditType === 'corporatecredit' ?
                  <Box component={'div'} sx={{ height: '2.5vw', width: '22vw', }}>
                    <Typography fontSize={'0.6vw'} color={'#616161'} fontWeight={'bold'}>{`( Corporate : ${states.selCompanyName ? states.selCompanyName : states?.patientData?.employer_name ? states?.patientData?.employer_name : '-'}`}</Typography>
                    <Typography fontSize={'0.6vw'} color={'#616161'} fontWeight={'bold'}>{`Employee No: ${states.selEmployeeNo ? states.selEmployeeNo : states?.patientData?.id_no ? states?.patientData?.id_no : '-'} )`}</Typography>
                  </Box> : null}
          </Box>
          {
            CheckAccessFunc("front_office", "In Patients", "Service Billing", "Create Bill", "SubTab")?.editAccess && !this.state.ForCancel && !this.state.isForEditBill ?
              <Box component={'div'} display={'flex'} justifyContent="flex-end" >
                <Tooltip title={'Change Credit Type'} placement='top'>
                  <IconButton size='small'
                    disabled={(this.state.billingList?.length === 0 || this.state.withOutAppointment?.is_ip || this.state.isButtonDisabled) || states?.per_access?.ip?.is_credit === false || (this.state.advCheck && RemainAmt == 0)}
                    onClick={() => {
                      this.setState({
                        creditChange: true,
                      }, () => {
                        this.getInsuranceDetails()
                        this.getCorporateDetails()
                      })
                    }}>
                    <img style={{ width: '1.2vw', height: '1.2vw' }} src={(this.state.billingList?.length === 0 || this.state.withOutAppointment?.is_ip || this.state.isButtonDisabled) || states?.per_access?.ip?.is_credit === false || (this.state.advCheck && RemainAmt == 0) ? ImagePaths.outlinedEditDis.default : ImagePaths.ChangeCredit.default} alt='icon' />
                  </IconButton>
                </Tooltip>
                <Button
                  variant='outlined'
                  size='small'
                  className='eMed_bill_btn_one'
                  disabled={this.state.totalPayableAmount === 0 ? true : (this.state.billingList?.length === 0 || this.state.withOutAppointment?.is_ip || this.state.isButtonDisabled) || states?.per_access?.ip?.is_credit === false || (this.state.advCheck && RemainAmt == 0) || this.state.DisableBtn}
                  onClick={() => {
                    this.setState({
                      DisableBtn: true
                    }, () => {
                      if (this.state.billingList.length > 0 && this.state.patientData?.is_billed) {
                        this.postCreditBill()
                      } else {
                        if (this.state.billingList.length === 0) {
                          this.successErrorMessgae("No Service has added", 'error')
                        } else if (this.state.patientData?.is_billed === false) {
                          this.setState({
                            creditChange: true
                          }, () => {
                            this.getInsuranceDetails()
                            this.getCorporateDetails()
                          })
                        }
                      }
                    })
                  }}
                >{t("AddtoCreditBill")}</Button>
                <Button
                  variant='outlined'
                  size='small'
                  className='eMed_bill_btn_one'
                  disabled={this.state.billingList.length === 0 || this.state.withOutAppointment?.is_ip || this.state.isButtonDisabled || this.state.advCheck || this.state.disable || this.state.DisableBtn}
                  onClick={() => {
                    this.setState({
                      IpSavedBill: true,
                      DisableBtn: true
                    }, () => { this.postCreditBill() })
                  }}
                >
                  {t("Save As Draft")}</Button>
                <Button variant='contained' size='small'
                  className='eMed_bill_btn_one'
                  disabled={this.state.billingList?.length === 0 || (this.state.withOutAppointment?.is_ip || this.state.isButtonDisabled) || this.state.DisableBtn}
                  onClick={() => {
                    if (this.state.advCheck && RemainAmt == 0) {
                      this.setState({
                        IpCompleteBill: true,
                        DisableBtn: true
                      }, () => { this.postCreditBill() })
                    } else {
                      this.onClickReceiveAndCreditPayment()
                    }
                  }}
                >{(this.state.advCheck && RemainAmt == 0) ? t("CompleteBill") : t("ReceivePayment")}</Button>
              </Box> : null}
          {this.state.ForCancel ?
            <Box component={'div'} style={{ padding: "1vw 1vw", alignItems: 'center', display: 'flex', flexDirection: 'row-reverse' }}>
              {/* <Typography>{(+this.state.CancelledNetAmount > 0 && +this.state.OutstandingAmount > 0) ? ` ₹ ${this.state?.CancelledNetAmount ? (this.state?.CancelledNetAmount < this.state?.OutstandingAmount ? this.state?.CancelledNetAmount : this.state?.OutstandingAmount) : 0} will be deducted from Outstanding Amount` : ""}</Typography> */}
              <Button
                variant='contained'
                sx={{ textTransform: "capitalize", width: '10vw' }}
                size='small'
                color='error'
                onClick={() => {
                  this.setState({
                    cancelBillClicked: true
                  })
                }}
              >
                {t("CancelBill")}
              </Button>
            </Box> : null}
          {this.state.isForEditBill ?
            <Box component={'div'} style={{ padding: "1vw 1vw", alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
              <Typography fontWeight={600}>{`Invoice Number : ${this.state.CancelledBillNumber}`}</Typography>
              <Button
                variant='contained'
                sx={{ textTransform: "capitalize", width: '10vw' }}
                size='small'
                onClick={() => { this.setState({ DisableBtn: true }, () => { this.postCreditBill() }) }}
                disabled={this.state.billingList?.length === 0 || this.state.DisableBtn}
              >
                {t("Save Changes")}
              </Button>
            </Box> : null}
        </Box>
      </Box>
    )
  }

  discountReasonPopup = () => {
    try {
      return (
        <Modal open={this.state.discountReasonPopup}>
          <Box className='eMed_Discount_Reason'>
            <Box component={'div'} height={'2vw'} display={'flex'} m={'1vw'} justifyContent={'space-between'}>
              <Typography color={Colors.SecondaryText} fontWeight={600}>Discount Reason</Typography>
            </Box>
            <Box m={'1vw'}>
              <TextField
                fullWidth
                multiline
                label='Reason *'
                rows={3}
                value={this.state.discountReason}
                onChange={(e) => {
                  this.setState({ discountReason: e.target.value })
                }}
              />
            </Box>
            <Box m={'1vw'} display={'flex'} justifyContent={'flex-end'}>
              <Button
                variant='outlined'
                sx={{ width: '5vw', height: '2vw', mr: '1vw' }}
                onClick={() => {
                  this.setState({ discountReasonPopup: false, discountReason: '' })
                }}
              >Close</Button>
              <Button
                variant='contained'
                sx={{ width: '5vw', height: '2vw' }}
                onClick={() => {
                  if (this.state.discountReason !== '') {
                    this.setState({ discountReasonPopup: false })
                  } else {
                    this.successErrorMessgae("Reason is mandatory", 'error')
                  }
                }}
              >Save</Button>
            </Box>
          </Box>
        </Modal>
      )
    } catch (error) {
      this.successErrorMessgae(error.message, 'error')
    }
  }


  feeValue = () => {
    try {
      let doctorFee = 0; let hospitalFee = 0;
      let duplicateSelectedValue = JSON.parse(this.state.dupliSelectedData)
      let ipServiceFee = duplicateSelectedValue?.ip_service
      doctorFee = +ipServiceFee?.original_doctor_fee
      hospitalFee = +ipServiceFee?.original_hospital_fee

      return { doctorFee, hospitalFee };
    } catch (error) {
      this.successErrorMessgae(error.message, 'error')
    }
  }

  onChangeDiscountHandler = (e, names) => {
    try {
      let value = e.target.value
      let fee = this.feeValue()
      switch (names) {
        case 'discountDoctorFee':
          if (this.state.selectedType === 'rupee') {
            if ((CommonValidation.NumberWithDot(value) && +value <= fee.doctorFee) || value === '') {
              this.state.discountPercentDoctorFee = CommonValidation.amountPercentage(+value, fee.doctorFee)
              this.setState({ discountDoctorFee: +value }, () => {
                let ipServiceFee = this.state.selectedData?.ip_service
                ipServiceFee.discount_doctor_fee = value ? value : 0
                if (!ipServiceFee.discount_hospital_fee && !ipServiceFee.discount_doctor_fee) {
                  this.setState({
                    IndivDiscountComments: ""
                  })
                }
                this.setState({ selectedData: this.state.selectedData })
              })
            }
          } else {
            if ((CommonValidation.NumberWithDot(value) && +value <= 100) || value === '') {
              this.state.discountDoctorFee = CommonValidation.calculatePercentage(+value, fee.doctorFee)
              this.setState({ discountPercentDoctorFee: +value }, () => {
                let ipServiceFee = this.state.selectedData?.ip_service
                ipServiceFee.discount_doctor_fee = value ? CommonValidation.calculatePercentage(value, fee.doctorFee) : 0
                if (!ipServiceFee.discount_hospital_fee && !ipServiceFee.discount_doctor_fee) {
                  this.setState({
                    IndivDiscountComments: ""
                  })
                }
                this.setState({ selectedData: this.state.selectedData })
              })
            }
          }
          break;
        case 'discountHospitalFee':
          if (this.state.selectedType === 'rupee') {
            if ((CommonValidation.NumberWithDot(value) && +value <= fee.hospitalFee) || value === '') {
              this.state.discountPercentHospitalFee = CommonValidation.amountPercentage(+value, fee.hospitalFee)
              this.setState({ discountHospitalFee: +value }, () => {
                let ipServiceFee = this.state.selectedData?.ip_service
                ipServiceFee.discount_hospital_fee = value ? value : 0
                if (!ipServiceFee.discount_hospital_fee && !ipServiceFee.discount_doctor_fee) {
                  this.setState({
                    IndivDiscountComments: ""
                  })
                }
                this.setState({ selectedData: this.state.selectedData })
              })
            }
          } else {
            if ((CommonValidation.NumberWithDot(value) && +value <= 100) || value === '') {
              this.state.discountHospitalFee = CommonValidation.calculatePercentage(+value, fee.hospitalFee)
              this.setState({ discountPercentHospitalFee: +value }, () => {
                let ipServiceFee = this.state.selectedData?.ip_service
                ipServiceFee.discount_hospital_fee = value ? CommonValidation.calculatePercentage(value, fee.hospitalFee) : 0
                if (!ipServiceFee.discount_hospital_fee && !ipServiceFee.discount_doctor_fee) {
                  this.setState({
                    IndivDiscountComments: ""
                  })
                }
                this.setState({ selectedData: this.state.selectedData })
              })
            }
          }
          break;
        default: break;
      }
    } catch (error) {
      this.successErrorMessgae(error.message)
    }
  }

  splitUpTextBox = (names, values, label, width) => {
    try {
      return (
        <TextField
          size='small'
          sx={{ width: width }}
          value={values}
          label={label}
          autoComplete='off'
          onChange={(e) => this.onChangeDiscountHandler(e, names)}
          InputProps={{
            endAdornment: this.state.selectedType === 'rupee' ?
              // <CurrencyRupee color='primary'></CurrencyRupee> :
              CurrencySymbol :
              <PercentIcon color='primary' />
          }}
        />
      )
    } catch (error) {
      this.successErrorMessgae(error.message, 'error')
    }
  }

  shareValue = () => {
    try {
      let states = this.state
      let doctorFee = 0; let hospitalFee = 0; let wardName = '';
      let discountDoctorFee = 0; let discountHospitalFee = 0;
      let ipServiceFee = states.selectedData?.ip_service
      doctorFee = CommonValidation.formatAmount(ipServiceFee?.original_doctor_fee)
      hospitalFee = CommonValidation.formatAmount(ipServiceFee?.original_hospital_fee)
      wardName = ipServiceFee?.selected_ward_name
      discountDoctorFee = CommonValidation.formatAmount(ipServiceFee?.discount_doctor_fee)
      discountHospitalFee = CommonValidation.formatAmount(ipServiceFee?.discount_hospital_fee)
      return { doctorFee, hospitalFee, wardName, discountDoctorFee, discountHospitalFee };
    } catch (error) {
      this.successErrorMessgae(error.message, 'error')
    }
  }

  onSaveDiscountAmount = () => {
    try {
      let states = this.state
      let doctorFee = this.state.discountDoctorFee ? this.state.discountDoctorFee : 0
      let hospitalFee = this.state.discountHospitalFee ? this.state.discountHospitalFee : 0
      let totalDiscountAmount = (+doctorFee) + (+hospitalFee)
      let newDiscount = 0; let totalAmount = 0;
      let ipServiceFee = states.selectedData?.ip_service

      let feeDoctor = this.state.discountPercentDoctorFee ? this.state.discountPercentDoctorFee : ipServiceFee?.discount_doctor_fee ? ipServiceFee?.discount_doctor_fee : 0
      let feeHospital = this.state.discountPercentHospitalFee ? this.state.discountPercentHospitalFee : ipServiceFee?.discount_hospital_fee ? ipServiceFee?.discount_hospital_percentage : 0

      // if (feeDoctor !== 0 && feeHospital !== 0) {
      let doctorAmount = CommonValidation.calculatePercentage(+states.discountPercentDoctorFee, ipServiceFee?.original_doctor_fee)
      let hospitalAmount = CommonValidation.calculatePercentage(+states.discountPercentHospitalFee, ipServiceFee?.original_hospital_fee)

      totalAmount = +ipServiceFee?.original_doctor_fee + +ipServiceFee?.original_hospital_fee
      newDiscount = doctorAmount + hospitalAmount
      let doctorPercentage = states.discountPercentDoctorFee ? +states.discountPercentDoctorFee : 0
      let hospitalPercentage = states.discountPercentHospitalFee ? +states.discountPercentHospitalFee : 0
      let discountPercent = CommonValidation.amountPercentage(newDiscount, totalAmount)
      states.selectedData.discountAmount = this.state.selectedType === 'rupee' ? (CommonValidation.formatAmount(+doctorFee) + CommonValidation.formatAmount(+hospitalFee)) : CommonValidation.formatAmount(newDiscount)
      states.selectedData.discountPercentage = this.state.selectedType === 'rupee' ? CommonValidation.amountPercentage(totalDiscountAmount, +states.selectedData?.amount) : CommonValidation.formatAmount(discountPercent)

      // } else if (feeDoctor !== 0 && feeHospital === 0) {
      //   newDiscount = +feeDoctor
      //   totalAmount = +ipServiceFee?.original_doctor_fee + +ipServiceFee?.original_hospital_fee
      //   let discountPercentAmount = CommonValidation.calculatePercentage(newDiscount, +totalAmount)
      //   let doctorPercentage = states.discountPercentDoctorFee ? +states.discountPercentDoctorFee : 0
      //   let hospitalPercentage = states.discountPercentHospitalFee ? +states.discountPercentHospitalFee : 0
      //   let discountPercent = doctorPercentage + hospitalPercentage
      //   states.selectedData.discountAmount = this.state.selectedType === 'rupee' ? (CommonValidation.formatAmount(+doctorFee) + CommonValidation.formatAmount(+hospitalFee)) : CommonValidation.formatAmount(discountPercentAmount)
      //   states.selectedData.discountPercentage = this.state.selectedType === 'rupee' ? CommonValidation.amountPercentage(totalDiscountAmount, +states.selectedData?.amount) : CommonValidation.formatAmount(discountPercent)

      // } else {
      //   newDiscount = feeHospital
      //   totalAmount = +ipServiceFee?.original_doctor_fee + +ipServiceFee?.original_hospital_fee
      //   let discountPercentAmount = CommonValidation.calculatePercentage(newDiscount, +totalAmount)
      //   let doctorPercentage = states.discountPercentDoctorFee ? +states.discountPercentDoctorFee : 0
      //   let hospitalPercentage = states.discountPercentHospitalFee ? +states.discountPercentHospitalFee : 0
      //   let discountPercent = doctorPercentage + hospitalPercentage
      //   states.selectedData.discountAmount = this.state.selectedType === 'rupee' ? (CommonValidation.formatAmount(+doctorFee) + CommonValidation.formatAmount(+hospitalFee)) : CommonValidation.formatAmount(discountPercentAmount)
      //   states.selectedData.discountPercentage = this.state.selectedType === 'rupee' ? CommonValidation.amountPercentage(totalDiscountAmount, +states.selectedData?.amount) : CommonValidation.formatAmount(discountPercent)

      // }
      ipServiceFee.discount_doctor_fee = this.state.selectedType === 'rupee' ? +doctorFee : CommonValidation.calculatePercentage(this.state.discountPercentDoctorFee, ipServiceFee?.original_doctor_fee)
      ipServiceFee.discount_hospital_fee = this.state.selectedType === 'rupee' ? +hospitalFee : CommonValidation.calculatePercentage(this.state.discountPercentHospitalFee, ipServiceFee?.original_hospital_fee)
      ipServiceFee.discount_doctor_percentage = this.state.selectedType === 'rupee' ? CommonValidation.amountPercentage(this.state.discountDoctorFee ? +this.state.discountDoctorFee : 0, ipServiceFee?.original_doctor_fee) : this.state.discountPercentDoctorFee ? +this.state.discountPercentDoctorFee : 0
      ipServiceFee.discount_hospital_percentage = this.state.selectedType === 'rupee' ? CommonValidation.amountPercentage(this.state.discountHospitalFee ? +this.state.discountHospitalFee : 0, ipServiceFee?.original_hospital_fee) : this.state.discountPercentHospitalFee ? +this.state.discountPercentHospitalFee : 0
      ipServiceFee.original_doctor_fee = +ipServiceFee.original_doctor_fee
      ipServiceFee.original_hospital_fee = +ipServiceFee.original_hospital_fee
      ipServiceFee.doctor_fee = CommonValidation.formatAmount((+ipServiceFee.original_doctor_fee) - (ipServiceFee.discount_doctor_fee ? ipServiceFee.discount_doctor_fee : +doctorFee))
      ipServiceFee.hospital_fee = CommonValidation.formatAmount((+ipServiceFee.original_hospital_fee) - (ipServiceFee.discount_hospital_fee ? ipServiceFee.discount_hospital_fee : +hospitalFee))
      // ipServiceFee.doctor_percentage = ipServiceFee.discount_doctor_percentage ? ipServiceFee.discount_doctor_percentage : CommonValidation.amountPercentage(ipServiceFee?.old_doctor_fee, states.selectedData?.amount)
      // ipServiceFee.hospital_percentage = ipServiceFee.discount_hospital_percentage ? ipServiceFee.discount_hospital_percentage : CommonValidation.amountPercentage(ipServiceFee?.old_hospital_fee, states.selectedData?.amount)
      states.selectedData.amount_type = this.state.selectedType
      ipServiceFee.total_amount = ipServiceFee.doctor_fee + ipServiceFee.hospital_fee
      states.selectedData.individual_discount_comments = states.IndivDiscountComments

      states.selectedData.ip_service = ipServiceFee
      states.billingList[this.state.discountIndex] = states.selectedData

      if ((states.discountDoctorFee || states.discountHospitalFee || states.discountPercentDoctorFee || states.discountPercentHospitalFee) > 0) {
        states.selectedData.isSplitBlock = true
      }
      this.setState({
        selectedData: this.state.selectedData,
        serviceDiscountpopup: false,
        discountIndex: null
      }, () => {
        // this.calculateTotalDiscount()
        this.setState({
          IndivDiscountComments: '',
          discountDoctorFee: '',
          discountHospitalFee: '',
          discountPercentDoctorFee: '',
          discountPercentHospitalFee: '',
          selectedType: 'rupee',
          totalDiscountedAmount: '',
          totalDiscountedPercentage: ''
        })
      })
    } catch (error) {
      this.successErrorMessgae(error.message, 'error')
    }
  }

  onChangeSymbol = (e) => {
    try {
      let states = this.state
      let ipServiceFee = states.selectedData?.ip_service
      ipServiceFee.discount_doctor_fee = 0;
      ipServiceFee.discount_hospital_fee = 0;
      this.setState({
        selectedType: e.target.value,
        discountDoctorFee: '',
        discountHospitalFee: '',
        discountPercentDoctorFee: '',
        discountPercentHospitalFee: '',
        selectedData: this.state.selectedData
      })
    } catch (error) {
      this.successErrorMessgae(error.message, 'error')
    }
  }

  serviceIndividualDiscountPopup = () => {
    try {
      let states = this.state
      let fee = this.shareValue()
      let doctorShare = this.state.selectedType === 'rupee' ? this.state.discountDoctorFee : this.state.discountPercentDoctorFee
      let hospitalShare = this.state.selectedType === 'rupee' ? this.state.discountHospitalFee : this.state.discountPercentHospitalFee
      return (
        <Modal open={this.state.serviceDiscountpopup}>
          <Box className='eMed_Service_Discount'>
            <Box m={'1vw'}>
              <Typography color={Colors.SecondaryText} fontWeight={600}>Split-Up</Typography>
            </Box>
            <Box display={'flex'} justifyContent={'space-between'} m={'1vw'}>
              <Box>
                <Typography component={'div'} color={Colors.SecondaryText} fontWeight={600}>Doctor Consultation - <Typography component={'span'} fontSize={'0.8vw'} color={'gray'}>{
                  states.ipPatientData?.patient_type === 'new_patient' ? "New Patient" :
                    states.ipPatientData?.patient_type === 'review_patient' ? 'Review Patient' :
                      states.ipPatientData?.patient_type === 'old_patient' ? 'Old Patient' : null
                }</Typography></Typography>
                <Typography visibility={fee?.wardName ? "visible" : "hidden"} component={'div'} color={'gray'}>Ward Name - <Typography component={'span'} color={Colors.SecondaryText} fontWeight={600}>{states.selectedData?.is_template ? "Nill" : fee.wardName ? fee.wardName : 'All'}</Typography></Typography>
              </Box>
              <Box>
                <Typography color={'gray'}>Bill Amount</Typography>
                <Typography fontWeight={600} color={Colors.SecondaryText}>{states.selectedData?.totalAmount ? AmountFormat(states.selectedData?.totalAmount || 0) :
                  states.selectedData?.ip_service?.total_amount ? AmountFormat(states.selectedData?.ip_service?.total_amount || 0) :
                    AmountFormat(states.selectedData?.total_amount ? (states.selectedData?.total_amount || 0) : (states.selectedData?.totalAmount || 0))}</Typography>
              </Box>
            </Box>
            <Box m={'1vw'} display={'flex'} justifyContent={'flex-end'}>
              <Typography fontWeight={600} color={Colors.SecondaryText}>Select Type:
                <Select
                  size='small'
                  sx={{ width: '5vw', height: '2vw', ml: '1vw' }}
                  value={this.state.selectedType}
                  onChange={(e) => { this.onChangeSymbol(e) }}
                >
                  {Unit_Types.map((item, index) => (
                    <MenuItem key={index} value={item.value}>{item?.label}</MenuItem>
                  ))}
                </Select>
              </Typography>
            </Box>
            <Box m={'1vw'} display={'flex'} justifyContent={'space-evenly'}>
              {this.splitUpTextBox('discountDoctorFee', doctorShare, 'Doctor Discount', '13.5vw')}
              {this.splitUpTextBox('discountHospitalFee', hospitalShare, 'Hospital Discount', '13.5vw')}
            </Box>
            <Box display={'flex'} justifyContent={'space-between'} m={'1vw'}>
              <Box>
                <Typography fontSize={'0.8vw'} color={'gray'}>Doctor Share</Typography>
                <Typography color={Colors.SecondaryText} fontWeight={600}>
                  {`${fee.doctorFee} - ${fee.discountDoctorFee ? fee.discountDoctorFee : 0} = ${((fee.doctorFee) - (+fee.discountDoctorFee)) ? AmountFormat((fee.doctorFee) - (+fee.discountDoctorFee)) : `${CurrencySymbol} 0.00`}`}
                </Typography>
              </Box>
              <Box>
                <Typography fontSize={'0.8vw'} color={'gray'}>Hospital Share</Typography>
                <Typography color={Colors.SecondaryText} fontWeight={600}>
                  {`${fee.hospitalFee} - ${fee.discountHospitalFee ? fee.discountHospitalFee : 0} = ${((fee.hospitalFee) - (+fee.discountHospitalFee)) ? AmountFormat(fee.hospitalFee - (+fee.discountHospitalFee)) : `${CurrencySymbol} 0.00`}`}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ paddingX: '0.5vw' }}>
              <TextField
                label={"Discount Comments"}
                sx={{ width: '31vw' }}
                multiline={true}
                onChange={(e) => {
                  this.setState({
                    IndivDiscountComments: e.target.value
                  })
                }}
                inputProps={{ maxLength: 250 }}
                disabled={(!doctorShare && !hospitalShare)}
                value={this.state.IndivDiscountComments}
                rows={2} />
            </Box>
            <Box display={'flex'} justifyContent={'flex-end'} m={'1vw'}>
              <Button variant='outlined' sx={{ width: '5vw', height: '2vw', textTransform: 'capitalize', mr: '1vw' }}
                onClick={() => {
                  this.setState({
                    serviceDiscountpopup: false,
                    discountDoctorFee: '',
                    discountHospitalFee: '',
                    discountPercentDoctorFee: '',
                    discountPercentHospitalFee: '',
                    selectedType: 'rupee',
                    selectedData: JSON.parse(this.state.dupliSelectedData),
                  })
                }}>Close</Button>
              <Button variant='contained' sx={{ width: '5vw', height: '2vw', textTransform: 'capitalize' }}
                onClick={() => { this.onSaveDiscountAmount() }}
              >Save</Button>
            </Box>
          </Box>
        </Modal>
      )
    } catch (error) {
      this.successErrorMessgae(error.message, 'error')
    }
  }

  onClickAddDiscount = (item, index) => {
    try {
      let ipServiceFee = item?.ip_service
      let discountFee = ipServiceFee?.discount_doctor_fee ? ipServiceFee?.discount_doctor_fee : 0
      let discountHospitalFee = ipServiceFee?.discount_hospital_fee ? ipServiceFee?.discount_hospital_fee : 0

      ipServiceFee.original_doctor_fee = ipServiceFee.original_doctor_fee ? ipServiceFee.original_doctor_fee : ipServiceFee?.doctor_fee
      ipServiceFee.original_hospital_fee = ipServiceFee.original_hospital_fee ? ipServiceFee.original_hospital_fee : ipServiceFee?.hospital_fee
      ipServiceFee.share_discount = true
      this.setState({
        serviceDiscountpopup: true,
        selectedData: JSON.parse(JSON.stringify(item)),
        dupliSelectedData: JSON.stringify(item),
        selectedType: item?.amount_type,
        discountIndex: index
      }, () => {
        if (this.state.selectedType === 'rupee') {
          this.setState({
            discountDoctorFee: ipServiceFee?.discount_doctor_fee ? ipServiceFee?.discount_doctor_fee : 0,
            discountHospitalFee: ipServiceFee?.discount_hospital_fee ? ipServiceFee?.discount_hospital_fee : 0
          })
        } else {
          this.setState({
            discountPercentDoctorFee: ipServiceFee?.discount_doctor_percentage ? ipServiceFee?.discount_doctor_percentage : 0,
            discountPercentHospitalFee: ipServiceFee?.discount_hospital_percentage ? ipServiceFee?.discount_hospital_percentage : 0
          })
        }
      })

      this.setState({
        IndivDiscountComments: item?.individual_discount_comments ? item?.individual_discount_comments : ""
      })
    } catch (error) {
      this.successErrorMessgae(error.message, 'error')
    }
  }

  FindTimeDifference = (startDate, endDate) => {

    var diff = Math.abs(new Date(endDate) - new Date(startDate));
    var hours = Math.floor(diff / 3.6e6);
    return hours
  }

  renderLeftTableContent = () => {
    const { t } = this.props
    let { billingList } = this.state
    return (
      <Box component={'div'} className='eMed_bill_table_card'>
        <TableContainer className='eMed_bill_table'>
          <Table stickyHeader size='small'>
            <TableHead>
              <TableRow>
                <TableCell sx={{ backgroundColor: Colors.DataTblHeaderbg }}>
                  <Button className='eMed_edit_btn_bill'
                    disabled={(this.state.withOutAppointment?.is_ip || this.state.ForCancel)}
                    sx={{ visibility: (this.state.withOutAppointment?.is_ip || this.state.ForCancel) ? "hidden" : "visible" }}
                    onClick={() => { this.setState({ isDeleteAll: true }) }}>
                    <Tooltip placement='top' title='Delete' arrow>
                      <img className='eMed_delete_img' src={ImagePaths.Delete.default} alt='del' />
                    </Tooltip>
                  </Button>
                </TableCell>
                <TableCell sx={{ backgroundColor: Colors.DataTblHeaderbg }}>
                  <Typography fontWeight={600} sx={{ color: Colors.SecondaryText, fontSize: "0.9vw" }}>{t("Description")}</Typography>
                </TableCell>
                <TableCell sx={{ backgroundColor: Colors.DataTblHeaderbg }}>
                  <Box component={'div'} display={'flex'} justifyContent={'center'}>
                    <Typography fontWeight={600} sx={{ color: Colors.SecondaryText, fontSize: "0.9vw" }}>{t("Unit")}</Typography>
                    <Button className='eMed_edit_btn_bill'
                      disabled={this.state.withOutAppointment?.is_ip || this.state.ForCancel}
                      sx={{ visibility: (this.state.withOutAppointment?.is_ip || this.state.ForCancel) ? "hidden" : "visible" }}
                      onClick={() => { this.setState({ isEditunit: !this.state.isEditunit }) }}
                    >
                      <Tooltip placement='top' title='Edit' arrow>
                        <img className='eMed_header_img' src={ImagePaths.NewEditcon.default} alt='edit' />
                      </Tooltip>
                    </Button>
                  </Box>
                </TableCell>
                <TableCell sx={{ backgroundColor: Colors.DataTblHeaderbg }}>
                  <Box component={'div'} display={'flex'} justifyContent={'center'}>
                    <Typography fontWeight={600} sx={{ color: Colors.SecondaryText, fontSize: "0.9vw" }}>{t("Rate Unit")}</Typography>
                    <Button className='eMed_edit_btn_bill'
                      disabled={this.state.withOutAppointment?.is_ip || this.state.ForCancel}
                      sx={{ visibility: (this.state.withOutAppointment?.is_ip || this.state.ForCancel) ? "hidden" : "visible" }}
                      onClick={() => { this.setState({ isEditRate: !this.state.isEditRate }) }}
                    >
                      <Tooltip placement='top' title='Edit' arrow>
                        <img className='eMed_header_img' src={ImagePaths.NewEditcon.default} alt='edit' />
                      </Tooltip>
                    </Button>
                  </Box>
                </TableCell>
                <TableCell sx={{ backgroundColor: Colors.DataTblHeaderbg }}>
                  <Box component={'div'} display={'flex'} justifyContent={'center'}>
                    <Typography fontWeight={600} sx={{ color: Colors.SecondaryText, fontSize: "0.9vw" }}>{t("concession")}</Typography>
                    <Button className='eMed_edit_btn_bill'
                      disabled={this.state.withOutAppointment?.is_ip || this.state.ForCancel}
                      sx={{ visibility: (this.state.withOutAppointment?.is_ip || this.state.ForCancel) ? "hidden" : "visible" }}
                      onClick={() => { this.setState({ isEditDiscount: !this.state.isEditDiscount }) }}
                    >
                      <Tooltip placement='top' title='Edit' arrow>
                        <img className='eMed_header_img' src={ImagePaths.NewEditcon.default} alt='edit' />
                      </Tooltip>
                    </Button>
                  </Box>
                </TableCell>
                <TableCell sx={{ backgroundColor: Colors.DataTblHeaderbg }}>
                  <Box component={'div'} display={'flex'} justifyContent={'flex-end'}>
                    <Typography fontWeight={600} sx={{ color: Colors.SecondaryText, fontSize: "0.9vw" }}>{`${t("TotalAmount")} (${CurrencySymbol})`}</Typography>
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.billingList?.length > 0 ? this.state.billingList.map((item, index) => {
                let serviceName = `${item?.service_type ? item?.service_type : "-"} 
                 - ${item?.service_name ? item?.service_name : "-"}`
                return (
                  <TableRow key={index}>
                    <TableCell>
                      <Button className='eMed_edit_btn_bill'
                        disabled={(this.state.withOutAppointment?.is_ip || this.state.ForCancel)}
                        sx={{ visibility: (this.state.withOutAppointment?.is_ip || this.state.ForCancel) ? "hidden" : "visible" }}
                        onClick={() => { this.setState({ isDeleteCliked: true, deleteIndex: index, deleteService: item?.service_type }) }}>
                        <Tooltip placement='top' title='Delete' arrow>
                          <img className='eMed_delete_img' src={ImagePaths.Delete.default} alt='del' />
                        </Tooltip>
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Typography id="eMed_opip_billTxt">{serviceName?.length > 50 ?
                        <Tooltip placement='top' title={serviceName} arrow><div id="eMed_opip_billTxt">{serviceName.slice(0, 48) + "..."}</div></Tooltip> :
                        serviceName ? serviceName : "-"}</Typography>
                    </TableCell>
                    <TableCell>
                      {item?.time_taken == null || item?.time_taken == undefined ?
                        <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                          {!item?.is_auto_billing && this.state.isEditunit && item.is_edit_unit ?
                            <Box component={'div'} display={'flex'} flexDirection={'row'}>
                              <Button className='eMed_Bill_add_btn'
                                disabled={this.state.withOutAppointment?.is_ip}
                                onClick={() => { this.onChangeUnit(item, 'decrease', index) }}
                              >
                                <Typography>-</Typography>
                              </Button>
                              <Typography paddingRight={"1vw"}>{item.unit}</Typography>
                              <Button className='eMed_Bill_add_btn'
                                disabled={this.state.withOutAppointment?.is_ip}
                                onClick={() => { this.onChangeUnit(item, 'increase', index) }}
                              >
                                <Typography>+</Typography>
                              </Button>
                            </Box> :
                            <Typography>{item?.unit ? item?.unit : 1}</Typography>
                          }
                        </Box> :
                        <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                          {
                            !item?.is_auto_billing && this.state.isEditunit && item.field && item.is_edit_unit ?
                              <div className='eMed_Time_field'>
                                <OutlinedInput
                                  value={item?.hour}
                                  onChange={(e) => {
                                    var today = new Date();
                                    today.setHours(23, 59, 59, 999);
                                    var AptDate = DateTime.fromJSDate(today).toFormat('yyyy-MM-dd')
                                    var Aptime = DateTime.fromJSDate(today).toLocaleString(DateTime.TIME_24_WITH_SECONDS);

                                    var startDate = this.state.ipPatientData?.admission_date ? this.state.ipPatientData?.admission_date : `${DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd')}T${DateTime.fromJSDate(new Date()).toLocaleString(DateTime.TIME_24_WITH_SECONDS)}`;
                                    var endDate = (AptDate + "T" + Aptime);
                                    let maxHours = this.FindTimeDifference(startDate, endDate)

                                    if ((CommonValidation.numberOnly(e.target.value) && +e.target.value < maxHours || +e.target.value == "")) {
                                      item.hour = +e.target.value;
                                      this.setState({ billingList })
                                    }
                                  }}
                                  style={{ width: "3.5vw", height: "1.7vw", fontSize: "0.9vw" }}
                                />
                                <OutlinedInput
                                  value={item?.minute}
                                  onChange={(e) => {
                                    if ((CommonValidation.numberOnly(e.target.value) && +e.target.value < 60 || +e.target.value == "")) {
                                      item.minute = +e.target.value;
                                      this.setState({ minuteField: e.target.value })
                                    }
                                  }}
                                  style={{ width: "3.5vw", height: "1.7vw", fontSize: "0.9vw" }}
                                />
                                <IconButton onClick={() => { this.TimeFieldCalculation(item) }} size="small" ><img className="eMed_Tick_img" src={ImagePaths.Tick.default} alt={"Tick"} /></IconButton>
                              </div> :
                              <div style={{ display: "flex", cursor: "pointer", paddingTop: "0.25vw" }}>
                                <div onClick={() => { this.TimeFieldEnable(item?.time_taken, item) }} style={{ paddingRight: "0.25vw", fontSize: "0.95vw" }}>{item?.time_taken.length > 5 ? item.time_taken.slice(0, 5) : item?.time_taken}</div>
                                <div className='eMed_Time_cont' onClick={() => { this.setState({ Timepopup: this.state.isEditunit && item.is_edit_unit ? true : false, selectedService: item, selectedIndex: index }) }}>
                                  {this.state.isEditunit && !item?.is_auto_billing ?
                                    <div className='eMed_Time_cont'>
                                      <img className='eMed_Time_img' src={ImagePaths.HighlightedClock.default} alt='edit' />
                                    </div> :
                                    <div className='eMed_Time_cont'>
                                      <img className='eMed_Time_img' src={ImagePaths.NormalClock.default} alt='edit' />
                                    </div>}
                                </div>
                              </div>
                          }
                        </Box>
                      }

                    </TableCell>
                    <TableCell>
                      <Box component={'div'} display={'flex'} justifyContent={'flex-end'}>
                        {!item?.is_auto_billing && this.state.isEditRate && item.is_edit_amount && ((item?.service_category__service_category === "Doctor Consultation Charges") || (item?.service_category__service_category === "Surgery Charges") || (item?.service_category__service_category === "Anasthesia Charges")) && ((item?.ip_service?.doctor_fee && item?.ip_service?.hospital_fee) || (item?.ip_service?.discount_doctor_fee > 0 || item?.ip_service?.discount_hospital_fee > 0) || (item?.ip_service?.doctor_percentage === 100 || item?.ip_service?.hospital_percentage === 100)) ?
                          <>
                            <Button size='small' sx={{ textTransform: "capitalize" }} disabled={this.state.disableSplitSharePopup} onClick={() => { this.addShare(item, index) }}>{`Add Share ${item.amount ? `(${AmountFormat(item?.amount)})` : `${CurrencySymbol} 0.00`}`}</Button>
                          </>
                          :
                          !item?.is_auto_billing && this.state.isEditRate && item.is_edit_amount ?
                            <>
                              {this.inputTextBox('rateUnit', item.amount, index, "0", "0.7vw", false)}
                            </> :
                            <Typography component={'div'} position={'relative'} right={"3vw"}>{item.amount ? AmountFormat(item.amount)?.replace(`${CurrencySymbol}`, "") : `0.00`}</Typography>
                        }
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box component={'div'} display={'flex'} justifyContent={'flex-end'}>
                        {
                          !item?.is_auto_billing && this.state.isEditDiscount && item.is_edit_discount && ((item?.service_category__service_category === "Doctor Consultation Charges") || (item?.service_category__service_category === "Surgery Charges") || (item?.service_category__service_category === "Anasthesia Charges")) && ((item?.ip_service?.doctor_fee && item?.ip_service?.hospital_fee) || (item?.ip_service?.discount_doctor_fee > 0 || item?.ip_service?.discount_hospital_fee > 0) || (item?.ip_service?.doctor_percentage === 100 || item?.ip_service?.hospital_percentage === 100)) ?
                            <>
                              <Button
                                startIcon={<AddCircle />}
                                endIcon={item?.discountAmount ? `(${AmountFormat(item?.discountAmount)})` : null}
                                sx={{ textTransform: 'capitalize', height: '1.5vw' }}
                                onClick={() => { this.onClickAddDiscount(item, index) }}
                              >Add Discount</Button>
                            </> :
                            !item?.is_auto_billing && this.state.isEditDiscount && item.is_edit_discount ?
                              <>
                                {this.inputTextBox('percentage', item.discountPercentage, index, "0 %", "0.7vw", false)}
                                {this.inputTextBox('discountAmount', item.discountAmount, index, `${CurrencySymbol} 0`, "0.7vw", false)}
                              </> :
                              <Typography position={'relative'} right={"2.5vw"}>{item?.discountAmount ? AmountFormat(item?.discountAmount)?.replace(`${CurrencySymbol}`, "") : `0.00`}</Typography>
                        }
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box component={'div'} display={'flex'} justifyContent={'flex-end'}>
                        <Typography>{AmountFormat((item.totalAmount - item.discountAmount).toFixed(2) || 0)}</Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                )
              }) :
                this.state.isBillServicesLoading ?
                  <TableRow className="eMed_table_empty">
                    <TableCell sx={{ borderBottom: "none" }} >{""}</TableCell>
                    <TableCell sx={{ borderBottom: "none" }} >{""}</TableCell>
                    <TableCell sx={{ borderBottom: "none" }} >{""}</TableCell>
                    <TableCell sx={{ borderBottom: "none" }} align="center"><CircularProgress size={25} /></TableCell>
                    <TableCell sx={{ borderBottom: "none" }} >{""}</TableCell>
                    <TableCell sx={{ borderBottom: "none" }} >{""}</TableCell>
                  </TableRow>
                  : <TableRow className="eMed_table_empty">
                    <TableCell sx={{ borderBottom: "none" }} >{""}</TableCell>
                    <TableCell sx={{ borderBottom: "none" }} >{""}</TableCell>
                    <TableCell sx={{ borderBottom: "none" }} >{""}</TableCell>
                    <TableCell sx={{ borderBottom: "none" }} align="center">{t("NoRecordsFound")}</TableCell>
                    <TableCell sx={{ borderBottom: "none" }} >{""}</TableCell>
                    <TableCell sx={{ borderBottom: "none" }} >{""}</TableCell>
                  </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>
        {this.customFooter(this.state.billingList)}
      </Box>
    )
  }
  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  IPRefundPost = (datas) => {
    this.CancelBill(datas)
  }

  ReasonPopupClose = () => {
    this.setState({
      cancelBillClicked: false
    })
  }

  RefundMode = (mode) => {
    this.setState({
      selectedRefundMode: mode
    })
  }

  BillMode = (mode) => {
    this.setState({
      selectedBillMode: mode,
      cancelReceipt: true
    })
  }

  changeReceiptCheck = (check) => {
    this.setState({
      cancelReceipt: check
    })
  }

  CancelBill = (datas) => {
    try {
      var { history } = this.props;
      let payment = []
      if (datas?.isCash) {
        payment.push(1)
      }
      if (datas?.isUPI) {
        payment.push(3)
      }
      if (datas?.isBankTranfer) {
        payment.push(5)
      }
      let data = {
        bill_id: this.state.invoice_id,
        bill_type: 'cancelled',
        cancel_reason: datas?.comments ? datas?.comments : "",
        cash_amount: datas?.CashAmount ? +datas?.CashAmount : 0,
        upi_amount: datas?.UPIAmount ? +datas?.UPIAmount : 0,
        bank_transfer_amount: datas?.BankTfrAmount ? +datas?.BankTfrAmount : 0,
        bank_ref_no: datas?.BankTfrNumber ? datas?.BankTfrNumber : "",
        upi_ref_no: datas?.UPINumber ? datas?.UPINumber : "",
        received_amount: this.state?.totalPayableAmount ? this.state?.totalPayableAmount : 0,
        net_amount: this.state?.totalPayableAmount ? this.state?.totalPayableAmount : 0,
        payment_mode: payment,
        add_to_advance: datas?.receiptType === "Advance" ? true : false,
        receipt_type: datas?.receiptType
      }

      let URL = Serviceurls.IP_RECEIVE_PAYMENT_GET
      RestAPIService.create(data, URL)
        .then((response) => {
          if (response.data.status === "success") {
            this.ReasonPopupClose()
            clearCachevalue("IPCancelBilldata");
            clearCachevalue("isForCancelBill");
            if (this.props.history?.location?.state?.FromBills) {
              this.onClearLeftContent()
              this.getApproxCharges()
              this.getPatientData()
              this.getServiceTemplateData()
              this.getServiceDescriptionData()
              history.push({ pathname: "/IPHome/PatientDetails/ServiceBilling" })
            } else {
              history.push({ pathname: '/BillingTransaction/OverAllBills' })
            }
          }
        }).catch((error) => {
          if (error?.response?.data?.status === "fail") {
            this.successErrorMessgae(error.response.data.message, 'error')
          } else {
            this.successErrorMessgae(error.message, 'error')
          }
        })
    }
    catch (e) {

    }
  }

  changeupiReferNum = (text) => {
    this.setState({
      upi_ref_num: text
    })
  }

  renderCancelContent = () => {
    const { t } = this.props
    var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
    var UserData = myUser;
    return (
      <Box marginTop={"16vw"}>
        <Box component={'div'} className='eMed_date_picker_grid_amount' marginLeft={"1.5vw"}>
          <Typography ml={'0.5vw'} color='#616161' fontWeight={'600'}>{t("Invoice Number")}</Typography>
          <Typography ml={'0.5vw'} mt={'1vw'} fontWeight={'600'}>{this.state.CancelledBillNumber}</Typography>
        </Box>
        <Box className='eMed_right_sub_grid'>
          <Box component={'div'} className='eMed_date_picker_grid' marginLeft={'1.2vw'}>
            <Typography marginLeft={'0.5vw'} color='#616161' fontWeight={'600'}>{"Bill Date and Time"}</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                open={this.state.DateOpen}
                onOpen={() => { this.setState({ DateOpen: true }) }}
                onClose={() => { this.setState({ DateOpen: false }) }}
                disabled={true}
                inputFormat='DD-MM-YYYY & hh:mm A'
                value={this.state.templateDate}
                // name={names}
                minDate={UserData?.is_user ? new Date() : this.state.patientData?.appointment_date}
                maxDate={UserData?.is_user ? new Date() : new Date((new Date).getFullYear(), (new Date).getMonth(), (new Date).getDate() + 1)}
                onChange={(newDate) => {
                  if (newDate === null) {
                    this.setState({ templateDate: new Date(), DateTimeChanged: false })
                  } else {
                    this.setState({ templateDate: newDate, descripDate: newDate, DateTimeChanged: true })
                  }
                }}
                // onAccept={(value) => {
                //     if (value === null && names === 'templateDate') {
                //         this.getServiceTemplateData()
                //     } else if (value === null && names === 'descripDate') {
                //         this.getServiceDescriptionData()
                //     } else {
                //         this.getServiceDescriptionData()
                //         this.getServiceTemplateData()
                //     }
                // }}
                renderInput={(params) => <TextField size='small' autoComplete='off'
                  onKeyDown={(e) => e.preventDefault()}
                  onClick={() => { this.setState({ DateOpen: true }) }}
                  {...params}
                  fullWidth
                  variant='standard'
                  sx={{ marginTop: "1vw", marginLeft: "0.5vw", marginRight: "0.5vw", width: "15vw" }}
                />}
              />
            </LocalizationProvider>
          </Box>
        </Box>
      </Box>
    )
  }

  closeSplitPopup = () => {
    this.setState({ docSplitPopup: false, selectedData: JSON.parse(this.state.dupliSelectedData) })
  }


  amtCalculate = (e, name) => {
    let states = JSON.parse(JSON.stringify(this.state))
    let value = e.target.value
    let serviceFee = JSON.parse(JSON.stringify(states.selectedData?.ip_service))
    switch (name) {
      case 'doctorAmt':
        if (CommonValidation.RangeDecimalNumber(value) || value == '') {
          serviceFee.total_amt = (+value) + (+states.hospitalAmt)
          states.selectedData.amount = serviceFee.total_amt
          states.selectedData.totalAmount = states.selectedData?.amount * (+states.selectedData?.unit)
          this.setState({
            docAmt: +value,
            selectedData: states.selectedData
          }, () => {
            let totalAmt = (+value) + (+states.hospitalAmt)
            this.setState({
              totalBillAmt: (+value) + (+states.hospitalAmt)
            }, () => {
              this.setState({
                docPercent: totalAmt ? +((+value / totalAmt) * 100).toFixed(2) : 0,
                hospitalPercent: totalAmt ? +((+states.hospitalAmt / totalAmt) * 100).toFixed(2) : 0,
              })
            })
          })
        }
        break;
      case 'hospitalAmt':
        if (CommonValidation.RangeDecimalNumber(value) || value == '') {
          if (serviceFee) {
            serviceFee.total_amt = (+states.docAmt) + (+value)
            states.selectedData.amount = serviceFee.total_amt
            states.selectedData.totalAmount = states.selectedData?.amount * (+states.selectedData?.unit)
          } else {
            // states.selectedData.amount = (+states.docAmt) + (+value)
            // states.selectedData.totalAmount = states.selectedData?.amount * (+states.selectedData?.unit)
            // states.selectedData.total_amount = states.selectedData?.amount * (+states.selectedData?.unit)
          }
          this.setState({
            hospitalAmt: +value,
            selectedData: states.selectedData
          }, () => {
            let totalAmt = (+states.docAmt) + (+value)
            this.setState({
              totalBillAmt: (+states.docAmt) + (+value)
            }, () => {
              this.setState({
                docPercent: totalAmt ? +((+states.docAmt / totalAmt) * 100).toFixed(2) : 0,
                hospitalPercent: totalAmt ? +((+value / totalAmt) * 100).toFixed(2) : 0,
              })
            })
          })
        }
        break;
      default:
        break;
    }
  }

  newShareSplitUp = () => {
    try {
      let { billingList, selectedData } = this.state
      billingList[this.state.rowIndex].amount = this.state.totalBillAmt ? +(+this.state.totalBillAmt / billingList[this.state.rowIndex].unit).toFixed(2) : 0
      billingList[this.state.rowIndex].ip_service.doctor_percentage = this.state.docPercent ? +this.state.docPercent : 0
      billingList[this.state.rowIndex].ip_service.hospital_percentage = this.state.hospitalPercent ? +this.state.hospitalPercent : 0
      billingList[this.state.rowIndex].ip_service.original_doctor_fee = this.state.docAmt ? +this.state.docAmt : 0
      billingList[this.state.rowIndex].ip_service.original_hospital_fee = this.state.hospitalAmt ? +this.state.hospitalAmt : 0
      billingList[this.state.rowIndex].ip_service.discount_hospital_percentage = this.state.hospitalAmt ? billingList[this.state.rowIndex]?.ip_service.discount_hospital_percentage : 0
      billingList[this.state.rowIndex].ip_service.discount_doctor_percentage = this.state.docAmt ? billingList[this.state.rowIndex]?.ip_service.discount_doctor_percentage : 0
      this.setState({ billingList }, () => {
        this.setState({ docSplitPopup: false }, () => { this.CalculateServiceValues(this.state.rowIndex) })
      })
    } catch (error) {
      this.successErrorMessgae(error.message, 'error')
    }
  }

  splitScreenPopup = () => {
    return (
      <Modal open={this.state.docSplitPopup}>
        <Paper elevation={3} id="emedhub_popup_mainBox" sx={{ width: "31vw", height: "60vh" }}>
          <div className="eMed_DialogBox_Title" style={{ padding: '0.5vw' }}>
            <Typography sx={{ fontSize: '1vw', fontWeight: "bold" }}>
              Split-Up
            </Typography>
            <div className="eMed_DialogBox_Close">
              <img className="eMed_DeletePopup_CrossIcon" src={ImagePaths.LabCloseButton.default} alt='Close' onClick={this.closeSplitPopup} />
            </div>
          </div>
          <hr />
          <div className="eMed_DialogBox_Title" style={{ padding: '0.5vw' }}>
            <Typography sx={{ fontSize: '1vw', fontWeight: "bold" }}>Doctor Consultation</Typography>
            {/* <div style={{display:'flex'}}>
                                <Typography sx={{ fontSize: '1vw' }}>Select Type</Typography>
                                <Select 
                                    size='small'
                                    sx={{ width: '5vw', height: '2vw', ml: '1vw' }}
                                    
                                ><MenuItem>%</MenuItem></Select>
                                </div> */}
          </div>
          <Box>
            <Box component={'div'} display={'flex'} justifyContent={'space-evenly'} pl={'0.5vw'}>
              <Box display={'flex'} justifyContent={'space-between'} pl={'1vw'}>
                <Typography color={Colors.SecondaryText} fontWeight={600}> Rate / Unit</Typography>
                <Typography fontWeight={'600'} pl={'1.5vw'}>{AmountFormat((this.state.totalBillAmt / this.state.selectedData?.unit) || 0)}</Typography>
              </Box>
              <Box display={'flex'} justifyContent={'space-between'} pl={'1vw'}>
                <Typography color={Colors.SecondaryText} fontWeight={600}>Unit</Typography>
                <Typography fontWeight={'600'} pl={'1.5vw'}>{this.state.selectedData?.unit ? this.state.selectedData?.unit : "-"}</Typography>
              </Box>
            </Box>
            <div className="eMed_DialogBox_Title" style={{ padding: '0.5vw' }}>
              <Typography>Doctor Share</Typography>
              <div style={{ display: 'flex', gap: '5%' }}>
                <TextField sx={{ width: '10vw' }} label='Amount' size='small' value={this.state.docAmt} onChange={(e) => this.amtCalculate(e, 'doctorAmt')}></TextField>
                <TextField disabled={true} sx={{ width: '10vw' }} size='small' label='Percentage (%)' value={this.state.docPercent}></TextField>
              </div>
            </div>
            <div className="eMed_DialogBox_Title" style={{ padding: '0.5vw' }}>
              <Typography>Hospital Share</Typography>
              <div style={{ display: 'flex', gap: '5%' }}>
                <TextField sx={{ width: '10vw' }} label='Amount' size='small' value={this.state.hospitalAmt} onChange={(e) => { this.amtCalculate(e, 'hospitalAmt') }}></TextField>
                <TextField disabled={true} sx={{ width: '10vw' }} size='small' label='Percentage (%)' value={this.state.hospitalPercent}></TextField>
              </div>
            </div>
            <div className="eMed_DialogBox_Title" style={{ padding: '0.5vw' }}>
              <Typography>Total</Typography>
              <div style={{ display: 'flex', gap: '5%' }}>
                <TextField disabled={true} sx={{ width: '10vw' }} size='small' label='Amount' value={AmountFormat(this.state.totalBillAmt || 0)}></TextField>
                <TextField disabled={true} sx={{ width: '10vw' }} size='small' label='Percentage (%)' value={this.state.totalBillPercent}></TextField>
              </div>
            </div>
          </Box>
          <Box m={'1vw'} display={'flex'} justifyContent={'flex-end'}>
            <Button
              variant='outlined'
              sx={{ width: '5vw', height: '2vw', mr: '1vw' }}
              onClick={this.closeSplitPopup}
            >Close</Button>
            <Button
              variant='contained'
              sx={{ width: '5vw', height: '2vw' }}
              onClick={() => { this.newShareSplitUp() }}
            >Save</Button>
          </Box>
        </Paper>
      </Modal>
    )
  }

  addShare = (item, index) => {
    this.setState({
      docAmt: (item?.ip_service?.original_doctor_fee ? item.ip_service?.original_doctor_fee : item.ip_service?.doctor_fee ? item.ip_service?.doctor_fee : ''),
      docPercent: item?.ip_service?.doctor_percentage ? item?.ip_service?.doctor_percentage : '',
      hospitalAmt: item?.ip_service?.original_hospital_fee ? item.ip_service?.original_hospital_fee : item.ip_service?.hospital_fee ? item.ip_service?.hospital_fee : '',
      hospitalPercent: item?.ip_service?.hospital_percentage ? item?.ip_service?.hospital_percentage : '',
      // totalBillAmt: item?.amount ? item?.amount : '',
      totalBillPercent: '100',
      rowIndex: index,
      selectedData: item,
      dupliSelectedData: JSON.stringify(item),
    }, () => {
      this.setState({ docSplitPopup: true, totalBillAmt: +this.state.docAmt + +this.state.hospitalAmt })
    })
  }

  renderCreditType = () => {
    let { patientData } = this.state
    let ins_name = patientData?.insurance_name ? patientData?.insurance_name : this.state.selInsName
    let ins_no = patientData?.policy_no ? patientData?.policy_no : this.state.selPolicyNo
    let comp_name = patientData?.employer_name ? patientData?.employer_name : this.state.selCompanyName
    let employee_no = patientData?.id_no ? patientData?.id_no : this.state.selEmployeeNo
    return (
      <Box>
        <Box component={'div'} display={'flex'} flexDirection={'row'} width={'40vw'} justifyContent={'space-between'}>
          <Typography fontSize={'1vw'} fontWeight={'bold'}>Select the Credit Type</Typography>
          <IconButton size='small' onClick={() => {
            this.setState({
              creditChange: false,
              addInsurance: false,
              addCorporate: false,
              paymentCreditType: this.state.selPayemtnCreditType ? this.state.selPayemtnCreditType : this.state.ipPatientData?.primary_payment_type === "Patient credit" ? 'patientcredit' :
                this.state.ipPatientData?.primary_payment_type === "Corprate credit" ? 'corporatecredit' :
                  this.state.ipPatientData?.primary_payment_type === "Insurance credit" ? 'insurancecredit' : 'patientcredit',
              insuranceName: null,
              insuranceNo: '',
              corpName: null,
              employeeNo: '',
              selInsName: this.state.selInsName ? this.state.selInsName : this.state.patientData?.insurance_name ? this.state.patientData?.insurance_name : '',
              selPolicyNo: this.state.selPolicyNo ? this.state.selPolicyNo : this.state.patientData?.policy_no ? this.state.patientData?.policy_no : '',
              selCompanyName: this.state.selCompanyName ? this.state.selCompanyName : this.state?.patientData?.employer_name ? this.state?.patientData?.employer_name : '',
              selEmployeeNo: this.state.selEmployeeNo ? this.state.selEmployeeNo : this.state?.patientData?.id_no ? this.state?.patientData?.id_no : '',
              selCompanyID: this.state.selCompanyID ? this.state.selCompanyID : this.state?.patientData?.employer_id ? this.state?.patientData?.employer_id : '',
              DisableBtn: false,

            })
          }}>
            <img src={ImagePaths.Close.default} alt="close" style={{ width: '1vw', height: '1vw' }} />
          </IconButton>
        </Box>
        <Box component={'div'} sx={{ height: '5vw', marginTop: '2vw', display: 'flex' }}>
          <Box component={'div'} sx={{ width: '13vw', height: '5vw', borderRight: '1px solid lightgray', marginLeft: '1vw' }}>
            <Typography>Patient Credit</Typography>
            <FormControlLabel value={'patientcredit'} control={<Radio checked={this.state.paymentCreditType === 'patientcredit'} size='small' />}
              onClick={(e) => {
                this.setState({
                  paymentCreditType: e.target.value,
                  addInsurance: false,
                  addCorporate: false,
                  corpName: null,
                  employeeNo: '',
                  insuranceName: null,
                  insuranceNo: ''
                })
              }} label="Patient Credit" />
          </Box>
          <Box component={'div'} sx={{ width: '15vw', height: '5vw', borderRight: '1px solid lightgray', marginLeft: '1vw' }}>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Typography>Insurace Credit</Typography>
              {ins_name ? <Tooltip title={'edit'} placement='top'>
                <IconButton size='small' onClick={() => {
                  this.setState({
                    addInsurance: true,
                    addCorporate: false,
                    paymentCreditType: 'insurancecredit',
                    insuranceName: ins_name,
                    insuranceNo: patientData?.policy_no,
                    corpName: null,
                    employeeNo: ''
                  })
                }}>
                  <img style={{ width: '.9vw', height: '.9vw' }} src={ImagePaths.outlinedEdit.default} alt='eidt' />
                </IconButton>
              </Tooltip> : null}
            </Box>
            {ins_name ?
              <FormControlLabel value={'insurancecredit'} control={<Radio checked={this.state.paymentCreditType === 'insurancecredit'} size='small' />} onClick={(e) => { this.setState({ paymentCreditType: e.target.value, addCorporate: false, }) }}
                label={<Box>
                  {ins_name?.length > 18 ?
                    <Tooltip title={ins_name} placement='top'><Typography sx={{ fontSize: '0.8vw', fontWeight: 'bold' }}>{ins_name.slice(0, 18) + '...'}</Typography></Tooltip>
                    : <Typography sx={{ fontSize: '0.8vw', fontWeight: 'bold' }}>{ins_name}</Typography>}
                  {ins_no?.length > 18 ?
                    <Tooltip title={ins_no} placement='top'><Typography sx={{ fontSize: '0.7vw', color: '#888888' }}>{ins_no.slice(0, 18) + '...'}</Typography></Tooltip>
                    : <Typography sx={{ fontSize: '0.7vw', color: '#888888' }}>{ins_no}</Typography>}
                </Box>} /> :
              <Button size='small' variant="text" startIcon={<AddCircleIcon />} sx={{ textTransform: 'capitalize' }}
                onClick={() => {
                  this.setState({
                    addInsurance: true,
                    addCorporate: false,
                    paymentCreditType: 'insurancecredit',
                    insuranceName: null,
                    insuranceNo: ''
                  })
                }}
              >Add Insurance</Button>}
          </Box>
          <Box component={'div'} sx={{ width: '15vw', height: '5vw', marginLeft: '1vw' }}>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Typography>Corporate Credit</Typography>
              {comp_name ? <Tooltip title={'edit'} placement='top'>
                <IconButton size='small' onClick={() => {
                  this.setState({
                    addCorporate: true,
                    addInsurance: false,
                    paymentCreditType: 'corporatecredit',
                    corpName: comp_name,
                    employeeNo: patientData?.id_no,
                    insuranceName: null,
                    insuranceNo: '',

                  })
                }}>
                  <img style={{ width: '.9vw', height: '.9vw' }} src={ImagePaths.outlinedEdit.default} alt='eidt' />
                </IconButton>
              </Tooltip> : null}
            </Box>
            {comp_name ?
              <FormControlLabel value={'corporatecredit'} control={<Radio checked={this.state.paymentCreditType === 'corporatecredit'} size='small' />} onClick={(e) => { this.setState({ paymentCreditType: e.target.value, addInsurance: false }) }}
                label={<Box>
                  {comp_name?.length > 18 ?
                    <Tooltip title={comp_name} placement='top'><Typography sx={{ fontSize: '0.8vw', fontWeight: 'bold' }}>{comp_name.slice(0, 18) + '...'}</Typography></Tooltip>
                    : <Typography sx={{ fontSize: '0.8vw', fontWeight: 'bold' }}>{comp_name}</Typography>}
                  {employee_no?.length > 18 ?
                    <Tooltip title={employee_no} placement='top'><Typography sx={{ fontSize: '0.7vw', color: '#888888' }}>{employee_no.slice(0, 18) + '...'}</Typography></Tooltip>
                    : <Typography sx={{ fontSize: '0.7vw', color: '#888888' }}>{employee_no}</Typography>}
                </Box>} /> :
              <Button size='small' variant="text" startIcon={<AddCircleIcon />} sx={{ textTransform: 'capitalize' }}
                onClick={() => {
                  this.setState({
                    addCorporate: true,
                    addInsurance: false,
                    paymentCreditType: 'corporatecredit',
                  })
                }}
              >
                Add Corporate</Button>}
          </Box>
        </Box>
        {this.state.addInsurance ?
          <Box marginTop={'1vw'} display={'flex'} height={'5vw'}>
            <Autocomplete
              size='small'
              id="combo-box-demo-ins"
              options={this.state.insuranceData}
              getOptionLabel={(options) => typeof (options) === 'string' ? options : options.insurance_name}
              onChange={(e, value) => {
                this.setState({
                  insuranceName: value,
                })
              }}
              value={this.state.insuranceName}
              sx={{ width: '13vw', marginRight: '1vw' }}
              renderInput={(params) => <TextField {...params} label="Insurance Company Name" />}
            />
            <TextField
              size='small'
              label="Insurance No"
              variant="outlined"
              sx={{ width: '13vw' }}
              value={this.state.insuranceNo}
              onChange={(e) => {
                let alphanum = CommonValidation.alphanumValidation(e.target.value)
                if (e.target.value === "" || alphanum) {
                  this.setState({
                    insuranceNo: e.target.value
                  })
                }
              }}
            />
          </Box> :
          this.state.addCorporate ?
            <Box marginTop={'1vw'} display={'flex'} height={'5vw'}>
              <Autocomplete
                size='small'
                id="combo-box-demo-ins"
                options={this.state.corporateData}
                getOptionLabel={(options) => typeof (options) === 'string' ? options : options.company_name}
                onChange={(e, value) => {
                  this.setState({
                    corpName: value,
                  })
                }}
                value={this.state.corpName}
                sx={{ width: '13vw', marginRight: '1vw' }}
                renderInput={(params) => <TextField {...params} label="Corporate Company Name" />}
              />
              <TextField
                size='small'
                label="Employee ID"
                variant="outlined"
                sx={{ width: '13vw' }}
                value={this.state.employeeNo}
                onChange={(e) => {
                  let alphanum = CommonValidation.alphanumValidation(e.target.value)
                  if (e.target.value === "" || alphanum) {
                    this.setState({
                      employeeNo: e.target.value
                    })
                  }
                }} />
            </Box> : <Box marginTop={'1vw'} display={'flex'} height={'5vw'}></Box>}
        <Box marginTop={'2vw'} display={'flex'} justifyContent={'flex-end'}>
          <Stack spacing={2} direction="row">
            <Button size='small' sx={{ textTransform: 'capitalize' }} variant="outlined"
              onClick={() => {
                this.setState({
                  creditChange: false,
                  addInsurance: false,
                  addCorporate: false,
                  paymentCreditType: this.state.selPayemtnCreditType ? this.state.selPayemtnCreditType : this.state.ipPatientData?.primary_payment_type === "Patient credit" ? 'patientcredit' :
                    this.state.ipPatientData?.primary_payment_type === "Corprate credit" ? 'corporatecredit' :
                      this.state.ipPatientData?.primary_payment_type === "Insurance credit" ? 'insurancecredit' : 'patientcredit',
                  insuranceName: null,
                  insuranceNo: '',
                  corpName: null,
                  employeeNo: '',
                  selInsName: this.state.selInsName ? this.state.selInsName : this.state.patientData?.insurance_name ? this.state.patientData?.insurance_name : '',
                  selPolicyNo: this.state.selPolicyNo ? this.state.selPolicyNo : this.state.patientData?.policy_no ? this.state.patientData?.policy_no : '',
                  selCompanyName: this.state.selCompanyName ? this.state.selCompanyName : this.state?.patientData?.employer_name ? this.state?.patientData?.employer_name : '',
                  selEmployeeNo: this.state.selEmployeeNo ? this.state.selEmployeeNo : this.state?.patientData?.id_no ? this.state?.patientData?.id_no : '',
                  selCompanyID: this.state.selCompanyID ? this.state.selCompanyID : this.state?.patientData?.employer_id ? this.state?.patientData?.employer_id : '',
                  DisableBtn: false,
                })
              }}>Back</Button>
            <Button size='small' sx={{ textTransform: 'capitalize' }} variant="contained" onClick={() => {
              if (this.state.addInsurance && (!this.state.insuranceName || !this.state.insuranceNo)) {
                this.successErrorMessgae("Invalid Insurance Details", 'error')
              } else if (this.state.addCorporate && (!this.state.corpName || !this.state.employeeNo)) {
                this.successErrorMessgae("Invalid Corporate Details", 'error')
              } else {
                this.state.patientData['is_billed'] = true
                this.setState({
                  creditChange: false,
                  selPayemtnCreditType: this.state.paymentCreditType,
                  selInsName: this.state.insuranceName?.insurance_name ? this.state.insuranceName?.insurance_name : this.state.selInsName ? this.state.selInsName : this.state.patientData?.insurance_name ? this.state.patientData?.insurance_name : '',
                  selPolicyNo: this.state.insuranceNo ? this.state.insuranceNo : this.state.selPolicyNo ? this.state.selPolicyNo : this.state.patientData?.policy_no ? this.state.patientData?.policy_no : '',
                  selCompanyName: this.state.corpName?.company_name ? this.state.corpName?.company_name : this.state.selCompanyName ? this.state.selCompanyName : this.state?.patientData?.employer_name ? this.state?.patientData?.employer_name : '',
                  selEmployeeNo: this.state.employeeNo ? this.state.employeeNo : this.state.selEmployeeNo ? this.state.selEmployeeNo : this.state?.patientData?.id_no ? this.state?.patientData?.id_no : '',
                  selCompanyID: this.state.corpName?.id ? this.state.corpName?.id : this.state.selCompanyID ? this.state.selCompanyID : this.state?.patientData?.employer_id ? this.state?.patientData?.employer_id : '',
                  patientData: this.state.patientData,
                  DisableBtn: false,
                })
              }
            }}>Submit</Button>
          </Stack>
        </Box>
      </Box>
    )
  }

  render() {
    const { t } = this.props
    let states = this.state
    let { billingList } = this.state
    let AppointmentDate = this.state.ipPatientData?.admission_date?.split("T")[0];
    let isRefundRequired = this.state.BillRefundData?.is_refund ? true : false
    let serviceAmt = {
      image: CurrencySymbol === '$' ? ImagePaths.Dollor.default : ImagePaths.Rupee.default,
      height: "1.3vw",
      width: "1.3vw",
      lineOne: t("BillAmount"),
      lineTwo: this.state.service_amount ? AmountFormat(this.state.service_amount) : `${CurrencySymbol} 0.00`,
      // secHead: t('PaidAmount'),
      // secAmt: AmountFormat(this.state.paid_amount ? this.state.paid_amount : "₹").replace("₹", "")
    }
    let PaidDetails = {
      image: CurrencySymbol === '$' ? ImagePaths.Dollor.default : ImagePaths.Rupee.default,
      height: "1.3vw",
      width: "1.3vw",
      lineOne: t("Received Amount"),
      lineTwo: this.state.paid_amount ? AmountFormat(this.state.paid_amount) : `${CurrencySymbol} 0.00`,
      // secHead: t('PaidAmount'),
      // secAmt: AmountFormat(this.state.paid_amount ? this.state.paid_amount : "₹").replace("₹", "")
    }

    let PendingDetails = {
      image: CurrencySymbol === '$' ? ImagePaths.Dollor.default : ImagePaths.Rupee.default,
      height: "1.3vw",
      width: "1.3vw",
      lineOne: t("BalAmt"),
      lineTwo: this.state.balance_amount ? AmountFormat(this.state.balance_amount) : `${CurrencySymbol} 0.00`,
      colorCode: +this.state.balance_amount > 0 ? 'green' : +this.state.balance_amount === 0 ? "balck" : 'red'
      // secHead: t('ApproxAmount'),
      // secAmt:  this.state.ApproxAmount ? `${AmountFormat(this.state.ApproxAmount).replace("₹", "")}` : "₹"
    }

    let ApproxDetails = {
      image: CurrencySymbol === '$' ? ImagePaths.Dollor.default : ImagePaths.Rupee.default,
      height: "1.3vw",
      width: "1.3vw",
      lineOne: t('ApproxAmount'),
      lineTwo: this.state.ApproxAmount ? `${AmountFormat(this.state.ApproxAmount)}` : `${CurrencySymbol} 0.00`,
      // lineOne: t("AdvanceAmount"),
      // lineTwo: AmountFormat(this.state.advance_amount ? this.state.advance_amount : "₹").replace("₹", "")
    }
    let AdvanceDetails = {
      image: CurrencySymbol === '$' ? ImagePaths.Dollor.default : ImagePaths.Rupee.default,
      height: "1.3vw",
      width: "1.3vw",
      lineOne: t('AdvanceAmount'),
      lineTwo: this.state.advance_amount ? `${AmountFormat(this.state.advance_amount)}` : `${CurrencySymbol} 0.00`,
    }
    let attender = {
      lineOne: t("AttenderName"),
      lineTwo: `${this.state.attender_name ? this.state.attender_name : "-"}`,
      lineFour: t('ContactNumber'),
      LineFive: `${this.state.attender_mobile ? this.state.attender_mobile : "-"}`
    }
    let ClaimDetails = {
      image: CurrencySymbol === '$' ? ImagePaths.Dollor.default : ImagePaths.Rupee.default,
      width: "1.3vw",
      height: "1.3vw",
      lineOne: t('Claimed Amount'),
      lineTwo: `${this.state.ApprovedAmt ? AmountFormat(this.state.ApprovedAmt) : `${CurrencySymbol} 0.00`}`
    }
    if (this.state.isLoader) {
      return <Loader loaderOpen={this.state.isLoader} />
    } else {
      return (
        <Box component={'div'} className='eMed_bill_container'>
          <Box component={'div'} className='' height={'11vh'}>
            <Box component={'div'} >
              <Box component={'div'} display={'flex'}>
                <Box component={'div'} className='eMed_nur_notes_details eMed_bill_header_one'>
                  <CommonPatientDetails data={this.state.ipPatientData} showDetailed={true} />
                </Box>
                {/* <CommonPatientCard details={attender} showDetailed={true} /> */}
                <CommonPatientCard details={AdvanceDetails} />
                <CommonPatientCard details={serviceAmt} showOnecard={true} smallWidth={true} />
                <CommonPatientCard details={PaidDetails} showOnecard={true} smallWidth={true} />
                <CommonPatientCard details={PendingDetails} showOnecard={true} smallWidth={true} />
                <CommonPatientCard details={ApproxDetails} smallWidth={true} />
                <CommonPatientCard details={ClaimDetails} smallWidth={true} />
              </Box>
            </Box>
          </Box>
          <Box component={'div'} className='eMed_bill_content' sx={{ marginTop: "-1vw" }}>
            <Grid container>
              <Grid item xs={8}>
                {this.renderLeftTableContent(this.state.billingList)}
              </Grid>
              <Grid item xs={4}>
                {this.state.ForCancel ? this.renderCancelContent() : this.renderRightContent()}
              </Grid>
            </Grid>
          </Box>
          {this.state.show ? <CreditBillPopUp
            data={this.state.data}
            serviceBilling={true}
            ClosePopUp={this.ClosePopUp.bind(this)}
            CrebillPosted={this.CrebillPosted.bind(this)}
            inVoiceId={this.state.invoice_id}

          /> : null}
          {this.state.isDeleteCliked ? <DeletePopup
            DeletTitle={`${t("deleteMsg")} ${this.state.deleteService ? this.state.deleteService : "-"} ?`}
            deleteAlertPopupClose={this.popupClose.bind(this)}
            removeData={this.handleDelete.bind(this)}
          /> : null}
          {this.state.isDeleteAll && this.state.billingList.length > 0 ? <DeletePopup
            DeletTitle={`${t("deleteMsg")} ${t("All")} ?`}
            deleteAlertPopupClose={this.popupClose.bind(this, "all")}
            removeData={this.onClearLeftContent.bind(this)}
          /> : null}
          {this.state.isErrorMsg ?
            <ToastMsg
              severity={'error'}
              msg={this.state.isErrorMsgText}
              msgPop={this.msgClose.bind(this)}
            />
            : null}
          {this.state.successMsg ?
            <ToastMsg
              severity={'success'}
              msg={this.state.successMsgText}
              msgPop={this.msgClose.bind(this)}
            />
            : null}
          {this.state.Timepopup ?
            <Modal open={true}>
              <Paper elevation={3} id="emedhub_popup_mainBox" sx={{ width: "37vw", height: "12vw" }}>
                <div id="emedhub_popup_header" style={{ backgroundColor: Colors.DataTblHeaderbg }}>
                  <Tooltip placement='top' title={states?.selectedService?.service_name} arrow>
                    <Typography sx={{ color: "#616161", fontWeight: 600 }}>{`Kindly Select Date and Time of ${states?.selectedService?.service_name?.length > 22 ?
                      states?.selectedService?.service_name.slice(0, 20) + "..." : states?.selectedService?.service_name}`}
                    </Typography>
                  </Tooltip>
                  <IconButton onClick={() => { this.buttonOneAction() }} size="small" ><img className="eMed_DeletePopup_CrossIcon" src={ImagePaths.LabCloseButton.default} alt={"close"} /></IconButton>
                </div>
                <div className='emedhub_DatePicker_container'>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      value={states?.selectedService?.start_date}
                      name={"datePicker"}
                      open={this.state.startTimeOpen}
                      onOpen={() => { this.setState({ startTimeOpen: true }) }}
                      onClose={() => { this.setState({ startTimeOpen: false }) }}
                      inputFormat='DD-MM-YYYY hh:mm A'
                      label={t('Start Date & Time')}
                      minDate={AppointmentDate}
                      maxDate={new Date()}
                      minTime={AppointmentDate === this.DateConvertion(new Date(states?.selectedService?.start_date)) ? moment(this.state.ipPatientData?.admission_date) : null}
                      onChange={
                        (newDate) => {
                          billingList[states?.selectedIndex]["start_date"] = newDate
                          this.setState({
                            billingList
                          }, () => { this.CalculateTimeDiff() })
                        }}
                      renderInput={(params) => <TextField onClick={() => { this.setState({ startTimeOpen: true }) }} onKeyDown={(e) => { e.preventDefault() }} style={{ width: '17vw' }} size='small' {...params} />}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      value={states?.selectedService?.end_date ? states?.selectedService?.end_date : null}
                      name={"datePicker"}
                      open={this.state.endTimeOpen}
                      onOpen={() => { this.setState({ endTimeOpen: true }) }}
                      onClose={() => { this.setState({ endTimeOpen: false }) }}
                      inputFormat='DD-MM-YYYY hh:mm A'
                      label={t('End Date & Time')}
                      minDate={states?.selectedService?.start_date}
                      maxDate={new Date()}
                      minTime={this.DateConvertion(new Date(states?.selectedService?.start_date)) === this.DateConvertion(new Date(states?.selectedService?.end_date)) ? this.TimeConvertion(new Date(states?.selectedService?.start_date)) : null}
                      onChange={
                        (newDate) => {
                          billingList[states?.selectedIndex]["end_date"] = newDate ? newDate : null
                          this.setState({
                            billingList
                          }, () => { this.CalculateTimeDiff() })
                        }}
                      renderInput={(params) => <TextField onClick={() => { this.setState({ endTimeOpen: true }) }} onKeyDown={(e) => { e.preventDefault() }} style={{ width: '17vw' }} size='small' {...params} />}
                    />
                  </LocalizationProvider>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginTop: "1vw" }}>
                  <Button size="small" sx={{ marginRight: "1vw", textTransform: "capitalize" }} variant={"outlined"} onClick={() => { this.buttonOneAction() }}>{"Cancel"}</Button>
                  <Button variant="contained" size="small" sx={{ textTransform: "capitalize" }} onClick={() => { this.buttonTwoAction(this.state?.selectedService) }}>{"Add"}</Button>
                </div>
              </Paper>
            </Modal>
            : null
          }
          {this.state.SuccessInvoice_ID !== null ? <BillSuccessPoPUp isDirectPrint={true} InvoiceId={this.state.SuccessInvoice_ID} Receipt_id={this.state.bill_receipt_id} isFromsmsIP={true} patientID={this.state.ipPatientData?.patient_id} ClosePopUp={this.ClosePopUp.bind(this)} /> : null}
          {this.state.cancelBillClicked ?
            <ReasonPopupWithRefund
              title={"Cancel Bill"}
              AlertPopupClose={this.ReasonPopupClose.bind(this)}
              label={"Enter reason for cancel"}
              btntext={'Close'}
              btnvarient={'outlined'}
              btncolor={'error'}
              btntext1={'Confirm'}
              btnvarient1={'contained'}
              IPRefundPost={this.IPRefundPost.bind(this)}
              fromIP={true}
              ShowAdvance={this.state?.isCurrentIp ? this.state?.isCurrentIp : false}
              payableAmount={this.state.totalPayableAmount}
              BillReceivedAmount={this.state.BillReceivedAmount}
              paymentMode={this.state.paymentMode} />
            : null
          }
          {this.discountReasonPopup()}
          {this.serviceIndividualDiscountPopup()}
          {this.splitScreenPopup()}
          {this.state.creditChange ?
            <Dialog
              className="emed_DialogboxOrder"
              open={this.state.creditChange}
              onClose={() => { this.setState({ creditChange: false }) }}
              maxWidth={"md"}
            >
              <div className='emed_Dialog_Appoin' style={{ width: '42vw', height: "45vh" }}>
                {this.renderCreditType()}
              </div>
            </Dialog> : null}
        </Box>
      )
    }
  }
}


export default withTranslation()(ServiceBilling)