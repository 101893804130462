import { Box, Button, ButtonGroup, Drawer, Stack, Tooltip } from '@mui/material'
import React, { Component } from 'react'
import '../pharmaReports.css';
import { Colors } from '../../../../Styles/Colors';
import { AmountsCard } from '../../../../Components/CommonCards/CommonCards';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { DataGrid } from '@mui/x-data-grid';
import { CommonGridToolBarWithFilterTextCustom } from '../../../../Components/Common Components/CommonComponents';
import { AmountFormat, formatDate, splitDate } from '../../../../Components/CommonFunctions/CommonFunctions';
import { withTranslation } from 'react-i18next';
import PharmaFilter from '../PharmaFilter';
import { DateTime } from 'luxon';
import { CurrencySymbol } from '../../../../Utility/Constants';

class PurchaseBillTaxWise extends Component {
  constructor(props) {
    super(props)
    this.state = {
      billTaxWiseData: [],
      page: 0,
      pageSize: 10,
      rowCount: 0,
      filterDatas: {
        'fromDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
        'toDate': DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
        "dateIndex": 1,
      },
      pageName: 'purchaseBillTaxWise',
      isForReturn: false,
      filterOpen: false,
      cgst: 0,
      sgst: 0,
      netAmount: 0,
      taxableAmount: 0,
      isLoader: false,

    }
  }

  componentDidMount = () => {
    this.getBillTaxWiseData()
  }

  getBillTaxWiseData = () => {
    try {
      this.LoaderFunction(true);
      let url = ''
      this.state.isForReturn ? url = Serviceurls.PHARMA_PURCHASE_BILL_RETURN_TAXWISE_GET : url = Serviceurls.PHARMA_PURCHASE_BILL_TAXWISE_GET
      RestAPIService.getAll(url + `?from_date=${this.state.filterDatas.fromDate}&to_date=${this.state.filterDatas.toDate}&page=${this.state.page + 1}&page_size=${this.state.pageSize}`)
        .then((response) => {
          if (response.data.status === 'success') {
            this.LoaderFunction(false);
            this.setState({
              billTaxWiseData: response.data?.data?.result,
              netAmount: response.data?.data?.total_net_amount,
              taxableAmount: response.data?.data?.total_taxable_amount,
              cgst: response.data?.data?.total_cgst_amount,
              sgst: response.data?.data?.total_sgst_amount,
              rowCount: response.data?.data?.total_count,
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.LoaderFunction(false);
            this.errorMessage(error.response.data.message)
          } else {
            this.LoaderFunction(false);
            this.errorMessage(error.message)
          }
          this.ClearData()
        })
    } catch (e) {
      this.errorMessage(e.message)
      this.LoaderFunction(false)
      this.ClearData()
    }
  }

  getBillTaxWiseExcel = () => {
    try {
      this.setState({ disableBtn: true })
      let url =''
      this.state.isForReturn ? url = Serviceurls.PHARMA_PURCHASE_BILL_RETURN_TAXWISE_GET : url = Serviceurls.PHARMA_PURCHASE_BILL_TAXWISE_GET
      RestAPIService.excelGet(url + `?from_date=${this.state.filterDatas.fromDate}&to_date=${this.state.filterDatas.toDate}&page=${this.state.page + 1}&page_size=${this.state.pageSize}&export_type=excel`).
        then((response) => {
          if (response.data) {
            var pom = document.createElement('a');
            var csvContent = response.data; //here we load our csv data 

            let filename = response.headers["content-disposition"].split("filename=")[1]
            // var blob = new Blob([csvContent], {type: 'data:application/vnd.ms-excel;base64'});

            var blob = new Blob([csvContent], { type: 'application/x-www-form-urlencoded; charset=UTF-8' }); // text/csv;charset=utf-8;
            var url = URL.createObjectURL(blob);
            pom.href = url;
            pom.setAttribute('download', filename ? filename : 'registry.xlsx');
            pom.click();
            this.setState({ disableBtn: false })
          }
        }).catch((error) => {
          if (error.response?.data?.message) {
            this.errorMessage(error.response?.data?.message);
          } else {
            this.errorMessage(error.message);
          }
          this.setState({ disableBtn: false })
        })
    } catch (error) {
      this.errorMessage(error.message);
      this.setState({ disableBtn: false })
    }
  }

  ClearData = ()=>{
    this.setState({
      billTaxWiseData: [],
      cgst: 0,
      sgst: 0,
      netAmount: 0,
      taxableAmount: 0,
    })
  }


  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  renderFilter = (data) => {
    this.setState({
      filterDatas: data,
      filterOpen: false,
      page: 0,
    }, () => { this.getBillTaxWiseData() })
  }

  ClosePopUp() {
    this.setState({
      filterOpen: false,
    })
  }

  clearFilter = (data) => {
    this.setState({
      FilterDatas: data
    })
  }

  gridToolBar = () => {
    let TextArray = [
      { label: "From Date", value: formatDate(this.state.filterDatas.fromDate) },
      { label: "To Date", value: formatDate(this.state.filterDatas.toDate) },
    ]
    let ExportData = []
    this.state.billTaxWiseData?.length > 0 && this.state.billTaxWiseData?.map((item) =>
        ExportData.push({
            "S No": item.sno,
            "Bill No": item.bill_number,
            "Bill Date": formatDate(item.bill_date),
            "Vendor Name": item.party_name,
            "State": item.state,
            "Tax Rate": item.gst_percentage,
            "Taxable Amount": item.taxable_amount,
            "CGST": item.cgst_amount,
            "SGST": item.sgst_amount,
            "IGST": item.igst_amount,
            "Net Amount": item.total_net_amount,
        }))
    return (
      <Box>
        <CommonGridToolBarWithFilterTextCustom FilterTextArray={TextArray} data={ExportData} filename={"Purchase Bill Tax Wise"} title={"Purchase Bill Tax Wise"} />
      </Box>

    )
  }


  render() {
    const { t } = this.props
    this.state.billTaxWiseData?.forEach((element, index) => element.sno = index + 1)
    const columns = [
      {
        field: "s_no", flex: 0.01, headerName: t("SNo"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.s_no ? params?.row?.s_no : "-"}</Box>)
      },
      {
        field: 'bill_number', headerName: "Bill No", flex: 0.15,
        renderCell: (params) => (<Box component={'div'}>
          {params?.row?.bill_number?.length > 20 ? <Tooltip placement='top' title={params?.row?.bill_number} arrow><div>{'...' + params?.row?.bill_number?.slice(10, 20)}</div></Tooltip> :
            params?.row?.bill_number ? params?.row?.bill_number : '-'}
        </Box>)
      },
      {
        field: 'bill_date', headerName: "Bill Date", flex: 0.10, headerAlign: "center", align: "center",
        renderCell: (params) => {
          let bill_date = params?.row?.bill_date ? splitDate(params?.row?.bill_date) : "-";
          return (<Box component={'div'}>
            {params?.row?.bill_date ? bill_date : '-'}
          </Box>)
        }
      },
      {
        field: 'party_name', headerName: "Vendor Name", flex: 0.18,
        renderCell: (params) => (<Box component={'div'}>{params.row.party_name ? 
          params.row.party_name?.length > 18 ?  <Tooltip placement='top' title={params?.row?.party_name} arrow><div>{params?.row?.party_name?.slice(0, 15) + "..."}</div></Tooltip> : params.row.party_name : '-'}</Box>)
      },
      {
        field: 'state', headerName: "State", flex: 0.09,
        renderCell: (params) => (<Box component={'div'}>{params.row.state ? params.row.state.length > 16 ? <Tooltip placement='top' title={params?.row?.state} arrow><div>{params?.row?.state?.slice(0,12) + '...'}</div></Tooltip> : params.row.state : '-'}</Box>)
      },
      {
        field: "gst_percentage", flex: 0.11, headerName: t("Tax Rate (%)"), type: "number",
        renderCell: (params) => {
            return (
                <Box component={'div'}>{params.row.gst_percentage ? params.row.gst_percentage : "0"}</Box>
            )
        }
      },
      {
        field: "taxable_amount", flex: 0.11, headerName: t(`Taxable Amount (${CurrencySymbol})`), type: "number",
        renderCell: (params) => {
          return (
            <Box component={'div'}>{params.row.taxable_amount ? AmountFormat(params.row.taxable_amount)?.replace(`${CurrencySymbol}`, "") : "0.00"}</Box>
          )
        }
      },
      {
        field: "cgst_amount", flex: 0.11, headerName: t(`CGST (${CurrencySymbol})`), type: "number",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.cgst_amount ? AmountFormat(params?.row?.cgst_amount)?.replace(`${CurrencySymbol}`, "") : "0.00"}</Box>)
      },
      {
        field: "sgst_amount", flex: 0.11, headerName: t(`SGST (${CurrencySymbol})`), type: "number",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.sgst_amount ? AmountFormat(params?.row?.sgst_amount)?.replace(`${CurrencySymbol}`, "") : "0.00"}</Box>)
      },
      {
        field: 'igst_amount', headerName: `IGST (${CurrencySymbol})`, flex: 0.07, type: "number",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.igst_amount ? AmountFormat(params?.row?.igst_amount)?.replace(`${CurrencySymbol}`, "") : "0.00"}</Box>)
      },
      {
        field: "total_net_amount", flex: 0.13, headerName: t(`Net Amount (${CurrencySymbol})`), type: "number",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.total_net_amount ? AmountFormat(params?.row?.total_net_amount)?.replace(`${CurrencySymbol}`, "") : "0.00"}</Box>)
      },

    ]
    return (
      <Box component={'div'} height={'80vh'} sx={{ backgroundColor: Colors.Background }}>
        <Box component={'div'} className='eMed_Pharma_rts_header'>
          <Box component={'div'} flex={0.8} display={'flex'}>
            {AmountsCard("Taxable Amount", this.state.taxableAmount, false, "white", false, null, 'rpt_saleDlt_tax_amt')}
            {AmountsCard("CGST", this.state.cgst, false, "white", false, null, 'rpt_saleDlt_cgst')}
            {AmountsCard("SGST", this.state.sgst, false, "white", false, null, 'rpt_saleDlt_sgst')}
            {AmountsCard("Net Amount", this.state.netAmount, false, "white", false, null, 'rpt_saleDlt_net_amt')}
          </Box>
          <Box flex={0.8} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} marginRight={'0.5vw'}>
            <ButtonGroup sx={{ marginRight: '1vw' }}>
              <Button variant={this.state.isForReturn ? 'outlined' : 'contained'} sx={{ width: "8.5vw", textTransform: 'capitalize' }} onClick={() => {
                this.setState({ isForReturn: false }, () => { this.getBillTaxWiseData() })
              }}>{t("Purchase")}</Button>
              <Button variant={this.state.isForReturn ? 'contained' : 'outlined'} sx={{ width: "8.5vw", textTransform: 'capitalize' }} onClick={() => {
                this.setState({ isForReturn: true }, () => { this.getBillTaxWiseData() })
              }}>{t("PO Return")}</Button>
            </ButtonGroup>
            <Button emed_tid="rpt_consoli_flt" className='eMed_Pharma_rts_btn' onClick={() => this.setState({ filterOpen: true })}>
              <Box component={'img'} src={ImagePaths.Filter.default} alt='filter' height={'2.3vw'} width={'2.3vw'} />
            </Button>
            <Button emed_tid="rpt_saleDlt_export" className='eMed_Pharma_rts_btn' disabled={this.state.disableBtn} onClick={() => this.getBillTaxWiseExcel()}>
              <Box component={'img'} src={ImagePaths.ExportIcon.default} alt='export' height={'2.3vw'} width={'2.3vw'} />
            </Button>
          </Box>
        </Box>
        <Box component={'div'} className='eMed_Pharma_rts_tbl_card'>
          <DataGrid
            className='eMed_Pharma_table'
            rows={this.state.billTaxWiseData}
            columns={columns}
            page={this.state.page}
            pageSize={this.state.pageSize}
            getRowId={(row) => row['sno']}
            localeText={{
              toolbarColumns: "",
              toolbarDensity: "",
              toolbarExport: "",
              toolbarFilters: "",
              toolbarExportPrint: ""
            }}
            components={{
              Toolbar: this.gridToolBar,
              NoRowsOverlay: () => (
                <Stack className='eMed_conf_nodata'>
                  {t("NoRecordsFound")}
                </Stack>
              )
            }}
            rowCount={this.state.rowCount}
            onPageChange={(newPage) => { this.setState({ page: newPage }, () => this.getBillTaxWiseData()) }}
            onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize }, () => this.getBillTaxWiseData())}
            rowsPerPageOptions={[10, 20, 30]}
            headerHeight={40}
            disableSelectionOnClick
            pagination
            paginationMode='server'
            loading={this.state.isLoader}
          />
        </Box>

        <Drawer
          anchor={'right'}
          open={this.state.filterOpen}
          ModalProps={{ onBackdropClick: this.ClosePopUp.bind(this) }}
        >
          <PharmaFilter pageName={this.state.pageName} filterDatas={this.state.filterDatas} closeFilter={this.ClosePopUp.bind(this)} filteredData={this.renderFilter.bind(this)} ClearData={this.clearFilter.bind(this)} />
        </Drawer>

        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
      </Box>
    )
  }
}
export default withTranslation()(PurchaseBillTaxWise)