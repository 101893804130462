import React, { Component } from 'react'
import { TextField, IconButton, Tooltip, Box, Typography, Button, Menu, MenuItem, } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ImagePaths } from '../../../Utility/ImagePaths';
import CommonGridHeader, { AppointmentCard } from '../../../Components/Common Components/CommonComponents';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import './RadHome.css'
import { DataGrid } from '@mui/x-data-grid';
import { Colors } from '../../../Styles/Colors';
import PaymentsIcon from '@mui/icons-material/Payments';
import ReportIcon from '@mui/icons-material/Report';
import { AmountFormat } from '../../../Components/CommonFunctions/CommonFunctions';
import { localGetItem, setCachevalue } from '../../../Utility/Services/CacheProviderService';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import { DateTime } from 'luxon';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export default class RadPatientHome extends Component {
  constructor(props) {
    super(props)
    this.state = {
      datePicker: new Date(),
      DatePickerOpen: false,
      patientDetailsData: [],
      selectedCard: 'Total Test',
      page: 0,
      pageSize: 10,
      getPatient: false,
      loading: false,
      inProgress: 0,
      completed: 0,
      cancelled: 0,
      allTest: 0,
      newPatientCount: 0,
      reviewPatientCount: 0,
      dispatched:  0,
      oldPatientCount: 0
    }
  }

  componentDidMount() {
    this.getRadPateintDetails()
  }

  getRadPateintDetails = () => {
    try {
      var states = this.state
      var StateDate = new Date(states.datePicker)
      var date = states.datePicker ? DateTime.fromJSDate(StateDate).toFormat('yyyy-MM-dd') : null

      this.setState({ loading: true })
      RestAPIService.getAll(Serviceurls.RAD_PATIENT_APPOINTMENT_LIST + '?date=' + date + '&status=' + this.state.selectedCard)
        .then((response) => {
          if (response.data.status === 'success') {
            setCachevalue(JSON.stringify(this.state.datePicker), 'RadAppointmentDate')
            this.setState({
              patientDetailsData: response.data.data,
              loading: false,
              inProgress: response.data.service_counts?.in_progress,
              completed: response.data.service_counts?.completed,
              dispatched: response.data.service_counts?.dispatched ? response.data.service_counts?.dispatched : 0,
              cancelled: response.data.service_counts?.cancelled,
              allTest: (response.data.service_counts?.in_progress + response.data.service_counts?.completed +response.data.service_counts?.dispatched),
              newPatientCount: response.data.patient_type_count?.new_patient,
              reviewPatientCount: response.data.patient_type_count?.review_patient,
              oldPatientCount: response.data.patient_type_count?.old_patient,
            })
          }
        }).catch((error) => {
          this.setState({ loading: false })
          if (error?.response?.data?.message) {
            this.errorMessage(error?.response?.data?.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ loading: false })
      this.errorMessage(error.message)
    }
  }
  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  getPatientReportPrint = (id) => {
    try {
      this.setState({ disableBtn: true })
      var states = this.state
      var StateDate = new Date(states.datePicker)
      var date = states.datePicker ? DateTime.fromJSDate(StateDate).toFormat('yyyy-MM-dd') : null
      RestAPIService.getAll(Serviceurls.RAD_PATIENT_APPOINTMENT_LIST + '?date=' + date + '&status=' + this.state.selectedCard + '&export=pdf').
        then((response) => {
          this.setState({ disableBtn: false })
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }).catch((error) => {
          this.setState({ disableBtn: false })
          this.errorMessage(error.response?.data?.message, false);
        })
    } catch (error) {
      this.errorMessage(error.message, false)
    }
}

  renderHeader = () => {
    try {
      return (
        <div className='rad_home_header_card'>
          <p>{'Radiology'}</p>
          <div className='rad_home_header'>
            <Tooltip placement='top' title={'Print'} arrow>
              <Button className='Common_Btn_Min_Width' sx={{ height: '2vw' }} emed_tid='rad_rpts_prt_pat'
                disabled={this.state.disableBtn}
                onClick={() => this.getPatientReportPrint()}
              >
                <Box component={'img'} src={ImagePaths.LabPrintIcon.default} height={'2vw'} width={'2vw'} />
              </Button>
            </Tooltip>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                open={this.state.DatePickerOpen}
                onOpen={() => { this.setState({ DatePickerOpen: true }) }}
                onClose={() => { this.setState({ DatePickerOpen: false }) }}
                value={this.state.datePicker}
                name={"datePicker"}
                inputFormat='DD-MM-YYYY'
                views={["year", "month", "day"]}
                onChange={
                  (newDate) => {
                    this.setState({
                      datePicker: newDate,
                      selectedCard: 'Total Test'
                    }, () => {
                      setCachevalue(JSON.stringify(this.state.datePicker), 'RadAppointmentDate')
                      this.getRadPateintDetails()
                    });
                  }}
                renderInput={(params) => <TextField onKeyDown={(e) => e.preventDefault()}
                  style={{ width: '10vw' }} size='small' {...params}
                  onClick={() => { this.setState({ DatePickerOpen: true }) }} />}
              />
            </LocalizationProvider>
          </div>
        </div>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderPatientCards = () => {
    try {
      let states = this.state
      return (
        <div className='rad_home_card_container'>
          <div className='rad_home_header' >
            <div>
              <Tooltip title={'Create Appointment'} placement='top' arrow>
                <IconButton emed_tid='rad_crte_pat'
                  onClick={() => { this.props.history?.push({ pathname: "/RadiologyHome/Demographics" }) }}
                >
                  <img className='rad_home_icon' src={ImagePaths.AddNewPatient.default} alt={"Add Patient"} />
                </IconButton>
              </Tooltip>
            </div>
            <div className='eMed_OP_PatientTypeCont'>
              <p className='rad_home_font' emed_tid='rad_new_pat'><img className='rad_home_icon_circle' src={ImagePaths.NewPatient.default} alt={'New Patient'} /> {'New Patient' + ' (' + (states.newPatientCount ? states.newPatientCount : '0') + ')'}</p>
              <p className='rad_home_icon_est' emed_tid='rad_rev_pat'><img className='rad_home_icon_circle' src={ImagePaths.EstablishedPatient.default} alt={'Review Patient'} /> {'Review Patient' + ' (' + (states.reviewPatientCount ? states.reviewPatientCount : '0') + ')'}</p>
              <p className='rad_home_icon_est' emed_tid='rad_old_pat'><img className='rad_home_icon_circle' src={ImagePaths.ReviewPatient.default} alt={'Old Patient'} /> {'Old Patient' + ' (' + (states.oldPatientCount ? states.oldPatientCount : '0') + ')'}</p>
            </div>
          </div>
          <div>
            <div className='rad_home_cards'>
              {/* <AppointmentCard emed_tid='rad_all_card' onClick={() => {
                this.setState({ selectedCard: '' }, () => {
                  setCachevalue(JSON.stringify(this.state.selectedCard), 'RadAppointmentStatus')
                  this.getRadPateintDetails()
                })
              }} count={this.state.allTest} label={"All"} color={"#616161"} isSelectedCard={states.selectedCard === "" ? true : false} /> */}
              <AppointmentCard emed_tid='rad_tlt_tst_card' onClick={() => {
                this.setState({ selectedCard: 'Total Test' }, () => {
                  setCachevalue(JSON.stringify(this.state.selectedCard), 'RadAppointmentStatus')
                  this.getRadPateintDetails()
                })
              }} count={this.state.allTest} label={"Total Test"} color={"#616161"} isSelectedCard={states.selectedCard === "Total Test" ? true : false} />
              <AppointmentCard emed_tid='rad_in_prgs_card' onClick={() => {
                this.setState({ selectedCard: 'In Progress' }, () => {
                  setCachevalue(JSON.stringify(this.state.selectedCard), 'RadAppointmentStatus')
                  this.getRadPateintDetails()
                })
              }} count={this.state.inProgress} label={"In Progress"} color={"#0461B7"} isSelectedCard={states.selectedCard === "In Progress" ? true : false} />
              <AppointmentCard emed_tid='rad_cmp_card' onClick={() => {
                this.setState({ selectedCard: 'Completed' }, () => {
                  setCachevalue(JSON.stringify(this.state.selectedCard), 'RadAppointmentStatus')
                  this.getRadPateintDetails()
                })
              }} count={this.state.completed} label={'Completed'} color={"#1E972A"} isSelectedCard={states.selectedCard === "Completed" ? true : false} />
                <AppointmentCard emed_tid='rad_cmp_card' onClick={() => {
                this.setState({ selectedCard: 'Dispatched' }, () => {
                  setCachevalue(JSON.stringify(this.state.selectedCard), 'RadAppointmentStatus')
                  this.getRadPateintDetails()
                })
              }} count={this.state.dispatched} label={'Dispatched'} color={"#1E972A"} isSelectedCard={states.selectedCard === "Dispatched" ? true : false} />
              {/* <AppointmentCard emed_tid='rad_cal_card' onClick={() => {
                this.setState({ selectedCard: 'Cancelled' }, () => {
                  setCachevalue(JSON.stringify(this.state.selectedCard), 'RadAppointmentStatus')
                  this.getRadPateintDetails()
                })
              }} count={this.state.cancelled} label={'Cancelled'} color={"#B8170D"} isSelectedCard={states.selectedCard === "Cancelled" ? true : false} /> */}
            </div>
          </div>
        </div>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  PaymentStatus(BillAmount, BalanceAmount) {
    return (
      <Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <PaymentsIcon sx={{ fontSize: "0.9vw", marginRight: '0.4vw', color: '#616161' }} />
          <Typography fontSize={"0.9vw"}>{`Bill : ${AmountFormat(BillAmount || 0)} `}</Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <ReportIcon sx={{ fontSize: "0.9vw", marginRight: '0.4vw', color: "#B8170D" }} />
          <Typography color={"#B8170D"} fontSize={"0.9vw"}>{`Balance : ${AmountFormat(BalanceAmount || 0)} `}</Typography>
        </Box>
      </Box>
    )
  }

  patientDetails = (item) => {
    try {
      return (
        <Box component={'div'} display={'flex'} alignItems={'center'}>
          <Box component={'div'} display={'flex'} alignItems={'center'} mr={'0.2vw'}>
            <Box component={'img'}
              src={item?.patient__photo_url === null || item?.patient__photo_url === "" || item?.patient__photo_url === undefined ? item?.patient_gender === "Male" || item?.patient_gender === "m" || item?.gender === "m" ? ImagePaths.Male.default : ImagePaths.Female.default : item?.patient__photo_url} height={'2.8vw'} width={'2.8vw'}
              border={`1.8px solid ${(item?.patient__patient_type === 1) ? "#F1584A" : (item?.patient__patient_type === 3) ? "#800080" : '#29B6F6'}`}
              borderRadius={'50px'}
            />
          </Box>
          <Box component={'div'}>
            {item?.name ?
              item?.name?.length > 14 ?
                <Tooltip placement='top' title={item?.name} arrow><Typography component={'div'} fontWeight={600} fontSize={'0.9vw'}>{item?.name?.slice(0, 14) + '...'}
                  <Typography component={'span'} fontSize={'0.9vw'} fontWeight={600}>{` ${item?.patient_age_str ? item?.patient_age_str : '-'}/${item?.gender ? item?.gender[0] : '-'}`}</Typography>
                </Typography></Tooltip> :
                <Typography component={'div'} fontWeight={600} fontSize={'0.9vw'}>{item?.name}
                  <Typography component={'span'} fontSize={'0.9vw'} fontWeight={600}>{` ${item?.patient_age_str ? item?.patient_age_str : '-'}/${item?.gender ? item?.gender[0] : '-'}`}</Typography>
                </Typography>
              : '-'}
            <Typography component={'div'} color={Colors.grayShade} fontSize={'0.8vw'}>{item?.patient_account_number ? item?.patient_account_number : '-'} |
              <Typography component={'span'} fontSize={'0.8vw'} color={Colors.grayShade}>{item?.patients_mobile ? item?.patients_mobile : '-'}</Typography>
            </Typography>
          </Box>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  routeToDemograhics = (params) => {
    console.log(params);
    let { history } = this.props;
    setCachevalue(JSON.stringify(params?.row), 'RadPatientData')
    if (params?.field !== 'action') {
      this.props.history.push({ pathname: '/RadiologyHome/Patient/Reports/TestReports' })
    }
  }

  getPatientOpCardPrint = (data) => {
    try {
      RestAPIService.getAll(Serviceurls.OP_CARD_PRINT + "?patient_id=" + data?.patient_id + "&appointment_id=" + data?.appointment_id + "&header=false"
      ).
        then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }).catch((error) => {
          this.errorMessage(error.response?.data?.message);
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getAdmissionCard = (data) => {
    try {
      RestAPIService.getAll(Serviceurls.IP_PATIENT_ADMISSION_PRINT + "?ip_admission_id=" + data?.ip_admission_id).
        then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.setState({
            selectedOption: ""
          })
        }).catch((error) => {
          this.errorMessage(error.response?.data?.message, false);
        })
    } catch (error) {
      this.errorMessage(error.message, false)
    }
  }

  MenuItem = (data) => {
    const { t } = this.props
    let RoleData = localGetItem("loggedInUserInfo") === null ? {} : JSON.parse(localGetItem("loggedInUserInfo"))
    let multi_option = []

    if (data?.ip_admission_id !== null) {
      multi_option = [
        { value: 'AdmissionCard', label: 'Admission Card' },
        { value: 'PatientCard', label: 'Patient Card' },
      ]
    } else if (data?.appointment_id !== null) {
      multi_option = [
        { value: 'OPCard', label: 'OP Card' },
        { value: 'PatientCard', label: 'Patient Card' },
      ]
    } else {
      multi_option = [{ value: 'PatientCard', label: 'Patient Card' },]
    }
    return (
      <div>
        <Tooltip title={("More")} placement='top' arrow>
          <IconButton
            onClick={(e) => {
              this.setState({
                anchorEl: e.currentTarget,
                selectedRow: data.patient_id,
                isSelected: true
              })
            }}
          >
            <MoreVertIcon />
          </IconButton>
        </Tooltip>
        {(this.state.isSelected && this.state.selectedRow == data.patient_id) ?
          <Menu
            key={data?.patient_id}
            elevation={1}
            anchorEl={this.state.anchorEl}
            open={Boolean(this.state.anchorEl)}
            onClose={() => {
              this.setState({ anchorEl: null, })
            }}>
            {multi_option.map((option, index) => (
              <MenuItem key={index} emed_tid={`${option?.value}`}
                onClick={() => {
                  if (option?.value === "OPCard") {
                    this.getPatientOpCardPrint(data)
                  }else if(option?.value === "AdmissionCard"){
                    this.getAdmissionCard(data)
                  }
                }}
              >
                {(option.label)}
              </MenuItem>
            ))}
          </Menu> : null}
      </div>
    )
  }

  renderDatagridTable = () => {
    try {
      this.state.patientDetailsData?.forEach((element, index) => element.sno = index + 1)
      const columns = [
        {
          field: 'name', headerName: 'Patient Details', flex: 0.166,
          renderCell: (params) => (
            <Box component={'div'} emed_tid='rad_pat_dts'>
              {this.patientDetails(params?.row)}
            </Box>)
        },
        {
          field: 'module', headerName: 'Patient Type', flex: 0.166,
          renderCell: (params) => (<Box component={'div'} emed_tid='rad_pat_modle'>
            <Typography fontSize={'0.9vw'} fontWeight={600}>{params?.row?.module}</Typography>
            <Typography fontSize={'0.8vw'} color={Colors.grayShade}>
              {params?.row?.ip_admission__ip_number ? params?.row?.ip_admission__ip_number : params?.row?.appointment__op_number ? params?.row?.appointment__op_number : '-'}
            </Typography>
          </Box>)
        },
        {
          field: 'net_amount', headerName: 'Payment Status', flex: 0.166,
          renderCell: (params) => (
            <div emed_tid='rad_pat_pay_sts'>{this.PaymentStatus(params.row?.net_amount ? params.row?.net_amount : 0, params.row?.balance_amount ? params.row?.balance_amount : 0)}</div>)
        },
        {
          field: 'test_name', headerName: 'Test Name', flex: 0.166,
          renderCell: (parmas) => (<Box component={'div'} emed_tid='rad_pat_tst_name'>
            <Typography fontSize={'0.9vw'}>{parmas?.row?.test_name ? parmas?.row?.test_name?.length > 20 ?
              <Tooltip placement='top' title={parmas?.row?.test_name} arrow>
                <Typography fontSize={'0.9vw'}>{parmas?.row?.test_name?.slice(0, 20) + '...'}</Typography>
              </Tooltip> :
              <Typography fontSize={'0.9vw'}>{parmas?.row?.test_name}</Typography> : '-'}</Typography>
          </Box>)
        },
        {
          field: 'total_test', headerName: 'Total Test', flex: 0.166,
          renderCell: (parmas) => (<Box component={'div'} emed_tid='rad_pat_tlt_tst'>
            <Typography fontSize={'0.9vw'}>{`${parmas?.row?.completed_test ? parmas?.row?.completed_test : 0} / ${parmas?.row?.total_test ? parmas?.row?.total_test : 0}`}</Typography>
          </Box>)
        },
        {
          field: 'action', headerName: 'Action', flex: 0.166, align: 'center', headerAlign: 'center',
          renderCell: (params) => (<Box component={'div'} display={'flex'}>
            <Tooltip placement='top' title='Create Bill' arrow>
              <Button className='Common_Btn_Min_Width' emed_tid='rad_crte_bill'
                onClick={() => {
                  setCachevalue(JSON.stringify(params?.row), 'RadPatientData')
                  this.props.history.push({ pathname: '/RadiologyHome/Patient/Billing' })
                }}
              >
                <Box component={'img'} src={ImagePaths.CreateBill.default} height={'1.5vw'} width={'1.5vw'} />
              </Button>
            </Tooltip>
            {/* <Tooltip placement='top' title='Cancel' arrow>
              <Button className='Common_Btn_Min_Width' emed_tid='rad_cal_app'>
                <Box component={'img'} src={ImagePaths.CancelAppoinment.default} height={'1.5vw'} width={'1.5vw'} />
              </Button>
            </Tooltip> */}
            {/* {this.MenuItem(params?.row)} */}
          </Box>)
        },
      ]
      return (
        <Box component={'div'}>
          <DataGrid
            sx={{ backgroundColor: Colors.white, height: '63vh', cursor: 'pointer' }}
            columns={columns}
            rows={this.state.patientDetailsData}
            getRowId={(row) => row['sno']}
            page={this.state.page}
            pageSize={this.state.pageSize}
            hideFooterSelectedRowCount
            localeText={{
              toolbarColumns: "",
              toolbarDensity: "",
              toolbarExport: "",
              toolbarFilters: "",
              toolbarExportPrint: ""
            }}
            headerHeight={40}
            components={{
              Toolbar: CommonGridHeader.CommonToolBar,
              NoRowsOverlay: () => (
                <Box className='eMed_conf_nodata'>
                  {'No Records To Be Shown'}
                </Box>
              )
            }}
            onCellClick={(params) => this.routeToDemograhics(params)}
            rowsPerPageOptions={[10, 20, 30]}
            pagination
            onPageChange={(newPage) => { this.setState({ page: newPage }) }}
            onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
            loading={this.state.getPatient || this.state.loading}
            disableSelectionOnClick
          />
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  render() {
    return (
      <Box component={'div'}>
        <Box className='rad_home_container'>
          {this.renderHeader()}
          {this.renderPatientCards()}
          {this.renderDatagridTable()}
        </Box>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
      </Box>
    )
  }
}
