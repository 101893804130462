import '../App.css';
import '../index.css';
import {
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import Signin from '../Signin/Signin';
import Forgotpassword from '../Signin/ForgotPassword';
import ComingSoon from '../Modules/Upcomingpages/ComingSoon';
import PageNotFound from '../Modules/Upcomingpages/404error';
import { getCachevalue, localGetItem } from "../Utility/Services/CacheProviderService";
import LabHome from '../Modules/Laboratory/Home/Home';
import Inventory from '../Modules/Laboratory/Inventory/Inventory';
import LabConfig from '../Modules/Laboratory/Config/Config';
import LabPurchase from '../Modules/Laboratory/Purchase/PurchaseManagement';
import MainHome from '../Modules/Laboratory/Home/index';
import Saved_Bill from '../Modules/Laboratory/Home/Saved_Bill';
import Status from '../Modules/Laboratory/Home/Status';
import Transmit from '../Modules/Laboratory/Home/Transmit';
import TestReport from '../Modules/Laboratory/Home/TestReport';
import Reports from '../Modules/Laboratory/Reports/index';
import Result from '../Modules/Laboratory/Home/Result';
import CommonTabs from '../Components/Common Components/CommonTabs';
import Dashboard from '../Dashboard/Dashboard';
import OutPatientHome from '../Modules/FrontOffice/OutPatients/OutpatientHome'
import ConfigHome from '../Modules/Configurations/ConfigHome';
import ServiceConfig from '../Modules/Configurations/OPConfigurations/ServiceNameandTypeConfig';
import ServiceTemplate from '../Modules/Configurations/OPConfigurations/ServiceTemplateConfig';
import Specialization from '../Modules/Configurations/OPConfigurations/specialization';
import DoctorConfig from '../Modules/Configurations/HospitalConfig/DoctorConfig';
import AppointmentConfig from '../Modules/Configurations/OPConfigurations/AppointmentConfig';
// import BillPrefix from '../Modules/Configurations/OPConfigurations/BillPrefix'; 
import TokenPrefix from '../Modules/Configurations/OPConfigurations/TokenPrefix';
import TagConfig from '../Modules/Configurations/OPConfigurations/Tag';
import { useHistory } from "react-router-dom";
import ReferralDoctor from '../Modules/Configurations/ReferralConfigurations/ReferralDoctor';
import ReferralSource from '../Modules/Configurations/ReferralConfigurations/ReferalSource';
import UserConfiguration from '../Modules/Configurations/UserConfigurations/UserConfiguration';
import CorporateConfig from '../Modules/Configurations/CorporateConfigurations/CorporateConfig';
import DemographicsConfigHome from '../Modules/Configurations/DemographicsConfigurations/DemographicsConfigHome';
import OverAllBills from '../Modules/FrontOffice/BillingTransaction/OverAllBills';
import ReportsHome from '../Modules/FrontOffice/FOReports/ReportsHome';
import ServiceDetailed from '../Modules/FrontOffice/FOReports/ServiceReports/ServiceDetailed';
import IPServiceDetailed from '../Modules/FrontOffice/FOReports/IpServiceReport/IPServiceDetailed';
import IPServiceOverview from '../Modules/FrontOffice/FOReports/IpServiceReport/IPServiceOverview';
import CreditBills from '../Modules/FrontOffice/BillingTransaction/CreditBills';
import CopPatientReport from '../Modules/FrontOffice/FOReports/CoporateReports/CopPatientReport';
import CopPaymentReport from '../Modules/FrontOffice/FOReports/CoporateReports/CopPaymentReport';
import ServiceOverview from '../Modules/FrontOffice/FOReports/ServiceReports/ServiceOverview';
import AppoinmentPatientReport from '../Modules/FrontOffice/FOReports/AppointmentReports/patientReport';
import RefDoctorReport from '../Modules/FrontOffice/FOReports/ReferralReports/RefDoctorReport';
import RefSourceReport from '../Modules/FrontOffice/FOReports/ReferralReports/RefSourceReport';
import DemographicsReport from '../Modules/FrontOffice/FOReports/DemographicReport/DemographicsReport';
import AppointmentTypeReport from '../Modules/FrontOffice/FOReports/AppointmentReports/AppointmentTypeReport';
import AppointmentSpecializationReport from '../Modules/FrontOffice/FOReports/AppointmentReports/SpecializationReport';
import CompletedBills from '../Modules/FrontOffice/BillingTransaction/CompletedBills';
import CancelledBills from '../Modules/FrontOffice/BillingTransaction/CancelledBills';
import SavedBills from '../Modules/FrontOffice/BillingTransaction/SavedBills';
import DayEndStatus from '../Modules/FrontOffice/BillingTransaction/DayEndStatus';
import AppointmentDoctorReport from '../Modules/FrontOffice/FOReports/AppointmentReports/doctorReport';
import CreateApppointment from '../Modules/FrontOffice/OutPatients/QuickBillAppointments/CreateAppointment';
import QuickBilling from '../Modules/FrontOffice/OutPatients/QuickBillAppointments/QuickBilling';
import DoctorVisitReport from '../Modules/FrontOffice/OutPatients/PatientDetails/DoctorVisitReport';
import NurseNotes from '../Modules/FrontOffice/OutPatients/PatientDetails/NurseNotes';
import TestReports from '../Modules/FrontOffice/OutPatients/PatientDetails/TestReports';
import IPServiceNameTypeConfig from '../Modules/Configurations/IPConfigurations/IPServiceNameTypeConfig';
import IPPackageConfig from '../Modules/Configurations/IPConfigurations/IPPackageConfig';
import HospitalConfig from '../Modules/Configurations/RoomConfig/HospitalConfig';
import WardConfig from '../Modules/Configurations/RoomConfig/WardConfig';
import BedConfig from '../Modules/Configurations/RoomConfig/BedConfig';
import DisSumLayoutConfig from '../Modules/Configurations/LayOutConfig/DisSumLayoutConfig';
import SurgeryNotesLayoutConfig from '../Modules/Configurations/LayOutConfig/SurgeryNotesLayout'
import IPpatientsHome from '../Modules/FrontOffice/IPPatients/IPpatientsHome';
import IPAppoinmentIndex from '../Modules/FrontOffice/IPPatients/CreateAppointment/IPAppoinmentIndex'
import ServiceBilling from '../Modules/FrontOffice/IPPatients/PatientsDetails/ServiceBIlling';
import DisSumTemplateConfig from '../Modules/Configurations/TemplateConfig/DisSumTemplateConfig';
import SurgeryTemplateConfig from '../Modules/Configurations/TemplateConfig/SurgeryTemplateConfig';
import DisSumTemplateAddConfig from '../Modules/Configurations/TemplateConfig/DisSumTemplateAddConfig';
import SurgeryTemplateAddConfig from '../Modules/Configurations/TemplateConfig/SurgeryTemplateAddConfig';
import IpPatientDetails from '../Modules/FrontOffice/IPPatients/PatientsDetails/PatientDetails';
import ReceivePayment from '../Modules/FrontOffice/IPPatients/PatientsDetails/ReceivePayment';
import OpBilling from '../Modules/FrontOffice/OutPatients/PatientDetails/OpBilling';
import RoomChange from '../Modules/FrontOffice/IPPatients/PatientsDetails/RoomChange';
import DoctorNotes from '../Modules/FrontOffice/IPPatients/PatientsDetails/ClinicalNotes/DoctorNotes';
import Medication from '../Modules/FrontOffice/IPPatients/PatientsDetails/ClinicalNotes/Medication';
import Laboratory from '../Modules/FrontOffice/IPPatients/PatientsDetails/ClinicalNotes/Laboratory';
import PainChart from '../Modules/FrontOffice/IPPatients/PatientsDetails/ClinicalNotes/PainChart';
import IpNurseNotes from '../Modules/FrontOffice/IPPatients/PatientsDetails/ClinicalNotes/NurseNotes';
import DischargeSummary from '../Modules/FrontOffice/IPPatients/PatientsDetails/DischargeSummary';
import Vitals from '../Modules/FrontOffice/IPPatients/PatientsDetails/ClinicalNotes/Vitals';
import SurgeryNotes from '../Modules/FrontOffice/IPPatients/PatientsDetails/SurgeryNotes';
import PatientSavedBill from '../Modules/FrontOffice/OutPatients/PatientDetails/PatientBillingDetails/PatientSavedBill';
import PatientCreditBill from '../Modules/FrontOffice/OutPatients/PatientDetails/PatientBillingDetails/PatientCreditBill';
import PrintConfig from '../Modules/Configurations/UserConfiguration/PrintConfig';
import Useraccess from '../Modules/Configurations/UserConfiguration/Useraccess';
import PatientCompletedBill from '../Modules/FrontOffice/OutPatients/PatientDetails/PatientBillingDetails/PatientCompletedBill';
import CommonUserAccess from '../Modules/Configurations/UserConfiguration/commonUserAccess';
import { CheckAccessFunc } from '../Components/CommonFunctions/CommonFunctions';
import ViewDemographics from '../Modules/FrontOffice/IPPatients/ViewDemographicHistory/viewDemographics';
import AdmissionHistory from '../Modules/FrontOffice/IPPatients/ViewDemographicHistory/AdmissionHistory';
import BillingDetails from '../Modules/FrontOffice/IPPatients/ViewDemographicHistory/DischargedPatientTabs/BillingDetails';
import ClinicNotes from '../Modules/FrontOffice/IPPatients/ViewDemographicHistory/DischargedPatientTabs/ClinicNotes';
import BillingMedication from '../Modules/FrontOffice/IPPatients/ViewDemographicHistory/DischargedPatientTabs/BillingMedication';
import BillingSummary from "../Modules/FrontOffice/IPPatients/ViewDemographicHistory/DischargedPatientTabs/BillingSummary";
import ReceiptBills from '../Modules/FrontOffice/BillingTransaction/ReceiptBills';
import AutomaticBills from '../Modules/Configurations/HospitalConfig/AutomaticBills';
import OpticalCRMsales from '../Modules/FrontOffice/FOReports/OPTReports/OpticalCRMsales';
import CustomConfig from '../Modules/Configurations/UserConfiguration/CustomConfig';
import BillingHome from '../Modules/FrontOffice/Billing/BillingHome';
import LabBilling from '../Modules/FrontOffice/Billing/LabBilling';
import RadiologyBilling from '../Modules/FrontOffice/Billing/RadiologyBilling';
import SummeryReport from '../Modules/FrontOffice/BillingTransaction/SummeryReport';
import IpPatientSavedBill from '../Modules/FrontOffice/IPPatients/PatientsDetails/IpPatientBillingDetails/IpPatientSavedBill';
import IpPatientBills from '../Modules/FrontOffice/IPPatients/PatientsDetails/IpPatientBillingDetails/IpPatientBills';
import IpPatinetReceipt from '../Modules/FrontOffice/IPPatients/PatientsDetails/IpPatientBillingDetails/IpPatinetReceipt';
import InsulinTrack from '../Modules/FrontOffice/IPPatients/PatientsDetails/ClinicalNotes/InsulinTrack';
import CorpPatientDetails from '../Modules/Configurations/CorporateConfigurations/PatientDetails';
import EmployerBilling from '../Modules/Configurations/CorporateConfigurations/EmployerBilling';
import CorpReports from '../Modules/Configurations/CorporateConfigurations/Reports';
import ServiceShare from '../Modules/FrontOffice/FOReports/ServiceReports/ServiceShare';
import TATReport from '../Modules/FrontOffice/FOReports/AppointmentReports/TATReport';
import PurposeOfVisitReport from '../Modules/FrontOffice/FOReports/DemographicReport/PurposeOfVisitReport';
import OPNurseNotes from '../Modules/FrontOffice/OutPatients/PatientDetails/OPNurseNotes';
import OPServiceShare from '../Modules/FrontOffice/FOReports/ServiceReports/OPServiceShare';
import IPLegerReport from '../Modules/FrontOffice/FOReports/IpServiceReport/IPLegerReport';
import NoShowReport from '../Modules/FrontOffice/FOReports/AppointmentReports/NoShowReport';
import RoomAvailability from '../Modules/FrontOffice/IPPatients/RoomAvailability';
import Estimation from '../Modules/FrontOffice/IPPatients/IPTabs/Estimation/EstimationSrc';
import EstimationSummary from '../Modules/FrontOffice/IPPatients/IPTabs/Estimation/EstimationSummary';
import ExpenseBills from '../Modules/FrontOffice/BillingTransaction/ExpenseBills';
import ExpenseCategory from '../Modules/Configurations/ClinicConfigurations/ExpenseCategory';
import { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import PharmaHome from '../Modules/Pharmacy/PharmacyHome/PharmaHome';
import PharmaReportHome from '../Modules/Pharmacy/PharmacyReports/PharmaReportHome';
import PharmaWantedList from '../Modules/Pharmacy/PharmacyPurchase/PharmaWantedList';
import PharmaPurchaseOrder from '../Modules/Pharmacy/PharmacyPurchase/PharmaPurchaseOrder';
import PharmaDeliveryChellan from '../Modules/Pharmacy/PharmacyPurchase/PharmaDeliveryChellan';
import PharmaDCview from '../Modules/Pharmacy/PharmacyPurchase/PharmaDCdetail'
import PharmasVendorList from '../Modules/Pharmacy/PharmacyPurchase/PharmsVendorList';
import PharmaVendorPayment from '../Modules/Pharmacy/PharmacyPurchase/PharmaVendorPayment';
import SalesOverview from '../Modules/Pharmacy/PharmacyReports/Sales/SalesOverview';
import SalesDetailed from '../Modules/Pharmacy/PharmacyReports/Sales/SalesDetailed';
import SalesReturn from '../Modules/Pharmacy/PharmacyReports/Sales/SalesReturn';
import PharmaDayEnd from '../Modules/Pharmacy/PharmacyReports/Sales/PharmaDayEnd';
import SalesMonthly from '../Modules/Pharmacy/PharmacyReports/Sales/SalesMonthly';
import PurchaseOverview from '../Modules/Pharmacy/PharmacyReports/Purchase/PurchaseOverview';
import PurchaseDetailed from '../Modules/Pharmacy/PharmacyReports/Purchase/PurchaseDetailed';
import PurchaseGST from '../Modules/Pharmacy/PharmacyReports/Purchase/PurchaseGST';
import PurchaseReturn from '../Modules/Pharmacy/PharmacyReports/Purchase/PurchaseReturn';
import PurchaseMonthly from '../Modules/Pharmacy/PharmacyReports/Purchase/PurchaseMonthly';
import ConsolidatedSales from '../Modules/Pharmacy/PharmacyReports/Purchase/ConsolidatedSales';
import StockOverview from '../Modules/Pharmacy/PharmacyReports/Inventory/StockOverview';
import MISReport from '../Modules/Pharmacy/PharmacyReports/Inventory/MISReport';
import ExpiryStock from '../Modules/Pharmacy/PharmacyReports/Inventory/ExpiryStock';
import LowStockDrug from '../Modules/Pharmacy/PharmacyReports/Inventory/LowStockDrug';
import SlowMovementDrug from '../Modules/Pharmacy/PharmacyReports/Inventory/SlowMovementDrug';
import StockCorrectionLog from '../Modules/Pharmacy/PharmacyReports/Inventory/StockCorrectionLog';
import StockValue from '../Modules/Pharmacy/PharmacyReports/Inventory/StockValue';
import PharmaNoMovement from '../Modules/Pharmacy/PharmacyReports/Inventory/PharmaNoMovement';
import PharmaDirectPo from '../Modules/Pharmacy/PharmacyPurchase/PharmaDirectPo';
import PharmaStockList from '../Modules/Pharmacy/PharmacyInventory/PharmaStockList';
import PharmaDrugList from '../Modules/Pharmacy/PharmacyInventory/PharmaDrugList'
import PharmaInactiveDrug from '../Modules/Pharmacy/PharmacyInventory/PharmaInactiveDrug'
import PharmacyOrderedList from '../Modules/Pharmacy/PharmacyPurchase/PharmacyOrderedList';
import PharmaConfirmOrderList from '../Modules/Pharmacy/PharmacyPurchase/PharmaCnfrmOrderList';
import PharmaAddvendor from '../Modules/Pharmacy/PharmacyPurchase/PharmaAddvendor';
import PharmaInvoice from '../Modules/Pharmacy/PharmacyInvoice/PharmaInvoice';
import PharmaCreditBills from '../Modules/Pharmacy/PharmacyInvoice/PharmaCreditBills';
import PharmaCreditBillsSplit from '../Modules/Pharmacy/PharmacyInvoice/PharmaCreditCompleteBills';
import PharmaStockCorrection from '../Modules/Pharmacy/PharmacySettings/PharmaStockCorrection';
import PharmaSettings from '../Modules/Pharmacy/PharmacySettings/PharmaSettings';
import PharmaAddNewDrug from '../Modules/Pharmacy/PharmacyInventory/PharmaAddNewDrug';
import PharmaRequestList from '../Modules/Pharmacy/PharmacyInventory/PharmaRequestList';
import PharmaStockReturn from '../Modules/Pharmacy/PharmacyInventory/PharmaStockReturn';
import PharmaNewDrugApproval from '../Modules/Pharmacy/PharmacySettings/PharmaNewDrugApproval';
import PharmaUserConfig from '../Modules/Pharmacy/PharmacySettings/PharmaUserConfig';
import PharmaGenericName from '../Modules/Pharmacy/PharmacySettings/PharmaGenericName';
import PharmaVendorMonthly from '../Modules/Pharmacy/PharmacyPurchase/PharmaVendorMonthly';
import RefDoctorList from '../Modules/Pharmacy/PharmacyReports/StanaloneReport/RefDoctorList';
import PatientReport from '../Modules/Pharmacy/PharmacyReports/StanaloneReport/PatientReport';
import PharmacyRefDoctor from '../Modules/Pharmacy/PharmacySettings/PharmacyRefDoctor';
import DayWiseReturnGST from '../Modules/Pharmacy/PharmacyReports/GSTReport/SalesReport/DayWiseReturnGST';
import HSNwiseSalesGST from '../Modules/Pharmacy/PharmacyReports/GSTReport/SalesReport/HSNwiseSalesGST';
import HSNwiseReturnGST from '../Modules/Pharmacy/PharmacyReports/GSTReport/SalesReport/HSNwiseReturnGST';
import DayWiseGSTSummary from '../Modules/Pharmacy/PharmacyReports/GSTReport/SalesReport/DayWiseGSTSummary';
import SalesGSTDayWise from '../Modules/Pharmacy/PharmacyReports/GSTReport/SalesReport/SalesGSTDayWise';
import HSNWiseSummaryGST from '../Modules/Pharmacy/PharmacyReports/GSTReport/SalesReport/HSNWiseSummaryGST';
import SubLocbilling from '../Modules/SubLocation/Billing/SubLocbilling';
import SubLocGenericConsp from '../Modules/SubLocation/Billing/SubLocGenericConsp';
import SubLocation from '../Modules/Pharmacy/PharmacySettings/SubLocation/SubLocation';
import PharmaUserAccess from '../Modules/Pharmacy/PharmacySettings/SubLocation/UserConfig/PharmaUserAccess';
import PharmaUserSettings from '../Modules/Pharmacy/PharmacySettings/SubLocation/UserConfig/PharmaUserSettings';
import SubLocStockList from '../Modules/SubLocation/Reports/SubLocStockList';
import SubLocShortExpiry from '../Modules/SubLocation/Reports/SubLocShortExpiry';
import SubLocCreditReport from '../Modules/SubLocation/Reports/SubLocCreditReport';
import SubLocConsumptionReport from '../Modules/SubLocation/Reports/SubLocConsumptionReport';
import SubLocWantedList from '../Modules/SubLocation/StockTransfer/SubLocWantedList';
import SubLocOutbound from '../Modules/SubLocation/StockTransfer/SubLocOutbound';
import SubLocInbound from '../Modules/SubLocation/StockTransfer/SubLocInbound';
import SubLocReturnRequest from '../Modules/SubLocation/StockTransfer/SubLocReturnRequest';
import SubLocStockTfrReport from '../Modules/SubLocation/StockTransfer/SubLocStockTfrReport';
import SubLocRaiseReq from '../Modules/SubLocation/StockTransfer/SubLocRaiseReq';
import PharmaInbound from '../Modules/Pharmacy/PharmacyStockTransfer/PharmaInbound';
import PharmaOutbound from '../Modules/Pharmacy/PharmacyStockTransfer/PharmaOutbound';
import PharmaStockTfrReport from '../Modules/Pharmacy/PharmacyStockTransfer/PharmaStockTfrReport';

import CounsellorHome from '../Modules/OT/CounsellorHome';
import OThome from '../Modules/OT/OThome';
import BedAvailability from '../Modules/FrontOffice/IPPatients/BedAvailability';

import ReasonDiagnosisReport from '../Modules/FrontOffice/FOReports/DemographicReport/ReasonDiagnosisReport';
import OPNewServiceConfig from '../Modules/Configurations/OPConfigurations/OPNewServiceConfig';
import IPNewServiceConfig from '../Modules/Configurations/IPConfigurations/IPNewServiceConfig';
import IPSurgeryConfig from '../Modules/Configurations/IPConfigurations/IPSurgeryConfig';
import AmountConfig from '../Modules/Laboratory/Config/AmountConfig';
import ConsumableMap from '../Modules/Laboratory/Config/ConsumableMap';
import PatientDemographics from '../Modules/Laboratory/Config/PatientDemographics';
import DoctorDemographics from '../Modules/Laboratory/Config/DoctorDemographics';
import OutsourceConfig from '../Modules/Laboratory/Config/OutsourceConfig';
import OutsourceMapping from '../Modules/Laboratory/Config/OutsourceMapping';
import RangeBasedConfig from '../Modules/Laboratory/Config/RangeBasedConfig';
import CultureTestConfig from '../Modules/Laboratory/Config/CultureTestConfig';
import TestConfig from '../Modules/Laboratory/Config/TestConfig';
import TestGroup from '../Modules/Laboratory/Config/TestGroup';
import PackageConfig from '../Modules/Laboratory/Config/PackageConfig';
import DoctorHome from '../Modules/Doctor/Common/Home/DoctorHome';
import DocRegistry from '../Modules/Doctor/Common/Registry/DocRegistry';
import DocNutritionChart from '../Modules/Doctor/Common/NutritionChart/DocNutritionChart';
import DocDemographics from '../Modules/Doctor/Common/Demographics/DocDemographics';
import DocNotesHome from '../Modules/Doctor/DoctorNotes/Common/DocNotesHome';
import DocPatientDocument from '../Modules/Doctor/Common/PatientDocument/DocPatientDocument';
import DocDoctorVisitReport from '../Modules/Doctor/Common/Reports/DocDoctorVisitReport';
import DocMedicalHistory from '../Modules/Doctor/History/CommonHistory/DocMedicalHistory';
import DocHbac from '../Modules/Doctor/DoctorDB/Charts/DocHbac';
import DocBranchicalIndex from '../Modules/Doctor/DoctorDB/FootClinic/DocBranchicalIndex';
import DocCollabDoctorNotes from '../Modules/Doctor/Common/CollaborativeSummary/DocCollabDoctorNotes';
import DocPrintOrder from '../Modules/Doctor/Common/Reports/DocPrintOrder';
import DocTestReport from '../Modules/Doctor/Common/Reports/DocTestReport';
import DocFamilyHistory from '../Modules/Doctor/History/CommonHistory/DocFamilyHistory';
import DocHospitalizationHistory from '../Modules/Doctor/History/CommonHistory/DocHospitalizationHistory';
import DocSocialHistory from '../Modules/Doctor/History/CommonHistory/DocSocialHistory';
import DocDiabiaticHistory from '../Modules/Doctor/History/DBHistory/DocDiabiaticHistory';
import DocGlucouselog from '../Modules/Doctor/DoctorDB/Charts/DocGlucouselog';
import DocCollabDisSummary from '../Modules/Doctor/Common/CollaborativeSummary/DocCollabDisSummary';
import DocCollabHistory from '../Modules/Doctor/Common/CollaborativeSummary/DocCollabHistory';
import DocCollabSurNotes from '../Modules/Doctor/Common/CollaborativeSummary/DocCollabSurNotes';
import DocBiothesometry from '../Modules/Doctor/DoctorDB/FootClinic/DocBiothesometry';
import DocCollabTestReport from '../Modules/Doctor/Common/CollaborativeSummary/DocCollabTestReport';
import DocLayoutConfig from '../Modules/Doctor/Common/Settings/DocLayoutConfig';
import DocNotesConfig from '../Modules/Doctor/Common/Settings/DocNotesConfig';
import BedAvailable from '../Modules/FrontOffice/IPPatients/BedAvailable';
import PharmaPOReturn from '../Modules/Pharmacy/PharmacyPurchase/PharmaPOReturn';
import PharmaReturn from '../Modules/Pharmacy/PharmacyPurchase/PharmaReturn';
import ChartDashboardHome from '../Modules/ChartDashboard/ChartDashboardHome';
import SlotConfig from '../Modules/Configurations/HospitalConfig/SlotConfig';
import SignatureConfig from '../Modules/Laboratory/Config/SignatureConfig';
import PharmaPrintConfig from '../Modules/Pharmacy/PharmacySettings/PharmaPrintConfig';
import ProductWiseSales from '../Modules/Pharmacy/PharmacyReports/Sales/ProductWiseSales';
import ConsultWiseReport from '../Modules/FrontOffice/FOReports/ServiceReports/ConsultWiseReport';
import HsnWise from '../Modules/Pharmacy/PharmacyReports/Sales/HsnCodeRateWise';
import VendorBill from '../Modules/Pharmacy/PharmacyReports/Purchase/VendorBill';
import TaxWise from '../Modules/Pharmacy/PharmacyReports/Sales/TaxWise';
import ReturnHSNWise from '../Modules/Pharmacy/PharmacyReports/Sales/ReturnHSNWise';
import ReturnTaxWise from '../Modules/Pharmacy/PharmacyReports/Sales/ReturnTaxWise';
import PurchaseHSNWise from '../Modules/Pharmacy/PharmacyReports/Purchase/PurchaseHSNWise';
import PurchaseTaxWise from '../Modules/Pharmacy/PharmacyReports/Purchase/PurchaseTaxWise';
import PurchaseReturnHSNWise from '../Modules/Pharmacy/PharmacyReports/Purchase/PurchaseReturnHSNWise';
import PurchaseReturnVendorWise from '../Modules/Pharmacy/PharmacyReports/Purchase/PurchaseReturnVendorWise';
import PurchaseReturnTaxWise from '../Modules/Pharmacy/PharmacyReports/Purchase/PurchaseReturnTaxWise';
import PrescriptionRegister from '../Modules/Pharmacy/PharmacyReports/Sales/PrescriptionRegister';
import PharmaPORequest from '../Modules/Pharmacy/PharmacyPurchase/PharmaPORequest';
import ConcessionReport from '../Modules/FrontOffice/FOReports/DemographicReport/ConcessionReport';
import IpAdmissionReport from '../Modules/FrontOffice/FOReports/IpServiceReport/IpAdmissionReport';
import ProfitAnalysis from '../Modules/Pharmacy/PharmacyReports/Sales/ProfitAnalysis';
import OtherDoctorNotes from '../Modules/Doctor/DoctorNotes/Others/OtherDoctorNotes';
import DocFitnessCertific from '../Modules/Doctor/DoctorNotes/Common/DocFitnessCertific';
import DocMedicalCertific from '../Modules/Doctor/DoctorNotes/Common/DocMedicalCertific';
import DocRefferalNotes from '../Modules/Doctor/DoctorNotes/Common/DocRefferalNotes';
import DocPidiatricHistory from '../Modules/Doctor/History/PDHistory/DocPidiatricHistory';
import DocDevelopmentAsses from '../Modules/Doctor/History/PDHistory/DocDevelopmentAsses';
import DocImmunization from '../Modules/Doctor/History/PDHistory/DocImmunization';
import DocGrowthChart from '../Modules/Doctor/History/PDHistory/DocGrowthChart';
import DocDevelopmentpaediatric from '../Modules/Doctor/History/PDHistory/DocDevelopmentpaediatric';
import DocRefferalNotesCommon from '../Modules/Doctor/DoctorNotes/Common/DocRefferalNotesCommon';
import NewDocNotes from '../Modules/Doctor/DoctorNotes/Common/DocNotesNew/NewDocNotes';
import OTSurgeryReport from '../Modules/OT/OT Reports/OTSurgeryReport';
import OTBilling from '../Modules/OT/OTBilling';
import SurgeonDetails from '../Modules/OT/SurgeonDetails';
import IOLDrugs from '../Modules/OT/Inventory/IOLDrugs';
import OTDrugs from '../Modules/OT/Inventory/OTDrugs';
import OTStockTrans from '../Modules/OT/OT Stocks/OTStockTrans';
import OTConfiguration from '../Modules/OT/OT Settings/OTConfiguration';
import OTStockList from '../Modules/OT/OT Stocks/OTStockList';
import PrintOrderReport from '../Modules/FrontOffice/OutPatients/PatientDetails/PrintOrderReport';
import PharmaPORequestList from '../Modules/Pharmacy/PharmacyPurchase/PharmaPORequestList';
import RadDemographics from '../Modules/Radiology/Home/RadPatients/RadDemographics/RadDemographics';
import RadSavedBill from '../Modules/Radiology/Home/RadPatients/RadBilling/RadSavedBill';
import RadBills from '../Modules/Radiology/Home/RadPatients/RadBilling/RadBills';
import RadReceiptBill from '../Modules/Radiology/Home/RadPatients/RadBilling/RadReceiptBill';
import RadNotes from '../Modules/Radiology/Home/RadPatients/RadReports/RadNotes';
import RadTestReports from '../Modules/Radiology/Home/RadPatients/RadReports/RadTestReports';
import RadCreditBills from '../Modules/Radiology/BillingTransaction/RadCreditBills';
import RadCancelledBills from '../Modules/Radiology/BillingTransaction/RadCancelledBills';
import RadSavedBills from '../Modules/Radiology/BillingTransaction/RadSavedBills';
import RadReceiptBills from '../Modules/Radiology/BillingTransaction/RadReceiptBills';
import RadDayEndStatus from '../Modules/Radiology/BillingTransaction/RadDayEndStatus';
import RadCensus from '../Modules/Radiology/Reports/RadCensus';
import RadPatientReport from '../Modules/Radiology/Reports/RadPatientReport';
import RadServiceConfig from '../Modules/Radiology/Settings/RadServiceConfig';
import RadServiceTemplate from '../Modules/Radiology/Settings/RadServiceTemplate';
import RadPrintConfig from '../Modules/Radiology/Settings/RadPrintConfig';
import RadPatientHome from '../Modules/Radiology/Home/RadPatientHome';
import RadOverAllBills from '../Modules/Radiology/BillingTransaction/RadOverAllBills';
import RadAddTemplate from '../Modules/Radiology/Settings/RadAddTemplate';
import RadQuickDemographics from '../Modules/Radiology/Home/RadQuickDemographics';
import RadBilling from '../Modules/Radiology/Home/RadPatients/RadBilling/RadBilling';
import CanteenHome from '../Modules/Canteen/CanteenHome';
import CanteenOverallBill from '../Modules/Canteen/BillingTransaction/CanteenOverallBill';
import CanteenCreditBill from '../Modules/Canteen/BillingTransaction/CanteenCreditBill';
import CanteenCanceledBill from '../Modules/Canteen/BillingTransaction/CanteenCanceledBill';
import CanteenReceiptBill from '../Modules/Canteen/BillingTransaction/CanteenReceiptBill';
import CanteenDayend from '../Modules/Canteen/BillingTransaction/CanteenDayend';
import CanteenMenuItem from '../Modules/Canteen/Settings/CanteenMenuItem';
import AddNewIOL from '../Modules/OT/Inventory/AddNewIOL';
import AssetHome from '../Modules/AssetManagement/Home/AssetHome';
import AssetTransfer from '../Modules/AssetManagement/Home/AssetTransfer';
import AssetAudit from '../Modules/AssetManagement/Home/AssetAudit';
import AssetComplaints from '../Modules/AssetManagement/Complaints/AssetComplaints';
import AssetCategory from '../Modules/AssetManagement/Settings/AssetCategory';
import AssetSubCategory from '../Modules/AssetManagement/Settings/AssetSubCategory';
import AssetBlock from '../Modules/AssetManagement/Settings/AssetBlock';
import AssetFloor from '../Modules/AssetManagement/Settings/AssetFloor';
import AssetSpotArea from '../Modules/AssetManagement/Settings/AssetSpotArea';
import AssetDesignation from '../Modules/AssetManagement/Settings/AssetDesignation';
import AssetEmployee from '../Modules/AssetManagement/Settings/AssetEmployee';
import NewComplaints from '../Modules/AssetManagement/Complaints/NewComplaints';
import AddNewAsset from '../Modules/AssetManagement/Home/AddNewAsset';
import AddAssetTransfer from '../Modules/AssetManagement/Home/AddAssetTransfer';
import InsuranceConfig from '../Modules/Configurations/InsuranceConfig/InsuranceConfig';
import DocDischargeSummaryReport from '../Modules/Doctor/Common/Reports/DocDischargeSummaryReport';
import OptometristList from '../Modules/FrontOffice/FOReports/Optometrist Report/OptometristList';
import CounsellorSurgeryReport from '../Modules/FrontOffice/FOReports/AppointmentReports/CounsellorSurgeryReport';
import CreditSettlementReport from '../Modules/FrontOffice/FOReports/DemographicReport/CreditSettlementReport';
import IPDischargeList from '../Modules/FrontOffice/FOReports/IpServiceReport/IPDischargeList';
import PurchaseWiseEnquiry from '../Modules/Pharmacy/PharmacyReports/Purchase/PurchaseWiseEnquiry';
import RefValueReport from '../Modules/FrontOffice/FOReports/ReferralReports/RefValueReport';
import DrugWiseStockValue from '../Modules/Pharmacy/PharmacyReports/Inventory/DrugWiseStockValue';
import InsuCorpoDetails from '../Modules/FrontOffice/IPPatients/PatientsDetails/IpPatientBillingDetails/InsuranceCorporate/InsuCorpoDetails';
import PreAuth from '../Modules/FrontOffice/IPPatients/PatientsDetails/IpPatientBillingDetails/InsuranceCorporate/PreAuth';
import ClaimDetails from '../Modules/FrontOffice/IPPatients/PatientsDetails/IpPatientBillingDetails/InsuranceCorporate/ClaimDetails';
import PharmaSubStockList from '../Modules/Pharmacy/PharmacyStockTransfer/PharmaSubStockList';
import LabReportsHome from '../Modules/Laboratory/Reports/LabReportsHome';
import BillReport from '../Modules/Laboratory/Reports/BillReport';
import LabCensusReport from '../Modules/Laboratory/Reports/LabCensusReport';
import LabResultCensusReport from '../Modules/Laboratory/Reports/LabResultCensusReport';
import DoctorReport from '../Modules/Laboratory/Reports/DoctorReport';
import OutSourceReport from '../Modules/Laboratory/Reports/OutSourceReport';
import GroupwiseReport from '../Modules/Laboratory/Reports/GroupwiseReport';
import GroupwiseSummaryReport from '../Modules/Laboratory/Reports/GroupwiseSummaryReport';
import TestwiseReport from '../Modules/Laboratory/Reports/TestwiseReport';
import TestwiseSummaryReport from '../Modules/Laboratory/Reports/TestwiseSummaryReport';
import LabReceiptBills from '../Modules/Laboratory/Reports/ReceiptBills';
import LabPatientReport from '../Modules/Laboratory/Reports/PatientReport';
import PackageWiseReport from '../Modules/Laboratory/Reports/PackageWiseReport';
import GenerateReport from '../Modules/FrontOffice/FOReports/TallyRports/GenerateReport';
import IPOccupancyReport from '../Modules/FrontOffice/FOReports/IpServiceReport/IPOccupancyReport';
import PharmaRejection from '../Modules/Pharmacy/PharmacyPurchase/PharmaRejection';
import PharmaCreditNote from '../Modules/Pharmacy/PharmacyPurchase/PharmaCreditNote';
import CreditNoteReport from '../Modules/Pharmacy/PharmacyReports/Purchase/CreditNoteReport';
import PharmaRejectionHome from '../Modules/Pharmacy/PharmacyPurchase/PharmaRejectionHome';
import PharmaCreditNoteHome from '../Modules/Pharmacy/PharmacyPurchase/PharmaCreditNoteHome';
import LabDayEnd from '../Modules/Laboratory/Reports/LabDayEnd';
import SubLocationExpiryStock from '../Modules/SubLocation/Reports/SubLocationExpiryStock';
import AdminDashBoard from '../Modules/OrgAdmin/AdminDashBoard';
import BranchDetailDashboard from '../Modules/OrgAdmin/BranchDetailDashboard';
import ChartComparison from '../Modules/OrgAdmin/ChartComparison';
import SalesBillTaxWise from '../Modules/Pharmacy/PharmacyReports/Sales/SalesBillTaxWise';
import PurchaseBillTaxWise from '../Modules/Pharmacy/PharmacyReports/Purchase/PurchaseBillTaxWise';
import DocOBGYNHistory from '../Modules/Doctor/History/OBGYNHistory/ObstetricianHistory';
import OvulationChart from '../Modules/Doctor/DoctorOBGYN/Charts/OvulationChart';
import AntenatalChart from '../Modules/Doctor/DoctorOBGYN/Charts/AntenatalChart';
import CardLayoutConfig from '../Modules/Configurations/CardLayoutConfig';
import LabIPList from '../Modules/Laboratory/Home/LabIPList';
import BranchCustomConfig from '../Modules/OrgAdmin/BranchCustomConfig';
import CorporateClaimCard from '../Modules/FrontOffice/IPPatients/PatientsDetails/IpPatientBillingDetails/InsuranceCorporate/CorporateClaimCard';
import InsPatientReport from '../Modules/FrontOffice/FOReports/CoporateReports/InsPatientReport';
import TemplateConfig from '../Modules/Laboratory/Config/TemplateConfig';
import PrescriptionReport from '../Modules/FrontOffice/FOReports/OPTReports/PrescriptionReport';
import BillWiseReports from '../Modules/FrontOffice/FOReports/AccountReports/BillWiseReports';
import AdvanceReports from '../Modules/FrontOffice/FOReports/AccountReports/AdvanceReports';
import RefundorCancelledReports from '../Modules/FrontOffice/FOReports/AccountReports/RefundorCancelledReports';
import CreditServiceReports from '../Modules/FrontOffice/FOReports/AccountReports/CreditServiceReports';
import CocessionReports from '../Modules/FrontOffice/FOReports/AccountReports/ConcessionReports';
import DoctorShareReports from '../Modules/FrontOffice/FOReports/AccountReports/DoctorShareReports';
import ConcessionReports from '../Modules/FrontOffice/FOReports/AccountReports/ConcessionReports';
import AccountsCreditSettlementReport from '../Modules/FrontOffice/FOReports/AccountReports/AccountsCreditSettlementReport';
import QMSConfig from '../Modules/Configurations/HospitalConfig/QMSConfig';
import SmsRequest from '../Modules/Configurations/ClinicConfigurations/SmsConfigurations/SmsRequest';
import SmsConfig from '../Modules/Configurations/ClinicConfigurations/SmsConfigurations/SmsConfig';
import DoctorProfile from '../Modules/Doctor/Common/Home/DoctorProfile';
import CorporateBillReport from '../Modules/Laboratory/Reports/CorporateBillReport';
import IPDoctorHome from '../Modules/Doctor/IPListTabs/IPDoctorHome';
import DrugChart from '../Modules/FrontOffice/IPPatients/PatientsDetails/ClinicalNotes/DrugChart';
import InputOutChart from '../Modules/FrontOffice/IPPatients/PatientsDetails/ClinicalNotes/InputOutputChart';
import NutritionChart from '../Modules/FrontOffice/IPPatients/PatientsDetails/ClinicalNotes/NutritionChart';
import TreatmentPlan from '../Modules/FrontOffice/OutPatients/PatientDetails/TreatmentPlan';
import LabDocShareConfig from '../Modules/Laboratory/Config/LabDocShareConfig';
import LabShareReport from '../Modules/Laboratory/Reports/LabShareReport';
import PharmacyStockConfig from '../Modules/OrgAdmin/PharmacyStockConfig';
import StockHome from '../Modules/Pharmacy/PharmacyBranchStockTrans/StockHome';
import PharmaBranchWanted from '../Modules/Pharmacy/PharmacyBranchStockTrans/PharmaBranchWanted';
import PharmaBranchInbound from '../Modules/Pharmacy/PharmacyBranchStockTrans/PharmaBranchInbound';
import PharmaBranchOutbound from '../Modules/Pharmacy/PharmacyBranchStockTrans/PharmaBranchOutbound';
import PharmaBranchReturn from '../Modules/Pharmacy/PharmacyBranchStockTrans/PharmaBranchReturn';
import PharmaBranchStockTransReport from '../Modules/Pharmacy/PharmacyBranchStockTrans/PharmaBranchStockTransReport';
import PharmaBranchRaiseReq from '../Modules/Pharmacy/PharmacyBranchStockTrans/PharmaBranchRaiseReq';
import PharmaBranchStockList from '../Modules/Pharmacy/PharmacyBranchStockTrans/PharmaBranchStockList';
import IPTestReport from '../Modules/FrontOffice/IPPatients/PatientsDetails/Reports/IPTestReports'
import OTTatReport from '../Modules/OT/OT Reports/OTTatReport';
import OTCancelledReport from '../Modules/OT/OT Reports/OTCancelledReport.js';
import OpthotatReport from '../Modules/FrontOffice/FOReports/Optometrist Report/OpthotatReport';
import PharmaDrugWiseSTReport from '../Modules/Pharmacy/PharmacyStockTransfer/PharmaDrugWiseSTReport.js';
import ResetPassword from '../Signin/ResetPassword.js';
import UserPerformance from '../Modules/Pharmacy/PharmacyReports/Sales/UserPerformance.js';
import UserDayEndSalesReport from '../Modules/FrontOffice/BillingTransaction/UserDayEndSalesReport.js';
import SettingsHome from '../Modules/Laboratory/Settings/SettingsHome.js';
import DepartmentMaster from '../Modules/Laboratory/Settings/LabMaster/DepartmentMaster.js';
import ContainerMaster from '../Modules/Laboratory/Settings/LabMaster/ContainerMaster.js';
import SpecimenMaster from '../Modules/Laboratory/Settings/LabMaster/SpecimenMaster.js';
import MethodMaster from '../Modules/Laboratory/Settings/LabMaster/MethodMaster.js';
import AntibioticMaster from '../Modules/Laboratory/Settings/LabMaster/AntibioticMaster.js';
import OrganismMaster from '../Modules/Laboratory/Settings/LabMaster/OrganismMaster.js';
import OrganismVsAntibioticGrp from '../Modules/Laboratory/Settings/LabMaster/OrganismVsAntibioticGrp.js';
import AnalyserMaster from '../Modules/Laboratory/Settings/LabMaster/AnalyserMaster.js';
import TestCensusReport from '../Modules/Laboratory/Reports/TestCensusReport.js';
import LabTatReport from '../Modules/Laboratory/Reports/TatReport.js';
import DocDeathCertific from '../Modules/Doctor/DoctorNotes/Common/DocDeathCertific.js'
import ReserveDemographics from '../Modules/FrontOffice/IPPatients/IPReservation/ReserveDemographics.js';
import ReserveReceivePayement from '../Modules/FrontOffice/IPPatients/IPReservation/ReserveReceivePayement.js';
import CreateReservation from '../Modules/FrontOffice/IPPatients/CreateReservation.js';
import DaywiseCategorySales from '../Modules/FrontOffice/FOReports/DemographicReport/DaywiseCategorySales.js';
import ServiceCensusReport from '../Modules/Laboratory/Reports/ServiceCensusReport';

function RouterComponent(props) {
  var configSearchKey = props.configSearch ? props.configSearch : ''
  let inventorySearch = props.inventorySearch ? props.inventorySearch : ''
  let reportSearch = props.reportSearch ? props.reportSearch : ""
  let purchaseSearchKey = props.purchaseSearch ? props.purchaseSearch : ''
  let unlockDate = props.changeDate ? props.changeDate : ''
  let isOPT = props.isOPT ? props.isOPT : ''
  let CallPatientTrigger = props.CallPatientTrigger ? props.CallPatientTrigger : ''
  var UserData = JSON.parse(localGetItem("loggedInUserInfo"))
  let isOpthometry = UserData?.module_name === "Optometry"
  let isFO = UserData?.module_name === "front_office"
  var OT_Request = props.OTRequest ? props?.OTRequest : null
  const isAuthenticated = localGetItem("isLoggedIn")
  const clinic_id = localGetItem("clinicId")
  let patientDatas = getCachevalue("patientData")
  let patients = JSON.parse(patientDatas)
  let qmsConfig = UserData?.is_qms_enabled ? UserData?.is_qms_enabled : false
  const [isNurse, setisNurse] = useState(false);
  const [visitdDateObj, setVisitDateObj] = useState({});

  useEffect(() => {
    setVisitDateObj({})
  }, [props]);

  let history = useHistory();
  let [dateDetails, setDateDetails] = useState({
    fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
    toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
    dateIndex: 1,
  })

  let [reserveDateDetails, setReserveDateDetails] = useState({
    fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
    toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
    dateIndex: 1,
  })

  const clearConfigSearch = () => {
    props.clearSearch()
  }

  const changeScreen = (id) => {
    props.changeScreen(id)
  }

  const subScreenChange = (screen) => {
    props.subScreenChange(screen)
  }
  const getPharmaSavedBillCount = (flag) => {
    props.getPharmaSavedBillCount(flag)
  }
  const getcountNotification = (flag) => {
    props.getcountNotification(flag)
  }
  
  const isSearchClear = (flag) => {
    props.isSearchClear(flag)
  }
  const getSavedBillStatus = (flag) => {
    props.getSavedBillStatus(flag)
  }
  const getTransmitBillCount = (flag) => {
    props.getTransmitBillCount(flag)
  }
  const getIPListCount = (flag) => {
    props.getIPListCount(flag)
  }
  const getPharmaPrescriptionCount = (flag) => {
    props.getPharmaPrescriptionCount(flag)
  }
  const getRadTransmitCount = (flag) => {
    props.getRadTransmitCount(flag)
  }
  const ClearCalledTrigger = () => {
    props.ClearCalledTrigger(false)
  }
  const isGetCalled = () => {
    props.isGetCalled(false)
  }
  
  var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
  var UserData = myUser

  const receiveDateChange = (from, to, index) => {
    setDateDetails({
      ...dateDetails,
      fromDate: from,
      toDate: to,
      dateIndex: index
    })
  }

  const reservationDateChange = (from, to, index) => {
    setReserveDateDetails({
      ...reserveDateDetails,
      fromDate: from,
      toDate: to,
      dateIndex: index
    })
  }

  const changeNurse = (check) => {
    setisNurse(check)
  }
  const changeNursestate = (check) => {
    setisNurse(check)
  }
  const VisitDateBalAmount = (data) => {
    setVisitDateObj(data)
  }

  const PatientDetailsTabs =
    UserData?.module_name !== 'nurse' ? [
      { id: 1.1, menuName: 'Demographics', component: <CreateApppointment history={history} isForOPUpdate={true} />, subMenu: [], path: "/OPHome/PatientDetails/Demographics", Access: CheckAccessFunc("front_office", "Home", "Demographics", null, "Tab") },
      {
        id: 1.2, menuName: 'Billing', component: <OpBilling history={history} />, Access: CheckAccessFunc("front_office", "Home", "Billing", null, "Tab"), subMenu: UserData?.module_name !== "front_office" || patients?.appointment_id ? [
          { id: 1.21, menuName: "Create Bill", component: <OpBilling history={history} />, path: "/OPHome/PatientDetails/Billing", Access: CheckAccessFunc("front_office", "Home", "Billing", "Create Bill", "SubTab") },
          { id: 1.22, menuName: "Saved Bill", component: <PatientSavedBill history={history} />, path: "/OPHome/PatientDetails/SavedBill", Access: CheckAccessFunc("front_office", "Home", "Billing", "Saved Bill", "SubTab") },
          { id: 1.23, menuName: "Bills", component: <PatientCreditBill history={history} />, path: "/OPHome/PatientDetails/Bills", Access: CheckAccessFunc("front_office", "Home", "Billing", "Bills", "SubTab") },
          { id: 1.24, menuName: "Receipts", component: <PatientCompletedBill history={history} />, path: "/OPHome/PatientDetails/Receipts", Access: CheckAccessFunc("front_office", "Home", "Billing", "Receipts", "SubTab") }
        ] : [
          { id: 1.23, menuName: "Bills", component: <PatientCreditBill history={history} />, path: "/OPHome/PatientDetails/Bills", Access: CheckAccessFunc("front_office", "Home", "Billing", "Bills", "SubTab") },
          { id: 1.24, menuName: "Receipts", component: <PatientCompletedBill history={history} />, path: "/OPHome/PatientDetails/Receipts", Access: CheckAccessFunc("front_office", "Home", "Billing", "Receipts", "SubTab") }
        ],
        path: "/OPHome/PatientDetails/Billing"
      },
      UserData?.is_opt ? { id: 1.3, menuName: 'Nurse Notes', component: <NurseNotes selectedSubTab={1.3} />, subMenu: [], path: "/OPHome/PatientDetails/NurseNotes", Access: CheckAccessFunc("front_office", "Home", "Nurse Notes", null, "Tab") }
        : {
          id: 1.3, menuName: 'Nurse Notes', component: <NurseNotes selectedSubTab={1.3} />, subMenu: [
            { id: 1.31, menuName: "Vitals", component: <NurseNotes history={history} />, path: "/OPHome/PatientDetails/NurseNotes", Access: CheckAccessFunc("front_office", "Home", "Nurse Notes", "Vitals", "SubTab") },
            { id: 1.32, menuName: "Treatment Plan", component: <TreatmentPlan history={history} />, path: "/OPHome/PatientDetails/TreatmentPlan", Access: CheckAccessFunc("front_office", "Home", "Nurse Notes", "Treatment Plan", "SubTab") },
          ], path: "/OPHome/PatientDetails/NurseNotes", Access: CheckAccessFunc("front_office", "Home", "Nurse Notes", null, "Tab")
        },
      { id: 1.4, menuName: 'Doctor Visit Reports', component: <DoctorVisitReport />, subMenu: [], path: "/OPHome/PatientDetails/DoctorVisitReports", Access: CheckAccessFunc("front_office", "Home", "Doctor Visit Reports", null, "Tab") },
      { id: 1.5, menuName: 'Test Reports', component: <TestReports />, subMenu: [], path: "/OPHome/PatientDetails/TestReports", Access: CheckAccessFunc("front_office", "Home", "Test Reports", null, "Tab") },
      { id: 1.6, menuName: 'Print Order Reports', component: <PrintOrderReport />, subMenu: [], path: "/OPHome/PatientDetails/PrintOrderReports", Access: CheckAccessFunc("front_office", "Home", "Print Order Reports", null, "Tab") },
      {
        id: 1.7, menuName: 'Corporate', component: <ComingSoon />, Access: CheckAccessFunc("front_office", "Home", "Corporate", null, "Tab"), subMenu: [
          { id: 1.71, menuName: 'Claim Details', component: <CorporateClaimCard history={history} FromOp={true} />, path: "/OPHome/PatientDetails/CorporateDetails/ClaimDetails", Access: CheckAccessFunc("front_office", "Home", "Corporate", "InsuCorpoDetails", "SubTab") },
        ], path: "/OPHome/PatientDetails/CorporateDetails"
      },
      { id: 1.8, menuName: 'Patient Document', component: <DocPatientDocument fromOP={true} />, subMenu: [], path: "/OPHome/PatientDetails/PatientDocument", Access: CheckAccessFunc("front_office", "Home", "Patient Document", null, "Tab") },
      { id: 1.9, menuName: 'Death Certificate', component: <DocDeathCertific fromOP={true} />, subMenu: [], path: "/OPHome/PatientDetails/DeathCertificate", Access: CheckAccessFunc("front_office", "Home", "Death Certificate", null, "Tab") },
    ] :
      [
        { id: 9.1, menuName: 'Demographics', component: <CreateApppointment history={history} isForOPUpdate={true} />, subMenu: [], path: "/OPHome/PatientDetails/Demographics", Access: CheckAccessFunc("front_office", "Home", "Demographics", null, "Tab") },
        {
          id: 9.2, menuName: 'Clinical Notes', component: <NurseNotes history={history} />, Access: CheckAccessFunc("front_office", "Home", "Clinical Notes", null, "Tab"), subMenu: [
            { id: 9.21, menuName: "Vitals", component: <NurseNotes history={history} />, path: "/OPHome/PatientDetails/Vitals", Access: CheckAccessFunc("front_office", "Home", "Clinical Notes", "Vitals", "SubTab") },
            { id: 9.22, menuName: "Nurse Notes", component: <OPNurseNotes history={history} />, path: "/OPHome/PatientDetails/NurseNotes", Access: CheckAccessFunc("front_office", "Home", "Clinical Notes", "Nurse Notes", "SubTab") },
            { id: 9.23, menuName: "Treatment Plan", component: <TreatmentPlan history={history} />, path: "/OPHome/PatientDetails/TreatmentPlan", Access: CheckAccessFunc("front_office", "Home", "Nurse Notes", "Treatment Plan", "SubTab") },
          ],
          path: "/OPHome/PatientDetails/ClinicalNotes"
        },
        { id: 9.3, menuName: 'Doctor Visit Reports', component: <DoctorVisitReport />, subMenu: [], path: "/OPHome/PatientDetails/DoctorVisitReports", Access: CheckAccessFunc("front_office", "Home", "Doctor Visit Reports", null, "Tab") },
        { id: 9.4, menuName: 'Test Reports', component: <TestReports />, subMenu: [], path: "/OPHome/PatientDetails/TestReports", Access: CheckAccessFunc("front_office", "Home", "Test Reports", null, "Tab") },
        { id: 9.5, menuName: 'Print Order Reports', component: <PrintOrderReport />, subMenu: [], path: "/OPHome/PatientDetails/PrintOrderReports", Access: CheckAccessFunc("front_office", "Home", "Print Order Reports", null, "Tab") },

      ]

  const PatientBillingTabs = [
    { id: 1.6, menuName: 'Quick Billing & Appointment', component: <QuickBilling is_Nurse={isNurse} VisitDateBalAmount={VisitDateBalAmount} changeNursestate={changeNursestate} isQuickBilling={true} history={history} />, subMenu: [], path: "/OPHome/Billing/QuickBilling", Access: CheckAccessFunc("front_office", "Home", 'Quick Billing & Appointment', null, "Tab") },
    { id: 1.7, menuName: 'Create Appointment', component: <CreateApppointment OpCreate={true} is_Nurse={isNurse} VisitDateBalAmount={VisitDateBalAmount} changeNursestate={changeNursestate} history={history} />, subMenu: [], path: "/OPHome/Billing/CreateAppointment", Access: CheckAccessFunc("front_office", "Home", 'Create Appointment', null, "Tab") },
  ]

  const BillingTransactionTabs = [
    // { id: 2.1, menuName: 'Summary', component: <SummeryReport history={history} />, subMenu: [], path: "/BillingTransaction/Summary", Access: CheckAccessFunc("front_office", "Billing Transaction", 'Summary', null, "Tab") },
    { id: 2.2, menuName: 'Overall Bills', component: <OverAllBills history={history} />, subMenu: [], path: "/BillingTransaction/OverAllBills", Access: CheckAccessFunc("front_office", "Billing Transaction", 'Overall Bills', null, "Tab") },
    { id: 2.3, menuName: 'Credit Bills', component: <CreditBills history={history} />, subMenu: [], path: "/BillingTransaction/CreditBills", Access: CheckAccessFunc("front_office", "Billing Transaction", 'Credit Bills', null, "Tab") },
    // { id: 2.3, menuName: 'Completed Bills', component: <CompletedBills history={history} />, subMenu: [], path: "/BillingTransaction/CompletedBills", Access: CheckAccessFunc("front_office", "Billing Transaction", 'Completed Bills', null, "Tab") },
    { id: 2.4, menuName: 'Cancelled Bills', component: <CancelledBills history={history} />, subMenu: [], path: "/BillingTransaction/CancelledBills", Access: CheckAccessFunc("front_office", "Billing Transaction", 'Cancelled Bills', null, "Tab") },
    { id: 2.5, menuName: 'Saved Bills', component: <SavedBills history={history} />, subMenu: [], path: "/BillingTransaction/SavedBills", Access: CheckAccessFunc("front_office", "Billing Transaction", 'Saved Bills', null, "Tab") },
    { id: 2.6, menuName: 'Receipt Bills', component: <ReceiptBills history={history} />, subMenu: [], path: "/BillingTransaction/ReceiptBills", Access: CheckAccessFunc("front_office", "Billing Transaction", 'Receipt Bills', null, "Tab") },
    { id: 2.7, menuName: 'Day End', component: <DayEndStatus history={history} />, subMenu: [], path: "/BillingTransaction/DayEnd", Access: CheckAccessFunc("front_office", "Billing Transaction", 'Day End', null, "Tab") },
    { id: 2.8, menuName: 'Expenses', component: <ExpenseBills history={history} />, subMenu: [], path: "/BillingTransaction/Expenses", Access: CheckAccessFunc("front_office", "Billing Transaction", 'Expenses', null, "Tab") },
    { id: 2.9, menuName: "Day End Denomination", component: <UserDayEndSalesReport from = "Clinic" history={history} {...props} />, subMenu: [], path: '/BillingTransaction/DayEndDenomination', testID: 'fo_denomination', Access: CheckAccessFunc("front_office", "Billing Transaction", "Day End Denomination", null, "Tab") }
  ]

  const BillingTabs = [
    { id: 6.1, menuName: 'Laboratory Billing', component: <LabBilling history={history} />, subMenu: [], path: "/Billing/LabBilling", Access: CheckAccessFunc("front_office", "Billing", 'Overall Bills', null, "Tab") },
    { id: 6.2, menuName: 'Radiology Billing', component: <RadQuickDemographics history={history} getRadTransmitCount = {getRadTransmitCount}/>, subMenu: [], path: "/Billing/RadiologyBilling", Access: CheckAccessFunc("front_office", "Billing", 'Credit Bills', null, "Tab") },
  ].filter((list) => (list?.id !== 6.2 || localGetItem("Show_Radiology_Bills_In_FO") === "true"))

  const ReportsTabs = {
    service_reports: [
      { id: 3.11, menuName: 'OP Service Detailed', component: <ServiceDetailed />, subMenu: [], path: "/FOReports/ServiceReports/ServiceDetailed", Access: CheckAccessFunc("front_office", "Reports", "OP Service Reports", 'OP Service Detailed', "SubTab") },
      { id: 3.12, menuName: 'OP Service Overview', component: <ServiceOverview />, subMenu: [], path: "/FOReports/ServiceReports/ServiceOverview", Access: CheckAccessFunc("front_office", "Reports", "OP Service Reports", 'OP Service Overview', "SubTab") },
      { id: 3.13, menuName: 'OP Service Share', component: <OPServiceShare />, subMenu: [], path: "/FOReports/ServiceReports/OPServiceShare", Access: CheckAccessFunc("front_office", "Reports", "OP Service Reports", 'OP Service Share', 'SubTab') },
      { id: 3.14, menuName: 'Consultation Wise Report', component: <ConsultWiseReport />, subMenu: [], path: "/FOReports/ServiceReports/ConsultWiseReport", Access: CheckAccessFunc("front_office", "Reports", "OP Service Reports", 'Consultation Wise Report', 'SubTab') }
    ],
    ip_service_reports: [
      { id: 3.21, menuName: 'IP Service Detailed', component: <IPServiceDetailed />, subMenu: [], path: "/FOReports/IpServiceReport/IPServiceDetailed", Access: CheckAccessFunc("front_office", "Reports", "IP Service Reports", 'IP Service Detailed', "SubTab") },
      { id: 3.22, menuName: 'IP Service Overview', component: <IPServiceOverview />, subMenu: [], path: "/FOReports/IpServiceReport/IPServiceOverview", Access: CheckAccessFunc("front_office", "Reports", "IP Service Reports", 'IP Service Overview', "SubTab") },
      { id: 3.23, menuName: 'IP Service Share', component: <ServiceShare />, subMenu: [], path: "/FOReports/ServiceReports/IPServiceShare", Access: CheckAccessFunc("front_office", "Reports", "IP Service Reports", 'IP Service Share', 'SubTab') },
      { id: 3.24, menuName: 'IP Leger Report', component: <IPLegerReport />, subMenu: [], path: "/FOReports/IpServiceReport/IPLegerReport", Access: CheckAccessFunc("front_office", "Reports", "IP Service Reports", 'IP Leger Report', "SubTab") },
      { id: 3.25, menuName: 'IP Admission List', component: <IpAdmissionReport />, subMenu: [], path: "/FOReports/IpServiceReport/IPAdmissionList", Access: CheckAccessFunc("front_office", "Reports", "IP Service Reports", 'IP Admission List', "SubTab") },
      { id: 3.26, menuName: 'IP Discharge List', component: <IPDischargeList />, subMenu: [], path: "/FOReports/IpServiceReport/IPDischargeList", Access: CheckAccessFunc("front_office", "Reports", "IP Service Reports", 'IP Discharge List', "SubTab") },
      { id: 3.27, menuName: 'IP Occupancy Report', component: <IPOccupancyReport />, subMenu: [], path: "/FOReports/IpServiceReport/IPOccupancyReport", Access: CheckAccessFunc("front_office", "Reports", "IP Service Reports", 'IP Occupancy Report', "SubTab") }
    ],
    corporate_reports: [
      { id: 3.31, menuName: 'Corporate Patient Bill Report', component: <CopPatientReport />, subMenu: [], path: "/FOReports/CorporateReports/BillReport", Access: CheckAccessFunc("front_office", "Reports", "Corporate Reports", "Corporate Patient Bill Report", "SubTab") },
      { id: 3.32, menuName: 'Corporate Payments Report', component: <CopPaymentReport />, subMenu: [], path: "/FOReports/CorporateReports/PaymentReport", Access: CheckAccessFunc("front_office", "Reports", "Corporate Reports", "Corporate Payment Reports", "SubTab") },
      { id: 3.33, menuName: 'Insurance Patient Bill Report', component: <InsPatientReport />, subMenu: [], path: "/FOReports/CorporateReports/InsuranceReport", Access: CheckAccessFunc("front_office", "Reports", "Corporate Reports", "Insurance Patient Bill Report", "SubTab") },
    ],
    appointment_reports: [
      { id: 3.41, menuName: "Patients Appointment Report", component: <AppoinmentPatientReport />, subMenu: [], path: "/FOReports/AppointmentReports/PatientReport", Access: CheckAccessFunc("front_office", "Reports", "Appointment Reports", "Patient Based Apointment Report", "SubTab") },
      { id: 3.42, menuName: "Doctors Appointment Report", component: <AppointmentDoctorReport />, subMenu: [], path: "/FOReports/AppointmentReports/DoctorReport", Access: CheckAccessFunc("front_office", "Reports", "Appointment Reports", "Doctor Based Apointment Report", "SubTab") },
      { id: 3.43, menuName: 'Specialization Appointment Report', component: <AppointmentSpecializationReport />, subMenu: [], path: "/FOReports/AppointmentReports/SpecializationReport", Access: CheckAccessFunc("front_office", "Reports", "Appointment Reports", "Specialization Based Apointment Report", "SubTab") },
      { id: 3.44, menuName: 'Appointment Type Report', component: <AppointmentTypeReport />, subMenu: [], path: "/FOReports/AppointmentReports/TypeBasedReport", Access: CheckAccessFunc("front_office", "Reports", "Appointment Reports", "Type Based Apointment Report", "SubTab") },
      { id: 3.45, menuName: 'TAT Report', component: <TATReport />, subMenu: [], path: "/FOReports/AppointmentReports/TATReport", Access: CheckAccessFunc("front_office", "Reports", "Appointment Reports", "TAT Report", "SubTab") },
      { id: 3.46, menuName: 'No Show Report', component: <NoShowReport />, subMenu: [], path: '/FOReports/AppointmentReports/NoShowReport', Access: CheckAccessFunc("front_office", "Reports", "Appointment Reports", "No Show Report", "SubTab") },
      { id: 3.47, menuName: 'Counsellor Surgery Conversion Report', component: <CounsellorSurgeryReport />, subMenu: [], path: '/FOReports/AppointmentReports/CounsellorSurgeryReport', Access: CheckAccessFunc("front_office", "Reports", "Appointment Reports", "Counsellor Surgery Conversion Report", "SubTab") },
    ],
    patient_reports: [
      { id: 3.51, menuName: 'Patient Demographics', component: <DemographicsReport />, subMenu: [], path: "/FOReports/PatientReport/PatientDemographicReport", Access: CheckAccessFunc("front_office", "Reports", "Patient Reports", "Patient Demographics Report", "SubTab") },
      { id: 3.52, menuName: 'Purpose Of Visit Report', component: <PurposeOfVisitReport />, subMenu: [], path: '/FOReports/PatientReport/PurposeOfVisitReport', Access: CheckAccessFunc("front_office", "Reports", "Patient Reports", 'Purpose Of Visit Report', "SubTab") },
      { id: 3.53, menuName: 'Reason / Diagnosis Report', component: <ReasonDiagnosisReport />, subMenu: [], path: '/FOReports/PatientReport/ReasonDiagnosisReport', Access: CheckAccessFunc("front_office", "Reports", "Patient Reports", 'Reason Diagnosis Report', "SubTab") },
      { id: 3.54, menuName: 'Concession Report', component: <ConcessionReport />, subMenu: [], path: '/FOReports/PatientReport/ConcessionReport', Access: CheckAccessFunc("front_office", "Reports", "Patient Reports", 'Concession Report', "SubTab") },
      { id: 3.55, menuName: "Credit Settlement Report", component: <CreditSettlementReport />, subMenu: [], path: '/FOReports/PatientReport/CreditSettlementReport', Access: CheckAccessFunc("front_office", "Reports", "Patient Reports", "Credit Settlement Report", "SubTab") },
    ],
    referral_report: [
      { id: 3.61, menuName: 'Referral Doctor Report', component: <RefDoctorReport />, subMenu: [], path: "/FOReports/ReferralReports/DoctorReport", Access: CheckAccessFunc("front_office", "Reports", "Referral Reports", "Referral Doctor Report", "SubTab") },
      { id: 3.62, menuName: 'Referral Source Report', component: <RefSourceReport />, subMenu: [], path: "/FOReports/ReferralReports/SourceReport", Access: CheckAccessFunc("front_office", "Reports", "Referral Reports", "Referral Source Report", "SubTab") },
      { id: 3.63, menuName: 'Reference Value Report', component: <RefValueReport />, subMenu: [], path: "/FOReports/ReferralReports/ReferalValueReport", Access: CheckAccessFunc("front_office", "Reports", "Referral Reports", "Reference Value Report", "SubTab") },
    ],
    opt_report: [
      { id: 3.71, menuName: 'Optical CRM Sales', component: <OpticalCRMsales />, subMenu: [], path: "/FOReports/OptReports/OpticalCRMReport", Access: CheckAccessFunc("front_office", "Reports", 'Ophthalmology Reports', "Optical CRM Sales", "SubTab") },
      { id: 3.72, menuName: 'Prescription Report', component: <PrescriptionReport />, subMenu: [], path: "/FOReports/OptReports/PrescriptionReport", Access: CheckAccessFunc("front_office", "Reports", 'Ophthalmology Reports', "Prescription Report", "SubTab") },
    ],
    optometrist_report: [
      { id: 3.81, menuName: 'Optometrist List', component: <OptometristList />, subMenu: [], path: "'/FOReports/OptometristReports/OptometristList'", Access: CheckAccessFunc("front_office", "Reports", 'Optometrist Reports', "Optometrist List", "SubTab") },
    ],
    tally_report: [
      { id: 4.91, menuName: 'Generate Report', component: <GenerateReport />, subMenu: [], path: "/FOReports/TallyReports/GenerateReport", Access: CheckAccessFunc("front_office", "Reports", 'Tally Reports', "Generate Report", "SubTab") },
    ],
    Account_reports:UserData?.account_tally_report ? [
      { id: 3.91, menuName: 'Bill Wise Reports', component: <BillWiseReports/>, subMenu: [], path: "/FOReports/AccountReports/BillWiseReports", Access: CheckAccessFunc("front_office", "Reports", "Account Reports", "Bill Wise Reports", "SubTab") },
      { id: 3.92, menuName: 'Advance Reports', component: <AdvanceReports/>, subMenu: [], path: '/FOReports/AccountReports/AdvanceReports', Access: CheckAccessFunc("front_office", "Reports", "Account Reports", 'Advance Reports', "SubTab") },
      { id: 3.93, menuName: 'Refund/Cancelled Reports', component: <RefundorCancelledReports/>, subMenu: [], path: '/FOReports/AccountReports/RefundorCancelledReports', Access: CheckAccessFunc("front_office", "Reports", "Account Reports", 'Refund/Cancelled Reports', "SubTab") },
      { id: 3.94, menuName: 'Credit Service Reports', component: <CreditServiceReports/>, subMenu: [], path: '/FOReports/AccountReports/CreditServiceReports', Access: CheckAccessFunc("front_office", "Reports", "Account Reports", 'Credit Service Reports', "SubTab") },
      { id: 3.95, menuName: 'Credit Settlement Reports', component: <AccountsCreditSettlementReport/>, subMenu: [], path: '/FOReports/AccountReports/AccountsCreditSettlementReport', Access: CheckAccessFunc("front_office", "Reports", "Account Reports", 'Credit Service Reports', "SubTab") },
      { id: 3.96, menuName: "Concession Reports", component: <ConcessionReports/>, subMenu: [], path: '/FOReports/AccountReports/ConcessionReports', Access: CheckAccessFunc("front_office", "Reports", "Account Reports", "Concession Reports", "SubTab") },
    ] : []
  }

  let showDoctorShare = UserData?.doctor_share_report
  if(showDoctorShare){
    ReportsTabs.Account_reports?.push(
      { id: 3.97, menuName: "Doctor Share Reports", component: <DoctorShareReports/>, subMenu: [], path: '/FOReports/AccountReports/DoctorShareReports', Access: CheckAccessFunc("front_office", "Reports", "Account Reports", "Doctor Share Reports", "SubTab") },
    )
  }

  
  let showDayWise = UserData?.show_daywise_report
  if(showDayWise){
    ReportsTabs.patient_reports?.push(
      { id: 3.56, menuName: "Day-wise Category Sales Report", component: <DaywiseCategorySales />, subMenu: [], path: '/FOReports/PatientReport/DaywiseCategorySalesReport', Access: CheckAccessFunc("front_office", "Reports", "Patient Reports", "Daywise Category Sales Report", "SubTab") },
    )
  }

  const SettingsTabs = {
        Op_services: [
      { id: 4.15, menuName: 'Service Name & Type Config', component: <OPNewServiceConfig />, subMenu: [], path: "/Settings/OPService/ServiceNameTypeConfig", Access: CheckAccessFunc("front_office", "Settings", "OP Service Configuration", "Service Name & Type Config", "SubTab") },
      { id: 4.2, menuName: 'Service Template', component: <ServiceTemplate />, subMenu: [], path: "/Settings/OPService/ServiceTemplates", Access: CheckAccessFunc("front_office", "Settings", "OP Service Configuration", "Service Template", "SubTab") },
    ],
    referral_config: [
      { id: 4.8, menuName: 'Referral Source', component: <ReferralSource history={history} />, subMenu: [], path: "/Settings/ReferralConfig/SourceConfig", Access: CheckAccessFunc("front_office", "Settings", "Referral Configuration", "Referral Source", "SubTab") },
      { id: 4.9, menuName: 'Referral Doctor', component: <ReferralDoctor history={history} />, subMenu: [], path: "/Settings/ReferralConfig/DoctorConfig", Access: CheckAccessFunc("front_office", "Settings", "Referral Configuration", "Referral Doctor", "SubTab") },
    ],
    corporate_config: [
      { id: 4.101, menuName: 'Corporate', component: <CorporateConfig />, subMenu: [], path: "/Settings/CorporateConfig/CorporateEnroll", Access: CheckAccessFunc("front_office", "Settings", "Corporate Configuration", "Corporate", "SubTab") },
      { id: 4.102, menuName: 'Patient Details', component: <CorpPatientDetails />, subMenu: [], path: "/Settings/CorporateConfig/PatientDetails", Access: CheckAccessFunc("front_office", "Settings", "Corporate Configuration", "Patient Details", "SubTab") },
      // { id: 4.103, menuName: 'Employer Billing', component: <EmployerBilling />, subMenu: [], path: "/Settings/CorporateConfig/EmployerBilling"  , Access: CheckAccessFunc("front_office", "Settings",  "Corporate Configuration",  "Employer Billing", "SubTab")},
      // { id: 4.104, menuName: 'Reports', component: <CorpReports />, subMenu: [], path: "/Settings/CorporateConfig/Reports"  , Access: CheckAccessFunc("front_office", "Settings",  "Corporate Configuration",  "Reports", "SubTab")},
    ],
    user_config: [
    ],
    demographics_config: [
    ],
    insurance_config: [
      { id: 4.201, menuName: 'Insurance Configuration', component: <InsuranceConfig />, subMenu: [], path: "/Settings/InsuranceConfig", Access: CheckAccessFunc("front_office", "Settings", "Insurance Configuration", "Insurance Configuration", "SubTab") },
    ],
    sms_config: [
      { id: 4.301, menuName: 'SMS Request', component: <SmsRequest />, subMenu: [], path: "/Settings/SmsConfig/Request", Access: CheckAccessFunc("front_office", "Settings", "SMS Configuration", "SMS Request", "SubTab") },
      { id: 4.302, menuName: 'SMS Configuration', component: <SmsConfig />, subMenu: [], path: "/Settings/SmsConfig/Config", Access: CheckAccessFunc("front_office", "Settings", "SMS Configuration", "SMS Configuration", "SubTab") },
    ]

  }
  const IPSettingsTabs = {
    Ip_services: [
      { id: 5.3, menuName: 'Surgery Configuration', component: <IPSurgeryConfig />, subMenu: [], path: "/Settings/IPService/SurgeryConfiguration", Access: CheckAccessFunc("front_office", "Settings", "IP Service Configuration", "Surgery Configuration", "SubTab") },
      { id: 5.1, menuName: 'IP Service Name & Type Config', component: <IPNewServiceConfig />, subMenu: [], path: "/Settings/IPService/IPServiceNameTypeConfig", Access: CheckAccessFunc("front_office", "Settings", "IP Service Configuration", "IP Service Name & Type Config", "SubTab") },
      { id: 5.2, menuName: 'IP Package Configuration', component: <IPPackageConfig />, subMenu: [], path: "/Settings/IPService/IPPackageConfig", Access: CheckAccessFunc("front_office", "Settings", "IP Service Configuration", "IP Package Configuration", "SubTab") },
    ],
    room_config: [
      { id: 5.3, menuName: 'Block & Floor Configuration', component: <HospitalConfig />, subMenu: [], path: "/Settings/RoomConfig/HospitalConfig", Access: CheckAccessFunc("front_office", "Settings", "Room Configuration", "Block & Floor Configuration", "SubTab") },
      { id: 5.4, menuName: 'Ward Configuration', component: <WardConfig />, subMenu: [], path: "/Settings/RoomConfig/WardConfig", Access: CheckAccessFunc("front_office", "Settings", "Room Configuration", "Ward Configuration", "SubTab") },
      { id: 5.5, menuName: 'Bed Configuration', component: <BedConfig />, subMenu: [], path: "/Settings/RoomConfig/BedConfig", Access: CheckAccessFunc("front_office", "Settings", "Room Configuration", "Bed Configuration", "SubTab") },
    ],
    layout_config: [
      { id: 5.6, menuName: 'Demographics Configuration', component: <DemographicsConfigHome />, subMenu: [], path: "/Settings/DemographicsConfig/DemographicsLayout", Access: CheckAccessFunc("front_office", "Settings", "Layout Configuration", "Demographics Configuration", "SubTab") },
      { id: 5.7, menuName: 'Discharge Summary Layout', component: <DisSumLayoutConfig history={history} />, subMenu: [], path: "/Settings/LayoutConfig/DisSummaryLayoutConfig", Access: CheckAccessFunc("front_office", "Settings", "Layout Configuration", "Discharge Summary Layout", "SubTab") },
      { id: 5.8, menuName: 'Surgery Notes Layout', component: <SurgeryNotesLayoutConfig history={history} />, subMenu: [], path: "/Settings/LayoutConfig/SurgeryNotesLayout", Access: CheckAccessFunc("front_office", "Settings", "Layout Configuration", "Surgery Notes Layout", "SubTab") },
      { id: 5.9, menuName: 'Print Configuration', component: <PrintConfig />, subMenu: [], path: "/Settings/PrintConfig/PrintSettings", Access: CheckAccessFunc("front_office", "Settings", "Layout Configuration", "Print Configuration", "SubTab") },

    ],
    template_config: [
      { id: 5.10, menuName: 'Discharge Summary Template Configuration', component: <DisSumTemplateConfig history={history} />, subMenu: [], path: "/Settings/TemplateConfig/DisSumTemplateConfig", Access: CheckAccessFunc("front_office", "Settings", "Template Configuration", "Discharge Summary Template Configuration", "SubTab") },
      { id: 5.11, menuName: 'Surgery Notes Template Configuration', component: <SurgeryTemplateConfig history={history} />, subMenu: [], path: "/Settings/TemplateConfig/SurgeryTemplateConfig", Access: CheckAccessFunc("front_office", "Settings", "Template Configuration", "Surgery Notes Template Configuration", "SubTab") },
    ],
    template_sub_config: [
      { id: 5.12, menuName: 'Discharge Summary Template Configuration', component: <DisSumTemplateAddConfig history={history} />, subMenu: [], path: "/Settings/TemplateConfig/DisSumTemplateConfig/Add", Access: CheckAccessFunc("front_office", "Settings", "Template Configuration", "Discharge Summary Template Configuration", "SubTab") },
      { id: 5.13, menuName: 'Surgery Notes Template Configuration', component: <SurgeryTemplateAddConfig history={history} />, subMenu: [], path: "/Settings/TemplateConfig/SurgeryTemplateConfig/Add", Access: CheckAccessFunc("front_office", "Settings", "Template Configuration", "Surgery Notes Template Configuration", "SubTab") },
    ],
    card_config: [
      { id: 5.14, menuName: 'Card Layout Configuration', component: <CardLayoutConfig history={history} />, subMenu: [], path: "/Settings/CardConfig/CardLayoutConfig", Access: CheckAccessFunc("front_office", "Settings", "Card Configuration", "Card Layout Configuration", "SubTab") }
    ],
  }

  const IP_Patient_Details = [
    { id: 6.1, menuName: 'Patient Details', component: <IpPatientDetails history={history} />, subMenu: [], path: "/IPHome/PatientDetails/PatientDetails", Access: CheckAccessFunc("front_office", "In Patients", "Patient Details", null, "Tab") },
    {
      id: 6.2, menuName: 'Service Billing', component: <ServiceBilling history={history} />, subMenu: [
        { id: 6.21, menuName: 'Create Bill', component: <ServiceBilling history={history} />, path: "/IPHome/PatientDetails/ServiceBilling", Access: CheckAccessFunc("front_office", "In Patients", "Service Billing", "Create Bill", "SubTab") },
        { id: 6.22, menuName: 'Saved Bill', component: <IpPatientSavedBill history={history} />, path: "/IPHome/PatientDetails/IpSavedBill", Access: CheckAccessFunc("front_office", "In Patients", "Service Billing", "Saved Bill", "SubTab") },
        { id: 6.23, menuName: 'Bills', component: <IpPatientBills history={history} />, path: "/IPHome/PatientDetails/IpBills", Access: CheckAccessFunc("front_office", "In Patients", "Service Billing", "Bills", "SubTab") },
        { id: 6.24, menuName: 'Receipts', component: <IpPatinetReceipt history={history} />, path: "/IPHome/PatientDetails/IpReceiptsBill", Access: CheckAccessFunc("front_office", "In Patients", "Service Billing", "Receipts", "SubTab") }
      ], path: "/IPHome/PatientDetails/ServiceBilling", Access: CheckAccessFunc("front_office", "In Patients", "Service Billing", null, "Tab")
    },
    { id: 6.3, menuName: "Room Change", component: <RoomChange history={history} />, subMenu: [], path: "/IPHome/PatientDetails/RoomChange", Access: CheckAccessFunc("front_office", "In Patients", "Room Change", null, "Tab") },
    { id: 6.4, menuName: 'Receive Payment', component: <ReceivePayment history={history} />, subMenu: [], path: "/IPHome/PatientDetails/ReceivePayment", Access: CheckAccessFunc("front_office", "In Patients", "Receive Payment", null, "Tab") },
    // { id: 6.5, menuName: 'Intial Assessment', component: <ComingSoon />, subMenu: [], path: "/IPHome/PatientDetails/InitialAssessment" , Access: CheckAccessFunc("front_office", "In Patients", "Intial Assessment", null, "Tab") },
    {
      id: 6.6, menuName: 'Clinical Notes', component: <ComingSoon />, Access: CheckAccessFunc("front_office", "In Patients", "Clinical Notes", null, "Tab"), subMenu: [
        { id: 6.61, menuName: 'Vitals', component: <Vitals />, path: "/IPHome/PatientDetails/ClinicalNotes/Vitals", Access: CheckAccessFunc("front_office", "In Patients", "Clinical Notes", "Vitals", "SubTab") },
        { id: 6.62, menuName: 'Doctor Notes', component: <DoctorNotes history={history} />, path: "/IPHome/PatientDetails/ClinicalNotes/DoctorNotes", Access: CheckAccessFunc("front_office", "In Patients", "Clinical Notes", "Doctor Notes", "SubTab") },
        { id: 6.63, menuName: 'Nurse Notes', component: <IpNurseNotes />, path: "/IPHome/PatientDetails/ClinicalNotes/NurseNotes", Access: CheckAccessFunc("front_office", "In Patients", "Clinical Notes", "Nurse Notes", "SubTab") },
        { id: 6.64, menuName: 'IV / IM Track', component: <InsulinTrack />, path: "/IPHome/PatientDetails/ClinicalNotes/IVTrack", Access: CheckAccessFunc("front_office", "In Patients", "Clinical Notes", "IV / IM Track", "SubTab") },
        { id: 6.65, menuName: 'Drug Chart', component: <DrugChart history={history}/>, path: "/IPHome/PatientDetails/ClinicalNotes/DrugChart", Access: CheckAccessFunc("front_office", "In Patients", "Clinical Notes", "Drug Chart", "SubTab") },
        { id: 6.66, menuName: 'Pain Chart', component: <PainChart />, path: "/IPHome/PatientDetails/ClinicalNotes/PainChart", Access: CheckAccessFunc("front_office", "In Patients", "Clinical Notes", "Pain Chart", "SubTab") },
        { id: 6.67, menuName: 'Nutrition Chart', component: <NutritionChart history={history}/>, path: "/IPHome/PatientDetails/ClinicalNotes/NutritionChart", Access: CheckAccessFunc("front_office", "In Patients", "Clinical Notes", "Nutrition Chart", "SubTab")},
        { id: 6.68, menuName: 'Input/OutPut Chart', component: <InputOutChart history={history}/>, path: "/IPHome/PatientDetails/ClinicalNotes/InputOutputChart", Access: CheckAccessFunc("front_office", "In Patients", "Clinical Notes", "Input/OutPut Chart", "SubTab")},
        { id: 6.69, menuName: 'Medication', component: <Medication />, path: "/IPHome/PatientDetails/ClinicalNotes/Medication", Access: CheckAccessFunc("front_office", "In Patients", "Clinical Notes", "Medication", "SubTab") },
        { id: 6.70, menuName: 'Laboratory', component: <Laboratory />, path: "/IPHome/PatientDetails/ClinicalNotes/Laboratory", Access: CheckAccessFunc("front_office", "In Patients", "Clinical Notes", "Laboratory", "SubTab") }
      ], path: "/IPHome/PatientDetails/ClinicalNotes"
    },
    {
      id: 6.14, menuName: 'Reports', component: <ComingSoon />, Access: CheckAccessFunc("front_office", "In Patients", "Reports", null, "Tab"), subMenu: [
        { id: 6.141, menuName: 'Test Report', component: <IPTestReport />, path: "/IPHome/PatientDetails/Reports/TestReport", Access: CheckAccessFunc("front_office", "In Patients", "Clinical Notes", "Test Report", "SubTab") },
        { id: 6.142, menuName: 'Print Order Report', component: <PrintOrderReport fromIP={true} />, path: "/IPHome/PatientDetails/Reports/PrintOrderReport", Access: CheckAccessFunc("front_office", "In Patients", "Clinical Notes", "Print Order Report", "SubTab") },
      ], path: "/IPHome/PatientDetails/Reports"
    }, 
    {
      id: 6.13, menuName: 'History', component: <DocMedicalHistory history={history} is_From_Fo = {true}/>, Access: CheckAccessFunc("front_office", "In Patients", "History", null, "Tab"), subMenu: [
        { id: 6.131, menuName: 'Medical History', component: <DocMedicalHistory history={history} is_From_Fo = {true}/>, path: "/IPHome/PatientDetails/History/MedicalHistory", Access: CheckAccessFunc("front_office", "In Patients", "History", "Medical History", "SubTab") },
        { id: 6.132, menuName: 'Hospitalization History', component: <DocHospitalizationHistory history={history} is_From_Fo = {true}/>, path: "/IPHome/PatientDetails/History/HospitalizationHistory", Access: CheckAccessFunc("front_office", "In Patients", "History", "Hospitalization History", "SubTab") },
        { id: 6.133, menuName: 'Social History', component: <DocSocialHistory history={history} is_From_Fo = {true}/>, path: "/IPHome/PatientDetails/History/SocialHistory", Access: CheckAccessFunc("front_office", "In Patients", "History", "Social History", "SubTab") },
        { id: 6.134, menuName: 'Family History', component: <DocFamilyHistory history={history} is_From_Fo = {true}/>, path: "/IPHome/PatientDetails/History/FamilyHistory", Access: CheckAccessFunc("front_office", "In Patients", "History", "Family History", "SubTab") },
      ], path: "/IPHome/PatientDetails/History"
    }, 
    { id: 6.7, menuName: 'Discharge Summary', component: <DischargeSummary />, subMenu: [], path: "/IPHome/PatientDetails/DischargeSummary", Access: CheckAccessFunc("front_office", "In Patients", "Discharge Summary", null, "Tab") },
    { id: 6.8, menuName: 'Surgery Notes', component: <SurgeryNotes history={history}/>, subMenu: [], path: "/IPHome/PatientDetails/SurgeryNotes", Access: CheckAccessFunc("front_office", "In Patients", "Surgery Notes", null, "Tab") },
    { id: 6.12, menuName: 'Patient Documents', component: <DocPatientDocument history={history} FromDoctor={true} FromFO={true}/>, subMenu: [], path: "/IPHome/PatientDetails/PatientDocuments", Access: CheckAccessFunc("front_office", "In Patients", "Patient Documents", null, "Tab") },
    { id: 6.94, menuName: 'Death Certificate', component: <DocDeathCertific fromIP={true} history={history} FromDoctor={true} FromFO={true}/>, subMenu: [], path: "/IPHome/PatientDetails/DeathCertificate", Access: CheckAccessFunc("front_office", "In Patients", "Death Certificate", null, "Tab") },
    {
      id: 6.9, menuName: 'Insurance', component: <ComingSoon />, Access: CheckAccessFunc("front_office", "In Patients", "Insurance", null, "Tab"), subMenu: [
        { id: 6.91, menuName: 'Insurance Details', component: <InsuCorpoDetails history={history} />, path: "/IPHome/PatientDetails/InsuranceDetails/InsuDetails", Access: CheckAccessFunc("front_office", "In Patients", "Insurance", "InsuranceDetails", "SubTab") },
        { id: 6.92, menuName: 'Pre Authorization', component: <PreAuth history={history} />, path: "/IPHome/PatientDetails/InsuranceDetails/PreAuth", Access: CheckAccessFunc("front_office", "In Patients", "Insurance ", "PreAuth", "SubTab") },
        { id: 6.93, menuName: 'Claim Details', component: <ClaimDetails history={history} />, path: "/IPHome/PatientDetails/InsuranceDetails/ClaimDetails", Access: CheckAccessFunc("front_office", "In Patients", "Insurance", "ClaimDetails", "SubTab") },
      ], path: "/IPHome/PatientDetails/InsuranceDetails"
    },
    {
      id: 6.11, menuName: 'Corporate', component: <ComingSoon />, Access: CheckAccessFunc("front_office", "In Patients", "Corporate", null, "Tab"), subMenu: [
        { id: 6.111, menuName: 'Claim Details', component: <CorporateClaimCard history={history} />, path: "/IPHome/PatientDetails/CorporateDetails/ClaimDetails", Access: CheckAccessFunc("front_office", "In Patients", "Corporate", "InsuCorpoDetails", "SubTab") },
      ], path: "/IPHome/PatientDetails/CorporateDetails"
    },
  ]

  const UserSettings = {
    user_config: [
      { id: 6.1, menuName: 'User Access', component: <Useraccess history={history} />, subMenu: [], path: "/Settings/UserAccess/UserSettings", Access: CheckAccessFunc("front_office", "Settings", "User Access Configuration", "User Access", "SubTab") },
      { id: 6.2, menuName: 'User Configuration', component: <UserConfiguration history={history} />, subMenu: [], path: "/Settings/UserConfig/Users", Access: CheckAccessFunc("front_office", "Settings", "User Access Configuration", "User Configuration", "SubTab") },

    ],
    user_new_config: [
      { id: 6.4, menuName: 'User Access', component: <CommonUserAccess history={history} />, subMenu: [], path: "/Settings/UserAccess/UserSettings/New", Access: CheckAccessFunc("front_office", "Settings", "User Access Configuration", "User Access", "SubTab") },
    ],
    print_config: [
    ],
    custom_config: [
    ],
  }

  const IP_Patient_ViewTabs = [
    { id: 7.1, menuName: 'Demographics', component: <ViewDemographics history={history} />, subMenu: [], path: "/IPHome/Demographics/Demographics" },
    { id: 7.2, menuName: 'Appointment / Admission History', component: <AdmissionHistory history={history} />, subMenu: [], path: "/IPHome/Demographics/AdmissionHistory" },
  ]

  const IP_Patient_viewBilling_Tabs = [
    { id: 8.1, menuName: 'Billing Details', component: <BillingDetails history={history} />, subMenu: [], path: "/IPHome/Billing/BillingDetails" },
    { id: 8.2, menuName: 'Clinical / Doctor Notes', component: <ClinicNotes history={history} />, subMenu: [], path: "/IPHome/Billing/ClinicalNotes" },
    { id: 8.3, menuName: 'Medication', component: <BillingMedication history={history} />, subMenu: [], path: "/IPHome/Billing/Medication" },
    { id: 8.4, menuName: 'Summary', component: <BillingSummary history={history} />, subMenu: [], path: "/IPHome/Billing/Summary" }
  ]

  const IP_Home_Tab = [
    { id: 10.1, menuName: 'In Patient List', component: <IPpatientsHome history={history} {...props} dateDetails={dateDetails} />, subMenu: [], path: "/IPHome" },
    { id: 10.2, menuName: 'Reservation', component: <RoomAvailability history={history} {...props} dateDetails={reserveDateDetails} />, subMenu: [], path: "/IPHome/Reservation" },
    { id: 10.3, menuName: 'Bed Availability', component: <BedAvailable history={history} {...props} />, subMenu: [], path: "/IPHome/BedAvailability" },
    { id: 10.4, menuName: 'Estimation', component: <Estimation history={history} {...props} />, subMenu: [], path: "/IPHome/Estimation" },
    { id: 10.5, menuName: 'Occupancy', component: <BedAvailability history={history} {...props} />, subMenu: [], path: "/IPHome/Occupancy" },
    { id: 10.6, menuName: 'OT List', component: <OThome history={history} {...props} />, subMenu: [], path: "/IPHome/OThome" },
  ]

  const IPReservation = [
    { id: 10.21, menuName: 'Create Reservation', component: <CreateReservation history={history} {...props} />, subMenu: [], path: "/IPHome/Reservation/Create" },
  ]

  const Estimation_Tabs = [
    { id: 11.1, menuName: 'Estimation Summary', component: <EstimationSummary history={history} {...props} />, subMenu: [], path: "/IPHome/EstimationSummary" },
  ]

  const ExpenseSettings = [
  ]


  // const Pharamacy_Billing = [
  //   {id:11.1,menuName:"Pharmacy Billing",component:<PharmaHome history={history} {...props} />,subMenu:[],path:'/Pharmacy/Billing'}
  // ]
  let pharmacy_type = UserData?.pharmacy_type === 'Inhouse Pharmacy' ? "Pharmacy" : "Standalone Pharmacy";

  const Pharamacy_Invoice = UserData?.pharmacy_type === 'Inhouse Pharmacy' ? [
    { id: 12.10, menuName: "Overall Bills", component: <OverAllBills history={history} {...props} />, subMenu: [], path: '/PharmacyInvoice/Invoice', testID: 'Overall Bills', Access: CheckAccessFunc("Pharmacy", "Billing Transaction", "Overall Bills", null, "Tab") },
    { id: 12.20, menuName: "Credit Bills", component: <PharmaCreditBills history={history} {...props} />, subMenu: [], path: '/PharmacyInvoice/CreditBills', testID: 'Credit Bills', Access: CheckAccessFunc("Pharmacy", "Billing Transaction", "Credit Bills", null, "Tab") },
    { id: 12.30, menuName: "Saved Bills", component: <SavedBills history={history} {...props} />, subMenu: [], path: '/PharmacyInvoice/SavedBills', testID: 'Saved Bills', Access: CheckAccessFunc("Pharmacy", "Billing Transaction", "Saved Bills", null, "Tab") },
    { id: 12.40, menuName: "Receipt Bills", component: <ReceiptBills history={history} {...props} />, subMenu: [], path: '/PharmacyInvoice/ReceiptBills', testID: 'Receipt Bills', Access: CheckAccessFunc("Pharmacy", "Billing Transaction", "Receipt Bills", null, "Tab") },
    { id: 12.50, menuName: "Day End Denomination", component: <UserDayEndSalesReport from = "Pharmacy" history={history} {...props} />, subMenu: [], path: '/PharmacyInvoice/DayEndDenomination', testID: 'Receipt Bills', Access: CheckAccessFunc("Pharmacy", "Billing Transaction", "Day End Denomination", null, "Tab") }
  ] : [
    { id: 12.10, menuName: "Overall Bills", component: <OverAllBills history={history} {...props} />, subMenu: [], path: '/PharmacyInvoice/Invoice', testId: 'tab_PhOverAll_list', Access: CheckAccessFunc("Standalone Pharmacy", "Billing Transaction", "Overall Bills", null, "Tab") },
    { id: 12.20, menuName: "Saved Bills", component: <SavedBills history={history} {...props} />, subMenu: [], path: '/PharmacyInvoice/SavedBills', testID: 'tab_PhSave_list', Access: CheckAccessFunc("Standalone Pharmacy", "Billing Transaction", "Saved Bills", null, "Tab") },
    { id: 12.30, menuName: "Receipt Bills", component: <ReceiptBills history={history} {...props} />, subMenu: [], path: '/PharmacyInvoice/ReceiptBills', testID: 'tab_PhRcpt_list', Access: CheckAccessFunc("Standalone Pharmacy", "Billing Transaction", "Receipt Bills", null, "Tab") },
    { id: 12.40, menuName: "Day End Denomination", component: <UserDayEndSalesReport from = "Pharmacy" history={history} {...props} />, subMenu: [], path: '/PharmacyInvoice/DayEndDenomination', testID: 'tab_PhRcpt_list', Access: CheckAccessFunc("Standalone Pharmacy", "Billing Transaction", "Day End Denomination", null, "Tab") }
  ]

  const Pharamacy_Inventory = [
    { id: 13.10, menuName: "Stock List", component: <PharmaStockList history={history} {...props} />, subMenu: [], path: '/PharmacyInventory/StockList', testID: 'Stock List', Access: CheckAccessFunc(pharmacy_type, "Inventory", "Stock List", null, "Tab") },
    { id: 13.20, menuName: "Drug List", component: <PharmaDrugList history={history} {...props} />, subMenu: [], path: '/PharmacyInventory/DrugList', testID: 'Drug List', Access: CheckAccessFunc(pharmacy_type, "Inventory", "Drug List", null, "Tab") },
    { id: 13.30, menuName: "Inactive Drug List", component: <PharmaInactiveDrug history={history} {...props} />, subMenu: [], path: '/PharmacyInventory/InactiveDrugList', testID: 'Inactive Drug List', Access: CheckAccessFunc(pharmacy_type, "Inventory", "Inactive Drug List", null, "Tab") },
    { id: 13.40, menuName: "Stock Correction", component: <PharmaStockCorrection history={history} {...props} />, subMenu: [], path: '/PharmacyInventory/StockCorrection', testID: 'Stock Correction', Access: CheckAccessFunc(pharmacy_type, "Inventory", "Stock Correction", null, "Tab") },
    { id: 18.10, menuName: "New Drug Approval", component: <PharmaNewDrugApproval history={history} {...props} />, subMenu: [], path: '/PharmacyInventory/NewDrugApproval', testID: 'tab_newDrug_appr', Access: CheckAccessFunc(pharmacy_type, "Inventory", "New Drug Approval", null, "Tab") },
  ]

  const Pharamacy_Purchase = [
    { id: 14.44, menuName: "Purchase Request", component: <PharmaPORequestList history={history} {...props} />, subMenu: [], path: '/PharmacyPurchase/PurchaseRequest', testID: 'tab_pur_req_list', Access: CheckAccessFunc(pharmacy_type, "Purchase", "Purchase Request", null, "Tab") },
    { id: 14.20, menuName: "Purchase Inward", component: <PharmaPurchaseOrder history={history} {...props} />, subMenu: [], path: '/PharmacyPurchase/PurchaseOrder', testID: 'tab_purord_list', Access: CheckAccessFunc(pharmacy_type, "Purchase", "Purchase Order", null, "Tab") },
    { id: 14.21, menuName: "Delivery Challan", component: <PharmaDeliveryChellan history={history} {...props} />, subMenu: [], path: '/PharmacyPurchase/DeliveryChallan', testID: 'tab_pur_dc_list', Access: CheckAccessFunc(pharmacy_type, "Purchase", "Delivery Challan", null, "Tab") },
    { id: 14.30, menuName: "Vendor Payment", component: <PharmaVendorPayment history={history} {...props} />, subMenu: [], path: '/PharmacyPurchase/VendorPayment', testID: 'tab_vndpay_list', Access: CheckAccessFunc(pharmacy_type, "Purchase", "Vendor Payment", null, "Tab") },
    { id: 14.40, menuName: "Vendor List", component: <PharmasVendorList history={history} {...props} />, subMenu: [], path: '/PharmacyPurchase/VendorList', testID: 'tab_vnd_list', Access: CheckAccessFunc(pharmacy_type, "Purchase", "Vendor List", null, "Tab") },
    { id: 14.41, menuName: "Purchase Return", component: <PharmaReturn allowAction={true} history={history} {...props} />, subMenu: [], path: '/PharmacyPurchase/PurchaseReturn', testID: 'tab_purreturn_list', Access: CheckAccessFunc(pharmacy_type, "Purchase", "Purchase Return", null, "Tab") },
    { id: 14.42, menuName: "Purchase Rejection", component: <PharmaRejectionHome history={history} {...props} />, subMenu: [], path: '/PharmacyPurchase/PurchaseRejection', testID: 'Purchase Rejection', Access: CheckAccessFunc(pharmacy_type, "Purchase", "Purchase Rejection", null, "Tab") },
    { id: 14.43, menuName: "Credit Note", component: <PharmaCreditNoteHome history={history} {...props} />, subMenu: [], path: '/PharmacyPurchase/CreditNote', testID: 'Credit Note', Access: CheckAccessFunc(pharmacy_type, "Purchase", "Credit Note", null, "Tab") },
    { id: 14.10, menuName: "Wanted List", component: <PharmaWantedList history={history} {...props} />, subMenu: [], path: '/PharmacyPurchase/WantedList', testID: 'tab_want_list', Access: CheckAccessFunc(pharmacy_type, "Purchase", "Wanted List", null, "Tab") },
  ]

  const Pharamacy_Report = {
    Sales_reports: [
      { id: 15.11, menuName: "Sales Overview", component: <SalesOverview history={history} {...props} />, subMenu: [], path: "/PharmacyReports/SalesOverview", testID: 'Sales Overview', Access: CheckAccessFunc(pharmacy_type, "Reports", "Sales", 'Sales Overview', "SubTab") },
      { id: 15.12, menuName: "Sales Detailed", component: <SalesDetailed history={history} {...props} />, subMenu: [], path: "/PharmacyReports/SalesDetailed", testID: 'Sales Detailed', Access: CheckAccessFunc(pharmacy_type, "Reports", "Sales", 'Sales Detailed', "SubTab") },
      { id: 15.14, menuName: "Sales Return", component: <SalesReturn history={history} {...props} />, subMenu: [], path: "/PharmacyReports/SalesReturn", testID: 'Sales Return', Access: CheckAccessFunc(pharmacy_type, "Reports", "Sales", 'Sales Return', "SubTab") },
      { id: 15.15, menuName: "Day End", component: <ReceiptBills isForReports={true} history={history} {...props} />, subMenu: [], path: "/PharmacyReports/SalesDayEnd", testID: 'Day End', Access: CheckAccessFunc(pharmacy_type, "Reports", "Sales", 'Day End', "SubTab") },
      { id: 15.16, menuName: "Sales Monthly", component: <SalesMonthly history={history} {...props} />, subMenu: [], path: "/PharmacyReports/SalesMonthly", testID: 'Sales Monthly', Access: CheckAccessFunc(pharmacy_type, "Reports", "Sales", 'Sales Monthly', "SubTab") },
      { id: 15.17, menuName: "Product Wise Sales", component: <ProductWiseSales history={history} {...props} />, subMenu: [], path: "/PharmacyReports/ProductWiseSales", testID: 'Product Wise Sales', Access: CheckAccessFunc(pharmacy_type, "Reports", "Sales", 'Product Wise Sales', "SubTab") },
      { id: 15.18, menuName: "HSN Wise", component: <HsnWise history={history} {...props} />, subMenu: [], path: "/PharmacyReports/HsnWise", testID: 'HSN Wise', Access: CheckAccessFunc(pharmacy_type, "Reports", "Sales", 'Hsn Wise', "SubTab") },
      { id: 15.19, menuName: "Sales Tax Wise", component: <TaxWise history={history} {...props} />, subMenu: [], path: "/PharmacyReports/TaxWise", testID: 'Sales Tax Wise', Access: CheckAccessFunc(pharmacy_type, "Reports", "Sales", 'Sales Tax Wise', "SubTab") },
      { id: 15.20, menuName: "Sales Return HSN Wise", component: <ReturnHSNWise history={history} {...props} />, subMenu: [], path: "/PharmacyReports/ReturnHSNWise", testID: 'Sales Return HSN Wise', Access: CheckAccessFunc(pharmacy_type, "Reports", "Sales", 'Sales Return HSN Wise', "SubTab") },
      { id: 15.21, menuName: "Sales Return Tax Wise", component: <ReturnTaxWise history={history} {...props} />, subMenu: [], path: "/PharmacyReports/ReturnTaxWise", testID: 'Sales Return Tax Wise', Access: CheckAccessFunc(pharmacy_type, "Reports", "Sales", 'Sales Return Tax Wise', "SubTab") },
      { id: 15.22, menuName: "Prescription Register", component: <PrescriptionRegister history={history} {...props} />, subMenu: [], path: "/PharmacyReports/PrescriptionRegister", testID: 'Prescription Register', Access: CheckAccessFunc(pharmacy_type, "Reports", "Sales", 'Prescription Register', "SubTab") },
      { id: 15.23, menuName: "Profit Analysis", component: <ProfitAnalysis history={history} {...props} />, subMenu: [], path: "/PharmacyReports/ProfitAnalysis", testID: 'Profit Analysis', Access: CheckAccessFunc(pharmacy_type, "Reports", "Sales", 'Profit Analysis', "SubTab") },
      { id: 15.24, menuName: "Sales Bill Tax Wise", component: <SalesBillTaxWise history={history} {...props} />, subMenu: [], path: "/PharmacyReports/billTaxWise", testID: 'Sales Bill Tax Wise', Access: CheckAccessFunc(pharmacy_type, "Reports", "Sales", 'Sales Bill Tax Wise', "SubTab") },
      { id: 15.25, menuName: "User Sales Performance", component: <UserPerformance history={history} {...props} />, subMenu: [], path: "/PharmacyReports/UserSalesPerformance", testID: 'User Performance', Access: CheckAccessFunc(pharmacy_type, "Reports", "Sales", 'User Sales Performance', "SubTab") },
    ],
    Purchase_Reports: [
      { id: 16.11, menuName: "Purchase Overview", component: <PurchaseOverview history={history} {...props} />, subMenu: [], path: "/PharmacyReports/PurchaseOverview", testID: 'Purchase Overview', Access: CheckAccessFunc(pharmacy_type, "Reports", "Purchase", 'Purchase Overview', "SubTab") },
      { id: 16.12, menuName: "Purchase Detailed", component: <PurchaseDetailed history={history} {...props} />, subMenu: [], path: "/PharmacyReports/PurchsaeDetailed", testID: 'Purchase Detailed', Access: CheckAccessFunc(pharmacy_type, "Reports", "Purchase", 'Purchase Detailed', "SubTab") },
      { id: 16.13, menuName: "Purchase GST", component: <PurchaseGST history={history} {...props} />, subMenu: [], path: "/PharmacyReports/PurchaseGST", testID: 'Purchase GST', Access: CheckAccessFunc(pharmacy_type, "Reports", "Purchase", 'Purchase GST', "SubTab") },
      { id: 16.17, menuName: "Purchase Return Overview", component: <PharmaReturn history={history} {...props} />, subMenu: [], path: '/PharmacyReports/PurchaseReturnOverview', testID: 'Purchase Return Overview', Access: CheckAccessFunc(pharmacy_type, "Reports", "Purchase", "Purchase Return Overview", "SubTab") },
      { id: 16.14, menuName: "Purchase Return Detailed", component: <PurchaseReturn history={history} {...props} />, subMenu: [], path: "/PharmacyReports/PurchaseReturnDetailed", testID: 'Purchase Return Detailed', Access: CheckAccessFunc(pharmacy_type, "Reports", "Purchase", 'Purchase Return Detailed', "SubTab") },
      { id: 16.15, menuName: "Purchase Monthly", component: <PurchaseMonthly history={history} {...props} />, subMenu: [], path: "/PharmacyReports/PurchaseMonthly", testID: 'Purchase Monthly', Access: CheckAccessFunc(pharmacy_type, "Reports", "Purchase", 'Purchase Monthly', "SubTab") },
      { id: 16.16, menuName: "Consolidated Sales", component: <ConsolidatedSales history={history} {...props} />, subMenu: [], path: "/PharmacyReports/ConsolidatedSales", testID: 'Consolidated Sales', Access: CheckAccessFunc(pharmacy_type, "Reports", "Purchase", 'Consolidated Sales', "SubTab") },
      { id: 16.18, menuName: "Purchase Vendor Wise", component: <VendorBill history={history} {...props} />, subMenu: [], path: "/PharmacyReports/VendorBill", testID: 'Purchase Vendor Wise', Access: CheckAccessFunc(pharmacy_type, "Reports", "Purchase", 'Purchase Vendor Wise', "SubTab") },
      { id: 16.19, menuName: "Purchase HSN Wise", component: <PurchaseHSNWise history={history} {...props} />, subMenu: [], path: "/PharmacyReports/PurchaseHSNWise", testID: 'Purchase HSN Wise', Access: CheckAccessFunc(pharmacy_type, "Reports", "Purchase", 'Purchase HSN Wise', "SubTab") },
      { id: 16.20, menuName: "Purchase Tax Wise", component: <PurchaseTaxWise history={history} {...props} />, subMenu: [], path: "/PharmacyReports/PurchaseTaxWise", testID: 'tab_vendor_bill', Access: CheckAccessFunc(pharmacy_type, "Reports", "Purchase", 'Purchase Tax Wise', "SubTab") },
      { id: 16.21, menuName: "Purchase Return HSN Wise", component: <PurchaseReturnHSNWise history={history} {...props} />, subMenu: [], path: "/PharmacyReports/PurchaseReturnHSNWise", testID: 'Purchase Tax Wise', Access: CheckAccessFunc(pharmacy_type, "Reports", "Purchase", 'Purchase Return HSN Wise', "SubTab") },
      { id: 16.22, menuName: "Purchase Return Vendor Wise", component: <PurchaseReturnVendorWise history={history} {...props} />, subMenu: [], path: "/PharmacyReports/ReturnVendorWise", testID: 'Purchase Return HSN Wise', Access: CheckAccessFunc(pharmacy_type, "Reports", "Purchase", 'Purchase Return Vendor Wise', "SubTab") },
      { id: 16.23, menuName: "Purchase Return Tax Wise", component: <PurchaseReturnTaxWise history={history} {...props} />, subMenu: [], path: "/PharmacyReports/PurchaseReturnTaxWise", testID: 'Purchase Return Vendor Wise', Access: CheckAccessFunc(pharmacy_type, "Reports", "Purchase", 'Purchase Return Tax Wise', "SubTab") },
      { id: 16.24, menuName: "Purchase Wise Enquiry", component: <PurchaseWiseEnquiry history={history} {...props} />, subMenu: [], path: "/PharmacyReports/PurchaseWiseEnquiry", testID: 'Purchase Return Tax Wise', Access: CheckAccessFunc(pharmacy_type, "Reports", "Purchase", 'Purchase Wise Enquiry', "SubTab") },
      { id: 16.25, menuName: "Purchase Rejection", component: <PharmaRejectionHome isReport={true} history={history} {...props} />, subMenu: [], path: "/PharmacyReports/PurchaseRejection", testID: 'Purchase Rejection', Access: CheckAccessFunc(pharmacy_type, "Reports", "Purchase", 'Purchase Rejection', "SubTab") },
      { id: 16.26, menuName: "Credit Note Report", component: <PharmaCreditNoteHome isReport={true} history={history} {...props} />, subMenu: [], path: "/PharmacyReports/CreditNoteReport", testID: 'Credit Note Report', Access: CheckAccessFunc(pharmacy_type, "Reports", "Purchase", 'Credit Note Report', "SubTab") },
      { id: 16.27, menuName: "Purchase Bill Tax Wise", component: <PurchaseBillTaxWise history={history} {...props} />, subMenu: [], path: "/PharmacyReports/PurchaseBillTaxWise", testID: 'Purchase Bill Tax Wise', Access: CheckAccessFunc(pharmacy_type, "Reports", "Purchase", 'Purchase Bill Tax Wise', "SubTab") },
    ],
    Inventory_Reports: [
      { id: 17.11, menuName: "Stock Overview", component: <StockOverview history={history} {...props} />, subMenu: [], path: "/PharmacyReports/StockOverview", testID: 'Stock Overview', Access: CheckAccessFunc(pharmacy_type, "Reports", "Inventory", 'Stock Overview', "SubTab") },
      { id: 17.12, menuName: "MIS Report", component: <MISReport history={history} {...props} />, subMenu: [], path: "/PharmacyReports/MISReport", testID: 'MIS Report', Access: CheckAccessFunc(pharmacy_type, "Reports", "Inventory", 'MIS Report', "SubTab") },
      { id: 17.13, menuName: "Expiry Stock", component: <ExpiryStock history={history} {...props} />, subMenu: [], path: "/PharmacyReports/ExpiryStock", testID: 'Expiry Stock', Access: CheckAccessFunc(pharmacy_type, "Reports", "Inventory", 'Expiry Stock', "SubTab") },
      { id: 17.14, menuName: "Low Stock Drugs", component: <LowStockDrug history={history} {...props} />, subMenu: [], path: "/PharmacyReports/LowStockDrugs", testID: 'Low Stock Drugs', Access: CheckAccessFunc(pharmacy_type, "Reports", "Inventory", 'Low Stock Drugs', "SubTab") },
      // { id: 17.15, menuName: "Slow Movement Drugs", component: <SlowMovementDrug history={history} {...props} />, subMenu: [], path: "/PharmacyReports/SlowMovementDrugs", testID: 'Slow Movement Drugs', Access: CheckAccessFunc(pharmacy_type, "Reports", "Inventory", 'Slow Movement Drugs', "SubTab") },
      { id: 17.16, menuName: "Drug Movement Report", component: <PharmaNoMovement history={history} {...props} />, subMenu: [], path: "/PharmacyReports/DrugMovementReport", testID: 'No Movement Drugs', Access: CheckAccessFunc(pharmacy_type, "Reports", "Inventory", 'Drug Movement Report', "SubTab") },
      { id: 17.17, menuName: "Stock Correction Log Report", component: <StockCorrectionLog history={history} {...props} />, subMenu: [], path: "/PharmacyReports/StockCorrectionLog", testID: 'Stock Correction Log Report', Access: CheckAccessFunc(pharmacy_type, "Reports", "Inventory", 'Stock Correction Log Report', "SubTab") },
      { id: 17.18, menuName: "Stock Value Detail", component: <StockValue history={history} {...props} />, subMenu: [], path: "/PharmacyReports/StockValueReport", testID: 'Stock Value Detail', Access: CheckAccessFunc(pharmacy_type, "Reports", "Inventory", 'Stock Value Detail', "SubTab") },
      { id: 17.19, menuName: "Stock And Profit Value", component: <DrugWiseStockValue history={history} {...props} />, subMenu: [], path: "/PharmacyReports/StockAndProfitValue", testID: 'Stock And Profit Value', Access: CheckAccessFunc(pharmacy_type, "Reports", "Inventory", 'Stock And Profit Value', "SubTab") },
    ],
    standalone_Report: [
      { id: 22.1, menuName: "Referral Doctor Report", component: <RefDoctorList history={history} {...props} />, subMenu: [], path: "/PharmacyReports/RefDoctorList", testID: 'tab_ref_docList', Access: CheckAccessFunc("Standalone Pharmacy", "Reports", "Pharmacy", 'Refferal Doctor', "SubTab") },
      { id: 22.2, menuName: "Patient Report", component: <PatientReport history={history} {...props} />, subMenu: [], path: "/PharmacyReports/PatientReport", testID: 'tab_pat_Rpt', Access: CheckAccessFunc("Standalone Pharmacy", "Reports", "Pharmacy", 'Patient', "SubTab") }
    ],
    GST_Reports: [
      { id: 23.1, menuName: "Sales GST", component: <SalesGSTDayWise history={history} {...props} />, subMenu: [], path: "/PharmacyReports/DayWiseSalesGST", testID: 'Sales GST', Access: CheckAccessFunc(pharmacy_type, "Reports", "GST", 'Sales GST', "SubTab") },
      { id: 23.2, menuName: "Return GST", component: <DayWiseReturnGST history={history} {...props} />, subMenu: [], path: "/PharmacyReports/DayWiseReturnGST", testID: 'Return GST', Access: CheckAccessFunc(pharmacy_type, "Reports", "GST", 'Return GST', "SubTab") },
      { id: 23.3, menuName: "HSN Sales GST", component: <HSNwiseSalesGST history={history} {...props} />, subMenu: [], path: "/PharmacyReports/HSNwiseSalesGST", testID: 'HSN Sales GST', Access: CheckAccessFunc(pharmacy_type, "Reports", "GST", 'HSN Sales GST', "SubTab") },
      { id: 23.4, menuName: "HSN Return GST", component: <HSNwiseReturnGST history={history} {...props} />, subMenu: [], path: "/PharmacyReports/HSNwiseReturnGST", testID: 'tab_hsnHSN Return GST', Access: CheckAccessFunc(pharmacy_type, "Reports", "GST", 'HSN Return GST', "SubTab") },
      { id: 23.5, menuName: "GST Summary", component: <DayWiseGSTSummary history={history} {...props} />, subMenu: [], path: "/PharmacyReports/DayWiseGSTSummary", testID: 'GST Summary', Access: CheckAccessFunc(pharmacy_type, "Reports", "GST", 'GST Summary', "SubTab") },
      { id: 23.6, menuName: "HSN GST Summary", component: <HSNWiseSummaryGST history={history} {...props} />, subMenu: [], path: "/PharmacyReports/HSNWiseSummaryGST", testID: 'HSN GST Summary', Access: CheckAccessFunc(pharmacy_type, "Reports", "GST", 'HSN GST Summary', "SubTab") },
    ]
  }

  const Pharamacy_Setting = {
    settings: UserData?.pharmacy_type === 'Inhouse Pharmacy' ? [
      { id: 18.46, menuName: "Print Configuration", component: <PharmaPrintConfig history={history} {...props} />, subMenu: [], path: '/PharmacySetting/PrintConfig', testID: 'tab_print', Access: CheckAccessFunc(pharmacy_type, "Settings", "Print Configuration", null, "Tab") },
      { id: 18.20, menuName: "Settings", component: <PharmaSettings history={history} {...props} />, subMenu: [], path: '/PharmacySetting/Settings', testID: 'tab_settings', Access: CheckAccessFunc(pharmacy_type, "Settings", "Settings", null, "Tab") },
      { id: 18.30, menuName: "Generic Name", component: <PharmaGenericName history={history} {...props} />, subMenu: [], path: '/PharmacySetting/GenericName', testID: 'tab_gen_name', Access: CheckAccessFunc(pharmacy_type, "Settings", "Generic Name", null, "Tab") },
      // {id:18.50,menuName:"User Configuration",component:<PharmaUserConfig history={history} {...props} />,subMenu:[],path:'/PharmacySetting/UserConfiguration', testID: 'tab_use_config'},
      { id: 18.40, menuName: "User Access", component: <Useraccess history={history} {...props} fromPharmacy={true} />, subMenu: [], path: '/PharmacySetting/UserConfiguration', testID: 'tab_use_config', Access: CheckAccessFunc(pharmacy_type, "Settings", "User Access", null, "Tab") },
      { id: 18.42, menuName: "Sub-Location", component: <SubLocation history={history} {...props} />, subMenu: [], path: '/PharmacySetting/SubLocation', testID: 'tab_sub_location', Access: CheckAccessFunc(pharmacy_type, "Settings", "Sub-Location", null, "Tab") },
      { id: 18.43, menuName: "Sub-Location Access", component: <PharmaUserAccess history={history} {...props} />, subMenu: [], path: '/PharmacySetting/PharmaUserAccess', testID: 'tab_ph_useraccess', Access: CheckAccessFunc(pharmacy_type, "Settings", "Sub-Location Access", null, "Tab") }
    ] : [
      { id: 18.46, menuName: "Print Configuration", component: <PharmaPrintConfig history={history} {...props} />, subMenu: [], path: '/PharmacySetting/PrintConfig', testID: 'tab_print', Access: CheckAccessFunc(pharmacy_type, "Settings", "Print Configuration", null, "Tab") },
      { id: 18.20, menuName: "Settings", component: <PharmaSettings history={history} {...props} />, subMenu: [], path: '/PharmacySetting/Settings', testID: 'tab_settings', Access: CheckAccessFunc(pharmacy_type, "Settings", "Settings", null, "Tab") },
      { id: 18.30, menuName: "Generic Name", component: <PharmaGenericName history={history} {...props} />, subMenu: [], path: '/PharmacySetting/GenericName', testID: 'tab_gen_name', Access: CheckAccessFunc(pharmacy_type, "Settings", "Generic Name", null, "Tab") },
      { id: 18.40, menuName: "User Access", component: <Useraccess history={history} {...props} fromPharmacy={true} />, subMenu: [], path: '/PharmacySetting/UserConfiguration', testID: 'tab_use_config', Access: CheckAccessFunc(pharmacy_type, "Settings", "User Access", null, "Tab") },
      { id: 18.41, menuName: "Referral Doctors", component: <PharmacyRefDoctor history={history} {...props} />, subMenu: [], path: '/PharmacySetting/ReferralDoctor', testID: 'tab_ref_doc', Access: CheckAccessFunc(pharmacy_type, "Settings", "Referral Doctors", null, "Tab") },
    ],
    userSettings: [
      { id: 18.44, menuName: "User Access", component: <PharmaUserSettings history={history} {...props} />, subMenu: [], path: '/PharmacySetting/PharmaUserSettings', testID: 'tab_ph_userset' }
    ],
    // Pharmacy new and edit user access
    user_new_setting: [
      { id: 18.45, menuName: 'User Access', component: <CommonUserAccess history={history} fromPharmacy={true} />, subMenu: [], path: "/PharmacySetting/UserConfiguration/New", testID: 'tab_use_config_new' },
    ],
  }


  const Pharamacy_directpo = [
    { id: 19.1, menuName: 'Direct PO', component: <PharmaDirectPo history={history} />, subMenu: [], path: "/PharmacyPurchase/PurchaseOrder/DirectPO", testID: 'tab_drt_po', Access: CheckAccessFunc(pharmacy_type, "Purchase", "Purchase Order", 'Direct Po', "SubTab") }
  ]

  const Pharamacy_dc_to_po_create = [
    { id: 20.1, menuName: 'Delivery Challan', component: <PharmaDirectPo history={history} fromDC={true} />, subMenu: [], path: "/PharmacyPurchase/PurchaseOrder/DirectPO", testID: 'tab_drt_po', Access: CheckAccessFunc(pharmacy_type, "Purchase", "Purchase Order", 'Direct Po', "SubTab") }
  ]

  const Pharamacy_dc_to_po = [
    { id: 19.1, menuName: 'Move to PO', component: <PharmaDCview history={history} />, subMenu: [], path: "/PharmacyPurchase/DeliveryChallan/MoveToPO", testID: 'tab_dc_to_po', Access: CheckAccessFunc(pharmacy_type, "Purchase", "Purchase Order", 'Direct Po', "SubTab") }
  ]

  const Pharamacy_StockList = [
    { id: 20.10, menuName: 'Add New Drug', component: <PharmaAddNewDrug history={history} />, subMenu: [], path: "/PharmacyInventory/StockList/AddNewDrug", testID: 'add_new_drug' },
    { id: 20.11, menuName: 'Request', component: <PharmaRequestList history={history} />, subMenu: [], path: "/PharmacyInventory/StockList/Request", testID: 'drug_rquest' }
  ]

  const Pharamacy_OrderList = [
    { id: 21.10, menuName: 'Order List', component: <PharmacyOrderedList history={history} />, subMenu: [], path: "/PharmacyPurchase/WantedList/OrderList", testID: 'tab_ord_list', Access: CheckAccessFunc(pharmacy_type, "Purchase", "Wanted List", 'Order List', "SubTab") },
    { id: 21.11, menuName: 'Confirm Order List', component: <PharmaConfirmOrderList history={history} />, subMenu: [], path: "/PharmacyPurchase/WantedList/ConfirmOrderList", testID: 'tab_cnfrm_list', Access: CheckAccessFunc(pharmacy_type, "Purchase", "Wanted List", 'Confirm Order List', "SubTab") }
  ]

  const SubLocation_Home = [
    { id: 22.10, menuName: 'Patient Billing', component: <SubLocbilling isForPatientBilling={true} history={history} getPharmaSavedBillCount={getPharmaSavedBillCount} />, subMenu: [], path: "/SubLocationBilling", testID: 'tab_subLoc_Billing', Access: CheckAccessFunc("sub_location", "Home", "Patient Billing", null, "Tab") },
    { id: 22.11, menuName: 'Generic Consumption', component: <SubLocGenericConsp history={history} />, subMenu: [], path: "/SubLocationBilling/GenericConsumption", testID: 'tab_subLoc_GenericConsump', Access: CheckAccessFunc("sub_location", "Home", "Generic Consumption", null, "Tab") }
  ]

  const SubLocation_Reports_Tab = [
    { id: 39.1, menuName: 'Stock List', component: <SubLocStockList history={history} {...props} />, subMenu: [], path: "/SubLocationReports", Access: CheckAccessFunc("sub_location", "Reports", "Stock List", null, "Tab") },
    { id: 39.2, menuName: 'Short Expiry', component: <SubLocShortExpiry history={history} {...props} />, subMenu: [], path: "/SubLocationReports/ShortExpiry", Access: CheckAccessFunc("sub_location", "Reports", "Short Expiry", null, "Tab") },
    { id: 39.3, menuName: 'Credit Report', component: <SubLocCreditReport history={history} {...props} />, subMenu: [], path: "/SubLocationReports/CreditReport", Access: CheckAccessFunc("sub_location", "Reports", "Credit Report", null, "Tab") },
    { id: 39.4, menuName: 'Consumption Report', component: <SubLocConsumptionReport history={history} {...props} />, subMenu: [], path: "/SubLocationReports/ConsumptionReport", Access: CheckAccessFunc("sub_location", "Reports", "Consumption Report", null, "Tab") },
    { id: 39.5, menuName: 'Expiry Stock', component: <SubLocationExpiryStock history={history} {...props} />, subMenu: [], path: "/SubLocationReports/ExpiryStock", Access: CheckAccessFunc("sub_location", "Reports", "Expiry Stock", null, "Tab") },
  ]
  const Pharmacy_Sub_Loc_Stock_Transfer = [
    { id: 24.10, menuName: 'Wanted List', component: <SubLocWantedList history={history} />, subMenu: [], path: '/SubLocationStockTransfer/WantedList', testID: 'tab_wanted_list', Access: CheckAccessFunc("sub_location", "Stock Transfer", "Wanted List", null, "Tab") },
    { id: 24.20, menuName: 'Inbound', component: <SubLocInbound history={history} getcountNotification={getcountNotification} />, subMenu: [], path: '/SubLocationStockTransfer/Inbound', testID: 'tab_inbound', Access: CheckAccessFunc("sub_location", "Stock Transfer", "Inbound", null, "Tab"), count: getCachevalue('subInbound') },
    { id: 24.30, menuName: 'Outbound', component: <SubLocOutbound history={history} getcountNotification={getcountNotification} />, subMenu: [], path: '/SubLocationStockTransfer/Outbound', testID: 'tab_outbound', Access: CheckAccessFunc("sub_location", "Stock Transfer", "Outbound", null, "Tab"), count: getCachevalue('subOutbound') },
    { id: 24.40, menuName: 'Return Request', component: <SubLocReturnRequest history={history} />, subMenu: [], path: '/SubLocationStockTransfer/ReturnRequest', testID: 'tab_rtn_req', Access: CheckAccessFunc("sub_location", "Stock Transfer", "Return Request", null, "Tab") },
    { id: 24.50, menuName: 'Stock Transfer Report', component: <SubLocStockTfrReport history={history} />, subMenu: [], path: '/SubLocationStockTransfer/StockTransferReport', testID: 'tab_stk_tfr_rpt', Access: CheckAccessFunc("sub_location", "Stock Transfer", "Stock Transfer Report", null, "Tab") },
  ]

  const Pharmacy_Stock_Transfer = [
    { id: 25.10, menuName: 'Inbound', component: <PharmaInbound history={history} getcountNotification={getcountNotification}/>, subMenu: [], path: '/PharmacyStockTransfer/Inbound', testID: 'tab_inbound', Access: UserData?.branch_to_branch_stock_transfer ? CheckAccessFunc("Pharmacy", "Stock Transfer", "Sub Location Transfer", "Inbound", "SubTab") : CheckAccessFunc("Pharmacy", "Stock Transfer", "Inbound", null, "Tab"), count: getCachevalue('subInbound') },
    { id: 25.20, menuName: 'Outbound', component: <PharmaOutbound history={history}  getcountNotification={getcountNotification}/>, subMenu: [], path: '/PharmacyStockTransfer/Outbound', testID: 'tab_outbound', Access: UserData?.branch_to_branch_stock_transfer ? CheckAccessFunc("Pharmacy", "Stock Transfer", "Sub Location Transfer", "Outbound", "SubTab") : CheckAccessFunc("Pharmacy", "Stock Transfer", "Outbound", null, "Tab"), count: getCachevalue('subOutbound') },
    { id: 25.30, menuName: 'Stock Transfer Report', component: <PharmaStockTfrReport history={history} />, subMenu: [], path: '/PharmacyStockTransfer/StockTransferReport', testID: 'tab_stk_tfr_rpt', Access: UserData?.branch_to_branch_stock_transfer ? CheckAccessFunc("Pharmacy", "Stock Transfer", "Sub Location Transfer", "Stock Transfer Report", "SubTab") : CheckAccessFunc("Pharmacy", "Stock Transfer", "Stock Transfer Report", null, "Tab") },
    { id: 25.31, menuName: 'Stock List', component: <PharmaSubStockList history={history} />, subMenu: [], path: '/PharmacyStockTransfer/PharmaSubStockList', testID: 'tab_stk_tfr_stock', Access: UserData?.branch_to_branch_stock_transfer ? CheckAccessFunc("Pharmacy", "Stock Transfer", "Sub Location Transfer", "Stock List", "SubTab") : CheckAccessFunc("Pharmacy", "Stock Transfer", "Stock List", null, "Tab") },
    { id: 25.32, menuName: 'Drug Wise Stock Transfer Report', component: <PharmaDrugWiseSTReport history={history} />, subMenu: [], path: '/PharmacyStockTransfer/PharmaDrugWiseStockTransfer', testID: 'tab_drug_wise_stk_tfr_stock', Access: UserData?.branch_to_branch_stock_transfer ? CheckAccessFunc("Pharmacy", "Stock Transfer", "Sub Location Transfer", "Drug Wise Stock Transfer Report", "SubTab") : CheckAccessFunc("Pharmacy", "Stock Transfer", "Drug Wise Stock Transfer Report", null, "Tab") },
  ]

  const HospitalConfigList = [
    { id: 12.2, menuName: 'Specialization', component: <Specialization />, subMenu: [], path: "/Settings/OPService/Specialization", Access: CheckAccessFunc("front_office", "Settings", "Hospital Configuration", "Specialization", "SubTab") },
    { id: 12.3, menuName: 'Doctor List', component: <DoctorConfig history={history} />, subMenu: [], path: "/Settings/HospitalConfig/DoctorList", Access: CheckAccessFunc("front_office", "Settings", "Hospital Configuration", "Doctor List", "SubTab") },
    { id: 12.4, menuName: 'Slot Configuration', component: <SlotConfig history={history} />, subMenu: [], path: "/Settings/HospitalConfig/SlotConfig", Access: CheckAccessFunc("front_office", "Settings", "Hospital Configuration", "Slot Configuration", "SubTab") },
    { id: 12.5, menuName: 'Token Prefix', component: <TokenPrefix />, subMenu: [], path: "/Settings/OPService/TokenPrefix", Access: CheckAccessFunc("front_office", "Settings", "Hospital Configuration", "Token Prefix", "SubTab") },
    { id: 12.6, menuName: 'Appointment Type', component: <AppointmentConfig />, subMenu: [], path: "/Settings/OPService/AppointmentType", Access: CheckAccessFunc("front_office", "Settings", "Hospital Configuration", "Appointment Type", "SubTab") },
    // { id: 12.7, menuName: 'Bill Prefix', component: <BillPrefix />, subMenu: [], path: "/Settings/OPService/BillPrefix" },
    { id: 12.7, menuName: 'Tag', component: <TagConfig />, subMenu: [], path: "/Settings/OPService/TagConfig", Access: CheckAccessFunc("front_office", "Settings", "Hospital Configuration", "Tag", "SubTab") },
    { id: 12.8, menuName: 'Custom Configuration', component: <CustomConfig />, subMenu: [], path: "/Settings/CustomConfig", Access: CheckAccessFunc("front_office", "Settings", "Hospital Configuration", "Custom Configuration", "SubTab") },
    { id: 12.9, menuName: 'Expense Category', component: <ExpenseCategory history={history} />, subMenu: [], path: "/Settings/ExpenseConfig/ExpenseCategory", Access: CheckAccessFunc("front_office", "Settings", "Hospital Configuration", "Expense Category", "SubTab") },
    { id: 12.1, menuName: 'Hospital Configuration', component: <AutomaticBills history={history} />, subMenu: [], path: "/Settings/HospitalConfig/HospitalConfig", Access: CheckAccessFunc("front_office", "Settings", "Hospital Configuration", "Hospital Configuration", "SubTab") },
    { id: 13.0, menuName: 'QMS Configuration', component: <QMSConfig history={history} />, subMenu: [], path: "/Settings/HospitalConfig/QMSConfig", Access: CheckAccessFunc("front_office", "Settings", "Hospital Configuration", "QMS Configuration", "SubTab") }
  ].filter((list) => ((list?.id !== 13.0 && list?.id !== 12.4 && list?.id !== 12.5) || (qmsConfig && (UserData?.token_gen_type === "Clinic" ? list?.id !== 12.5 : true) )))

  //Lab Configuration ====> Router Path Change As FO Router Path

  const Lab_Configuration = [
    { id: 12.3, menuName: 'Consumable Mapping', component: <ConsumableMap history={history} />, subMenu: [], path: "/SettingsHome/ConsumableConfiguration", Access: CheckAccessFunc("Laboratory", "Settings", "Lab Configuration", "Consumable Mapping", "SubTab") },
    { id: 12.4, menuName: 'Patient Demographics', component: <PatientDemographics history={history} />, subMenu: [], path: "/SettingsHome/PatientDemographics", Access: CheckAccessFunc("Laboratory", "Settings", "Lab Configuration", "Patient Demographics", "SubTab") },
    { id: 12.5, menuName: 'Referred Doctor', component: <DoctorDemographics history={history} />, subMenu: [], path: "/SettingsHome/ReferredDoctor", Access: CheckAccessFunc("Laboratory", "Settings", "Lab Configuration", "Referred Doctors", "SubTab") },
    { id: 12.6, menuName: 'Outsource Configuration', component: <OutsourceConfig history={history} />, subMenu: [], path: "/SettingsHome/OutsourceConfiguration", Access: CheckAccessFunc("Laboratory", "Settings", "Lab Configuration", "Outsource Configuration", "SubTab") },
    { id: 12.7, menuName: 'Outsource Mapping', component: <OutsourceMapping history={history} />, subMenu: [], path: "/SettingsHome/OutsourceMapping", Access: CheckAccessFunc("Laboratory", "Settings", "Lab Configuration", "Outsource Mapping", "SubTab") },
    { id: 12.9, menuName: 'Lab E-Signature Configuration', component: <SignatureConfig history={history} />, subMenu: [], path: "/SettingsHome/SignatureConfiguration", Access: CheckAccessFunc("Laboratory", "Settings", "Lab Configuration", "Lab E-Signature Configuration", "SubTab") },
    // { id: 12.9, menuName: 'Culture Test Configuration', component: <CultureTestConfig history={history} />, subMenu: [], path: "/Config/CultureTestConfiguration" },
  ]

  if (UserData?.module_name === 'Laboratory') {
    Lab_Configuration.unshift(
      // { id: 12.1, menuName: 'Amount Configuration', component: <AmountConfig history={history} />, subMenu: [], path: "/Config/AmountConfiguration" },
      { id: 12.2, menuName: 'Result Configuration', component: <RangeBasedConfig history={history} />, subMenu: [], path: "/SettingsHome/ResultConfiguration", Access: CheckAccessFunc("Laboratory", "Settings", "Lab Configuration", "Result Configuration", "SubTab") },
      { id: 12.8, menuName: 'Package Configuration', component: <PackageConfig history={history} />, subMenu: [], path: "/SettingsHome/PackageConfiguration", Access: CheckAccessFunc("Laboratory", "Settings", "Lab Configuration", "Package Configuration", "SubTab") },
      { id: 12.11, menuName: 'Template Configuration', component: <TemplateConfig history={history} />, subMenu: [], path: "/SettingsHome/TemplateConfiguration", Access: CheckAccessFunc("Laboratory", "Settings", "Lab Configuration", "Template Configuration", "SubTab") },
      { id: 12.12, menuName: 'Doctor Share Configuration', component: <LabDocShareConfig history={history} />, subMenu: [], path: "/SettingsHome/DoctorConfiguration", Access: CheckAccessFunc("Laboratory", "Settings", "Lab Configuration", "Doctor Share Configuration", "SubTab") },
    )
  } else if (UserData?.module_name === 'Radiology') {
    Lab_Configuration.unshift(
      { id: 12.1, menuName: 'Test Configuration', component: <TestConfig history={history} />, subMenu: [], path: "/SettingsHome/TestConfiguration" },
      { id: 12.2, menuName: 'Test Group', component: <TestGroup history={history} />, subMenu: [], path: "/SettingsHome/GroupConfiguration" },
    )
  }

  const OT_Patient_Tabs = [
    { id: 31.1, menuName: 'Demographics', component: <DocDemographics fromOT={true} history={history} />, subMenu: [], path: "/OTHome/Demographics" },
    { id: 31.2, menuName: 'Surgeon Details', component: <SurgeonDetails fromOT={true} history={history} />, subMenu: [], path: "/OTHome/SurgeonDetails" },
    { id: 31.3, menuName: 'Nurse Note', component: <NurseNotes fromOT={true} history={history} />, subMenu: [], path: "/OTHome/NurseNotes" },
    {
      id: 31.4, menuName: 'Reports', component: <DoctorVisitReport fromOT={true} history={history} />, subMenu: [
        { id: 31.41, menuName: 'Doctor Visit Report', component: <DoctorVisitReport fromOT={true} history={history} />, path: "/OTHome/Reports" },
        { id: 31.42, menuName: 'Test Report', component: <TestReports fromOT={true} history={history} />, path: "/OTHome/Reports" },
        { id: 31.43, menuName: 'Print Order', component: <DocPrintOrder fromOT={true} history={history} />, path: "/OTHome/Reports" },
        { id: 31.44, menuName: 'Discharge Summary Report', component: <DocDischargeSummaryReport fromOT={true} history={history} />, path: "/OTHome/Reports" },
      ], path: "/OTHome/Reports"
    },
    { id: 31.5, menuName: 'Surgery Notes', component: <SurgeryNotes fromOT={true} history={history} />, subMenu: [], path: "/OTHome/SurgeryNotes" },
    { id: 31.6, menuName: 'OT Billing', component: <OTBilling fromOT={true} history={history} />, subMenu: [], path: "/OTHome/Billing" }
  ]

  const OT_Stocks = [
    { id: 34.1, menuName: 'OT Stock List', component: <OTStockList history={history} />, subMenu: [], path: "/OTStocks" },
    { id: 34.2, menuName: 'OT Stock Usage Transaction', component: <OTStockTrans history={history} />, subMenu: [], path: "/OTStocks/UsageTransaction" }
  ]

  const OT_Inventory = isOPT || UserData?.is_opt ?
    [
      { id: 35.1, menuName: 'Intraocular Drug', component: <IOLDrugs history={history} />, subMenu: [], path: "/OTInventory" },
      { id: 35.2, menuName: 'OT Drugs', component: <OTDrugs history={history} />, subMenu: [], path: "/OTInventory/OTDrugs" }
    ] :
    [
      { id: 35.2, menuName: 'OT Drugs', component: <OTDrugs history={history} />, subMenu: [], path: "/OTInventory/OTDrugs" }
    ]

  const OT_Stock_Transfer = [
    { id: 36.1, menuName: 'Inbound', component: <SubLocInbound history={history} getcountNotification={getcountNotification} fromOT={true} />, subMenu: [], path: "/OTStockTransfer/Inbound", count: getCachevalue('subInbound') },
    { id: 36.2, menuName: 'Outbound', component: <SubLocOutbound history={history} getcountNotification={getcountNotification} fromOT={true} />, subMenu: [], path: "/OTStockTransfer/Outbound", count: getCachevalue('subOutbound') },
    { id: 36.3, menuName: 'Return Request', component: <SubLocReturnRequest history={history} fromOT={true} />, subMenu: [], path: "/OTStockTransfer/ReturnRequest" },
    { id: 36.4, menuName: 'Stock Transfer Report', component: <SubLocStockTfrReport history={history} fromOT={true} />, subMenu: [], path: "/OTStockTransfer/OTTransferReport" }
  ]

  const OT_Report = [
    { id: 37.1, menuName: 'Surgery Report', component: <OTSurgeryReport history={history} />, subMenu: [], path: "/OTReports" },
    { id: 37.3, menuName: 'TAT Report', component: <OTTatReport history={history} />, subMenu: [], path: "/OTReports/TATReport" },
    { id: 37.2, menuName: 'Cancelled Report', component: <OTCancelledReport history={history} />, subMenu: [], path: "/OTReports/OTCancelledReport" },
  ]

  const OT_Settings = [
    { id: 38.1, menuName: 'Operation Theatre', component: <OTConfiguration history={history} />, subMenu: [], path: "/OTSettings" },
  ]

  const Doc_Nutrition_Chart = [
    { id: 26.1, menuName: 'Nutrition', component: <DocNutritionChart history={history} />, subMenu: [], path: "/DoctorNutritionChart" },
  ]

  const Doctor_Demographics = [
    { id: 28.1, menuName: 'Demographics', component: <DocDemographics history={history} />, subMenu: [], path: "/DoctorHome/Doctor/Demographics" },
  ]

  const Doc_Registry_Chart = [
    { id: 29.1, menuName: 'Registry', component: <DocRegistry history={history} />, subMenu: [], path: "/DoctorRegistry" },
  ]

  const Doctor_Settings = [
    { id: 30.1, menuName: 'Layout Configuration', component: <DocLayoutConfig history={history} />, subMenu: [], path: "/DoctorSettings" },
    { id: 30.2, menuName: 'Doctor Notes Configuration', component: <DocNotesConfig history={history} />, subMenu: [], path: "/DoctorSettings/DoctorNotes" },
  ]

  const Doctor_Report = [
    { id: 80.1, menuName: 'Optometrist Report', component: <OptometristList history={history} />, subMenu: [], path: "/DoctorReports" },
    { id: 80.2, menuName: 'OPT TAT Report', component: <OpthotatReport history={history} />, subMenu: [], path: "/DoctorReports/OptTatReport" },
  ]

  const Opthometry_Settings = [
    { id: 39.1, menuName: 'Layout Configuration', component: <DocLayoutConfig history={history} />, subMenu: [], path: "/DoctorSettings" },
  ]

  const Branch_Settings = [
    { id: 40.1, menuName: 'Custom Configuration', component: <BranchCustomConfig history={history} />, subMenu: [], path: "/AdminSettings" },
    { id: 40.2, menuName: 'Pharmacy Purchase Cofiguration', component: <PharmacyStockConfig history={history} />, subMenu: [], path: "/AdminSettings/PharmacyStockConfig" },
  ]

  const Opthometry_Report = [
    { id: 81.1, menuName: 'Optometrist Report', component: <OptometristList history={history} />, subMenu: [], path: "/OptometryReports" },
  ]

  let Doctor_Tabs = isOPT ? [
    { id: 27.1, menuName: 'Doctor Notes', component: <DocNotesHome history={history} />, subMenu: [], path: "/DoctorHome/DoctorNotes" },
    {
      id: 27.9, menuName: 'Forms & Certificates', component: <DocRefferalNotes history={history} />, subMenu: [
        { id: 27.91, menuName: 'Referral Notes', component: <DocRefferalNotes history={history} />, path: "/DoctorHome/FormsCertificates/ReferralNotes" },
        { id: 27.92, menuName: 'Medical Certificate', component: <DocMedicalCertific history={history} />, path: "/DoctorHome/FormsCertificates/MedicalCertificate" },
        { id: 27.93, menuName: 'Fitness Certificate', component: <DocFitnessCertific history={history} />, path: "/DoctorHome/FormsCertificates/FitnessCertificate" },
        { id: 27.94, menuName: 'Death Certificate', component: <DocDeathCertific history={history} />, path: "/DoctorHome/FormsCertificates/DeathCertificate" },
      ], path: "/DoctorHome/FormsCertificates/ReferralNotes"
    },
    { id: 27.2, menuName: 'Patient Documents', component: <DocPatientDocument history={history} />, subMenu: [], path: "/DoctorHome/PatientDocuments" },
    {
      id: 27.3, menuName: 'History', component: <DocMedicalHistory history={history} />, subMenu: [
        { id: 27.31, menuName: 'Medical History', component: <DocMedicalHistory history={history} />, path: "/DoctorHome/History/MedicalHistory" },
        { id: 27.32, menuName: 'Hospitalization History', component: <DocHospitalizationHistory history={history} />, path: "/DoctorHome/History/HospitalizationHistory" },
        { id: 27.33, menuName: 'Social History', component: <DocSocialHistory history={history} />, path: "/DoctorHome/History/SocialHistory" },
        { id: 27.34, menuName: 'Family History', component: <DocFamilyHistory history={history} />, path: "/DoctorHome/History/FamilyHistory" },
      ], path: "/DoctorHome/History/MedicalHistory"
    },
    {
      id: 27.4, menuName: 'Reports', component: <DocDoctorVisitReport history={history} />, subMenu: [
        { id: 27.43, menuName: 'Print Order', component: <DocPrintOrder history={history} />, path: "/DoctorHome/Reports/PrintOrder" },
        { id: 27.42, menuName: 'Investigation Report', component: <DocTestReport history={history} />, path: "/DoctorHome/Reports/TestReport" },
        { id: 27.41, menuName: 'Doctor Visit Report', component: <DocDoctorVisitReport history={history} />, path: "/DoctorHome/Reports/DoctorVisit" },
        { id: 27.44, menuName: 'Discharge Summary Report', component: <DocDischargeSummaryReport history={history} />, path: "/DoctorHome/Reports/DischargeSummary" },
      ], path: "/DoctorHome/Reports/DoctorVisit"
    },
    {
      id: 27.5, menuName: 'Collaborative Summary', component: <DocCollabDoctorNotes history={history} />, subMenu: [
        { id: 27.51, menuName: 'Doctor Notes', component: <DocCollabDoctorNotes history={history} />, path: "/DoctorHome/CollaborativeSummary/DoctorNotes" },
        { id: 27.52, menuName: 'History', component: <DocCollabHistory history={history} />, path: "/DoctorHome/CollaborativeSummary/History" },
        { id: 27.53, menuName: 'Test Report', component: <DocCollabTestReport history={history} />, path: "/DoctorHome/CollaborativeSummary/TestReport" },
        // { id: 27.54, menuName: 'Discharge Summary', component: <DocCollabDisSummary history={history} />, path: "/DoctorHome/CollaborativeSummary/DischargeSummary" },
        // { id: 27.55, menuName: 'Surgery Notes', component: <DocCollabSurNotes history={history} />, path: "/DoctorHome/CollaborativeSummary/SurgeryNotes" },
      ], path: "/DoctorHome/CollaborativeSummary/DoctorNotes"
    },
    { id: 27.6, menuName: 'Demographics', component: <DocDemographics history={history} />, subMenu: [], path: "/DoctorHome/Demographics" },
  ] : isOpthometry ? history?.location?.state?.fromView ? [
    {
      id: 27.3, menuName: 'Reports', component: <DocDoctorVisitReport history={history} />, subMenu: [
        { id: 27.31, menuName: 'Doctor Visit Report', component: <DocDoctorVisitReport history={history} />, path: "/DoctorHome/Reports/DoctorVisit" },
        { id: 27.32, menuName: 'Print Order', component: <DocPrintOrder history={history} />, path: "/DoctorHome/Reports/PrintOrder" },
      ], path: "/DoctorHome/Reports/DoctorVisit"
    }
  ] : [
    { id: 27.1, menuName: 'Doctor Notes', component: <DocNotesHome history={history} />, subMenu: [], path: "/DoctorHome/DoctorNotes" },
    { id: 27.2, menuName: 'Patient Documents', component: <DocPatientDocument history={history} />, subMenu: [], path: "/DoctorHome/PatientDocuments" },
    {
      id: 27.3, menuName: 'Reports', component: <DocDoctorVisitReport history={history} />, subMenu: [
        { id: 27.31, menuName: 'Doctor Visit Report', component: <DocDoctorVisitReport history={history} />, path: "/DoctorHome/Reports/DoctorVisit" },
        { id: 27.32, menuName: 'Print Order', component: <DocPrintOrder history={history} />, path: "/DoctorHome/Reports/PrintOrder" },
      ], path: "/DoctorHome/Reports/DoctorVisit"
    },
  ] : [
    { id: 27.1, menuName: 'Doctor Notes', component: <OtherDoctorNotes history={history} />, subMenu: [], path: "/DoctorHome/DoctorNotes" },
    {
      id: 27.15, menuName: 'Forms & Certificates', component: <DocRefferalNotesCommon history={history} />, subMenu: [
        { id: 27.151, menuName: 'Referral Notes', component: <DocRefferalNotesCommon history={history} />, path: "/DoctorHome/FormsCertificates/DocRefferalNotesCommon" },
        { id: 27.152, menuName: 'Medical Certificate', component: <DocMedicalCertific history={history} />, path: "/DoctorHome/FormsCertificates/MedicalCertificate" },
        { id: 27.153, menuName: 'Fitness Certificate', component: <DocFitnessCertific history={history} />, path: "/DoctorHome/FormsCertificates/FitnessCertificate" },
        { id: 27.154, menuName: 'Death Certificate', component: <DocDeathCertific history={history} />, path: "/DoctorHome/FormsCertificates/DeathCertificate" },
      ], path: "/DoctorHome/FormsCertificates/DocRefferalNotesCommon"
    },
    { id: 27.2, menuName: 'Patient Documents', component: <DocPatientDocument history={history} />, subMenu: [], path: "/DoctorHome/PatientDocuments" },
    {
      id: 27.3, menuName: 'History', component: <DocMedicalHistory history={history} />, subMenu: [
        { id: 27.31, menuName: 'Medical History', component: <DocMedicalHistory history={history} />, path: "/DoctorHome/History/MedicalHistory" },
        { id: 27.32, menuName: 'Hospitalization History', component: <DocHospitalizationHistory history={history} />, path: "/DoctorHome/History/HospitalizationHistory" },
        { id: 27.33, menuName: 'Social History', component: <DocSocialHistory history={history} />, path: "/DoctorHome/History/SocialHistory" },
        { id: 27.34, menuName: 'Family History', component: <DocFamilyHistory history={history} />, path: "/DoctorHome/History/FamilyHistory" },
        UserData?.service_type === 'PD' ? { id: 27.35, menuName: 'PD History', component: <DocPidiatricHistory history={history} />, path: "/DoctorHome/History/PidiatricHistory" } :
          UserData?.service_type === 'OBGYN' ? { id: 27.36, menuName: 'OBGYN History', component: <DocOBGYNHistory history={history} />, path: "/DoctorHome/History/obstetricianHistory" } :
            UserData?.service_type !== 'ENT' && { id: 27.35, menuName: 'Diabetic History', component: <DocDiabiaticHistory history={history} />, path: "/DoctorHome/History/DiabeticHistory" }
      ], path: "/DoctorHome/History/MedicalHistory"
    },
    {
      id: 27.4, menuName: 'Reports', component: <DocDoctorVisitReport history={history} />, subMenu: [
        { id: 27.41, menuName: 'Doctor Visit Report', component: <DocDoctorVisitReport history={history} />, path: "/DoctorHome/Reports/DoctorVisit" },
        { id: 27.42, menuName: 'Test Report', component: <DocTestReport history={history} />, path: "/DoctorHome/Reports/TestReport" },
        { id: 27.43, menuName: 'Print Order', component: <DocPrintOrder history={history} />, path: "/DoctorHome/Reports/PrintOrder" },
        { id: 27.44, menuName: 'Discharge Summary Report', component: <DocDischargeSummaryReport history={history} />, path: "/DoctorHome/Reports/DischargeSummary" },
      ], path: "/DoctorHome/Reports/DoctorVisit"
    },
    UserData?.service_type === 'PD' ? {
      id: 27.12, menuName: 'Developmental Assessment', component: <DocDevelopmentAsses history={history} />, subMenu: [
        { id: 27.121, menuName: 'Developmental Assessment', component: <DocDevelopmentAsses />, path: "/DoctorHome/DocDevelopmentAsses" },
        // { id: 27.122, menuName: 'Paediatrician', component: <DocDevelopmentpaediatric />, path: "/DoctorHome/DocDevelopmentpaediatric" },
      ], path: "/DoctorHome/DocDevelopmentAsses"
    } :
      UserData?.service_type === 'OBGYN' ? {
        id: 27.11, menuName: 'Charts', component: <OvulationChart history={history} />, subMenu: [
          { id: 27.111, menuName: 'Ovulation', component: <OvulationChart />, path: "/DoctorHome/Charts/Ovulation" },
          { id: 27.112, menuName: 'Antenatal', component: <AntenatalChart />, path: "/DoctorHome/Charts/Antenatal" },
        ], path: "/DoctorHome/Charts/Ovulation"
      } : UserData?.service_type === 'ENT' ? null : {
        id: 27.5, menuName: 'Charts', component: <DocHbac history={history} />, subMenu: [
          { id: 27.51, menuName: 'HBA1C', component: <DocHbac />, path: "/DoctorHome/Charts/Hbac" },
          { id: 27.52, menuName: 'Glucose Log', component: <DocGlucouselog />, path: "/DoctorHome/Charts/GlucoseLog" },
        ], path: "/DoctorHome/Charts/Hbac"
      },
    UserData?.service_type === 'PD' ? {
      id: 27.13, menuName: 'Immunization', component: <DocImmunization history={history} />, subMenu: [], path: "/DoctorHome/DocImmunization"
    } : UserData?.service_type === 'ENT' ? null : {
      id: 27.6, menuName: 'Foot Clinic', component: <DocBranchicalIndex history={history} />, subMenu: [
        { id: 27.61, menuName: 'Ankle Brachial Index', component: <DocBranchicalIndex />, path: "/DoctorHome/FootClinic/Branchical" },
        { id: 27.62, menuName: 'Biothesiometry Log', component: <DocBiothesometry />, path: "/DoctorHome/FootClinic/Biothesiometry" },
      ], path: "/DoctorHome/FootClinic/Branchical"
    },
    UserData?.service_type === 'ENT' ? null : {
      id: 27.14, menuName: 'Growth Chart', component: <DocGrowthChart history={history} />, subMenu: [], path: "/DoctorHome/DocGrowthChart"
    },
    {
      id: 27.7, menuName: 'Collaborative Summary', component: <DocCollabDoctorNotes history={history} />, subMenu: [
        { id: 27.71, menuName: 'Doctor Notes', component: <DocCollabDoctorNotes />, path: "/DoctorHome/CollaborativeSummary/DoctorNotes" },
        { id: 27.72, menuName: 'History', component: <DocCollabHistory />, path: "/DoctorHome/CollaborativeSummary/History" },
        { id: 27.73, menuName: 'Test Report', component: <DocCollabTestReport />, path: "/DoctorHome/CollaborativeSummary/TestReport" },
        // { id: 27.94, menuName: 'Discharge Summary', component: <DocCollabDisSummary />, path: "/DoctorHome/CollaborativeSummary/DischargeSummary" },
        // { id: 27.95, menuName: 'Surgery Notes', component: <DocCollabSurNotes />, path: "/DoctorHome/CollaborativeSummary/SurgeryNotes" },
      ], path: "/DoctorHome/CollaborativeSummary/DoctorNotes"
    },
    { id: 27.8, menuName: 'Demographics', component: <DocDemographics history={history} />, subMenu: [], path: "/DoctorHome/Demographics" },
  ].filter(Boolean)
  let New_Doctor_Tabs = []
  let New_sub = []
  if (UserData?.service_type === 'GP') {
    New_Doctor_Tabs = Doctor_Tabs.filter(item => item?.menuName !== 'Foot Clinic' && item?.menuName !== 'Charts' && item?.menuName !== 'Growth Chart');
    New_sub = New_Doctor_Tabs.map(item => {
      if (item?.menuName === 'History') {
        item.subMenu = item?.subMenu?.filter(subItem => subItem?.menuName !== 'Diabetic History');
      }
      return item;
    });
    Doctor_Tabs = New_sub;
  } else if (UserData?.service_type === 'DB') {
    New_Doctor_Tabs = Doctor_Tabs.filter(item => item?.menuName !== 'Growth Chart');
    Doctor_Tabs = New_Doctor_Tabs
  } else if (UserData?.service_type === 'OBGYN') {
    New_Doctor_Tabs = Doctor_Tabs.filter(item => item?.menuName !== 'Foot Clinic' && item?.menuName !== 'Growth Chart');
    New_sub = New_Doctor_Tabs.map(item => {
      if (item?.menuName === 'History') {
        item.subMenu = item?.subMenu?.filter(subItem => subItem?.menuName !== 'Diabetic History');
      }
      return item;
    });
    Doctor_Tabs = New_sub;

  }

  var PatientData = getCachevalue("DoctorPatientData") ? JSON.parse(getCachevalue("DoctorPatientData")) : {}
  if (PatientData?.is_ip_patient) {
    Doctor_Tabs.push({ id: 27.99, menuName: 'Discharge Summary', component: <DischargeSummary history={history} isFromDoctor={true} />, subMenu: [], path: "/DoctorHome/DischargeSummary" })
  }

  const Demographics_Update = [
    { id: 32.1, menuName: 'Demographics', component: <DocDemographics history={history} />, subMenu: [], path: "/DoctorHome/Demographics/Update" },
    {
      id: 32.2, menuName: 'Reports', component: <DocDoctorVisitReport history={history} />, subMenu: [
        { id: 32.21, menuName: 'Doctor Visit Report', component: <DocDoctorVisitReport history={history} />, path: "/Reports/DoctorVisitReport" },
        { id: 32.22, menuName: 'Test Report', component: <DocTestReport history={history} />, path: "/DoctorHome/Reports/TestReport" },
        { id: 32.23, menuName: 'Print Order', component: <DocPrintOrder history={history} />, path: "/DoctorHome/Reports/PrintOrder" },
        { id: 32.24, menuName: 'Discharge Summary Report', component: <DocDischargeSummaryReport history={history} />, path: "/DoctorHome/Reports/DischargeSummary" },
      ], path: "/Reports/DoctorVisitReport"
    }
  ]

  const Pharma_PO_Request = [
    { id: 33.1, menuName: 'Purchase Request', component: <PharmaPORequest history={history} />, subMenu: [], path: "/PharmacyPurchase/PurchaseOrder/POrequest" },
    // { id: 33.2, menuName: 'Request List', component: <PharmaPORequestList history={history} />, subMenu: [], path: "/PharmacyPurchase/PurchaseOrder/RequestList" },
  ]

  // Radiology Tabs Starts ====>

  const Rad_Home = [
    {
      id: 45.3, menuName: 'Reports', component: <RadNotes history={history} />, subMenu: [
        { id: 45.32, menuName: 'Test Reports', component: <RadTestReports history={history} />, path: "/RadiologyHome/Patient/Reports/TestReports", Access: CheckAccessFunc("Radiology", "Home", "Reports", "Test Reports", "SubTab") },
        { id: 45.31, menuName: 'Notes', component: <RadNotes history={history} />, path: "/RadiologyHome/Patient/Reports/Notes", Access: CheckAccessFunc("Radiology", "Home", "Reports", "Notes", "SubTab") },

      ], path: "/RadiologyHome/Patient/Reports/TestReports"
    },
    {
      id: 45.2, menuName: 'Billing', component: <RadBilling history={history} />, subMenu: [
        { id: 45.21, menuName: 'Create Bill', component: <RadBilling history={history} />, path: "/RadiologyHome/Patient/Billing", Access: CheckAccessFunc("Radiology", "Home", "Billing", "Create Bill", "SubTab") },
        { id: 45.22, menuName: 'Saved Bill', component: <RadSavedBill history={history} />, path: "/RadiologyHome/Patient/Billing/SavedBill", Access: CheckAccessFunc("Radiology", "Home", "Billing", "Saved Bill", "SubTab") },
        { id: 45.23, menuName: 'Bills', component: <RadBills history={history} />, path: "/RadiologyHome/Patient/Billing/Bills", Access: CheckAccessFunc("Radiology", "Home", "Billing", "Bills", "SubTab") },
        { id: 45.24, menuName: 'Receipt Bills', component: <RadReceiptBill history={history} />, path: "/RadiologyHome/Patient/Billing/ReceiptBills", Access: CheckAccessFunc("Radiology", "Home", "Billing", "Receipts", "SubTab") },
      ], path: "/RadiologyHome/Patient/Billing"
    },
    { id: 45.1, menuName: 'Demographics', component: <RadDemographics history={history} />, subMenu: [], path: "/RadiologyHome/Patient/Demographics", Access: CheckAccessFunc("Radiology", "Home", 'Demographics', null, "Tab") },
  ]

  const Rad_Billing_Transaction = [
    { id: 46.1, menuName: 'Overall Bills', component: <RadOverAllBills history={history} />, subMenu: [], path: "/RadiologyBillingTransaction/OverallBills", Access: CheckAccessFunc("Radiology", "Billing Transaction", 'Overall Bills', null, "Tab") },
    { id: 46.2, menuName: 'Credit Bills', component: <RadCreditBills history={history} />, subMenu: [], path: "/RadiologyBillingTransaction/CreditBills", Access: CheckAccessFunc("Radiology", "Billing Transaction", 'Credit Bills', null, "Tab") },
    { id: 46.3, menuName: 'Cancelled Bills', component: <RadCancelledBills history={history} />, subMenu: [], path: "/RadiologyBillingTransaction/CancelledBills", Access: CheckAccessFunc("Radiology", "Billing Transaction", 'Cancelled Bills', null, "Tab") },
    { id: 46.4, menuName: 'Saved Bills', component: <RadSavedBills history={history} />, subMenu: [], path: "/RadiologyBillingTransaction/SavedBills", Access: CheckAccessFunc("Radiology", "Billing Transaction", 'Saved Bills', null, "Tab") },
    { id: 46.5, menuName: 'Receipt Bills', component: <RadReceiptBills history={history} />, subMenu: [], path: "/RadiologyBillingTransaction/ReceiptBills", Access: CheckAccessFunc("Radiology", "Billing Transaction", 'Receipt Bills', null, "Tab") },
    { id: 46.6, menuName: 'Day End', component: <RadDayEndStatus history={history} />, subMenu: [], path: "/RadiologyBillingTransaction/DayEndStatus", Access: CheckAccessFunc("Radiology", "Billing Transaction", 'Day End', null, "Tab") },
    { id: 46.7, menuName: "Day End Denomination", component: <UserDayEndSalesReport from = "Radiology" history={history} {...props} />, subMenu: [], path: '/RadiologyBillingTransaction/DayEndDenomination', testID: 'Receipt Bills', Access: CheckAccessFunc("Radiology", "Billing Transaction", 'Day End Denomination', null, "Tab") }
  ]

  const Rad_Reports = [
    { id: 47.1, menuName: 'Census Report', component: <RadCensus history={history} />, subMenu: [], path: "/RadiologyReports/Census", Access: CheckAccessFunc("Radiology", "Reports", 'Census Report', null, "Tab") },
    { id: 47.2, menuName: 'Patient Report', component: <RadPatientReport history={history} />, subMenu: [], path: "/RadiologyReports/PatientReport", Access: CheckAccessFunc("Radiology", "Reports", 'Patient Report', null, "Tab") },
  ]

  const Rad_Setting = [
    { id: 48.1, menuName: 'Service Name & Type Config', component: <RadServiceConfig history={history} />, subMenu: [], path: "/RadiologySettings/ServiceConfig", Access: CheckAccessFunc("Radiology", "Settings", 'Service Name & Type Configuration', null, "Tab") },
    { id: 48.2, menuName: 'Result Template', component: <RadServiceTemplate history={history} />, subMenu: [], path: "/RadiologySettings/ServiceTemplateConfig", Access: CheckAccessFunc("Radiology", "Settings", 'Service Template', null, "Tab") },
    { id: 48.3, menuName: 'Print Configuration', component: <RadPrintConfig history={history} />, subMenu: [], path: "/RadiologySettings/PrintConfig", Access: CheckAccessFunc("Radiology", "Settings", 'Print Configuration', null, "Tab") },
  ]

  const Rad_Setting_Templete = [
    { id: 49.1, menuName: 'Result Template', component: <RadAddTemplate history={history} />, subMenu: [], path: "/RadiologySettings/ServiceTemplateConfig/Template" },
  ]

  const Rad_Demographics = [
    { id: 50.1, menuName: 'Quick Billing & Demographics', component: <RadQuickDemographics history={history} />, subMenu: [], path: "/RadiologyHome/Demographics" },
  ]
  const Rad_Demographics_Update = [
    { id: 51.1, menuName: 'Demographics', component: <RadDemographics history={history} />, subMenu: [], path: "/RadiologyHome/Demographics/Update" },
  ]

  // Radiology Tabs Ends ====>
  const Canteen_Home = [
    { id: 62.1, menuName: 'Canteen Billing', component: <CanteenHome history={history} />, subMenu: [], path: "/CanteenHome", },
  ]
  const Canteen_Bill_Trans = [
    { id: 60.1, menuName: 'Overall Bills', component: <CanteenOverallBill history={history} />, subMenu: [], path: "/CanteenBillingTransaction", },
    { id: 60.2, menuName: 'Credit Bills', component: <CanteenCreditBill history={history} />, subMenu: [], path: "/CanteenBillingTransaction/CreditBill", },
    { id: 60.3, menuName: 'Cancelled Bills', component: <CanteenCanceledBill history={history} />, subMenu: [], path: "/CanteenBillingTransaction/CanceledBill", },
    { id: 60.4, menuName: 'Receipt Bills', component: <CanteenReceiptBill history={history} />, subMenu: [], path: "/CanteenBillingTransaction/ReceiptBill", },
    { id: 60.5, menuName: 'Day End', component: <CanteenDayend history={history} />, subMenu: [], path: "/CanteenBillingTransaction/Dayend", },
  ]
  const Canteen_Settings = [
    { id: 61.1, menuName: 'Menu Item', component: <CanteenMenuItem history={history} />, subMenu: [], path: "/CanteenSettings/CanteenMenuItem", },
  ]
  const Asset_Home_Tabs = [
    { id: 71.1, menuName: 'Asset List', component: <AssetHome history={history} />, subMenu: [], path: "/AssetHome" },
    { id: 71.2, menuName: 'Asset Transfer', component: <AssetTransfer history={history} />, subMenu: [], path: "/AssetHome/AssetTransfer" },
    { id: 71.3, menuName: 'Asset Audit', component: <AssetAudit history={history} />, subMenu: [], path: "/AssetHome/AssetAudit" },
  ]

  const Asset_Settings_Tabs = [
    { id: 72.1, menuName: 'Category', component: <AssetCategory history={history} />, subMenu: [], path: "/AssetSettings" },
    { id: 72.2, menuName: 'Sub-Category', component: <AssetSubCategory history={history} />, subMenu: [], path: "/AssetSettings/SubCategory" },
    { id: 72.3, menuName: 'Block', component: <AssetBlock history={history} />, subMenu: [], path: "/AssetSettings/Block" },
    { id: 72.4, menuName: 'Floor', component: <AssetFloor history={history} />, subMenu: [], path: "/AssetSettings/Floor" },
    { id: 72.5, menuName: 'Spot Area', component: <AssetSpotArea history={history} />, subMenu: [], path: "/AssetSettings/SpotArea" },
    { id: 72.6, menuName: 'Designation', component: <AssetDesignation history={history} />, subMenu: [], path: "/AssetSettings/Designation" },
    { id: 72.7, menuName: 'Employee', component: <AssetEmployee history={history} />, subMenu: [], path: "/AssetSettings/Employee" }
  ]

  const Pharmacy_Purchase_Return = [
    { id: 34.11, menuName: "Purchase Rejection", component: <PharmaRejection history={history} />, subMenu: [], path: '/PharmacyPurchase/PurchaseRejection/New' },
  ]

  const Pharmacy_Credit_Note = [
    { id: 35.11, menuName: "Purchase Return", component: <PharmaCreditNote history={history} />, subMenu: [], path: '/PharmacyPurchase/CreditNote/NewCreditNote' },
  ]

  const LabReportsTabs = {
    financial_reports: [
      { id: 80.1, menuName: 'Bill Report', component: <BillReport />, subMenu: [], path: "/LabReports/FinancialReport/BillReport", Access: CheckAccessFunc("Laboratory", "Reports", "Financial Reports", 'Bill Report', "SubTab") },
      { id: 80.2, menuName: 'Receipt Bills', component: <LabReceiptBills />, subMenu: [], path: "/LabReports/FinancialReport/LabReceiptBills", Access: CheckAccessFunc("Laboratory", "Reports", "Financial Reports", 'Receipt Bills', "SubTab") },
      { id: 80.3, menuName: 'Census Report', component: <LabCensusReport />, subMenu: [], path: "/LabReports/FinancialReport/LabCensusReport", Access: CheckAccessFunc("Laboratory", "Reports", "Financial Reports", 'Lab Census Report', 'SubTab') },
      { id: 80.4, menuName: 'Day End Report', component: <LabDayEnd />, subMenu: [], path: "/LabReports/FinancialReport/LabDayEndReport", Access: CheckAccessFunc("Laboratory", "Reports", "Financial Reports", 'Day End Report', 'SubTab') },
      { id: 80.5, menuName: 'Corporate Bill Report', component: <CorporateBillReport />, subMenu: [], path: "/LabReports/FinancialReport/CorporateBillReport", Access: CheckAccessFunc("Laboratory", "Reports", "Financial Reports", 'Corporate Bill Report', 'SubTab') },
      { id: 80.6, menuName: 'Lab Share Report', component: <LabShareReport />, subMenu: [], path: "/LabReports/FinancialReport/LabShareReport", Access: CheckAccessFunc("Laboratory", "Reports", "Financial Reports", 'Lab Share Report', 'SubTab') },
      { id: 80.7, menuName: 'Test Census Report', component: <TestCensusReport />, subMenu: [], path: "/LabReports/FinancialReport/TestCensusReport", Access: CheckAccessFunc("Laboratory", "Reports", "Financial Reports", 'Test Census Report', 'SubTab') },
      { id: 80.8, menuName: "Day End Denomination", component: <UserDayEndSalesReport from = "Laboratory" history={history} {...props} />, subMenu: [], path: '/LabReports/FinancialReport/DayEndDenomination', testID: 'lab_denomination', Access: CheckAccessFunc("Laboratory", "Reports", "Financial Reports", "Day End Denomination", "SubTab") }
    ],
    patient_reports: [
      { id: 81.1, menuName: 'Patient Report', component: <LabPatientReport />, subMenu: [], path: "/LabReports/PatientReport/LabPatientReport", Access: CheckAccessFunc("Laboratory", "Reports", "Patient Reports", 'Patient Report', "SubTab") },
      { id: 81.2, menuName: 'Diagnostic Census Report', component: <LabResultCensusReport />, subMenu: [], path: "/LabReports/PatientReport/LabResultCensusReport", Access: CheckAccessFunc("Laboratory", "Reports", "Patient Reports", 'Diagnostic Census  Bills', "SubTab") },
      { id: 81.3, menuName: 'IP Admission List', component: <IpAdmissionReport />, subMenu: [], path: "/LabReports/PatientReport/IpAdmissionReport", Access: CheckAccessFunc("Laboratory", "Reports", "Patient Reports", 'IP Admission List', 'SubTab') },
    ],
    business_reports: [
      { id: 82.1, menuName: 'Referred Doctor Report', component: <DoctorReport />, subMenu: [], path: "/LabReports/BusinessReport/DoctorReport", Access: CheckAccessFunc("Laboratory", "Reports", "Business Reports", "Referral Doctor Report", "SubTab") },
      { id: 82.2, menuName: 'Outsource Report', component: <OutSourceReport />, subMenu: [], path: "/LabReports/BusinessReport/OutSourceReport", Access: CheckAccessFunc("Laboratory", "Reports", "Business Reports", "Outsource Report", "SubTab") },
      { id: 82.3, menuName: 'Group Wise Report', component: <GroupwiseReport />, subMenu: [], path: "/LabReports/BusinessReport/GroupwiseReport", Access: CheckAccessFunc("Laboratory", "Reports", "Business Reports", 'Group Wise Report', "SubTab") },
      { id: 82.4, menuName: 'Group Wise Summary', component: <GroupwiseSummaryReport />, subMenu: [], path: "/LabReports/BusinessReport/GroupwiseSummaryReport", Access: CheckAccessFunc("Laboratory", "Reports", "Business Reports", 'Group Wise Summary', "SubTab") },
      { id: 82.5, menuName: 'Test Wise Report', component: <TestwiseReport />, subMenu: [], path: "/LabReports/BusinessReport/TestwiseReport", Access: CheckAccessFunc("Laboratory", "Reports", "Business Reports", 'Test Wise Report', "SubTab") },
      { id: 82.6, menuName: 'Test Wise Summary', component: <TestwiseSummaryReport />, subMenu: [], path: "/LabReports/BusinessReport/TestwiseSummaryReport", Access: CheckAccessFunc("Laboratory", "Reports", "Business Reports", 'Test Wise Summary', "SubTab") },
      { id: 82.7, menuName: 'Package Wise Report', component: <PackageWiseReport />, subMenu: [], path: "/LabReports/BusinessReport/PackageWiseReport", Access: CheckAccessFunc("Laboratory", "Reports", "Business Reports", 'Package Wise Report', "SubTab") },
      { id: 82.8, menuName: 'TAT Report', component: <LabTatReport />, subMenu: [], path: "/LabReports/BusinessReport/TatReport", Access: CheckAccessFunc("Laboratory", "Reports", "Business Reports", 'TAT Report', "SubTab") },
      { id: 82.9, menuName: 'Service Census Report', component: <ServiceCensusReport />, subMenu: [], path: "/LabReports/BusinessReport/ServiceCensusReport", Access: CheckAccessFunc("Laboratory", "Reports", "Business Reports", 'Service Census Report', "SubTab") },
    ],
  }

  const SettingsMasterTab = {
    Lab_masters: [
      { id: 83.1, menuName: 'Department Master', component: <DepartmentMaster />, subMenu: [], path: "/SettingsHome/LabMaster/DepartmentMaster", Access: CheckAccessFunc("Laboratory", "Settings", "Lab Masters", 'Department Master', "SubTab") },
      { id: 83.2, menuName: 'Container Master', component: <ContainerMaster />, subMenu: [], path: "/SettingsHome/LabMaster/ContainerMaster", Access: CheckAccessFunc("Laboratory", "Settings", "Lab Masters", 'Container Master', "SubTab") },
      { id: 83.3, menuName: 'Specimen Master', component: <SpecimenMaster />, subMenu: [], path: "/SettingsHome/LabMaster/SpecimenMster", Access: CheckAccessFunc("Laboratory", "Settings", "Lab Masters", 'Specimen Master', 'SubTab') },
      { id: 83.4, menuName: 'Method Master', component: <MethodMaster />, subMenu: [], path: "/SettingsHome/LabMaster/MethodMaster", Access: CheckAccessFunc("Laboratory", "Settings", "Lab Masters", 'Method Master', 'SubTab') },
      { id: 83.5, menuName: 'Antibiotic Master', component: <AntibioticMaster />, subMenu: [], path: "/SettingsHome/LabMaster/AntibioticMaster", Access: CheckAccessFunc("Laboratory", "Settings", "Lab Masters", 'Antibiotic Master', 'SubTab') },
      { id: 83.6, menuName: 'Organism Master', component: <OrganismMaster />, subMenu: [], path: "/SettingsHome/LabMaster/OrganismMaster", Access: CheckAccessFunc("Laboratory", "Settings", "Lab Masters", 'Organism Master', 'SubTab') },
      { id: 83.7, menuName: 'Organism Vs Antibiotic Name', component: <OrganismVsAntibioticGrp />, subMenu: [], path: "/SettingsHome/LabMaster/OrganismVsAntibioticGrp", Access: CheckAccessFunc("Laboratory", "Settings", "Lab Masters", 'Organism Vs Antibiotic Name', 'SubTab') },
      { id: 83.8, menuName: 'Analyser Master', component: <AnalyserMaster />, subMenu: [], path: "/SettingsHome/LabMaster/AnalyserMaster", Access: CheckAccessFunc("Laboratory", "Settings", "Lab Masters", 'Analyser Master', 'SubTab') },
    ]
  }

  // Doctor module Ip list starts ====>
  const IP_Doctor_Admission_Details = [
    { id: 81.1, menuName: 'Admission Details', component: <IPAppoinmentIndex history={history} {...props} is_From_Ip_AdmissionDetails = {true}/>, subMenu: [], path: "/IPDoctorHome/AdmissionDetails" },
    { id: 81.2, menuName: 'Clinical Notes', component: <ComingSoon history={history} />, subMenu: [
      { id: 81.21, menuName: 'Vitals', component: <Vitals history={history} is_From_IpNotes = {true}/>, path: "/IPDoctorHome/AdmissionDetails/ClinicalNotes/Vitals"},
      { id: 81.22, menuName: 'Doctor Notes', component: <DoctorNotes history={history} is_From_IpNotes = {true}/>, path: "/IPDoctorHome/AdmissionDetails/ClinicalNotes/DoctorNotes"},
      { id: 81.23, menuName: 'Nurse Notes', component: <IpNurseNotes history={history} is_From_IpNotes = {true}/>, path: "/IPDoctorHome/AdmissionDetails/ClinicalNotes/NurseNotes"},
      { id: 81.24, menuName: 'IV / IM Track', component: <InsulinTrack history={history} is_From_IpNotes = {true}/>, path: "/IPDoctorHome/AdmissionDetails/ClinicalNotes/IVIMTrack" },
      { id: 81.25, menuName: 'Drug Chart', component: <DrugChart history={history} is_From_IpNotes = {true}/>, path: "/IPDoctorHome/AdmissionDetails/ClinicalNotes/DrugChart" },
      { id: 81.26, menuName: 'Pain Chart', component: <PainChart history={history} is_From_IpNotes = {true}/>, path: "/IPDoctorHome/AdmissionDetails/ClinicalNotes/PainChart"},
      { id: 81.27, menuName: 'Nutrition Chart', component: <NutritionChart history={history} is_From_IpNotes = {true}/>, path: "/IPDoctorHome/AdmissionDetails/ClinicalNotes/NutritionChart"},
      { id: 81.28, menuName: 'Input/OutPut Chart', component: <InputOutChart history={history} is_From_IpNotes = {true}/>, path: "/IPDoctorHome/AdmissionDetails/ClinicalNotes/InputOutputChart"},
      { id: 81.29, menuName: 'Medication', component: <Medication history={history} is_From_IpNotes = {true}/>, path: "/IPDoctorHome/AdmissionDetails/ClinicalNotes/Medication"},
      { id: 81.30, menuName: 'Laboratory', component: <Laboratory history={history} is_From_IpNotes = {true}/>, path: "/IPDoctorHome/AdmissionDetails/ClinicalNotes/Laboratory"},
    ], path: "/IPDoctorHome/AdmissionDetails/ClinicalNotes" },
    {
      id: 81.4, menuName: 'History', component: <DocMedicalHistory history={history} is_From_Ip_AdmissionDetails = {true}/>, subMenu: [
        { id: 81.41, menuName: 'Medical History', component: <DocMedicalHistory history={history} is_From_Ip_AdmissionDetails = {true}/>, path: "/IPDoctorHome/AdmissionDetails/History/MedicalHistory" },
        { id: 81.42, menuName: 'Hospitalization History', component: <DocHospitalizationHistory history={history} is_From_Ip_AdmissionDetails = {true}/>, path: "/IPDoctorHome/AdmissionDetails/History/HospitalizationHistory" },
        { id: 81.43, menuName: 'Social History', component: <DocSocialHistory history={history} is_From_Ip_AdmissionDetails = {true}/>, path: "/IPDoctorHome/AdmissionDetails/History/SocialHistory" },
        { id: 81.44, menuName: 'Family History', component: <DocFamilyHistory history={history} is_From_Ip_AdmissionDetails = {true}/>, path: "/IPDoctorHome/AdmissionDetails/History/FamilyHistory" },
      ], path: "/IPDoctorHome/AdmissionDetails/History"
    },
    { id: 81.8, menuName: 'Reports', component: <DocPrintOrder history={history} />, subMenu: [
      { id: 81.81, menuName: 'Print Order Report', component: <DocPrintOrder history={history} fromIP={true} />, path: "/IPDoctorHome/AdmissionDetails/Reports/PrintOrderReports" },
    ], path: "/IPDoctorHome/AdmissionDetails/Reports" },
    { id: 81.5, menuName: 'Surgery Notes', component: <SurgeryNotes history={history} />, subMenu: [], path: "/IPDoctorHome/AdmissionDetails/SurgeryNotes" },
    { id: 81.6, menuName: 'Discharge Summary', component: <DischargeSummary history={history} isFromDoctor={true} isFromIPDoctor={true}/>, subMenu: [], path: "/IPDoctorHome/AdmissionDetails/DischargeSummary" },
    { id: 81.7, menuName: 'Patient Documents', component: <DocPatientDocument history={history} FromDoctor={true}/>, subMenu: [], path: "/IPDoctorHome/AdmissionDetails/PatientDocuments" },
]
// Doctor module Ip list End ====>
  const Counsellor_Tabs = [
    { id: 81.1, menuName: 'Doctor Visit Report', component: <DocDoctorVisitReport history={history} />, subMenu: [], path: "/Counsellor/PatientDetails/DoctorVisitReport" }
  ]

  const PharmacyBranch_Stock_Transfer = [
    { id: 90.10, menuName: 'Wanted List', component: <PharmaBranchWanted history={history} />, subMenu: [], path: '/PharmacyStockTransfer/PharmaBranchWanted', testID: 'tab_branch_wanted', Access: CheckAccessFunc("Pharmacy", "Stock Transfer", "Inter Branch Stock Movement", "Wanted List", "SubTab") },
    { id: 90.11, menuName: 'Inbound', component: <PharmaBranchInbound  history={history} getcountNotification={getcountNotification} />, subMenu: [], path: '/PharmacyStockTransfer/PharmaBranchInbound', testID: 'tab_branch_inbound', Access: CheckAccessFunc("Pharmacy", "Stock Transfer", "Inter Branch Stock Movement", "Inbound", "SubTab"), count: getCachevalue('branchInbound') },
    { id: 90.12, menuName: 'Outbound', component: <PharmaBranchOutbound history={history} getcountNotification={getcountNotification} />, subMenu: [], path: '/PharmacyStockTransfer/PharmaBranchOutbound', testID: 'tab_branch_outbound', Access: CheckAccessFunc("Pharmacy", "Stock Transfer", "Inter Branch Stock Movement", "Outbound", "SubTab"), count: getCachevalue('branchOutbound') },
    { id: 90.13, menuName: 'Return Request', component: <PharmaBranchReturn history={history} />, subMenu: [], path: '/PharmacyStockTransfer/PharmaBranchReturn', testID: 'tab_branch_rtn_req', Access: CheckAccessFunc("Pharmacy", "Stock Transfer", "Inter Branch Stock Movement", "Return Request", "SubTab") },
    { id: 90.14, menuName: 'Stock Transfer Report', component: <PharmaBranchStockTransReport history={history} />, subMenu: [], path: '/PharmacyStockTransfer/PharmaBranchStockTransReport', testID: 'tab_branch_stk_tfr_rpt', Access: CheckAccessFunc("Pharmacy", "Stock Transfer", "Inter Branch Stock Movement", "Stock Transfer Report", "SubTab") },
    // { id: 90.15, menuName: 'Stock List', component: <PharmaBranchStockList history={history} />, subMenu: [], path: '/PharmacyStockTransfer/PharmaBranchStockList', testID: 'tab_branch_stk_list', Access: CheckAccessFunc("Pharmacy", "Stock Transfer", "Inter Branch Stock Movement", "Stock List", "SubTab") },
  ]

  const Reservation_Tabs = [
    { id: 93.10, menuName: 'Demographics', component: <ReserveDemographics history={history} />, subMenu: [], path: '/IPHome/Reservation/Demographics', testID: 'tab_res_demographics' },
    { id: 93.11, menuName: 'Receive Payment', component: <ReserveReceivePayement history={history} />, subMenu: [], path: '/IPHome/Reservation/ReceivePayment', testID: 'tab_res_receive_payment' },
  ]


  return (
    <Switch>
      <Route exact path="/" render={props => <Signin {...props} />} />
      <Route exact path="/ForgotPassword" render={props => <Forgotpassword {...props} />} />
      <Route exact path="/ResetPassword" render={props => <ResetPassword {...props} />} />
      <Switch>
        {/* FO Routers ===== */}
        <Route exact path="/Dashboard" render={props => isAuthenticated ? <Dashboard {...props} /> : <Redirect to="/" />} />
        <Route exact path="/ViewDoctorProfile" render={props => isAuthenticated ? <DoctorProfile {...props} history={history}/> : <Redirect to="/" />} />
        <Route exact path="/OPHome" render={props => isAuthenticated ? <OutPatientHome {...props} /> : <Redirect to="/" />} />
        <Route exact path="/ChartDashboard" render={props => isAuthenticated ? <ChartDashboardHome {...props} /> : <Redirect to="/" />} />
        {/* FO Patient Details - Routers ===== */}
        <Route exact path='/OPHome/PatientDetails/Demographics' render={props => isAuthenticated ? <CommonTabs  {...props} List={PatientDetailsTabs} selectedTab={PatientDetailsTabs[0]} BackToHome={"/OPHome"} /> : <Redirect to="/" />} />
        {UserData?.module_name !== 'nurse' ? <Route exact path='/OPHome/PatientDetails/Billing' render={props => isAuthenticated ? <CommonTabs  {...props} changeScreen={changeScreen} List={PatientDetailsTabs} selectedTab={PatientDetailsTabs[1]} BackToHome={"/OPHome"} /> : <Redirect to="/" />} /> :
          <Route exact path='/OPHome/PatientDetails/ClinicalNotes' render={props => isAuthenticated ? <CommonTabs  {...props} List={PatientDetailsTabs} selectedTab={PatientDetailsTabs[1]} BackToHome={"/OPHome"} /> : <Redirect to="/" />} />}
        {UserData?.module_name !== 'nurse' ? <Route exact path='/OPHome/PatientDetails/NurseNotes' render={props => isAuthenticated ? <CommonTabs  {...props} List={PatientDetailsTabs} selectedTab={PatientDetailsTabs[2]} BackToHome={"/OPHome"} /> : <Redirect to="/" />} /> : null}
        {UserData?.module_name !== 'nurse' ? <Route exact path='/OPHome/PatientDetails/DoctorVisitReports' render={props => isAuthenticated ? <CommonTabs  {...props} List={PatientDetailsTabs} selectedTab={PatientDetailsTabs[3]} BackToHome={"/OPHome"} /> : <Redirect to="/" />} /> :
          <Route exact path='/OPHome/PatientDetails/DoctorVisitReports' render={props => isAuthenticated ? <CommonTabs  {...props} List={PatientDetailsTabs} selectedTab={PatientDetailsTabs[2]} BackToHome={"/OPHome"} /> : <Redirect to="/" />} />}
        {UserData?.module_name !== 'nurse' ? <Route exact path='/OPHome/PatientDetails/TestReports' render={props => isAuthenticated ? <CommonTabs  {...props} List={PatientDetailsTabs} selectedTab={PatientDetailsTabs[4]} BackToHome={"/OPHome"} /> : <Redirect to="/" />} /> :
          <Route exact path='/OPHome/PatientDetails/TestReports' render={props => isAuthenticated ? <CommonTabs  {...props} List={PatientDetailsTabs} selectedTab={PatientDetailsTabs[3]} BackToHome={"/OPHome"} /> : <Redirect to="/" />} />}
        {UserData?.module_name !== 'nurse' ? <Route exact path='/OPHome/PatientDetails/PrintOrderReports' render={props => isAuthenticated ? <CommonTabs  {...props} List={PatientDetailsTabs} selectedTab={PatientDetailsTabs[5]} BackToHome={"/OPHome"} /> : <Redirect to="/" />} /> :
          <Route exact path='/OPHome/PatientDetails/PrintOrderReports' render={props => isAuthenticated ? <CommonTabs  {...props} List={PatientDetailsTabs} selectedTab={PatientDetailsTabs[4]} BackToHome={"/OPHome"} /> : <Redirect to="/" />} />}
        {UserData?.module_name !== 'nurse' ? <Route exact path='/OPHome/PatientDetails/CorporateDetails' render={props => isAuthenticated ? <CommonTabs  {...props} List={PatientDetailsTabs} selectedTab={PatientDetailsTabs[6]} BackToHome={"/OPHome"} /> : <Redirect to="/" />} /> :
          <Route exact path='/OPHome/PatientDetails/CorporateDetails' render={props => isAuthenticated ? <CommonTabs  {...props} List={PatientDetailsTabs} selectedTab={PatientDetailsTabs[5]} BackToHome={"/OPHome"} /> : <Redirect to="/" />} />}
        {UserData?.module_name !== 'nurse' ? <Route exact path='/OPHome/PatientDetails/PatientDocument' render={props => isAuthenticated ? <CommonTabs  {...props} List={PatientDetailsTabs} selectedTab={PatientDetailsTabs[7]} BackToHome={"/OPHome"} /> : <Redirect to="/" />} /> :
          <Route exact path='/OPHome/PatientDetails/PatientDocument' render={props => isAuthenticated ? <CommonTabs  {...props} List={PatientDetailsTabs} selectedTab={PatientDetailsTabs[6]} BackToHome={"/OPHome"} /> : <Redirect to="/" />} />}
        {UserData?.module_name !== 'nurse' ? <Route exact path='/OPHome/PatientDetails/DeathCertificate' render={props => isAuthenticated ? <CommonTabs  {...props} List={PatientDetailsTabs} selectedTab={PatientDetailsTabs[8]} BackToHome={"/OPHome"} /> : <Redirect to="/" />} /> :
          <Route exact path='/OPHome/PatientDetails/DeathCertificate' render={props => isAuthenticated ? <CommonTabs  {...props} List={PatientDetailsTabs} selectedTab={PatientDetailsTabs[7]} BackToHome={"/OPHome"} /> : <Redirect to="/" />} />}
        {/* FO Billing & Apoointment - Routers ===== */}
        <Route exact path='/OPHome/Billing/QuickBilling' render={props => isAuthenticated ? <CommonTabs  {...props} VisitDateBalAmount={visitdDateObj} changeNurse={changeNurse} nurseState={isNurse} history={history} List={PatientBillingTabs} selectedTab={PatientBillingTabs[0]} BackToHome={"/OPHome"} /> : <Redirect to="/" />} />
        <Route exact path='/OPHome/Billing/CreateAppointment' render={props => isAuthenticated ? <CommonTabs  {...props} VisitDateBalAmount={visitdDateObj} changeNurse={changeNurse} nurseState={isNurse} history={history} List={PatientBillingTabs} selectedTab={PatientBillingTabs[1]} BackToHome={"/OPHome"} /> : <Redirect to="/" />} />
        {/* FO Billing Transactions - Routers ===== */}
        <Route exact path='/BillingTransaction' render={props => isAuthenticated ? <CommonTabs  {...props} List={BillingTransactionTabs} selectedTab={BillingTransactionTabs[0]} /> : <Redirect to="/" />} />
        {/* <Route exact path='/BillingTransaction/Summary' render={props => isAuthenticated ? <CommonTabs  {...props} List={BillingTransactionTabs} selectedTab={BillingTransactionTabs[0]} /> : <Redirect to="/" />} /> */}
        <Route exact path='/BillingTransaction/OverAllBills' render={props => isAuthenticated ? <CommonTabs  {...props} List={BillingTransactionTabs} selectedTab={BillingTransactionTabs[0]} /> : <Redirect to="/" />} />
        <Route exact path='/BillingTransaction/CreditBills' render={props => isAuthenticated ? <CommonTabs  {...props} List={BillingTransactionTabs} selectedTab={BillingTransactionTabs[1]} /> : <Redirect to="/" />} />
        {/* <Route exact path='/BillingTransaction/CompletedBills' render={props => isAuthenticated ? <CommonTabs  {...props} List={BillingTransactionTabs} selectedTab={BillingTransactionTabs[2]} /> : <Redirect to="/" />} /> */}
        <Route exact path='/BillingTransaction/CancelledBills' render={props => isAuthenticated ? <CommonTabs  {...props} List={BillingTransactionTabs} selectedTab={BillingTransactionTabs[2]} /> : <Redirect to="/" />} />
        <Route exact path='/BillingTransaction/SavedBills' render={props => isAuthenticated ? <CommonTabs  {...props} List={BillingTransactionTabs} selectedTab={BillingTransactionTabs[3]} /> : <Redirect to="/" />} />
        <Route exact path='/BillingTransaction/ReceiptBills' render={props => isAuthenticated ? <CommonTabs  {...props} List={BillingTransactionTabs} selectedTab={BillingTransactionTabs[4]} /> : <Redirect to="/" />} />
        <Route exact path='/BillingTransaction/DayEnd' render={props => isAuthenticated ? <CommonTabs  {...props} List={BillingTransactionTabs} selectedTab={BillingTransactionTabs[5]} /> : <Redirect to="/" />} />
        <Route exact path='/BillingTransaction/Expenses' render={props => isAuthenticated ? <CommonTabs  {...props} List={BillingTransactionTabs} selectedTab={BillingTransactionTabs[6]} /> : <Redirect to="/" />} />
        <Route exact path='/BillingTransaction/DayEndDenomination' render={props => isAuthenticated ? <CommonTabs {...props} List={BillingTransactionTabs} selectedTab={BillingTransactionTabs[7]} /> : <Redirect to='/' />} />

        {/* FO Reports - Routers ===== */}
        <Route exact path='/FOReports' render={props => isAuthenticated ? <ReportsHome {...props} history={history} /> : <Redirect to="/" />} />
        <Route exact path='/FOReports/ServiceReports/ServiceDetailed' render={props => isAuthenticated ? <CommonTabs {...props} List={ReportsTabs.service_reports} selectedTab={ReportsTabs.service_reports[0]} BackToHome={"/FOReports"} /> : <Redirect to="/" />} />
        <Route exact path='/FOReports/ServiceReports/ServiceOverview' render={props => isAuthenticated ? <CommonTabs {...props} List={ReportsTabs.service_reports} selectedTab={ReportsTabs.service_reports[1]} BackToHome={"/FOReports"} /> : <Redirect to="/" />} />
        <Route exact path='/FOReports/ServiceReports/OPServiceShare' render={props => isAuthenticated ? <CommonTabs {...props} List={ReportsTabs.service_reports} selectedTab={ReportsTabs.service_reports[2]} BackToHome={'/FOReports'} /> : <Redirect to='/' />} />
        <Route exact path='/FOReports/ServiceReports/ConsultWiseReport' render={props => isAuthenticated ? <CommonTabs {...props} List={ReportsTabs.service_reports} selectedTab={ReportsTabs.service_reports[3]} BackToHome={'/FOReports'} /> : <Redirect to='/' />} />
        <Route exact path='/FOReports/IpServiceReport/IPServiceDetailed' render={props => isAuthenticated ? <CommonTabs {...props} List={ReportsTabs.ip_service_reports} selectedTab={ReportsTabs.ip_service_reports[0]} BackToHome={"/FOReports"} /> : <Redirect to="/" />} />
        <Route exact path='/FOReports/IpServiceReport/IPServiceOverview' render={props => isAuthenticated ? <CommonTabs {...props} List={ReportsTabs.ip_service_reports} selectedTab={ReportsTabs.ip_service_reports[1]} BackToHome={"/FOReports"} /> : <Redirect to="/" />} />
        <Route exact path='/FOReports/ServiceReports/IPServiceShare' render={props => isAuthenticated ? <CommonTabs {...props} List={ReportsTabs.ip_service_reports} selectedTab={ReportsTabs.ip_service_reports[2]} BackToHome={'/FOReports'} /> : <Redirect to='/' />} />
        <Route exact path='/FOReports/IpServiceReport/IPLegerReport' render={props => isAuthenticated ? <CommonTabs {...props} List={ReportsTabs.ip_service_reports} selectedTab={ReportsTabs.ip_service_reports[3]} BackToHome={"/FOReports"} /> : <Redirect to="/" />} />
        <Route exact path='/FOReports/IpServiceReport/IPAdmissionList' render={props => isAuthenticated ? <CommonTabs {...props} List={ReportsTabs.ip_service_reports} selectedTab={ReportsTabs.ip_service_reports[4]} BackToHome={"/FOReports"} /> : <Redirect to="/" />} />
        <Route exact path='/FOReports/IpServiceReport/IPDischargeList' render={props => isAuthenticated ? <CommonTabs {...props} List={ReportsTabs.ip_service_reports} selectedTab={ReportsTabs.ip_service_reports[5]} BackToHome={"/FOReports"} /> : <Redirect to="/" />} />
        <Route exact path='/FOReports/IpServiceReport/IPOccupancyReport' render={props => isAuthenticated ? <CommonTabs {...props} List={ReportsTabs.ip_service_reports} selectedTab={ReportsTabs.ip_service_reports[6]} BackToHome={"/FOReports"} /> : <Redirect to="/" />} />
        <Route exact path='/FOReports/CorporateReports/BillReport' render={props => isAuthenticated ? <CommonTabs {...props} List={ReportsTabs.corporate_reports} selectedTab={ReportsTabs.corporate_reports[0]} BackToHome={"/FOReports"} /> : <Redirect to="/" />} />
        <Route exact path='/FOReports/CorporateReports/PaymentReport' render={props => isAuthenticated ? <CommonTabs {...props} List={ReportsTabs.corporate_reports} selectedTab={ReportsTabs.corporate_reports[1]} BackToHome={"/FOReports"} /> : <Redirect to="/" />} />
        <Route exact path='/FOReports/CorporateReports/InsuranceReport' render={props => isAuthenticated ? <CommonTabs {...props} List={ReportsTabs.corporate_reports} selectedTab={ReportsTabs.corporate_reports[2]} BackToHome={"/FOReports"} /> : <Redirect to="/" />} />
        <Route exact path='/FOReports/AppointmentReports/PatientReport' render={props => isAuthenticated ? <CommonTabs {...props} List={ReportsTabs.appointment_reports} selectedTab={ReportsTabs.appointment_reports[0]} BackToHome={"/FOReports"} /> : <Redirect to="/" />} />
        <Route exact path='/FOReports/AppointmentReports/DoctorReport' render={props => isAuthenticated ? <CommonTabs {...props} List={ReportsTabs.appointment_reports} selectedTab={ReportsTabs.appointment_reports[1]} BackToHome={"/FOReports"} /> : <Redirect to="/" />} />
        <Route exact path='/FOReports/AppointmentReports/SpecializationReport' render={props => isAuthenticated ? <CommonTabs {...props} List={ReportsTabs.appointment_reports} selectedTab={ReportsTabs.appointment_reports[2]} BackToHome={"/FOReports"} /> : <Redirect to="/" />} />
        <Route exact path='/FOReports/AppointmentReports/TypeBasedReport' render={props => isAuthenticated ? <CommonTabs {...props} List={ReportsTabs.appointment_reports} selectedTab={ReportsTabs.appointment_reports[3]} BackToHome={"/FOReports"} /> : <Redirect to="/" />} />
        <Route exact path='/FOReports/AppointmentReports/NoShowReport' render={props => isAuthenticated ? <CommonTabs {...props} List={ReportsTabs.appointment_reports} selectedTab={ReportsTabs.appointment_reports[5]} BackToHome={"/FOReports"} /> : <Redirect to="/" />} />
        <Route exact path='/FOReports/AppointmentReports/CounsellorSurgeryReport' render={props => isAuthenticated ? <CommonTabs {...props} List={ReportsTabs.appointment_reports} selectedTab={ReportsTabs.appointment_reports[6]} BackToHome={"/FOReports"} /> : <Redirect to="/" />} />
        <Route exact path='/FOReports/AppointmentReports/TATReport' render={props => isAuthenticated ? <CommonTabs {...props} List={ReportsTabs.appointment_reports} selectedTab={ReportsTabs.appointment_reports[4]} BackToHome={"/FOReports"} /> : <Redirect to="/" />} />
        <Route exact path='/FOReports/PatientReport/PatientDemographicReport' render={props => isAuthenticated ? <CommonTabs {...props} List={ReportsTabs.patient_reports} selectedTab={ReportsTabs.patient_reports[0]} BackToHome={"/FOReports"} /> : <Redirect to="/" />} />
        <Route exact path='/FOReports/PatientReport/PurposeOfVisitReport' render={props => isAuthenticated ? <CommonTabs {...props} List={ReportsTabs.patient_reports} selectedTab={ReportsTabs.patient_reports[1]} BackToHome={"/FOReports"} /> : <Redirect to="/" />} />
        <Route exact path='/FOReports/PatientReport/ReasonDiagnosisReport' render={props => isAuthenticated ? <CommonTabs {...props} List={ReportsTabs.patient_reports} selectedTab={ReportsTabs.patient_reports[2]} BackToHome={"/FOReports"} /> : <Redirect to="/" />} />
        <Route exact path='/FOReports/PatientReport/ConcessionReport' render={props => isAuthenticated ? <CommonTabs {...props} List={ReportsTabs.patient_reports} selectedTab={ReportsTabs.patient_reports[3]} BackToHome={"/FOReports"} /> : <Redirect to="/" />} />
        <Route exact path='/FOReports/PatientReport/CreditSettlementReport' render={props => isAuthenticated ? <CommonTabs {...props} List={ReportsTabs.patient_reports} selectedTab={ReportsTabs.patient_reports[4]} BackToHome={"/FOReports"} /> : <Redirect to="/" />} />
        <Route exact path='/FOReports/PatientReport/DaywiseCategorySalesReport' render={props => isAuthenticated ? <CommonTabs {...props} List={ReportsTabs.patient_reports} selectedTab={ReportsTabs.patient_reports[5]} BackToHome={"/FOReports"} /> : <Redirect to="/" />} />
        <Route exact path='/FOReports/ReferralReports/DoctorReport' render={props => isAuthenticated ? <CommonTabs {...props} List={ReportsTabs.referral_report} selectedTab={ReportsTabs.referral_report[0]} BackToHome={"/FOReports"} /> : <Redirect to="/" />} />
        <Route exact path='/FOReports/ReferralReports/SourceReport' render={props => isAuthenticated ? <CommonTabs {...props} List={ReportsTabs.referral_report} selectedTab={ReportsTabs.referral_report[1]} BackToHome={"/FOReports"} /> : <Redirect to="/" />} />
        <Route exact path='/FOReports/ReferralReports/ReferalValueReport' render={props => isAuthenticated ? <CommonTabs {...props} List={ReportsTabs.referral_report} selectedTab={ReportsTabs.referral_report[2]} BackToHome={"/FOReports"} /> : <Redirect to="/" />} />
        <Route exact path='/FOReports/OptReports/OpticalCRMReport' render={props => isAuthenticated ? <CommonTabs {...props} List={ReportsTabs.opt_report} selectedTab={ReportsTabs.opt_report[0]} BackToHome={"/FOReports"} /> : <Redirect to="/" />} />
        <Route exact path='/FOReports/OptReports/PrescriptionReport' render={props => isAuthenticated ? <CommonTabs {...props} List={ReportsTabs.opt_report} selectedTab={ReportsTabs.opt_report[1]} BackToHome={"/FOReports"} /> : <Redirect to="/" />} />
        <Route exact path='/FOReports/OptometristReports/OptometristList' render={props => isAuthenticated ? <CommonTabs {...props} List={ReportsTabs.optometrist_report} selectedTab={ReportsTabs.optometrist_report[0]} BackToHome={"/FOReports"} /> : <Redirect to="/" />} />
        <Route exact path='/FOReports/TallyReports/GenerateReport' render={props => isAuthenticated ? <CommonTabs {...props} List={ReportsTabs.tally_report} selectedTab={ReportsTabs.tally_report[0]} BackToHome={"/FOReports"} /> : <Redirect to="/" />} />

        <Route exact path='/FOReports/AccountReports/BillWiseReports' render={props => isAuthenticated ? <CommonTabs {...props} List={ReportsTabs.Account_reports} selectedTab={ReportsTabs.Account_reports[0]} BackToHome={"/FOReports"} /> : <Redirect to="/" />} />
        <Route exact path='/FOReports/AccountReports/AdvanceReports' render={props => isAuthenticated ? <CommonTabs {...props} List={ReportsTabs.Account_reports} selectedTab={ReportsTabs.Account_reports[1]} BackToHome={"/FOReports"} /> : <Redirect to="/" />} />
        <Route exact path='/FOReports/AccountReports/RefundorCancelledReports' render={props => isAuthenticated ? <CommonTabs {...props} List={ReportsTabs.Account_reports} selectedTab={ReportsTabs.Account_reports[2]} BackToHome={"/FOReports"} /> : <Redirect to="/" />} />
        <Route exact path='/FOReports/AccountReports/CreditServiceReports' render={props => isAuthenticated ? <CommonTabs {...props} List={ReportsTabs.Account_reports} selectedTab={ReportsTabs.Account_reports[3]} BackToHome={"/FOReports"} /> : <Redirect to="/" />} />
        <Route exact path='/FOReports/AccountReports/AccountsCreditSettlementReport' render={props => isAuthenticated ? <CommonTabs {...props} List={ReportsTabs.Account_reports} selectedTab={ReportsTabs.Account_reports[4]} BackToHome={"/FOReports"} /> : <Redirect to="/" />} />
        <Route exact path='/FOReports/AccountReports/ConcessionReports' render={props => isAuthenticated ? <CommonTabs {...props} List={ReportsTabs.Account_reports} selectedTab={ReportsTabs.Account_reports[5]} BackToHome={"/FOReports"} /> : <Redirect to="/" />} />
        <Route exact path='/FOReports/AccountReports/DoctorShareReports' render={props => isAuthenticated ? <CommonTabs {...props} List={ReportsTabs.Account_reports} selectedTab={ReportsTabs.Account_reports[6]} BackToHome={"/FOReports"} /> : <Redirect to="/" />} />
        {/* FO Settings Configuration -  Routers ===== */}
        <Route exact path='/Settings' render={props => isAuthenticated ? <ConfigHome {...props} /> : <Redirect to="/" />} />
        <Route exact path='/Settings/OPService/ServiceNameTypeConfig' render={props => isAuthenticated ? <CommonTabs {...props} List={SettingsTabs.Op_services} selectedTab={SettingsTabs.Op_services[0]} BackToHome={"/Settings"} /> : <Redirect to="/" />} />
        <Route exact path='/Settings/OPService/ServiceTemplates' render={props => isAuthenticated ? <CommonTabs {...props} List={SettingsTabs.Op_services} selectedTab={SettingsTabs.Op_services[1]} BackToHome={"/Settings"} /> : <Redirect to="/" />} />
        <Route exact path='/Settings/OPService/DoctorName' render={props => isAuthenticated ? <CommonTabs {...props} List={SettingsTabs.Op_services} selectedTab={SettingsTabs.Op_services[3]} BackToHome={"/Settings"} /> : <Redirect to="/" />} />
        <Route exact path='/Settings/OPService/AutomaticBills' render={props => isAuthenticated ? <CommonTabs {...props} List={SettingsTabs.Op_services} selectedTab={SettingsTabs.Op_services[4]} BackToHome={"/Settings"} /> : <Redirect to="/" />} />

        <Route exact path='/Settings/ReferralConfig/SourceConfig' render={props => isAuthenticated ? <CommonTabs {...props} List={SettingsTabs.referral_config} selectedTab={SettingsTabs.referral_config[0]} BackToHome={"/Settings"} /> : <Redirect to="/" />} />
        <Route exact path='/Settings/ReferralConfig/DoctorConfig' render={props => isAuthenticated ? <CommonTabs {...props} List={SettingsTabs.referral_config} selectedTab={SettingsTabs.referral_config[1]} BackToHome={"/Settings"} /> : <Redirect to="/" />} />

        <Route exact path='/Settings/CorporateConfig/CorporateEnroll' render={props => isAuthenticated ? <CommonTabs {...props} List={SettingsTabs.corporate_config} selectedTab={SettingsTabs.corporate_config[0]} BackToHome={"/Settings"} /> : <Redirect to="/" />} />
        <Route exact path='/Settings/CorporateConfig/PatientDetails' render={props => isAuthenticated ? <CommonTabs {...props} List={SettingsTabs.corporate_config} selectedTab={SettingsTabs.corporate_config[1]} BackToHome={"/Settings"} /> : <Redirect to="/" />} />

        <Route exact path='/Settings/CorporateConfig/EmployerBilling' render={props => isAuthenticated ? <CommonTabs {...props} List={SettingsTabs.corporate_config} selectedTab={SettingsTabs.corporate_config[2]} BackToHome={"/Settings"} /> : <Redirect to="/" />} />
        <Route exact path='/Settings/CorporateConfig/Reports' render={props => isAuthenticated ? <CommonTabs {...props} List={SettingsTabs.corporate_config} selectedTab={SettingsTabs.corporate_config[3]} BackToHome={"/Settings"} /> : <Redirect to="/" />} />
        <Route exact path='/Settings/InsuranceConfig' render={props => isAuthenticated ? <CommonTabs {...props} List={SettingsTabs.insurance_config} selectedTab={SettingsTabs.insurance_config[0]} BackToHome={"/Settings"} /> : <Redirect to="/" />} />
        <Route exact path='/Settings/SmsConfig/Request' render={props => isAuthenticated ? <CommonTabs {...props} List={SettingsTabs.sms_config} selectedTab={SettingsTabs.sms_config[0]} BackToHome={"/Settings"} /> : <Redirect to="/" />} />
        <Route exact path='/Settings/SmsConfig/Config' render={props => isAuthenticated ? <CommonTabs {...props} List={SettingsTabs.sms_config} selectedTab={SettingsTabs.sms_config[1]} BackToHome={"/Settings"} /> : <Redirect to="/" />} />

        <Route exact path='/Settings/IPService/IPServiceNameTypeConfig' render={props => isAuthenticated ? <CommonTabs {...props} List={IPSettingsTabs.Ip_services} selectedTab={IPSettingsTabs.Ip_services[1]} BackToHome={"/Settings"} /> : <Redirect to="/" />} />
        <Route exact path='/Settings/IPService/IPPackageConfig' render={props => isAuthenticated ? <CommonTabs {...props} List={IPSettingsTabs.Ip_services} selectedTab={IPSettingsTabs.Ip_services[2]} BackToHome={"/Settings"} /> : <Redirect to="/" />} />
        <Route exact path='/Settings/IPService/SurgeryConfiguration' render={props => isAuthenticated ? <CommonTabs {...props} List={IPSettingsTabs.Ip_services} selectedTab={IPSettingsTabs.Ip_services[0]} BackToHome={"/Settings"} /> : <Redirect to="/" />} />
        <Route exact path='/Settings/RoomConfig/HospitalConfig' render={props => isAuthenticated ? <CommonTabs {...props} List={IPSettingsTabs.room_config} selectedTab={IPSettingsTabs.room_config[0]} BackToHome={"/Settings"} /> : <Redirect to="/" />} />
        <Route exact path='/Settings/RoomConfig/WardConfig' render={props => isAuthenticated ? <CommonTabs {...props} List={IPSettingsTabs.room_config} selectedTab={IPSettingsTabs.room_config[1]} BackToHome={"/Settings"} /> : <Redirect to="/" />} />
        <Route exact path='/Settings/RoomConfig/BedConfig' render={props => isAuthenticated ? <CommonTabs {...props} List={IPSettingsTabs.room_config} selectedTab={IPSettingsTabs.room_config[2]} BackToHome={"/Settings"} /> : <Redirect to="/" />} />

        <Route exact path='/Settings/DemographicsConfig/DemographicsLayout' render={props => isAuthenticated ? <CommonTabs {...props} List={IPSettingsTabs.layout_config} selectedTab={IPSettingsTabs.layout_config[0]} BackToHome={"/Settings"} /> : <Redirect to="/" />} />
        <Route exact path='/Settings/LayoutConfig/DisSummaryLayoutConfig' render={props => isAuthenticated ? <CommonTabs {...props} List={IPSettingsTabs.layout_config} selectedTab={IPSettingsTabs.layout_config[1]} BackToHome={"/Settings"} /> : <Redirect to="/" />} />
        <Route exact path='/Settings/LayoutConfig/SurgeryNotesLayout' render={props => isAuthenticated ? <CommonTabs {...props} List={IPSettingsTabs.layout_config} selectedTab={IPSettingsTabs.layout_config[2]} BackToHome={"/Settings"} /> : <Redirect to="/" />} />
        <Route exact path='/Settings/PrintConfig/PrintSettings' render={props => isAuthenticated ? <CommonTabs {...props} List={IPSettingsTabs.layout_config} selectedTab={IPSettingsTabs.layout_config[3]} BackToHome={"/Settings"} /> : <Redirect to="/" />} />

        <Route exact path='/Settings/TemplateConfig/DisSumTemplateConfig' render={props => isAuthenticated ? <CommonTabs {...props} List={IPSettingsTabs.template_config} selectedTab={IPSettingsTabs.template_config[0]} BackToHome={"/Settings"} /> : <Redirect to="/" />} />
        <Route exact path='/Settings/TemplateConfig/SurgeryTemplateConfig' render={props => isAuthenticated ? <CommonTabs {...props} List={IPSettingsTabs.template_config} selectedTab={IPSettingsTabs.template_config[1]} BackToHome={"/Settings"} /> : <Redirect to="/" />} />
        <Route exact path='/Settings/TemplateConfig/DisSumTemplateConfig/Add' render={props => isAuthenticated ? <CommonTabs {...props} List={IPSettingsTabs.template_sub_config} selectedTab={IPSettingsTabs.template_sub_config[0]} BackToHome={"/Settings/TemplateConfig/DisSumTemplateConfig"} /> : <Redirect to="/" />} />
        <Route exact path='/Settings/TemplateConfig/SurgeryTemplateConfig/Add' render={props => isAuthenticated ? <CommonTabs {...props} List={IPSettingsTabs.template_sub_config} selectedTab={IPSettingsTabs.template_sub_config[1]} BackToHome={"/Settings/TemplateConfig/SurgeryTemplateConfig"} /> : <Redirect to="/" />} />
        <Route exact path='/Settings/UserAccess/UserSettings' render={props => isAuthenticated ? <CommonTabs {...props} List={UserSettings.user_config} selectedTab={UserSettings.user_config[0]} BackToHome={"/Settings"} /> : <Redirect to="/" />} />
        <Route exact path='/Settings/UserAccess/UserSettings/New' render={props => isAuthenticated ? <CommonTabs {...props} List={UserSettings.user_new_config} selectedTab={UserSettings.user_new_config[0]} BackToHome={"/Settings/UserAccess/UserSettings"} /> : <Redirect to="/" />} />
        <Route exact path='/Settings/UserConfig/Users' render={props => isAuthenticated ? <CommonTabs {...props} List={UserSettings.user_config} selectedTab={UserSettings.user_config[1]} BackToHome={"/Settings"} /> : <Redirect to="/" />} />
        <Route exact path='/Settings/CardConfig/CardLayoutConfig' render={props => isAuthenticated ? <CommonTabs {...props} List={IPSettingsTabs.card_config} selectedTab={IPSettingsTabs.card_config[1]} BackToHome={"/Settings"} /> : <Redirect to="/" />} />

        <Route exact path='/Settings/HospitalConfig/HospitalConfig' render={props => isAuthenticated ? <CommonTabs {...props} List={HospitalConfigList} selectedTab={HospitalConfigList?.find((list) => list.id === 12.1)} BackToHome={"/Settings"} /> : <Redirect to="/" />} />
        <Route exact path='/Settings/OPService/Specialization' render={props => isAuthenticated ? <CommonTabs {...props} List={HospitalConfigList} selectedTab={HospitalConfigList?.find((list) => list.id === 12.2)} BackToHome={"/Settings"} /> : <Redirect to="/" />} />
        <Route exact path='/Settings/HospitalConfig/Doctorlist' render={props => isAuthenticated ? <CommonTabs {...props} List={HospitalConfigList} selectedTab={HospitalConfigList?.find((list) => list.id === 12.3)} BackToHome={"/Settings"} /> : <Redirect to="/" />} />
        <Route exact path='/Settings/HospitalConfig/SlotConfig' render={props => isAuthenticated ? <CommonTabs {...props} List={HospitalConfigList} selectedTab={HospitalConfigList?.find((list) => list.id === 12.4)} BackToHome={"/Settings"} /> : <Redirect to="/" />} />
        <Route exact path='/Settings/OPService/TokenPrefix' render={props => isAuthenticated ? <CommonTabs {...props} List={HospitalConfigList} selectedTab={HospitalConfigList?.find((list) => list.id === 12.5)} BackToHome={"/Settings"} /> : <Redirect to="/" />} />
        <Route exact path='/Settings/OPService/AppointmentType' render={props => isAuthenticated ? <CommonTabs {...props} List={HospitalConfigList} selectedTab={HospitalConfigList?.find((list) => list.id === 12.6)} BackToHome={"/Settings"} /> : <Redirect to="/" />} />
        <Route exact path='/Settings/OPService/TagConfig' render={props => isAuthenticated ? <CommonTabs {...props} List={HospitalConfigList} selectedTab={HospitalConfigList?.find((list) => list.id === 12.7)} BackToHome={"/Settings"} /> : <Redirect to="/" />} />
        <Route exact path='/Settings/CustomConfig' render={props => isAuthenticated ? <CommonTabs {...props} List={HospitalConfigList} selectedTab={HospitalConfigList?.find((list) => list.id === 12.8)} BackToHome={"/Settings"} /> : <Redirect to="/" />} />
        <Route exact path='/Settings/ExpenseConfig/ExpenseCategory' render={props => isAuthenticated ? <CommonTabs {...props} List={HospitalConfigList} selectedTab={HospitalConfigList?.find((list) => list.id === 12.9)} BackToHome={"/Settings"} /> : <Redirect to="/" />} />
        <Route exact path='/Settings/HospitalConfig/QMSConfig' render={props => isAuthenticated ? <CommonTabs {...props} List={HospitalConfigList} selectedTab={HospitalConfigList?.find((list) => list.id === 13.0)} BackToHome={"/Settings"} /> : <Redirect to="/" />} />


        {/* <Route exact path='/Settings/OPService/BillPrefix' render={props => isAuthenticated ? <CommonTabs {...props} List={SettingsTabs.Op_services} selectedTab={SettingsTabs.Op_services[5]} BackToHome={"/Settings"} /> : <Redirect to="/" />} /> */}

        {/*FO Billing */}
        <Route exact path="/Billing" render={props => isAuthenticated ? <BillingHome {...props} List={BillingTabs} selectedTab={BillingTabs[0]} /> : <Redirect to="/" />} />
        <Route exact path='/Billing/LabBilling' render={props => isAuthenticated ? <CommonTabs changeScreen={changeScreen} {...props} List={BillingTabs} selectedTab={BillingTabs[0]} /> : <Redirect to="/" />} />
        <Route exact path='/Billing/RadiologyBilling' render={props => isAuthenticated ? <CommonTabs changeScreen={changeScreen} {...props} List={BillingTabs} selectedTab={BillingTabs[1]} /> : <Redirect to="/" />} />
        <Route exact path='/Billing/OTBilling' render={props => isAuthenticated ? <CommonTabs changeScreen={changeScreen} {...props} List={BillingTabs} selectedTab={BillingTabs[1]} /> : <Redirect to="/" />} />
        {/*IP Patient Details */}
        {/* <Route exact path="/IPHome" render={props => isAuthenticated ? <IPpatientsHome history={history} {...props} /> : <Redirect to="/" />} /> */}
        <Route exact path="/IPHome" render={props => isAuthenticated ? <CommonTabs  {...props} List={IP_Home_Tab} selectedTab={IP_Home_Tab[0]} receiveDateChange={receiveDateChange} dateDetails={dateDetails} /> : <Redirect to="/" />} />
        <Route exact path='/IPHome/Reservation' render={props => isAuthenticated ? <CommonTabs  {...props} List={IP_Home_Tab} selectedTab={IP_Home_Tab[1]} receiveDateChange={reservationDateChange} dateDetails={reserveDateDetails} /> : <Redirect to="/" />} />
        <Route exact path='/IPHome/BedAvailability' render={props => isAuthenticated ? <CommonTabs  {...props} List={IP_Home_Tab} selectedTab={IP_Home_Tab[2]} /> : <Redirect to="/" />} />
        <Route exact path='/IPHome/Estimation' render={props => isAuthenticated ? <CommonTabs  {...props} List={IP_Home_Tab} selectedTab={IP_Home_Tab[3]} /> : <Redirect to="/" />} />
        <Route exact path='/IPHome/Occupancy' render={props => isAuthenticated ? <CommonTabs  {...props} List={IP_Home_Tab} selectedTab={IP_Home_Tab[4]} /> : <Redirect to="/" />} />
        <Route exact path='/IPHome/OThome' render={props => isAuthenticated ? <CommonTabs  {...props} List={IP_Home_Tab} selectedTab={IP_Home_Tab[5]} /> : <Redirect to="/" />} />
        <Route exact path='/IPHome/EstimationSummary' render={props => isAuthenticated ? <CommonTabs history={history} {...props} List={Estimation_Tabs} selectedTab={Estimation_Tabs[1]} BackToHome={"/IPHome/Estimation"} /> : <Redirect to="/" />} />
        <Route exact path="/IPHome/CreateAppointment" render={props => isAuthenticated ? <IPAppoinmentIndex history={history} {...props} /> : <Redirect to="/" />} />
        <Route exact path='/IPHome/PatientDetails/PatientDetails' render={props => isAuthenticated ? <CommonTabs  {...props} List={IP_Patient_Details} selectedTab={IP_Patient_Details[0]} BackToHome={"/IPHome"} /> : <Redirect to="/" />} />
        <Route exact path='/IPHome/PatientDetails/ServiceBilling' render={props => isAuthenticated ? <CommonTabs changeScreen={changeScreen} {...props} List={IP_Patient_Details} selectedTab={IP_Patient_Details[1]} BackToHome={"/IPHome"} /> : <Redirect to="/" />} />
        <Route exact path='/IPHome/PatientDetails/RoomChange' render={props => isAuthenticated ? <CommonTabs  {...props} List={IP_Patient_Details} selectedTab={IP_Patient_Details[2]} BackToHome={"/IPHome"} /> : <Redirect to="/" />} />
        <Route exact path='/IPHome/PatientDetails/ReceivePayment' render={props => isAuthenticated ? <CommonTabs  {...props} List={IP_Patient_Details} selectedTab={IP_Patient_Details[3]} BackToHome={"/IPHome"} /> : <Redirect to="/" />} />
        {/* <Route exact path='/IPHome/PatientDetails/InitialAssessment' render={props => isAuthenticated ? <CommonTabs  {...props} List={IP_Patient_Details} selectedTab={IP_Patient_Details[4]} BackToHome={"/IPHome"} /> : <Redirect to="/" />} /> */}
        <Route exact path='/IPHome/PatientDetails/ClinicalNotes' render={props => isAuthenticated ? <CommonTabs  {...props} List={IP_Patient_Details} selectedTab={IP_Patient_Details[4]} BackToHome={"/IPHome"} /> : <Redirect to="/" />} />
        <Route exact path='/IPHome/PatientDetails/Reports' render={props => isAuthenticated ? <CommonTabs  {...props} List={IP_Patient_Details} selectedTab={IP_Patient_Details[5]} BackToHome={"/IPHome"} /> : <Redirect to="/" />} />
        <Route exact path='/IPHome/PatientDetails/History' render={props => isAuthenticated ? <CommonTabs  {...props} List={IP_Patient_Details} selectedTab={IP_Patient_Details[6]} BackToHome={"/IPHome"} /> : <Redirect to="/" />} />
        <Route exact path='/IPHome/PatientDetails/DischargeSummary' render={props => isAuthenticated ? <CommonTabs  {...props} List={IP_Patient_Details} selectedTab={IP_Patient_Details[7]} BackToHome={"/IPHome"} /> : <Redirect to="/" />} />
        <Route exact path='/IPHome/PatientDetails/SurgeryNotes' render={props => isAuthenticated ? <CommonTabs  {...props} List={IP_Patient_Details} selectedTab={IP_Patient_Details[8]} BackToHome={"/IPHome"} /> : <Redirect to="/" />} />
        <Route exact path='/IPHome/PatientDetails/PatientDocuments' render={props => isAuthenticated ? <CommonTabs  {...props} List={IP_Patient_Details} selectedTab={IP_Patient_Details[9]} BackToHome={"/IPHome"} /> : <Redirect to="/" />} />
        <Route exact path='/IPHome/PatientDetails/InsuranceDetails' render={props => isAuthenticated ? <CommonTabs  {...props} List={IP_Patient_Details} selectedTab={IP_Patient_Details[11]} BackToHome={"/IPHome"} /> : <Redirect to="/" />} />
        <Route exact path='/IPHome/PatientDetails/CorporateDetails' render={props => isAuthenticated ? <CommonTabs  {...props} List={IP_Patient_Details} selectedTab={IP_Patient_Details[12]} BackToHome={"/IPHome"} /> : <Redirect to="/" />} />
        <Route exact path='/IPHome/PatientDetails/DeathCertificate' render={props => isAuthenticated ? <CommonTabs  {...props} List={IP_Patient_Details} selectedTab={IP_Patient_Details[10]} BackToHome={"/IPHome"} /> : <Redirect to="/" />} />
        <Route exact path='/IPHome/Reservation/Create' render={props => isAuthenticated ? <CommonTabs  {...props} List={IPReservation} selectedTab={IPReservation[0]} BackToHome={"/IPHome/Reservation"} /> : <Redirect to="/" />} />


        {/* IP Home edit demograpics Routers ===== */}
        <Route exact path='/IPHome/Demographics/Demographics' render={props => isAuthenticated ? <CommonTabs  {...props} List={IP_Patient_ViewTabs} selectedTab={IP_Patient_ViewTabs[0]} BackToHome={"/IPHome"} /> : <Redirect to="/" />} />
        <Route exact path='/IPHome/Demographics/AdmissionHistory' render={props => isAuthenticated ? <CommonTabs  {...props} List={IP_Patient_ViewTabs} selectedTab={IP_Patient_ViewTabs[1]} BackToHome={"/IPHome"} /> : <Redirect to="/" />} />

        {/* IP Home edit demograpics Routers ===== */}
        <Route exact path='/IPHome/Billing/BillingDetails' render={props => isAuthenticated ? <CommonTabs  {...props} List={IP_Patient_viewBilling_Tabs} selectedTab={IP_Patient_viewBilling_Tabs[0]} BackToHome={"/IPHome/Demographics/AdmissionHistory"} /> : <Redirect to="/" />} />
        <Route exact path='/IPHome/Billing/ClinicalNotes' render={props => isAuthenticated ? <CommonTabs  {...props} List={IP_Patient_viewBilling_Tabs} selectedTab={IP_Patient_viewBilling_Tabs[1]} BackToHome={"/IPHome/Demographics/AdmissionHistory"} /> : <Redirect to="/" />} />
        <Route exact path='/IPHome/Billing/Medication' render={props => isAuthenticated ? <CommonTabs  {...props} List={IP_Patient_viewBilling_Tabs} selectedTab={IP_Patient_viewBilling_Tabs[2]} BackToHome={"/IPHome/Demographics/AdmissionHistory"} /> : <Redirect to="/" />} />
        <Route exact path='/IPHome/Billing/Summary' render={props => isAuthenticated ? <CommonTabs  {...props} List={IP_Patient_viewBilling_Tabs} selectedTab={IP_Patient_viewBilling_Tabs[3]} BackToHome={"/IPHome/Demographics/AdmissionHistory"} /> : <Redirect to="/" />} />

        {/* IP Reservation Routers */}
        <Route exact path='/IPHome/Reservation/Demographics' render={props => isAuthenticated ? <CommonTabs  {...props} List={Reservation_Tabs} selectedTab={Reservation_Tabs[0]} BackToHome={"/IPHome/Reservation"} /> : <Redirect to="/" />} />
        <Route exact path='/IPHome/Reservation/ReceivePayment' render={props => isAuthenticated ? <CommonTabs  {...props} List={Reservation_Tabs} selectedTab={Reservation_Tabs[1]} BackToHome={"/IPHome/Reservation"} /> : <Redirect to="/" />} />
       
        {/* Pharmacy Router Path =====> STARTS */}

        {/* Home */}
        <Route exact path="/PharmacyBilling" render={props => isAuthenticated ? <PharmaHome {...props} history={history} getPharmaSavedBillCount={getPharmaSavedBillCount} getPharmaPrescriptionCount={getPharmaPrescriptionCount} /> : <Redirect to="/" />} />
        <Route exact path="/PharmacyBilling/Return" render={props => isAuthenticated ? <PharmaHome {...props} history={history} MultipleReturnScreen={true} getPharmaSavedBillCount={getPharmaSavedBillCount} getPharmaPrescriptionCount={getPharmaPrescriptionCount} /> : <Redirect to="/" />} />

        {/* <Route exact path='/Pharmacy/Billing' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Billing} selectedTab={Pharamacy_Billing[0]} /> : <Redirect to='/' />} /> */}
        {/* Invoice */}
        <Route exact path='/PharmacyInvoice' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Invoice} selectedTab={Pharamacy_Invoice[0]} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyInvoice/Invoice' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Invoice} selectedTab={Pharamacy_Invoice[0]} /> : <Redirect to='/' />} />
        {UserData?.pharmacy_type === 'Inhouse Pharmacy' ? <Route exact path='/PharmacyInvoice/CreditBills' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Invoice} selectedTab={Pharamacy_Invoice[1]} /> : <Redirect to='/' />} /> : null}
        {UserData?.pharmacy_type === 'Inhouse Pharmacy' ? <Route exact path='/PharmacyInvoice/CreditBills/Bills' render={props => isAuthenticated ? <PharmaCreditBillsSplit {...props} history={history} /> : <Redirect to='/' />} /> : null}
        {UserData?.pharmacy_type === 'Inhouse Pharmacy' ? <Route exact path='/PharmacyInvoice/SavedBills' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Invoice} selectedTab={Pharamacy_Invoice[2]} /> : <Redirect to='/' />} /> :
          UserData?.pharmacy_type === 'Standalone Pharmacy' ? <Route exact path='/PharmacyInvoice/SavedBills' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Invoice} selectedTab={Pharamacy_Invoice[1]} /> : <Redirect to='/' />} /> : null}
        {UserData?.pharmacy_type === 'Inhouse Pharmacy' ? <Route exact path='/PharmacyInvoice/ReceiptBills' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Invoice} selectedTab={Pharamacy_Invoice[3]} /> : <Redirect to='/' />} /> :
          UserData?.pharmacy_type === 'Standalone Pharmacy' ? <Route exact path='/PharmacyInvoice/ReceiptBills' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Invoice} selectedTab={Pharamacy_Invoice[2]} /> : <Redirect to='/' />} /> : null}
        {UserData?.pharmacy_type === 'Inhouse Pharmacy' ? <Route exact path='/PharmacyInvoice/DayEndDenomination' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Invoice} selectedTab={Pharamacy_Invoice[4]} /> : <Redirect to='/' />} /> :
          UserData?.pharmacy_type === 'Standalone Pharmacy' ? <Route exact path='/PharmacyInvoice/DayEndDenomination' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Invoice} selectedTab={Pharamacy_Invoice[3]} /> : <Redirect to='/' />} /> : null}
        {/* Inventory */}
        <Route exact path='/PharmacyInventory' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Inventory} selectedTab={Pharamacy_Inventory[0]} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyInventory/StockList' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Inventory} selectedTab={Pharamacy_Inventory[0]} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyInventory/StockList/AddNewDrug' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_StockList} selectedTab={Pharamacy_StockList[0]} BackToHome={"/PharmacyInventory/DrugList"} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyInventory/StockList/Request' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_StockList} selectedTab={Pharamacy_StockList[1]} BackToHome={"/PharmacyInventory/DrugList"} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyInventory/StockList/StockReturn' render={props => isAuthenticated ? <PharmaStockReturn {...props} history={history} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyInventory/DrugList' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Inventory} selectedTab={Pharamacy_Inventory[1]} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyInventory/InactiveDrugList' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Inventory} selectedTab={Pharamacy_Inventory[2]} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyInventory/StockCorrection' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Inventory} selectedTab={Pharamacy_Inventory[3]} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyInventory/NewDrugApproval' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Inventory} selectedTab={Pharamacy_Inventory[4]} /> : <Redirect to='/' />} />
        {/* Purchase */}
        <Route exact path='/PharmacyPurchase' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Purchase} selectedTab={Pharamacy_Purchase[0]} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyPurchase/WantedList' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Purchase} selectedTab={Pharamacy_Purchase?.find((list) => list.id === 14.10)} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyPurchase/WantedList/OrderList' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_OrderList} selectedTab={Pharamacy_OrderList[0]} BackToHome={"/PharmacyPurchase/WantedList"} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyPurchase/WantedList/ConfirmOrderList' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_OrderList} selectedTab={Pharamacy_OrderList[1]} BackToHome={"/PharmacyPurchase/WantedList"} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyPurchase/PurchaseOrder' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Purchase} selectedTab={Pharamacy_Purchase[1]} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyPurchase/PurchaseRequest' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Purchase} selectedTab={Pharamacy_Purchase[0]} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyPurchase/DeliveryChallan' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Purchase} selectedTab={Pharamacy_Purchase[2]} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyPurchase/DeliveryChallan/Create' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_dc_to_po_create} selectedTab={Pharamacy_dc_to_po_create[0]} BackToHome={"/PharmacyPurchase/DeliveryChallan"} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyPurchase/DeliveryChallan/MoveToPO' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_dc_to_po} selectedTab={Pharamacy_dc_to_po[0]} BackToHome={"/PharmacyPurchase/DeliveryChallan"} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyPurchase/VendorPayment' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Purchase} selectedTab={Pharamacy_Purchase[3]} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyPurchase/VendorPayment/VendorMonthly' render={props => isAuthenticated ? <PharmaVendorMonthly {...props} history={history} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyPurchase/VendorList' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Purchase} selectedTab={Pharamacy_Purchase[4]} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyPurchase/PurchaseOrder/DirectPO' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_directpo} selectedTab={Pharamacy_directpo[0]} BackToHome={"/PharmacyPurchase/PurchaseOrder"} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyPurchase/VendorList/AddVendor' render={props => isAuthenticated ? <PharmaAddvendor {...props} history={history} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyPurchase/PurchaseReturn' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Purchase} selectedTab={Pharamacy_Purchase[5]} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyPurchase/PurchaseRejection/New' render={props => isAuthenticated ? <PharmaRejection {...props} selectedTab={Pharmacy_Purchase_Return[0]} /> : <Redirect to='/' />} />
        <Route exact path="/PharmacyPurchase/PurchaseOrder/ReturnPO" render={props => isAuthenticated ? <PharmaPOReturn {...props} history={history} /> : <Redirect to="/" />} />
        <Route exact path='/PharmacyPurchase/CreditNote/NewCreditNote' render={props => isAuthenticated ? <PharmaCreditNote {...props} selectedTab={Pharmacy_Credit_Note[0]} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyPurchase/PurchaseOrder/POrequest' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharma_PO_Request} selectedTab={Pharma_PO_Request[0]} BackToHome={"/PharmacyPurchase"} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyPurchase/PurchaseOrder/RequestList' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharma_PO_Request} selectedTab={Pharma_PO_Request[1]} BackToHome={"/PharmacyPurchase"} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyPurchase/PurchaseRejection' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Purchase} selectedTab={Pharamacy_Purchase[6]} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyPurchase/CreditNote' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Purchase} selectedTab={Pharamacy_Purchase[7]} /> : <Redirect to='/' />} />

        {/* Report */}
        <Route exact path='/PharmacyReports' render={props => isAuthenticated ? <PharmaReportHome {...props} history={history} BackToHome={"/PharmacyReports"} /> : <Redirect to="/" />} />
        <Route exact path='/PharmacyReports/SalesOverview' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Report.Sales_reports} selectedTab={Pharamacy_Report.Sales_reports[0]} BackToHome={"/PharmacyReports"} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyReports/SalesDetailed' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Report.Sales_reports} selectedTab={Pharamacy_Report.Sales_reports[1]} BackToHome={"/PharmacyReports"} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyReports/SalesReturn' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Report.Sales_reports} selectedTab={Pharamacy_Report.Sales_reports[2]} BackToHome={"/PharmacyReports"} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyReports/SalesDayEnd' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Report.Sales_reports} selectedTab={Pharamacy_Report.Sales_reports[3]} BackToHome={"/PharmacyReports"} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyReports/SalesMonthly' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Report.Sales_reports} selectedTab={Pharamacy_Report.Sales_reports[4]} BackToHome={"/PharmacyReports"} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyReports/ProductWiseSales' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Report.Sales_reports} selectedTab={Pharamacy_Report.Sales_reports[5]} BackToHome={"/PharmacyReports"} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyReports/HsnWise' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Report.Sales_reports} selectedTab={Pharamacy_Report.Sales_reports[6]} BackToHome={"/PharmacyReports"} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyReports/TaxWise' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Report.Sales_reports} selectedTab={Pharamacy_Report.Sales_reports[7]} BackToHome={"/PharmacyReports"} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyReports/ReturnHSNWise' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Report.Sales_reports} selectedTab={Pharamacy_Report.Sales_reports[8]} BackToHome={"/PharmacyReports"} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyReports/ReturnTaxWise' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Report.Sales_reports} selectedTab={Pharamacy_Report.Sales_reports[9]} BackToHome={"/PharmacyReports"} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyReports/PrescriptionRegister' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Report.Sales_reports} selectedTab={Pharamacy_Report.Sales_reports[10]} BackToHome={"/PharmacyReports"} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyReports/ProfitAnalysis' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Report.Sales_reports} selectedTab={Pharamacy_Report.Sales_reports[11]} BackToHome={"/PharmacyReports"} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyReports/BillTaxWise' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Report.Sales_reports} selectedTab={Pharamacy_Report.Sales_reports[12]} BackToHome={"/PharmacyReports"} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyReports/UserSalesPerformance' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Report.Sales_reports} selectedTab={Pharamacy_Report.Sales_reports[13]} BackToHome={"/PharmacyReports"} /> : <Redirect to='/' />} />

        <Route exact path='/PharmacyReports/PurchaseOverview' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Report.Purchase_Reports} selectedTab={Pharamacy_Report.Purchase_Reports[0]} BackToHome={"/PharmacyReports"} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyReports/PurchsaeDetailed' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Report.Purchase_Reports} selectedTab={Pharamacy_Report.Purchase_Reports[1]} BackToHome={"/PharmacyReports"} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyReports/PurchaseGST' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Report.Purchase_Reports} selectedTab={Pharamacy_Report.Purchase_Reports[2]} BackToHome={"/PharmacyReports"} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyReports/PurchaseReturnOverview' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Report.Purchase_Reports} selectedTab={Pharamacy_Report.Purchase_Reports[3]} BackToHome={"/PharmacyReports"} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyReports/PurchaseReturnDetailed' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Report.Purchase_Reports} selectedTab={Pharamacy_Report.Purchase_Reports[4]} BackToHome={"/PharmacyReports"} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyReports/PurchaseMonthly' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Report.Purchase_Reports} selectedTab={Pharamacy_Report.Purchase_Reports[5]} BackToHome={"/PharmacyReports"} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyReports/ConsolidatedSales' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Report.Purchase_Reports} selectedTab={Pharamacy_Report.Purchase_Reports[6]} BackToHome={"/PharmacyReports"} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyReports/VendorBill' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Report.Purchase_Reports} selectedTab={Pharamacy_Report.Purchase_Reports[7]} BackToHome={"/PharmacyReports"} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyReports/PurchaseHSNWise' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Report.Purchase_Reports} selectedTab={Pharamacy_Report.Purchase_Reports[8]} BackToHome={"/PharmacyReports"} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyReports/PurchaseTaxWise' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Report.Purchase_Reports} selectedTab={Pharamacy_Report.Purchase_Reports[9]} BackToHome={"/PharmacyReports"} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyReports/PurchaseReturnHSNWise' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Report.Purchase_Reports} selectedTab={Pharamacy_Report.Purchase_Reports[10]} BackToHome={"/PharmacyReports"} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyReports/ReturnVendorWise' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Report.Purchase_Reports} selectedTab={Pharamacy_Report.Purchase_Reports[11]} BackToHome={"/PharmacyReports"} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyReports/PurchaseReturnTaxWise' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Report.Purchase_Reports} selectedTab={Pharamacy_Report.Purchase_Reports[12]} BackToHome={"/PharmacyReports"} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyReports/PurchaseWiseEnquiry' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Report.Purchase_Reports} selectedTab={Pharamacy_Report.Purchase_Reports[13]} BackToHome={"/PharmacyReports"} /> : <Redirect to='/' />} />

        <Route exact path='/PharmacyReports/PurchaseRejection' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Report.Purchase_Reports} selectedTab={Pharamacy_Report.Purchase_Reports[14]} BackToHome={"/PharmacyReports"} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyReports/CreditNoteReport' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Report.Purchase_Reports} selectedTab={Pharamacy_Report.Purchase_Reports[15]} BackToHome={"/PharmacyReports"} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyReports/PurchaseBillTaxWise' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Report.Purchase_Reports} selectedTab={Pharamacy_Report.Purchase_Reports[16]} BackToHome={"/PharmacyReports"} /> : <Redirect to='/' />} />

        <Route exact path='/PharmacyReports/StockOverview' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Report.Inventory_Reports} selectedTab={Pharamacy_Report.Inventory_Reports[0]} BackToHome={"/PharmacyReports"} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyReports/MISReport' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Report.Inventory_Reports} selectedTab={Pharamacy_Report.Inventory_Reports[1]} BackToHome={"/PharmacyReports"} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyReports/ExpiryStock' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Report.Inventory_Reports} selectedTab={Pharamacy_Report.Inventory_Reports[2]} BackToHome={"/PharmacyReports"} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyReports/LowStockDrugs' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Report.Inventory_Reports} selectedTab={Pharamacy_Report.Inventory_Reports[3]} BackToHome={"/PharmacyReports"} /> : <Redirect to='/' />} />
        {/* <Route exact path='/PharmacyReports/SlowMovementDrugs' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Report.Inventory_Reports} selectedTab={Pharamacy_Report.Inventory_Reports[4]} BackToHome={"/PharmacyReports"} /> : <Redirect to='/' />} /> */}
        <Route exact path='/PharmacyReports/DrugMovementReport' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Report.Inventory_Reports} selectedTab={Pharamacy_Report.Inventory_Reports[4]} BackToHome={"/PharmacyReports"} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyReports/StockCorrectionLog' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Report.Inventory_Reports} selectedTab={Pharamacy_Report.Inventory_Reports[5]} BackToHome={"/PharmacyReports"} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyReports/StockValueReport' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Report.Inventory_Reports} selectedTab={Pharamacy_Report.Inventory_Reports[6]} BackToHome={"/PharmacyReports"} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyReports/StockAndProfitValue' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Report.Inventory_Reports} selectedTab={Pharamacy_Report.Inventory_Reports[7]} BackToHome={"/PharmacyReports"} /> : <Redirect to='/' />} />

        <Route exact path='/PharmacyReports/RefDoctorList' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Report.standalone_Report} selectedTab={Pharamacy_Report.standalone_Report[0]} BackToHome={"/PharmacyReports"} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyReports/PatientReport' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Report.standalone_Report} selectedTab={Pharamacy_Report.standalone_Report[1]} BackToHome={"/PharmacyReports"} /> : <Redirect to='/' />} />

        <Route exact path='/PharmacyReports/DayWiseSalesGST' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Report.GST_Reports} selectedTab={Pharamacy_Report.GST_Reports[0]} BackToHome={"/PharmacyReports"} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyReports/DayWiseReturnGST' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Report.GST_Reports} selectedTab={Pharamacy_Report.GST_Reports[1]} BackToHome={"/PharmacyReports"} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyReports/HSNwiseSalesGST' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Report.GST_Reports} selectedTab={Pharamacy_Report.GST_Reports[2]} BackToHome={"/PharmacyReports"} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyReports/HSNwiseReturnGST' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Report.GST_Reports} selectedTab={Pharamacy_Report.GST_Reports[3]} BackToHome={"/PharmacyReports"} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyReports/DayWiseGSTSummary' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Report.GST_Reports} selectedTab={Pharamacy_Report.GST_Reports[4]} BackToHome={"/PharmacyReports"} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacyReports/HSNWiseSummaryGST' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Report.GST_Reports} selectedTab={Pharamacy_Report.GST_Reports[5]} BackToHome={"/PharmacyReports"} /> : <Redirect to='/' />} />

        {/* Setting */}
        <Route exact path='/PharmacySetting' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Setting.settings} selectedTab={Pharamacy_Setting.settings[0]} /> : <Redirect to='/' />} />
        {/* <Route exact path='/PharmacySetting/StockCorrection' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Setting.settings} selectedTab={Pharamacy_Setting.settings[0]} /> : <Redirect to='/' />} /> */}
        <Route exact path='/PharmacySetting/PrintConfig' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Setting.settings} selectedTab={Pharamacy_Setting.settings[0]} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacySetting/Settings' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Setting.settings} selectedTab={Pharamacy_Setting.settings[1]} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacySetting/GenericName' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Setting.settings} selectedTab={Pharamacy_Setting.settings[2]} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacySetting/UserConfiguration' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Setting.settings} selectedTab={Pharamacy_Setting.settings[3]} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacySetting/UserConfiguration/New' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Setting.user_new_setting} selectedTab={Pharamacy_Setting.user_new_setting[0]} BackToHome={"/PharmacySetting/UserConfiguration"} /> : <Redirect to='/' />} />
        {UserData?.pharmacy_type === 'Inhouse Pharmacy' ? null : <Route exact path='/PharmacySetting/ReferralDoctor' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Setting.settings} selectedTab={Pharamacy_Setting.settings[4]} /> : <Redirect to='/' />} />}
        <Route exact path='/PharmacySetting/SubLocation' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Setting.settings} selectedTab={Pharamacy_Setting.settings[4]} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacySetting/PharmaUserAccess' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Setting.settings} selectedTab={Pharamacy_Setting.settings[5]} /> : <Redirect to='/' />} />
        <Route exact path='/PharmacySetting/PharmaUserSettings' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharamacy_Setting.userSettings} selectedTab={Pharamacy_Setting.userSettings[0]} BackToHome={"/PharmacySetting/PharmaUserAccess"} /> : <Redirect to='/' />} />

        {/* Stock Transfer */}
        {UserData?.branch_to_branch_stock_transfer ? <Route exact path='/PharmacyStockTransfer' render={props => isAuthenticated ? <StockHome {...props} history={history} /> : <Redirect to="/" />} /> : 
        <Route exact path='/PharmacyStockTransfer' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharmacy_Stock_Transfer} selectedTab={Pharmacy_Stock_Transfer[0]} /> : <Redirect to='/' />} />}
        <Route exact path='/PharmacyStockTransfer/Inbound' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharmacy_Stock_Transfer} selectedTab={Pharmacy_Stock_Transfer[0]} BackToHome={"/PharmacyStockTransfer"}/> : <Redirect to='/' />} />
        <Route exact path='/PharmacyStockTransfer/Outbound' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharmacy_Stock_Transfer} selectedTab={Pharmacy_Stock_Transfer[1]} BackToHome={"/PharmacyStockTransfer"}/> : <Redirect to='/' />} />
        <Route exact path='/PharmacyStockTransfer/StockTransferReport' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharmacy_Stock_Transfer} selectedTab={Pharmacy_Stock_Transfer[2]} BackToHome={"/PharmacyStockTransfer"}/> : <Redirect to='/' />} />
        <Route exact path='/PharmacyStockTransfer/PharmaSubStockList' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharmacy_Stock_Transfer} selectedTab={Pharmacy_Stock_Transfer[3]} BackToHome={"/PharmacyStockTransfer"}/> : <Redirect to='/' />} />
        <Route exact path='/PharmacyStockTransfer/PharmaDrugWiseStockTransfer' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharmacy_Stock_Transfer} selectedTab={Pharmacy_Stock_Transfer[4]} BackToHome={"/PharmacyStockTransfer"}/> : <Redirect to='/' />} />


        {/* Pharmacy Router Path =====> ENDS */}

        {/* Pharmacy Sub Location Routing ======> Starts */}
        <Route exact path="/SubLocationBilling" render={props => isAuthenticated ? <CommonTabs {...props} List={SubLocation_Home} selectedTab={SubLocation_Home[0]} /> : <Redirect to="/" />} />
        <Route exact path="/SubLocationBilling/GenericConsumption" render={props => isAuthenticated ? <CommonTabs {...props} List={SubLocation_Home} selectedTab={SubLocation_Home[1]} /> : <Redirect to="/" />} />

        <Route exact path="/SubLocationReports" render={props => isAuthenticated ? <CommonTabs {...props} List={SubLocation_Reports_Tab} selectedTab={SubLocation_Reports_Tab[0]} /> : <Redirect to='/' />} />
        <Route exact path="/SubLocationReports/ShortExpiry" render={props => isAuthenticated ? <CommonTabs {...props} List={SubLocation_Reports_Tab} selectedTab={SubLocation_Reports_Tab[1]} /> : <Redirect to='/' />} />
        <Route exact path="/SubLocationReports/CreditReport" render={props => isAuthenticated ? <CommonTabs {...props} List={SubLocation_Reports_Tab} selectedTab={SubLocation_Reports_Tab[2]} /> : <Redirect to='/' />} />
        <Route exact path="/SubLocationReports/ConsumptionReport" render={props => isAuthenticated ? <CommonTabs {...props} List={SubLocation_Reports_Tab} selectedTab={SubLocation_Reports_Tab[3]} /> : <Redirect to='/' />} />
        <Route exact path="/SubLocationReports/ExpiryStock" render={props => isAuthenticated ? <CommonTabs {...props} List={SubLocation_Reports_Tab} selectedTab={SubLocation_Reports_Tab[4]} /> : <Redirect to='/' />} />

        <Route exact path='/SubLocationStockTransfer' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharmacy_Sub_Loc_Stock_Transfer} selectedTab={Pharmacy_Sub_Loc_Stock_Transfer[0]} /> : <Redirect to='/' />} />
        <Route exact path='/SubLocationStockTransfer/WantedList' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharmacy_Sub_Loc_Stock_Transfer} selectedTab={Pharmacy_Sub_Loc_Stock_Transfer[0]} /> : <Redirect to='/' />} />
        <Route exact path='/SubLocationStockTransfer/Inbound' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharmacy_Sub_Loc_Stock_Transfer} selectedTab={Pharmacy_Sub_Loc_Stock_Transfer[1]} /> : <Redirect to='/' />} />
        <Route exact path='/SubLocationStockTransfer/Outbound' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharmacy_Sub_Loc_Stock_Transfer} selectedTab={Pharmacy_Sub_Loc_Stock_Transfer[2]} /> : <Redirect to='/' />} />
        <Route exact path='/SubLocationStockTransfer/ReturnRequest' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharmacy_Sub_Loc_Stock_Transfer} selectedTab={Pharmacy_Sub_Loc_Stock_Transfer[3]} /> : <Redirect to='/' />} />
        <Route exact path='/SubLocationStockTransfer/StockTransferReport' render={props => isAuthenticated ? <CommonTabs {...props} List={Pharmacy_Sub_Loc_Stock_Transfer} selectedTab={Pharmacy_Sub_Loc_Stock_Transfer[4]} /> : <Redirect to='/' />} />
        <Route exact path="/SubLocationStockTransfer/RaiseRequest" render={props => isAuthenticated ? <SubLocRaiseReq {...props} history={history} /> : <Redirect to="/" />} />

        {/* Pharmacy Sub Location Routing ======> Ends */}

        {/* Lab Routers ===== */}
        <Route exact path="/MainHome" render={props => isAuthenticated ? <MainHome {...props} changeScreen={changeScreen} subScreenChange={subScreenChange} isSearchClear={isSearchClear} clinic_id={clinic_id} getSavedBillStatus={getSavedBillStatus} getTransmitBillCount={getTransmitBillCount} getIPListCount={getIPListCount} /> : <Redirect to="/" />} />
        <Route exact path="/LabHome" render={props => isAuthenticated ? <LabHome {...props} /> : <Redirect to="/" getSavedBillStatus={getSavedBillStatus} getTransmitBillCount={getTransmitBillCount} />} />
        <Route exact path="/Inventory" render={props => isAuthenticated ? <Inventory {...props} inventorySearch={inventorySearch} clrSearch={clearConfigSearch} changeScreen={changeScreen} subScreenChange={subScreenChange} /> : <Redirect to="/" />} />
        <Route exact path="/Purchase" render={props => isAuthenticated ? <LabPurchase {...props} search={purchaseSearchKey} changeScreen={changeScreen} clrSearch={clearConfigSearch} subScreenChange={subScreenChange} /> : <Redirect to="/" />} />
        {/* <Route exact path="/Config" render={props => isAuthenticated ? <LabConfig {...props} search={configSearchKey} changeScreen={changeScreen} clrSearch={clearConfigSearch} subScreenChange={subScreenChange} /> : <Redirect to="/" />} /> */}
        <Route exact path="/SavedBill" render={props => isAuthenticated ? <Saved_Bill {...props} /> : <Redirect to="/" />} />
        <Route exact path="/Transmit" render={props => isAuthenticated ? <Transmit {...props} /> : <Redirect to="/" getTransmitBillCount={getTransmitBillCount} />} />
        <Route exact path="/Status" render={props => isAuthenticated ? <Status {...props} /> : <Redirect to="/" />} />
        <Route exact path='/Reports' render={props => isAuthenticated ? <Reports {...props} reportSearch={reportSearch} clrSearch={clearConfigSearch} changeScreen={changeScreen} subScreenChange={subScreenChange} /> : <Redirect to='/' />} />
        <Route exact path='/TestReport' render={props => isAuthenticated ? <TestReport {...props} /> : <Redirect to='/' />} />
        <Route exact path='/Results' render={props => isAuthenticated ? <Result {...props} /> : <Redirect to="/" />} />
        <Route exact path='/LabIpList' render={props => isAuthenticated ? <LabIPList {...props} /> : <Redirect to="/" getIPListCount={getIPListCount} />} />

        <Route exact path='/LabReports' render={props => isAuthenticated ? <LabReportsHome {...props} history={history} /> : <Redirect to="/" />} />
        <Route exact path='/LabReports/FinancialReport/BillReport' render={props => isAuthenticated ? <CommonTabs {...props} List={LabReportsTabs.financial_reports} selectedTab={LabReportsTabs.financial_reports[0]} BackToHome={"/LabReports"} /> : <Redirect to="/" />} />
        <Route exact path='/LabReports/FinancialReport/LabReceiptBills' render={props => isAuthenticated ? <CommonTabs {...props} List={LabReportsTabs.financial_reports} selectedTab={LabReportsTabs.financial_reports[1]} BackToHome={"/LabReports"} /> : <Redirect to="/" />} />
        <Route exact path='/LabReports/FinancialReport/LabCensusReport' render={props => isAuthenticated ? <CommonTabs {...props} List={LabReportsTabs.financial_reports} selectedTab={LabReportsTabs.financial_reports[2]} BackToHome={"/LabReports"} /> : <Redirect to="/" />} />
        <Route exact path='/LabReports/FinancialReport/LabDayEndReport' render={props => isAuthenticated ? <CommonTabs {...props} List={LabReportsTabs.financial_reports} selectedTab={LabReportsTabs.financial_reports[3]} BackToHome={"/LabReports"} /> : <Redirect to="/" />} />
        <Route exact path='/LabReports/FinancialReport/CorporateBillReport' render={props => isAuthenticated ? <CommonTabs {...props} List={LabReportsTabs.financial_reports} selectedTab={LabReportsTabs.financial_reports[4]} BackToHome={"/LabReports"} /> : <Redirect to="/" />} />
        <Route exact path='/LabReports/FinancialReport/LabShareReport' render={props => isAuthenticated ? <CommonTabs {...props} List={LabReportsTabs.financial_reports} selectedTab={LabReportsTabs.financial_reports[5]} BackToHome={"/LabReports"} /> : <Redirect to="/" />} />
        <Route exact path='/LabReports/FinancialReport/TestCensusReport' render={props => isAuthenticated ? <CommonTabs {...props} List={LabReportsTabs.financial_reports} selectedTab={LabReportsTabs.financial_reports[6]} BackToHome={"/LabReports"} /> : <Redirect to="/" />} />
        <Route exact path='/LabReports/PatientReport/LabPatientReport' render={props => isAuthenticated ? <CommonTabs {...props} List={LabReportsTabs.patient_reports} selectedTab={LabReportsTabs.patient_reports[0]} BackToHome={"/LabReports"} /> : <Redirect to="/" />} />
        <Route exact path='/LabReports/PatientReport/LabResultCensusReport' render={props => isAuthenticated ? <CommonTabs {...props} List={LabReportsTabs.patient_reports} selectedTab={LabReportsTabs.patient_reports[1]} BackToHome={"/LabReports"} /> : <Redirect to="/" />} />
        <Route exact path='/LabReports/PatientReport/IpAdmissionReport' render={props => isAuthenticated ? <CommonTabs {...props} List={LabReportsTabs.patient_reports} selectedTab={LabReportsTabs.patient_reports[2]} BackToHome={"/LabReports"} /> : <Redirect to="/" />} />
        <Route exact path='/LabReports/BusinessReport/DoctorReport' render={props => isAuthenticated ? <CommonTabs {...props} List={LabReportsTabs.business_reports} selectedTab={LabReportsTabs.business_reports[0]} BackToHome={"/LabReports"} /> : <Redirect to="/" />} />
        <Route exact path='/LabReports/BusinessReport/OutSourceReport' render={props => isAuthenticated ? <CommonTabs {...props} List={LabReportsTabs.business_reports} selectedTab={LabReportsTabs.business_reports[1]} BackToHome={"/LabReports"} /> : <Redirect to="/" />} />
        <Route exact path='/LabReports/BusinessReport/GroupwiseReport' render={props => isAuthenticated ? <CommonTabs {...props} List={LabReportsTabs.business_reports} selectedTab={LabReportsTabs.business_reports[2]} BackToHome={"/LabReports"} /> : <Redirect to="/" />} />
        <Route exact path='/LabReports/BusinessReport/GroupwiseSummaryReport' render={props => isAuthenticated ? <CommonTabs {...props} List={LabReportsTabs.business_reports} selectedTab={LabReportsTabs.business_reports[3]} BackToHome={"/LabReports"} /> : <Redirect to="/" />} />
        <Route exact path='/LabReports/BusinessReport/TestwiseReport' render={props => isAuthenticated ? <CommonTabs {...props} List={LabReportsTabs.business_reports} selectedTab={LabReportsTabs.business_reports[4]} BackToHome={"/LabReports"} /> : <Redirect to="/" />} />
        <Route exact path='/LabReports/BusinessReport/TestwiseSummaryReport' render={props => isAuthenticated ? <CommonTabs {...props} List={LabReportsTabs.business_reports} selectedTab={LabReportsTabs.business_reports[5]} BackToHome={"/LabReports"} /> : <Redirect to="/" />} />
        <Route exact path='/LabReports/BusinessReport/PackageWiseReport' render={props => isAuthenticated ? <CommonTabs {...props} List={LabReportsTabs.business_reports} selectedTab={LabReportsTabs.business_reports[6]} BackToHome={"/LabReports"} /> : <Redirect to="/" />} />
        <Route exact path='/LabReports/BusinessReport/TatReport' render={props => isAuthenticated ? <CommonTabs {...props} List={LabReportsTabs.business_reports} selectedTab={LabReportsTabs.business_reports[7]} BackToHome={"/LabReports"} /> : <Redirect to="/" />} />
        <Route exact path='/LabReports/BusinessReport/ServiceCensusReport' render={props => isAuthenticated ? <CommonTabs {...props} List={LabReportsTabs.business_reports} selectedTab={LabReportsTabs.business_reports[8]} BackToHome={"/LabReports"} /> : <Redirect to="/" />} />
        <Route exact path='/LabReports/FinancialReport/DayEndDenomination' render={props => isAuthenticated ? <CommonTabs {...props} List={LabReportsTabs.financial_reports} selectedTab={LabReportsTabs.financial_reports[7]} BackToHome={"/LabReports"}  /> : <Redirect to='/' />} />

        <Route exact path='/Counsellor' render={props => isAuthenticated ? <CounsellorHome history={history} {...props} /> : <Redirect to="/" />} />

        {/* OT Module */}

        <Route exact path='/OTHome' render={props => isAuthenticated ? <OThome history={history} {...props} Request={OT_Request} isGetCalled={isGetCalled}/> : <Redirect to="/" />} />
        <Route exact path='/OTHome/Demographics' render={props => isAuthenticated ? <CommonTabs  {...props} List={OT_Patient_Tabs} selectedTab={OT_Patient_Tabs[0]} BackToHome={isFO ? "/IPHome/OThome" : "/OTHome"} /> : <Redirect to="/" />} />
        <Route exact path='/OTHome/SurgeonDetails' render={props => isAuthenticated ? <CommonTabs  {...props} List={OT_Patient_Tabs} selectedTab={OT_Patient_Tabs[1]} BackToHome={isFO ? "/IPHome/OThome" : "/OTHome"} /> : <Redirect to="/" />} />
        <Route exact path='/OTHome/NurseNotes' render={props => isAuthenticated ? <CommonTabs  {...props} List={OT_Patient_Tabs} selectedTab={OT_Patient_Tabs[2]} BackToHome={isFO ? "/IPHome/OThome" : "/OTHome"} /> : <Redirect to="/" />} />
        <Route exact path='/OTHome/Reports' render={props => isAuthenticated ? <CommonTabs  {...props} List={OT_Patient_Tabs} selectedTab={OT_Patient_Tabs[3]} BackToHome={isFO ? "/IPHome/OThome" : "/OTHome"} /> : <Redirect to="/" />} />
        <Route exact path='/OTHome/SurgeryNotes' render={props => isAuthenticated ? <CommonTabs  {...props} List={OT_Patient_Tabs} selectedTab={OT_Patient_Tabs[4]} BackToHome={isFO ? "/IPHome/OThome" : "/OTHome"} /> : <Redirect to="/" />} />
        <Route exact path='/OTHome/Billing' render={props => isAuthenticated ? <CommonTabs  {...props} List={OT_Patient_Tabs} selectedTab={OT_Patient_Tabs[5]} BackToHome={isFO ? "/IPHome/OThome" : "/OTHome"} /> : <Redirect to="/" />} />

        <Route exact path='/OTStocks' render={props => isAuthenticated ? <CommonTabs  {...props} List={OT_Stocks} selectedTab={OT_Stocks[0]} /> : <Redirect to="/" />} />
        <Route exact path='/OTStocks/UsageTransaction' render={props => isAuthenticated ? <CommonTabs  {...props} List={OT_Stocks} selectedTab={OT_Stocks[1]} /> : <Redirect to="/" />} />

        <Route exact path='/OTInventory' render={props => isAuthenticated ? <CommonTabs  {...props} List={OT_Inventory} selectedTab={OT_Inventory[0]} /> : <Redirect to="/" />} />
        <Route exact path='/OTInventory/OTDrugs' render={props => isAuthenticated ? <CommonTabs  {...props} List={OT_Inventory} selectedTab={OT_Inventory[1]} /> : <Redirect to="/" />} />
        <Route exact path="/OTInventory/NewIOL" render={props => isAuthenticated ? <AddNewIOL {...props} history={history} /> : <Redirect to="/" />} />

        <Route exact path='/OTStockTransfer' render={props => isAuthenticated ? <CommonTabs  {...props} List={OT_Stock_Transfer} selectedTab={OT_Stock_Transfer[0]} /> : <Redirect to="/" />} />
        <Route exact path='/OTStockTransfer/Inbound' render={props => isAuthenticated ? <CommonTabs  {...props} List={OT_Stock_Transfer} selectedTab={OT_Stock_Transfer[0]} /> : <Redirect to="/" />} />
        <Route exact path='/OTStockTransfer/Outbound' render={props => isAuthenticated ? <CommonTabs  {...props} List={OT_Stock_Transfer} selectedTab={OT_Stock_Transfer[1]} /> : <Redirect to="/" />} />
        <Route exact path='/OTStockTransfer/ReturnRequest' render={props => isAuthenticated ? <CommonTabs  {...props} List={OT_Stock_Transfer} selectedTab={OT_Stock_Transfer[2]} /> : <Redirect to="/" />} />
        <Route exact path='/OTStockTransfer/OTTransferReport' render={props => isAuthenticated ? <CommonTabs  {...props} List={OT_Stock_Transfer} selectedTab={OT_Stock_Transfer[3]} /> : <Redirect to="/" />} />
        <Route exact path="/OTStockTransfer/RaiseRequest" render={props => isAuthenticated ? <SubLocRaiseReq {...props} history={history} fromOT={true} /> : <Redirect to="/" />} />

        <Route exact path='/OTReports' render={props => isAuthenticated ? <CommonTabs  {...props} List={OT_Report} selectedTab={OT_Report[0]} /> : <Redirect to="/" />} />
        <Route exact path='/OTReports/TATReport' render={props => isAuthenticated ? <CommonTabs  {...props} List={OT_Report} selectedTab={OT_Report[1]} /> : <Redirect to="/" />} />
        <Route exact path='/OTReports/OTCancelledReport' render={props => isAuthenticated ? <CommonTabs  {...props} List={OT_Report} selectedTab={OT_Report[2]} /> : <Redirect to="/" />} />

        <Route exact path='/OTSettings' render={props => isAuthenticated ? <CommonTabs  {...props} List={OT_Settings} selectedTab={OT_Settings[0]} /> : <Redirect to="/" />} />

        <Route exact path='/OptometryReports' render={props => isAuthenticated ? <CommonTabs  {...props} List={Opthometry_Report} selectedTab={Opthometry_Report[0]} /> : <Redirect to="/" />} />

        <Route exact path='/DoctorReports' render={props => isAuthenticated ? <CommonTabs  {...props} List={Doctor_Report} selectedTab={Doctor_Report[0]} /> : <Redirect to="/" />} />
        <Route exact path='/DoctorReports/OptTatReport' render={props => isAuthenticated ? <CommonTabs  {...props} List={Doctor_Report} selectedTab={Doctor_Report[1]} /> : <Redirect to="/" />} />

        {/* Canteen Module Routing */}
        <Route exact path='/CanteenHome' render={props => isAuthenticated ? <CommonTabs  {...props} List={Canteen_Home} selectedTab={Canteen_Home[0]} /> : <Redirect to="/" />} />

        <Route exact path='/CanteenBillingTransaction' render={props => isAuthenticated ? <CommonTabs  {...props} List={Canteen_Bill_Trans} selectedTab={Canteen_Bill_Trans[0]} /> : <Redirect to="/" />} />
        <Route exact path='/CanteenBillingTransaction/CreditBill' render={props => isAuthenticated ? <CommonTabs  {...props} List={Canteen_Bill_Trans} selectedTab={Canteen_Bill_Trans[1]} /> : <Redirect to="/" />} />
        <Route exact path='/CanteenBillingTransaction/CanceledBill' render={props => isAuthenticated ? <CommonTabs  {...props} List={Canteen_Bill_Trans} selectedTab={Canteen_Bill_Trans[2]} /> : <Redirect to="/" />} />
        <Route exact path='/CanteenBillingTransaction/ReceiptBill' render={props => isAuthenticated ? <CommonTabs  {...props} List={Canteen_Bill_Trans} selectedTab={Canteen_Bill_Trans[3]} /> : <Redirect to="/" />} />
        <Route exact path='/CanteenBillingTransaction/Dayend' render={props => isAuthenticated ? <CommonTabs  {...props} List={Canteen_Bill_Trans} selectedTab={Canteen_Bill_Trans[4]} /> : <Redirect to="/" />} />

        <Route exact path='/CanteenSettings' render={props => isAuthenticated ? <CommonTabs  {...props} List={Canteen_Settings} selectedTab={Canteen_Settings[0]} /> : <Redirect to="/" />} />

        {/* ASSET MANAGEMENT NEW ROUTER PATH */}

        <Route exact path='/AssetHome' render={props => isAuthenticated ? <CommonTabs  {...props} List={Asset_Home_Tabs} selectedTab={Asset_Home_Tabs[0]} /> : <Redirect to='/' />} />
        <Route exact path='/AssetHome/AssetTransfer' render={props => isAuthenticated ? <CommonTabs  {...props} List={Asset_Home_Tabs} selectedTab={Asset_Home_Tabs[1]} /> : <Redirect to="/" />} />
        <Route exact path='/AssetHome/AssetAudit' render={props => isAuthenticated ? <CommonTabs  {...props} List={Asset_Home_Tabs} selectedTab={Asset_Home_Tabs[2]} /> : <Redirect to="/" />} />
        <Route exact path='/AssetHome/NewAsset' render={props => isAuthenticated ? <AddNewAsset {...props} history={history} /> : <Redirect to="/" />} />
        <Route exact path='/AssetHome/NewAssetTransfer' render={props => isAuthenticated ? <AddAssetTransfer {...props} history={history} /> : <Redirect to="/" />} />

        <Route exact path='/AssetComplaints' render={props => isAuthenticated ? <AssetComplaints history={history} {...props} /> : <Redirect to="/" />} />
        <Route exact path="/AssetComplaints/NewComplaints" render={props => isAuthenticated ? <NewComplaints {...props} history={history} /> : <Redirect to="/" />} />

        <Route exact path='/AssetSettings' render={props => isAuthenticated ? <CommonTabs  {...props} List={Asset_Settings_Tabs} selectedTab={Asset_Settings_Tabs[0]} /> : <Redirect to="/" />} />
        <Route exact path='/AssetSettings/SubCategory' render={props => isAuthenticated ? <CommonTabs  {...props} List={Asset_Settings_Tabs} selectedTab={Asset_Settings_Tabs[1]} /> : <Redirect to="/" />} />
        <Route exact path='/AssetSettings/Block' render={props => isAuthenticated ? <CommonTabs  {...props} List={Asset_Settings_Tabs} selectedTab={Asset_Settings_Tabs[2]} /> : <Redirect to="/" />} />
        <Route exact path='/AssetSettings/Floor' render={props => isAuthenticated ? <CommonTabs  {...props} List={Asset_Settings_Tabs} selectedTab={Asset_Settings_Tabs[3]} /> : <Redirect to="/" />} />
        <Route exact path='/AssetSettings/SpotArea' render={props => isAuthenticated ? <CommonTabs  {...props} List={Asset_Settings_Tabs} selectedTab={Asset_Settings_Tabs[4]} /> : <Redirect to="/" />} />
        <Route exact path='/AssetSettings/Designation' render={props => isAuthenticated ? <CommonTabs  {...props} List={Asset_Settings_Tabs} selectedTab={Asset_Settings_Tabs[5]} /> : <Redirect to="/" />} />
        <Route exact path='/AssetSettings/Employee' render={props => isAuthenticated ? <CommonTabs  {...props} List={Asset_Settings_Tabs} selectedTab={Asset_Settings_Tabs[6]} /> : <Redirect to="/" />} />
        {/* Doctor Module Router Path Starts ====> */}

        <Route exact path="/DoctorHome" render={props => isAuthenticated ? <DoctorHome {...props} changeDate={unlockDate} CallPatientTrigger={CallPatientTrigger} ClearCalledTrigger={ClearCalledTrigger}/> : <Redirect to="/" />} />
        <Route exact path='/DoctorHome/DoctorNotes' render={props => isAuthenticated ? <CommonTabs  {...props} List={Doctor_Tabs} selectedTab={Doctor_Tabs[0]} BackToHome={'/DoctorHome'} /> : <Redirect to="/" />} />
        {/* <Route exact path='/DoctorHome/Billing' render={props => isAuthenticated ? <CommonTabs  {...props} List={Doctor_Tabs} selectedTab={isOPT ? null : Doctor_Tabs[1]} BackToHome={'/DoctorHome'} /> : <Redirect to="/" />} /> */}
        <Route exact path='/DoctorHome/FormsCertificates/ReferralNotes' render={props => isAuthenticated ? <CommonTabs  {...props} List={Doctor_Tabs} selectedTab={isOPT ? Doctor_Tabs[1] : null} BackToHome={'/DoctorHome'} /> : <Redirect to="/" />} />
        <Route exact path='/DoctorHome/FormsCertificates/DocRefferalNotesCommon' render={props => isAuthenticated ? <CommonTabs  {...props} List={Doctor_Tabs} selectedTab={isOPT ? Doctor_Tabs[2] : isOpthometry ? Doctor_Tabs[1] : Doctor_Tabs[1]} BackToHome={'/DoctorHome'} /> : <Redirect to="/" />} />
        <Route exact path='/DoctorHome/PatientDocuments' render={props => isAuthenticated ? <CommonTabs  {...props} List={Doctor_Tabs} selectedTab={isOPT ? Doctor_Tabs[2] : isOpthometry ? Doctor_Tabs[1] : Doctor_Tabs[2]} BackToHome={'/DoctorHome'} /> : <Redirect to="/" />} />
        <Route exact path='/DoctorHome/History/MedicalHistory' render={props => isAuthenticated ? <CommonTabs  {...props} List={Doctor_Tabs} selectedTab={isOPT ? Doctor_Tabs[3] : Doctor_Tabs[3]} BackToHome={'/DoctorHome'} /> : <Redirect to="/" />} />
        <Route exact path='/DoctorHome/Reports/DoctorVisit' render={props => isAuthenticated ? <CommonTabs  {...props} List={Doctor_Tabs} selectedTab={isOPT ? Doctor_Tabs[4] : isOpthometry ? (history?.location?.state?.fromView ? Doctor_Tabs[0] : Doctor_Tabs[2]) : Doctor_Tabs[4]} BackToHome={'/DoctorHome'} /> : <Redirect to="/" />} />
        <Route exact path='/DoctorHome/Charts/Hbac' render={props => isAuthenticated ? <CommonTabs  {...props} List={Doctor_Tabs} selectedTab={isOPT ? null : UserData?.service_type === 'GP' ? null : UserData?.service_type === 'PD' ? null : UserData?.service_type === 'ENT' ? null : Doctor_Tabs[5]} BackToHome={'/DoctorHome'} /> : <Redirect to="/" />} />
        <Route exact path='/DoctorHome/FootClinic/Branchical' render={props => isAuthenticated ? <CommonTabs  {...props} List={Doctor_Tabs} selectedTab={isOPT ? null : UserData?.service_type === 'GP' ? null : UserData?.service_type === 'PD' ? null : UserData?.service_type === 'ENT' ? null : Doctor_Tabs[6]} BackToHome={'/DoctorHome'} /> : <Redirect to="/" />} />
        <Route exact path='/DoctorHome/DocDevelopmentAsses' render={props => isAuthenticated ? <CommonTabs  {...props} List={Doctor_Tabs} selectedTab={UserData?.service_type === 'PD' ? Doctor_Tabs[5] : null} BackToHome={'/DoctorHome'} /> : <Redirect to="/" />} />
        <Route exact path='/DoctorHome/DocImmunization' render={props => isAuthenticated ? <CommonTabs  {...props} List={Doctor_Tabs} selectedTab={UserData?.service_type === 'PD' ? Doctor_Tabs[6] : null} BackToHome={'/DoctorHome'} /> : <Redirect to="/" />} />
        <Route exact path='/DoctorHome/DocGrowthChart' render={props => isAuthenticated ? <CommonTabs  {...props} List={Doctor_Tabs} selectedTab={UserData?.service_type === 'PD' ? Doctor_Tabs[7] : null} BackToHome={'/DoctorHome'} /> : <Redirect to="/" />} />
        <Route exact path='/DoctorHome/CollaborativeSummary/DoctorNotes' render={props => isAuthenticated ? <CommonTabs  {...props} List={Doctor_Tabs} selectedTab={isOPT ? Doctor_Tabs[5] : UserData?.service_type === 'GP' ? Doctor_Tabs[5] : UserData?.service_type === 'PD' ? Doctor_Tabs[8] : UserData?.service_type === 'OBGYN' ? Doctor_Tabs[6] : UserData?.service_type === 'ENT' ? Doctor_Tabs[5] : Doctor_Tabs[7]} BackToHome={'/DoctorHome'} /> : <Redirect to="/" />} />
        <Route exact path='/DoctorHome/Demographics' render={props => isAuthenticated ? <CommonTabs  {...props} List={Doctor_Tabs} selectedTab={isOPT ? Doctor_Tabs[6] : UserData?.service_type === 'GP' ? Doctor_Tabs[6] : UserData?.service_type === 'OBGYN' ? Doctor_Tabs[7] : UserData?.service_type === 'PD' ? Doctor_Tabs[9] : UserData?.service_type === 'ENT' ? Doctor_Tabs[6] : Doctor_Tabs[8]} BackToHome={'/DoctorHome'} /> : <Redirect to="/" />} />
        <Route exact path='/DoctorHome/Doctor/Demographics' render={props => isAuthenticated ? <CommonTabs {...props} List={Doctor_Demographics} selectedTab={Doctor_Demographics[0]} BackToHome={'/DoctorHome'} /> : <Redirect to='/' />} />
        {PatientData?.is_ip_patient ? <Route exact path='/DoctorHome/DischargeSummary' render={props => isAuthenticated ? <CommonTabs  {...props} List={Doctor_Tabs} selectedTab={Doctor_Tabs[Doctor_Tabs.length - 1]} BackToHome={'/DoctorHome'} /> : <Redirect to="/" />} /> : null}
        <Route exact path='/DoctorHome/Charts/Ovulation' render={props => isAuthenticated ? <CommonTabs  {...props} List={Doctor_Tabs} selectedTab={isOPT ? null : UserData?.service_type === 'GP' ? null : UserData?.service_type === 'PD' ? null : Doctor_Tabs[5]} BackToHome={'/DoctorHome'} /> : <Redirect to="/" />} />
        <Route exact path='/DoctorHome/Charts/Antenatal' render={props => isAuthenticated ? <CommonTabs  {...props} List={Doctor_Tabs} selectedTab={isOPT ? null : UserData?.service_type === 'GP' ? null : UserData?.service_type === 'PD' ? null : Doctor_Tabs[5]} BackToHome={'/DoctorHome'} /> : <Redirect to="/" />} />

        <Route exact path='/DoctorHome/Demographics/update' render={props => isAuthenticated ? <CommonTabs {...props} List={Demographics_Update} selectedTab={Demographics_Update[0]} BackToHome={'/DoctorHome'} /> : <Redirect to='/' />} />
        <Route exact path='/Reports/DoctorVisitReport' render={props => isAuthenticated ? <CommonTabs  {...props} List={Demographics_Update} selectedTab={Demographics_Update[1]} BackToHome={'/DoctorHome'} /> : <Redirect to="/" />} />
        <Route exact path='/DoctorNutritionChart' render={props => isAuthenticated ? <CommonTabs {...props} List={Doc_Nutrition_Chart} selectedTab={Doc_Nutrition_Chart[0]} BackToHome={'/DoctorHome'} /> : <Redirect to='/' />} />
        <Route exact path='/DoctorRegistry' render={props => isAuthenticated ? <CommonTabs {...props} List={Doc_Registry_Chart} selectedTab={Doc_Registry_Chart[0]} BackToHome={'/DoctorHome'} /> : <Redirect to='/' />} />
        <Route exact path='/DoctorSettings' render={props => isAuthenticated ? <CommonTabs {...props} List={Doctor_Settings} selectedTab={Doctor_Settings[0]} BackToHome={'/DoctorHome'} /> : <Redirect to='/' />} />
        <Route exact path='/OptometrySettings' render={props => isAuthenticated ? <CommonTabs {...props} List={Opthometry_Settings} selectedTab={Opthometry_Settings[0]} BackToHome={'/DoctorHome'} /> : <Redirect to='/' />} />
        <Route exact path='/DoctorSettings/DoctorNotes' render={props => isAuthenticated ? <CommonTabs {...props} List={Doctor_Settings} selectedTab={Doctor_Settings[1]} BackToHome={'/DoctorHome'} /> : <Redirect to='/' />} />

        <Route exact path="/IPDoctorHome" render={props => isAuthenticated ? <IPDoctorHome {...props}/> : <Redirect to="/" />} />
        <Route exact path='/IPDoctorHome/AdmissionDetails' render={props => isAuthenticated ? <CommonTabs  {...props} List={IP_Doctor_Admission_Details} selectedTab={IP_Doctor_Admission_Details[0]} BackToHome={'/IPDoctorHome'} /> : <Redirect to="/" />} />
        <Route exact path='/IPDoctorHome/AdmissionDetails/ClinicalNotes' render={props => isAuthenticated ? <CommonTabs  {...props} List={IP_Doctor_Admission_Details} selectedTab={IP_Doctor_Admission_Details[1]} BackToHome={'/IPDoctorHome'} /> : <Redirect to="/" />} />
        <Route exact path='/IPDoctorHome/AdmissionDetails/History' render={props => isAuthenticated ? <CommonTabs  {...props} List={IP_Doctor_Admission_Details} selectedTab={IP_Doctor_Admission_Details[2]} BackToHome={'/IPDoctorHome'} /> : <Redirect to="/" />} />
        <Route exact path='/IPDoctorHome/AdmissionDetails/Reports' render={props => isAuthenticated ? <CommonTabs  {...props} List={IP_Doctor_Admission_Details} selectedTab={IP_Doctor_Admission_Details[3]} BackToHome={'/IPDoctorHome'} /> : <Redirect to="/" />} />
        <Route exact path='/IPDoctorHome/AdmissionDetails/SurgeryNotes' render={props => isAuthenticated ? <CommonTabs  {...props} List={IP_Doctor_Admission_Details} selectedTab={IP_Doctor_Admission_Details[4]} BackToHome={'/IPDoctorHome'} /> : <Redirect to="/" />} />
        <Route exact path='/IPDoctorHome/AdmissionDetails/DischargeSummary' render={props => isAuthenticated ? <CommonTabs  {...props} List={IP_Doctor_Admission_Details} selectedTab={IP_Doctor_Admission_Details[5]} BackToHome={'/IPDoctorHome'} /> : <Redirect to="/" />} />
        <Route exact path='/IPDoctorHome/AdmissionDetails/PatientDocuments' render={props => isAuthenticated ? <CommonTabs  {...props} List={IP_Doctor_Admission_Details} selectedTab={IP_Doctor_Admission_Details[6]} BackToHome={'/IPDoctorHome'} /> : <Redirect to="/" />} />

        {/* ORGANIZATION ADMIN ROUTER PATH */}

        <Route exact path='/AdminDashboard' render={props => isAuthenticated ? <AdminDashBoard {...props} history={history} /> : <Redirect to='/' />} />
        <Route exact path='/AdminDashboard/BranchDashboard' render={props => isAuthenticated ? <BranchDetailDashboard {...props} history={history} /> : <Redirect to='/' />} />
        <Route exact path='/AdminDashboard/BranchDashboard/ChartComparison' render={props => isAuthenticated ? <ChartComparison {...props} history={history} /> : <Redirect to='/' />} />
        <Route exact path='/AdminSettings' render={props => isAuthenticated ? <CommonTabs {...props} List={Branch_Settings} selectedTab={Branch_Settings[0]} BackToHome={'/AdminDashboard'} /> : <Redirect to='/' />} />
        <Route exact path='/AdminSettings/PharmacyStockConfig' render={props => isAuthenticated ? <CommonTabs {...props} List={Branch_Settings} selectedTab={Branch_Settings[1]} BackToHome={'/AdminDashboard'} /> : <Redirect to='/' />} />

        {/* LAB NEW ROUTER PATH */}
        { /* Lab Settings and Master */}
        <Route exact path='/SettingsHome' render={props => isAuthenticated ? <SettingsHome {...props} history={history} /> : <Redirect to="/" />} />
        <Route exact path='/SettingsHome/LabMaster/DepartmentMaster' render={props => isAuthenticated ? <CommonTabs {...props} List={SettingsMasterTab.Lab_masters} selectedTab={SettingsMasterTab.Lab_masters[0]} BackToHome={"/SettingsHome"} /> : <Redirect to="/" />} />
        <Route exact path='/SettingsHome/LabMaster/ContainerMaster' render={props => isAuthenticated ? <CommonTabs {...props} List={SettingsMasterTab.Lab_masters} selectedTab={SettingsMasterTab.Lab_masters[1]} BackToHome={"/SettingsHome"} /> : <Redirect to="/" />} />
        <Route exact path='/SettingsHome/LabMaster/SpecimenMster' render={props => isAuthenticated ? <CommonTabs {...props} List={SettingsMasterTab.Lab_masters} selectedTab={SettingsMasterTab.Lab_masters[2]} BackToHome={"/SettingsHome"} /> : <Redirect to="/" />} />
        <Route exact path='/SettingsHome/LabMaster/MethodMaster' render={props => isAuthenticated ? <CommonTabs {...props} List={SettingsMasterTab.Lab_masters} selectedTab={SettingsMasterTab.Lab_masters[3]} BackToHome={"/SettingsHome"} /> : <Redirect to="/" />} />
        <Route exact path='/SettingsHome/LabMaster/AntibioticMaster' render={props => isAuthenticated ? <CommonTabs {...props} List={SettingsMasterTab.Lab_masters} selectedTab={SettingsMasterTab.Lab_masters[4]} BackToHome={"/SettingsHome"} /> : <Redirect to="/" />} />
        <Route exact path='/SettingsHome/LabMaster/OrganismMaster' render={props => isAuthenticated ? <CommonTabs {...props} List={SettingsMasterTab.Lab_masters} selectedTab={SettingsMasterTab.Lab_masters[5]} BackToHome={"/SettingsHome"} /> : <Redirect to="/" />} />
        <Route exact path='/SettingsHome/LabMaster/OrganismVsAntibioticGrp' render={props => isAuthenticated ? <CommonTabs {...props} List={SettingsMasterTab.Lab_masters} selectedTab={SettingsMasterTab.Lab_masters[6]} BackToHome={"/SettingsHome"} /> : <Redirect to="/" />} /> 
        <Route exact path='/SettingsHome/LabMaster/AnalyserMaster' render={props => isAuthenticated ? <CommonTabs {...props} List={SettingsMasterTab.Lab_masters} selectedTab={SettingsMasterTab.Lab_masters[7]} BackToHome={"/SettingsHome"} /> : <Redirect to="/" />} /> 


        <Route exact path='/SettingsHome/ResultConfiguration' render={props => isAuthenticated ? <CommonTabs  {...props} List={Lab_Configuration} selectedTab={Lab_Configuration[0]} BackToHome={"/SettingsHome"}/> : <Redirect to="/" />} />
        <Route exact path='/SettingsHome/PackageConfiguration' render={props => isAuthenticated ? <CommonTabs  {...props} List={Lab_Configuration} selectedTab={Lab_Configuration[1]} BackToHome={"/SettingsHome"}/> : <Redirect to="/" />} />
        <Route exact path='/SettingsHome/TemplateConfiguration' render={props => isAuthenticated ? <CommonTabs  {...props} List={Lab_Configuration} selectedTab={Lab_Configuration?.find((list) => list.id === 12.11)} BackToHome={"/SettingsHome"}/> : <Redirect to="/" />} />
        <Route exact path='/SettingsHome/DoctorConfiguration' render={props => isAuthenticated ? <CommonTabs  {...props} List={Lab_Configuration} selectedTab={Lab_Configuration?.find((list) => list.id === 12.12)} BackToHome={"/SettingsHome"}/> : <Redirect to="/" />} />
        <Route exact path='/SettingsHome/ConsumableConfiguration' render={props => isAuthenticated ? <CommonTabs  {...props} List={Lab_Configuration} selectedTab={Lab_Configuration?.find((list) => list.id === 12.3)} BackToHome={"/SettingsHome"}/> : <Redirect to="/" />} />
        <Route exact path='/SettingsHome/PatientDemographics' render={props => isAuthenticated ? <CommonTabs  {...props} List={Lab_Configuration} selectedTab={Lab_Configuration?.find((list) => list.id === 12.4)} BackToHome={"/SettingsHome"}/> : <Redirect to="/" />} />
        <Route exact path='/SettingsHome/ReferredDoctor' render={props => isAuthenticated ? <CommonTabs  {...props} List={Lab_Configuration} selectedTab={Lab_Configuration?.find((list) => list.id === 12.5)} BackToHome={"/SettingsHome"}/> : <Redirect to="/" />} />
        <Route exact path='/SettingsHome/OutsourceConfiguration' render={props => isAuthenticated ? <CommonTabs  {...props} List={Lab_Configuration} selectedTab={Lab_Configuration?.find((list) => list.id === 12.6)} BackToHome={"/SettingsHome"}/> : <Redirect to="/" />} />
        <Route exact path='/SettingsHome/OutsourceMapping' render={props => isAuthenticated ? <CommonTabs  {...props} List={Lab_Configuration} selectedTab={Lab_Configuration?.find((list) => list.id === 12.7)} BackToHome={"/SettingsHome"}/> : <Redirect to="/" />} />
        <Route exact path='/SettingsHome/SignatureConfiguration' render={props => isAuthenticated ? <CommonTabs  {...props} List={Lab_Configuration} selectedTab={Lab_Configuration?.find((list) => list.id === 12.9)} BackToHome={"/SettingsHome"}/> : <Redirect to="/" />} />

        {/* Radiology Router Path Starts ======> */}

        <Route exact path="/RadiologyHome" render={props => isAuthenticated ? <RadPatientHome {...props} /> : <Redirect to="/" />} />
        <Route exact path='/RadiologyHome/Patient/Demographics' render={props => isAuthenticated ? <CommonTabs  {...props} List={Rad_Home} selectedTab={Rad_Home[2]} BackToHome={"/RadiologyHome"} /> : <Redirect to="/" />} />
        <Route exact path='/RadiologyHome/Patient/Billing' render={props => isAuthenticated ? <CommonTabs  {...props} changeScreen={changeScreen} List={Rad_Home} selectedTab={Rad_Home[1]} BackToHome={"/RadiologyHome"} /> : <Redirect to="/" />} />
        <Route exact path='/RadiologyHome/Patient/Reports/TestReports' render={props => isAuthenticated ? <CommonTabs  {...props} List={Rad_Home} selectedTab={Rad_Home[0]} BackToHome={"/RadiologyHome"} /> : <Redirect to="/" />} />
        <Route exact path='/RadiologyHome/Demographics' render={props => isAuthenticated ? <CommonTabs  {...props} List={Rad_Demographics} selectedTab={Rad_Demographics[2]} BackToHome={"/RadiologyHome"} /> : <Redirect to="/" />} />

        <Route exact path='/RadiologyBillingTransaction' render={props => isAuthenticated ? <CommonTabs  {...props} List={Rad_Billing_Transaction} selectedTab={Rad_Billing_Transaction[0]} /> : <Redirect to="/" />} />
        <Route exact path='/RadiologyBillingTransaction/OverallBills' render={props => isAuthenticated ? <CommonTabs  {...props} List={Rad_Billing_Transaction} selectedTab={Rad_Billing_Transaction[0]} /> : <Redirect to="/" />} />
        <Route exact path='/RadiologyBillingTransaction/CreditBills' render={props => isAuthenticated ? <CommonTabs  {...props} List={Rad_Billing_Transaction} selectedTab={Rad_Billing_Transaction[1]} /> : <Redirect to="/" />} />
        <Route exact path='/RadiologyBillingTransaction/CancelledBills' render={props => isAuthenticated ? <CommonTabs  {...props} List={Rad_Billing_Transaction} selectedTab={Rad_Billing_Transaction[2]} /> : <Redirect to="/" />} />
        <Route exact path='/RadiologyBillingTransaction/SavedBills' render={props => isAuthenticated ? <CommonTabs  {...props} List={Rad_Billing_Transaction} selectedTab={Rad_Billing_Transaction[3]} /> : <Redirect to="/" />} />
        <Route exact path='/RadiologyBillingTransaction/ReceiptBills' render={props => isAuthenticated ? <CommonTabs  {...props} List={Rad_Billing_Transaction} selectedTab={Rad_Billing_Transaction[4]} /> : <Redirect to="/" />} />
        <Route exact path='/RadiologyBillingTransaction/DayEndStatus' render={props => isAuthenticated ? <CommonTabs  {...props} List={Rad_Billing_Transaction} selectedTab={Rad_Billing_Transaction[5]} /> : <Redirect to="/" />} />
        <Route exact path='/RadiologyBillingTransaction/DayEndDenomination' render={props => isAuthenticated ? <CommonTabs {...props} List={Rad_Billing_Transaction} selectedTab={Rad_Billing_Transaction[6]} /> : <Redirect to='/' />} />

        <Route exact path='/RadiologyReports' render={props => isAuthenticated ? <CommonTabs  {...props} List={Rad_Reports} selectedTab={Rad_Reports[0]} /> : <Redirect to="/" />} />
        <Route exact path='/RadiologyReports/Census' render={props => isAuthenticated ? <CommonTabs  {...props} List={Rad_Reports} selectedTab={Rad_Reports[0]} /> : <Redirect to="/" />} />
        <Route exact path='/RadiologyReports/PatientReport' render={props => isAuthenticated ? <CommonTabs  {...props} List={Rad_Reports} selectedTab={Rad_Reports[1]} /> : <Redirect to="/" />} />

        <Route exact path='/RadiologySettings' render={props => isAuthenticated ? <CommonTabs  {...props} List={Rad_Setting} selectedTab={Rad_Setting[0]} /> : <Redirect to="/" />} />
        <Route exact path='/RadiologySettings/ServiceConfig' render={props => isAuthenticated ? <CommonTabs  {...props} List={Rad_Setting} selectedTab={Rad_Setting[0]} /> : <Redirect to="/" />} />
        <Route exact path='/RadiologySettings/ServiceTemplateConfig' render={props => isAuthenticated ? <CommonTabs  {...props} List={Rad_Setting} selectedTab={Rad_Setting[1]} /> : <Redirect to="/" />} />
        <Route exact path='/RadiologySettings/ServiceTemplateConfig/Template' render={props => isAuthenticated ? <CommonTabs  {...props} List={Rad_Setting_Templete} selectedTab={Rad_Setting_Templete[0]} BackToHome={'/RadiologySettings/ServiceTemplateConfig'} /> : <Redirect to="/" />} />
        <Route exact path='/RadiologySettings/PrintConfig' render={props => isAuthenticated ? <CommonTabs  {...props} List={Rad_Setting} selectedTab={Rad_Setting[2]} /> : <Redirect to="/" />} />

        <Route exact path='/RadiologyHome/Demographics/Update' render={props => isAuthenticated ? <CommonTabs  {...props} List={Rad_Demographics_Update} selectedTab={Rad_Demographics_Update[0]} BackToHome={"/RadiologyHome"} /> : <Redirect to="/" />} />


        {/* Radiology Routing Ends ====> */}
        {/* Councellor Module Tabs */}
        <Route exact path='/Counsellor/PatientDetails/DoctorVisitReport' render={props => isAuthenticated ? <CommonTabs  {...props} List={Counsellor_Tabs} selectedTab={Counsellor_Tabs[0]} BackToHome={"/Counsellor"} /> : <Redirect to="/" />} />

        {/* Stock Branch to Branch Transfer */}
        <Route exact path='/PharmacyStockTransfer/PharmaBranchWanted' render={props => isAuthenticated ? <CommonTabs {...props} List={PharmacyBranch_Stock_Transfer} selectedTab={PharmacyBranch_Stock_Transfer[0]} BackToHome={"/PharmacyStockTransfer"}/> : <Redirect to='/' />} />
        <Route exact path='/PharmacyStockTransfer/PharmaBranchInbound' render={props => isAuthenticated ? <CommonTabs {...props} List={PharmacyBranch_Stock_Transfer} selectedTab={PharmacyBranch_Stock_Transfer[1]} BackToHome={"/PharmacyStockTransfer"}/> : <Redirect to='/' />} />
        <Route exact path='/PharmacyStockTransfer/PharmaBranchOutbound' render={props => isAuthenticated ? <CommonTabs {...props} List={PharmacyBranch_Stock_Transfer} selectedTab={PharmacyBranch_Stock_Transfer[2]} BackToHome={"/PharmacyStockTransfer"}/> : <Redirect to='/' />} />
        <Route exact path='/PharmacyStockTransfer/PharmaBranchReturn' render={props => isAuthenticated ? <CommonTabs {...props} List={PharmacyBranch_Stock_Transfer} selectedTab={PharmacyBranch_Stock_Transfer[3]} BackToHome={"/PharmacyStockTransfer"}/> : <Redirect to='/' />} />
        <Route exact path='/PharmacyStockTransfer/PharmaBranchStockTransReport' render={props => isAuthenticated ? <CommonTabs {...props} List={PharmacyBranch_Stock_Transfer} selectedTab={PharmacyBranch_Stock_Transfer[4]} BackToHome={"/PharmacyStockTransfer"}/> : <Redirect to='/' />} />
        <Route exact path='/PharmacyStockTransfer/PharmaBranchStockList' render={props => isAuthenticated ? <CommonTabs {...props} List={PharmacyBranch_Stock_Transfer} selectedTab={PharmacyBranch_Stock_Transfer[5]} BackToHome={"/PharmacyStockTransfer"}/> : <Redirect to='/' />} />
        <Route exact path="/PharmacyStockTransfer/RaiseRequest" render={props => isAuthenticated ? <PharmaBranchRaiseReq {...props} history={history} /> : <Redirect to="/" />} />
        <Route exact path="*" render={props => <PageNotFound {...props} />} />
      </Switch>
    </Switch>
  )
}
export default RouterComponent;